
import { useEffect, useMemo, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { StyledCard } from 'src/styled_components/StyledCard'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { blue, green, yellow } from '@ant-design/colors'
import { AsyncStates } from 'src/constants'
import { LoadingOutlined } from '@ant-design/icons'
import useTranslate from 'src/utils/useTranslate'


export const Dashboard = () => {
  const [t] = useTranslate()

  const stages = useSelector((state: StoreState) => state.displayNames.data?.stages)
  const { data: workOrders, status: woListStatus } = useSelector((state: StoreState) => state.workOrders);
  const { overview } = useSelector((state: StoreState) => state.projects);

  const [woStagesData, setWoStagesData] = useState<any>({})
  const [woStatusData, setWoStatusData] = useState<any[]>([])

  useEffect(() => {
    if (Object.keys(overview || {}).length > 0) {
      setWoStagesData(Object.entries(overview?.stages || {}).filter(([key, value]: any) => value !== 0).reduce((acc: any, [key, value]: any) => ({ ...acc, [key]: value }), {}))
      const woStatus = { "Closed": overview?.status?.closed, "In Progress": overview?.status?.open }
      setWoStatusData(Object.entries(woStatus || {}).map(([key, value]) => ({ name: key, y: value })))
    }
  }, [overview])

  const woStageOptions = useMemo(() => ({
    chart: {
      type: "bar",
    },
    colors: [blue[4]],
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        tooltip: {
          headerFormat: `<b>{point.x}</b><br/>`,
          pointFormat: `<b>Count:</b> {point.y}`
        }
      },
    },
    title: {
      text: "Work Orders by Stage"
    },
    xAxis: {
      categories: Object.entries(stages || {}).filter(([key, value]: any) => !!woStagesData?.[key]).map(([key, value]: any) => value),
      allowDecimals: false,
      title: {
        text: null
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: null
      },
      visible: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [{
      data: Object.values(woStagesData || {})
    }]
  }), [stages, woStagesData])

  const woStatusOptions = useMemo(() => ({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    colors: [green[3], yellow[4]],
    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}: {point.y}</b><br/>'
    },
    title: {
      text: 'Work Order Status'
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [{
      name: "Status",
      colorByPoint: true,
      data: woStatusData
    }]
  }), [woStatusData])


  return (
    !!workOrders?.length ?
      <Spin spinning={woListStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
        <StyledCard title={`${t("common.project")} ${t("navBar.menuItem.dashboard")}`}>
          <Row justify="space-between">
            {!!workOrders.filter((wo: any) => wo.status === "open").length &&
              <Col span={11}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={woStageOptions}
                />
              </Col>
            }
            <Col span={11}>
              <HighchartsReact
                highcharts={Highcharts}
                options={woStatusOptions}
              />
            </Col>
          </Row>
        </StyledCard>
      </Spin> : null
  )
}

