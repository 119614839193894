import { PlusOutlined } from "@ant-design/icons";
import { Row, Typography, Select, Space, Tooltip, Collapse, Empty } from "antd"
import { useSelector } from "react-redux"
import { IDataSheet } from "src/components/IDataSheet"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import "./CategoricalInputConstraints.scss"
import { antdTheme } from "src/constants"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"

const { Text } = Typography
const { Option } = Select

export const CategoricalInputConstraints = ({
  categorialInputList,
  setCategorialInputList,
  modelData,
  setUnsavedChanges,
  categoricalRange,
  inputContraintsData,
  currentSelectedStage,
}: any) => {
  const [t] = useTranslate()
  const labels = useSelector(
    (state: StoreState) => state.displayNames.data || {},
  )

  const addRow = () => {
    setCategorialInputList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage].push({ parameter: "", data: [] })
      return { ...newState }
    })
  }

  const removeRow = (parameterIndex: any) => {
    setCategorialInputList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = newState[currentSelectedStage].filter(
        (item: any, index: number) => index !== parameterIndex,
      )
      return { ...newState }
    })
  }

  const parameterListDropDown = (index: any) => {
    const ingredientsNames = modelData?.inputs_ingredients
      ? modelData?.inputs_ingredients
      : []
    const processingNames = modelData?.inputs_processing
      ? modelData?.inputs_processing
      : []

    return (
      <Row
        style={{
          alignItems: "center",
          width: "100%",
          padding: 8,
          justifyContent: "space-between",
          gap: 8,
          flexWrap: "nowrap",
        }}
      >
        <Tooltip title={t("common.remove")}>
          <StyledDeleteIcon
            style={{
              fontSize: antdTheme.fontSizeHeading4,
              verticalAlign: "baseline",
              outline: "none",
            }}
            onClick={() => removeRow(index)}
          />
        </Tooltip>
        <Select
          value={
            labels?.ingredients?.[categorialInputList?.[index]?.parameter]
              ?.name ||
            labels?.processing?.[categorialInputList?.[index]?.parameter]
              ?.name ||
            categorialInputList[index]?.parameter
          }
          bordered={false}
          showArrow={false}
          showSearch
          placeholder={t("aiEngine.selectInputs")}
          style={{ textAlign: "left", textOverflow: "clip", minWidth: 340 }}
          notFoundContent={
            <Space style={{ background: "whitesmoke", width: "100%" }}>
              <Text>{t("aiEngine.selectDifferentModel")}</Text>
            </Space>
          }
          options={[
            {
              label: t("common.ingredients"),
              options: Object.entries(inputContraintsData || {})
                ?.filter(
                  ([key, value]: any) =>
                    !categorialInputList
                      .map((row: any) => row?.parameter)
                      .includes(key) && Array.isArray(value),
                )
                .filter(([key]: any) => ingredientsNames.includes(key))
                .map(([key, value]: any) => ({
                  value: key,
                  label: labels?.ingredients?.[key]?.name || key,
                })) ?? [{ value: null, label: "No Data Available" }],
            },
            {
              label: t("common.processing"),
              options: Object.entries(inputContraintsData || {})
                ?.filter(
                  ([key, value]: any) =>
                    !categorialInputList
                      .map((row: any) => row?.parameter)
                      .includes(key) && Array.isArray(value),
                )
                .filter(([key]: any) => processingNames.includes(key))
                .map(([key, value]: any) => ({
                  value: key,
                  label: labels?.processing?.[key]?.name || key,
                })) ?? [{ value: null, label: "No Data Available" }],
            },
          ]}
          onChange={(e: any) => {
            setCategorialInputList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage].forEach(
                (res: any, parameterIndex: any) => {
                  if (index === parameterIndex) {
                    res.parameter = e
                    res.data = []
                  }
                },
              )
              return { ...newState }
            })
            setUnsavedChanges(true)
          }}
        />
      </Row>
    )
  }
  const categorialInputListDropDown = (index: any, parameter: any) => {
    return (
      <Row
        style={{
          alignItems: "center",
          width: "100%",
          padding: 8,
          justifyContent: "space-between",
          gap: 8,
        }}
      >
        <Select
          style={{ flexGrow: 1 }}
          value={categorialInputList?.[index].data}
          mode="multiple"
          onChange={(e: any) => {
            setCategorialInputList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage][index].data = e
              return { ...newState }
            })
          }}
        >
          {(
            modelData?.inputs_range?.[parameter] ??
            inputContraintsData?.[parameter]
          )?.map((res: any) => (
            <Option value={res}>
              {typeof res === "number"
                ? res
                : res?.[0]?.toUpperCase() + res.slice(1)}
            </Option>
          ))}
        </Select>
      </Row>
    )
  }

  return (
    <Collapse style={{ width: "100%" }}>
      <Collapse.Panel
        key="categorical-input-constraints"
        header={t("aiEngine.categoricalInputConstraints")}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <StyledButton
                onClick={addRow}
                icon={<PlusOutlined />}
                type="default"
                size="small"
                style={{ borderRadius: 5, outline: "none" }}
                disabled={
                  Object.entries(inputContraintsData || {})?.filter(
                    ([key, value]: any) => Array.isArray(value),
                  ).length === 0 ||
                  Object.entries(inputContraintsData || {})?.filter(
                    ([key, value]: any) => Array.isArray(value),
                  ).length === categorialInputList.length
                }
              >
                {`${t("common.add")} ${t("aiEngine.inputFeatures")}`}
              </StyledButton>
            </div>
          </div>
          {!!categorialInputList?.length ? (
            <IDataSheet
              data={[
                [
                  {
                    value: t("aiEngine.inputFeatures"),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                        <Typography.Text
                          strong
                        >
                          {t("aiEngine.inputFeatures")}
                        </Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true,
                    width: "30%",
                  },
                  {
                    value: t("inputConstraints.optimalRange"),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text
                          strong
                        >
                          {t("inputConstraints.optimalRange")}
                        </Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                  },
                  {
                    value: t("common.select"),
                    forceComponent: true,
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text
                          strong
                        >
                          {t("common.select")}
                        </Typography.Text>
                      </Row>
                    ),
                    width: "40%",
                  },
                ],
                ...categorialInputList.map((key: any, index: any) => [
                  {
                    value: index,
                    component: parameterListDropDown(index),
                    forceComponent: true,
                    disableEvents: true,
                    width: "30%",
                  },
                  {
                    value:
                      !!modelData?.inputs_range?.[key?.parameter]?.length ||
                      !!inputContraintsData?.[key?.parameter]?.length
                        ? `${
                            modelData?.inputs_range?.[key?.parameter]?.join(
                              ", ",
                            ) ??
                            inputContraintsData?.[key?.parameter]?.join(", ")
                          }`
                        : "-",
                    readOnly: true,
                  },
                  {
                    value: index,
                    component: categorialInputListDropDown(
                      index,
                      key?.parameter,
                    ),
                    forceComponent: true,
                    disableEvents: true,
                    width: "40%",
                  },
                ]),
              ]}
              valueRenderer={(cell) => cell.value}
              className="categorical-input-constraints-table"
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Empty
                description={
                  <Text>
                    {`${t("common.add")} ${t(
                      "aiEngine.categoricalInputConstraints",
                    )}`}
                  </Text>
                }
              />
            </div>
          )}
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}
