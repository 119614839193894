import { EditOutlined } from "@ant-design/icons";
import { Col, Collapse, Empty, Row, Space } from "antd";
import { useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import AddEditIngredientTemplatePropertiesModal from "../Common/IngredientTemplate/AddEditIngredientTemplatePropertiesModal";

type TProps = {
    propertiesByCategory: { category: string; properties: any[] }[];
    selectedTemplateId: string;
};

const IngredientTemplateProperties = ({
    propertiesByCategory,
    selectedTemplateId,
}: TProps) => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Row justify={"space-between"} style={{ marginBottom: "12px" }}>
                <Col></Col>
                <Col>
                    <StyledButton
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => setShowModal(true)}
                    >
                        {t("common.edit")}
                    </StyledButton>
                </Col>
            </Row>
            <Space direction="vertical" style={{ width: "100%" }}>
                {propertiesByCategory.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    propertiesByCategory.map(({ category, properties }) => (
                        <Collapse
                            defaultActiveKey={[category]}
                            items={[
                                {
                                    key: category,
                                    showArrow: false,
                                    collapsible: "icon",
                                    label: (
                                        <div className="collapse-header-container">
                                            <span className="collapse-header">{category}</span>
                                        </div>
                                    ),
                                    children: (
                                        <>
                                            {properties.length === 0 && (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                            {properties.map((p: any) => (
                                                <div key={p.id} className="data-list">
                                                    {p.name && <span>{p.name}</span>}
                                                </div>
                                            ))}
                                        </>
                                    ),
                                },
                            ]}
                        />
                    ))
                )}
            </Space>
            <AddEditIngredientTemplatePropertiesModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedTemplateId={selectedTemplateId}
            />
        </>
    );
};

export default IngredientTemplateProperties;
