import {
    AutoComplete,
    Cascader,
    Col,
    Empty,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Table,
    Typography,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { PARAMETER_TYPES } from "../../Repository/Common/Data";
import { StyledCard } from "src/styled_components/StyledCard";
import ParameterDropdown from "../../Repository/Common/MethodParameters/ParameterDropdown";
import { LoadingOutlined, MinusCircleOutlined } from "@ant-design/icons";
import "../../Repository/Common/Common.scss";
import { addPropertyValueToPropertyClear, addPropertyValueToPropertyRequest, getPropertyMethodsListRequest, updatePropertyValueToPropertyClear, updatePropertyValueToPropertyRequest } from "src/store/actions/inventoryV2";
import { AsyncStates } from "src/constants";
import { useValue } from "src/utils/useValue";
import { getDropdownFilteredValue } from "src/utils/decorator";
import { Headers } from "src/services/interface";
import jwtManager from "src/utils/jwtManager";
import RepositoryService from "src/services/repository/v3";
import InventoryUtils from "../../utils";
import { StyledButton } from "src/styled_components/StyledButton";

const { Text } = Typography;

const operators = ['=', '<', '<=', '>', '>=', '~']

const operatorOptions = operators.map((operator) => {
    return {
        label: operator,
        value: operator,
        key: operator
    }
})

const validateRange = (type: string, value: number, value_max: number) => {
    if (type === "range") {
        if ((value !== null) && (value_max !== null)) {
            if (value <= value_max) {
                return Promise.resolve();
            }
            return Promise.reject('Value <= Max Value');
        }
    }
    return Promise.resolve();
}

export const InventoryNewPropertyValueModal = ({ propertyValueModal, setPropertyValueModal }: any) => {
    const createParameterStatus = useSelector((state: StoreState) => state.repository.parameters.status);
    const [openAddParameterPopover, setOpenAddParameterPopover] = useState(false);

    const [form] = Form.useForm();
    const [selectedParameters, setSelectedParameters] = useState<string[]>([])
    const unitList = useSelector((state: StoreState) => state.conversion.unitList);
    const [loadingStandards, setLoadingStandards] = useState<boolean>(false);
    const [standardOptions, setStandardOptions] = useState<any[]>([]);
    const [specimenOptions, setSpecimenOptions] = useState<any[]>([]);
    const propertyUnitsData = useSelector(
        (state: StoreState) => state.repository.propertyUnits.data
    );
    const propertyUnitsStatus = useSelector(
        (state: StoreState) => state.repository.propertyUnits.status
    );

    const defaultHeaders = useSelector(
      (state: StoreState) => state.defaultHeaders
    );

    const dispatch = useDispatch()

    const propertyMethodsList = useSelector((state: StoreState) => state.inventoryV2.propertyMethodsList)
    const propertyMethodsListStatus = useSelector((state: StoreState) => state.inventoryV2.propertyMethodsListStatus)
    const addPropertyValueToPropertyStatus = useSelector((state: StoreState) => state.inventoryV2.addPropertyValueToPropertyStatus)
    const addPropertyValueToPropertyError = useSelector((state: StoreState) => state.inventoryV2.addPropertyValueToPropertyError)
    const updatePropertyValueToPropertyStatus = useSelector((state: StoreState) => state.inventoryV2.updatePropertyValueToPropertyStatus)

    const [propertyValue, setPropertyValue] = useState({
        value_type: "numerical",
        value_subtype: "single",
        method_name: "-"
    })

    const propertyMethods = useMemo(() => {
        return propertyMethodsList?.[propertyValueModal.data.property_id] ?? {
            methods: [],
            default_parameters: []
        }
    }, [propertyMethodsList, propertyValueModal.data.property_id])

    const allowEditing = useMemo(() => {
        return true
        // propertyValue.method_name === "-" || !propertyMethods?.methods?.find((method) => method.method_name === propertyValue.method_name)
    }, []);

    const { getDecimalSeparator, getValue: getLocalValue } = useValue()

    const [t] = useTranslate()

    const unitOptions = useMemo(() => {
        return unitList.map((unit) => {
            return {
                label: unit.name,
                value: unit.name
            }
        })
    }, [unitList]);

    const propertyUnitsOptions = useMemo(() => {
        if (propertyUnitsData?.units?.length > 0) {
            return [...new Set(propertyUnitsData.units)].map(unit => ({
                label: unit,
                value: unit
            }))
        }
        return unitOptions;
    }, [propertyUnitsData, unitOptions]);


    useEffect(() => {
        if (createParameterStatus === AsyncStates.SUCCESS) {
            dispatch(getPropertyMethodsListRequest({
                inventory_id: propertyValueModal.data.inventory_id,
                property_id: propertyValueModal.data.property_id
            }))
        }
    }, [createParameterStatus, dispatch, propertyValueModal.data.inventory_id, propertyValueModal.data.property_id]);

    const fetchStandardsAndSpecimens = useCallback(async () => {
        setLoadingStandards(true);
        const headers = {
          ...defaultHeaders,
          token: jwtManager.getToken(),
        } as Headers;
        const standardsPromise: any = RepositoryService.getStandards(
            propertyValueModal.data.property_id,
          headers
        );
        const specimensPromise: any = RepositoryService.getSpecimens(
            propertyValueModal.data.property_id,
          headers
        );

        Promise.allSettled([standardsPromise, specimensPromise])
          .then((results) => {
            const [standardsResult, specimensResult] = results;

            setStandardOptions(
              standardsResult.status === "fulfilled"
                ? standardsResult?.value?.data?.result?.data?.standards
                    ?.map((standard: string) => {
                      return {
                        label: standard,
                        value: standard,
                      };
                    })
                    ?.sort((a: any, b: any) => a.label?.localeCompare(b.label))
                : []
            );
            setSpecimenOptions(
              specimensResult.status === "fulfilled"
                ? specimensResult?.value?.data?.result?.data?.specimens
                    ?.map((standard: string) => {
                      return {
                        label: standard,
                        value: standard,
                      };
                    })
                    ?.sort((a: any, b: any) => a.label?.localeCompare(b.label))
                : []
            );
          })
          .catch((error) => {
            console.log(error);
          });

        
        setLoadingStandards(false);
      }, [defaultHeaders, propertyValueModal.data.property_id]);
    
      useEffect(() => {
        fetchStandardsAndSpecimens();
      }, [fetchStandardsAndSpecimens]);

    useEffect(() => {
        if (addPropertyValueToPropertyStatus?.[propertyValueModal.data.inventory_property_id] === AsyncStates.SUCCESS) {
            setPropertyValueModal({
                mode: "create",
                isModalVisible: false,
                data: null
            })
            form.resetFields()
            dispatch(addPropertyValueToPropertyClear({
                inventory_property_id: propertyValueModal.data.inventory_property_id,
                inventory_id: propertyValueModal.data.inventory_id,
            }))
        }
    }, [addPropertyValueToPropertyStatus, dispatch, form, propertyValueModal.data.inventory_id, propertyValueModal.data.inventory_property_id, setPropertyValueModal])

    useEffect(() => {
        if(addPropertyValueToPropertyStatus?.[propertyValueModal.data.inventory_property_id] === AsyncStates.ERROR){
            if(addPropertyValueToPropertyError.existing_method_name){
                form.setFieldValue("method_name", addPropertyValueToPropertyError.existing_method_name);
            }
            dispatch(addPropertyValueToPropertyClear({
                inventory_property_id: propertyValueModal.data.inventory_property_id,
                inventory_id: propertyValueModal.data.inventory_id,
            }))
        }
    }, [addPropertyValueToPropertyError.existing_method_name, addPropertyValueToPropertyStatus, dispatch, form, propertyValueModal.data.inventory_id, propertyValueModal.data.inventory_property_id])



    useEffect(() => {
        if (updatePropertyValueToPropertyStatus?.[propertyValueModal.data.inventory_property_id] === AsyncStates.SUCCESS) {
            setPropertyValueModal({
                mode: "create",
                isModalVisible: false,
                data: null
            })
            form.resetFields()
            dispatch(updatePropertyValueToPropertyClear({
                inventory_property_id: propertyValueModal.data.inventory_property_id,
                inventory_id: propertyValueModal.data.inventory_id,
            }))
        }
    }, [dispatch, form, propertyValueModal.data.inventory_id, propertyValueModal.data.inventory_property_id, setPropertyValueModal, updatePropertyValueToPropertyStatus])

    const handlePropertyValueFormSubmit = (values: any) => {
        if (propertyValueModal.mode === "create") {
            const payload = {
                ...values,
                standard: values.standard?.trim() ?? "",
                specimen: values.specimen?.trim() ?? "",
                method_name: values.method_name.trim(),
                property_id: propertyValueModal.data.property_id,
                inventory_property_id: propertyValueModal.data.inventory_property_id,
                inventory_id: propertyValueModal.data.inventory_id,
                category_id: propertyValueModal.data.category_id,
                parameters: values.parameters.sort((a: any, b: any) => a.parameter?.localeCompare(b.parameter))
                .reduce((acc: any, curr: any) => {
                    const parameterData = { ...curr, value_type: curr.type[0], value_subtype: curr.type[1] }
                    delete parameterData.type
                    acc.push(parameterData)
                    return acc
                }, [])
            }
            dispatch(addPropertyValueToPropertyRequest(payload))
        } else {
            const payload = {
                ...values,
                standard: values.standard?.trim() ?? "",
                specimen: values.specimen?.trim() ?? "",
                method_name: values.method_name.trim(),
                property_id: propertyValueModal.data.property_id,
                inventory_property_id: propertyValueModal.data.inventory_property_id,
                inventory_id: propertyValueModal.data.inventory_id,
                inventory_property_value_id: propertyValueModal.data.inventory_property_value_id,
                category_id: propertyValueModal.data.category_id,
                parameters: values.parameters.reduce((acc: any, curr: any) => {
                    const parameterData = { ...curr, value_type: curr.type[0], value_subtype: curr.type[1] }
                    delete parameterData.type
                    acc.push(parameterData)
                    return acc
                }, [])
            }
            dispatch(updatePropertyValueToPropertyRequest(payload))
        }
    }

    const parametersList = useMemo(() => {
        return (propertyMethods.default_parameters ?? []).sort((a: any, b: any) => a.parameter?.localeCompare(b.parameter))
    }, [propertyMethods]);

    const getParameterUnitOptions = useCallback((parameter_id) => {
        const parameter = parametersList.find((p) => p.parameter_id === parameter_id);
        
        return parameter?.units?.length ? [...new Set(parameter.units)].map(unit => ({
            label: unit,
            value: unit
        })) : unitOptions;
    }, [parametersList, unitOptions]);

    const setParameterDataInForm = useCallback((parameterListData) => {
        const parametersData = parameterListData.map((parameter: any) => {
            const type = (parameter.value_type && parameter.value_subtype) ? [parameter.value_type, parameter.value_subtype] : []
            return {
                parameter_id: parameter.parameter_id,
                value: parameter?.value ?? null,
                value_max: parameter?.value_max ?? null,
                unit: parameter?.unit ?? null,
                type: type,
                value_str: parameter?.value_str ?? null,
                operator: parameter?.operator ?? null
            }
        })
        form.setFieldValue('parameters', parametersData)
        if (!!parametersData.length) {
            const parameterIds = [...new Set(parametersData.map((p: any) => p.parameter_id))] as string[]
            setSelectedParameters(parameterIds)
        }
    }, [form])

    useEffect(() => {
        if (propertyValue.method_name === "-" && propertyValueModal.mode === "create") {
            const parameterData = propertyMethods.default_parameters
            const filteredParameters = parameterData.filter((parameter: any) => parameter?.is_default)
            setParameterDataInForm(filteredParameters)
        }
    }, [propertyMethods, propertyValue.method_name, propertyValueModal.mode, setParameterDataInForm])

    useEffect(() => {
        if (propertyValueModal.mode === "create" && propertyUnitsData?.default_unit) {
            form.setFieldValue('unit', propertyUnitsData?.default_unit);
        }
    }, [form, propertyUnitsData, propertyValueModal.mode])

    useEffect(() => {
        if (propertyValueModal.mode === "edit") {
            setPropertyValue({
                value_type: propertyValueModal.data.value_type,
                value_subtype: propertyValueModal.data.value_subtype,
                method_name: propertyValueModal.data.method_name
            })
            form.setFieldsValue({
                ...propertyValueModal.data,
                parameters: []
            })
            setParameterDataInForm(propertyValueModal.data.parameters.sort((a: any, b: any) => a.parameter?.localeCompare(b.parameter)))
        }
    }, [propertyValueModal, form, setParameterDataInForm])

    const dataSource = [
        {
            key: '1',
        },
    ];


    const columns = useMemo(() => {
        return [
            {
                title: t("inventory.Value"),
                dataIndex: 'value',
                key: 'value',
                width: "60%",
                render: (text: any, record: any) => {
                    return <GetFieldItem formType={propertyValue.value_type} formItemType={propertyValue.value_subtype} t={t} getDecimalSeparator={getDecimalSeparator} form={form} />
                }
            },
            {
                title: t("common.unit"),
                dataIndex: 'unit',
                key: 'unit',
                width: "40%",
                render: (text: any, record: any) => {
                    return <Form.Item name="unit"
                        rules={
                            [{
                                required: true,
                                message: t("common.requiredInfo"),
                            }]
                        }
                    >
                        <Select
                            showSearch
                            optionFilterProp="label"
                            filterOption={(inputValue, options: any) =>
                                getDropdownFilteredValue(inputValue, options)
                            }
                            options={
                                record.units ? [...new Set(record.units)].map(unit => ({
                                    label: unit,
                                    value: unit
                                })) :
                                propertyUnitsOptions}
                            placeholder={t("common.selectAUnit")}
                        />
                    </Form.Item>
                }
            },
        ];
    }, [t, propertyValue.value_type, propertyValue.value_subtype, getDecimalSeparator, form, propertyUnitsOptions])

    const autoCompleteMethodOptions = useMemo(() => {
        const optionsData = [...(propertyMethods?.methods ?? []), {
            method_name: "-",
            method_id: "-"
        }]
        return optionsData?.map((method) => {
            return {
                value: method?.method_name,
                // label: method.method_name,
                label: InventoryUtils.getNameWithParameters(method.method_name, method.parameters, getLocalValue),
            }
        }).sort((a: any, b: any) => a.label?.localeCompare(b.label))
    }, [propertyMethods, getLocalValue])

    const typeSelection = useCallback((parameter_id: string, type: string[], index: number) => {
        return (
            <Cascader
                showSearch
                disabled={!allowEditing}
                className="custom-table-input"
                placeholder={t("common.select")}
                value={type}
                options={PARAMETER_TYPES}
                onChange={(value) => {
                    const parameters = JSON.parse(JSON.stringify(form.getFieldValue("parameters")))
                    parameters[index] = {
                        ...parameters[index],
                        type: value,
                        value: null,
                        unit: null,
                        value_max: null,
                        value_str: null,
                        operator: null
                    }
                    form.setFieldValue("parameters", parameters)
                }}
                displayRender={(labels) =>
                    `${labels[0].substring(0, 3)}./${labels[1]}`
                }
            />
        );
    }, [allowEditing, t, form]);

    const renderFormFieldByType = useCallback((index: number, parameter_id: string, type: string[], value?: any) => {
        if (type[0] === "numerical") {
            if (type[1] === "single")
                return (
                    <Form.Item
                        name={[index, "value"]}
                        style={{ width: "100%" }}
                        rules={[{
                            required: true,
                            message: t("common.requiredInfo"),
                        }]}
                    >
                        <InputNumber
                            disabled={!allowEditing}
                            decimalSeparator={getDecimalSeparator()}
                            type="number"
                            placeholder="-"
                            className="custom-table-input"
                            value={value as number | undefined}
                        // onChange={(e) =>
                        //     onValueChange(parameter_id, "value", e.target.value)
                        // }
                        />
                    </Form.Item>
                );

            if (type[1] === "range")
                return (
                    <div className="range-container" style={{ display: "flex", gap: "0.5rem" }}>
                        <Form.Item
                            name={[index, "value"]}
                            style={{ width: "100%" }}
                            rules={[{
                                required: true,
                                message: t("common.requiredInfo"),
                            },
                            {
                                validator: async () => {
                                    await validateRange(type[1], form.getFieldValue(["parameters", index, "value"]), form.getFieldValue(["parameters", index, "value_max"]));
                                }
                            }
                            ]}>
                            <InputNumber
                                disabled={!allowEditing}
                                decimalSeparator={getDecimalSeparator()}
                                type="number"
                                placeholder="-"
                                className="custom-table-input"
                                value={Array.isArray(value) ? value[0] : undefined}
                            // onChange={(e) =>
                            //     onToupleChange(parameter_id, "value", e.target.value, 0)
                            // }
                            />
                        </Form.Item>
                        <Form.Item
                            name={[index, "value_max"]}
                            style={{ width: "100%" }}
                            rules={[{
                                required: true,
                                message: t("common.requiredInfo"),
                            }, {
                                validator: async () => {
                                    await validateRange(type[1], form.getFieldValue(["parameters", index, "value"]), form.getFieldValue(["parameters", index, "value_max"]));
                                }
                            }


                            ]}>

                            <InputNumber
                                disabled={!allowEditing}
                                decimalSeparator={getDecimalSeparator()}
                                type="number"
                                placeholder="-"
                                className="custom-table-input"
                                value={Array.isArray(value) ? value[1] : undefined}
                            // onChange={(e) =>
                            //     onToupleChange(parameter_id, "value", e.target.value, 1)
                            // }
                            />
                        </Form.Item>
                    </div>
                );

            if (type[1] === "operator")
                return (
                    <div className="range-container" style={{ display: "flex", gap: "0.5rem" }}>
                        <Form.Item
                            name={[index, "operator"]}
                            style={{ width: "100%" }}
                            rules={[{
                                required: true,
                                message: t("common.requiredInfo"),
                            }]}>
                            <Select
                                showSearch
                                filterOption={(inputValue, options: any) =>
                                    getDropdownFilteredValue(inputValue, options)
                                }
                                disabled={!allowEditing}
                                placeholder="-"
                                className="custom-table-input"
                                value={value as string | undefined}
                                // onChange={(v) => onToupleChange(parameter_id, "value", v, 0)}
                                options={operatorOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            name={[index, "value"]}
                            style={{ width: "100%" }}
                            rules={[{
                                required: true,
                                message: t("common.requiredInfo"),
                            },
                            ]}>
                            <InputNumber
                                disabled={!allowEditing}
                                decimalSeparator={getDecimalSeparator()}
                                type="number"
                                placeholder="-"
                                className="custom-table-input"
                                value={Array.isArray(value) ? value[1] : undefined}
                            // onChange={(e) =>
                            //     onToupleChange(parameter_id, "value", e.target.value, 1)
                            // }
                            />
                        </Form.Item>
                    </div>
                );
        } else if (type[0] === "categorical") {
            if (type[1] === "single")
                return (
                    <Form.Item
                        name={[index, "value_str"]}
                        style={{ width: "100%" }}
                        rules={[{
                            required: true,
                            message: t("common.requiredInfo"),
                            transform: (val: any) => {
                                return val?.trim()
                            }
                        }]}>
                        <Input
                            disabled={!allowEditing}
                            placeholder="-"
                            className="custom-table-input"
                            value={value as string | undefined}
                        // onChange={(e) =>
                        //     onValueChange(parameter_id, "value", e.target.value)
                        // }
                        />
                    </Form.Item>
                );
        }
    }, [allowEditing, getDecimalSeparator, t, form]);

    const renderUnitFieldByType = useCallback((parameter_id: string, type: string[], value: string, index: number) => {
        return (
            <Form.Item
                name={[index, "unit"]}
                style={{
                    maxWidth: "100px",
                }}
                rules={[{
                    required: true,
                    message: t("common.requiredInfo"),
                }]}>
                <Select
                    disabled={!allowEditing}
                    placeholder="-"
                    className="custom-table-input"
                    value={value}
                    showSearch
                    filterOption={(inputValue, options: any) =>
                        getDropdownFilteredValue(inputValue, options)
                    }
                    onChange={(unitValue) => {
                        const parameters = JSON.parse(JSON.stringify(form.getFieldValue("parameters")))
                        parameters[index] = {
                            ...parameters[index],
                            unit: unitValue,
                        }
                        form.setFieldValue("parameters", parameters)
                    }}
                    options={getParameterUnitOptions(parameter_id)}
                    
                    popupMatchSelectWidth={150}
                />
            </Form.Item>
        );
    }, [allowEditing, form, t, getParameterUnitOptions])

    return (
        <Modal
            title={`${propertyValueModal.mode === "create" ? t("common.addPropertyValue") : t("common.editPropertyValue")} ${t("common.for")} ${propertyValueModal?.data?.property_name}`}
            width={800}
            open={propertyValueModal.isModalVisible}
            footer={null}
            onCancel={() => {
                setPropertyValueModal({
                    mode: "create",
                    isModalVisible: false,
                    data: null
                })
            }}>
            <Spin
                spinning={propertyUnitsStatus === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
            >
                <Form
                    layout="vertical"
                    className='property__value__form'
                    form={form}
                    onFinish={handlePropertyValueFormSubmit}
                    initialValues={{
                        value_type: propertyValue.value_type,
                        value_subtype: propertyValue.value_subtype,
                        method_name: "-"
                    }}>
                    <Form.Item
                        label={t("common.property_value_type")}
                        name={"value_type"}
                        rules={[{
                            required: true,
                            message: t("common.requiredInfo"),
                        }]}
                    >
                        <Select
                            showSearch
                            filterOption={(inputValue, options: any) =>
                                getDropdownFilteredValue(inputValue, options)
                            }
                            onChange={(value) => {
                                const formValues = JSON.parse(JSON.stringify(form.getFieldsValue()))
                                const newData = { ...formValues, value_type: value, value_subtype: "single", unit: null, value: null, value_max: null, value_str: null, operator: null }
                                if (propertyValueModal.mode === "create" && propertyUnitsData?.default_unit && value === "numerical") newData.unit = propertyUnitsData?.default_unit;
                                form.setFieldsValue(newData)
                                setPropertyValue({
                                    value_type: value,
                                    value_subtype: "single",
                                    method_name: newData?.method_name ?? "-"
                                })
                            }}>
                            <Select.Option value="numerical">{t("common.numerical")}</Select.Option>
                            <Select.Option value="categorical">{t("common.categorical")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={null} name={"value_subtype"}>
                        {propertyValue.value_type === "numerical" ?
                            <Radio.Group
                                onChange={(e) => {
                                    form.setFieldValue("value_str", null)
                                    form.setFieldValue("value", null)
                                    form.setFieldValue("value_max", null)
                                    setPropertyValue((prev) => {
                                        return {
                                            ...prev,
                                            value_subtype: e.target.value
                                        }
                                    })
                                }}>
                                <Radio value={"single"}>{t("common.singleValue")}</Radio>
                                {/* <Radio value={"select"}>Select type</Radio> */}
                                <Radio value={"range"}>{t("common.range")}</Radio>
                                <Radio value={"operator"}>{t("common.operator")}</Radio>
                            </Radio.Group>
                            :
                            <Radio.Group
                                onChange={(e) => {
                                    form.setFieldValue("value", null)
                                    setPropertyValue((prev) => {
                                        return {
                                            ...prev,
                                            value_subtype: e.target.value
                                        }
                                    })
                                }}
                            >
                                <Radio value={"single"}>{t("common.singleValue")}</Radio>
                            </Radio.Group>
                        }
                    </Form.Item>
                    <div>
                        <Table dataSource={dataSource} columns={columns} pagination={false} rowClassName={"parameter__value__container"} bordered={false} />
                    </div>
                    <div style={{ display: "flex", width: "100%", gap: "1rem", alignItems: "center" }}>
                        <Form.Item label={t("common.method")} name={"method_name"}
                            rules={[{
                                required: true,
                                message: t("common.requiredInfo"),
                                transform: (val: any) => {
                                    return val?.trim()
                                }
                            }]}
                        >
                            <AutoComplete
                                style={{ width: 200 }}
                                dropdownRender={(menu) => {
                                    return (
                                        <Spin spinning={propertyMethodsListStatus[propertyValueModal.data.property_id] === AsyncStates.LOADING}>
                                            {menu}
                                        </Spin>
                                    )
                                }}
                                options={autoCompleteMethodOptions}
                                onChange={(e: any) => {
                                    setOpenAddParameterPopover(false)
                                    const trimmedValue = e.trim()
                                    setPropertyValue((prev) => {
                                        return {
                                            ...prev,
                                            method_name: trimmedValue.length >= 1 ? trimmedValue : prev.method_name
                                        }
                                    })

                                    if (trimmedValue === "-") {
                                        const parameterData = propertyMethods.default_parameters
                                        const filteredParameters = parameterData.filter((parameter: any) => parameter?.is_default)
                                        setParameterDataInForm(filteredParameters)
                                    }
                                    const parameters = propertyMethods.methods.find((method) => method.method_name.trim() === trimmedValue)?.parameters
                                    if (!!parameters?.length) {
                                        setParameterDataInForm(parameters)
                                    }

                                }}
                                filterOption={(inputValue, option) => {
                                    return option!.label?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1 || inputValue === '-'
                                }}
                            />
                        </Form.Item>

                        <Form.Item name="standard"
                            label={t("common.standard")}
                            >
                            <Select
                                allowClear
                                style={{ width: 200 }}
                                loading={loadingStandards}
                                showSearch
                                optionFilterProp="label"
                                filterOption={(inputValue, options: any) =>
                                    getDropdownFilteredValue(inputValue, options)
                                }
                                options={standardOptions}
                                placeholder={t("common.select")}
                            />
                        </Form.Item>
                        <Form.Item name="specimen"
                            label={t("inventory.specimen")}
                            >
                            <Select
                                allowClear
                                style={{ width: 200 }}
                                loading={loadingStandards}
                                showSearch
                                optionFilterProp="label"
                                filterOption={(inputValue, options: any) =>
                                    getDropdownFilteredValue(inputValue, options)
                                }
                                options={specimenOptions}
                                placeholder={t("inventory.specimen")}
                            />
                        </Form.Item>
                    </div>

                    {/* <Form.Item label="" name={"parameters"}> */}
                    {/* <MethodParameters editable={(propertyValue.method_name === "-" || form.getFieldValue("method_name") === "") ? true : false} rawTableData={rawTableData} setRawTableData={setRawTableData} parameters={parameters} form={form} /> */}
                    {/* </Form.Item> */}
                    <Form.List
                        name={"parameters"}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                <StyledCard
                                    bordered={false}
                                    headStyle={{
                                        minHeight: "max-content",
                                        padding: "1rem 0rem",
                                    }}
                                    bodyStyle={{
                                        padding: "0.5rem",
                                    }}
                                    title={
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1rem",
                                                    width: "100%",
                                                }}
                                            >
                                                <Text className="highlighted">{t("common.parameters")} ({fields.length})</Text>
                                                <ParameterDropdown
                                                    selectedParameters={selectedParameters ?? []}
                                                    setSelectedParameters={setSelectedParameters}
                                                    parameters={parametersList}
                                                    form={form}
                                                    allowEditing={true}
                                                    open={openAddParameterPopover}
                                                    setOpen={setOpenAddParameterPopover}
                                                    showAddPropertyButton={false}
                                                />
                                            </div>
                                        </div>
                                    }
                                >
                                    <Row
                                        gutter={2}
                                        style={{ paddingBottom: "5px" }}
                                    >
                                        <Col span={9}>
                                            <Text style={{ fontWeight: "bold" }}>{t("common.parameter")}</Text>
                                        </Col>
                                        <Col span={7}>
                                            <Text style={{ fontWeight: "bold" }}>{t("common.type")}</Text>
                                        </Col>
                                        <Col span={8}>
                                            <Text style={{ fontWeight: "bold" }}>{t("common.value")}</Text>
                                        </Col>
                                    </Row>
                                    {
                                        !fields.length ? <Empty description={t("common.noParameters")} /> :
                                            fields.map(({ key, name, ...restField }, index) => (
                                                <div key={key}>
                                                    <Row gutter={[4, 4]} >
                                                        <Col span={9}>
                                                            <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
                                                                <MinusCircleOutlined
                                                                    disabled={!allowEditing}
                                                                    onClick={() => {
                                                                        remove(name);
                                                                        setSelectedParameters((prev) => {
                                                                            return prev.filter((p, index) => index !== name)
                                                                        })
                                                                    }}
                                                                />
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "parameter_id"]}
                                                                    style={{ marginRight: 8, width: "100%" }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t("common.categoryRequired"),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        filterOption={(inputValue, options: any) =>
                                                                            getDropdownFilteredValue(inputValue, options)
                                                                        }
                                                                        disabled={!allowEditing}
                                                                        style={{ width: "100%" }}
                                                                        className="custom-table-input"
                                                                        placeholder={t("common.select")}
                                                                        onChange={(value) => {
                                                                            setSelectedParameters((prev: string[]) => {
                                                                                const newState = [...prev]
                                                                                newState[name] = value
                                                                                return newState
                                                                            })
                                                                        }}
                                                                        options={parametersList?.map((p: any) => ({
                                                                            label: p.parameter,
                                                                            value: p.parameter_id,
                                                                            disabled: selectedParameters.includes(p.parameter_id)
                                                                        }))}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                        <Col span={7}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "type"]}
                                                                style={{ marginRight: 8 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t("common.typeRequired"),
                                                                    },
                                                                ]}
                                                            >
                                                                {typeSelection(form.getFieldValue("parameters")?.[name]?.parameter_id, form.getFieldValue("parameters")?.[name]?.type, name)}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                            {
                                                                !!form.getFieldValue("parameters")?.[name].type?.length &&
                                                                <div style={{
                                                                    display: "flex",
                                                                    gap: "0.5rem",
                                                                    padding: "0rem 0.25rem",
                                                                    width: "100%"
                                                                }}>
                                                                    {renderFormFieldByType(name, form.getFieldValue("parameters")?.[name]?.parameter_id, form.getFieldValue("parameters")?.[name]?.type ?? [], form.getFieldValue("parameters")?.[name]?.value ?? null)}
                                                                    {renderUnitFieldByType(form.getFieldValue("parameters")?.[name]?.parameter_id, form.getFieldValue("parameters")?.[name]?.type ?? [], form.getFieldValue("parameters")?.[name]?.unit ?? null, name)}
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))
                                    }
                                </StyledCard>
                            </>
                        )}
                    </Form.List>

                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem", padding: "0.25rem 0rem" }}>
                        <Form.Item>
                            <StyledButton
                                danger
                                onClick={() => {
                                    setPropertyValue({
                                        value_type: "numerical",
                                        value_subtype: "single",
                                        method_name: "-"
                                    })
                                    form.resetFields()
                                    form.setFieldValue("value_type", "numerical")
                                }}
                            >{t("common.reset")}</StyledButton>
                        </Form.Item>
                        <Form.Item>
                            <StyledButton htmlType='submit' type="primary">{t("common.save")}</StyledButton>
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        </Modal >
    )
}


type GetFieldItemProps = {
    formType: string,
    formItemType: string,
    t: any,
    getDecimalSeparator: () => string,
    form: any,
}

const GetFieldItem = ({ formType, formItemType, t, getDecimalSeparator, form }: GetFieldItemProps) => {
    const commonProps = {
        name: 'value', rules: [{
            required: true,
            message: t("common.requiredInfo"),
        }]
    };

    switch (formType) {
        case 'numerical':
            switch (formItemType) {
                case 'single':
                    return (
                        <Form.Item {...commonProps}
                            style={{ width: "50%" }}>
                            <InputNumber
                                decimalSeparator={getDecimalSeparator()}
                            />
                        </Form.Item>
                    );
                case 'select':
                    return (
                        <Form.Item {...commonProps}>
                            <Select>
                                <Select.Option value="lucy">Lucy</Select.Option>
                            </Select>
                        </Form.Item>
                    );
                case 'range':
                    return (
                        <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
                            <Form.Item {...commonProps} name={"value"}
                                rules={[{
                                    required: true,
                                    message: t("common.requiredInfo"),
                                }, {
                                    validator: async () => {
                                        await validateRange("range", form.getFieldValue("value"), form.getFieldValue("value_max"));
                                    }
                                }]}

                            >
                                <InputNumber placeholder='Enter a Value'

                                    decimalSeparator={getDecimalSeparator()}
                                />
                            </Form.Item>
                            <Form.Item {...commonProps} name={"value_max"}
                                rules={[{
                                    required: true,
                                    message: t("common.requiredInfo"),
                                }, {
                                    validator: async () => {
                                        await validateRange("range", form.getFieldValue("value"), form.getFieldValue("value_max"));
                                    }
                                }]}
                            >
                                <InputNumber placeholder='Enter a Value'
                                    decimalSeparator={getDecimalSeparator()}
                                />
                            </Form.Item>
                        </div>

                    );
                case 'operator':
                    return (
                        <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
                            <Form.Item {...commonProps} name={"operator"} style={{ width: "50%" }}>
                                <Select
                                    showSearch
                                    filterOption={(inputValue, options: any) =>
                                        getDropdownFilteredValue(inputValue, options)
                                    }
                                    options={operatorOptions}
                                    placeholder="Select An Operator"
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item {...commonProps} style={{ width: "50%" }}>
                                <InputNumber
                                    decimalSeparator={getDecimalSeparator()}
                                />
                            </Form.Item>
                        </div>
                    );
                default:
                    break;
            }
            break;

        case 'categorical':
            return (
                <Form.Item
                    {...commonProps}
                    name="value_str"
                    rules={[{
                        required: true,
                        message: t("common.requiredInfo"),
                        transform: (val: any) => {
                            return val?.trim()
                        }
                    }]}
                >
                    <Input type="text" placeholder="Enter a Value" />
                </Form.Item>
            );

        default:
            break;
    }
    return <></>
}