import { Table } from "antd";
import React, { FC } from "react";

const MaskTable: FC<any> = ({ data }: any) => {
  const dataWithKeys: any = () =>
    data?.mask_results?.map((item: any, index: any) => ({ key: index, ...item }));

  const isCalibrated = data?.mask_results?.[0]?.calibrated_area?.value;

  const extractNumericValue = (value: any) => {
    return value.replace(/[^0-9.]/g, "").trim()
  }

  const columns = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (item: any) => <>{item?.replace("mask_id_", "Mask ")}</>,
      width: "10%",
    },
    {
      title: "Area",
      dataIndex: isCalibrated ? "calibrated_area" : "pixel_area",
      key: isCalibrated ? "calibrated_area" : "pixel_area",
      render: (item: any) => <>{item?.value || `${item} px²`}</>,
      width: "20%",
      sorter: {
        compare: (a: any, b: any) => {
          return isCalibrated
            ? extractNumericValue(a.calibrated_area.value) - extractNumericValue(b.calibrated_area.value)
            : a.pixel_area - b.pixel_area
        }
      },
    },
    {
      title: "Length",
      dataIndex: isCalibrated ? "calibrated_length" : "max_length",
      key: isCalibrated ? "calibrated_length" : "length",
      render: (item: any) => <>{item?.value || `${item?.pixel_length} px`}</>,
      width: "20%",
      sorter: {
        compare: (a: any, b: any) =>
          isCalibrated
            ? extractNumericValue(a.calibrated_length.value) - extractNumericValue(b.calibrated_length.value)
            : a.max_length.pixel_length - b.max_length.pixel_length,
      },
    },
    {
      title: "Angle",
      dataIndex: "max_length",
      key: "angle",
      render: (item: any) => <>{`${item?.angle ?? item.angle}°`}</>,
      width: "50%",
      sorter: {
        compare: (a: any, b: any) => a.max_length.angle - b.max_length.angle,
      },
    },
  ];

  return <Table dataSource={dataWithKeys()} columns={columns} />;
};

export default React.memo(MaskTable);
