import React, { ChangeEvent, createRef, useCallback, useEffect, useMemo, useState } from "react"
import { StoreState } from "../../../store/configureStore"
import "./Compare.scss"
import { Space, Card, Select, Empty, Input, Row, Form, Col, Checkbox, Tooltip } from "antd";
import {
	CloseOutlined,
	UpOutlined,
	DownOutlined,
	MinusCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import useTranslate from "src/utils/useTranslate"
import { colorCodes } from 'src/components/Formulation/Compare/ZeonCompare/zeon-chart/utils'
import { useValue } from "src/utils/useValue"
import { isValidNumber } from "src/utils/decorator"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import { StyledButton } from "src/styled_components/StyledButton"
import { AsyncStates } from "src/constants"
import { fetchAllMethodsRequest } from "src/store/actions/repository"
import InventoryUtils from "src/modules/InventoryV2/utils"
const { Option } = Select

export const Chart = React.memo(
	({
		allPlots,
		plot,
		setPlots,
		formulationList,
		chartIndex,
		isChartLoading
	}: {
		setPlots: any
		plot: any
		allPlots: any
		formulationList: any,
		chartIndex: number,
		isChartLoading: boolean
	}) => {
		const { id } = plot
		const { getValue, convertValue } = useValue()
		const requiredFieldStar = useRequiredFieldStar()
		const isSidebarCollapsed = useSelector((state: StoreState) => state.sidebar.collapsed)
		useEffect(() => {
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, plotType: "bar", description: "" } : plot
				)
			)
		}, [id, setPlots])
		const propertyLabels = useSelector((state: StoreState) => state.displayNames?.data?.properties || {})

		const dispatch = useDispatch();
		const allMethodList = useSelector((state: StoreState) => state.repository.allMethods.data)
		const allMethodStatus = useSelector((state: StoreState) => state.repository.allMethods.status)
	
		
		useEffect(() => {
			if(allMethodStatus === AsyncStates.INITIAL){
				dispatch(fetchAllMethodsRequest())
			}
		}, [allMethodStatus, dispatch])

		const [selectedTrials, setSelectedTrials] = useState<any[]>(
			plot.selectedTrials || []
		)
		const [xLabels, setxLabels] = useState<any[]>([])
		const [selectedPropertySets, setSelectedPropertySets] = useState<any[]>(
			plot.selectedPropertySets || []
		)
		const [description, setDescription] = useState<string>(
			plot.description || ""
		)
		const [title, setTitle] = useState<string>(plot.title || "")
		const [xLabel, setxLabel] = useState<string>(plot.xLabel || "")
		const [colorEntries, setColorEntries] = useState<{ [key: string]: string }>({})

		const highchartsRef = useMemo(() => Array.from({ length: allPlots.length }).map(() => createRef<HighchartsReact.RefObject>()), [allPlots.length])

		const redraw = useCallback(() => {
			if (highchartsRef.length) {
				for (let i = 0; i < highchartsRef.length; i++) {
					highchartsRef?.[i]?.current?.chart?.reflow()
				}
			}
		}, [highchartsRef])

		useEffect(() => {
			redraw()
		}, [isSidebarCollapsed, redraw])

		const [t] = useTranslate()
		const stages = [
			{
				key: "processing",
				translation: t("common.processing"),
			},
			{
				key: "properties",
				translation: t("common.properties"),
			},
			{
				key: "ingredients",
				translation: t("common.ingredients"),
			},
			{
				key: "characterizations",
				translation: t("common.characterizations"),
			},
		]
		const [optionalSelectedData, setOptionalSelectedData] = useState<any[]>([])
		const [stateData, setStateData] = useState<any[]>([])

		const [form] = Form.useForm()
		const displayNames = useSelector(
			(state: StoreState) => state.displayNames.data
		)
		const linkedFormulationDetailsData = useSelector(
			(state: StoreState) =>
				state.compareFormulations.linkedFormulationDetailsData
		)

		const numericalIngredientList = useMemo(() => {
			const data = Array.from(
				new Set(
					formulationList.flatMap(({ ingredients }: any) => Object.keys(ingredients || {}))
				)
			).filter((key: any) => formulationList.some(({ ingredients }: any) => {
				return typeof convertValue(ingredients?.[key]?.value) === "number"
			}))
			return data
		}, [formulationList, convertValue])

		const getPropertyLabel = useCallback((propertyId: any) => {
			const displayNamesProperty = propertyLabels?.[propertyId];
			const method = allMethodList?.find(
			  (method) => method.method_id === displayNamesProperty?.method_id
			);
			return !!method
			  ? InventoryUtils.getNameWithParameters(
				  displayNamesProperty?.name,
				  method.parameters,
				  getValue
				)
			  : InventoryUtils.getNameWithoutParameters(displayNamesProperty);
		  }, [allMethodList, getValue, propertyLabels]);

		useEffect(() => {
			setOptionalSelectedData(
				stateData
					?.flatMap((_data: any) => _data?.selectedData)
					?.map((ele: any) => ele.value)
			)
		}, [formulationList, selectedTrials, stateData])

		useEffect(() => {
			setxLabels(selectedTrials.map((propertyId) => propertyId))
		}, [selectedTrials, formulationList])

		const setRandomColor = useCallback((nameType: string) => {
			if (!colorEntries?.[nameType]) {
				const usedColors = Object.values(colorEntries)
				const uniqueColors = colorCodes.filter((color) => !usedColors.includes(color))
				setColorEntries((prev) => {
					return {
						...prev,
						[nameType]: uniqueColors[0]
					}
				})
			}
		}, [colorEntries])

		useEffect(() => {
			let graphsData: any
			let withOptionalGraphData = stateData?.map(
				({ type, selectedData, plotype }: any, index: any) => {
					if (type === "ingredients") {
						const values = selectedData?.map((plotSelection: any) => {
							const nameType = plotSelection?.value
							setRandomColor(nameType)
							return {
								name:
									linkedFormulationDetailsData?.find(
										({ id_set }: any) => id_set.formulation_id === nameType
									)?.meta?.display_id ?? displayNames[type]?.[nameType]?.name ?? nameType,
								marker: {
									fillColor: colorEntries[nameType]
								},
								color: colorEntries[nameType],
								data: !!selectedTrials?.length
									? formulationList
										?.filter((formulation: any) =>
											selectedTrials?.includes(formulation?.id_set?.formulation_id)
										).sort((a: any, b: any) => {
											const aIndex = selectedTrials.indexOf(a?.id_set?.formulation_id);
											const bIndex = selectedTrials.indexOf(b?.id_set?.formulation_id);
											return aIndex - bIndex;
										})?.flatMap((currentFormulation: any) =>
											currentFormulation[type]?.[nameType]?.value !== undefined
												? {
													y: getChartPointValue(convertValue(currentFormulation[type]?.[nameType]?.value)) ?? null,
													unit: currentFormulation[type]?.[nameType]?.unit ?? null,
													woname: currentFormulation.work_order_name,
													stage_name: currentFormulation.stage_name,
													stage: currentFormulation.stage,
													color: colorEntries[nameType],
												}
												: null
										)
									:
									// convertValue(formulationList?.flatMap((currentFormulation: any) => currentFormulation[type]?.[nameType]?.value)) ?? null,
									formulationList?.flatMap((currentFormulation: any) =>
										currentFormulation[type]?.[0]?.[type]?.[nameType]?.value !== undefined
											? {
												y: getChartPointValue(convertValue(currentFormulation[type]?.[0]?.[type]?.[nameType]?.value)) ?? null,
												unit: currentFormulation[type]?.[0]?.[type]?.[nameType]?.unit ?? null,
												woname: currentFormulation.work_order_name,
											}
											: null),
								yAxis: index,
								type: plotype ?? "column",
								chartType: "ingredients",
								unit: [
									...new Set(
										!!selectedTrials?.length
											? formulationList
												?.filter((formulation: any) =>
													selectedTrials?.includes(
														formulation?.id_set?.formulation_id
													)
												).sort((a: any, b: any) => {
													const aIndex = selectedTrials.indexOf(a?.id_set?.formulation_id);
													const bIndex = selectedTrials.indexOf(b?.id_set?.formulation_id);
													return aIndex - bIndex;
												})?.flatMap((currentFormulation: any) =>
													currentFormulation[type]?.[nameType]?.unit !== undefined
														? currentFormulation[type]?.[nameType]?.unit
														: null
												)
											: formulationList?.flatMap((currentFormulation: any) =>
												currentFormulation[type]?.[nameType]?.unit !== undefined
													? currentFormulation[type]?.[nameType]?.unit
													: null
											) ?? null
									),
								],
								zIndex: plotype === "line" && 1000,
							}
						})
						return values ?? []
					} else {
						const values: any = selectedData?.map((plotSelection: any) => {
							const nameType = plotSelection?.value
							setRandomColor(nameType)
							return {
								name: type === 'properties' ? getPropertyLabel(nameType): displayNames[type]?.[nameType]?.name ?? nameType,
								marker: {
									fillColor: colorEntries[nameType]
								},
								color: colorEntries[nameType],
								data: !!selectedTrials?.length
									? formulationList
										?.filter((formulation: any) =>
											selectedTrials?.includes(formulation?.id_set?.formulation_id)
										).sort((a: any, b: any) => {
											const aIndex = selectedTrials.indexOf(a?.id_set?.formulation_id);
											const bIndex = selectedTrials.indexOf(b?.id_set?.formulation_id);
											return aIndex - bIndex;
										})?.flatMap((currentFormulation: any) =>
											currentFormulation[type][0]?.[type]?.[nameType]?.value !== undefined
												? {
													y: getChartPointValue(convertValue(currentFormulation[type]?.[0]?.[type]?.[nameType]?.value)) ?? null,
													unit: currentFormulation[type]?.[0]?.[type]?.[nameType]?.unit ?? null,
													woname: currentFormulation.work_order_name,
													stage_name: currentFormulation.stage_name,
													stage: currentFormulation.stage,
													color: colorEntries[nameType],
												}
												: null
										)
									: formulationList?.flatMap((currentFormulation: any) =>
										currentFormulation[type]?.[0]?.[type]?.[nameType]?.value !== undefined
											? {
												y: getChartPointValue(convertValue(currentFormulation[type]?.[0]?.[type]?.[nameType]?.value)) ?? null,
												unit: currentFormulation[type]?.[0]?.[type]?.[nameType]?.unit ?? null,
												woname: currentFormulation.work_order_name,
											}
											: null
									),
								yAxis: index,
								type: plotype ?? "column",
								chartType: type,
								unit: [
									...new Set(
										!!selectedTrials?.length
											? formulationList
												?.filter((formulation: any) =>
													selectedTrials?.includes(
														formulation?.id_set?.formulation_id
													)
												).sort((a: any, b: any) => {
													const aIndex = selectedTrials.indexOf(a?.id_set?.formulation_id);
													const bIndex = selectedTrials.indexOf(b?.id_set?.formulation_id);
													return aIndex - bIndex;
												})?.flatMap((currentFormulation: any) =>
													currentFormulation[type]?.[0]?.[type]?.[nameType]?.unit !== undefined ? currentFormulation[type]?.[0]?.[type]?.[nameType]?.unit : null
												)
											: formulationList?.flatMap((currentFormulation: any) =>
												currentFormulation[type]?.[0]?.[type]?.[nameType]?.unit !== undefined ? currentFormulation[type]?.[0]?.[type]?.[nameType] : null
											)
									),
								],
								zIndex: plotype === "line" && 1000,
							}
						})
						return values ?? []
					}
				}
			)
			if (withOptionalGraphData !== undefined) {
				graphsData = withOptionalGraphData?.flat()
			}

			const plotData: any = {
				chart: {
					renderTo: "plot" + id,
					height: description ? "70%" : "50%",
				},
				title: {
					text: title,
				},
				caption: {
					text: description,
					style: {
						fontSize: "14",
						color: "#000000",
						fontFamily: "Arial, Times New Roman"
					}
				},

				xAxis: {
					title: {
						text: xLabel,
					},
					categories: selectedTrials.map((trial_id) => formulationList.find((formulations: any) => formulations.id_set.formulation_id === trial_id)?.meta.display_id),
				},
				yAxis:
					graphsData[graphsData?.length - 1] === undefined
						? {}
						: stateData?.map(({ label }, key: any) => ({
							title: {
								text: label,
							},
							opposite: key % 2 === 0 ? false : true,
						})),

				legend: {
					layout: "horizontal",
					align: "center",
					verticalAlign: "bottom",
					x: 0,
					y: 0,
					labelFormatter: function () {
						let unit =
							(this as any)?.userOptions?.unit?.[0] !== null
								? (this as any)?.userOptions?.unit?.[0]
								: (this as any)?.userOptions?.unit?.[1]
						return `${(this as any).name} ${!!unit ? `(${unit})` : ""}`
					},
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					useHTML: true,
					headerFormat: `<b>{point.x}</b> <br/>`,
					pointFormatter: function () {
						const point = this as any
						return `<b>${point.stage_name}<br/><b>Work Order : ${point.woname}</b><br/>${point.series.name} : ${getValue(point.y, 3)} ${point.unit ?? "-"}`
					}
				},
				plotOptions: {
					series: {
						dataLabels: {
							enabled: true,
							formatter: function () {
								const point = this as any
								return getValue(point.y, 3)
							},
							connectorShape: "crookedLine",
							crookDistance: "70%",
						},
						style: {
							fontWeight: "bold",
						},
					},
				},

				series: graphsData?.[graphsData?.length - 1] === undefined ? [] : graphsData,

				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 800,
							},
							chartOptions: {
								legend: {
									layout: "horizontal",
									align: "center",
									verticalAlign: "bottom",
								},
							},
						},
					],
				},
			}

			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, selectedPropertySets: plotData } : plot
				)
			)

			setSelectedPropertySets(plotData)
		}, [
			selectedTrials,
			formulationList,
			stateData,
			title,
			numericalIngredientList,
			propertyLabels,
			xLabels,
			id,
			displayNames,
			setPlots,
			xLabel,
			linkedFormulationDetailsData,
			setRandomColor,
			colorEntries,
			description,
			getValue,
			convertValue,
			getPropertyLabel
		])

		const selectPropertyIds = (value: any[]) => {
			setSelectedTrials(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, selectedTrials: value } : plot
				)
			)
		}

		const changeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
			const value = e.target.value.trim()
			setDescription(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, description: value } : plot
				)
			)
		}
		const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value.trim()
			setTitle(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, title: value } : plot
				)
			)
		}
		const changexLabel = (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value.trim()
			setxLabel(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, xLabel: value } : plot
				)
			)
		}

		const moveCardDown = () => {
			setPlots((prevState: any) => {
				const array = [...prevState]
				const element = array.filter((res: any) => res.id === id)[0]
				array[array.indexOf(element)] = array.splice(
					array.indexOf(element) + 1,
					1,
					array[array.indexOf(element)]
				)[0]
				return array
			})
		}

		const moveCardUp = () => {
			setPlots((prevState: any) => {
				const array = [...prevState]
				const element = array.filter((res: any) => res.id === id)[0]
				array[array.indexOf(element)] = array.splice(
					array.indexOf(element) - 1,
					1,
					array[array.indexOf(element)]
				)[0]
				return array
			})
		}

		const addStage = (value: string, key: any) => {
			const { plotGraph } = form?.getFieldsValue()
			const defaultValue = { type: value, selectedData: [], plottype: "column", label: null }
			Object.assign(plotGraph[key], defaultValue)
			form.setFieldsValue({ plotGraph })
			setStateData((prev) => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState[key] = defaultValue
				return newState
			})
		}

		const addData = (value: any, key: any) => {
			const selectedData = [...(stateData?.[key]?.selectedData ?? []), value]
			const { plotGraph } = form?.getFieldsValue()
			Object.assign(plotGraph[key], { selectedData: selectedData })
			form.setFieldsValue({ plotGraph })
			setStateData((prev) => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState[key].selectedData = selectedData
				return newState
			})
		}

		const removeParameterData = (removedParameterData: any, key: any) => {
			const { plotGraph } = form?.getFieldsValue()
			const selectedData = (plotGraph?.[key]?.selectedData ?? []).filter((parameter: any) => parameter.value !== removedParameterData.value)
			Object.assign(plotGraph[key], { selectedData })
			form.setFieldsValue({ plotGraph })
			setStateData((prev) => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState[key].selectedData = selectedData
				return newState
			})
		}

		const removeAllParameterData = (key: any) => {
			const { plotGraph } = form?.getFieldsValue()
			Object.assign(plotGraph[key], { selectedData: [] })
			form.setFieldsValue({ plotGraph })
			setStateData((prev) => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState[key].selectedData = []
				return newState
			})
		}

		const handleChartType = (value: string, key: any) => {
			const { plotGraph } = form.getFieldsValue()
			Object.assign(plotGraph[key], { plotype: value })
			form.setFieldsValue({ plotGraph })
			setStateData((prev) => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState[key].plotype = value
				return newState
			})
		}

		const handleChartLabel = (event: any, key: any) => {
			const { plotGraph } = form.getFieldsValue()
			Object.assign(plotGraph[key], { label: event.target.value })
			form.setFieldsValue({ plotGraph })
			setStateData((prev) => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState[key].label = event.target.value
				return newState
			})
		}

		const handleSelectPlaceHolderTranslations = (type: string) => {
			switch (type) {
				case 'ingredients': return t("aiEngine.customInsights.selectIngredients");
				case 'properties': return t("aiEngine.customInsights.selectProperties");
				case 'characterizations': return `${t('common.select')} ${t("formulations.characterizations")}`;
				case 'processing': return `${t("common.select")} ${t("formulations.type.processing")}`;
			}
		}

		const getDropDownList = useCallback((plotType: string) => {
			return formulationList.reduce((acc: any, curr: any) => {
				acc = acc.concat(
					Object.keys(
						curr?.[plotType][0]?.[plotType] || []
					)
				).filter((key: any) => formulationList.some((formulation: any) => !isNaN(convertValue(formulation?.[plotType]?.[0]?.[plotType]?.[key]?.value))))
				return [...new Set(acc)]
			}, [])?.filter((element: any) => !optionalSelectedData?.includes(element))
		}, [convertValue, formulationList, optionalSelectedData])

		const getChartPointValue = (pointValue: any) => {
			return isValidNumber(pointValue) ? pointValue : null
		}

		const trialsOptions = useMemo(() => {
			const result = formulationList.reduce((acc: any, curr: any) => {
				return {
					...acc, [curr.work_order_id]: formulationList.filter((formmulation: any) => formmulation.work_order_id === curr.work_order_id).sort((a: any, b: any) => {
						return a?.stage_name?.localeCompare(b?.stage_name)
					})
				}
			}, {})
			return Object.keys(result || {}).map((workOrderId) => ({
				label: result[workOrderId]?.[0]?.work_order_name ?? workOrderId,
				options: result[workOrderId]?.map((formulation: any) => ({
					label: <span>{formulation?.meta?.display_id} - <span style={{ color: "gray" }}>{formulation.stage_name ? formulation.stage_name : ""}</span></span>,
					value: formulation?.id_set?.formulation_id
				}))
			}))
		}, [formulationList])

		return (
			<Card
				size="small"
				type="inner"
				key={id}
				style={{ width: "100%" }}
				loading={isChartLoading}
				extra={[
					<Space>
						{" "}
						{allPlots?.indexOf(plot) !== 0 && (
							<StyledButton onClick={moveCardUp} icon={<UpOutlined />} />
						)}
						{allPlots?.indexOf(plot) !== allPlots?.length - 1 && (
							<StyledButton onClick={moveCardDown} icon={<DownOutlined />} />
						)}
						<StyledButton
							danger
							onClick={() =>
								setPlots((plots: any) =>
									plots.filter(({ id: _id }: any) => _id !== id)
								)
							}
							icon={<CloseOutlined />}
						/>
					</Space>,
				]}
			>
				<Space
					direction="vertical"
					size="small"
					style={{ width: "100%" }}
					id="charts"
				>
					<Space>
						<>
							<Checkbox
								checked={formulationList.length === selectedTrials.length}
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedTrials(
											formulationList.map(
												(formulation: any, index: any) =>
													formulation?.id_set?.formulation_id
											)
										)
										setPlots((plots: any) =>
											plots.map((plot: any) =>
												plot.id === id
													? {
														...plot,
														selectedTrials: formulationList.map(
															(formulation: any) =>
																formulation?.id_set?.formulation_id
														),
													}
													: plot
											)
										)
									} else {
										setSelectedTrials([])
										setPlots((plots: any) =>
											plots.map((plot: any) =>
												plot.id === id ? { ...plot, selectedTrials: [] } : plot
											)
										)
									}
								}}
							>
								{t("compare.selectAllTrials")}
							</Checkbox>
						</>
					</Space>

					<Select
						size="large"
						style={{ width: "100%" }}
						value={selectedTrials}
						onChange={selectPropertyIds}
						mode="multiple"
						options={trialsOptions}
						optionFilterProp="children"
						filterOption={(input: any, option: any) => {
							if (option.label && typeof option.label === "object" && !!option.label.props.children.length) {
								const { children } = option.label.props
								const trial = children[0] + children[1] + (typeof children[2] === "object" ? children[2]?.props?.children : "") || ""
								return trial.toLowerCase()?.includes(input?.toLowerCase())
							}
							return false
						}}
						placeholder={t("common.selectTrials")}
					>
					</Select>

					<Row gutter={12}>
						<Col span={12}>
							<Input
								placeholder={t("common.title")}
								value={title}
								onChange={changeTitle}
							/>
						</Col>
						<Col span={12}>
							<Input
								placeholder={t("chart.placeholder.xAxisLabel")}
								value={xLabel}
								onChange={changexLabel}
							/>
						</Col>
					</Row>
					<Input.TextArea
						placeholder={t("common.description")}
						value={description}
						onChange={changeDescription}
					/>

					<Form form={form} requiredMark={false}>
						<Form.List name="plotGraph">
							{(fields, { add, remove }, { errors }) => (
								<>
									{fields.map(({ key, name }) => (
										<Row justify="space-between" key={name}>
											<Col span={6}>
												<Form.Item
													rules={[{ required: true }]}
													name={[name, "type"]}
													required tooltip={requiredFieldStar}
												>
													<Select
														placeholder={t("common.forY-axis")}
														onChange={(e: any) => addStage(e, name)}
														style={{ width: 250 }}
													>
														{stages.map((ele) => (
															<Option value={ele.key}>{ele.translation}</Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col>
												<Form.Item name={[name, "selectedData"]}>
													<Space>
														{!!stateData[name]?.type &&
															stateData[name]?.type === "ingredients" && (
																<Select
																	style={{ width: 250 }}
																	mode="multiple"
																	optionFilterProp="label"
																	labelInValue={true}
																	allowClear
																	value={!!stateData[name]?.selectedData?.length ? stateData[name]?.selectedData : []}
																	onSelect={(e: any) => addData(e, name)}
																	onDeselect={e => removeParameterData(e, name)}
																	onClear={() => removeAllParameterData(name)}
																	placeholder={handleSelectPlaceHolderTranslations(stateData[name]?.type)}
																	options={numericalIngredientList?.filter((element: any) => !optionalSelectedData?.includes(element))
																		.map((ingredient: any, index: any) => ({
																			value: ingredient,
																			key: index,
																			label: displayNames[stateData[name]?.type]?.[ingredient]?.name || linkedFormulationDetailsData?.find(({ id_set }: any) => id_set.formulation_id === ingredient)?.meta?.display_id
																		}))
																	}
																/>
															)}
														{!!stateData[name]?.type &&
															stateData[name]?.type !== "ingredients" && (
																<Select
																	style={{ width: 250 }}
																	mode="multiple"
																	optionFilterProp="label"
																	labelInValue={true}
																	allowClear
																	value={!!stateData[name]?.selectedData?.length ? stateData[name]?.selectedData : []}
																	onSelect={(e: any) => addData(e, name)}
																	onDeselect={e => removeParameterData(e, name)}
																	onClear={() => removeAllParameterData(name)}
																	placeholder={handleSelectPlaceHolderTranslations(stateData[name]?.type)}
																	options={[...getDropDownList(stateData[name]?.type)?.map((identifier: any, index: any) => ({
																		value: identifier,
																		key: index,
																		label: stateData[name]?.type === 'properties' ? getPropertyLabel(identifier): displayNames[stateData[name]?.type]?.[identifier]?.name
																	}))]
																	}
																/>
															)}
													</Space>
												</Form.Item>
											</Col>

											<Col span={5}>
												<Form.Item name={[name, "plotype"]}>
													<Select
														defaultValue={"column"}
														placeholder="Select a Chart Type"
														onChange={(e: any) => handleChartType(e, name)}
														disabled={!stateData[name]?.type}
													>
														<Option value="column">{t("chartType.Bar")}</Option>
														<Option value="line">{t("chartType.Line")}</Option>
														<Option value="scatter">
															{t("chartType.Scatter")}
														</Option>
														<Option value="area">{t("chartType.Area")}</Option>
													</Select>
												</Form.Item>
											</Col>

											<Col span={4}>
												<Form.Item name={[name, "label"]}>
													<Input
														placeholder={t("chart.placeholder.enterYAxisLabel")}
														onChange={(e: any) => handleChartLabel(e, name)}
														disabled={!stateData[name]?.type}
													/>
												</Form.Item>
											</Col>
											<Col span={4.5}>
												<MinusCircleOutlined
													className="dynamic-delete-button"
													onClick={() => {
														remove(name)
														setStateData((prev) => prev.filter((_, plotIndex) => plotIndex !== name))
													}}
												/>
											</Col>
										</Row>
									))}

									<Space
										style={{
											display: "flex",
											justifyContent: "center",
											marginTop: 10,
										}}
									>
										{fields?.length < 4 && (
											<Tooltip title={!selectedTrials.length ? `Please Select At least One Trial` : null}>
												<Form.Item>
													<StyledButton
														type="dashed"
														onClick={() => {
															const { plotGraph } = form.getFieldsValue()
															const defaultValue = { type: null, selectedData: [], plottype: null, label: null }
															add(defaultValue, plotGraph?.length)
															setStateData((prev) => {
																const newState = JSON.parse(JSON.stringify(prev))
																newState[plotGraph?.length] = defaultValue
																return newState
															})
														}}
														style={{ margin: "auto", marginBottom: 10 }}
														size="large"
														icon={<PlusOutlined />}
														disabled={
															!!stateData?.length ? !stateData[stateData?.length - 1]?.type?.length ? true : !stateData[stateData?.length - 1]?.selectedData?.length && !!stateData[stateData?.length - 1]?.type : !selectedTrials.length ? true : false
														}
													>
														{t("chart.addYaxis")}
													</StyledButton>
													<Form.ErrorList errors={errors} />
												</Form.Item>
											</Tooltip>
										)}
									</Space>
								</>
							)}
						</Form.List>
					</Form>

					{!!selectedTrials.length && !!stateData.length ? (
						<Card style={{ marginTop: 20 }} className="graph-container">
							<HighchartsReact
								highcharts={Highcharts}
								options={selectedPropertySets}
								ref={highchartsRef?.[chartIndex]}
							/>
						</Card>
					) : (
						<Empty description={!selectedTrials.length ? t("chart.pleaseSelectTrial!") : "Please Select a Y-Axis"} />
					)}
				</Space>
			</Card >
		)
	}
)
