import React, { useEffect, useState } from "react";
import {
  Space,
  Spin,
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Typography,
  Modal,
  Tooltip,
  Tag,
  Row,
  Form,
  Select,
  Input,
  Popover,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDoeCleanup,
  deleteDoeRequest,
  doeHistoryListRequest,
  listAlgorithmsRequest,
} from "src/store/actions/doe";
import { StoreState } from "src/store/configureStore";
import {
  PlusOutlined,
  LoadingOutlined,
  FileTextOutlined,
  ContainerOutlined,
  SortDescendingOutlined,
  FilterOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { antdTheme, AsyncStates } from "src/constants";
import { useHistory } from "react-router-dom";
import {
  includeDoeExp,
  resetWorkOrder,
} from "src/store/actions/workOrderDetails";
import { motion } from "framer-motion";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";

const { Text } = Typography;
const { Option } = Select;

export function ExpHistory() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [t] = useTranslate();
  const requiredFieldStar = useRequiredFieldStar()

  const { listAlgorithmsStatus, listAlgorithmsData } = useSelector(
    (state: StoreState) => state.doe
  );
  const { doeHistoryListStatus, doeHistoryData, deleteDoeStatus } = useSelector(
    (state: StoreState) => state.doe
  );
  const teams = useSelector((state: StoreState) => state.teams?.data);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);
  const [deleteModalVsisble, setDeleteModalVisible] = useState<boolean>(false);
  const [woModalVsisble, setWoModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<any>({
    pageNum: currentPage,
    sort_by: "created",
    ascending: false,
  });
  const [sortVisible, setSortVisible] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);

  useEffect(() => {
    if (listAlgorithmsStatus === AsyncStates.INITIAL)
      dispatch(listAlgorithmsRequest());
  }, [dispatch, listAlgorithmsStatus]);

  const applyPagination = (pageNum: number) => {
    setSelectedRecords([]);
    setCurrentPage(pageNum);
    setFilters((prevState: any) => ({ ...prevState, pageNum }));
  };

  useEffect(() => {
    dispatch(doeHistoryListRequest(filters));
  }, [dispatch, currentPage, filters]);

  const dropDownOverlay = (record: any) => {
    return (
      <Menu>
        <Menu.Item key={1} style={{ color: "bule" }}>
          <Popconfirm
            cancelText={t("common.cancel")}
            placement="top"
            okText={t("common.ok")}
            title={
              <Space style={{ width: 300 }}>
                <Text>
                  {`${t("expHistory.createWO")}`}{" "}
                  <strong>{record.title}</strong> ?
                </Text>
              </Space>
            }
            onConfirm={() => {
              dispatch(includeDoeExp([record.doe_id]));
              push("/work-orders/create-workorder");
            }}
          >
            <StyledButton
              icon={<PlusOutlined />}
              style={{ color: "black" }}
              type="link"
            >
              {t("common.createWorkOrder")}
            </StyledButton>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item
          key={2}
          onClick={() => {
            setRecord(record);
            push(`/experiment-history/${record.doe_id}`)
            setFilters({
              pageNum: 1,
              sort_by: "created",
              ascending: false,
            });
          }}
        >
          <StyledButton
            icon={<FileTextOutlined />}
            style={{ color: "black" }}
            type="link"
          >
            {t("common.viewDetails")}
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={3}
          onClick={() => {
            setRecord(record);
            setDeleteModalVisible(true);
          }}
        >
          <StyledButton icon={<StyledDeleteIcon />} danger type="link">
            {t("common.delete")}
          </StyledButton>
        </Menu.Item>
        {!!record?.work_order_id?.length && (
          <Menu.Item
            key={4}
            onClick={() => {
              setRecord(record);
              setWoModalVisible(true);
            }}
          >
            <StyledButton icon={<ContainerOutlined />} type="link">
              {t("common.viewWorkOrders")}
            </StyledButton>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    if (deleteDoeStatus === AsyncStates.SUCCESS) {
      setSelectedRecords([]);
      notification.success({ message: t("doe.deletedSelectedExperiments") });
      dispatch(deleteDoeCleanup());
    }
  }, [deleteDoeStatus, dispatch, t]);

  const columns = [
    {
      dataIndex: "title",
      title: t("common.title"),
      key: "title",
      width: 150,
    },
    {
      dataIndex: "description",
      title: t("common.description"),
      key: "description",
      ellipsis: {
        showTitle: false,
      },
      render: (text: any) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      dataIndex: "algorithm",
      title: t("common.algorithm"),
      key: "algorithm",
      render: (text: any, record: any) =>
        text
          .split("_")
          .map((res: any) => res.charAt(0).toUpperCase() + res.slice(1))
          .join(" "),
    },
    {
      dataIndex: "created",
      title: t("common.date"),
      key: "created",
      render: (text: any, record: any) => new Date(text).toLocaleString(),
    },
    {
      dataIndex: "trials",
      title: t("common.noOfTrials"),
      key: "trials",
      width: 100,
    },
    {
      dataIndex: "user_name",
      title: t("aiEngine.user"),
      key: "user",
    },
    {
      dataIndex: "actions",
      title: t("common.actions"),
      key: "actions",
      width: 100,
      align: "center" as any,
      render: (text: any, record: any) => {
        return (
          <Dropdown key="more" overlay={() => dropDownOverlay(record)}>
            <StyledButton
              type="link"
              style={{
                border: "none",
                padding: 0,
                color:'black', 
                outline:'none'
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <MoreOutlined
                style={{
                  fontSize: antdTheme.fontSizeHeading3,
                  verticalAlign: "top",
                }}
              />
            </StyledButton>
          </Dropdown>
        );
      },
    },
  ];

  const onFilterChange = (key: any, value: any) => {
    setSelectedRecords([]);
    setCurrentPage(1);
    setFilters((prevState: any) => ({
      ...prevState,
      [key]: value,
      pageNum: 1,
    }));
  };
  const sortOverlay = () => (
    <Space size="large" direction="vertical" style={{ width: 300 }}>
      <Form requiredMark={false}>
        <Row justify="space-around">
          <Form.Item
            name="sort_on"
            rules={[{ required: true }]}
            initialValue={"created"}
            required tooltip={requiredFieldStar}
          >
            <Select
              placeholder={t("common.property")}
              optionFilterProp={"children"}
              onChange={(e) => onFilterChange("sort_by", e)}
            >
              <Option value="created">{t("common.date")}</Option>
              <Option value="user_id">{t("aiEngine.user")}</Option>
              <Option value="algorithm">{t("common.algorithm")}</Option>
              <Option value="title">{t("common.title")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="ascending"
            rules={[{ required: true }]}
            initialValue={"descending"}
            required tooltip={requiredFieldStar}
          >
            <Select
              placeholder="Order"
              onChange={(e) =>
                onFilterChange("ascending", e === "ascending" ? true : false)
              }
            >
              <Option value="ascending">
                {t("aiEngine.form.select.ascending")}
              </Option>
              <Option value="descending">
                {t("aiEngine.form.select.descending")}
              </Option>
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </Space>
  );

  const filtersOverlay = () => (
    <Space size="large" direction="vertical" style={{ width: 300 }}>
      <Form wrapperCol={{ span: 16 }} labelCol={{ span: 6 }}>
        <Form.Item name="title" label={t("common.title")}>
          <Input
            allowClear
            onChange={(e) => onFilterChange("title", e.target.value)}
          />
        </Form.Item>
        <Form.Item name="algorithms" label={t("common.algorithms")}>
          <Select
            mode="multiple"
            allowClear
            optionFilterProp={"children"}
            onChange={(e) => onFilterChange("algorithms", e)}
          >
            {Object.keys(listAlgorithmsData || {}).map((key: any) => (
              <Option value={key}>
                {key
                  .split("_")
                  .map((res: any) => res.charAt(0).toUpperCase() + res.slice(1))
                  .join(" ")}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="user_ids" label={t("common.createdBy")}>
          <Select
            mode="multiple"
            allowClear
            optionFilterProp={"children"}
            onChange={(e) => onFilterChange("user_ids", e)}
          >
            {teams.map((team: any) => (
              <Option value={team.user_id}>{team.user_name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Space>
  );

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <StyledPageHeader
            ghost={false}
            title={t("navBar.menuItem.experimentHistory")}
          />
          <Spin
            indicator={<LoadingOutlined />}
            spinning={
              doeHistoryListStatus === AsyncStates.LOADING ||
              deleteDoeStatus === AsyncStates.LOADING ||
              listAlgorithmsStatus === AsyncStates.LOADING
            }
          >
            <StyledCard
              title={`${doeHistoryData?.total || ""} ${t(
                "common.experiments"
              )}`}
              extra={
                <Space>
                  <Popover
                    style={{ zIndex: 5 }}
                    content={sortOverlay}
                    placement="bottomLeft"
                    title={
                      <Row justify="space-between">
                        <Text strong>{t("common.btn.sort")}</Text>
                        <CloseOutlined
                          onClick={() => setSortVisible(false)}
                          style={{ fontSize: antdTheme.fontSizeHeading5, cursor: "pointer" }}
                        />
                      </Row>
                    }
                    visible={sortVisible}
                  >
                    <StyledButton
                      type="primary"
                      ghost
                      icon={<SortDescendingOutlined />}
                      onClick={() => {
                        setFilterVisible(false);
                        setSortVisible(true);
                      }}
                    >
                      {t("common.btn.sort")}
                    </StyledButton>
                  </Popover>

                  <Popover
                    style={{ zIndex: 5 }}
                    content={filtersOverlay}
                    placement="bottomLeft"
                    title={
                      <Row justify="space-between">
                        <Text strong>{t("common.filters")}</Text>
                        <CloseOutlined
                          onClick={() => setFilterVisible(false)}
                          style={{ fontSize: antdTheme.fontSizeHeading5, cursor: "pointer" }}
                        />
                      </Row>
                    }
                    visible={filterVisible}
                  >
                    <StyledButton
                      type="primary"
                      ghost
                      icon={<FilterOutlined />}
                      onClick={() => {
                        setSortVisible(false);
                        setFilterVisible(true);
                      }}
                    >
                      {t("common.filters")}
                    </StyledButton>
                  </Popover>
                  <StyledButton
                    onClick={() => setDeleteModalVisible(true)}
                    disabled={!selectedRecords?.length}
                    icon={<StyledDeleteIcon />}
                    danger
                  >
                    {t("common.delete")}
                  </StyledButton>
                </Space>
              }
            >
              <Space
                size="large"
                direction="vertical"
                style={{ width: "100%", overflowX: "auto" }}
              >
                <Table
                  style={{ minWidth: 800 }}
                  bordered
                  columns={columns}
                  dataSource={doeHistoryData?.["DOE History"]?.map(
                    (res: any, index: any) => ({ ...res, key: index })
                  )}
                  rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selectedRecords.map((res: any) => res.key),
                    onChange: (
                      selectedRowKeys: React.Key[],
                      selectedRows: any[]
                    ) => {
                      setSelectedRecords(selectedRows);
                    },
                  }}
                  pagination={{
                    pageSize: 10,
                    current: currentPage,
                    total: doeHistoryData?.total,
                    showSizeChanger: false,
                    responsive: true,
                    onChange: (e) => applyPagination(e),
                  }}
                />
              </Space>
            </StyledCard>
          </Spin>
          <Modal
            title={t("common.confirmDelete")}
            visible={deleteModalVsisble}
            okText={t("common.delete")}
            cancelText={t("common.cancel")}
            onOk={() => {
              if (!!Object.keys(record).length) {
                setRecord({});
                dispatch(deleteDoeRequest({ doe_ids: [record.doe_id] }));
              } else {
                dispatch(
                  deleteDoeRequest({
                    doe_ids: selectedRecords.map((res: any) => res.doe_id),
                  })
                );
              }
              setDeleteModalVisible(false);
            }}
            onCancel={() => {
              setRecord({});
              setDeleteModalVisible(false);
            }}
          >
            {!!Object.keys(record).length ? (
              <>
                <Text type="secondary">{t("common.deleteConfirmation")} </Text>
                <Text strong>{record.title}? </Text>
                <Text type="secondary">{t("common.cannotUndoOperation")}</Text>
              </>
            ) : (
              <Space direction="vertical">
                <Text type="secondary">{t("doe.deleteExperiments")}</Text>
                {selectedRecords.map((res: any, index: any) => (
                  <Text strong>{`${index + 1}) ${res.title}`}</Text>
                ))}
                <Text type="secondary">{t("common.cannotUndoOperation")}</Text>
              </Space>
            )}
          </Modal>

          <Modal
            title={t(
              "expHistory.tooltip.workOrdersCreatedGeneratedExperiments"
            )}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            visible={woModalVsisble}
            footer={null}
            onCancel={() => {
              setRecord({});
              setWoModalVisible(false);
            }}
          >
            <Row>
              {record.work_order_name?.map((res: any, index: any) => (
                <Popconfirm
                  okText={t("common.ok")}
                  cancelText={t("common.cancel")}
                  onConfirm={() => {
                    dispatch(resetWorkOrder());
                    push(
                      `/work-orders/details/${record.work_order_id[index]}/`,
                      record.work_order_id[index]
                    );
                  }}
                  title={`${t("common.goToWorkOrder")} ${res}`}
                >
                  <Tag style={{ cursor: "pointer", marginBottom: 15 }}>
                    {res}
                  </Tag>
                </Popconfirm>
              ))}
            </Row>
          </Modal>
      </Space>
    </motion.div>
  );
}
