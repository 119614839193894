import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Drawer, Dropdown, List, MenuProps, Row, Select, Space, Typography } from 'antd';
import { Popconfirm } from 'antd/lib'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { reportDeleteRequest, reportsRetrieveRequest, templateListRequest } from 'src/store/actions/compareFormulations'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { Design } from './Design'
import { Tds } from './Tds'
import { Verification } from './Verification'

export const Reports = ({ currentProject }: any) => {
    const dispatch = useDispatch()
    const { reports: reportsList, retrieveStatus: reportsRetieveStatus, deleteStatus: reportsDeleteStatus, templateDeleteStatus, lists, createStatus, updateStatus } = useSelector((state: StoreState) => state.compareFormulations)

    const [type, setType] = useState("verification");
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [templateType, setTemplateType] = useState('Create New Template');
    const [createTemplate, setCreateTemplate] = useState(true)

    const getContent = useCallback(() => {
        if (type === "design") {
            return <Design templateType={templateType} templateCreation={[createTemplate, setCreateTemplate]} />
        }
        else if (type === "verification" || type === "verification-note") {
            return <Verification currentProject={currentProject} type={type} />
        }
        else if (type === "tds") {
            return <Tds />
        }
    }, [type, templateType, createTemplate, currentProject])

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e?.key !== "Null") setTemplateType(e?.key)
    };

    useEffect(() => {
        if (templateDeleteStatus === AsyncStates.SUCCESS) {
            setTemplateType("Create New Template")
        }
    }, [templateDeleteStatus])

    useEffect(() => {
        if (type === "design" || templateDeleteStatus === AsyncStates.SUCCESS) {
            if (templateDeleteStatus === AsyncStates.SUCCESS) {
                setTemplateType("Create New Template")
            }
            dispatch(templateListRequest())
        }
    }, [dispatch, templateDeleteStatus, type])

    useEffect(() => {
        if (type === "design" && (createStatus === AsyncStates.SUCCESS || updateStatus === AsyncStates.SUCCESS)) {
            dispatch(templateListRequest())
        }
    }, [dispatch, type, createStatus, updateStatus])

    return (
        <Space direction="vertical" size="large" style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
            <Row>
                <Space>
                    <Typography.Text strong>{"Select Document Type: "}</Typography.Text>
                    <Select style={{ width: 250 }} onChange={setType} value={type}>
                        <Select.Option value={"verification-note"}>{"Trial Note"}</Select.Option>
                        <Select.Option value={"verification"}>{"Trial Validation Report"}</Select.Option>
                        <Select.Option value={"design"}>{"Design Project Plan"}</Select.Option>
                        <Select.Option value={"tds"}>{"TDS"}</Select.Option>
                    </Select>
                </Space>
                {type === "design" && <Dropdown.Button
                    icon={<DownOutlined />}
                    menu={{
                        items: lists?.length === 0 ? [{
                            label: "Template Data Not Available",
                            key: "Null",
                        }] : lists?.map(({ name, template_id }: any) => ({
                            label: name,
                            key: template_id,
                        })), onClick: handleMenuClick
                    }}
                    key="custom"
                    onClick={(e) => {
                        setTemplateType("Create New Template");
                        setCreateTemplate(true)
                    }}
                    style={{ width: "auto", marginLeft: "auto" }}
                >
                    {"Create New Template"}
                </Dropdown.Button>}
                <StyledButton style={{ marginLeft: type === "design" ? "1rem" : "auto" }} onClick={() => {
                    dispatch(reportsRetrieveRequest({ type }))
                    setDrawerVisible(true)
                }} type="primary" ghost>{"Saved Reports"}</StyledButton>
            </Row>
            {getContent()}
            <Drawer title={"Saved Reports"} width={600} open={drawerVisible} onClose={() => setDrawerVisible(false)}>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <List loading={{
                        spinning: reportsRetieveStatus === AsyncStates.LOADING || reportsDeleteStatus === AsyncStates.LOADING,
                        indicator: <LoadingOutlined />
                    }} size="small" bordered dataSource={reportsList} renderItem={(item) => (
                        <List.Item style={{ width: "100%" }} extra={<Space>
                            <StyledButton type="link" href={item?.download_link}>
                                {"download"}
                            </StyledButton>
                            <Popconfirm placement="leftTop" title={"Confirm Delete ?"} onConfirm={() => {
                                dispatch(reportDeleteRequest({ report_id: item?.report_id, from: "modularReports", type }))
                            }}>
                                <StyledButton type="link" danger>
                                    {"delete"}
                                </StyledButton>
                            </Popconfirm>
                        </Space>}>
                            <Typography.Text strong>{item?.file_name}</Typography.Text>
                        </List.Item>
                    )} />
                </Space>
            </Drawer>
        </Space>
    )
}