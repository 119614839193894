import { useEffect, useMemo, useState, useCallback, useRef } from "react"
import {
    Space,
    Table,
    Row,
    message,
    Form,
    Cascader,
    Select,
    Input,
    Typography,
    Tooltip,
    Collapse,
    Checkbox,
    Col,
    Dropdown,
    MenuProps,
    Tag,
    Descriptions,
    Alert,
    Drawer,
    Segmented,
} from "antd";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants";
import {
	CloseOutlined,
	DownOutlined,
	DownloadOutlined,
	EditOutlined,
	EnterOutlined,
	FilterOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	MinusCircleOutlined,
	SortDescendingOutlined,
} from "@ant-design/icons"
import useTranslate from "src/utils/useTranslate"
import {
	customScrollIntoView,
	generateVersionName,
	newColumnData,
	predictedModelsColumns,
	predictionStatus,
	predictionStatusColorText,
	predictionStatusIcon,
	transposeData,
	translatedLabel,
	getLinkedTableCellData
} from "src/utils/decorator"
import { StyledButton } from "src/styled_components/StyledButton"
import { exportPredictionRequest } from "src/store/actions/suggestedExp"
import { getDisplayNameElement } from "src/utils/general/getDisplayNameElement"
import { PropertiesMetrics } from "./PropertiesMetrics"
import {
	changeTitleForwardRequest,
	favouriteModelRequest,
	forwardPredResultCurrentpageRequest,
	forwardPredResultFiltersRequest,
	forwardPredResultRequest,
	getPredictionTrialClear,
	getPredictionTrialRequest,
} from "src/store/actions/formulate"
import { WorkOrderModal } from "./WorkOrderModal"
import { LinkedTrialsAiEngine } from "../common/LinkedTrialsAiEngine"
import { AiCosting } from "../common/AiCosting"
import { linkedFormulationsAiEngineClear, linkedFormulationsAiEngineRequest } from "src/store/actions/common"
import "./prediction-result.scss"
import Note from "../Note"
import { DroppedPropertyWarning } from "../common/DroppedPropertyWarning"
import { useValue } from "src/utils/useValue"
import { SegmentedValue } from "antd/es/segmented"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import StyledBadge from "src/styled_components/StyledBadge";
const { Text } = Typography

type CheckedExperimentsProps = {
	checkAllPages: number[]
	experimentIdList: string[]
}

export const PredictionResult = ({ currentTab }: any) => {
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const { getValue } = useValue()
	const requiredFieldStar = useRequiredFieldStar()

	// let query = useQuery()
	// const predId = query?.get("predId")

	const { configData, changeTitleStatus } = useSelector(
		(state: StoreState) => state.formulate,
	)
	const displayNames = useSelector(
		(state: StoreState) => state.displayNames.data,
	)

	const linkedPredictionTrial = useSelector(
		(state: StoreState) => state.formulate.linkedPredictionTrial
	)

	const collator = useMemo(() => new Intl.Collator([], { numeric: true }), [])
	const [filtersData, setFiltersData] = useState<{
		[key: string]: string[] | string | SegmentedValue | null | undefined
	}>({})

	const {
		forwardPredResult: {
			missing_properties: forwardPredMissingProperties = [],
			predictions: forwardPredResult = [],
			hidden_procs: processingsWithZeroValue = [],
			hidden_ings: ingredientsWithZeroValue = [],
		},
		forwardPredResultStatus,
		favouriteModelStatus,
		metricData,
		forwardPredResultTotal,
		forwardPredResultCurrentpage,
		forwardPredResultFilters,
		forwardPredId,
	} = useSelector((state: StoreState) => state.formulate)
	const { linkedFormulationData } = useSelector((state: StoreState) => state.common)

	const [checkedExperiments, setCheckedExperiments] =
		useState<CheckedExperimentsProps>({
			checkAllPages: [],
			experimentIdList: [],
		})
	const [form] = Form.useForm()
	const [operatorStates, setOperatorStates] = useState<any[]>([])
	const [experiments, setExperiments] = useState<any[]>([])
	const { predictionDataExportStatus } = useSelector(
		(state: StoreState) => state.suggestedExp,
	)
	const configs = useSelector((state: StoreState) => state.configs.features)
	const [metricsVisible, setMetricsVisible] = useState(false)
	const [currentProperty, setCurrentProperty] = useState<any>(null)
	const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([])
	const [workOrderVisible, setWorkOrderVisible] = useState(false)
	const [showZeroValue, setShowZeroValue] = useState(false)
	const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
	const [expType, setExpType] = useState<any>()
	const [isFilterSortingVisible, setIsFilterSortingVisible] =
		useState<boolean>(false)

	const filterInputTypeOptions = useMemo(() => {
		return Boolean(configs?.work_order_costing)
			? ["ingredients", "processing", "predicted_properties", "cost"]
			: ["ingredients", "processing", "predicted_properties"]
	}, [configs])

	const [currentSelectedStage, setCurrentSelectedStage] = useState<SegmentedValue | null>(null)

	const allStages = useMemo(() => experiments?.[0]?.all_stages ?? [], [experiments])
	const isMultiStage = useMemo(() => !!experiments?.[0]?.is_multistage, [experiments])

	const tableRef = useRef<any>(null)


	useEffect(() => {
		return () => {
			setFiltersData({})
		}
	}, [currentSelectedStage])

	useEffect(() => {
		if (forwardPredResultStatus === AsyncStates.SUCCESS) {
			setExperiments(forwardPredResult)
			setShowZeroValue(false)
		}
	}, [forwardPredResultStatus, forwardPredResult, form])

	useEffect(() => {
		if (
			forwardPredResultStatus === AsyncStates.SUCCESS &&
			favouriteModelStatus === AsyncStates.INITIAL
		) {
			setExpandedRowKeys([])
			setTimeout(() => {
				document.getElementById("forwardpred-results")?.scrollIntoView()
			}, 300)
		}
	}, [forwardPredResultStatus, favouriteModelStatus])

	const checkboxChange = useCallback(
		(e) => {
			if (e.target.checked) {
				setCheckedExperiments((prevState) => {
					const data = [
						...new Set([...prevState.experimentIdList, e.target.name]),
					]
					const result = experiments.filter(
						(exp: any) => !data.includes(exp.experiment_id),
					)
					const checkAllPages =
						result.length % 10 === 0
							? [
								...new Set([
									...prevState.checkAllPages,
									forwardPredResultCurrentpage,
								]),
							]
							: [...new Set([...prevState.checkAllPages])]

					return {
						checkAllPages: checkAllPages,
						experimentIdList: [
							...new Set([...prevState.experimentIdList, e.target.name]),
						],
					} as any
				})
			} else {
				setCheckedExperiments((prev) => {
					return {
						checkAllPages: [
							...new Set(
								prev.checkAllPages.filter(
									(res: any) => res !== forwardPredResultCurrentpage,
								),
							),
						],
						experimentIdList: prev.experimentIdList.filter(
							(res: any) => res !== e.target.name,
						),
					}
				})
			}
		},
		[experiments, forwardPredResultCurrentpage],
	)

	const selectAll = useCallback(
		(e: any) => {
			if (e.target.checked) {
				setCheckedExperiments((prevState) => {
					const data = [
						...new Set([
							...prevState.experimentIdList,
							...experiments.map((res: any) => res.experiment_id),
						]),
					]
					return {
						checkAllPages: [
							...new Set([
								...prevState.checkAllPages,
								forwardPredResultCurrentpage,
							]),
						],
						experimentIdList: data,
					} as any
				})
			} else {
				setCheckedExperiments((prev) => {
					const data = prev.experimentIdList.filter(
						(res: any) =>
							!experiments.map((res: any) => res.experiment_id).includes(res),
					)
					return {
						checkAllPages: [
							...new Set(
								prev.checkAllPages.filter(
									(res: any) => res !== forwardPredResultCurrentpage,
								),
							),
						],
						experimentIdList: data,
					}
				})
			}
		},
		[experiments, forwardPredResultCurrentpage],
	)

	const columns = useMemo(() => {
		if (forwardPredResultStatus === AsyncStates.SUCCESS) {
			return newColumnData(
				experiments,
				checkboxChange,
				displayNames,
				checkedExperiments,
				"forward",
				selectAll,
				t,
				forwardPredResultCurrentpage,
				false,
				setMetricsVisible,
				setCurrentProperty,
				true,
			)
		} else return []
	}, [
		forwardPredResultStatus,
		experiments,
		checkedExperiments,
		checkboxChange,
		selectAll,
		displayNames,
		t,
		forwardPredResultCurrentpage,
	])

	const setModelStatus = useCallback(
		(modelName: string, property: string, fav: boolean) => {
			dispatch(
				favouriteModelRequest({
					version: experiments?.[0]?.version,
					modelName: modelName,
					property,
					fav,
				}),
			)
		},
		[dispatch, experiments],
	)

	const propertyModelColumns = useMemo(() => {
		return predictedModelsColumns(experiments, setModelStatus, t, forwardPredResultCurrentpage)
	}, [experiments, setModelStatus, t, forwardPredResultCurrentpage])

	const updateHeaders = useCallback((data: any[]) => {
		if (!Array.isArray(data)) return [];

		const clonedData = structuredClone(data)
		const reversedData = clonedData.reverse();

		let counter = 0;
		for (let i = 0; i < reversedData.length; i++) {
			if (reversedData[i]?.key?.includes("header")) {
				const parameter = reversedData[i]?.parameter;
				reversedData[i] = {
					...reversedData[i],
					parameter: typeof parameter === "string" ? `${parameter.split(" ").slice(0, -1).join(" ")} (${counter})` : ""
				}
				counter = 0
			}
			else counter++
		}

		return reversedData.reverse();
	}, []);

	const tableData = useMemo(() => {
		if (forwardPredResultStatus === AsyncStates.SUCCESS) {
			const data = transposeData(
				experiments,
				t,
				getValue,
				[],
				false,
				Boolean(configs?.nestle_configs),
			)
			const zeroValueIdentifierList = [
				...new Set([...processingsWithZeroValue, ...ingredientsWithZeroValue]),
			].map(
				(parameter) =>
					displayNames.ingredients?.[parameter]?.name ??
					experiments?.[0]?.ingredients?.[parameter]?.name ??
					parameter,
			)
			const predictionTableData = showZeroValue
				? data
				: data.filter(
					(ele: any) => !zeroValueIdentifierList.includes(ele.parameter),
				)
			// Assigning new keys to assist expand, keeping head keys inchanged to maintain title style
			return updateHeaders(predictionTableData).map((res: any, index: number) => ({
				...res,
				...(res?.key?.includes("header") ? {} : { key: index }),
			}))
		} else return []
	}, [
		forwardPredResultStatus,
		experiments,
		t,
		configs?.nestle_configs,
		processingsWithZeroValue,
		ingredientsWithZeroValue,
		showZeroValue,
		displayNames.ingredients,
		getValue,
		updateHeaders
	])

	const filterData: any = useMemo(() => {
		if (!!forwardPredResult.length) {
			return filterInputTypeOptions
				.map((inputParameter) => {
					if (
						Object.keys(forwardPredResult[0] || {}).includes(inputParameter)
					) {
						return {
							value: inputParameter,
							label: translatedLabel(inputParameter, t),
							children:
								inputParameter === "cost"
									? [
										{ value: "total_cost", label: "Total Cost" },
										...[
											...new Set(
												experiments.flatMap(({ cost }: any) =>
													Object.keys(cost?.ingredients || {}),
												),
											),
										]
											.map((value: any) => ({
												value,
												label: displayNames?.ingredients[value]?.name,
											}))
											.sort((a: any, b: any) =>
												collator.compare(a.label, b.label),
											),
									]
									: Object.keys(
										forwardPredResult?.[0]?.[inputParameter] || {},
									).map((res: any) => ({
										value: res,
										label:
											inputParameter === "predicted_properties"
												? getDisplayNameElement(
													displayNames,
													"properties",
													res,
												)?.name ?? res
												: getDisplayNameElement(
													displayNames,
													inputParameter,
													res,
												)?.name ??
												forwardPredResult?.[0]?.[inputParameter]?.[res]
													?.name ??
												res,
									})),
						}
					} else {
						return null
					}
				})
				.filter((res: any) => res && res?.children?.length)
		} else {
			return []
		}
	}, [
		forwardPredResult,
		filterInputTypeOptions,
		t,
		experiments,
		displayNames,
		collator,
	])

	const callForwardRetrieve = useCallback(
		(page: number, filters: any) => {
			dispatch(forwardPredResultCurrentpageRequest(page))
			dispatch(forwardPredResultFiltersRequest(filters))
			dispatch(
				forwardPredResultRequest({
					prediction_id: forwardPredId,
					type: "history",
					page_num: page,
					...filters,
					...(isMultiStage && { stage: currentSelectedStage })
				}),
			)
			dispatch(getPredictionTrialClear())
			dispatch(linkedFormulationsAiEngineClear())
			customScrollIntoView(tableRef, { behavior: "smooth" })
		},
		[dispatch, forwardPredId, currentSelectedStage, isMultiStage],
	)

	const applyFilters = useCallback(
		({
			expFilters,
			sorting,
			isCleared = false,
		}: {
			expFilters: any
			sorting: any
			isCleared?: boolean
		}) => {
			if (!expFilters?.length && !sorting?.length && isCleared) {
				callForwardRetrieve(forwardPredResultCurrentpage, {
					...(isMultiStage && { stage: currentSelectedStage })
				})
				setFiltersData({
					...(isMultiStage && { stage: currentSelectedStage })
				})
				return
			}

			if (!expFilters?.length && !sorting?.length) {
				message.error(t("aiEngine.filters.error"))
			} else {
				let filtersPayload = []
				let sortingPayload = []
				if (!!expFilters?.length) {
					filtersPayload = expFilters
						.filter((item: any) => !!item)
						.reduce(
							(array: any, res: any) => [
								...array,
								{
									category: res.property[0],
									field: res.property[1],
									operand: res.operand,
									minVal: String(res.minVal),
									...(res?.maxVal && { maxVal: String(res.maxVal) }),
								},
							],
							[],
						)
				}
				if (!!sorting?.length) {
					sortingPayload = sorting
						.filter((item: any) => !!item)
						.reduce(
							(array: any, res: any) => [
								...array,
								{
									category: res.property[0],
									field: res.property[1],
									sort_order: res.sort_order,
								},
							],
							[],
						)
				}
				const payload = {
					filters: filtersPayload, sorts: sortingPayload,
					...(isMultiStage && { stage: currentSelectedStage })
				}
				setFiltersData(payload)
				callForwardRetrieve(
					Object.keys(forwardPredResultFilters || {}).length
						? forwardPredResultCurrentpage
						: 1,
					payload,
				)
			}
		},
		[callForwardRetrieve, currentSelectedStage, forwardPredResultCurrentpage, forwardPredResultFilters, isMultiStage, t],
	)

	useEffect(() => {
		dispatch(forwardPredResultFiltersRequest({}))
		dispatch(forwardPredResultCurrentpageRequest(1))
		form.resetFields()
	}, [dispatch, forwardPredId, form])

	const exportDataSheet = () => {
		dispatch(
			exportPredictionRequest({
				prediction_id: experiments?.[0]?.prediction_id,
				...(!!checkedExperiments?.experimentIdList?.length && {
					prediction_experiment_ids: checkedExperiments.experimentIdList,
				}),
				type: "forward",
				filters: filtersData.filters,
				sorts: filtersData.sorts,
				version: experiments?.[0]?.version,
				...(isMultiStage && { stage: currentSelectedStage })
			}),
		)
	}

	const generateWO = useCallback(
		(type: string, selectAll: boolean = false) => {
			if (selectAll) {
				setIsSelectAll(true)
				setWorkOrderVisible(true)
			} else if (checkedExperiments.experimentIdList.length > 0) {
				setExpType(checkedExperiments.experimentIdList)
				setWorkOrderVisible(true)
			} else {
				message.warning(t("aiEngine.wo.error"))
			}
		},
		[checkedExperiments, t],
	)

	const generateWoMenuItems: MenuProps = useMemo(() => {
		return {
			items: [
				{
					key: "generate-wo-selected-trials",
					label: t("aiEngine.generateWorkOrder"),
					disabled: forwardPredResultStatus === AsyncStates.LOADING,
				},
				{
					key: "generate-wo-all-trials",
					label: t("aiEngine.generateWorkOrderAll"),
					disabled: forwardPredResultStatus === AsyncStates.LOADING,
				},
			],
			onClick: ({ key }: any) => {
				generateWO("suggested", key === "generate-wo-all-trials")
			},
		}
	}, [forwardPredResultStatus, generateWO, t])

	const updateSelectedExperiments = (e: any, selectedId: string) => {
		e.preventDefault()
		setCheckedExperiments((prev) => {
			const filteredList = prev.experimentIdList.filter(
				(id) => id !== selectedId,
			)
			const checkAllPages = filteredList.length === 0 ? [] : prev.checkAllPages
			return {
				checkAllPages: checkAllPages,
				experimentIdList: filteredList,
			}
		})
	}

	const clearAllSelectedExperiment = () => {
		setCheckedExperiments({
			checkAllPages: [],
			experimentIdList: [],
		})
	}

	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [title, setTitle] = useState<string>("")

	useEffect(() => {
		if (currentSelectedStage === null && !!experiments?.[0]?.all_stages?.length) { //  for First Time Setting State
			setCurrentSelectedStage(experiments?.[0]?.all_stages?.[0])
		}

	}, [currentSelectedStage, experiments])

	// const predDetailsForward = useMemo(() => {
	// 	return forwardPredResult?.find((pred: any) => pred.prediction_id === predId)
	// }, [forwardPredResult, predId])

	useEffect(() => {
		setTitle(experiments?.[0]?.objective)
	}, [experiments])

	useEffect(() => {
		if (changeTitleStatus === AsyncStates.ERROR)
			setTitle(experiments?.[0]?.objective)
	}, [changeTitleStatus, experiments])

	const submitTitle = useCallback(() => {
		setIsEditing(false)
		dispatch(
			changeTitleForwardRequest({
				prediction_id: forwardPredId,
				title,
				type: "forward",
			}),
		)
	}, [forwardPredId, dispatch, title])


	return (
		<Space
			size="large"
			direction="vertical"
			style={{ width: "100%", overflow: "hidden" }}
			id="forwardpred-results"
		>
			{/* Top action */}
			<Row justify="end" gutter={8}>
				<Col>
					<Dropdown menu={generateWoMenuItems} placement="bottomRight">
						<StyledButton type="primary">
							{t("common.generateWorkOrder")}
							<DownOutlined />
						</StyledButton>
					</Dropdown>
				</Col>
				{!Boolean(configs?.ai_engine_with_methods) && (
					<Col>
						<Tooltip
							placement="top"
							title={t("workOrderDetails.exportDatasheet")}
						>
							<StyledButton
								type="default"
								icon={<DownloadOutlined />}
								disabled={predictionDataExportStatus === AsyncStates.LOADING}
								onClick={exportDataSheet}
							/>
						</Tooltip>
					</Col>
				)}
			</Row>

			{/* Model detials */}
			<Row
				style={{
					padding: "16px",
					borderRadius: "8px",
					backgroundColor: "#FAFAFA",
				}}
			>
				<Descriptions
					layout="vertical"
					colon={false}
					size="small"
					className="suggested-exp-description"
					column={5}
					bordered={true}
				>
					<Descriptions.Item
						label={`${t("aiEngine.objective")} / ${t("common.title")}`}
					>
						<Space style={{ width: "100%" }}>
							{isEditing ? (
								<Input
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									onBlur={() => submitTitle()}
									autoFocus
									size="small"
									style={{ flexGrow: 1 }}
									onPressEnter={() => submitTitle()}
								/>
							) : (
								<Text
									style={{
										maxWidth: "300px",
									}}
									ellipsis={{ tooltip: title }}
									strong
								>
									{title !== ''
										? title
										: t("common.loading")}
								</Text>
							)}
							{isEditing ? (
								<EnterOutlined
									onClick={() => submitTitle()}
									style={{ outline: "none" }}
								/>
							) : (
								<EditOutlined
									onClick={() => setIsEditing(true)}
									style={{ outline: "none" }}
								/>
							)}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item label={t("common.model")}>
						<Text
							style={{ maxWidth: "300px" }}
							ellipsis={{
								tooltip: experiments?.[0]?.version
									? generateVersionName(experiments?.[0]?.version, configData)
									: t("common.loading")
							}}
							strong
						>
							{experiments?.[0]?.version
								? generateVersionName(experiments?.[0]?.version, configData)
								: t("common.loading")}
						</Text>
					</Descriptions.Item>
					<Descriptions.Item label={t("common.status")}>
						<Tag
							color={predictionStatusColorText["Completed"]}
							icon={predictionStatusIcon["Completed"]}
							style={{
								textTransform: "capitalize",
								border: "1px solid",
								fontWeight: "bold",
							}}
						>
							{predictionStatus["Completed"]}
						</Tag>
					</Descriptions.Item>
				</Descriptions>
			</Row>

			{/* Note about '-' */}
			<Note
				content={
					<>
						{t("predictedValue.note")}
						<a
							href="mailto:contact@polymerize.io"
							style={{ color: "#1677ff", display: "inline" }}
						>
							{"contact@polymerize.io"}
						</a>
					</>
				}
				icon={<InfoCircleOutlined />}
			/>

			{
				!!allStages.length && isMultiStage && <div style={{
					width: "100vw",
					overflowY: "auto",
					scrollbarWidth: "thin"
				}}>
					<Segmented
						value={currentSelectedStage as SegmentedValue}
						options={allStages}
						onChange={(value) => {
							form.resetFields()
							setCurrentSelectedStage(value)
							dispatch(forwardPredResultRequest({
								prediction_id: forwardPredId,
								type: "history",
								stage: value,
								page_num: 1,
							}))
							dispatch(forwardPredResultCurrentpageRequest(1))
							dispatch(linkedFormulationsAiEngineClear())
						}}
					/>
				</div>
			}

			{(tableData?.length > 0 && !!forwardPredMissingProperties?.length) && <Row style={{ color: 'grey', marginTop: '1rem' }}>
				<DroppedPropertyWarning missingProperties={forwardPredMissingProperties} />
			</Row>
			}

			{/* Checked exp listing */}

			<Drawer
				open={checkedExperiments.experimentIdList.length > 0}
				placement="bottom"
				style={{
					background: "#262626",
				}}
				height={125}
				mask={false}
				maskClosable={false}
				closeIcon={false}
				title={
					<>
						<Space direction="vertical" size={"large"}>
							<Typography.Title
								level={5}
								style={{ margin: 0, color: "#fff" }}
							>{`${checkedExperiments.experimentIdList.length} ${t(
								"common.trials",
							)} ${t("formulations.selected")}`}</Typography.Title>
							<div
								style={{
									display: "flex",
									overflowX: "auto",
								}}
							>
								{checkedExperiments.experimentIdList.map(
									(experimentId, index) => {
										const idx = experiments.findIndex(
											(exp) => exp?.experiment_id === experimentId,
										)
										return (
											<Tag
												closable
												onClose={(e) =>
													updateSelectedExperiments(e, experimentId)
												}
												style={{
													background: "#262626",
													borderColor: "#fff",
													color: "#fff",
												}}
												closeIcon={
													<CloseOutlined
														style={{ color: "#fff" }}
													/>
												}
												key={experimentId ?? idx}
											>
												<Text
													style={{ color: "#fff" }}
												>{`Trial ${(forwardPredResultCurrentpage - 1) * 10 + idx + 1
													}`}</Text>
											</Tag>
										)
									},
								)}
							</div>
						</Space>
						<div style={{ position: "absolute", top: "-12%", left: "50%" }}>
							<StyledButton
								onClick={() => clearAllSelectedExperiment()}
								style={{
									background: "#262626",
									color: "#fff",
									borderColor: "#fff",
								}}
							>
								<Space>
									<CloseOutlined />
									{t("common.clear")}
								</Space>
							</StyledButton>
						</div>
					</>
				}
				styles={{
					body: {
						display: "none",
					},
					header: {
						padding: 24,
					},
				}}
			/>

			<Table
				title={() => (
					<Space direction="vertical" style={{ width: "100%" }}>
						<Row justify="space-between">
							<Typography.Title level={5}>
								{`${forwardPredResultTotal} ${t("common.trials")}`}
							</Typography.Title>
							<Col>
								<Space>
									{(!!processingsWithZeroValue.length ||
										!!ingredientsWithZeroValue.length) && (
											<Checkbox
												checked={showZeroValue}
												onChange={() => {
													setShowZeroValue((prev) => !prev)
												}}
											>
												{t("common.showZeroValues")}
											</Checkbox>
										)}
									<StyledBadge
										dot={
											form.getFieldValue("expFilters")?.length ||
											form.getFieldValue("sorting")?.length
										}
										style={{ marginRight: 5 }}
									>
										<StyledButton
											onClick={() =>
												setIsFilterSortingVisible((prevState) => !prevState)
											}
											className={
												isFilterSortingVisible ? "action-button-active" : ""
											}
											style={{ marginRight: 5 }}
										>
											{t("aiEngine.filters.title")}
										</StyledButton>
									</StyledBadge>
								</Space>
							</Col>
						</Row>

						<Alert
							description={
								<Form
									name="dynamic_form_nest_item"
									onFinish={applyFilters}
									form={form}
									requiredMark={false}
								>
									<Row gutter={24} style={{ flexWrap: "nowrap" }}>
										<Col span={12}>
											<Form.List name="expFilters">
												{(fields, { add, remove }) => (
													<>
														<Form.Item>
															<StyledButton
																type="primary"
																onClick={() => add()}
																ghost
																icon={<FilterOutlined />}
															>
																{t("formulations.button.addFilter")}
															</StyledButton>
														</Form.Item>
														{fields.map(
															({ key, name, fieldKey, ...restField }) => (
																<Space
																	key={key}
																	style={{
																		display: "flex",
																		marginBottom: 8,
																	}}
																	align="baseline"
																>
																	<Form.Item
																		{...restField}
																		name={[name, "property"]}
																		fieldKey={[key, "property"]}
																		rules={[
																			{
																				required: true,
																				message: t(
																					"aiEngine.form.properties.error",
																				),
																			},
																		]}
																		required
																		tooltip={requiredFieldStar}
																	>
																		<Cascader
																			placeholder={t(
																				"aiEngine.form.properties.placeholder",
																			)}
																			options={filterData}
																			onChange={(value) => {
																				const { expFilters } =
																					form.getFieldsValue()

																				if (
																					experiments
																						.map(
																							(exp: any) =>
																								exp[value[0]]?.[value[1]]
																									.value,
																						)
																						.every(
																							(value: any) =>
																								typeof value === "string",
																						)
																				) {
																					setOperatorStates(
																						(state: any) => {
																							const array = [...state]
																							array[key] = "="
																							return array
																						},
																					)
																					if (expFilters?.[name]) {
																						Object.assign(
																							expFilters?.[name],
																							{
																								operand: "=",
																								minVal: [],
																								maxVal: null,
																							},
																						)
																					}
																				} else {
																					setOperatorStates(
																						(state: any) => {
																							const array = [...state]
																							array[key] = null
																							return array
																						},
																					)
																					if (expFilters?.[name]) {
																						Object.assign(
																							expFilters?.[name],
																							{
																								operand: null,
																								minVal: null,
																								maxVal: null,
																							},
																						)
																					}
																				}
																				form.setFieldsValue({ expFilters })
																			}}
																			showSearch
																		/>
																	</Form.Item>
																	<Form.Item
																		{...restField}
																		name={[name, "operand"]}
																		fieldKey={[key, "operand"]}
																		rules={[
																			{
																				required: true,
																				message: t(
																					"aiEngine.form.operator.error",
																				),
																			},
																		]}
																		required
																		tooltip={requiredFieldStar}
																	>
																		<Select
																			placeholder={t(
																				"formulations.placeholder.operator",
																			)}
																			onChange={(operator) => {
																				setOperatorStates((state: any) => {
																					const array = [...state]
																					array[name] = operator
																					return array
																				})
																				const { expFilters } =
																					form.getFieldsValue()
																				if (expFilters?.[name]) {
																					Object.assign(
																						expFilters?.[name],
																						{
																							minVal: "",
																							maxVal: "",
																						},
																					)
																				}
																				form.setFieldsValue({ expFilters })
																			}}
																		>
																			<Select.Option value="=">
																				{"="}
																			</Select.Option>
																			{experiments
																				.map(
																					(exp: any) =>
																						exp[
																							form?.getFieldsValue()
																								?.expFilters?.[name]
																								?.property[0]
																						]?.[
																							form?.getFieldsValue()
																								?.expFilters?.[name]
																								?.property[1]
																						].value,
																				)
																				.every(
																					(value: any) =>
																						typeof value !== "string",
																				) && (
																					<>
																						<Select.Option value="<=">
																							{"<="}
																						</Select.Option>
																						<Select.Option value=">=">
																							{">="}
																						</Select.Option>
																						{form?.getFieldsValue()
																							?.expFilters[name]
																							?.property[0] !== "cost" && (
																								<>
																									<Select.Option value="range">
																										{t("formulations.operator")}
																									</Select.Option>
																									<Select.Option value="exists">
																										{t("common.exists")}
																									</Select.Option>
																								</>
																							)}
																					</>
																				)}
																		</Select>
																	</Form.Item>
																	{operatorStates[name] === "range" ? (
																		<>
																			<Form.Item
																				{...restField}
																				name={[name, "minVal"]}
																				fieldKey={[key, "minVal"]}
																				rules={[
																					{
																						required: true,
																						message: t(
																							"aiEngine.form.minVal.error",
																						),
																					},
																				]}
																				required
																				tooltip={requiredFieldStar}
																			>
																				<Input
																					style={{ textAlign: "center" }}
																					placeholder={t(
																						"formulations.placeholder.minimum",
																					)}
																				/>
																			</Form.Item>
																			~
																			<Form.Item
																				{...restField}
																				name={[name, "maxVal"]}
																				fieldKey={[key, "maxVal"]}
																				rules={[
																					{
																						required: true,
																						message: t(
																							"aiEngine.form.maxVal.error",
																						),
																					},
																				]}
																				required
																				tooltip={requiredFieldStar}
																			>
																				<Input
																					className="site-input-right"
																					style={{ textAlign: "center" }}
																					placeholder={t(
																						"formulations.placeholder.maximum",
																					)}
																				/>
																			</Form.Item>
																		</>
																	) : operatorStates[name] === "exists" ? (
																		<Form.Item
																			{...restField}
																			name={[name, "minVal"]}
																			fieldKey={[key, "minVal"]}
																			rules={[
																				{
																					required: true,
																					message: t(
																						"aiEngine.form.operator.error",
																					),
																				},
																			]}
																			required
																			tooltip={requiredFieldStar}
																		>
																			<Select
																				placeholder={t(
																					"formulations.placeholder.value",
																				)}
																				onChange={(value) => {
																					const { expFilters } =
																						form?.getFieldsValue()
																					Object.assign(
																						expFilters?.[name],
																						{
																							minVal: value,
																						},
																					)
																					form.setFieldsValue({
																						expFilters,
																					})
																				}}
																			>
																				<Select.Option value={1}>
																					{t("common.yes")}
																				</Select.Option>
																				<Select.Option value={0}>
																					{t("common.no")}
																				</Select.Option>
																			</Select>
																		</Form.Item>
																	) : Boolean(
																		configs?.processing_profiles,
																	) &&
																		form?.getFieldsValue()?.expFilters?.[
																			name
																		]?.property[1] === "Profile" ? (
																		<Form.Item
																			{...restField}
																			name={[name, "minVal"]}
																			fieldKey={[key, "minVal"]}
																			rules={[
																				{
																					required: true,
																					message: t(
																						"aiEngine.form.operator.error",
																					),
																				},
																			]}
																			required
																			tooltip={requiredFieldStar}
																		>
																			<Select
																				placeholder={t(
																					"formulations.placeholder.value",
																				)}
																				mode="multiple"
																				onChange={(value) => {
																					const { expFilters } =
																						form?.getFieldsValue()
																					Object.assign(
																						expFilters?.[name],
																						{
																							minVal: value,
																						},
																					)
																					form.setFieldsValue({
																						expFilters,
																					})
																				}}
																				style={{ width: "10em" }}
																				options={[
																					...new Set(
																						forwardPredResult.map(
																							(value: any) =>
																								value.processing["Profile"]
																									.value,
																						),
																					),
																				].map((pro: any) => ({
																					label: pro,
																					value: pro,
																				}))}
																			/>
																		</Form.Item>
																	) : (
																		<Form.Item
																			{...restField}
																			name={[name, "minVal"]}
																			fieldKey={[key, "minVal"]}
																			style={{ flex: 1, marginBottom: 0 }}
																			rules={[
																				{
																					required: true,
																					message: t("common.required"),
																				},
																			]}
																			required
																			tooltip={requiredFieldStar}
																		>
																			{experiments
																				.map(
																					(exp: any) =>
																						exp[
																							form?.getFieldsValue()
																								?.expFilters?.[name]
																								?.property[0]
																						]?.[
																							form?.getFieldsValue()
																								?.expFilters?.[name]
																								?.property[1]
																						].value,
																				)
																				.every(
																					(value: any) =>
																						typeof value === "string",
																				) ? (
																				<Select
																					placeholder={t(
																						"formulations.placeholder.value",
																					)}
																					style={{ width: "10em" }}
																					mode="multiple"
																					onChange={(value) => {
																						const { expFilters } =
																							form?.getFieldsValue()
																						Object.assign(
																							expFilters?.[name],
																							{
																								minVal: value,
																							},
																						)
																						form.setFieldsValue({
																							expFilters,
																						})
																					}}
																					options={[
																						...new Set(
																							experiments.map(
																								(exp: any) =>
																									exp[
																										form?.getFieldsValue()
																											?.expFilters?.[name]
																											?.property[0]
																									]?.[
																										form?.getFieldsValue()
																											?.expFilters?.[name]
																											?.property[1]
																									].value,
																							),
																						),
																					].map((value) => ({
																						label: value,
																						value,
																					}))}
																				/>
																			) : (
																				<Input
																					style={{ textAlign: "center" }}
																					placeholder={t(
																						"formulations.placeholder.value",
																					)}
																				/>
																			)}
																		</Form.Item>
																	)}
																	<Tooltip title={t("common.remove")}>
																		<MinusCircleOutlined
																			onClick={() => {
																				setOperatorStates((state) =>
																					state.filter(
																						(o, idx) => idx !== name,
																					),
																				)
																				remove(name)
																			}}
																		/>
																	</Tooltip>
																</Space>
															),
														)}
													</>
												)}
											</Form.List>
										</Col>

										<Col span={12}>
											<Form.List name="sorting">
												{(fields, { add, remove }) => (
													<>
														<Form.Item>
															<StyledButton
																type="primary"
																onClick={() => add()}
																ghost
																icon={<SortDescendingOutlined />}
															>
																{t("aiEngine.button.sorting")}
															</StyledButton>
														</Form.Item>
														{fields.map(
															({ key, name, fieldKey, ...restField }) => (
																<Space
																	key={key}
																	style={{
																		display: "flex",
																		marginBottom: 8,
																	}}
																	align="baseline"
																>
																	<Form.Item
																		{...restField}
																		name={[name, "property"]}
																		fieldKey={[key, "property"]}
																		rules={[
																			{
																				required: true,
																				message: t(
																					"aiEngine.form.properties.error",
																				),
																			},
																		]}
																		required
																		tooltip={requiredFieldStar}
																	>
																		<Cascader
																			placeholder={t(
																				"aiEngine.form.properties.placeholder",
																			)}
																			options={filterData}
																			onChange={() => {
																				const { sorting } =
																					form.getFieldsValue()
																				if (sorting?.[key]) {
																					Object.assign(sorting?.[key], {
																						sort_order: null,
																					})
																				}
																				form.setFieldsValue({ sorting })
																			}}
																			showSearch
																		/>
																	</Form.Item>
																	<Form.Item
																		{...restField}
																		name={[name, "sort_order"]}
																		fieldKey={[key, "sort_order"]}
																		rules={[
																			{
																				required: true,
																				message: t(
																					"aiEngine.form.sort.error",
																				),
																			},
																		]}
																		required
																		tooltip={requiredFieldStar}
																	>
																		<Select
																			placeholder={t(
																				"aiEngine.form.sort.placeholder",
																			)}
																		>
																			<Select.Option value="ascending">
																				{t(
																					"aiEngine.form.select.ascending",
																				)}
																			</Select.Option>
																			<Select.Option value="descending">
																				{t(
																					"aiEngine.form.select.descending",
																				)}
																			</Select.Option>
																		</Select>
																	</Form.Item>
																	<Tooltip title={t("common.remove")}>
																		<MinusCircleOutlined
																			onClick={() => {
																				remove(name)
																			}}
																		/>
																	</Tooltip>
																</Space>
															),
														)}
													</>
												)}
											</Form.List>
										</Col>
									</Row>
									<Space>
										<Form.Item>
											<StyledButton type="primary" htmlType="submit">
												{t("aiEngine.button.apply")}
											</StyledButton>
										</Form.Item>
										<Form.Item>
											<StyledButton
												type="primary"
												ghost
												disabled={
													!Object.keys(forwardPredResultFilters).length
												}
												onClick={() => {
													form.resetFields()
													setOperatorStates([])
													dispatch(forwardPredResultFiltersRequest({}))
													callForwardRetrieve(1, {
														...(isMultiStage && { stage: currentSelectedStage })
													})
													setFiltersData({
														...(isMultiStage && { stage: currentSelectedStage })
													})
												}}
											>
												{t("common.clear")}
											</StyledButton>
										</Form.Item>
									</Space>
								</Form>
							}
							type="info"
							message={
								<Row justify={"space-between"}>
									<Typography.Text
									>
										{t("aiEngine.filters.title")}
									</Typography.Text>
									<CloseOutlined
										onClick={() => setIsFilterSortingVisible(false)}
										style={{ outline: "none" }}
									/>
								</Row>
							}
							style={{
								border: "none",
								padding: "16px",
								backgroundColor: "#FAFAFA",
								display: isFilterSortingVisible ? "flex" : "none",
							}}
						/>
					</Space>
				)}
				expandable={
					experiments.some((res: any) => !!Object.keys(res?.property_data || {}).length)
						? {
							expandedRowKeys: expandedRowKeys,
							onExpand: (expanded, record) => {
								if (expanded) {
									setExpandedRowKeys((prevState: any) => [
										...new Set([...prevState, record?.key]),
									])
									if (
										!!record?.linked_trial &&
										!linkedFormulationData?.[record?.linked_trial]
									) {
										const payload = {
											formulation_id: [record?.linked_trial],
											is_nested: true,
										}
										dispatch(linkedFormulationsAiEngineRequest(payload))
									}
									if (!!record?.predictionTrial && !linkedPredictionTrial[record?.predictionTrial]) {

										const payload = {
											formulation_id: record?.predictionTrial,
											prediction_id: experiments?.[0]?.prediction_id
										}

										dispatch(getPredictionTrialRequest(payload))
									}
								} else {
									setExpandedRowKeys((prevState: any) =>
										prevState.filter((key: any) => key !== record?.key),
									)
								}
							},
							expandedRowRender: (record) => {
								const currentPropertyData = experiments.map(
									(res: any) => res?.property_data?.[record?.parameter],
								)
								const initialData = [
									{
										models: "Polymerize",
										key: 0,
										unit: displayNames?.properties?.[record?.parameter]
											?.unit?.[0],
										...Object.entries(record || {}).reduce(
											(obj: any, [key, value]: any) => ({
												...obj,
												[key]: getValue(value),
											}),
											{},
										),
									},
									...Object.keys(currentPropertyData?.[0] || {}).map(
										(res: any, index: number) => ({
											models: res,
											unit: record.unit,
											key: index + 1,
										}),
									),
								]
								experiments.forEach((exp: any) => {
									initialData.forEach((res: any) => {
										if (res?.models !== "Polymerize") {
											const value =
												exp?.property_data?.[record?.parameter]?.[
													res?.models
												]?.value
											const std =
												exp?.property_data?.[record?.parameter]?.[
													res?.models
												]?.std
											res.favourite =
												exp?.property_data?.[record?.parameter]?.[
													res?.models
												]?.favourite
											res.currentProperty = record?.parameter
											if (
												!!std &&
												std !== "-" &&
												!!value &&
												!isNaN(Number(value))
											) {
												res[exp?.experiment_id] = `${getValue(
													value,
												)} ± ${getValue(std)}`
											} else {
												res[exp?.experiment_id] =
													getValue(value)
											}
										}
									})
								})
								return (
									<>
										{!!record?.linked_trial && (
											<LinkedTrialsAiEngine
												record={record}
												experiments={experiments}
												linkedFormulationDetailsData={
													linkedFormulationData
												}
												from={"forward"}
												pageNumber={forwardPredResultCurrentpage}
											/>
										)}
										{!!record?.predictionTrial &&
											<PredictionTrialTable
												record={record}
												experiments={experiments}
												linkedPredictionTrialData={linkedPredictionTrial?.[record?.predictionTrial]}
												from={"forward"}
												pageNumber={forwardPredResultCurrentpage}
											/>
										}
										{record?.parameterType === "properties" &&
											!!record?.property_data && (
												<Table
													scroll={{ x: 400, y: 800 }}
													style={{ marginTop: 20, marginBottom: 20 }}
													pagination={false}
													bordered
													columns={propertyModelColumns}
													dataSource={initialData}
													className={"suggested-exp-result-table"}
												/>
											)}
									</>
								)
							},
							rowExpandable: (record) =>
								record?.predictionTrial || (record?.parameterType === "properties" &&
									!!record?.property_data) ||
								!!record?.linked_trial,
						}
						: {
							expandedRowRender: (record) => {
								return (
									<>
										<LinkedTrialsAiEngine
											record={record}
											experiments={experiments}
											linkedFormulationDetailsData={linkedFormulationData}
											from={"forward"}
											pageNumber={forwardPredResultCurrentpage}
										/>
										{!!record?.predictionTrial &&
											<PredictionTrialTable
												record={record}
												experiments={experiments}
												linkedPredictionTrialData={linkedPredictionTrial?.[record?.predictionTrial]}
												from={"forward"}
												pageNumber={forwardPredResultCurrentpage}
											/>
										}
									</>
								)
							},
							rowExpandable: (record) => {
								return !!record?.predictionTrial || !!record?.linked_trial
							},
							onExpand: (expanded, record) => {
								if (expanded) {
									if (!!record?.linked_trial && !linkedFormulationData?.[record?.linked_trial]) {
										const payload = {
											formulation_id: [record?.linked_trial],
											is_nested: true,
										}
										dispatch(linkedFormulationsAiEngineRequest(payload))
									} else if (!!record?.predictionTrial && !linkedPredictionTrial[record?.predictionTrial]) {

										const payload = {
											formulation_id: record?.predictionTrial,
											prediction_id: experiments?.[0]?.prediction_id
										}

										dispatch(getPredictionTrialRequest(payload))
									}
								}
							},
						}
				}
				scroll={{ x: 400 }}
				columns={columns}
				dataSource={tableData}
				pagination={{
					pageSize: tableData?.length,
					current: forwardPredResultCurrentpage,
					showSizeChanger: false,
					responsive: true,
					total: Math.ceil(
						(tableData?.length * forwardPredResultTotal) / 10,
					),
					onChange: (page) => {
						dispatch(forwardPredResultCurrentpageRequest(page))
						callForwardRetrieve(page, forwardPredResultFilters)
					},
					position: ["topRight"],
				}}
				className={"suggested-exp-result-table"}
				bordered={false}
				ref={tableRef}
				loading={{
					indicator: <LoadingOutlined />,
					spinning: forwardPredResultStatus === AsyncStates.LOADING
				}}
			/>

			{!!experiments?.length && Boolean(configs?.work_order_costing) && (
				<Collapse>
					<Collapse.Panel
						header={<Text strong>{"Costing"}</Text>}
						key={"1"}
					>
						<AiCosting experiments={experiments} />
					</Collapse.Panel>
				</Collapse>
			)}

			<Space direction="vertical" style={{ marginLeft: 5 }}>
				{Object.entries(metricData || {})
					.filter(([key, value]: any) => !!value?.metric_version)
					.map(([key, value]: any) => (
						<Text strong type="secondary">{`${t(
							"forwardPredResult.noGoodModel",
						)} ${displayNames?.properties?.[key]?.name || key}`}</Text>
					))}
			</Space>

			<PropertiesMetrics
				metricsVisible={metricsVisible}
				setMetricsVisible={setMetricsVisible}
				experiments={experiments}
				currentProperty={currentProperty}
				setCurrentProperty={setCurrentProperty}
			/>

			<WorkOrderModal
				workOrderVisible={workOrderVisible}
				setWorkOrderVisible={setWorkOrderVisible}
				checkedExperiments={expType}
				experiments={experiments}
				type={"forward"}
				isSelectAll={isSelectAll}
			/>

			{/* Spacing equal to Exp Listing drawer so we can see content properly */}
			<div
				style={{
					paddingBottom:
						checkedExperiments.experimentIdList.length > 0 ? 125 : 10,
				}}
			></div>
		</Space>
	)
}


const PredictionTrialTable = ({ record, experiments = [], linkedPredictionTrialData = {}, pageNumber, from }: any) => {
	const [t] = useTranslate()
	const { getValue } = useValue()

	const linkedPredictionTrialStatus = useSelector((state: StoreState) => state.formulate.linkedPredictionTrialStatus)

	const columns = useMemo(() => [
		{
			title: (
				<Text strong style={{ paddingLeft: "26px", }}>
					{t("common.ingredient")}
				</Text>
			),
			dataIndex: "name",
			key: "name",
			className: 'table-name-column',
			align: "left" as any,
			fixed: "left",
			width: "300px",
			render: (text: string) => {
				return getLinkedTableCellData(text, true)
			},
		},
		{
			dataIndex: "category",
			key: "Category",
			width: "170px",
			title: (
				<Text strong>
					{t("common.category")}
				</Text>
			),
			align: "left",
			fixed: "left" as "left",
			render: (text: string) => {
				return getLinkedTableCellData(text, false)
			},
		},
		{
			title: (
				<Text strong>
					{t("common.unit")}
				</Text>
			),
			dataIndex: "unit",
			key: "unit",
			className: 'table-unit-column',
			fixed: "left",
			width: "80px",
			align: "left",
			render: (text: string) => {
				return getLinkedTableCellData(text, false)
			},
		},
		...experiments.map((res: any, index: number) => (
			{
				title: () => (
					<Space
						style={{
							alignItems: "center",
							gap: "10px",
							wordBreak: "break-word",
							lineHeight: 1,
							paddingLeft: "26px",
						}}
					>
						<Text strong >{`Trial ${(pageNumber ? 1 : 0) * (pageNumber - 1) * 10 + index + 1
							}`}</Text>
					</Space>
				),
				dataIndex: res?.formulation_id,
				width: "170px",
				align: "left",
				render: (text: string) => {
					return getLinkedTableCellData(text)
				},
			}
		))

	], [t, pageNumber, experiments])

	const dataSource = useMemo(() => {
		const ingredients = linkedPredictionTrialData?.ingredients ?? {}
		const data = Object.keys(ingredients || {})
			.map((name: any, index: number) => ({
				key: index,
				category: ingredients?.[name]?.category || "-",
				name: ingredients?.[name]?.name,
				unit: ingredients?.[name]?.unit || "-",
				...experiments.reduce(
					(acc: any, experiment: any) => ({
						...acc,
						[experiment.formulation_id]: getValue(Number(ingredients?.[name]?.value)) || "-",
					}),
					{}
				)
			}))
		return data
	}, [experiments, getValue, linkedPredictionTrialData?.ingredients])

	return <Table
		columns={columns}
		dataSource={dataSource}
		pagination={false}
		scroll={{ x: 400 }}
		style={{ marginTop: 20, marginBottom: 20 }}
		loading={
			{
				indicator: <LoadingOutlined />,
				spinning: linkedPredictionTrialStatus[record?.predictionTrial] === AsyncStates.LOADING
			}
		}

	/>
}
