import { DeploymentUnitOutlined } from '@ant-design/icons'
import { Modal, Space, Table, Tooltip, Typography } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { useModelErrorInfo } from '../ModelAnalysis/useModelErrorInfo'
import { useValue } from 'src/utils/useValue'
import useTranslate from "src/utils/useTranslate";
const { Text } = Typography


export const PropertiesMetrics = ({ metricsVisible, setMetricsVisible, experiments, currentProperty, setCurrentProperty }: any) => {
    const [t] = useTranslate()
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const { getModelErrorInfo } = useModelErrorInfo()
    const { getValue } = useValue()

    const columns = [
        {
            dataIndex: "models",
            key: "models",
            width: "150px",
            title: "Models",
            align: "center" as "center",
            fixed: "left" as "left",
        },
        // {
        //     dataIndex: "train_metrics",
        //     key: "train_metrics",
        //     width: "150px",
        //     title: "Train metrics",
        //     align: "center" as "center",

        // },
        {
            dataIndex: "eval_metrics",
            key: "eval_metrics",
            width: "150px",
            title: () => <Space direction="vertical">
                <Text>{t("common.evaluationMetrics")}</Text>
                <Text type="secondary">{"(Mean Absolute percentage error)"}</Text>
            </Space>,
            align: "center" as "center",
            render: (text: any) => {
                const response = (!isNaN(text) && typeof text === "number") ? getModelErrorInfo(text, "mean_absolute_percentage_error") : null
                return <Space>
                    <Text>{text ?? "-"}</Text>
                    <Tooltip title={response?.modelResult}>
                        {response && <DeploymentUnitOutlined style={{ color: response?.color }} />}
                    </Tooltip>
                </Space>
            }
        },
    ]

    const tableData = useMemo(() => {
        if (!currentProperty) return []
        const experiment = experiments.find((res: any) => !isNaN(Number(res?.predicted_properties?.[currentProperty]?.value)))
            || experiments?.[0]
        const data = Object.entries(experiment?.property_data?.[currentProperty] || {}).map(([key, value]: any) => ({
            models: key,
            // train_metrics: getValue(value?.train_metrics),
            eval_metrics: getValue(value?.eval_metrics)
        }))
        return data
    }, [experiments, currentProperty, getValue])


    return (
        <Modal
            maskClosable={false}
            title={t("common.propertiesMetrics")}
            open={metricsVisible} onCancel={() => { setCurrentProperty(null); setMetricsVisible(false) }}
            footer={null} width={800}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Table
                    title={() => <Text strong>{
                        displayNames?.properties?.[currentProperty]?.name ?? currentProperty}
                    </Text>}
                    bordered
                    pagination={false}
                    scroll={{ x: 400 }}
                    columns={columns}
                    dataSource={tableData}
                />
            </Space>
        </Modal>
    )
}
