import { Slider, Switch } from "antd";
import { memo, useEffect, useState } from "react";

type TProps = {
    minDiameter: number,
    maxDiameter: number,
    showAllMasks: boolean,
    showOriginalImage: boolean,
    setDiameterRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>,
    setShowAllMasks: React.Dispatch<React.SetStateAction<boolean>>,
    setShowOriginalImage: React.Dispatch<React.SetStateAction<boolean>>,
    showBBox: boolean,
    setShowBBox: React.Dispatch<React.SetStateAction<boolean>>,
}

const DEFAULT_MIN_RANGE = 8;

const PaintFilmAnalysisDetailsSidebar = ({ minDiameter, maxDiameter, showAllMasks, showOriginalImage, setDiameterRangeThreshold, setShowAllMasks, setShowOriginalImage, showBBox, setShowBBox }: TProps) => {
    const [diameterRange, setDiameterRange] = useState<any[]>([]);

    useEffect(() => {
        let minVal = Math.max(DEFAULT_MIN_RANGE, minDiameter);
        if(minVal >= maxDiameter) minVal = minDiameter; 
        setDiameterRange([minVal, maxDiameter]);
        setDiameterRangeThreshold([minVal, maxDiameter]);
    }, [minDiameter, maxDiameter, setDiameterRangeThreshold]);

    return (
        <div className="sidebar">
            <div className="content">
                <div className="option">
                    <div className="label">Show Original Image</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showOriginalImage}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowOriginalImage(val);
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                <div className="option">
                    <div className="label">Diameter</div>
                    <Slider
                        range
                        step={0.1}
                        min={minDiameter}
                        max={maxDiameter}
                        value={diameterRange}
                        onChange={(val) => setDiameterRange(val)}
                        onChangeComplete={(val) => {
                            setDiameterRangeThreshold(val)
                        }}
                        style={{ width: 200 }}
                    />
                </div>

                <div className="option">
                    <div className="label">Show All Masks</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showAllMasks}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowAllMasks(val)
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                <div className="option">
                    <div className="label">Show Bounding Box</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showBBox}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowBBox(val)
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(PaintFilmAnalysisDetailsSidebar);