import { LoadingOutlined } from '@ant-design/icons'
import { Form, Modal, Row, Space, Spin, Typography, Input, Tag, message } from 'antd'
import React, { Dispatch, useEffect, useMemo, useState } from 'react'
import { AsyncStates, commonDefaultPageSize } from 'src/constants'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { Legend } from '../prediction-result-zeon/legend'
import { volcano } from "@ant-design/colors"
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { addFavoritesRequest, getFavoritesRequest } from 'src/store/actions/formulate'
import { ModelConfig } from 'src/typings'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'

const { Text } = Typography

type Props = {
  visible: boolean
  setShowAddFavoriteModal: Dispatch<React.SetStateAction<boolean>>
  ingredientsParameterList: string[]
  processingParameterList: string[]
  favoritesCurrentPage: number,
  forwardModalVersionData: ModelConfig | undefined
}

export const AddToFavoritesModal = ({ visible, setShowAddFavoriteModal, ingredientsParameterList, processingParameterList, favoritesCurrentPage, forwardModalVersionData }: Props) => {
  const [form] = Form.useForm()
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()

  const dispatch = useDispatch()
  const displayLabel = useSelector((state: StoreState) => state.displayNames.data)
  const addFavoriteStatus = useSelector((state: StoreState) => state.formulate.addFavoriteStatus)

  const filteredProcessingParameterList = useMemo(() => processingParameterList.filter((item) => item), [processingParameterList])

  useEffect(() => {
    if (addFavoriteStatus === AsyncStates.SUCCESS) {
      dispatch(getFavoritesRequest({
        page_num: favoritesCurrentPage,
        page_size: commonDefaultPageSize
      }))
      setShowAddFavoriteModal(false)
      form.resetFields()
    }
  }, [dispatch, favoritesCurrentPage, addFavoriteStatus, setShowAddFavoriteModal, form])

  const handleOk = (values: any) => {
    if (!values.name.trim()) {
      message.error("Please enter a name for the favorite")
      return
    }
    const payload = {
      ...favorite,
      name: values.name,
    }
    dispatch(addFavoritesRequest(payload))
  }

  const [favorite, setFavorite] = useState({
    name: '',
    ingredients: ingredientsParameterList,
    processing: filteredProcessingParameterList
  })

  useEffect(() => {
    setFavorite({
      name: '',
      ingredients: ingredientsParameterList,
      processing: filteredProcessingParameterList
    })
  }, [ingredientsParameterList, filteredProcessingParameterList])

  const getDisplayName = (type: string, key: string) => {
    return displayLabel?.[type]?.[key]?.name ?? key
  }


  const handleCancel = () => {
    setShowAddFavoriteModal(false)
  }

  return (
    <Modal
      open={visible}
      width={600}
      onCancel={() => {
        setShowAddFavoriteModal(false);
      }}
      footer={null}
      title={t("common.addToFavourites")}
    >
      <Spin
        indicator={<LoadingOutlined />}
        spinning={addFavoriteStatus === AsyncStates.LOADING}
      >
        <Space
          direction="vertical"
          style={{ width: "100%" }}
          size="large"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Legend from={"favorites"} />
            {/* <Space style={{ display: "flex", gap: "20px" }}> */}
            <Space
              style={{
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "#fafafa",
                border: "1px solid #ccc",
                maxHeight: "125px",
                overflowY: "auto",
                padding: "10px",
                width: "100%",
              }}
            >
              {ingredientsParameterList.map((ingredient) => (
                <Tag key={ingredient}>
                  <Text
                    style={{
                      color: "#222",
                      backgroundColor: "white",
                    }}
                  >
                    {forwardModalVersionData?.display_names?.["ingredients"]?.[ingredient] ?? getDisplayName("ingredients", ingredient)}
                  </Text>
                </Tag>
              ))}
              {filteredProcessingParameterList.map((processing) => (
                <Tag key={processing}>
                  <Text
                    style={{
                      color: volcano[5],
                      backgroundColor: volcano[0],
                    }}
                  >
                    {getDisplayName("processing", processing)}
                  </Text>
                </Tag>
              ))}
            </Space>
            {/* </Space> */}
          </div>
          {/* <Text>{`You are deleting`}<Text strong>{currentProject?.name}.</Text>{` All Work Orders, related formulations, reports, files, will be removed permanently.This cannot be undone.Confirm delete? `}</Text> */}
          <Form
            layout="vertical"
            onFinish={handleOk}
            form={form}
            name="favourites"
            requiredMark={false}
          >
            <Form.Item
              label={t("common.name")}
              name="name"
              rules={[
                { required: true, message: t("workOrderDetails.enterName") },
              ]}
              required 
				      tooltip={requiredFieldStar}
            >
              <Input type="text" />
            </Form.Item>
            <Row justify="end" style={{ marginTop: 20 }}>
              <Space>
                <StyledButton onClick={handleCancel}>
                  {t("common.cancel")}
                </StyledButton>
                <StyledButton type="primary" htmlType="submit">
                  {t("common.confirm")}
                </StyledButton>
              </Space>
            </Row>
          </Form>
        </Space>
      </Spin>
    </Modal>
  );
}
