import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Row, Space, Spin, Popconfirm, Pagination, Modal
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates, EXP_PAGE_SIZE } from 'src/constants';
import { StoreState } from 'src/store/configureStore';
import { Dataset } from 'src/utils/getHeaders';
import { deleteCharacterizationSetsRequest, saveCharacterizationSetsRequest, } from 'src/store/actions/characterizationMethods';
import { StyledButton } from 'src/styled_components/StyledButton';
import { DataSheetVariation } from './DataSheetVariation';
import { CreateNewMethod } from './modals/CreateNewMethod';
import { AddMethodModal } from './modals/AddMethodModal';
import LibraryDrawer from '../drawers/LibraryDrawer';
import { setEditingState } from 'src/store/actions/workOrderDetails';
import { useMethodDetails } from 'src/utils/useMethodDetails';
import { fetchExperimentRequest } from 'src/store/actions/experiment';
import { useValue } from 'src/utils/useValue';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon';


export const Characterizations = ({ initialDisplayIdList, initialTrialSetList, dataset, userAccess, showModal, setLoadData }: any) => {
    const dispatch = useDispatch()
    const { getValue } = useValue()
    const { deleteCharacterizationsStatus, saveCharacterizationsStatus, methodListStatus, methodList } = useSelector((state: StoreState) => state.characterizationMethods)
    const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder)
    const displayNames = useSelector((state: StoreState) => state.displayNames?.data)
    const experiments = useSelector((state: StoreState) => state.workOrderDetails.experiment)
    const [viewLibraryDrawer, setViewLibraryDrawer] = useState(false)
    const [addMethodModal, setAddMethodModal] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [addMethodFlag, setAddMethodFlag] = useState(false)
    const expIndex = useMemo(() => workOrder?.stages?.findIndex((stage: any) => stage?.identifier === workOrder?.work_order_parent_stage), [workOrder?.stages, workOrder?.work_order_parent_stage])
    const expTotal = useSelector((state: StoreState) => state.workOrderDetails.total)
    const editingState = useSelector((state: StoreState) => state.workOrderDetails.editingState)
    const [current, setCurrent] = useState<number>(1)
    const experimentStatus = useSelector((state: StoreState) => state.workOrderDetails.experimentStatus)
    const [selectedRecords, setSelectedRecords] = useState<any>([])
    // const trialsForAddMethodOptions = useMemo(() => initialTrialSetList.map((trial: any, index: number) => ({ value: trial?.id_set?.formulation_id, label: initialDisplayIdList[index] })), [initialDisplayIdList, initialTrialSetList])

    const { getVariationDetails } = useMethodDetails()

    useEffect(() => {
        setCurrent(1)
    }, [dataset])

    useEffect(() => {
        if (saveCharacterizationsStatus === AsyncStates.SUCCESS) {
            setAddMethodFlag(false)
            dispatch(setEditingState(false))
        }
    }, [saveCharacterizationsStatus, dispatch])

    const [data, setData] = useState<any>([])
    useEffect(() => {
        if (Array.isArray(initialTrialSetList?.[0]?.[dataset]) && experimentStatus === AsyncStates.SUCCESS) {
            const newData: any = []
            initialTrialSetList?.[0]?.[dataset]?.forEach((variation: any, variationIndex: number) => {
                const currentVariation = initialTrialSetList.map((trial: any) => trial?.[dataset]?.[variationIndex])
                const parametersData = [...new Set(currentVariation?.flatMap((key: any) => Object.keys(key?.data || {})))].map((res: any) => ({ identifier: res, parameter: displayNames?.[dataset]?.[res]?.name }))
                if (dataset === Dataset.properties) {
                    initialTrialSetList.forEach((exp: any, trialIndex: number) => {
                        parametersData.forEach((res: any) => {
                            const current = exp?.[dataset]?.[variationIndex]
                            const currentRecord = current?.data
                            res.unit = currentRecord?.[res?.identifier]?.unit
                            res.category = displayNames?.properties?.[res?.identifier]?.category
                            const value = currentRecord?.[res?.identifier]?.value
                            if (!!current?.meta?.length && ["overall_filtration_rate_kpi_gdivided_bymin", "leaching_time_gdivided_bymin"].includes(res?.identifier)) {
                                res[exp?.id_set?.formulation_id] = getValue(current.meta[current.meta.length - 1]?.rate)
                            } else {
                                if (res?.identifier === "initial_dry_matter_in_substrate_g") {
                                    const currentTrial = experiments?.find((trial: any) => trial?.id_set?.formulation_id === exp?.id_set?.formulation_id)
                                    if (!!currentTrial?.ingredients?.["substrate1"]?.value) {
                                        res[exp?.id_set?.formulation_id] = getValue(90 / 100 * Number(currentTrial?.ingredients?.["substrate1"]?.value))
                                    }
                                } else {
                                    res[exp?.id_set?.formulation_id] = getValue(value)
                                }
                            }
                            res.index = variationIndex
                            res.variation_id = variation?.variation_id
                            res.characterization_set_id = variation?.characterization_set_id
                        })
                    })
                }
                else if (dataset === Dataset.characterizations) {
                    parametersData.forEach((res: any) => {
                        const currentRecord = initialTrialSetList[0]?.[dataset]?.[variationIndex]?.data
                        res.unit = currentRecord?.[res?.identifier]?.unit
                        res.category = displayNames?.characterizations?.[res?.identifier]?.category
                        const value = currentRecord?.[res?.identifier]?.value
                        res.input = getValue(value)
                        res.index = variationIndex
                        res.variation_id = variation?.variation_id
                        res.characterization_set_id = variation?.characterization_set_id
                    })
                }
                newData[variationIndex] = parametersData
            })
            return setData(newData)
        } else {
            setData([])
        }
    }, [dataset, displayNames, experimentStatus, experiments, initialTrialSetList, getValue])

    const [tableData, setTableData] = useState<any>([])
    useEffect(() => {
        if (Array.isArray(initialTrialSetList?.[0]?.[dataset]) && methodListStatus === AsyncStates.SUCCESS && !addMethodFlag) {
            setTableData((prevState: any) => {
                return initialTrialSetList?.[0]?.[dataset]?.map((res: any, index: any) => {
                    const variatonDetails = getVariationDetails(res?.variation_id, experiments?.[0]?.[dataset]?.[0]?.[dataset]?.[index]?.data)
                    return ({
                        variation_id: res?.variation_id,
                        characterization_set_id: res?.characterization_set_id,
                        variation: variatonDetails?.variationName,
                        description: variatonDetails?.variationDesc,
                        key: index,
                    })
                })
            })
        }
        if (!Array.isArray(initialTrialSetList?.[0]?.[dataset]) && methodListStatus === AsyncStates.SUCCESS && !addMethodFlag) {
            setTableData([])
        }
    }, [dataset, experiments, initialTrialSetList, getVariationDetails, methodListStatus, addMethodFlag])

    useEffect(() => {
        if (deleteCharacterizationsStatus === AsyncStates.SUCCESS) {
            setSelectedRecords((prevRecordState: any) => {
                setTableData((prevTableState: any) => prevTableState.filter((res: any) => !prevRecordState.includes(res.key)).map((res: any, key: number) => ({ ...res, key })))
                setData((prevTableState: any) => {
                    const newData = prevTableState.filter((res: any, index: number) => !prevRecordState.includes(index))
                    newData.forEach((rowData: any, index: any) => {
                        rowData.forEach((res: any) => {
                            res.index = index
                        })
                    })
                    return newData
                })
                return []
            })
        }
    }, [deleteCharacterizationsStatus])

    const [metaData, setMetaData] = useState([])
    const [currentTrial, setCurrentTrial] = useState("")
    const [metaTitle, setMetaTitle] = useState("")

    const saveCharacterizationsData = () => {
        const payload = initialTrialSetList.map((res: any, index: number) => ({
            formulation_id: res?.id_set?.formulation_id,
            characterization_id: res?.id_set?.characterization_id,
            properties_id: res?.id_set?.properties_id,
            input_data: data.map((variation: any, variationIndex: number) => {
                const variationId = tableData?.[variationIndex]?.variation_id
                let currentVarition
                if (Array.isArray(res?.properties)) {
                    currentVarition = res?.properties?.find((res: any) => res?.variation_id === variationId)
                }
                return ({
                    characterization_set_id: initialTrialSetList?.[0]?.[dataset]?.[variationIndex]?.characterization_set_id || null,
                    variation_id: variationId,
                    ...(currentVarition?.meta?.length && { meta: currentVarition?.meta }),
                    ...((res?.id_set?.formulation_id === currentTrial && metaTitle === getVariationDetails(variationId).variationName) && { meta: metaData }),
                    data: variation.reduce((obj: any, ele: any) => ({
                        ...obj, [ele.identifier]: ({
                            unit: ele.unit, category: ele.category,
                            value: dataset === Dataset.characterizations ? ele?.["input"] : ele?.[res?.id_set?.formulation_id]
                        })
                    }), {})
                })
            })
        }))
        dispatch(saveCharacterizationSetsRequest({
            experiment_id: workOrder?.experiment_id?.[expIndex],
            input_key: dataset,
            exp_data: payload,
            page_num: current,
            page_size: EXP_PAGE_SIZE,
        }))
    }

    const deleteCharacterizationMethods = () => {
        let characterizationSetIds = []
        if (Array.isArray(initialTrialSetList?.[0]?.[dataset])) {
            characterizationSetIds = initialTrialSetList?.[0]?.[dataset]?.filter((res: any, index: number) => selectedRecords?.includes(index)).map((res: any) => res?.characterization_set_id)
        }
        dispatch(deleteCharacterizationSetsRequest({
            method_type: "characterizations",
            experiment_id: workOrder?.experiment_id?.[expIndex],
            characterization_set_id: characterizationSetIds,
            page_num: current,
            page_size: EXP_PAGE_SIZE,
        }))
    }


    return (
        <>
            <Spin spinning={saveCharacterizationsStatus === AsyncStates.LOADING || deleteCharacterizationsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Space size='large' direction='vertical' style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Row justify="space-between">
                        {!userAccess &&
                            <Space>
                                {<StyledButton
                                    icon={<PlusOutlined />}
                                    onClick={() => { setAddMethodModal(true) }
                                    }>{"Add Method"}</StyledButton>}
                                {
                                    <Popconfirm title={"Delete selected method(s) from this Work Order ?"} onConfirm={deleteCharacterizationMethods}>
                                        <StyledButton icon={<StyledDeleteIcon />} danger disabled={!selectedRecords?.length}>{"Delete Method(s)"}</StyledButton>
                                    </Popconfirm>
                                }
                            </Space>
                        }
                        <Space>
                            <StyledButton onClick={saveCharacterizationsData} type='primary'>{"Save"}</StyledButton>
                            {<StyledButton onClick={() => setViewLibraryDrawer(true)}>{"View Library"}</StyledButton>}
                        </Space>
                    </Row>
                    {dataset === Dataset.properties &&
                        <Row justify="end">
                            <Pagination total={expTotal} current={current} pageSize={20} showSizeChanger={false}
                                onChange={(e) => {
                                    if (editingState) {
                                        Modal.confirm({
                                            title: "Unsaved changes present",
                                            onCancel: () => { },
                                            onOk: () => {
                                                setAddMethodFlag(false)
                                                setCurrent(e)
                                                setLoadData(true)
                                                dispatch(fetchExperimentRequest({
                                                    experiment_id: [workOrder.experiment_id?.[expIndex]],
                                                    page_num: e,
                                                    page_size: EXP_PAGE_SIZE
                                                }))
                                            }
                                        })
                                    } else {
                                        setAddMethodFlag(false)
                                        setCurrent(e)
                                        setLoadData(true)
                                        dispatch(fetchExperimentRequest({
                                            experiment_id: [workOrder.experiment_id?.[expIndex]],
                                            page_num: e,
                                            page_size: EXP_PAGE_SIZE
                                        }))
                                    }
                                }} />
                        </Row>
                    }
                    <Spin spinning={experimentStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                        <DataSheetVariation
                            trialsList={initialTrialSetList}
                            dataset={dataset}
                            initialDisplayIdList={initialDisplayIdList}
                            data={data}
                            setData={setData}
                            tableData={tableData}
                            selectedRecords={selectedRecords}
                            setSelectedRecords={setSelectedRecords}
                            disabled={userAccess}
                            metaData={metaData}
                            setMetaData={setMetaData}
                            saveCharacterizationsData={saveCharacterizationsData}
                            showModal={showModal}
                            currentTrial={currentTrial}
                            setCurrentTrial={setCurrentTrial}
                            metaTitle={metaTitle}
                            setMetaTitle={setMetaTitle}
                        />
                    </Spin>
                </Space>
                <CreateNewMethod modalVisible={modalVisible} setModalVisible={setModalVisible} />
                <AddMethodModal
                    setData={setData}
                    setTableData={setTableData}
                    addMethodModal={addMethodModal}
                    setAddMethodModal={setAddMethodModal}
                    setModalVisible={setModalVisible}
                    dataset={dataset}
                    setAddMethodFlag={setAddMethodFlag}
                    initialTrialSetList={initialTrialSetList}
                />
                <LibraryDrawer viewLibraryDrawer={viewLibraryDrawer} setViewLibraryDrawer={setViewLibraryDrawer}
                    setModalVisible={setModalVisible} disabled={userAccess} methodType={"characterizations"}
                    methodList={methodList}
                />
            </Spin>
        </>
    )
}



