import React, { memo } from "react"
import { List, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
const { Text } = Typography

export const CorrelationNote = memo(({ data }: any) => {
	const [t] = useTranslate()

	const listData = [
		`${t("dataSummary.Correlation.2ComponentsCombinationNotAvailable")} - ${t(
			"dataSummary.Correlation.2ComponentsCombinationNotAvailableExample"
		)}`,
		`${t("dataSummary.Correlation.2ComponentsCombinationVariation")} - ${t(
			"dataSummary.Correlation.2ComponentsCombinationVariationExample"
		)}`,
		`${t("dataSummary.3differentCombinations")}`,
	]

	return (
		<List
			header={
				<Text strong>
					{Object.keys(data.correlation_summary || {}).length > 0 ? `${t("workOrderDetails.note")} : ${t("dataSummary.correlationHeatmap")}-` : t('dataSummary.correlationHeatMapUnavailable')}
				</Text>
			}
			size="small"
			bordered
			dataSource={listData}
			style={{ padding: 10 }}
			renderItem={(item) => (
				<List.Item>
					<Text strong type="secondary">
						{item}
					</Text>
				</List.Item>
			)}
		/>
	)
})

CorrelationNote.displayName = "Correlation Note"
