import { call, put, select, takeLatest } from "redux-saga/effects"
import jwtManager from "src/utils/jwtManager"
import {
  applyForQC,
  getQCFiles,
  deleteFile,
  getFileExtractions,
  saveChangesAndApply,
  getRegisterLinkData,
  linkRecurringCycle,
  syncOnDemand,
  validateLink,
  generateReport,
  getQCFilesFilters,
} from "src/services/qualityCheck";
import {
  qcGetFilesSuccess,
  qcGetFilesFailure,
  qcGetFilesRequest,
  qcApplyFilesSuccess,
  qcApplyFilesFailure,
  qcApplyFilesRequest,
  qcFileExtractionsRequest,
  qcFileExtractionsFailure,
  qcFileExtractionsSuccess,
  qcSaveChangesAndApplyFailure,
  qcSaveChangesAndApplySuccess,
  qcSaveChangesAndApplyRequest,
  qcSetIsReportSectionUnsaved,
  qcValidateLinkFailure,
  qcValidateLinkSuccess,
  qcLinkRecurringCycleFailure,
  qcLinkRecurringCycleSuccess,
  qcSyncOnDemandFailure,
  qcSyncOnDemandSuccess,
  qcLinkRecurringCycleRequest,
  qcSyncOnDemandRequest,
  qcValidateLinkRequest,
  qcGetRegisterLinkDataSuccess,
  qcGetRegisterLinkDataFailure,
  qcGetRegisterLinkDataRequest,
  qcSetRegisterLinkModalVisible,
  qcGenerateReportSuccess,
  qcGenerateReportFailure,
  qcGenerateReportRequest,
  qcDeleteFileSuccess,
  qcDeleteFileFailure,
  qcDeleteFileRequest,
  qcSetGetFilesPageNumber,
  qcGetFilesFiltersSuccess,
  qcGetFilesFiltersFailure,
  qcGetFilesFiltersRequest,
  qcClearSelectedFilesForReport,
  qcSetFileUploadModalVisible,
} from "../actions/qualityCheck";
import { notification } from "antd";

function* qcGetFilesSaga({ payload }: any): Generator<any, any, any> {
  const defaultPayload = {
    page_num: yield select((state) => state.qualityCheck.getFilesPageNumber),
    page_size: yield select((state) => state.qualityCheck.getFilesPageSize),
    filters: yield select((state) => state.qualityCheck.getFilesFilters),
  }
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(getQCFiles, {...defaultPayload, ...payload }, headers)
    if (status === "Success") {
      yield put(qcGetFilesSuccess(data))
      yield put(qcSetFileUploadModalVisible(false))
      yield put(qcSetGetFilesPageNumber(payload.page_num))
    } else {
      yield put(qcGetFilesFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetFilesFailure(error))
  }
}

function* qcGetFilesFiltersSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(getQCFilesFilters, payload, headers)
    if (status === "Success") {
      yield put(qcGetFilesFiltersSuccess(data))
    } else {
      yield put(qcGetFilesFiltersFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetFilesFiltersFailure(error))
  }
}

function* qcApplyFilesSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(applyForQC, payload, headers)
    if (status === "Success") {
      yield put(qcApplyFilesSuccess(data))
      yield put(qcGetFilesRequest({ page_num: 1 }))
    } else {
      yield put(qcApplyFilesFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcApplyFilesFailure(error))
  }
}

function* qcFileExtractionsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(getFileExtractions, payload, headers)
    if (status === "Success") {
      yield put(qcFileExtractionsSuccess(data))
    } else {
      yield put(qcFileExtractionsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcFileExtractionsFailure(error))
  }
}

function* qcSaveChangesAndApplySaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(saveChangesAndApply, payload, headers)
    if (status === "Success") {
      yield put(qcSaveChangesAndApplySuccess(data))
      yield put(qcFileExtractionsRequest({
        file_id: payload.file_id,
      }))
      yield put(qcSetIsReportSectionUnsaved(false))
    } else {
      yield put(qcSaveChangesAndApplyFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcSaveChangesAndApplyFailure(error))
  }
}

function* qcDeleteFileSaga({ payload }: any): Generator<any, any, any> {
  try {
    const defaultPayload = {
      page_num: yield select((state) => state.qualityCheck.getFilesPageNumber),
      page_size: yield select((state) => state.qualityCheck.getFilesPageSize),
      filters: yield select((state) => state.qualityCheck.getFilesFilters),
    }

    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(deleteFile, payload, headers)
    if (status === "Success") {
      yield put(qcDeleteFileSuccess(data))
      yield put(qcClearSelectedFilesForReport())
      yield put(qcGetFilesRequest(
        defaultPayload
      ))
    } else {
      yield put(qcDeleteFileFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcDeleteFileFailure(error))
  }
}

function* qcGetRegisterLinkDataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(getRegisterLinkData, payload, headers)
    if (status === "Success") {
      yield put(qcGetRegisterLinkDataSuccess(data))
    } else {
      yield put(qcGetRegisterLinkDataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetRegisterLinkDataFailure(error))
  }
}

function* qcValidateLinkSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(validateLink, payload, headers)
    if (status === "Success") {
      yield put(qcValidateLinkSuccess(data))
    } else {
      yield put(qcValidateLinkFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcValidateLinkFailure(error))
  }
}

function* qcLinkRecurringCycleSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(linkRecurringCycle, payload, headers)
    if (status === "Success") {
      yield put(qcLinkRecurringCycleSuccess(data))
      yield put(qcSetRegisterLinkModalVisible(false))
      notification.success({ message })
    } else {
      yield put(qcLinkRecurringCycleFailure(message))
      message && notification.error({ message })
      yield put(qcSetRegisterLinkModalVisible(true))
    }
  } catch (error) {
    yield put(qcLinkRecurringCycleFailure(error))
  }
}

function* qcSyncOnDemandSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(syncOnDemand, payload, headers)
    if (status === "Success") {
      yield put(qcSyncOnDemandSuccess(data))
      yield put(qcGetFilesRequest())
      notification.success({ message })
    } else {
      yield put(qcSyncOnDemandFailure(message))
      message && notification.error({ message })
      yield put(qcSetRegisterLinkModalVisible(true))
    }
  } catch (error) {
    yield put(qcSyncOnDemandFailure(error))
  }

}


function* qcGenerateReportSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const filesToReport = yield select((state) => state.qualityCheck.filesToReport)

    const selectedFiles =  Object.values(filesToReport).flat()

    const finalPayload = {
      file_ids: selectedFiles
    }

    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(generateReport, finalPayload, headers)
    if (status === "Success") {
      yield put(qcGenerateReportSuccess(data))
      window.open(data, "_blank")
    } else {
      yield put(qcGenerateReportFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGenerateReportFailure(error))
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(qcGetFilesRequest, qcGetFilesSaga)
  yield takeLatest(qcGetFilesFiltersRequest, qcGetFilesFiltersSaga)
  yield takeLatest(qcApplyFilesRequest, qcApplyFilesSaga)
  yield takeLatest(qcFileExtractionsRequest, qcFileExtractionsSaga)
  yield takeLatest(qcSaveChangesAndApplyRequest, qcSaveChangesAndApplySaga)

  yield takeLatest(qcDeleteFileRequest, qcDeleteFileSaga)

  yield takeLatest(qcGetRegisterLinkDataRequest, qcGetRegisterLinkDataSaga)
  yield takeLatest(qcValidateLinkRequest, qcValidateLinkSaga)
  yield takeLatest(qcLinkRecurringCycleRequest, qcLinkRecurringCycleSaga)
  yield takeLatest(qcSyncOnDemandRequest, qcSyncOnDemandSaga)

  yield takeLatest(qcGenerateReportRequest, qcGenerateReportSaga)
}
