import { Form, Mentions } from "antd";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useState } from "react";
import { nanoid } from "nanoid";
import { doc, setDoc } from "firebase/firestore";
import { FB_COLLECTION_NAME_COMMENTS, firestoreDb } from "src/utils/firebase";
import { useWOCommentsSocket } from "src/utils/hooks/useWOCommentsSocket";
import { StyledButton } from "src/styled_components/StyledButton";

// const commentEditorMutation = graphql`
//   mutation CommentEditorMutation(
//     $comment: String
//     $work_order_id: String
//     $tags: [String]
//     $key: String
//     $token: String
//   ) {
//     addWoComment(
//       comment: $comment
//       work_order_id: $work_order_id
//       tags: $tags
//       key: $key
//       token: $token
//     ) {
//       comment
//     }
//   }
// `;

export const CommentEditor = (props: any) => {
  const [t] = useTranslate();
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  );
  const { user_id, user_name, image_url } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const mentions = useSelector((state: StoreState) => state.teams.data);
  //   const [commit, isInFlight] = useMutation(commentEditorMutation);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const [value, setValue] = useState("");

  const socket = useWOCommentsSocket('comments', work_order_id)

  const addComment = async () => {
    setIsAddingComment(true);
    const userIdsTags = mentions.reduce((acc, curr) => {
      if (value.trim()?.includes(curr.user_name)) {
        acc.push(curr.user_id);
      }
      return [...new Set(acc)];
    }, []);
    const commentId = `PlCom_${nanoid(10)}`;
    const commentPayload = {
      user_name: user_name,
      user_id: user_id,
      image_url: image_url,
      comment_id: commentId,
      work_order_id: work_order_id,
      comment: value?.trim(),
      status: "active",
      tags: userIdsTags,
      created: new Date().toISOString(),
    };
    await socket?.emit("create_comment", { comment: value?.trim(), tags: userIdsTags })
    await setDoc(
      doc(firestoreDb, FB_COLLECTION_NAME_COMMENTS, commentId),
      commentPayload
    );
    setValue("");
    console.log(commentPayload);
    setIsAddingComment(false);
  };

  return (
    <>
      <Form.Item>
        <Mentions
          autoSize={{ minRows: 4 }}
          onChange={setValue}
          placeholder={t("commentsSection.leaveAComment")}
          value={value}
        >
          {mentions.map((member: any) => (
            <Mentions.Option value={member.user_name} key={member.user_name}>
              {member.user_name}
            </Mentions.Option>
          ))}
        </Mentions>
      </Form.Item>
      <Form.Item>
        <StyledButton
          loading={isAddingComment}
          onClick={
            addComment
          }
          type="primary"
          disabled={!Boolean(value?.trim())}
        >
          {t("common.comment")}
        </StyledButton>
      </Form.Item>
    </>
  );
};
