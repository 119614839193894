import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Form, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import { getCharacterizationMethodsZeonRequest } from "src/store/actions/formulate"
import { ZeonCharacterizationVariations } from "./zeon-characterization-variations"
import useTranslate from "src/utils/useTranslate"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
const { useForm } = Form

type P = {
	selectedCharacterization: any
	setSelectedCharacterization: Dispatch<
		SetStateAction<{[key:string]:any}>
	>
	ings_procs: string[]
	properties: string[]
	tab: string
	currentSelectedStage:number
}

type FormData = {
	characterization: string
}

export type CharacsList = {
	characterization_id: string
	characterization_name: string
}

const Component = ({
	setSelectedCharacterization,
	ings_procs,
	properties,
	tab,
	currentSelectedStage
}: P) => {
	const [form] = useForm<FormData>()
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const requiredFieldStar = useRequiredFieldStar()
	const {
		characterizationMethodsZeon: data,
		characterizationMethodsZeonStatus,
	} = useSelector((state: StoreState) => state.formulate)

	const [characsList, setCharacsList] = useState<any>({
		inverse: [],
		forward: []
	})
	const [variationsList, setVariationsList] = useState<any>({
		forward: [],
		inverse: []
	})
	const project_id = useSelector((state: StoreState) => state.projects.current)
	const currentTab = useMemo(() => tab === "properties_prediction" ? "forward" : tab === "formulation_prediction" ? "inverse" : "inverse", [tab])

	const [selectedVariations, setSelectedVariations] = useState<any>({
		inverse: [],
		forward: []
	})

	useEffect(() => {
		if (data?.[currentTab] && data[currentTab]?.type === "methods") {
			setCharacsList((prev: any) => {
				return {
					...prev,
					[currentTab]: data[currentTab]?.data
				}
			})
		}
		if (data?.[currentTab] && data[currentTab]?.type === "variations") {
			if (data?.[currentTab] && data[currentTab]?.data.variations.length > 0) {
				setSelectedCharacterization((prev) => {
					const newState=JSON.parse(JSON.stringify(prev))
					newState[currentSelectedStage][currentTab] = {
							characterization_id: data[currentTab]?.data.characterization_id,
							characterization_name: data[currentTab]?.data.characterization_name,
							variations: [],
						}
					return {
						...newState
						}
				})
				setVariationsList((prev: any) => {
					return {
						...prev,
						[currentTab]: data[currentTab]?.data.variations
					}
				})
			} else {

				setVariationsList((prev: any) => {
					return {
						...prev,
						[currentTab]: []
					}
				})
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, setSelectedCharacterization])

	useEffect(() => {
		if (currentTab === "inverse") {
			dispatch(
				getCharacterizationMethodsZeonRequest({
					payload: {
						characterization_method_id: "",
					},
					from: currentTab
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => {
		setVariationsList({
			inverse: [],
			forward: []
		})
		form.resetFields()
	}, [project_id, form])


	function handleFetchVariations(values: FormData) {
		setSelectedCharacterization((prev) => {
			const newState=JSON.parse(JSON.stringify(prev))
			newState[currentSelectedStage][currentTab] = {}
			return {
				...newState
				}
		})
		dispatch(
			getCharacterizationMethodsZeonRequest({
				payload: {
					characterization_method_id: values.characterization,
					ings_procs,
					properties,
				},
				from: currentTab
			})
		)
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			<Form
				form={form}
				onFinish={handleFetchVariations}
				style={{ display: "flex", gap: "20px", alignItems: "center" }}
				requiredMark={false}
			>
				<Form.Item
					name="characterization"
					style={{ margin: 0 }}
					label={t("common.characterization")}
					rules={[
						{ required: true, message: "Please select a characterization" },
					]}
					required 
				  tooltip={requiredFieldStar}
				>
					<Select
						allowClear
						showSearch
						optionFilterProp="children"
						style={{ width: "350px" }}
						placeholder="Please select a characterization"
					>
						{characsList[currentTab]?.map((charac: any) => {
							return (
								<Select.Option
									key={charac.characterization_id}
									value={charac.characterization_id}
								>
									{charac.characterization_name}
								</Select.Option>
							)
						})}
					</Select>
				</Form.Item>

				<Form.Item style={{ margin: 0 }}>
					<StyledButton
						loading={characterizationMethodsZeonStatus === AsyncStates.LOADING}
						htmlType="submit"
						type="primary"
					>
						Get Characterization Variations
					</StyledButton>
				</Form.Item>
			</Form>

			<ZeonCharacterizationVariations
				loading={characterizationMethodsZeonStatus === AsyncStates.LOADING}
				setSelectedCharacterization={setSelectedCharacterization}
				data={variationsList?.[currentTab]}
				currentTab={currentTab}
				setSelectedVariations={setSelectedVariations}
				selectedVariations={selectedVariations}
			/>
		</div>
	)
}

export const ZeonCharacterizationSelection = (props: P) => {
	return <Component {...props} />
}
