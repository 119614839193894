import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { DownOutlined, HistoryOutlined, PlusOutlined, StarOutlined } from "@ant-design/icons";
import {
  Dropdown,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd"
import { StyledButton } from "src/styled_components/StyledButton"
import { IDataSheet } from "src/components/IDataSheet"
import { Sorter } from "src/components/Sorter"
import useTranslate from "src/utils/useTranslate"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { isTrialValueExceeded } from "../../utils"
import { Cell } from "src/components/IDataSheet/IDataSheet"
import { convertToPrecision } from "src/utils/decorator"
import { AddToFavoritesModal } from "../AddToFavoritesModal"
import { FavouritesDrawer } from "../../FavouritesDrawer"
import { ModelConfig } from "src/typings"
import { useQuery } from "src/utils/useQuery"
import { useValue } from "src/utils/useValue";
import { fixDatasheetColumnsCellRenderer } from "src/utils/general/fix-datasheet-columns"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
const { Option } = Select

type P = {
  addTrials: (currentSelectedStage: number, stageName: string) => void
  ingredientsInputs: any
  ingredientsCategories: any
  addRow: (inputType: string, currentSelectedStage: number) => void
  ingredientsParameterList: string[]
  setProcessingParameterList: Dispatch<
    SetStateAction<{ [key: number]: string[] }>
  >
  setIngredientsParameterList: Dispatch<
    SetStateAction<{ [key: number]: string[] }>
  >
  ingredientsDataList: any[]
  processingDataList: any[]
  deleteTrial: (index: number, currentSelectedStage: number) => void
  paramterListDropDown: (index: number, inputType: string) => JSX.Element
  setIngredientsDataList: Dispatch<SetStateAction<{ [key: number]: any[] }>>
  setProcessingDataList: Dispatch<SetStateAction<{ [key: number]: any[] }>>
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  processingParameterList: string[]
  setFavoritesCurrentPage: Dispatch<SetStateAction<number>>
  favoritesCurrentPage: number
  processingInputs: any
  versionData: ModelConfig | undefined
  groupedIngredientOptions: any
  currentSelectedStage: number
  trialDisplayNameList: { [key: number]: any[] }
  disableDeletionFor: any
  isMultiStageModel: boolean
}

export const IngredientsTable = ({
  addTrials,
  ingredientsInputs,
  ingredientsCategories,
  addRow,
  ingredientsParameterList,
  setIngredientsParameterList,
  ingredientsDataList,
  processingDataList,
  deleteTrial,
  paramterListDropDown,
  setIngredientsDataList,
  setProcessingDataList,
  setUnsavedChanges,
  processingParameterList,
  setFavoritesCurrentPage,
  favoritesCurrentPage,
  setProcessingParameterList,
  processingInputs,
  versionData,
  groupedIngredientOptions,
  currentSelectedStage,
  trialDisplayNameList,
  disableDeletionFor,
  isMultiStageModel,
}: P) => {
  const [t] = useTranslate()
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data || {},
  )
  let query = useQuery()
  let modelVersion = query?.get("version")
  const { getValue, convertValue } = useValue()
  const [showAddFavoriteModal, setShowAddFavoriteModal] = useState(false)
  const [showFavouritesDrawer, setShowFavouritesDrawer] = useState(false)

  const selectedFavoriteItemList = useMemo(
    () =>
      [...ingredientsParameterList, ...processingParameterList].filter(
        (res) => res,
      ),
    [ingredientsParameterList, processingParameterList],
  )

  const hasCategories = useMemo(
    () => Object.keys(ingredientsCategories || {}).length !== 0,
    [ingredientsCategories],
  )

  const modelConfigData = useSelector(
    (state: StoreState) => state.formulate.modelConfigData,
  )

  // const uncategorizedIngredients = useMemo(
  //   () =>
  //     groupedIngredientOptions.length &&
  //       groupedIngredientOptions[groupedIngredientOptions.length - 1]?.label ===
  //       "OTHER"
  //       ? groupedIngredientOptions[groupedIngredientOptions.length - 1]?.options
  //       : 0,
  //   [groupedIngredientOptions]
  // );

  const ingredientsOptionsDropDown = useCallback(
    (parameterIndex: number, trialIndex: number) => {
      return (
        <Select
          style={{ width: "100%" }}
          value={
            ingredientsDataList?.[trialIndex][
            ingredientsParameterList[parameterIndex]
            ]
          }
          bordered={false}
          onChange={(e: any) => {
            setIngredientsDataList((prevState) => {
              return {
                ...prevState,
                [currentSelectedStage]: prevState[currentSelectedStage].map(
                  (currentData, index) => {
                    if (index === trialIndex) {
                      return {
                        ...currentData,
                        [ingredientsParameterList[parameterIndex]]: e,
                      }
                    } else {
                      return currentData
                    }
                  },
                ),
              }
            })
          }}
        >
          {ingredientsInputs?.[ingredientsParameterList[parameterIndex]]?.map(
            (res: any) => {
              return (
                <Option key={res} value={res}>
                  {typeof res === "number"
                    ? res
                    : res?.[0]?.toUpperCase() + res?.slice(1)}
                </Option>
              )
            },
          )}
        </Select>
      )
    },
    [
      ingredientsDataList,
      ingredientsInputs,
      ingredientsParameterList,
      setIngredientsDataList,
      currentSelectedStage,
    ],
  )

  const disableAddButtonLengthLogic = () => {
    return hasCategories
      ? Object.keys(ingredientsInputs).length ===
      ingredientsParameterList.length
      : // Object.keys(ingredientsCategories).length +
      // uncategorizedIngredients.length ===
      // ingredientsParameterList.length
      Object.keys(ingredientsInputs).length ===
      ingredientsParameterList.length
  }

  useEffect(() => {
    setIngredientsDataList((state) => {
      const currentState = state?.[currentSelectedStage]
      if (currentState) {
        const data = currentState.map((id: any, idx: number) => {
          return currentState[idx]
            ? ingredientsParameterList.reduce(
              (acc, parameter) => ({
                ...acc,
                [parameter]: currentState[idx][parameter] || null,
              }),
              {},
            )
            : ingredientsParameterList.reduce(
              (acc, parameter) => ({ ...acc, [parameter]: null }),
              {},
            )
        })
        return {
          ...state,
          [currentSelectedStage]: data,
        }
      } else {
        return {
          ...state,
          [currentSelectedStage]: [
            ingredientsParameterList.reduce(
              (acc, parameter) => ({ ...acc, [parameter]: null }),
              {},
            ),
          ],
        }
      }
    })
  }, [ingredientsParameterList, setIngredientsDataList, currentSelectedStage])

  const handleSorter = (value: string[]) => {
    setIngredientsParameterList((prev) => ({
      ...prev,
      [currentSelectedStage]: value,
    }))
  }

  const linkedTrialIdDisplayName = useMemo(() => {
    return ingredientsParameterList
      ?.filter((val) => Boolean(val))
      ?.reduce(
        (acc, parameter) => ({
          ...acc,
          [parameter]:
            (trialDisplayNameList?.[currentSelectedStage - 1] ?? [])?.find(
              (trial: any) => {
                return trial?.value === parameter
              },
            )?.label ||
            modelConfigData?.[currentSelectedStage - 1]?.display_names
              ?.ingredients?.[parameter],
        }),
        {},
      )
  }, [
    currentSelectedStage,
    ingredientsParameterList,
    trialDisplayNameList,
    modelConfigData,
  ])
  // const inputComponent = useCallback(
  //   (parameterIndex, trialIndex) => {
  //     return (
  //       <div style={{ padding: 10 }}>
  //         <Input
  //           style={{ textAlign: "right" }}
  //           value={ingredientsDataList[trialIndex][ingredientsParameterList[parameterIndex]]}
  //           onChange={(e: any) => {
  //             setIngredientsDataList((prevState) => {
  //               const newState = JSON.parse(JSON.stringify(prevState))
  //               newState[currentSelectedStage][trialIndex][ingredientsParameterList[parameterIndex]] = e.target.value
  //               return { ...newState }
  //             })
  //           }}
  //           onBlur={(e) => {
  //             const value = Number(e.target.value)
  //             setIngredientsDataList((prevState) => {
  //               const newState = JSON.parse(JSON.stringify(prevState))
  //               if (isNaN(value)) {
  //                 message.warning(t("aiEngine.pleaseEnterAValidNumber"))
  //               } else {
  //                 newState[currentSelectedStage][trialIndex][ingredientsParameterList[parameterIndex]] = value
  //                 setUnsavedChanges(true)
  //               }
  //               return { ...newState }
  //             })
  //           }}
  //         />
  //       </div>
  //     )
  //   },
  //   [currentSelectedStage, ingredientsDataList, ingredientsParameterList, setIngredientsDataList, setUnsavedChanges, t],
  // )

  const datasheetData = useMemo(() => {
    return [
      [
        {
          value: t("common.ingredient"),
          component: (
            <Row style={{ padding: 8, marginLeft: 8, minWidth: "400px" }}>
              <Typography.Text strong>
                {t("common.ingredient")}
              </Typography.Text>
            </Row>
          ),
          forceComponent: true,
          readOnly: true,
          width: "400px",
          fixed: true
        },
        {
          value: t("aiEngine.range"),
          component: (
            <Row style={{ padding: 8, marginLeft: 8, minWidth: "200px" }}>
              <Typography.Text strong>
                {t("aiEngine.range")}
              </Typography.Text>
            </Row>
          ),
          readOnly: true,
          forceComponent: true,
          width: "200px",
          fixed: true
        },
        ...ingredientsDataList.map((_, index) => ({
          value: trialDisplayNameList?.[currentSelectedStage]?.[index]?.value,
          component: (
            <Space style={{ padding: 8, marginLeft: 8, minWidth: "150px", justifyContent: 'flex-end' }}>
              <Typography.Text strong>
                {isMultiStageModel
                  ? trialDisplayNameList?.[currentSelectedStage]?.[index].label
                  : `Trial ${index + 1}`}
              </Typography.Text>
              {ingredientsDataList.length > 1 && (
                <Tooltip
                  title={
                    disableDeletionFor?.includes(
                      trialDisplayNameList?.[currentSelectedStage]?.[index].value,
                    )
                      ? "Please Remove Ingredient from next Stage to delete."
                      : null
                  }
                >
                  <Popconfirm
                    disabled={disableDeletionFor?.includes(
                      trialDisplayNameList?.[currentSelectedStage]?.[index].value,
                    )}
                    okText={t("common.ok")}
                    cancelText={t("common.cancel")}
                    title={`${t("common.deleteTrial")}?`}
                    onConfirm={() => {
                      deleteTrial(index, currentSelectedStage)
                    }}
                  >
                    <StyledDeleteIcon
                      style={{ cursor: "pointer", outline: "none" }}
                      disabled={disableDeletionFor?.includes(
                        trialDisplayNameList?.[currentSelectedStage]?.[index].value,
                      )}
                    />
                  </Popconfirm>
                </Tooltip>
              )}
            </Space>
          ),
          width: "150px",
          forceComponent: true,
          readOnly: true,
          className: "datahseet-header-ingredients",
        })),
      ],
      ...ingredientsParameterList.map((key, paramterIndex) => [
        {
          value: paramterIndex,
          component: paramterListDropDown(paramterIndex, "ingredients"),
          forceComponent: true,
          className: "dropdown-remove-cell",
          width: 400,
        },
        {
          value: Array.isArray(ingredientsInputs?.[key])
            ? ingredientsInputs?.[key]
              ?.map((res: any) => {
                if (typeof res === "number") return res
                return res?.[0]?.toUpperCase() + res?.slice(1)
              })
              .join(", ")
            : !!Object.keys(ingredientsInputs?.[key] || {}).length
              ? `(${getValue(ingredientsInputs?.[key]?.min) ?? 0} - ${getValue(ingredientsInputs?.[key]?.max) ?? 0
              }) ${getValue(displayNames?.[displayNames?.units?.data?.ingredients]?.data?.[key]?.[0]) ?? ""
              }`
              : "-",
          readOnly: true,
          width: 200,
          align: "left" as "left"
        },
        ...ingredientsDataList.map((trial: any, trialIndex) => {
          const isInvalid: any =
            trial[ingredientsParameterList[paramterIndex]] === ""
              ? false
              : isTrialValueExceeded(
                Number(trial[ingredientsParameterList[paramterIndex]]),
                ingredientsInputs?.[key]?.min ?? 0,
                ingredientsInputs?.[key]?.max ?? 0,
              )

          return {
            value: getValue(trial[ingredientsParameterList[paramterIndex]]),
            ...(Array.isArray(
              ingredientsInputs?.[ingredientsParameterList[paramterIndex]],
            )
              ? {
                component: ingredientsOptionsDropDown(
                  paramterIndex,
                  trialIndex,
                ),
                forceComponent: true,
              }
              : {}),
            className: isInvalid ? "datahseet-cell-red" : "",
            width: 150,
          } as Cell
        }),
      ]),
      [
        {
          value: t("formulations.total"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8, minWidth: "400px" }}>
              <Typography.Text strong>
                {t("formulations.total")}
              </Typography.Text>
            </Row>
          ),
          forceComponent: true,
          width: 400
        },
        { value: "", readOnly: true, width: 150 },
        ...ingredientsDataList.map((res: any) => ({
          value: convertToPrecision(
            Object.values(res || {}).reduce(
              (sum: any, element: any) =>
                sum + (isNaN(element) ? null : Number(element)),
              0,
            ),
          ),
          readOnly: true,
          width: 100
        })),
      ],
    ]
  }, [
    currentSelectedStage,
    deleteTrial,
    disableDeletionFor,
    displayNames,
    ingredientsDataList,
    ingredientsInputs,
    ingredientsOptionsDropDown,
    ingredientsParameterList,
    isMultiStageModel,
    paramterListDropDown,
    t,
    trialDisplayNameList,
    getValue
  ])

  const [trialsCount, setTrialsCount] = useState<number>(1)

  return (
    <>
      <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
        {((Boolean(modelVersion) &&
          Object.keys(ingredientsInputs ?? {}).length > 0) ||
          !Boolean(modelVersion)) && (
            <Space
              direction="vertical"
              size={"middle"}
              style={{ width: "100%", overflow: "auto" }}
              className="ingredients-table-container"
            >
              <Space style={{ display: "flex", justifyContent: "space-between" }}>
                <Space size="large">
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    {t("common.ingredients")}
                  </Typography.Title>
                  <StyledButton
                    onClick={() => addRow("ingredients", currentSelectedStage)}
                    icon={<PlusOutlined />}
                    disabled={
                      (ingredientsInputs && disableAddButtonLengthLogic()) ||
                      !Object.keys(ingredientsInputs ?? {}).length
                    }
                    type="default"
                    size="small"
                    style={{ borderRadius: 5, outline: "none" }}
                  >
                    {t("common.addIngredients")}
                    {ingredientsCategories["OTHER"]?.options.length}
                  </StyledButton>
                  <Sorter
                    list={ingredientsParameterList.filter((val) => Boolean(val))}
                    onChange={handleSorter}
                    dataset={"ingredients"}
                    aiEngineVersionData={versionData}
                    linkedTrialIdDisplayName={linkedTrialIdDisplayName}
                  />
                  <Popconfirm
                    title={`${t("common.add")} ${t("common.trials")}`}
                    description={
                      <InputNumber
                        value={trialsCount}
                        onChange={(value) => setTrialsCount(Number(value))}
                        controls
                        min={1}
                      />
                    }
                    onConfirm={() => {
                      for (let i = 0; i < trialsCount; i++) {
                        addTrials(currentSelectedStage, modelConfigData?.[0]?.is_multistage ? modelConfigData?.[0]?.all_stages?.[currentSelectedStage - 1] : null)
                      }
                      setTrialsCount(1)
                    }}
                  >
                    <StyledButton
                      type="link"
                      ghost
                      size="small"
                      style={{ borderRadius: 5, outline: "none" }}
                      icon={<PlusOutlined />}
                    >
                      {`${t("common.add")} ${t("common.trials")}`}
                    </StyledButton>
                  </Popconfirm>
                </Space>

                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items: [
                      {
                        label: `${t("common.addToFavourites")}`,
                        key: "1",
                        onClick: () => setShowAddFavoriteModal(true),
                        disabled: selectedFavoriteItemList.length === 0,
                        icon: <StarOutlined />,
                        style: { outline: "none" },
                      },
                      {
                        label: `${t("common.saved")} ${t("common.Favourites")}`,
                        key: "0",
                        onClick: () => setShowFavouritesDrawer(true),
                        icon: <HistoryOutlined />,
                        style: { outline: "none" },
                      },
                    ],
                  }}
                >
                  <StyledButton
                    icon={<StarOutlined />}
                    type="default"
                    size="small"
                    style={{ borderRadius: 5, outline: "none" }}
                  >
                    <Space>
                      {t("common.Favourites")}
                      <DownOutlined />
                    </Space>
                  </StyledButton>
                </Dropdown>
              </Space>

              <IDataSheet
                className="forward-model-datasheet forward-model-datasheet-ingredients"
                data={datasheetData}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = [...ingredientsDataList]
                  changes.forEach(({ row, col, value }) => {
                    if (col === 0) return
                    const convertedValue = convertValue(value)
                    if (isNaN(convertedValue)) {
                      message.warning(t("aiEngine.pleaseEnterAValidNumber"))
                    } else {
                      const parameter = ingredientsParameterList[row - 1]
                      grid[col - 2][parameter] = value
                      setUnsavedChanges(true)
                    }
                  })
                  setIngredientsDataList((prev) => ({
                    ...prev,
                    [currentSelectedStage]: grid,
                  }))
                }}
                cellRenderer={(props) => fixDatasheetColumnsCellRenderer(datasheetData, props)}
              />
            </Space>
          )}
      </Space>
      <AddToFavoritesModal
        visible={showAddFavoriteModal}
        setShowAddFavoriteModal={setShowAddFavoriteModal}
        processingParameterList={processingParameterList}
        ingredientsParameterList={ingredientsParameterList}
        favoritesCurrentPage={favoritesCurrentPage}
        forwardModalVersionData={versionData}
      />
      <FavouritesDrawer
        open={showFavouritesDrawer}
        setShowFavouritesDrawer={setShowFavouritesDrawer}
        setFavoritesCurrentPage={setFavoritesCurrentPage}
        setIngredientsParameterList={setIngredientsParameterList}
        ingredientsDataList={ingredientsDataList}
        setIngredientsDataList={setIngredientsDataList}
        setProcessingParameterList={setProcessingParameterList}
        processingDataList={processingDataList}
        setProcessingDataList={setProcessingDataList}
        processingInputs={processingInputs}
        ingredientsInputs={ingredientsInputs}
        currentSelectedStage={currentSelectedStage}
      />
    </>
  )
}
