import { useCallback } from "react";
import useTranslate, { TranslationKey } from "../useTranslate";

const translationMap = new Map<string, TranslationKey>([
    [
        "Completed", "common.completed"
    ],
    [
        "Failed", "common.failed"
    ],
    [
        "In Progress", "common.inProgress"
    ],
    [
        "Properties Prediction", "aiEngine.propertiesPrediction"
    ],
    [
        "In Queue", "common.inQueue"
    ]
])

export const useTranslationLabel = () => {

    const [t] = useTranslate();

    const translate = useCallback((label: string) => {
        const translationKey = translationMap.get(label)
        const translation = translationKey ? t(translationKey) : label;
        return translation
    }, [t])

    return translate
};