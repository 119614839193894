import {
  put,
  takeLatest,
  call,
  select,
  takeLeading,
  all,
} from "redux-saga/effects";
import { loginRequest, loginSuccess, loginFailure } from "../actions/";
import { messages } from "src/utils/hooks";
import {
  loginApi,
  refreshTokenApi,
  requestOtpApi,
  veriftOtpTrueApi,
  verifyOtpApi,
  disableOtpApi,
  getIpDetails,
  setProjectTypeApi,
  logoutApi,
  updateProfileApi,
  updateCurrencyApi,
} from "../../services/login";
import {
  loginNotVerified,
  refreshTokenRequest,
  refreshTokenSuccess,
  requestOtpFailure,
  requestOtpRequest,
  requestOtpSuccess,
  verifyOtpFailure,
  verifyOtpRequest,
  verifyOtpSuccess,
  disableOtpFailure,
  disableOtpRequest,
  disableOtpSuccess,
  verifyOtpTrueFailure,
  verifyOtpTrueRequest,
  verifyOtpTrueSuccess,
  updateLoginResponse,
  setOtp,
  clearOtpState,
  setVerification,
  setProjectTypeFailure,
  setProjectTypeRequest,
  setProjectTypeSuccess,
  logoutFailure,
  logoutRequest,
  logoutSuccess,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  updateCurrencyRequest,
  updateCurrencySuccess,
  updateCurrencyFailure,
} from "../actions/login";
import {
  RefreshTokenRequestPayload,
  LoginPayload,
} from "../../services/login/interface";
import { history } from "src";
import { Modal, notification, message as messageBar } from "antd";
import { setDefaultHeaders } from "../actions/defaultHeaders";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import CryptoJS from "crypto-js";
import jwtManager from "src/utils/jwtManager";
import { trackEvent } from "src/analytics";
import { handleFirebaseLogin } from "src/utils/firebase";
import { currentPlatform } from "src/constants";
import { languageToggle } from "../actions/language";

type loginAction = {
  type: string;
  payload: LoginPayload;
};

interface RefreshTokenPayload extends RefreshTokenRequestPayload {
  previousAction: any[];
}

type refreshTokenAction = {
  type: string;
  payload: RefreshTokenPayload;
};

function* loginSaga({ payload }: loginAction): Generator<any, any, any> {
  const { current: currentLanguage } = yield select((state) => state.language);
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  let ip;
  try {
    try {
      const {
        data: { ip: ipData },
      } = yield call(getIpDetails);
      ip = ipData;
    } catch (error) {
      console.log(error);
    }
    const { password, user_email } = payload;
    const key = CryptoJS.enc.Utf8.parse(
      String(process.env.REACT_APP_LOGIN_ENCRYPTION_KEY)
    );
    const emailRandomBytes = CryptoJS.lib.WordArray.random(128 / 8).toString();
    const emailIv = CryptoJS.enc.Hex.parse(emailRandomBytes);
    const passwordRandomBytes = CryptoJS.lib.WordArray.random(
      128 / 8
    ).toString();
    const passwordIv = CryptoJS.enc.Hex.parse(passwordRandomBytes);
    const encryptedEmail = CryptoJS.AES.encrypt(user_email, key, {
      mode: CryptoJS.mode.CBC,
      iv: emailIv,
    }).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, key, {
      mode: CryptoJS.mode.CBC,
      iv: passwordIv,
    }).toString();
    const credentials = `${encryptedEmail}.${emailIv.toString()}.${encryptedPassword}.${passwordIv.toString()}`;

    const { defaultHeaders } = yield select((state) => state);
    const userId = yield select((state) => state.login.loginResponse.user_id);
    const platform = currentPlatform

    const {
      data: {
        result: { data, message, status, redirect },
      },
    } = yield call(
      loginApi,
      { credentials, user_loggedin_IP: ip },
      defaultHeaders
    );

    if (status === "Success") {
      yield put(
        setDefaultHeaders({
          ...defaultHeaders,
          platform,
          lang: currentLanguage,
        })
      );
      if (data?.language !== 'en') localStorage.setItem("isLanguageAnnouncement", "true")
      yield put(languageToggle(data.language))
      if (data.user_verified && !data.user_access && platform === "connect") {
        history.push("/access-denied", {
          user_email: data.email,
          user_name: data.user_name,
          user_verified: data.user_verified,
          user_access: data.user_access,
        });
      }
      if (data?.otp) {
        yield put(setOtp(true));
        yield put(
          updateLoginResponse({ user_id: data?.user_id, partial: true })
        );
      } else if (data?.uri) {
        yield put(
          setVerification({
            otpVerificationRequired: true,
            requestOtpData: data.uri,
          })
        );
        yield put(
          updateLoginResponse({ user_id: data?.user_id, partial: true })
        );
      } else if (data.user_access) {
        trackEvent(userId, "User logged in successfully", {
          "Login details": { data },
        });
        yield call(handleFirebaseLogin, data?.firebase_auth_token);
        yield put(loginSuccess(data));
      } else {
        yield put(loginNotVerified());
        yield call(history.push as any, "/access-denied", {
          user_email: data.email,
          user_name: data.user_name,
          user_verified: data.user_verified,
          user_access: data.user_access,
        });
      }
    } else {
      trackEvent(userId, "User failed to log in", {
        "Login details": { data },
      });
      if (redirect) {
        yield put(
          loginFailure(
            `${message}. <br> <a style="color:white;font-weight: bold" href=${data.url} rel="noreferrer" target="_blank">Click Here to Login to ${data.platform}</a>`
          )
        );
      } else {
        yield put(loginFailure(message));
      }
    }
  } catch (error: any) {
    yield put(loginFailure(messages[ln].internal_server_error));
  }
}

function* refreshTokenSaga({
  payload,
}: refreshTokenAction): Generator<any, any, any> {
  try {
    const {
      data: { ip },
    } = yield call(getIpDetails);
    const previousAction = yield select(
      (state: StoreState) => state.previousAction
    );
    const { defaultHeaders } = yield select((state) => state);
    const { user_id } = yield select((state) => state.login.loginResponse);
    const headers = { ...defaultHeaders, id: user_id };
    const {
      data: { result },
    } = yield call(refreshTokenApi, { user_loggedin_IP: ip }, headers);
    if (result.status === "Success") {
      yield put(refreshTokenSuccess(result.token));
      yield all(previousAction.map((action: any) => put(action)));
    } else {
      yield put(logoutSuccess());
      Modal.info({
        title: "Session expired",
        content: "Please Login again",
        onOk() { },
      });
    }
  } catch (error) {
    yield put(logoutSuccess());
    Modal.info({
      title: "Session expired",
      content: "Please Login again",
      onOk() { },
    });
  }
}

function* requestOtpSaga({ payload }: any) {
  try {

    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(requestOtpApi, {}, headers);
    if (result.status === "Success") {
      yield put(requestOtpSuccess(result?.data?.uri));
    } else {
      yield put(requestOtpFailure());
    }
  } catch (error) {
    yield put(requestOtpFailure());
  }
}

function* verifyOtpTrueSaga({ payload }: any) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const { user_id: key } = yield select((state) => state.login.loginResponse);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
      key,
    };
    const {
      data: { result },
    } = yield call(veriftOtpTrueApi, { ...payload, user_id: key }, headers);
    if (result.status === "Success") {
      yield put(verifyOtpTrueSuccess());
      if (payload?.from === "login") {
        if (!!result?.data?.firebase_auth_token?.length) {
          yield call(handleFirebaseLogin, result?.data?.firebase_auth_token);
        }
        yield put(loginSuccess(result?.data));
      } else {
        yield put(updateLoginResponse(result?.data));
      }

      yield put(clearOtpState());
      notification.success({
        message: result.message,
        description: result.description,
        duration: 12,
      });
    } else {
      yield put(verifyOtpTrueFailure());
      messageBar.error(result.message);
    }
  } catch (error) {
    yield put(verifyOtpTrueFailure());
  }
}

function* verifyOtpSaga({ payload }: any) {
  try {
    const { user_id: key } = yield select((state) => state.login.loginResponse);
    const { defaultHeaders } = yield select((state) => state);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
      key,
    };
    const {
      data: { result },
    } = yield call(verifyOtpApi, { ...payload, user_id: key }, headers);
    if (result.status === "Success") {
      yield put(verifyOtpSuccess());
      yield call(handleFirebaseLogin, result?.data?.firebase_auth_token);
      yield put(loginSuccess(result?.data));
    } else {
      yield put(verifyOtpFailure());
      messageBar.error(result.message);
    }
  } catch (error) {
    yield put(verifyOtpFailure());
  }
}

function* disableOtpSaga({ payload }: any) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(disableOtpApi, { disable_for_company: payload === "admin" }, headers);
    if (result.status === "Success") {
      yield put(disableOtpSuccess());
      notification.warning({
        message: result.message,
      });
      yield put(updateLoginResponse(result?.data));
    } else {
      yield put(disableOtpFailure());
    }
  } catch (error) {
    yield put(disableOtpFailure());
  }
}

function* setProjectTypeSaga({ payload }: any) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, data },
      },
    } = yield call(setProjectTypeApi, payload, headers);
    if (status === "Success") {
      yield put(setProjectTypeSuccess(data));
    } else {
      yield put(setProjectTypeFailure());
    }
  } catch (error) {
    yield put(setProjectTypeFailure());
  }
}

function* logoutSaga({ payload }: any) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(logoutApi, payload, headers);
    if (result.status === "Success") {
      yield put(logoutSuccess());
    } else {
      yield put(logoutFailure());
    }
  } catch (error) {
    yield put(logoutFailure());
  }
}

function* updateProfileSaga({ payload }: any) {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, data, message },
      },
    } = yield call(updateProfileApi, payload, headers);
    if (status === "Success") {
      if (payload?.lang !== 'en') localStorage.setItem("isLanguageAnnouncement", "true")
      yield put(updateProfileSuccess(data));
      notification.success({ message });
    } else {
      yield put(updateProfileFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(updateProfileFailure());
    notification.error({ message: messages[ln].update_profile_error });
  }
}

function* updateCurrencySaga({ payload }: any) {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(updateCurrencyApi, payload, headers);
    if (status === "Success") {
      yield put(updateCurrencySuccess(data));
      notification.success({ message });
    } else {
      yield put(updateCurrencyFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(updateCurrencyFailure());
    notification.error({ message: messages[ln].internal_server_error });
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(loginRequest, loginSaga);
  yield takeLeading(refreshTokenRequest, refreshTokenSaga);
  yield takeLatest(requestOtpRequest, requestOtpSaga);
  yield takeLatest(verifyOtpTrueRequest, verifyOtpTrueSaga);
  yield takeLatest(verifyOtpRequest, verifyOtpSaga);
  yield takeLatest(disableOtpRequest, disableOtpSaga);
  yield takeLatest(setProjectTypeRequest, setProjectTypeSaga);
  yield takeLatest(logoutRequest, logoutSaga);
  yield takeLatest(updateProfileRequest, updateProfileSaga);
  yield takeLatest(updateCurrencyRequest, updateCurrencySaga);
}
