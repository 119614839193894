import {
    Anchor,
    Col,
    Descriptions,
    Drawer,
    Flex,
    List,
    Row,
    Space,
    Spin,
    Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workOrdersRequest } from "src/store/actions/workOrders";
import { StoreState } from "src/store/configureStore";
import "./SmileAnalytics.scss";
import { CustomPrompt } from "src/utils/CustomPrompts";
import useTranslate from "src/utils/useTranslate";
import { AsyncStates } from "src/constants";
import SmileOverview from "./SmileResults/SmileOverview";
import SmileNumericalData from "./SmileResults/SmileNumericalData";
import SmileNumericalHeatmap from "./SmileResults/SmileNumericalHeatmap";
import { ArrowLeftOutlined, HistoryOutlined, LoadingOutlined } from "@ant-design/icons";
import SmileAnalyticsForm from "./SmileAnalyticsForm";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import Title from "antd/es/typography/Title";
import { smileSummaryHistoryRequest, smileSummaryResultClear, smileSummaryResultRequest } from "src/store/actions/smileSummary";
import { useMemberName } from "src/utils/useMemberName";
import SmileResultHeader from "./SmileResults/SmileResultHeader";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";

const anchorOptions = [
    {
        key: "overview",
        href: "#overview",
        title: "Overview",
    },
    {
        key: "num-data-summary",
        href: "#num-data-summary",
        title: "Num. data summary",
    },
    {
        key: "num-correlation-heatmap",
        href: "#num-correlation-heatmap",
        title: "Num. correlation heatmap",
    },
    {
        key: "fingerprint-analysis",
        href: "#fingerprint-analysis",
        title: "Fingerprint analysis",
    },
    {
        key: "graphs",
        href: "#graphs",
        title: "Graphs",
    },
];

const SmileAnalytics = () => {
    const { current: currentProject } = useSelector(
        (state: StoreState) => state.projects
    );
    const isEditing = useSelector((state: StoreState) => state.isEditing);
    const { getName } = useMemberName()
    const dispatch = useDispatch();
    const [t] = useTranslate();
    const [showData, setShowData] = useState(false);
    const { smileSummaryStatus, smileSummaryResultStatus, smileSummaryHistoryStatus, smileSummaryHistory, smileSummaryResult } = useSelector(
        (state: StoreState) => state.smileSummary
    );
    const [smileReportDownloadLink, setSmileRepostDownloadLink] =
        useState<string>();
    const [hasHeatmapData, setHasHeatmapData] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        if (smileSummaryResultStatus === AsyncStates.SUCCESS) setShowData(true);
        else setShowData(false);
    }, [smileSummaryResultStatus]);

    useEffect(() => {
        dispatch(
            workOrdersRequest({
                project_id: currentProject,
                closed_stages: true,
            })
        );

        return () => {
            dispatch(smileSummaryResultClear());
            setShowHistory(false);
            setShowData(false);
        }
    }, [dispatch, currentProject]);

    useEffect(() => {
        if (showHistory) {
            dispatch(smileSummaryHistoryRequest());
        }
    }, [dispatch, showHistory]);

    const getSmilesResult = (run_id: string | undefined) => {
        if (!run_id) return;
        dispatch(smileSummaryResultRequest({ run_id }));
        setShowHistory(false);
    }

    const clearSmileSummaryResult = () => {
        dispatch(smileSummaryResultClear());
        setShowData(false);
    }

    return (
        <div className="smile-analytics-container">
            <CustomPrompt
                isEditing={isEditing as boolean}
                message={`${t("common.unsavedChangesLost")}!.`}
            />
            <Space
                direction="vertical"
                style={{ width: "100%", marginBottom: 20 }}
                size="large"
            >
                <StyledPageHeader
                    title={t("smiles.analytics")}
                    extra={
                        <Tooltip title={`${t("smiles.analytics")} ${t("aiEngine.history")}`}>
                            <StyledButton
                                onClick={() => setShowHistory(true)}
                                type="default"
                                icon={<HistoryOutlined />}
                            />
                        </Tooltip>
                    }
                />
                <Spin
                    spinning={[smileSummaryStatus, smileSummaryResultStatus].includes(
                        AsyncStates.LOADING
                    )}
                    indicator={<LoadingOutlined />}
                    tip={<div>{t("dataAnalytics.loading")}</div>}
                >
                    {!showData ? (
                        <SmileAnalyticsForm
                            setSmileRepostDownloadLink={setSmileRepostDownloadLink}
                            setHasHeatmapData={setHasHeatmapData}
                        />
                    ) : (
                        <>
                            <Flex className="back-header" >
                                <ArrowLeftOutlined onClick={clearSmileSummaryResult} style={{ cursor: "pointer" }} />
                                <span>{t("smiles.runTitle")}: {smileSummaryResult?.experiment_details?.title || t("common.back")}</span>
                            </Flex>
                            <Row>
                                <SmileResultHeader />
                            </Row>
                            <Row justify="start" gutter={[32, 8]}>
                                <Col span={20}>
                                    <SmileOverview
                                        smileReportDownloadLink={smileReportDownloadLink}
                                    />
                                    <SmileNumericalData />
                                    <SmileNumericalHeatmap
                                        setSmileRepostDownloadLink={setSmileRepostDownloadLink}
                                        setHasHeatmapData={setHasHeatmapData}
                                    />
                                </Col>
                                <Col span={4}>
                                    <div className="anchor-container">
                                        <div className="heading">{t("common.contents")}</div>
                                        <Anchor
                                            targetOffset={280}
                                            items={
                                                hasHeatmapData ? anchorOptions : anchorOptions.slice(0, 3)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Spin>

                <Drawer
                    open={showHistory}
                    width={500}
                    onClose={() => setShowHistory(false)}
                    title={
                        <Row justify="space-between">
                            <Title level={4} style={{ margin: 0 }}>
                                {t("aiEngine.history")}
                            </Title>
                        </Row>
                    }
                >
                    <List
                        loading={smileSummaryHistoryStatus === AsyncStates.LOADING}
                        pagination={
                            {
                                defaultCurrent: 1,
                                total: (smileSummaryHistory || [])?.length,
                                showSizeChanger: true,
                                position: "top",
                            }
                        }
                        dataSource={Array.isArray(smileSummaryHistory) ? smileSummaryHistory.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()) : []}
                        renderItem={(item: any) => (
                            <List.Item style={{ marginTop: 10 }}>
                                <StyledCard
                                    type="inner"
                                    style={{ width: "100%", cursor: String(item?.status || "").toLowerCase() === "completed" ? "pointer" : "not-allowed" }}
                                    onClick={() => String(item?.status || "").toLowerCase() === "completed" && getSmilesResult(item?.run_id)}
                                >
                                    <Descriptions bordered column={1}>
                                        <Descriptions.Item label={t("smiles.runTitle")}>
                                            {item?.title}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t("common.type")}>
                                            {item?.upload_file ? "File Upload" : "Work Order"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t("common.createdOn")}>
                                            {new Date(item?.created).toLocaleString()}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t("common.createdBy")}>
                                            {getName(item?.created_by)}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t("common.status")}>
                                            {item?.status}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </StyledCard>
                            </List.Item>
                        )}
                    />
                </Drawer>
            </Space>
        </div>
    );
};

export default SmileAnalytics;
