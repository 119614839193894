type Range = {
	[key: string]:
		| {
				[key in "min" | "max"]: number
		  }
		| string[]
}

type SumLimit = {
	[key in "min" | "max"]: number | null
}

type ModelData = {
	[key: string]: {
		"range": {
			"min": number
			"max": number
		}
		"data_points": number
		"train_metrics": number
		"eval_metrics": number | null
		"trained_on": string
	}
}

export type ModelDataAnalysis = {
	"model_names": string[]
	"objective": string | null
	"is_inverse": boolean
	"version": number
	"comments": string | null
	"model_metadata": {
		[key: string]: {
			[key: string]: string | null
		}
	}
	"access": boolean
	"is_custom": boolean
	"company_id": string
	"input_type": string[]
	"inputs": string[]
	"inputs_ingredients": string[]
	"inputs_processing": string[]
	"inputs_range": Range
	"output_range_optimal": Range
	"outputs_range_padded": Range
	"output_type": string
	"inverse_input_constraints": Range
	"input_sum_limits": SumLimit
	"model_display_type": string
	"model_data": ModelData
}

export type Parameter =
	| {
			"parameter": string
			"exclude": false
			"include": false
			"min": string | number
			"max": string | number
	  }
	| {
			"parameter": string
			"exclude": true
			"include": true
			"min": 0
			"max": 0
	  }

export type CategoryInput = {
	"parameter": string
	"data": string[]
}
export type ParameterListDropdown = (
	index: number,
	constraintstype: string
) => JSX.Element

export type OptionsDropdown = (
	rangeData: CategoricalProperty,
	index: number
) => JSX.Element

export type CategoricalObjectiveList = [
	string,
	{
		min: number | null
		max: number | null
	}
][]

export type CategoricalProperty = {
	parameter: string
	modelType: string
	output_range_optimal: string
	val: any[]
	checked: boolean
}

export type NumericalProperty = {
	parameter: string
	output_range_optimal: string
	min: string | number
	max: string | number
	checked: boolean
	modelType: string
	value: string
	priority: number | null
}

export function isCategoricalProperties(
	properties: NumericalProperty | CategoricalProperty
): properties is CategoricalProperty {
	return (properties as CategoricalProperty).val !== undefined
}
