import { Col, Divider, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import "./Common.scss";
import { useEffect, useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    createCategoryRequest,
    updateCategoryRequest,
} from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { antdTheme, AsyncStates } from "src/constants";
import { CATEGORY_TYPES } from "./Data";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    mode?: "edit" | "create" | "delete";
    selectedCategoryId?: string;
    type: CATEGORY_TYPES;
};

const AddEditCategoryModal = ({
    showModal,
    setShowModal,
    mode = "create",
    selectedCategoryId,
    type,
}: TProps) => {
    const [t] = useTranslate();
    const [categoryForm] = Form.useForm();
    const [optionName, setOptionName] = useState<string>();
    const categories = useSelector(
        (state: StoreState) => state.repository.categories.data
    );
    const [options, setOptions] = useState<string[]>([]);
    const dispatch = useDispatch();
    const createCategoryStatus = useSelector(
        (state: StoreState) => state.repository.categories.status
    );

    useEffect(() => {
        if (mode === "edit" && selectedCategoryId) {
            const selectOptions = categories.find(
                (c) => c.category_id === selectedCategoryId
            )?.sub_category || [];
            setOptions(selectOptions);

            setOptionName(undefined);
            categoryForm.setFieldsValue({
                name: categories.find((c) => c.category_id === selectedCategoryId)
                    ?.name,
                sub_category: selectOptions,
            });
        } else if (mode === "create") {
            categoryForm.resetFields();
            setOptions([]);
            setOptionName(undefined);
        }
    }, [selectedCategoryId, categories, mode, categoryForm]);

    useEffect(() => {
        if (createCategoryStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [createCategoryStatus, setShowModal]);

    useEffect(() => {
        categoryForm.setFieldValue("sub_category", options);
    }, [options, categoryForm]);

    const submit = () => {
        const payload = categoryForm.getFieldsValue();

        // Add type
        payload.type = type;

        if (mode === "create") dispatch(createCategoryRequest(payload));
        if (mode === "edit")
            dispatch(
                updateCategoryRequest({ ...payload, category_id: selectedCategoryId })
            );
    };

    const addOption = () => {
        if (optionName) {
            const newOptions = new Set([...options, optionName]);
            setOptions([...newOptions]);
            setOptionName(undefined);
        }
    };

    const removeOption = (optionToRemove: string) =>
        setOptions((prev) => prev.filter((p) => p !== optionToRemove));

    const resetForm = () => {
        categoryForm.resetFields();
        setOptions([]);
        setOptionName(undefined);
    };

    return (
        <Modal
            title={
                <Typography.Title level={4} style={{ textTransform: "capitalize" }}>{`${mode === "create" ? t("repository.addNewCategory") : t("repository.editCategory")}`}</Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            rootClassName="repository-custom-modal"
        >
            <Form
                onFinish={submit}
                form={categoryForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="name"
                    label={t("common.category")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder={t("common.enterName")} />
                </Form.Item>

                {type === CATEGORY_TYPES.INGREDIENT && (
                    <Form.Item name="sub_category" label={t("common.Sub-Category")}>
                        <Select
                            loading={false}
                            placeholder={t("common.select")}
                            allowClear
                            showSearch={true}
                            mode="multiple"
                            maxTagCount="responsive"
                            onChange={(v) => setOptions(v)}
                            dropdownRender={() => (
                                <>
                                    {options.map((op) => (
                                        <div className="subcategory-options">
                                            <span>{op}</span>
                                            <CloseOutlined
                                                onClick={() => removeOption(op)}
                                                className="subcategory-options-delete"
                                            />
                                        </div>
                                    ))}
                                    <Divider style={{ margin: "8px 0" }} />
                                    <Space style={{ padding: "0 8px 4px" }}>
                                        <Input
                                            placeholder="Option name"
                                            value={optionName}
                                            onChange={(e) => setOptionName(e.target.value)}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <StyledButton
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={addOption}
                                            style={{ color: antdTheme.colorPrimary }}
                                        >
                                            {t("common.Addoption")}
                                        </StyledButton>
                                    </Space>
                                </>
                            )}
                            options={options.map((item) => ({ label: item, value: item }))}
                        />
                    </Form.Item>
                )}

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton type="default" onClick={resetForm}>
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={createCategoryStatus === AsyncStates.LOADING}
                            loading={createCategoryStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditCategoryModal;
