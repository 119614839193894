import {
  Avatar,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteFileRequest, fileHistoryRequest } from "src/store/actions/files"
import { workOrdersRequest } from "src/store/actions/workOrders"
import { StoreState } from "src/store/configureStore"
import { StyledCard } from "src/styled_components/StyledCard"
import { useHistory } from "react-router-dom"
import {
  CaretDownOutlined,
  CloseOutlined,
  DownloadOutlined,
  FilterOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  MoreOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";
import {
  resetWorkOrder,
  workOrderSuccess,
} from "src/store/actions/workOrderDetails"
import { volcano } from "@ant-design/colors"
import { antdTheme, AsyncStates, permissions, projectStatus } from "src/constants"
import { useMemberName } from "src/utils/useMemberName"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import { usePermission } from "src/utils/usePermissions"
import { displayTextPermissions } from "src/utils/decorator"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"

const { Text, Title } = Typography
const { Option } = Select

export const DataHistory = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const history = useHistory()
  const { getName } = useMemberName()

  const { data: workOrders } = useSelector(
    (state: StoreState) => state.workOrders
  )
  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  )
  const { data: historyData, status: historyStatus } = useSelector(
    (state: StoreState) => state.files.fileHistory
  )
  const { status: deleteStatus } = useSelector(
    (state: StoreState) => state.files.deleteFile
  )
  const stages = useSelector(
    (state: StoreState) => state.displayNames.data?.stages
  )
  const [modalData, setModalData] = useState<any>({})
  const [selectedRecords, setSelectedRecords] = useState<any[]>([])
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)

  const [filters, setFilters] = useState<any>({})
  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const teams = useSelector((state: StoreState) => state.teams?.data)
  const [filterTerm, setfilterTerm] = useState("")
  const filteredFileHistoryData = useMemo(
    () =>
      historyData?.filter((fileHistoryData) =>
        fileHistoryData.file_name
          ?.toLowerCase()
          ?.includes(filterTerm?.toLocaleLowerCase())
      ),
    [historyData, filterTerm]
  )
  const userAccess = usePermission()

  useEffect(() => {
    if (Object.keys(filters || {}).length) {
      dispatch(
        fileHistoryRequest({
          project_id: currentProject,
          user_id: filters?.user_id,
        })
      )
    } else {
      dispatch(fileHistoryRequest({ project_id: currentProject }))
    }
    dispatch(workOrdersRequest({ project_id: currentProject }))
  }, [dispatch, currentProject, filters])

  useEffect(() => {
    if (deleteStatus === AsyncStates.SUCCESS) {
      setDrawerVisible(false)
      setModalData({})
      setSelectedRecords([])
    }
  }, [deleteStatus])

  const dropDownOverlay = useCallback(
    (row: any) => {
      return (
        <Menu>
          <Menu.Item key={3}>
            <StyledButton
              icon={<DownloadOutlined />}
              style={{ color: "black" }}
              type="link"
              onClick={(e) => {
                e.stopPropagation()
                window.open(row?.download_link, "_blank")
              }}
            >
              {t("notifications.download")}
            </StyledButton>
          </Menu.Item>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          {(userAccess.permission !== permissions.viewer && userAccess?.status === projectStatus.in_progress) &&
            <Menu.Item key={4}>
              <StyledButton
                icon={<StyledDeleteIcon />}
                style={{ color: "black" }}
                type="link"
                onClick={(e) => {
                  e.stopPropagation()
                  setModalData(row)
                  Modal.confirm({
                    title: `${t("common.delete")} ${row?.file_name} ?`,
                    icon: <StyledDeleteIcon />,
                    okText: t("common.confirm"),
                    cancelText: t("common.cancel"),
                    onOk: () => {
                      dispatch(deleteFileRequest({ file_id: [row?.file_id] }))
                      setModalData({})
                    },
                  })
                }}
              >
                {t("common.delete")}
              </StyledButton>
            </Menu.Item>
          }
        </Menu>
      )
    },
    [dispatch, t, userAccess]
  )

  const tablesColumn = useMemo(() => {
    return [
      {
        title: t("common.Name"),
        key: "file_name",
        dataIndex: "file_name",
        filterIcon: (filtered: any) => <CaretDownOutlined />,
        sorter: (a: any, b: any, c: any) => {
          if (c === "ascend") {
            return b.file_name?.toLocaleLowerCase() > a.file_name?.toLocaleLowerCase() ? -1 : 1
          } else {
            return b.file_name?.toLocaleLowerCase() < a.file_name?.toLocaleLowerCase() ? 1 : -1
          }
        },
        width: 300,
        render: (fileName: string) => (
          <Text style={{ wordBreak: "break-word" }}>{fileName || "-"}</Text>
        ),
      },
      {
        title: t("common.workOrder"),
        dataIndex: "workOrder",
        key: "workOrder",
        width: 300,
        render: (value: any, row: any, index: any) => (
          <Space>
            <Text
              style={{
                wordBreak: "break-word",
                color: antdTheme.colorPrimary,
                cursor: "pointer",
              }}
              onClick={(e: any) => {
                e.stopPropagation()
                dispatch(resetWorkOrder())
                dispatch(
                  workOrderSuccess(
                    workOrders?.find(
                      (workOrder: any) =>
                        workOrder.work_order_id === row?.work_order_id
                    )
                  )
                )
                history.push(`/work-orders/details/${row?.work_order_id}`, row?.work_order_id)
              }}
            >
              {
                workOrders?.find(
                  (workOrder: any) =>
                    workOrder.work_order_id === row?.work_order_id
                )?.work_order_name
              }
            </Text>
          </Space>
        ),
      },
      {
        title: t("common.stage"),
        dataIndex: "Stage",
        key: "Stage",
        width: 200,

        render: (value: any, row: any, index: any) => (
          <Space>
            {stages[
              workOrders?.find(
                (workOrder: any) =>
                  workOrder.work_order_id === row?.work_order_id
              )?.work_order_stage
            ] ||
              workOrders?.find(
                (workOrder: any) =>
                  workOrder.work_order_id === row?.work_order_id
              )?.work_order_stage}
          </Space>
        ),
      },
      {
        title: t("common.uploadedBy"),
        dataIndex: "user_id",
        key: "uploadedBy",
        width: 200,
        render: (value: any, row: any, index: any) => (
          <Space>
            <Avatar
              style={{ backgroundColor: volcano[3], verticalAlign: "middle" }}
              size="large"
            >
              {getName(value)?.[0]?.toUpperCase()}
            </Avatar>
            {getName(value)}
          </Space>
        ),
      },
      {
        title: t("common.uploadedOn"),
        dataIndex: "created",
        key: "created",
        width: 100,
        render: (value: any) =>
          new Date(value).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
      },
      {
        dataIndex: "actions",
        title: t("common.actions"),
        key: "actions",
        align: "center",
        width: 100,
        render: (value: any, row: any, index: any) => (
          <Dropdown key="more" overlay={() => dropDownOverlay(row)}>
            <StyledButton
              type="link"
              style={{
                border: "none",
                padding: 0,
                color:'black', 
                outline:'none'
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <MoreOutlined
                style={{
                  fontSize: antdTheme.fontSizeHeading3,
                  verticalAlign: "top",
                }}
              />
            </StyledButton>
          </Dropdown>
        ),
      },
    ]
  }, [dropDownOverlay, getName, stages, workOrders, dispatch, history, t])

  const fileDetails = [
    {
      name: (
        <Title
          level={5}
          style={{
            marginBlockStart: 0,
            marginBottom: 0,
          }}
        >
          <Text>Work Order</Text>
        </Title>
      ),
      data: (
        <Text
          style={{
            margin: 0,
            padding: 0,
            wordBreak: "break-word",
            color: antdTheme.colorPrimary,
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(resetWorkOrder())
            dispatch(
              workOrderSuccess(
                workOrders?.find(
                  (workOrder: any) =>
                    workOrder.work_order_id === modalData?.work_order_id,
                ),
              ),
            )
            history.push(`/work-orders/details/${modalData?.work_order_id}`, modalData?.work_order_id)
          }}
        >
          {
            workOrders?.find(
              (workOrder: any) =>
                workOrder.work_order_id === modalData?.work_order_id,
            )?.work_order_name
          }
        </Text>
      ),
    },
    {
      name: (
        <Title
          level={5}
          style={{
            marginBlockStart: 0,
            marginBottom: 0,
          }}
        >
          <Text>{t("common.stage")}</Text>
        </Title>
      ),
      data: (
        <Text>
          {stages[
            workOrders?.find(
              (workOrder: any) =>
                workOrder.work_order_id === modalData?.work_order_id,
            )?.work_order_stage
          ] ||
            workOrders?.find(
              (workOrder: any) =>
                workOrder.work_order_id === modalData?.work_order_id,
            )?.work_order_stage}
        </Text>
      ),
    },
    {
      name: (
        <Title
          level={5}
          style={{
            marginBlockStart: 0,
            marginBottom: 0,
          }}
        >
          <Text>{t("common.size")}</Text>
        </Title>
      ),
      data: <Text>{`${modalData?.file_size || ""} kb`}</Text>,
    },
    {
      name: (
        <Title
          level={5}
          style={{
            marginBlockStart: 0,
            marginBottom: 0,
          }}
        >
          <Text>{t("common.uploadedBy")}</Text>
        </Title>
      ),
      data: <Text>{getName(modalData?.user_id)}</Text>,
    },
    {
      name: (
        <Title
          level={5}
          style={{
            marginBlockStart: 0,
            marginBottom: 0,
          }}
        >
          <Text>{t("common.uploadedOn")}</Text>
        </Title>
      ),
      data: (
        <Text>
          {new Date(modalData?.created).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Text>
      ),
    },
  ]

  const onFilterChange = (key: any, value: any) => {
    setSelectedRecords([])
    setFilters((prevState: any) => ({ ...prevState, [key]: value }))
  }

  const filtersOverlay = () => (
    <Space size="large" direction="vertical">
      <Select
        mode="multiple"
        allowClear
        onChange={(e) => onFilterChange("user_id", e)}
        placeholder={t("history.uploadedBy")}
        style={{ width: 300 }}
      >
        {teams.map((team: any) => (
          <Option value={team.user_id}>{team.user_name}</Option>
        ))}
      </Select>
    </Space>
  )

  return (
    <Spin
      spinning={
        historyStatus === AsyncStates.LOADING ||
        deleteStatus === AsyncStates.LOADING
      }
      indicator={<LoadingOutlined />}
    >
      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: 10 }}
        size="large"
      >
        <StyledCard title={<Typography.Title level={3}>{t("dataManagement.importHistory")}</Typography.Title>}>
          {displayTextPermissions(userAccess)}
          <Space style={{ margin: "10px", float: "right" }}>
            <Input.Search
              allowClear
              onChange={(e) => {
                setfilterTerm(e.target.value)
                setSelectedRecords([])
              }}
              placeholder={t("common.searchByFilename")}
              enterButton
            />
            <Popover
              style={{ zIndex: 5 }}
              content={filtersOverlay}
              placement="bottomLeft"
              title={
                <Row justify="space-between" style={{ padding: "5px" }}>
                  <Text strong>{t("common.filters")}</Text>
                  <CloseOutlined
                    onClick={() => setFilterVisible(false)}
                    style={{
                      fontSize: antdTheme.fontSizeHeading5,
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                  />
                </Row>
              }
              visible={filterVisible}
            >
              <StyledButton
                type="primary"
                ghost
                icon={<FilterOutlined />}
                onClick={() => {
                  setFilterVisible(true)
                }}
              >
                {t("common.filters")}
              </StyledButton>
            </Popover>
            {selectedRecords?.length > 1 && (
              <StyledButton
                danger
                onClick={() => {
                  Modal.confirm({
                    title: `${t("common.delete")} selected record(s)`,
                    icon: <StyledDeleteIcon />,
                    okText: t("common.confirm"),
                    cancelText: t("common.cancel"),
                    okButtonProps: { style: { borderRadius: "12px" } },
                    cancelButtonProps: { style: { borderRadius: "12px" } },
                    onOk: () => {
                      dispatch(
                        deleteFileRequest({
                          file_id: selectedRecords.map(
                            (file) => file?.file_id,
                          ),
                        }),
                      )
                    },
                  })
                }}
              >
                {t("common.deleteFiles")}
              </StyledButton>
            )}
          </Space>

          <Table
            locale={{ emptyText: t("common.noDataAvailable") }}
            columns={tablesColumn as any[]}
            dataSource={filteredFileHistoryData.map((ele: any) => ({
              ...ele,
              key: ele.file_id,
            }))}
            pagination={false}
            size="small"
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                setDrawerVisible(true)
                setModalData(record)
              },
            })}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRecords.map(
                (res: any) => res.file_id,
              ),
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: any[],
              ) => {
                setSelectedRecords(selectedRows)
              },
            }}
          />
        </StyledCard>
        <Drawer
          open={drawerVisible}
          closable={true}
          closeIcon={<VerticalLeftOutlined />}
          onClose={() => {
            setDrawerVisible(false)
            setModalData({})
          }}
          width={400}
          title={
            <Row justify="space-between">
              <Space>
                <Title level={4}>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <Text style={{ width: "100%" }}>
                      {modalData?.file_name}
                    </Text>
                  </Space>
                </Title>
              </Space>
              <Space style={{ display: "flex", flexDirection: "column" }}>
                <Space>
                  <StyledButton
                    icon={<DownloadOutlined />}
                    style={{ color: "black" }}
                    type="link"
                    onClick={() => {
                      window.open(modalData?.download_link, "_blank")
                    }}
                  >
                    {t("notifications.download")}
                  </StyledButton>
                  <StyledButton
                    icon={<StyledDeleteIcon />}
                    style={{ color: "white" }}
                    type="primary"
                    danger
                    onClick={(e) => {
                      e.stopPropagation()
                      Modal.confirm({
                        title: `${t("common.delete")} ${modalData?.file_name
                          } ?`,
                        icon: <StyledDeleteIcon />,
                        okText: t("common.confirm"),
                        cancelText: t("common.cancel"),
                        onOk: () => {
                          dispatch(
                            deleteFileRequest({
                              file_id: [modalData?.file_id],
                            }),
                          )
                        },
                      })
                    }}
                  >
                    {t("common.delete")}
                  </StyledButton>
                </Space>
              </Space>
            </Row>
          }
        >
          <Row>
            <Space>
              <InfoCircleFilled style={{ marginTop: 7 }} />
              <Title
                level={4}
                style={{ marginBlockStart: 0, marginBottom: 0 }}
              >
                <Text>{t("common.fileDetails")}</Text>
              </Title>
            </Space>
          </Row>
          <Space direction="vertical">
            <Title
              level={5}
              style={{
                marginBlockStart: 0,
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <Text>{t("formulations.type.properties")}</Text>
            </Title>
          </Space>
          {fileDetails?.map((fileDetail: any) => (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <Col style={{ width: "48%" }}>{fileDetail.name}</Col>
              <Col style={{ width: "4%" }}>{":"}</Col>
              <Col style={{ width: "48%" }}>{fileDetail.data}</Col>
            </Row>
          ))}
        </Drawer>
      </Space>
    </Spin>
  )
}
