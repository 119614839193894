import {
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
  message,
  Typography,
} from "antd";
import { motion } from "framer-motion";
import { Link, useHistory, useParams } from "react-router-dom";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import useTranslate from "src/utils/useTranslate";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IngredientModal } from "./components/IngredientModal";
import { addIngredientAttachmentClear, addIngredientAttachmentRequest, deleteIngredientAttachmentRequest, editIngredientAttachmentClear, editIngredientAttachmentRequest, getIngredientAttachmentsClear, getIngredientAttachmentsRequest, getIngredientDetailsClear, getIngredientDetailsRequest, getIngredientPropertiesClear, getIngredientUsedInWoClear, uploadSmilesFileRequest } from "src/store/actions/inventoryV2";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { InventoryAttachmentsDrawer } from "./components/InventoryAttachmentsDrawer";
import { getCustomFieldsClear, getCustomFieldsRequest } from "src/store/actions/customFields";
import {
  EditOutlined,
  ExpandAltOutlined,
  FileAddOutlined,
  InboxOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ViewSmilesDetailModal, ingredientSmileModalDataTypes } from "./components/Smiles/ViewSmilesDetailModal";
import { workOrderClear, workOrderRequest } from "src/store/actions/workOrderDetails";
import { TabsProps } from "antd/lib";
import { InventoryDetailsProjects } from "./components/InventoryDetailsProjects/InventoryDetailsProjects";
// import { InventoryDetailsProperties } from "./components/InventoryDetailsProperties/InventoryDetailsProperties";
import { useValue } from "src/utils/useValue";
import { InventoryPropertyDetails } from "./components/InventoryPropertyDetails";
import { InventoryCustomFieldsForm } from "./components/InventoryCustomFieldsForm";
import { InventoryAdditivesForm } from "./components/InventoryAdditivesForm";
import Dragger from "antd/es/upload/Dragger";
import "./InventoryDetail.scss";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { StyledButton } from "src/styled_components/StyledButton";

const { Text, Title } = Typography


type InventoryDetailProps = {
  inventory_id?: string
}

export const smileModalDefaultState: ingredientSmileModalDataTypes = {
  mode: "view",
  showModal: false,
  metaData: {
    inventory_id: null,
    chemical_information: {}
  }
}

export const InventoryDetail = ({ inventory_id }: InventoryDetailProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { getValue } = useValue()
  const history = useHistory()

  const { data: ingredient, status: ingredientStatus } = useSelector(
    (state: StoreState) => state.inventoryV2.getIngredientDetails
  );

  const updateIngredientResponse = useSelector((state: StoreState) => state.inventoryV2.updateIngredient);

  // const families = useSelector(
  //   (state: StoreState) => state.inventoryV2.ingredientFamilyList
  // );

  const inventoryDataList = useSelector(
    (state: StoreState) => state.displayNames.inventoryData
  );

  const [activeTab, setActiveTab] = useState("inventory_properties_details")

  const { smilesFileUploadStatus } = useSelector((state: StoreState) => state.inventoryV2.smiles);

  const [ingredientModalOpen, setIngredientModalOpen] =
    useState<boolean>(false);


  const [ingredientSmileModalData, setIngredientSmileModalData] = useState({ ...smileModalDefaultState, metaData: { inventory_id: ingredient?.inventory_id, chemical_information: ingredient?.chemical_information ?? null } });

  const [attachmentsDrawerOpen, setAttachmentsDrawerOpen] =
    useState<boolean>(false);

  const { uuid } = useParams<{
    uuid: string;
  }>();

  const getCustomFieldsResponse = useSelector(
    (state: StoreState) => state.customFields.getCustomFields
  );

  useEffect(() => {
    if (smilesFileUploadStatus === AsyncStates.SUCCESS && !!ingredient.chemical_information) {
      setIngredientSmileModalData({
        mode: "view",
        showModal: true,
        metaData: { inventory_id: ingredient.inventory_id, chemical_information: ingredient?.chemical_information ?? null }
      })
    }
  }, [smilesFileUploadStatus, ingredient])

  useEffect(() => {
    if (uuid || inventory_id) {
      dispatch(getIngredientDetailsRequest({ inventory_id: uuid || inventory_id }));
    }
  }, [uuid, dispatch, inventory_id]);

  useEffect(() => {
    if (getCustomFieldsResponse.status === AsyncStates.INITIAL) {
      dispatch(getCustomFieldsRequest({ entity_system_name: "inventory.ingredients" }));
    }
  }, [getCustomFieldsResponse.status, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(getIngredientAttachmentsClear())
    }
  }, [dispatch])


  const getCosting = useCallback(() => {
    const costing = ingredient?.costing
    return costing?.amount ? `${costing?.currency ?? "-"} ${getValue(costing?.amount)} ${costing?.quantity && costing?.unit ? `per ${getValue(costing?.quantity) ?? "-"} ${costing?.unit ?? "-"}` : ''}` : "--"
  }, [getValue, ingredient])
  // const [file, setFile] = useState<any>();

  const uploadProps: UploadProps = {
    onRemove: (record: any) => {
    },
    multiple: false,
    showUploadList: false,
    accept: ".mol",
    customRequest: ({ file }: any) => {
      const formData = new FormData()
      formData.append('file', file)
      dispatch(uploadSmilesFileRequest({ inventory_id: ingredient.inventory_id, formData }))
    }
  }

  const openWorkOrder = (workOrderId: string) => {
    dispatch(workOrderRequest(workOrderId))
    history.push(`/work-orders/details/${workOrderId}`)
  }

  const onChange = (key: string) => {
    setActiveTab(key)
  };

  useEffect(() => {
    return () => {
      dispatch(getIngredientUsedInWoClear())
      dispatch(getIngredientDetailsClear())
      dispatch(getCustomFieldsClear())
      dispatch(getIngredientPropertiesClear())
    }
  }, [dispatch])

  const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder)

  useEffect(() => {
    if (!!workOrder?.work_order_id && !inventory_id && activeTab === "projects") {
      dispatch(workOrderClear())
    }
  }, [activeTab, dispatch, inventory_id, workOrder])

  const inventoryTabs: TabsProps['items'] = useMemo(() => [
    {
      key: 'inventory_properties_details',
      label: t("common.propertyDetails"),
      children: <InventoryPropertyDetails inventory_id={inventory_id} ingredient={ingredient} from={'ingredients'} inventoryData={inventoryDataList} isReadOnly={!!inventory_id} />,
    },
    {
      key: 'custom_fields',
      label: t("projects.customFields"),
      children: <Spin spinning={updateIngredientResponse.status === AsyncStates.LOADING}>
        <div style={{ padding: "0.5rem" }}>
          <InventoryCustomFieldsForm inventory_id={inventory_id} ingredient={ingredient} from={"ingredients"} />
        </div>
      </Spin>,
    },
    {
      key: 'additives',
      label: t("inventory.Additives"),
      children: <Spin spinning={updateIngredientResponse.status === AsyncStates.LOADING}>
        <div style={{ padding: "0.5rem" }}>
          <InventoryAdditivesForm inventory_id={inventory_id} ingredient={ingredient} from={"ingredients"} />
        </div>
      </Spin>,
    },
    // {
    //   key: 'inventory_properties',
    //   label: t("common.properties"),
    //   children: <InventoryDetailsProperties inventory_id={inventory_id} ingredient={ingredient} from={'ingredients'} inventoryData={inventoryDataList} />,
    // },
    {
      key: 'projects',
      label: t("projects.header.title"),
      children: <InventoryDetailsProjects />,
    },
  ], [ingredient, inventoryDataList, inventory_id, t, updateIngredientResponse.status]);

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
    >
      <Spin spinning={ingredientStatus === AsyncStates.LOADING}>
        <Space
          direction="vertical"
          size="small"
          style={{ width: '100%', background: '#fff' }}
        >
          <div>
            {!!ingredient ? (
              <>
                {!inventory_id && (
                  <StyledPageHeader
                    ghost={false}
                    title={
                      <div style={{gap:0, display:'flex', flexDirection:'column'}} >
                        {t('common.title.inventory')}
                        <Space>
                          <Link
                            style={{
                              color: "#000000", opacity: "45%",
                              outline:'none'
                            }}
                            to={'/inventory/ingredients'}
                          >
                            <Typography.Text>{t('common.ingredient')}</Typography.Text>
                          </Link>
                          <Typography.Text style={{ color: '#00000073' }}>{"/"}</Typography.Text>
                          <Typography.Text strong>{ingredient.name}</Typography.Text>{' '}
                          {Boolean(ingredient?.created_via_formulation) && (
                            <>
                              <Tag color="geekblue">
                                {t('inventory.CreatedfromTrial')}
                              </Tag>
                              {Boolean(ingredient?.work_order_id) &&
                                Boolean(ingredient?.work_order_name) && (
                                  <>
                                    <Typography.Text
                                      style={{ color: '#000000D9' }}
                                    >
                                      From{' '}
                                      <Tooltip
                                        title={
                                          ingredient?.work_order_name?.length >
                                          25
                                            ? ingredient?.work_order_name
                                            : null
                                        }
                                        placement={'top'}
                                      >
                                        <StyledButton
                                          onClick={() =>
                                            openWorkOrder(
                                              ingredient?.work_order_id
                                            )
                                          }
                                          type="link"
                                          style={{
                                            padding: 0,
                                            margin: 0,
                                            outline: 'none'
                                          }}
                                        >
                                          <span className="desc__text__info">
                                            {ingredient?.work_order_name
                                              ?.length > 25
                                              ? ingredient?.work_order_name.slice(
                                                  0,
                                                  25
                                                ) + '...'
                                              : ingredient?.work_order_name}
                                          </span>
                                        </StyledButton>
                                      </Tooltip>
                                    </Typography.Text>
                                  </>
                                )}
                            </>
                          )}
                        </Space>
                      </div>
                    }
                  />
                )}
                <Row style={{ flexDirection: 'row', gap: '12px' }}>
                  <Col span={6} style={{ marginLeft: '0.5rem' }}>
                    <div
                      style={{
                        background: '#FAFAFA',
                        borderRadius: '6px',
                        padding: '0.5rem'
                      }}
                    >
                      {!inventory_id && (
                        <StyledButton
                          key="1"
                          style={{ color: '#000000D9', float: 'inline-end' }}
                          size="small"
                          onClick={() => setIngredientModalOpen(true)}
                        >
                          {t('common.edit')}
                        </StyledButton>
                      )}
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('common.name')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9',
                            wordBreak: 'break-word'
                          }}
                        >
                          {ingredient.name}
                        </Title>
                      </div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('common.category')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {ingredient?.category_name ??
                            ingredient?.category ??
                            '--'}
                        </Title>
                      </div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('inventory.subCategory')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {' '}
                          {ingredient.sub_category || '--'}
                        </Title>
                      </div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('inventory.lot_no')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {ingredient.lot_no || '--'}
                        </Title>
                      </div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('common.brandName')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {ingredient.brand_name || '--'}
                        </Title>
                      </div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('inventory.supplier')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {ingredient.supplier || '--'}
                        </Title>
                      </div>
                      <Divider style={{ margin: '10px 0' }} />
                      {/* <div>
                        <Text style={{
                          color: "#000000D9",
                        }}>{t("common.family")}</Text>
                        <Title level={5} style={{
                          color: "#000000D9"
                        }}>
                          {families?.find(({ family_id }: any) => family_id === ingredient?.family_id)?.family_name ?? ingredient?.family ?? "--"}
                        </Title>
                      </div>
                      <Divider style={{ margin: "10px 0" }} /> */}
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('common.grade')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {ingredient.grade || '--'}
                        </Title>
                      </div>
                    </div>
                    <div
                      style={{
                        background: '#FAFAFA',
                        borderRadius: '6px',
                        padding: '0.5rem',
                        marginTop: '0.5rem'
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.5rem',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Text
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {t('smiles.chemical_information_smiles__heading')}
                          </Text>

                          {!!Object.keys(ingredient?.chemical_information ?? {})
                            .length ? (
                            <div>
                              <Tooltip
                                title={t('smiles.view_chemical_information')}
                              >
                                <ExpandAltOutlined
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 'bolder',
                                    cursor: 'pointer',
                                    outline: 'none'
                                  }}
                                  onClick={() => {
                                    setIngredientSmileModalData((prev) => ({
                                      mode: 'view',
                                      showModal: true,
                                      metaData: {
                                        inventory_id: ingredient.inventory_id,
                                        chemical_information:
                                          ingredient?.chemical_information ??
                                          null
                                      }
                                    }))
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              <Tooltip
                                title={
                                  !inventory_id
                                    ? 'smiles.upload_mol_file'
                                    : null
                                }
                              >
                                <Upload {...uploadProps} showUploadList={false}>
                                  <StyledButton
                                    icon={<UploadOutlined />}
                                    loading={
                                      smilesFileUploadStatus ===
                                      AsyncStates.LOADING
                                    }
                                    disabled={!!inventory_id}
                                  ></StyledButton>
                                </Upload>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        {!!ingredientSmileModalData.showModal && (
                          <ViewSmilesDetailModal
                            ingredientSmileModalData={ingredientSmileModalData}
                            setIngredientSmileModalData={
                              setIngredientSmileModalData
                            }
                            editMode={!!inventory_id}
                          />
                        )}
                        <Title level={5} style={{ color: '#000000D9' }}>
                          {ingredient?.chemical_information?.formula || '--'}
                        </Title>
                      </div>
                    </div>
                    <div
                      style={{
                        background: '#FAFAFA',
                        borderRadius: '6px',
                        padding: '0.5rem',
                        marginTop: '0.5rem'
                      }}
                    >
                      {/* <Title level={5}>Costing</Title> */}
                      <div>
                        <Text
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {t('inventory.costing')}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#000000D9'
                          }}
                        >
                          {getCosting()}
                        </Title>
                      </div>
                    </div>
                    <IngredientAttachementsList inventory_id={inventory_id} />
                  </Col>
                  {ingredientStatus === AsyncStates.SUCCESS && (
                    <Col
                      span={17}
                      style={{ overflowY: 'auto', height: '90vh' }}
                    >
                      <Tabs
                        defaultActiveKey="inventory_properties"
                        items={inventoryTabs}
                        onChange={onChange}
                        activeKey={activeTab}
                      />
                    </Col>
                  )}
                </Row>
                <IngredientModal
                  open={ingredientModalOpen}
                  setOpen={setIngredientModalOpen}
                  mode="edit"
                  ingredient={ingredient}
                />

                <InventoryAttachmentsDrawer
                  open={attachmentsDrawerOpen}
                  setOpen={setAttachmentsDrawerOpen}
                />

                {/* Used for Uploading Attachments */}
              </>
            ) : (
              <p>{t('common.notFound')}</p>
            )}
          </div>
        </Space>
      </Spin>
    </motion.div>
  )
};

type IngredientAttachementsListPropsType = {
  inventory_id: string | undefined
}
type fileUploadAttachmentModalType = {
  isModalOpen: boolean,
  data: any,
  mode: "create" | "edit"
}

export const IngredientAttachementsList = ({ inventory_id }: IngredientAttachementsListPropsType) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { data: ingredient } = useSelector((state: StoreState) => state.inventoryV2.getIngredientDetails);
  const getIngredientAttachments = useSelector((state: StoreState) => state.inventoryV2.getIngredientAttachments);

  const [fileUploadAttachmentModal, setFileUploadAttachmentsModal] = useState<fileUploadAttachmentModalType>({
    isModalOpen: false,
    data: null,
    mode: "create"
  });

  useEffect(() => {
    dispatch(getIngredientAttachmentsRequest({
      inventory_id: ingredient?.inventory_id || inventory_id
    }))
  }, [dispatch, inventory_id, ingredient])

  return <>
    <Spin spinning={getIngredientAttachments.status === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      <div style={{ background: "#FAFAFA", borderRadius: "6px", padding: "0.5rem", marginTop: "0.5rem" }}>
        <div>
          <div style={{ display: "flex", gap: "0.5rem", justifyContent: "space-between", padding: "0.25rem 0rem" }}>
            <Text style={{
              color: "#000000D9", display: "flex", flexDirection: "column"
            }}>{t("common.attachments")}
            </Text>

            {
              !inventory_id &&
              <div>
                <Tooltip title={t("common.uploadAnAttachment")}>
                  <StyledButton
                    size="small"
                    onClick={() => {
                      setFileUploadAttachmentsModal({
                        isModalOpen: true,
                        data: null,
                        mode: "create"
                      })
                    }}>
                    <FileAddOutlined
                      style={{ fontSize: "1rem", fontWeight: "bolder", cursor: "pointer", outline: "none" }}
                    />
                  </StyledButton>
                </Tooltip>
              </div>
            }
          </div>
          <div style={{ color: "#000000D9" }} className="attachments__list__containers">
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              {getIngredientAttachments.data.length === 0 ? <Empty description={t("common.noAttachmentFound")} /> :
                getIngredientAttachments.data.map((attachment: any) => {
                  return <div key={attachment.attachment_id} className="attachment__title__container">
                    <Typography.Text ellipsis={{ tooltip: attachment.label }} >
                      <a href={attachment.download_link} target="_blank" rel="noreferrer">{attachment.label}</a>
                    </Typography.Text>
                    {
                      !inventory_id &&
                      <div className="attachment__action__container">
                        <EditOutlined size={8}
                          onClick={() => {
                            setFileUploadAttachmentsModal({
                              isModalOpen: true,
                              data: attachment,
                              mode: "edit"
                            })
                          }}
                        />
                        <StyledDeleteIcon
                          size={8}
                          onClick={() => {
                            Modal.confirm({
                              title: `${t("common.deleteWarningMessage")} ${attachment.label}?`,
                              onCancel: () => { },
                              okButtonProps: {
                                style: {
                                  background: "#ff4d4f"
                                }
                              },
                              onOk: () => {
                                dispatch(deleteIngredientAttachmentRequest({
                                  inventory_id: ingredient?.inventory_id,
                                  attachment_id: attachment.attachment_id,
                                }))
                              },
                            });
                          }} />
                      </div>
                    }
                  </div>
                })}
            </div>
          </div>
        </div>
      </div>
    </Spin>

    {
      fileUploadAttachmentModal.isModalOpen && <IngredientAttachmentModal fileUploadAttachmentModal={fileUploadAttachmentModal} setFileUploadAttachmentsModal={setFileUploadAttachmentsModal} />
    }
  </>
}


type IngredientAttachmentModalPropsType = {
  fileUploadAttachmentModal: {
    isModalOpen: boolean,
    data: any,
    mode: "create" | "edit"
  },
  setFileUploadAttachmentsModal: Dispatch<SetStateAction<any>>

}

export const IngredientAttachmentModal = ({ fileUploadAttachmentModal, setFileUploadAttachmentsModal }: IngredientAttachmentModalPropsType) => {
  const [form] = Form.useForm()
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const [file, setFile] = useState<any>()

  const addIngredientAttachment = useSelector((state: StoreState) => state.inventoryV2.addIngredientAttachment);
  const editIngredientAttachment = useSelector((state: StoreState) => state.inventoryV2.editIngredientAttachment);
  const { data: ingredient } = useSelector((state: StoreState) => state.inventoryV2.getIngredientDetails);

  useEffect(() => {
    if (fileUploadAttachmentModal.mode === "edit") {
      form.setFieldsValue({
        label: fileUploadAttachmentModal.data.label
      })
    }
  }, [fileUploadAttachmentModal, form])

  const handleUploadFinish = (values: any) => {
    if (fileUploadAttachmentModal.mode === "create") {
      if (!file && fileUploadAttachmentModal.mode) return message.error("Please upload a file")

      const formData = new FormData()
      formData.append('file', file)
      formData.append('payload', JSON.stringify({
        label: values.label.trim(),
      }))

      const payload = {
        formData,
        inventory_id: ingredient.inventory_id
      }
      dispatch(addIngredientAttachmentRequest(payload))
    } else {
      const formData = new FormData()
      formData.append('payload', JSON.stringify({
        label: values.label.trim(),
      }))

      const payload = {
        formData,
        inventory_id: ingredient.inventory_id,
        attachment_id: fileUploadAttachmentModal.data.attachment_id
      }
      dispatch(editIngredientAttachmentRequest(payload))
    }

  }

  const props: UploadProps = {
    accept: ".pdf, .xlsx, .docx , .doc, .xls, .png, .jpeg",
    onRemove: (file: any) => {
      setFile(null)
    },
    beforeUpload: (file: any) => {
      setFile(file)
    },
    fileList: file ? [file] : []
  };

  const handleReset = () => {
    form.resetFields()
    setFile(null)
  }

  const handleCancel = useCallback(() => {
    setFileUploadAttachmentsModal({
      isModalOpen: false,
      data: null,
      mode: "create"
    })
    form.resetFields()
    setFile(null)
  }, [form, setFileUploadAttachmentsModal])


  useEffect(() => {
    if (addIngredientAttachment.status === AsyncStates.SUCCESS) {
      handleCancel()
      dispatch(addIngredientAttachmentClear())
    }
  }, [addIngredientAttachment, handleCancel, dispatch])


  useEffect(() => {
    if (editIngredientAttachment.status === AsyncStates.SUCCESS) {
      handleCancel()
      dispatch(editIngredientAttachmentClear())
    }
  }, [editIngredientAttachment, dispatch, handleCancel])

  return (
    <Modal maskClosable={false} open={fileUploadAttachmentModal.isModalOpen} onCancel={handleCancel} footer={null} title={fileUploadAttachmentModal.mode === "create" ? t("common.uploadAnAttachment") : t("common.editAttachment")}>
      <Form form={form} onFinish={handleUploadFinish} layout="vertical">
        <Form.Item name={"label"}
          label={t("common.fileName")}
          rules={[{
            required: true,
            message: t("common.requiredField"),
            transform: (value) => value?.trim()
          }]}
        >
          <Input />
        </Form.Item>
        {
          fileUploadAttachmentModal.mode === "create" &&
          <Form.Item label="">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("fileupload.upload")}</p>
            </Dragger>
          </Form.Item>
        }

        <div style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}>
          <Form.Item>
            <StyledButton danger onClick={handleReset}>{t("common.reset")}</StyledButton>
          </Form.Item>
          <Form.Item>
            <StyledButton htmlType="submit" type="primary" disabled={!file && fileUploadAttachmentModal.mode === "create"} loading={addIngredientAttachment.status === AsyncStates.LOADING || editIngredientAttachment.status === AsyncStates.LOADING}>{t("common.submit")}</StyledButton>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}