import { InfoCircleFilled, LinkOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { AutoComplete, Col, Collapse, DatePicker, Form, Input, Row, Space, Tooltip, Typography, Spin, Popconfirm, Tag, message, Select, Divider } from 'antd'
import { motion } from 'framer-motion'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import { displayTextPermissions, getExternalLink, mapFields } from 'src/utils/decorator'
import useTranslate from 'src/utils/useTranslate'
import { CustomFieldsModal } from 'src/components/Templates/CustomFieldsModal'
import { clearEditProjectId, newProjectRequest, updateProjectRequest } from 'src/store/actions/projects'
import { AsyncStates, permissions, projectStatus } from 'src/constants'
import { useForm } from 'antd/lib/form/Form'
import { SaveTemplateModal } from 'src/components/Templates/SaveTemplateModal'
import { TemplatesOptionModal } from 'src/components/Templates/TemplatesOptionModal'
import { listTemplateRequest } from 'src/store/actions/templates'
import { useMemberName } from 'src/utils/useMemberName'
import { EditMembersTable } from 'src/components/Teams/EditMembersTable'
import { orange } from '@ant-design/colors'
import { CustomPrompt } from 'src/utils/CustomPrompts'
import { setIsEditing } from 'src/store/actions/isEditing'
import { usePermission } from 'src/utils/usePermissions'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { useHistory } from 'react-router-dom'
import { labLocationInitialState } from 'src/components/Settings/tabs/LabLocation/LabLocation'
import { LabLocationModal } from 'src/components/Settings/tabs/LabLocation/LabLocationModal/LabLocationModal'
import { useValue } from 'src/utils/useValue'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import isURL from 'validator/lib/isURL'
dayjs.extend(isSameOrAfter)

const { Link, Text } = Typography
const { Panel } = Collapse

export const CreateProject = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { getName } = useMemberName()
  const { location: { state } }: any = useHistory()
  const { getDecimalSeparator } = useValue()
  const requiredFieldStar = useRequiredFieldStar()


  const categories = useSelector((state: StoreState) => state.projects.projectList.map(project => project.category))
  const categoryOptions = useMemo(() => [...new Set(categories)].map(category => ({ label: category, value: category })), [categories])
  const { createProjectStatus, updateProjectStatus, editProjectId, projectList } = useSelector((state: StoreState) => state.projects)
  const editprojectDetails = useMemo(() => projectList.find((res: any) => res.project_id === editProjectId), [projectList, editProjectId])

  const [customModalVisible, setCustomModalVisible] = useState(false)
  const [customFields, setCustomFields] = useState<any[]>([])
  const [saveTemplateModalVisible, setSaveTemplateModalVisible] = useState(false)
  const [templateOptionsModal, setTemplateOptionsModal] = useState(false)
  const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false)
  const [members, setMembers] = useState([])
  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const userAccess = usePermission()
  const disabled = useMemo(() => (userAccess.permission === permissions.viewer || userAccess?.status !== projectStatus.in_progress) && !!editProjectId, [userAccess, editProjectId])
  const projects = useSelector((state: StoreState) => state.projects.projectList)
  const [form] = useForm()
  const { listTemplate: { data: templateData } } = useSelector((state: StoreState) => state.templates)
  const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);

  const [labLocationModalState, setLabLocationModalState] = useState(labLocationInitialState);

  useEffect(() => {
    if (state) {
      const template = templateData?.filter((res: any) => res?.template_type === "project")?.find((temp: any) => temp.template_id === state.template_id)
      if (template.fields) {
        setCustomFields(template.fields)
        message.success(`${template.name} field(s) added`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    dispatch(listTemplateRequest())
    return () => {
      dispatch(clearEditProjectId())
    }
  }, [dispatch])

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    if (!!Object.keys(editprojectDetails || {}).length) {
      setMembers(editprojectDetails?.members || [])
      if (!!editprojectDetails?.additional_fields?.length) {
        setCustomFields(editprojectDetails.additional_fields.map((res: any) => (res.field_type === "date" && (!!res?.value ?? !!res?.default_value)) ? ({ ...res, value: dayjs(res.value) }) : res))
      }
    }
  }, [editprojectDetails])

  const getInitialValues = (field: any) => {
    if (!editProjectId) {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field.default_value)
        } else {
          return null
        }
      } else {
        return field?.value ?? field?.default_value
      }
    }
    else {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field?.value ?? field.default_value)
        } else return null
      } else {
        return field?.value ?? field?.default_value
      }
    }
  }

  const createProjectSubmit = (values: any) => {
    dispatch(setIsEditing(false))
    if (!!values?.close_date && !!values?.start_date && dayjs(values?.start_date).isSameOrAfter(dayjs(values?.close_date))) {
      return (message.error(t("common.message.closeDateGreaterEqualStartDate")))
    }
    const additional_fields: any = []
    Object.entries(values || {}).forEach(([key, value]: any) => {
      const record = customFields.find((res: any) => res.field_name === key)
      if (!!record) {
        additional_fields.push({ ...record, value: (record?.field_type === "date" && (!!record?.value ?? !!record?.default_value)) ? dayjs(value).toISOString() : value })
      }
    })
    if (!!editProjectId) {
      dispatch(updateProjectRequest({
        ...values, members, project_id: editProjectId, additional_fields,
        lab_locations: !!values?.lab_locations?.length ? values?.lab_locations?.map((locationId: string) => labsLocationList.find((lablocation) => lablocation.lab_location_id === locationId)) : [],
        ...(!!values?.start_date && { start_date: dayjs(values?.start_date).toISOString() }),
        ...(!!values?.close_date && { close_date: dayjs(values?.close_date).toISOString() }),
      }))
    } else {
      dispatch(newProjectRequest({
        ...values, members, additional_fields,
        lab_locations: !!values?.lab_locations?.length ? values?.lab_locations?.map((locationId: string) => labsLocationList.find((lablocation) => lablocation.lab_location_id === locationId)) : [],
        ...(!!values?.start_date && { start_date: dayjs(values?.start_date).toISOString() }),
        ...(!!values?.close_date && { close_date: dayjs(values?.close_date).toISOString() })
      }))
    }
  }

  const tagOptions = useMemo(() => {
    return ([...new Set(projects.flatMap((project) => project.tags))]?.filter((res) => res) ?? [])?.map((tag, idx) => ({
      value: tag,
      label: tag,
      key: `${tag}_${idx}`
    }))
  }, [projects])


  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
      <Spin
        spinning={
          createProjectStatus === AsyncStates.LOADING ||
          updateProjectStatus === AsyncStates.LOADING
        }
        indicator={<LoadingOutlined />}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <CustomPrompt
            isEditing={isEditing}
            message="Unsaved changes will be lost!."
          />
          <StyledPageHeader
            ghost={false}
            title={
              !!editProjectId
                ? t("editProject.editProjectDetails")
                : t("projects.createProject")
            }
            onBack={() => window.history.back()}
            extra={
              !editProjectId && (
                <StyledButton
                  onClick={() => setTemplateOptionsModal(true)}
                  size="large"
                >
                  {t("template.create")}
                </StyledButton>
              )
            }
          />
          {!!editProjectId && displayTextPermissions(userAccess)}
          <Form
            layout="vertical"
            onFinish={createProjectSubmit}
            form={form}
            initialValues={{
              ...editprojectDetails,
              lab_locations: !!editprojectDetails?.lab_locations?.length ? editprojectDetails?.lab_locations?.map((labLocation: any) => labLocation.lab_location_id) : [],
              close_date: !!editprojectDetails?.close_date
                ? dayjs(editprojectDetails.close_date)
                : null,
              start_date: !!editprojectDetails?.start_date
                ? dayjs(editprojectDetails.start_date)
                : null,
            }}
            onFieldsChange={() => dispatch(setIsEditing(true))}
            disabled={disabled}
            requiredMark={false}
          >
            <Space
              direction="vertical"
              size="large"
              style={{ width: "100%" }}
            >
              <StyledCard>
                {!!customFields.length && !editProjectId && (
                  <Row
                    justify="end"
                    style={{ marginBottom: 20, cursor: "pointer" }}
                  >
                    <Popconfirm
                      title={t("template.reset.message")}
                      onConfirm={() => setCustomFields([])}
                    >
                      <StyledButton type="link">{t("template.reset")}</StyledButton>
                    </Popconfirm>
                  </Row>
                )}
                <Collapse
                  defaultActiveKey={["standard", "custom"]}
                  expandIconPosition="right"
                  style={{ padding: "8px 16px" }}
                >
                  <Panel
                    header={
                      <Space>
                        <Text strong>{t("projects.standardFeilds")}</Text>
                        <Tooltip title={t("project.standardFields.message")}>
                          <InfoCircleFilled />
                        </Tooltip>
                        {isEditing && (
                          <Text style={{ color: orange.primary }}>
                            {t("common.unsavedChanges")}
                          </Text>
                        )}
                      </Space>
                    }
                    key="standard"
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="name"
                          label={t("projects.projectName")}
                          validateFirst
                          rules={[
                            {
                              required: true,
                              message: "required",
                              transform: (value: any) => value?.trim(),
                            },
                          ]}
                          required tooltip={requiredFieldStar}
                        >
                          <Input minLength={1} />
                        </Form.Item>
                        <Form.Item
                          name="description"
                          label={t("common.description")}
                          validateFirst
                          rules={[
                            {
                              required: true,
                              message: "required",
                              transform: (value: any) => value?.trim(),
                            },
                          ]}
                          required tooltip={requiredFieldStar} >
                          <Input.TextArea minLength={1} />
                        </Form.Item>
                        <Form.Item
                          name="customer"
                          label={t("projects.customer")}
                          validateFirst
                          rules={[
                            {
                              required: false,
                              message: "required",
                              transform: (value: any) => value?.trim(),
                            },
                          ]}
                        >
                          <Input minLength={1} />
                        </Form.Item>
                        <Form.Item
                          name="members"
                          label={`${t("common.project")} ${t(
                            "common.members"
                          )}`}
                        >
                          <Space
                            style={{
                              padding: "0.5rem",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            direction="horizontal"
                          >
                            {!!members.length ? (
                              <Space style={{ flexWrap: "wrap" }}>
                                {members.map(({ user_id }: any) => (
                                  <Tag
                                    color="orange"
                                    style={{ padding: "5px" }}
                                    key={user_id}
                                  >
                                    {String(getName(user_id))}
                                  </Tag>
                                ))}
                              </Space>
                            ) : (
                              <Space>{t("project.noMembers")}</Space>
                            )}
                            <Space>
                              <StyledButton
                                onClick={() =>
                                  setIsAddMemberModalVisible(true)
                                }
                              >
                                {t("project.members.text")}
                              </StyledButton>
                            </Space>
                          </Space>
                          <Space direction="vertical">
                            <EditMembersTable
                              isAddMemberModalVisible={
                                isAddMemberModalVisible
                              }
                              setIsAddMemberModalVisible={
                                setIsAddMemberModalVisible
                              }
                              members={members}
                              setMembers={setMembers}
                            />
                          </Space>
                        </Form.Item>
                        <Form.Item
                          name="tags"
                          label={t("project.tags")}
                          required={false}
                        >
                          <Select
                            mode="tags"
                            onSelect={(values) => {
                              const tags: string[] = form.getFieldValue("tags") ?? [];
                              form.setFieldsValue({
                                tags: tags.filter((tag, index) => {
                                  const trimTag = tag.trim().toLocaleLowerCase()
                                  return !!trimTag.length
                                }).map((tag) => tag.trim())
                              });
                            }
                            }
                            placeholder={"Enter Tag(s) here"}
                            style={{ width: '100%' }}
                            options={tagOptions}
                            allowClear
                          />
                        </Form.Item>
                        <Form.Item
                          name="category"
                          label={t("common.category")}
                          rules={[
                            {
                              required: true,
                              message: "required",
                              transform: (value: any) => value?.trim(),
                            },
                          ]}
                          required tooltip={requiredFieldStar}
                        >
                          <AutoComplete
                            style={{ width: 300 }}
                            showSearch
                            filterOption={true}
                            options={categoryOptions}
                          />
                        </Form.Item>
                        <Form.Item
                          required={false}
                          label={t("workOrderDetails.startDate")}
                          name="start_date"
                          style={{ width: 1000 }}
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          required={false}
                          label={t("workOrderDetails.closeDate")}
                          name="close_date"
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          required={false}
                          label={t("common.labLocation(s)")}
                          name="lab_locations"
                        >
                          <Select
                            placeholder={t("common.selectLabLocation")}
                            mode='multiple'
                            allowClear
                            optionFilterProp='label'
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px', float: "right" }}>
                                  <StyledButton size='small' type="text" icon={<PlusOutlined />} onClick={() => setLabLocationModalState((prev) => ({
                                    ...prev,
                                    mode: "create",
                                    isModalVisible: true,
                                  }))}>
                                    {t("common.addLabLocation")}
                                  </StyledButton>
                                </Space>
                              </>
                            )}
                            options={labsLocationList?.length ? labsLocationList.map((labLocation) => ({ label: `${labLocation?.name}, (${labLocation?.city}, ${labLocation?.country})`, value: labLocation.lab_location_id, key: labLocation.lab_location_id })) : []}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel
                    header={
                      <Space>
                        <Text strong>{t("projects.customFields")}</Text>
                        <Tooltip title={t("project.customFields.message")}>
                          {" "}
                          <InfoCircleFilled />
                        </Tooltip>{" "}
                      </Space>
                    }
                    key="custom"
                  >
                    <Row>
                      <Col span={14}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          {customFields.map((field: any) => (
                            <Space>
                              <Popconfirm
                                title={t("common.removeField")}
                                onConfirm={() => {
                                  setCustomFields((prevState: any) =>
                                    prevState.filter(
                                      (res: any) =>
                                        res?.field_name !== field?.field_name
                                    )
                                  );
                                }}
                              >
                                <StyledButton
                                  style={{ marginTop: 4 }}
                                  icon={<MinusCircleOutlined />}
                                  type="text"
                                ></StyledButton>
                              </Popconfirm>
                              <Form.Item
                                name={field.field_name}
                                label={field.field_name}
                                initialValue={getInitialValues(field)}
                                rules={[
                                  {
                                    required: field.required,
                                    message: "required",
                                    transform: (value: any) => value?.trim(),
                                  },
                                  ...(field.field_type === "link"
                                    ? [
                                        {
                                          message: "Please enter a valid URL",
                                          validator: (_: any, value: string) => {
                                            if (!field.required && !value) return Promise.resolve();
                                            if (isURL(value)) {
                                              return Promise.resolve();
                                            } else {
                                              return Promise.reject();
                                            }
                                          },
                                        },
                                      ]
                                    : []),
                                ]}
                                extra={!!field.value && field.field_type === 'link' &&
                                  <Link href={getExternalLink(field.value)} target="_blank" rel="noreferrer">
                                    <LinkOutlined />
                                  </Link>
                                  }
                              >
                                {mapFields(field, false, getDecimalSeparator())}
                              </Form.Item>
                            </Space>
                          ))}
                        </Space>
                      </Col>
                    </Row>
                    <StyledButton
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => setCustomModalVisible(true)}
                    >
                      <Text type="secondary">
                        {t("common.AddCustomField")}
                      </Text>
                    </StyledButton>
                  </Panel>
                </Collapse>
              </StyledCard>
              <StyledCard>
                <Row justify="end">
                  <Space>
                    <Tooltip title={t("common.customFields.message")}>
                      <StyledButton
                        size="middle"
                        disabled={!customFields?.length || disabled}
                        onClick={() => setSaveTemplateModalVisible(true)}
                      >
                        {t("templates.saveNew")}
                      </StyledButton>
                    </Tooltip>
                    <StyledButton
                      size="middle"
                      htmlType="submit"
                      type="primary"
                    >
                      {!!editProjectId
                        ? t("common.saveChanges")
                        : t("projects.createProject")}
                    </StyledButton>
                  </Space>
                </Row>
              </StyledCard>
            </Space>
          </Form>
        </Space>
      </Spin>
      <TemplatesOptionModal
        templateOptionsModal={templateOptionsModal}
        setTemplateOptionsModal={setTemplateOptionsModal}
        setCustomFields={setCustomFields}
        type="project"
      />
      <CustomFieldsModal
        customModalVisible={customModalVisible}
        setCustomModalVisible={setCustomModalVisible}
        customFields={customFields}
        setCustomFields={setCustomFields}
        type="project"
      />
      <SaveTemplateModal
        saveTemplateModalVisible={saveTemplateModalVisible}
        setSaveTemplateModalVisible={setSaveTemplateModalVisible}
        customFields={customFields}
        type="project"
      />
      {labLocationModalState?.isModalVisible &&
        <LabLocationModal labLocationModalState={labLocationModalState} handleClose={() => {
          setLabLocationModalState(labLocationInitialState)
        }} />
      }
    </motion.div>
  );
}
