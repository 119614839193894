import React, { Dispatch, SetStateAction } from "react"
import { Input, Popconfirm, Space, Typography } from "antd"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { COLLAPSED_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH } from "src/router/AppRouter"
const { Text } = Typography

type P = {
	clearState: () => void
	objective: string
	setObjective: Dispatch<SetStateAction<string>>
	onPredictClick: () => void
}

export const FooterForm = ({
	clearState,
	objective,
	setObjective,
	onPredictClick,
}: P) => {
	const [t] = useTranslate()
	const isSidebarCollapsed = useSelector(
		(state: StoreState) => state.sidebar.collapsed
	)

	return (
		<div style={{
			display: "flex",
			justifyContent: "space-between",
			padding: '10px 32px 10px 32px',
			marginLeft: isSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : OPEN_SIDEBAR_WIDTH,
			background: '#FAFAFA',
			position: 'fixed',
			bottom: 0,
			right: 0,
			width: '-webkit-fill-available',
			zIndex: 3
		}}>
			<Popconfirm
				okText={t("common.ok")}
				cancelText={t("common.cancel")}
				title={t("common.resetTable")}
				onConfirm={clearState}
			>
				<StyledButton type="primary" ghost>
					{t("compare.clear")}
				</StyledButton>
			</Popconfirm>

			<Space direction="vertical">
				<Space>
					<Text strong>{t("aiEngine.objective")}: </Text>
					<Input
						onChange={(e) => setObjective(e.target.value)}
						value={objective}
						placeholder={t("aiEngine.briefDescription")}
						style={{ width: 400 }}
					/>
					<StyledButton type="primary" onClick={onPredictClick}>
						{t("aiEngine.predict")}
					</StyledButton>
				</Space>
			</Space>
		</div>
	)
}
