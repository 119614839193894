import { Space, Form, Radio, Tooltip, Typography, InputNumber, Popover } from "antd";
import { InfoCircleFilled, ExclamationCircleFilled } from "@ant-design/icons"
import { yellow } from "@ant-design/colors"
import { setIsEditing } from "src/store/actions/isEditing"
import { useDispatch } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"
import { useHistory } from "react-router-dom"
import { StyledButton } from "src/styled_components/StyledButton"


const { Text } = Typography

interface AddtionalParametersProps {
  selectedAlgorithm: any
  additionalParams: any
  setAdditionalParams: React.Dispatch<React.SetStateAction<any>>
}


export function AdditionalParameters({ selectedAlgorithm, additionalParams, setAdditionalParams }: AddtionalParametersProps) {

  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { getDecimalSeparator } = useValue()
  const { push } = useHistory()

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Text type="secondary" strong>{t("doe.additionalParameters")}</Text>
      <Form onValuesChange={(changedValues, values) => dispatch(setIsEditing(true))}>
        {selectedAlgorithm?.parameters?.alpha === null &&
          <Form.Item name="alpha" label={<Space>
            <Text strong>{t("doe.alpha")}</Text>
            <Tooltip title={t("additionalParameters.selectAlphaValueMethod")}>
              <InfoCircleFilled />
            </Tooltip>
          </Space>}>
            <Radio.Group onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, alpha: e.target.value }))}
              value={additionalParams.alpha} defaultValue={additionalParams.alpha}>
              <Radio value={"orthogonal"}>{t("additionalParameters.orthogonal")}</Radio>
              <Radio value={"rotatable"}>{t("additionalParameters.rotatable")}</Radio>
            </Radio.Group>
          </Form.Item>}
        {selectedAlgorithm?.parameters?.face === null &&
          <Form.Item name="face"
            label={<Space>
              <Text strong>{t("doe.additionalParams.face")}</Text>
              <Tooltip title={t("additionalParameters.tooltip.CCDesignType")}>
                <InfoCircleFilled />
              </Tooltip>
            </Space>}>
            <Radio.Group onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, face: e.target.value }))}
              value={additionalParams.face} defaultValue={additionalParams.face}>
              <Radio value={"circumscribed"}>{t("additionalParameters.circumscribed")}</Radio>
              <Radio value={"inscribed"}>{t("additionalParameters.inscribed")}</Radio>
              <Radio value={"faced"}>{t("additionalParameters.faced")}</Radio>
            </Radio.Group>
          </Form.Item>}
        {selectedAlgorithm?.parameters?.reduction === null &&
          <Form.Item name="reduction"
            label={<Space>
              <Text strong>{t("additionalParameters.reductionFactor")}</Text>
              <Tooltip title={t("additionalParameters.reduceExperimentsNumber")}>
                <InfoCircleFilled />
              </Tooltip>
            </Space>}>
            <Popover placement="right" overlayStyle={{ width: 300 }}
              content={<Space>
                <ExclamationCircleFilled style={{ color: yellow[5] }} />
                <Text>
                  {(selectedAlgorithm.name === "_gsd" && additionalParams.reduction === 1) ?
                    <Space direction="vertical">
                      <Text>{t("additionalparameters.useReductionFactor")}</Text>
                      <StyledButton type="primary" onClick={() => {
                        setAdditionalParams({ alpha: "orthagonal", face: "circumscribed", reduction: 2, samples: "", centers: 1 })
                        push('/generate-new-experiments')
                      }} size="small">{t("additionalParameters.useFullFactorial")}</StyledButton>
                    </Space>
                    : t("additionalParameters.nonNegativeReductionFactor")}
                </Text>
              </Space>}
              visible={(selectedAlgorithm.name === "_gsd" && additionalParams.reduction === 1) || additionalParams.reduction < 1}>
              <InputNumber
                onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, reduction: e }))}
                defaultValue={additionalParams.reduction}
                value={additionalParams.reduction}
                decimalSeparator={getDecimalSeparator()}
                min={1} max={10} />
            </Popover>
          </Form.Item>}
        {selectedAlgorithm?.parameters?.centers === null && (
          selectedAlgorithm.name === "_ccdesign" ?
            <>
              <Form.Item name="lft-centers"
                label={<Space>
                  <Text strong>{t("centers.xValue")}</Text>
                  <Tooltip title={t("additionalParameters.numberOfCenterFactorialBlock")}>
                    <InfoCircleFilled />
                  </Tooltip>
                </Space>}>
                <Popover placement="right" overlayStyle={{ width: 300 }}
                  content={<Space>
                    <ExclamationCircleFilled style={{ color: yellow[5] }} />
                    <Text>{t("aiEngine.centerPoints")}</Text>
                  </Space>}
                  visible={additionalParams.centers[0] < 0 || additionalParams.centers[0] === null}>
                  <InputNumber
                    onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, centers: [e, prevState.centers[1]] }))}
                    defaultValue={4}
                    value={additionalParams.centers[0]}
                    decimalSeparator={getDecimalSeparator()}
                    min={0} />
                </Popover>
              </Form.Item>
              <Form.Item name="right-centers"
                label={<Space>
                  <Text strong>{t("centers.yValue")}</Text>
                  <Tooltip title={t("additionalParameters.numberOfCenterStarBlock")}>
                    <InfoCircleFilled />
                  </Tooltip>
                </Space>}>
                <Popover placement="right" overlayStyle={{ width: 300 }}
                  content={<Space>
                    <ExclamationCircleFilled style={{ color: yellow[5] }} />
                    <Text>{t("aiEngine.centerPoints")}</Text>
                  </Space>}
                  visible={additionalParams.centers[1] < 0 || additionalParams.centers[1] === null}>
                  <InputNumber
                    onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, centers: [prevState.centers[0], e] }))}
                    defaultValue={4}
                    value={additionalParams.centers[1]}
                    decimalSeparator={getDecimalSeparator()}
                    min={0} />
                </Popover>
              </Form.Item>
            </>
            :
            <Form.Item name="centers"
              label={<Space>
                <Text strong>{t("common.centers")}</Text>
                <Tooltip title={t("additionalParameters.tooltip.numberOfCenterPoints")}>
                  <InfoCircleFilled />
                </Tooltip>
              </Space>}>
              <Popover placement="right" overlayStyle={{ width: 300 }}
                content={<Space>
                  <ExclamationCircleFilled style={{ color: yellow[5] }} />
                  <Text>{t("aiEngine.centerPoints")}</Text>
                </Space>}
                visible={additionalParams.centers < 0 || additionalParams.centers === null}>
                <InputNumber
                  onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, centers: e }))}
                  value={additionalParams.centers}
                  defaultValue={additionalParams.centers}
                  decimalSeparator={getDecimalSeparator()}
                  min={0} />
              </Popover>
            </Form.Item>
        )}
        {selectedAlgorithm?.parameters?.samples === null &&
          <Form.Item name="samples"
            label={<Space>
              <Text strong>{t("additionalParameters.samples")}</Text>
              <Tooltip title={t("additionalParameters.tooltip.runExperimentsNumber")}>
                <InfoCircleFilled />
              </Tooltip>
            </Space>
            }>
            <InputNumber
              onChange={(e) => setAdditionalParams((prevState: any) => ({ ...prevState, samples: e }))}
              value={additionalParams.samples}
              defaultValue={additionalParams.samples}
              decimalSeparator={getDecimalSeparator()}
              min={1} />
          </Form.Item>}
      </Form>
    </Space>
  )
}
