import "../../../WorkOrderDetails/react-datasheet.css"
import { useCallback, useEffect, useMemo, useState } from "react";
import { Space, Select, message, Checkbox, Form, Tooltip, Segmented, Typography, Slider, Row, Input, Popconfirm, Modal } from "antd"
import { LockFilled } from "@ant-design/icons";
import Text from "antd/lib/typography/Text"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { antdTheme, AsyncStates, } from "src/constants"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import { clearSuggestedExpInsights } from "src/store/actions/insights"
import { newInverseModelRequest } from "src/store/actions/newInverseModel"
import {
  CategoricalObjectiveList,
  isCategoricalProperties,
  CategoricalProperty,
  NumericalProperty,
} from "../types"
import { ZeonCharacterizationSelection } from "src/components/AIEngine/common"
import jwtManager from "src/utils/jwtManager"
import { suggestedExperimentsClear } from "src/store/actions/suggestedExp"
import { StyledCard } from "src/styled_components/StyledCard"
import { useQuery } from "src/utils/useQuery"
import { ModelConfig } from "src/typings/model-config"
import { modelsConfigRequest } from "src/store/actions/formulate"
import PayloadInput from "./input"
import './child.scss'
import { DroppedPropertyWarning } from "../../common/DroppedPropertyWarning";
import { useValue } from "src/utils/useValue";
import { isValidNumber } from "src/utils/decorator";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { COLLAPSED_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH } from "src/router/AppRouter";
const { Option } = Select

interface P {
  setUnsavedChanges: any
  tab: any,
  setExperimentCurrent: any,
  setSelectedObjective: any
}
export type InverseModelProps = P

export type ExludeAllParameterConstraintsTypes = {
  [key: string]: {
    [key: string]: {
      [key: string]: string[]
    }
  }
}

export type ExludeAllCategoryConstraintsTypes = { [key: string]: { [key: string]: string[] } }

export type SelectAllIngredientsInCategoryTypes = {
  [key: string]: {
    [key: string]: {
      [key: string]: string[]
    }
  }
}

const initialStateExludeAllCategoryConstraints = {
  1: {
    "ingredients": []
  }
}

const initialStateExludeAllParameterConstraints = {
  1: {
    "numerical_parameter": {}
  }
}

const initialStateSelectAllIngredientsInCategory = {
  1: {
    numerical: {},
    categorical: {}
  }
}

export type crossCategoricalState = {
  [key: string]: boolean
}

export type crossCategoricalSelectedCategories = {
  [key: string]: string[]
}

export type crossCategoricalUseDefault = {
  [key: string]: boolean
}

export type crossCategoricalValuesType = {
  [key: string]: {
    min: string | null,
    max: string | null
  }
}

const initialStateCrossCategorical = {
  1: false
}

const initialStateCrossCategoricalUseDefault = {
  1: false
}

const initialStateCrossCategoricalSelectedCategories = {
  1: []
}

const initialStateCrossCategoricalValues = {
  1: {
    min: null,
    max: null
  }
}

export const InverseModel = ({
  setUnsavedChanges,
  tab,
  setExperimentCurrent,
  setSelectedObjective
}: P) => {
  const dispatch = useDispatch()
  const { getValue, convertValue } = useValue()
  const [t] = useTranslate()
  const [inverseForm] = Form.useForm()
  let query = useQuery();
  let modelVersion = query?.get('version');
  const [title, setTitle] = useState<string>('')
  const inverseModel = useSelector((state: StoreState) => state.inverseModel)
  const newInverseModel = useSelector(
    (state: StoreState) => state.newInverseModel
  )

  const labels = useSelector(
    (state: StoreState) => state.displayNames.data || {}
  )
  const { current: currentProject } = useSelector(
    (state: StoreState) => state.projects
  )
  const configs = useSelector((state: StoreState) => state.configs.features)

  const {
    configData,
    modelConfigData,
    mlProjectSpecificData,
    mlProjectSpecificDataZeon,
  } = useSelector(
    (state: StoreState) => state.formulate
  )
  const isSidebarCollapsed = useSelector(
    (state: StoreState) => state.sidebar.collapsed
  )

  const defaultInverseConfigurationData = useSelector((state: StoreState) => state.inverseModel.inverseConfigData)

  const [isMultiStageModel, setIsMultiStageModel] = useState((configData.find((modelData: ModelConfig) => modelData.version === Number(modelVersion))?.is_multistage ?? false))
  const [selectedStages, setSelectedStages] = useState<[number, number]>([1, 1])
  const [currentSelectedStage, setCurrentSelectedStage] = useState<number>(1);
  const [activePredictionInputTab, setActivePredictionInputTab] = useState('objectives')

  const [rangeParameterList, setRangeParameterList] = useState<{ [key: string]: any[] }>({ 1: [] })
  const [catwiseRangeParameterList, setCatwiseRangeParameterList] = useState<{ [key: string]: any[] }>({ 1: [] })
  const [categorialInputList, setCategorialInputList] = useState<{ [key: string]: any[] }>({ 1: [] })
  const [selectedCharacterization, setSelectedCharacterization] = useState<{ [key: string]: any }>({ 1: { inverse: {}, forward: {} } })
  const [numericalProperties, setRange] = useState<{ [key: string]: NumericalProperty[] }>({
    1: [
      {
        parameter: "",
        output_range_optimal: `(0 - 0)`,
        min: "",
        max: "",
        checked: false,
        modelType: "",
        value: "",
        priority: null,
      },
    ]
  })
  const [catConstraintsList, setCatConstraintsList] = useState<{
    [key: string]: any[]
  }>({ 1: [] })

  const modelData = useMemo(() => {
    if (!Boolean(modelVersion)) {
      if (
        (mlProjectSpecificData?.properties &&
          Object.keys(mlProjectSpecificData?.properties).length > 0) ||
        (Boolean(configs?.characterization_methods) &&
          Object.keys(mlProjectSpecificDataZeon?.properties || {}).length > 0)
      )
        return undefined
      else
        return Boolean(configs?.characterization_methods)
          ? mlProjectSpecificDataZeon
          : mlProjectSpecificData
    } else {
      return modelConfigData?.[currentSelectedStage - 1]
    }
  }, [
    configs?.characterization_methods,
    currentSelectedStage,
    mlProjectSpecificData,
    mlProjectSpecificDataZeon,
    modelConfigData,
    modelVersion,
  ])

  const currentStageModelConfigData = useMemo(() => modelConfigData?.[currentSelectedStage - 1], [currentSelectedStage, modelConfigData])

  const [categoricalRange, setCategoricalRange] = useState<{
    [key: string]: CategoricalProperty[]
  }>({
    1: [
      {
        parameter: "",
        modelType: "range",
        output_range_optimal: `(0 - 0)`,
        val: [],
        checked: false,
      },
    ],
  })

  const [selectAllIngredientsInCategory, setSelectAllIngredientsInCategory] = useState<SelectAllIngredientsInCategoryTypes>({ ...initialStateSelectAllIngredientsInCategory })
  const [exludeAllParameterConstraints, setExludeAllParameterConstraints] = useState<ExludeAllParameterConstraintsTypes>({ ...initialStateExludeAllParameterConstraints })
  const [exludeAllCategoryConstraints, setExludeAllCategoryConstraints] = useState<ExludeAllCategoryConstraintsTypes>({ ...initialStateExludeAllCategoryConstraints })

  useEffect(() => {
    setExludeAllCategoryConstraints({ ...initialStateExludeAllCategoryConstraints })
    setExludeAllParameterConstraints({ ...initialStateExludeAllParameterConstraints })
    setSelectAllIngredientsInCategory({ ...initialStateSelectAllIngredientsInCategory })
  }, [modelVersion])

  const [isCrossCategorical, setIsCrossCategorical] = useState<crossCategoricalState>({ ...initialStateCrossCategorical })
  const [crossCategoricalSelectedCategories, setCrossCategoricalSelectedCategories] = useState<crossCategoricalSelectedCategories>({ ...initialStateCrossCategoricalSelectedCategories })
  const [isCategoricalConstraintsUseDefault, setIsCategoricalConstraintsUseDefault] = useState<crossCategoricalUseDefault>({ ...initialStateCrossCategoricalUseDefault })
  const [crossCategoricalValues, setCrossCategoricalValues] = useState<crossCategoricalValuesType>({ ...initialStateCrossCategoricalValues })

  const [isCrossCategoricalModalVisible, setIsCrossCategoricalModalVisible] = useState<boolean>(false)

  const [inverseConfigurationData, setInverseConfigurationData] = useState<{ [key: string]: any }>({
    1: defaultInverseConfigurationData
  })

  useEffect(() => {
    setIsCrossCategorical({ ...initialStateCrossCategorical })
    setCrossCategoricalSelectedCategories({ ...initialStateCrossCategoricalSelectedCategories })
    setIsCategoricalConstraintsUseDefault({ ...initialStateCrossCategoricalUseDefault })
    setCrossCategoricalValues({ ...initialStateCrossCategoricalValues })
  }, [modelVersion])

  useEffect(() => {
    const initalInverseConfigurationData = {
      1: defaultInverseConfigurationData
    }
    setInverseConfigurationData({ ...initalInverseConfigurationData })
  }, [defaultInverseConfigurationData])

  // For Range Optimization
  const [statusRange, setStatusRange] = useState<AsyncStates>(
    AsyncStates.INITIAL,
  )

  useEffect(() => {
    setStatusRange(
      inverseModel?.statusRange.find(
        (res: any) => res.version === modelData?.version,
      )?.status || [],
    )
  }, [inverseModel, modelData])

  const numericalObjectiveList: [string, any][] = useMemo(
    () =>
      Object.entries(
        (modelData?.output_range_optimal ?? modelData?.properties) || {},
      ).filter(([_, value]) => !Array.isArray(value)),
    [modelData],
  )

  const categoricalObjectiveList: CategoricalObjectiveList = useMemo(() => {
    if (!modelData) return []
    return Object.entries(
      modelData.output_range_optimal ?? modelData.properties ?? {},
    ).filter(([_, value]) => Array.isArray(value)) as CategoricalObjectiveList
  }, [modelData])

  useEffect(() => {
    if (statusRange === AsyncStates.SUCCESS) {
      setSelectedObjective("")
      setExperimentCurrent(1)
      inverseForm.resetFields()
    }
    // eslint-disable-next-line
  }, [statusRange])

  const stagesOptions = useMemo(() => {
    const options = new Array(
      selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1,
    ).fill(null)
    const segmentOptions = options.map((res, index) => ({
      value: selectedStages[0] + index,
      label: modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1] ?? `${t("common.stage")} ${selectedStages[0] + index}`,
      title: modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1] ?? `Stage ${selectedStages[0] + index}`,
      disabled: false,
    }))
    return segmentOptions
  }, [selectedStages, t, modelConfigData])

  const clearState = useCallback(() => {
    setUnsavedChanges(false)
    setTitle('')
    setSelectedStages([1, 1])
    setCurrentSelectedStage(1)
    setActivePredictionInputTab('objectives')
    setRangeParameterList({ 1: [] })
    setCatwiseRangeParameterList({ 1: [] })
    setCategorialInputList({ 1: [] })
    setSelectedCharacterization({ 1: [] })
    setRange({
      1: [{
        parameter: "",
        output_range_optimal: `(0 - 0)`,
        min: "",
        max: "",
        checked: false,
        modelType: "",
        value: "",
        priority: null,
      }]
    })
    setCatConstraintsList({ 1: [] })
    setCategoricalRange({
      1: [
        {
          parameter: "",
          modelType: "range",
          output_range_optimal: `(0 - 0)`,
          val: [],
          checked: false,
        },
      ],
    })
  }, [setUnsavedChanges]);

  useEffect(() => {
    if (Boolean(modelVersion)) {
      setIsMultiStageModel(
        configData.find(
          (modelData: ModelConfig) =>
            modelData.version === Number(modelVersion),
        )?.is_multistage ?? false,
      )
      setSelectedStages([1, 1])
    }
    setCurrentSelectedStage(1)
    clearState()
  }, [modelVersion, dispatch, configData, clearState])

  const formatter = (value: number | undefined) => {
    const stageName = value !== undefined ? modelConfigData[0]?.all_stages?.[value - 1] : 0

    if (selectedStages[0] === selectedStages[1]) {
      return `Start and End Stage: ${stageName}`
    }
    if (selectedStages[0] === value) {
      return `Start Stage:  ${stageName}`
    }
    if (selectedStages[1] === value) {
      return `End Stage:  ${stageName}`
    }
    return value
  }

  const marks = useMemo(() => {
    return modelConfigData[0]?.all_stages?.reduce(
      (acc: any, slide: string, index: number) => {
        const stagePrefix = `Stage ${(index + 1)}`;
        const stageName = modelConfigData[0]?.all_stages?.[index];
        return {
          ...acc,
          [index + 1]: {
            label: (
              <>
                <Tooltip title={`${stagePrefix}: ${stageName}`}><Typography.Text>{stageName}</Typography.Text></Tooltip>
                {index + 1 < selectedStages[0] ||
                  index + 1 > selectedStages[1] ? (
                  <>
                    {" "}
                    <LockFilled />
                  </>
                ) : null}
              </>
            ),
            style: { width: "max-content" },
          },
        }
      },
      {},
    )
  }, [modelConfigData, selectedStages])

  // rangeParameterList, categorialInputList ===> ingredients and processes
  // numericalProperties, categoricalRange ===> properties
  const getcharacterizationPayload = useMemo(() => {
    return {
      ings_procs: [
        ...(!!rangeParameterList[currentSelectedStage]
          ? rangeParameterList[currentSelectedStage].map(
            (item) => item.parameter,
          )
          : []),
        ...(!!categorialInputList[currentSelectedStage]
          ? categorialInputList[currentSelectedStage].map(
            (item) => item.parameter,
          )
          : []),
      ],
      properties: [
        ...(!!numericalProperties[currentSelectedStage]
          ? numericalProperties?.[currentSelectedStage]?.map(
            (item) => item.parameter,
          )
          : []),
        ...(!!categoricalRange[currentSelectedStage]
          ? categoricalRange?.[currentSelectedStage]?.map(
            (item) => item.parameter,
          )
          : []),
      ].filter((item) => item),
    }
  }, [
    categorialInputList,
    categoricalRange,
    currentSelectedStage,
    numericalProperties,
    rangeParameterList,
  ])

  const handleCrossCategoricalConstraints = (stage: string) => {
    return {
      categories: crossCategoricalSelectedCategories?.[stage] ?? [],
      sum: {
        min: convertValue(crossCategoricalValues[stage]?.min) ?? null,
        max: convertValue(crossCategoricalValues[stage]?.max) ?? null,
      }
    }
  }

  const generateExp = (type: string) => {
    let otherProperties: { [key: string]: any } = {}
    let priority_list: { [key: string]: any } = {}
    let objectives: { [key: string]: any } = {}
    let category_wise_constraints: { [key: string]: any } = {}
    let variations: { [key: string]: any } = {}
    let characterization_method_id: { [key: string]: any } = {}
    let cross_category_constraints: { [key: string]: any } = {}
    let use_default_category_constraints: { [key: string]: any } = {}
    let custom_inverse_configs: { [key: string]: any } = {}

    const correctedNumericalProperties: any = Object.entries(numericalProperties).reduce((acc, [stage, objectives]) => ({
      ...acc,
      [stage]: objectives.map(objective => ({
        ...objective,
        min: convertValue(objective.min),
        max: convertValue(objective.max)
      }))
    }), {})
    for (const stage of Object.keys(correctedNumericalProperties)) {
      const actualStageName = modelConfigData[0]?.all_stages?.[Number(stage) - 1];

      const combinedData = [
        ...correctedNumericalProperties[stage],
        ...categoricalRange[stage],
      ]
      const checkedProperties = combinedData.filter((res) => !!res.parameter)

      const inputsCheck = checkedProperties.filter((property) => {
        if (!isCategoricalProperties(property)) return true
        return !Array.isArray(property.val)
      })
      const optionsCheck = checkedProperties.filter((property) => {
        if (!isCategoricalProperties(property)) {
          return !property.modelType.length
        }

        return (
          !property.modelType.length ||
          (Array.isArray(property.val) && !property.val.length)
        )
      })

      const customFeatureRangeData = [
        ...rangeParameterList[stage],
        ...categorialInputList[stage],
      ]
      otherProperties[actualStageName] = optionsCheck.map(
        ({ parameter }) => parameter,
      )

      if (checkedProperties.length < 1 && stage === String(selectedStages[1]))
        return message.error(`Please select at least one Objective ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        Boolean(configs?.ai_engine_with_methods) &&
        selectedCharacterization?.inverse?.variations?.length === 0
      ) {
        return message.error(
          `${t("common.selectAtleastOneCharacterizationVariation")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`,
        )
      }

      if (
        checkedProperties.filter((property) => property.modelType).length < 1 &&
        stage === String(selectedStages[1])
      ) {
        return message.error(
          `Please select optimization type for at least one property ${isMultiStageModel ? `- ${actualStageName}` : ``}`,
        )
      }

      if (
        !Boolean(modelVersion) &&
        correctedNumericalProperties[stage].length > 0 &&
        correctedNumericalProperties[stage].some(
          (res: any) =>
            !!res.modelType.length &&
            res.modelType !== "range" &&
            !res.priority,
        ) &&
        stage === String(selectedStages[1])
      )
        return message.error(`Please Input Priority ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        inputsCheck.length > 0 &&
        stage === String(selectedStages[1]) &&
        inputsCheck.some((property) => {
          if (isCategoricalProperties(property)) return false
          return (
            property.modelType === "range" && (property.min === null || property.max === null)
          )
        })
      )
        return message.error(`${t("aiEngine.minMaxValuesCannotBeBlank")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        Boolean(modelVersion) &&
        !!customFeatureRangeData?.length &&
        customFeatureRangeData.every(
          (res: any) => res?.max === null || res.min === null,
        )
      )
        return message.error(`${t("aiEngine.minMaxValuesCannotBeBlank")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        !Object.values(catwiseRangeParameterList).length ||
        Object.values(catwiseRangeParameterList)
          .flat()[0]
          ?.categories.every((catObj: any) => catObj.exclude)
      ) {
        setActivePredictionInputTab("category-constraints")
        return message.error(`Include atleast 1 category ${isMultiStageModel ? `- ${actualStageName}` : ``}`)
      }

      priority_list[actualStageName] = checkedProperties.reduce(
        (obj, element) => {
          if (isCategoricalProperties(element)) return obj
          if (!element.priority) return obj

          return {
            ...obj,
            [element.parameter]: element.priority,
          }
        },
        {},
      )

      objectives[actualStageName] = {
        ...checkedProperties.reduce<{
          [key: string]: any
        }>((acc, curr) => {
          if (curr.modelType.length < 1) return acc
          if (otherProperties[actualStageName].includes(curr.parameter))
            return acc

          if (curr.modelType === "range") {
            acc[curr.modelType] = checkedProperties.reduce((obj, element) => {
              if (element.modelType !== curr.modelType) return obj
              if (otherProperties[actualStageName].includes(element.parameter))
                return acc

              const key = element.parameter

              return {
                ...obj,
                [key]: isCategoricalProperties(element)
                  ? element.val
                  : { min: element.min, max: element.max },
              }
            }, {})
          } else {
            acc["minmax"] = {
              ...acc["minmax"],
              ...checkedProperties.reduce((obj, element) => {
                if (element.modelType !== curr.modelType) return obj
                if (isCategoricalProperties(element)) return obj

                const key = element.parameter

                return {
                  ...obj,
                  [key]: element.value,
                }
              }, {}),
            }
          }
          return acc
        }, {}),
      }

      category_wise_constraints[actualStageName] = {
        ...catwiseRangeParameterList[stage]
          .flatMap((typeObj: any) => typeObj.categories)
          .reduce(
            (acc: any, cur: any) => ({
              ...acc,
              [cur.category]: {
                ...cur,
                item_constraints: Object.entries(cur.item_constraints).reduce((acc, [param, constraint]: any) => ({
                  ...acc,
                  [param]: Array.isArray(constraint) ? constraint : {
                    ...constraint,
                    min: convertValue(constraint.min),
                    max: convertValue(constraint.max),
                    range: {
                      min: convertValue(constraint.min),
                      max: convertValue(constraint.max)
                    }
                  }
                }), {})
              },
            }),
            {},
          ),
      }

      cross_category_constraints[actualStageName] = handleCrossCategoricalConstraints(stage)

      use_default_category_constraints[actualStageName] = isCategoricalConstraintsUseDefault[stage]

      custom_inverse_configs[actualStageName] = Object.keys(inverseConfigurationData?.[stage] ?? {}).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: inverseConfigurationData?.[stage]?.[curr]?.value ?? null
        }
      }, {})

      if (Boolean(configs?.ai_engine_with_methods)) {
        variations[actualStageName] =
          selectedCharacterization?.[stage].inverse?.variations

        characterization_method_id[actualStageName] =
          selectedCharacterization?.[stage].inverse?.characterization_id
      }
    }

    let catwisePayload: any = {
      token: jwtManager.getToken(),
      ...(Boolean(modelVersion) && { version: modelData.version }),
      ...(!Boolean(modelVersion) && { project_id: currentProject }),
      priority_list,
      ...(otherProperties.length > 0 && { other_properties: otherProperties }),
      objectives,
      ...(!!Object.values(catwiseRangeParameterList).flat().length && {
        category_wise_constraints,
      }),
      ...(Boolean(configs?.ai_engine_with_methods) && {
        variations,
        characterization_method_id,
      }),
      title,
      cross_category_constraints,
      use_default_category_constraints,
      custom_inverse_configs
    }

    if (!isMultiStageModel) {
      const actualStageName = modelConfigData[0]?.all_stages?.[currentSelectedStage - 1];
      catwisePayload = {
        ...catwisePayload,
        priority_list: priority_list[actualStageName],
        objectives: objectives[actualStageName],
        cross_category_constraints: cross_category_constraints[actualStageName],
        use_default_category_constraints: use_default_category_constraints[actualStageName],
        ...(otherProperties.length > 0 && {
          other_properties: otherProperties[actualStageName],
        }),
        ...(!!Object.values(catwiseRangeParameterList).flat().length && {
          category_wise_constraints:
            category_wise_constraints[actualStageName],
        }),
        ...(Boolean(configs?.ai_engine_with_methods) && {
          variations: variations[actualStageName],
          characterization_method_id:
            characterization_method_id[actualStageName],
        }),
      }
    }

    if (isMultiStageModel) {
      const start_stage = modelConfigData[0]?.all_stages?.[Number(Object.keys(correctedNumericalProperties)[0]) - 1] ?? `Stage ${Object.keys(correctedNumericalProperties)[0]}`
      const end_stage = modelConfigData[0]?.all_stages?.[Number(Object.keys(correctedNumericalProperties)[Object.keys(correctedNumericalProperties).length - 1]) - 1] ?? `Stage ${Object.keys(correctedNumericalProperties)[Object.keys(correctedNumericalProperties).length - 1]}`
      const catwisePayloadFinal = catwisePayload['objectives'][end_stage]
      catwisePayload['objectives'] = { [end_stage]: catwisePayloadFinal }
      catwisePayload["custom_inverse_configs"] = { [end_stage]: catwisePayload['custom_inverse_configs'][end_stage] }
      catwisePayload = {
        ...catwisePayload,
        start_stage,
        end_stage
      }
    }

    dispatch(clearSuggestedExpInsights())
    dispatch(
      newInverseModelRequest({
        payload: catwisePayload,
        isMultiStage: isMultiStageModel,
      }),
    )
  }

  const getDisplayNames = useCallback(
    (key: string) => {
      return labels?.properties?.[key]?.name ?? key
    },
    [labels?.properties],
  )

  const optionsDropDown = (rangeData: any, index: number) => {
    return (
      <Select
        style={{ width: "100%" }}
        value={
          categoricalRange[currentSelectedStage][index]?.parameter
            ? rangeData?.[index]?.val
            : []
        }
        disabled={!categoricalRange[currentSelectedStage][index]?.parameter}
        mode="multiple"
        onChange={(e) => {
          setCategoricalRange((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState))
            newState[currentSelectedStage][index].val = e
            return { ...newState }
          })
        }}
      >
        {(modelData?.output_range_optimal ?? modelData?.properties)?.[
          rangeData?.parameter
        ]?.map((res: string) => (
          <Option
            key={typeof res === "boolean" ? String(res) : res}
            value={typeof res === "boolean" ? String(res) : res}
          >
            {typeof res === "boolean" ? String(res) : res}
          </Option>
        ))}
      </Select>
    )
  }

  const disableCell = useCallback(
    (record: any, type: string) => {
      const result = numericalProperties[currentSelectedStage].find(
        (res) => res.parameter === record.parameter,
      )
      if (
        result?.modelType &&
        result.modelType.length > 0 &&
        result.modelType === type
      ) {
        return false
      } else {
        return true
      }
    },
    [currentSelectedStage, numericalProperties],
  )

  const addRow = (inputType: string) => {
    if (inputType === "properties") {
      setRange((state) => {
        const newState = JSON.parse(JSON.stringify(state))

        if (newState[currentSelectedStage]) {
          newState[currentSelectedStage].push({
            parameter: "",
            output_range_optimal: `(0 - 0)`,
            min: "",
            max: "",
            checked: false,
            modelType: "",
            value: "",
            priority: null,
          })
        } else {
          newState[currentSelectedStage] = [
            {
              parameter: "",
              output_range_optimal: `(0 - 0)`,
              min: "",
              max: "",
              checked: false,
              modelType: "",
              value: "",
              priority: null,
            },
          ]
        }

        return {
          ...newState,
        }
      })
    } else {
      setCategoricalRange((state) => {
        const newState = JSON.parse(JSON.stringify(state))

        if (newState[currentSelectedStage]) {
          newState[currentSelectedStage].push({
            parameter: "",
            modelType: "range",
            output_range_optimal: `(0 - 0)`,
            val: [],
            checked: false,
          })
        } else {
          newState[currentSelectedStage] = [
            {
              parameter: "",
              modelType: "range",
              output_range_optimal: `(0 - 0)`,
              val: [],
              checked: false,
            },
          ]
        }
        return {
          ...newState,
        }
      })
    }
  }

  const removeRow = useCallback(
    (parameterIndex: number, constraintstype: string) => {
      if (constraintstype === "numerical_output_constraints") {
        setRange((state) => {
          const newState = JSON.parse(JSON.stringify(state))
          if (newState[currentSelectedStage].length > 1) {
            newState[currentSelectedStage] = [
              ...newState[currentSelectedStage].filter(
                (_: NumericalProperty, index: number) =>
                  index !== parameterIndex,
              ),
            ]
          } else {
            newState[currentSelectedStage] = [
              {
                parameter: "",
                output_range_optimal: `(0 - 0)`,
                min: "",
                max: "",
                checked: false,
                modelType: "",
                value: "",
                priority: null,
              },
            ]
          }
          return { ...newState }
        })
      } else {
        setCategoricalRange((state) => {
          const newState = JSON.parse(JSON.stringify(state))
          if (newState[currentSelectedStage].length > 1) {
            newState[currentSelectedStage] = [
              ...newState[currentSelectedStage].filter(
                (_: CategoricalProperty, index: number) =>
                  index !== parameterIndex,
              ),
            ]
          } else {
            newState[currentSelectedStage] = [
              {
                parameter: "",
                modelType: "range",
                output_range_optimal: `(0 - 0)`,
                val: [],
                checked: false,
              },
            ]
          }
          return { ...newState }
        })
      }
    },
    [currentSelectedStage],
  )

  const parameterListDropDown = useCallback(
    (index: any, constraintstype: string) => {
      if (constraintstype === "numerical_output_constraints") {
        return (
          <Row
            style={{
              alignItems: "center",
              width: "100%",
              padding: 8,
              justifyContent: "space-between",
              gap: 8,
              flexWrap: "nowrap",
            }}
          >
            <Tooltip title={t("common.remove")}>
              <StyledDeleteIcon
                style={{
                  fontSize: antdTheme.fontSizeHeading4,
                  verticalAlign: "baseline",
                  outline: "none",
                }}
                onClick={() => removeRow(index, constraintstype)}
              />
            </Tooltip>

            <Select
              value={
                getDisplayNames(
                  numericalProperties[currentSelectedStage]?.[index]?.parameter,
                ) ||
                numericalProperties[currentSelectedStage]?.[index]?.parameter
              }
              showSearch
              style={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                verticalAlign: "bottom",
              }}
              optionFilterProp="children"
              filterOption={(input, option: any) => {
                return (option?.children ?? "")
                  ?.toLowerCase()
                  ?.includes(input.toLowerCase())
              }}
              notFoundContent={
                <Space style={{ background: "whitesmoke", width: "100%" }}>
                  <Text>
                    {!numericalObjectiveList.length
                      ? "NO OBJECTIVES FOUND"
                      : "You have selected all the parameters."}
                  </Text>
                </Space>
              }
              dropdownRender={(menu) => {
                return (
                  <>
                    {!!numericalObjectiveList.length && (
                      <Checkbox
                        style={{ padding: 10 }}
                        checked={
                          numericalProperties[currentSelectedStage].filter(
                            (numerical) => numerical.parameter,
                          ).length === numericalObjectiveList.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newRange = numericalObjectiveList?.map(
                              ([ele, value]) => ({
                                parameter: ele,
                                output_range_optimal: `(${getValue(value?.min)} - ${getValue(value?.max)})`,
                                min: "",
                                max: "",
                                checked: false,
                                modelType: "",
                                value: "",
                                priority: null,
                              }),
                            )
                            setRange((prevState) => ({
                              ...prevState,
                              [currentSelectedStage]: newRange,
                            }))
                          } else {
                            setRange((prevState) => {
                              const newState = JSON.parse(
                                JSON.stringify(prevState),
                              )
                              newState[currentSelectedStage] = [
                                {
                                  parameter: "",
                                  output_range_optimal: `(0 - 0)`,
                                  min: "",
                                  max: "",
                                  checked: false,
                                  modelType: "",
                                  value: "",
                                  priority: null,
                                },
                              ]
                              return { ...newState }
                            })
                          }
                        }}
                      >{`${t("common.selectAll")}`}</Checkbox>
                    )}
                    {menu}
                  </>
                )
              }}
              onSelect={(e: any) => {
                const newRange = [...numericalProperties[currentSelectedStage]]
                newRange[index].parameter = e
                newRange[index].output_range_optimal = `(${getValue((modelData?.output_range_optimal ?? modelData?.properties)?.[
                  e
                ]?.min)
                  } - ${getValue((modelData?.output_range_optimal ?? modelData?.properties)?.[
                    e
                  ]?.max)
                  })`

                newRange[index].min = ""
                newRange[index].max = ""
                newRange[index].checked = false
                newRange[index].modelType = ""
                newRange[index].value = ""
                newRange[index].priority = null
                setRange((prevState) => ({
                  ...prevState,
                  [currentSelectedStage]: newRange,
                }))
                setUnsavedChanges(true)
              }}
              placeholder={t("aiEngine.inverseModel.selectType")}
            >
              {numericalObjectiveList
                ?.filter(
                  ([key]) =>
                    !numericalProperties[currentSelectedStage]
                      .map((row) => row?.parameter)
                      .includes(key),
                )
                .map(([res]) => (
                  <Option value={res} key={res}>
                    {getDisplayNames(res)}
                  </Option>
                ))}
            </Select>
          </Row>
        )
      } else {
        return (
          <Row
            style={{
              alignItems: "center",
              width: "100%",
              padding: 8,
              justifyContent: "space-between",
              gap: 8,
              flexWrap: "nowrap",
            }}
          >
            <Tooltip title={t("common.remove")}>
              <StyledDeleteIcon
                style={{
                  fontSize: antdTheme.fontSizeHeading4,
                  verticalAlign: "baseline",
                  outline: "none",
                }}
                onClick={() => removeRow(index, constraintstype)}
              />
            </Tooltip>

            <Select
              value={
                getDisplayNames(
                  categoricalRange[currentSelectedStage]?.[index]?.parameter,
                ) ?? categoricalRange[currentSelectedStage]?.[index]?.parameter
              }
              style={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                verticalAlign: "bottom",
              }}
              optionFilterProp="children"
              filterOption={(input, option: any) => {
                return (option?.children ?? "")
                  ?.toLowerCase()
                  ?.includes(input.toLowerCase())
              }}
              notFoundContent={
                <Space style={{ background: "whitesmoke", width: "100%" }}>
                  <Text>
                    {!categoricalObjectiveList.length
                      ? "NO OBJECTIVES FOUND"
                      : "You have selected all the parameters."}
                  </Text>
                </Space>
              }
              dropdownRender={(menu) => {
                return (
                  <>
                    {!!Object.keys(
                      (modelData?.output_range_optimal ??
                        modelData?.properties) ||
                      {},
                    ).length && (
                        <Checkbox
                          style={{ padding: 10 }}
                          checked={
                            categoricalObjectiveList.length ===
                            categoricalRange[currentSelectedStage].filter(
                              (categorical: any) => categorical?.parameter,
                            )?.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newCategoricalRange = categoricalObjectiveList
                                ?.filter(
                                  ([key]) =>
                                    !categoricalRange[currentSelectedStage]
                                      .map((row) => row?.parameter)
                                      .includes(key),
                                )
                                .map(([ele, value]) => ({
                                  parameter: ele,
                                  modelType: "range",
                                  output_range_optimal: `${value}`,
                                  val: [],
                                  checked: false,
                                }))
                              setCategoricalRange((prevState) => ({
                                ...prevState,
                                [currentSelectedStage]: newCategoricalRange,
                              }))
                            } else {
                              setCategoricalRange((prevState) => {
                                const newState = JSON.parse(
                                  JSON.stringify(prevState),
                                )

                                newState[currentSelectedStage] = [
                                  {
                                    parameter: "",
                                    modelType: "range",
                                    output_range_optimal: `(0 - 0)`,
                                    val: [],
                                    checked: false,
                                  },
                                ]
                                return { ...newState }
                              })
                            }
                          }}
                        >{`${t("common.selectAll")}`}</Checkbox>
                      )}
                    {menu}
                  </>
                )
              }}
              onSelect={(e: any) => {
                const newCategoricalRange = [
                  ...categoricalRange[currentSelectedStage],
                ]
                newCategoricalRange[index].parameter = e
                newCategoricalRange[
                  index
                ].output_range_optimal = `${(modelData?.output_range_optimal ??
                  modelData?.properties)?.[e]?.join(", ")}`
                newCategoricalRange[index].modelType = "range"
                newCategoricalRange[index].val = []
                newCategoricalRange[index].checked = false
                setCategoricalRange((prevState) => ({
                  ...prevState,
                  [currentSelectedStage]: newCategoricalRange,
                }))
                setUnsavedChanges(true)
              }}
              placeholder={t("aiEngine.inverseModel.selectType")}
            >
              {categoricalObjectiveList
                ?.filter(
                  ([key]) =>
                    !categoricalRange[currentSelectedStage]
                      .map((row: any) => row?.parameter)
                      .includes(key),
                )
                .map(([res]) => (
                  <Option value={res} key={res}>
                    {getDisplayNames(res) ?? { res }}
                  </Option>
                ))}
            </Select>
          </Row>
        )
      }
    },
    [
      t,
      getDisplayNames,
      numericalProperties,
      currentSelectedStage,
      numericalObjectiveList,
      removeRow,
      modelData?.output_range_optimal,
      modelData?.properties,
      setUnsavedChanges,
      categoricalRange,
      categoricalObjectiveList,
      getValue
    ],
  )

  useEffect(() => {
    if (newInverseModel.inverseStatus === AsyncStates.ERROR) {
      dispatch(suggestedExperimentsClear())
    }
  }, [newInverseModel, dispatch])

  // On stages selection change moving to last stage objective

  useEffect(() => {
    setCurrentSelectedStage(selectedStages[1])
    setActivePredictionInputTab("objectives")

    const stage = selectedStages[1]
    if (!Object.keys(modelConfigData[stage - 1] || {}).length && Boolean(modelVersion)) {
      dispatch(
        modelsConfigRequest({
          version: modelVersion,
          isMultiStage: isMultiStageModel,
          stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedStages])

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size="middle"
    >
      {/* Slider */}
      {Boolean(modelVersion) && isMultiStageModel && (
        <Space size={"large"}>
          <Typography.Text>
            {t("aiEngine.selectStagesForOptimization")}
            {": "}
          </Typography.Text>
          <Slider
            tooltip={{ formatter }}
            style={{
              margin: "1rem",
              transition: "all .5s",
              width: (modelConfigData[0]?.all_stages?.length ?? 1) * 80,
              maxWidth: "90%",
            }}
            value={selectedStages}
            range
            marks={marks}
            step={null}
            min={1}
            max={modelConfigData[0]?.all_stages?.length ?? 1}
            onChange={(val: number[]) => {
              setSelectedStages((prev: [number, number]) => {
                if (prev[0] !== val[0]) {
                  if (val[0] > prev[0]) {
                    if (
                      val[0] >= currentSelectedStage &&
                      prev[1] >= currentSelectedStage
                    ) {
                      if (!Object.keys(modelConfigData[val[0]] || {}).length) {
                        dispatch(
                          modelsConfigRequest({
                            version: modelVersion,
                            isMultiStage: isMultiStageModel,
                            stage_name:
                              modelConfigData?.[0]?.all_stages?.[val[0] - 1],
                          }),
                        )
                      }
                      setCurrentSelectedStage(val[0])
                    }
                  }
                } else {
                  if (val[1] < prev[1]) {
                    if (
                      !(
                        currentSelectedStage >= prev[0] &&
                        currentSelectedStage <= val[1]
                      )
                    ) {
                      if (!Object.keys(modelConfigData[val[1]] || {}).length) {
                        dispatch(
                          modelsConfigRequest({
                            version: modelVersion,
                            isMultiStage: isMultiStageModel,
                            stage_name:
                              modelConfigData?.[0]?.all_stages?.[val[1] - 1],
                          }),
                        )
                      }
                      setCurrentSelectedStage(val[1])
                    }
                  }
                }
                return [val[0], val[1]]
              })

              setRange((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = [
                      {
                        parameter: "",
                        output_range_optimal: `(0 - 0)`,
                        min: "",
                        max: "",
                        checked: false,
                        modelType: "",
                        value: "",
                        priority: null,
                      },
                    ]
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setRangeParameterList((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setCatwiseRangeParameterList((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setCategorialInputList((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setSelectedCharacterization((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = { inverse: {}, forward: {} }
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setCategoricalRange((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = [
                      {
                        parameter: "",
                        modelType: "range",
                        output_range_optimal: `(0 - 0)`,
                        val: [],
                        checked: false,
                      },
                    ]
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setIsCrossCategorical((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = false
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })

              setIsCategoricalConstraintsUseDefault((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = false
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })

              setCrossCategoricalSelectedCategories((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })

              setCrossCategoricalValues((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: crossCategoricalValuesType = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = { min: null, max: null }
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }

              })

              setInverseConfigurationData((state: any) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = defaultInverseConfigurationData
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }

                return {
                  ...finalState,
                }
              })
            }}
          />
        </Space>
      )}

      {/* Stage tabs and main content */}
      <div style={{ width: "100%", paddingBottom: '32px' }}>
        {Boolean(modelVersion) && isMultiStageModel && (
          <Segmented
            options={stagesOptions}
            value={currentSelectedStage}
            style={{ transition: "all .5s" }}
            size="middle"
            onChange={(stage: any) => {
              if (!Object.keys(modelConfigData[stage - 1] || {}).length) {
                dispatch(
                  modelsConfigRequest({
                    version: modelVersion,
                    isMultiStage: isMultiStageModel,
                    stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
                  }),
                )
              }
              setCurrentSelectedStage(stage)
            }}
            className="inverse-stage-segmented"
          />
        )}

        {Boolean(modelVersion) && !!currentStageModelConfigData?.missing_properties?.length ? <DroppedPropertyWarning missingProperties={currentStageModelConfigData?.missing_properties} /> : null}

        <StyledCard
          style={{ width: "100%" }}
          bodyStyle={{ padding: 0 }}
          headStyle={{ padding: 0 }}
        >
          {Boolean(configs?.ai_engine_with_methods) && (
            <ZeonCharacterizationSelection
              selectedCharacterization={selectedCharacterization}
              setSelectedCharacterization={setSelectedCharacterization}
              ings_procs={getcharacterizationPayload.ings_procs}
              properties={getcharacterizationPayload.properties}
              tab={tab}
              currentSelectedStage={currentSelectedStage}
            />
          )}
          <div style={{ width: "100%" }}>
            <PayloadInput
              modelData={modelData}
              addRow={addRow}
              disableCell={disableCell}
              numericalObjectiveList={numericalObjectiveList}
              parameterListDropDown={parameterListDropDown}
              setUnsavedChanges={setUnsavedChanges}
              numericalProperties={numericalProperties[currentSelectedStage]}
              setRange={setRange}
              currentSelectedStage={currentSelectedStage}
              isMultiStageModel={isMultiStageModel}
              categoricalObjectiveList={categoricalObjectiveList}
              categoricalRange={categoricalRange[currentSelectedStage]}
              optionsDropDown={optionsDropDown}
              rangeParameterList={rangeParameterList[currentSelectedStage]}
              setRangeParameterList={setRangeParameterList}
              catwiseRangeParameterList={
                catwiseRangeParameterList[currentSelectedStage]
              }
              setCatwiseRangeParameterList={setCatwiseRangeParameterList}
              categorialInputList={categorialInputList[currentSelectedStage]}
              setCategorialInputList={setCategorialInputList}
              activePredictionInputTab={activePredictionInputTab}
              setActivePredictionInputTab={setActivePredictionInputTab}
              selectedStages={selectedStages}
              catConstraintsList={catConstraintsList[currentSelectedStage]}
              setCatConstraintsList={setCatConstraintsList}
              modelVersion={modelVersion}
              setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
              selectAllIngredientsInCategory={selectAllIngredientsInCategory[currentSelectedStage]}
              exludeAllParameterConstraints={exludeAllParameterConstraints[currentSelectedStage]}
              setExludeAllParameterConstraints={setExludeAllParameterConstraints}
              exludeAllCategoryConstraints={exludeAllCategoryConstraints[currentSelectedStage]}
              setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
              isCrossCategorical={isCrossCategorical}
              setIsCrossCategorical={setIsCrossCategorical}
              setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}
              isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
              setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
              inverseConfigurationData={inverseConfigurationData}
              setInverseConfigurationData={setInverseConfigurationData}
            />
          </div>
        </StyledCard>
      </div>

      {/* Predict button */}
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        padding: '10px 32px 10px 32px',
        marginLeft: isSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : OPEN_SIDEBAR_WIDTH,
        background: '#FAFAFA',
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '-webkit-fill-available',
        zIndex: 3
      }}>
        <Popconfirm
          okText={t("common.ok")}
          cancelText={t("common.cancel")}
          title={t("common.resetTable")}
          onConfirm={clearState}
        >
          <StyledButton type="primary" ghost>
            {t("compare.clear")}
          </StyledButton>
        </Popconfirm>

        <Space>
          <Text strong>{t("common.title")}: </Text>
          <Input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder={t("aiEngine.briefDescription")}
            style={{ width: 400 }}
          />
          <Tooltip
            title={
              newInverseModel.inverseStatus === AsyncStates.LOADING
                ? t("aiEngine.inverseModel.predictingExperiments")
                : ""
            }
          >
            <StyledButton
              loading={newInverseModel.inverseStatus === AsyncStates.LOADING}
              type="primary"
              onClick={() => {
                setUnsavedChanges(false)
                generateExp("range")
              }}
              style={{ outline: "none" }}
            >
              {newInverseModel.inverseStatus === AsyncStates.LOADING
                ? t("aiEngine.predicting")
                : t("aiEngine.inverseModel.predictExperiments")}
            </StyledButton>
          </Tooltip>
        </Space>
      </div >

      <CrossCategoricalModal
        open={isCrossCategoricalModalVisible}
        setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}

        crossCategoricalSelectedCategories={crossCategoricalSelectedCategories}
        setCrossCategoricalSelectedCategories={setCrossCategoricalSelectedCategories}

        crossCategoricalValues={crossCategoricalValues}
        setCrossCategoricalValues={setCrossCategoricalValues}

        currentSelectedStage={currentSelectedStage}

        catwiseParameterList={catwiseRangeParameterList[currentSelectedStage]}
        isMultiStageModel={isMultiStageModel}
      />
    </Space >
  )
}

const CrossCategoricalModal = ({
  open,
  setIsCrossCategoricalModalVisible,

  crossCategoricalSelectedCategories,
  setCrossCategoricalSelectedCategories,

  crossCategoricalValues,
  setCrossCategoricalValues,

  currentSelectedStage,

  catwiseParameterList,
  isMultiStageModel
}: any) => {
  const [t] = useTranslate()
  const { convertValue } = useValue()

  const options = useMemo(() => {
    return (catwiseParameterList || [])
      ?.filter((typeObj: any) => typeObj.parameter_type === "ingredients")
      ?.flatMap((typeObj: any) => {
        return typeObj?.categories?.map((item: any) => {
          return (
            <Option value={item?.category} key={item?.category}>
              {item?.category}
            </Option>
          )
        })
      })
  }, [catwiseParameterList])

  const handleClear = useCallback(() => {
    setCrossCategoricalSelectedCategories((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = []
      return { ...newState }
    })
    setCrossCategoricalValues((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = { min: null, max: null }
      return { ...newState }
    })
  }, [currentSelectedStage, setCrossCategoricalSelectedCategories, setCrossCategoricalValues])

  const getCrossCategoryContraintValue = (input: any) => {
    if (isValidNumber(input)) {
      return input
    } else {
      const inputValue = input.trim()
      return inputValue === "" ? null : inputValue
    }
  }

  return (
    <Modal
      open={open}
      title={
        <Space style={{ justifyContent: 'space-between', width: '95%' }} >
          {`${t("aiEngine.crossCategorical")}`}
          <StyledButton key="clear" onClick={handleClear}>
            {t("common.clear")}
          </StyledButton>
        </Space>
      }
      closable={true}
      onCancel={() => {
        if (isMultiStageModel && !!crossCategoricalSelectedCategories[currentSelectedStage]?.length) {
          if (crossCategoricalValues[currentSelectedStage]?.min === null) {
            return message.error("Please Enter Min Quantity")
          }
          if (crossCategoricalValues[currentSelectedStage]?.max === null) {
            return message.error("Please Enter Max Quantity")
          }
        }
        setIsCrossCategoricalModalVisible(false)
      }}
      footer={null}
    >
      <Space direction="vertical" style={{ width: "100%", gap: 20 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>{t("common.selectCategory")}</Text>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            value={crossCategoricalSelectedCategories[currentSelectedStage]}
            onChange={(e: any) => {
              setCrossCategoricalSelectedCategories((state: any) => {
                const newState = JSON.parse(JSON.stringify(state))

                newState[currentSelectedStage] = e
                return { ...newState }
              }
              )
            }}
            allowClear
            dropdownRender={(menu) => {
              return (
                <div>
                  <Checkbox
                    style={{ padding: 10 }}
                    checked={crossCategoricalSelectedCategories[currentSelectedStage]?.length === options?.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCrossCategoricalSelectedCategories((state: any) => {
                          const newState = JSON.parse(JSON.stringify(state))
                          newState[currentSelectedStage] = options?.map((item: any) => item?.props?.value)
                          return { ...newState }
                        })
                      } else {
                        setCrossCategoricalSelectedCategories((state: any) => {
                          const newState = JSON.parse(JSON.stringify(state))
                          newState[currentSelectedStage] = []
                          return { ...newState }
                        })
                      }
                    }}
                  >
                    {`${t("common.selectAll")} ${t("common.category")}`}
                  </Checkbox>
                  {menu}
                </div>
              )
            }}
          >
            {options}
          </Select>
        </Space>

        <Space style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Space direction="vertical">
            <Text strong>{t("common.minQuantity")}</Text>
            <Input
              value={crossCategoricalValues[currentSelectedStage]?.min ?? null}
              onBlur={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const convertedValue = convertValue(e.target.value)
                  const newState = JSON.parse(JSON.stringify(state))

                  if (isNaN(convertedValue)) {
                    message.error(t("aiEngine.pleaseEnterAValidNumber"),)
                  } else if (newState[currentSelectedStage].max !== null) {
                    if (convertedValue > convertValue(newState[currentSelectedStage].max)) {
                      newState[currentSelectedStage].min = null
                      message.error(t("aiEngine.minShouldBeLessThanMax"))
                    }
                  }
                  else {
                    const value = getCrossCategoryContraintValue(e.target.value)
                    newState[currentSelectedStage].min = value
                  }

                  return { ...newState }
                })
              }}
              onChange={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const newState = JSON.parse(JSON.stringify(state))
                  newState[currentSelectedStage].min = getCrossCategoryContraintValue(e.target.value)

                  return { ...newState }
                })
              }}
            />
          </Space>

          <div>~</div>

          <Space direction="vertical">
            <Text strong>{t("common.maxQuantity")}</Text>
            <Input
              value={crossCategoricalValues[currentSelectedStage]?.max ?? null}
              onBlur={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const convertedValue = convertValue(e.target.value)
                  const newState = JSON.parse(JSON.stringify(state))

                  if (isNaN(convertedValue)) {
                    message.error(t("aiEngine.pleaseEnterAValidNumber"),)
                  } else if (newState[currentSelectedStage].min !== null) {
                    if (convertedValue < convertValue(newState[currentSelectedStage].min)) {
                      newState[currentSelectedStage].max = null
                      message.error(t("aiEngine.maxShouldBeGreaterThanMin"))
                    }
                  }
                  else {
                    newState[currentSelectedStage].max = getCrossCategoryContraintValue(e.target.value)
                  }
                  return { ...newState }
                })
              }}
              onChange={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const newState = JSON.parse(JSON.stringify(state))
                  newState[currentSelectedStage].max = getCrossCategoryContraintValue(e.target.value)

                  return { ...newState }
                })
              }}
            />
          </Space>
        </Space>

      </Space>
    </Modal>

  )

}