import { CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import useTranslate from 'src/utils/useTranslate'
import { QualityCheckStatus } from '..'
import { antdTheme } from 'src/constants'

const Status = ({ qc_details, status }: any) => {
  const [t] = useTranslate()

  if(status !== QualityCheckStatus.COMPLETED){
    return null
  }

  return qc_details?.alert && qc_details?.alert > 0 ? (
    <Tooltip title={t('qc.alert')}>
      <ExclamationCircleFilled style={{ color: '#FAAD14',  fontSize:antdTheme.fontSizeHeading4 }} />
    </Tooltip>
  ) : qc_details.total - qc_details.passed > 0 ? (
    <CloseCircleFilled style={{ color: '#FF4D4F',  fontSize:antdTheme.fontSizeHeading4 }} />
  ) : (
    <CheckCircleFilled style={{ color: '#52C41A', fontSize:antdTheme.fontSizeHeading4 }} />
  )
}

export default Status
