import { useCallback, useMemo, useState } from 'react'
import { Select, Space, Col, Row, Form, Typography, Cascader, Slider } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { convertToPrecision, isValidNumber } from 'src/utils/decorator'
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import useTranslate from 'src/utils/useTranslate'
import { exportGraphRequest } from 'src/store/actions/workOrderDetails'
import { AsyncStates } from 'src/constants'
import { StyledButton } from 'src/styled_components/StyledButton'

const { Option } = Select
const { Text } = Typography


export const CharacterizationsPlots = () => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const datasetLabels = useSelector((state: StoreState) => state.displayNames.data || {})
    const experiments = useSelector((state: StoreState) => state.workOrderDetails.experiment)
    const graphExportStatus = useSelector((state: StoreState) => state.workOrderDetails.graphExportStatus)
    const trialSetList = useMemo(() => (
        experiments.map((trialSet: any) => ({
            formulation_id: trialSet?.id_set?.formulation_id,
            display_id: trialSet?.meta?.display_id,
            ingredients: trialSet?.ingredients,
            processing: trialSet?.processing?.[0]?.processing,
            characterizations: trialSet?.characterizations?.[0]?.characterizations,
            properties: trialSet?.properties?.[0]?.properties,
        }))
    ), [experiments])
    const [selectedTrials, setSelectedTrials] = useState<any>([])
    const [xaxis, setXaxis] = useState([])
    const [yaxis, setYaxis] = useState([])
    const [range, setRange] = useState<any>({ x: { min: "", max: "" }, y: { min: "", max: "" } })

    const options: any = useMemo(() => ([
        {
            ...(trialSetList.some((exp: any) => Object.keys(exp?.ingredients || {}).length) && {
                value: 'ingredients',
                label: t('common.ingredients'),
                children: [...new Set(trialSetList.flatMap((exp: any) => Object.keys(exp?.ingredients || {})))]
                    .map((res: any) => ({
                        value: res,
                        label: datasetLabels?.ingredients?.[res]?.name
                    }))
            }),
        },
        {
            ...(trialSetList.some((exp: any) => Object.keys(exp?.processing || {}).length) && {
                value: 'processing',
                label: t('common.processing'),
                children: [...new Set(trialSetList.flatMap((exp: any) => Object.keys(exp?.processing || {})))]
                    .map((res: any) => ({
                        value: res,
                        label: datasetLabels?.processing?.[res]?.name
                    }))
            }),
        },
        {
            ...(trialSetList.some((exp: any) => Object.keys(exp?.characterizations || {}).length) && {
                value: 'characterizations',
                label: t('common.characterizations'),
                children: [...new Set(trialSetList.flatMap((exp: any) => Object.keys(exp?.characterizations || {})))]
                    .map((res: any) => ({
                        value: res,
                        label: datasetLabels?.characterizations?.[res]?.name
                    }))
            }),
        },
        {
            ...(trialSetList.some((exp: any) => Object.keys(exp?.properties || {}).length) && {
                value: 'properties',
                label: t('common.properties'),
                children: [...new Set(trialSetList.flatMap((exp: any) => Object.keys(exp?.properties || {})))]
                    .map((res: any) => ({
                        value: res,
                        label: datasetLabels?.properties?.[res]?.name
                    }))
            }),
        }
    ].filter(values => JSON.stringify(values) !== '{}') ?? []
    ), [trialSetList, datasetLabels, t])

    const displayRender = (labels: string[]) => labels.join('/')

    const exportGraphs = useCallback(() => {
        const graph = new Promise(async (resolve: any, reject: any) => {
            let svgDom: any = document?.getElementsByClassName(
                "highcharts-root" || ""
            )?.[0]
            svgDom?.setAttribute("id", "plot")
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")
            if (!!svgDom) {
                const svgXml = new XMLSerializer()?.serializeToString(svgDom)
                canvas.width = svgDom?.width?.baseVal?.value
                canvas.height = svgDom?.height?.baseVal?.value
                const img = document.createElement("img")
                img.setAttribute(
                    "src",
                    "data:image/svg+xml;base64," +
                    window.btoa(unescape(encodeURIComponent(svgXml)))
                )
                try {
                    await img.decode()
                    ctx?.drawImage(
                        img,
                        0,
                        0,
                        svgDom?.width?.baseVal?.value,
                        svgDom?.height?.baseVal?.value
                    )
                    return resolve(canvas.toDataURL("image/png", 1.0)?.replace("data:image/png;base64,", ""))
                } catch (error) {
                    return reject()
                }
            }
        })
        graph.then((res: any) => {
            dispatch(exportGraphRequest({ graph: res }))
        })
    }, [dispatch])

    const getTrendLine = useCallback((data: any[]) => {
        const n = data.length;

        let sumX = 0,
            sumY = 0,
            sumXY = 0,
            sumX2 = 0

        // Calculate the sums needed for linear regression
        data.forEach((res: any) => {
            const { x, y } = res
            sumX += x
            sumY += y
            sumXY += x * y
            sumX2 += x ** 2
        })

        // Calculate the slope of the trend line
        const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2)

        // Calculate the intercept of the trend line
        const intercept = (sumY - slope * sumX) / n

        const trendline = [] // Array to store the trend line data points

        // Find the minimum and maximum x-values from the scatter plot data
        const minX = Math.min(...data.map((res) => res?.x))
        const maxX = Math.max(...data.map((res) => res?.x))

        // Calculate the corresponding y-values for the trend line using the slope and intercept
        trendline.push([minX, minX * slope + intercept])
        trendline.push([maxX, maxX * slope + intercept])

        return { trendline, slope: convertToPrecision(slope), intercept: convertToPrecision(intercept) }
    }, [])

    const formulationGraphs = useMemo(() => {
        const dataset = trialSetList.filter((trial: any) => selectedTrials.includes(trial?.formulation_id))
            .map((trial: any) => ({
                x: isValidNumber(trial?.[xaxis?.[0]]?.[xaxis?.[1]]?.value) ? trial?.[xaxis?.[0]]?.[xaxis?.[1]]?.value : null,
                y: isValidNumber(trial?.[yaxis?.[0]]?.[yaxis?.[1]]?.value) ? trial?.[yaxis?.[0]]?.[yaxis?.[1]]?.value : null,
                trialName: trial?.display_id
            }))
        const xValues: any = dataset.map((res: any) => res?.x)
        const xMin = Math.min(...xValues)
        const xMax = Math.max(...xValues)
        const yValues: any = dataset.map((res: any) => res?.y)
        const yMin = Math.min(...yValues)
        const yMax = Math.max(...yValues)

        // Calculation for best fit line and R sq
        const { trendline, slope, intercept } = getTrendLine(dataset)
        const residualArray: any = []
        dataset.forEach((res: any) => {
            const actualValue = res?.y
            const calculatedValue = slope * res?.x + intercept
            residualArray.push(actualValue - calculatedValue)
        })
        const residualsSquared = residualArray.reduce((res: any, sum: number) => sum + (res * res), 0)
        const meanValues = yValues.reduce((res: any, sum: number) => sum + res, 0) / dataset.length
        const totalSumSqaures = yValues.reduce((res: any, sum: number) => sum + ((res - meanValues) * (res - meanValues)), 0)
        const rSqaureValue = 1 - (residualsSquared / totalSumSqaures)

        let plotOptions = {}
        if (!!selectedTrials?.length && !!xaxis?.[1] && !!yaxis?.[1]) {
            plotOptions = {
                credits: {
                    enabled: false,
                },
                chart: {
                    height: "550px",
                    zoomType: "xy"
                },
                title: {
                    text: !!xaxis?.[1] && !!yaxis?.[1] ? `${datasetLabels?.[xaxis?.[0]]?.[xaxis?.[1]]?.name} vs ${datasetLabels?.[yaxis?.[0]]?.[yaxis?.[1]]?.name}` : "",
                    style: { fontSize: "1.2em", fontWeight: 'bold' }
                },
                legend: { enabled: true, itemStyle: { fontSize: "1em" } },
                xAxis: {
                    title: {
                        text: datasetLabels?.[xaxis?.[0]]?.[xaxis?.[1]]?.name,
                        style: { fontSize: "1em", fontWeight: 'bold' }
                    },
                    labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true,
                },
                yAxis: {
                    title: {
                        text: datasetLabels?.[yaxis?.[0]]?.[yaxis?.[1]]?.name,
                        style: { fontSize: "1em", fontWeight: 'bold' }
                    },
                    labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: "",
                    pointFormatter: function (this: any) {
                        return `<strong>${this?.trialName || ""}</strong><br/><br/>
                    <strong>${datasetLabels?.[xaxis?.[0]]?.[xaxis?.[1]]?.name}: </strong><span>${(this.x)}</span><br/>
                    <strong>${datasetLabels?.[yaxis?.[0]]?.[yaxis?.[1]]?.name}: </strong><span>${(this.y)}</span>`
                    },
                },
                series: [
                    {
                        type: 'line',
                        name: 'Best Fit Line',
                        data: trendline
                            .filter(([x_point, y_point]: any[]) => (x_point !== null) &&
                                (isValidNumber(range?.x?.min) ? range?.x?.min <= x_point : true) &&
                                (isValidNumber(range?.x?.max) ? range?.x?.max >= x_point : true) &&
                                (y_point !== null) &&
                                (isValidNumber(range?.y?.min) ? range?.y?.min <= y_point : true) &&
                                (isValidNumber(range?.y?.max) ? range?.y?.max >= y_point : true)
                            ),
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                lineWidth: 0
                            }
                        },
                        enableMouseTracking: false
                    },
                    {
                        name: datasetLabels?.[yaxis?.[0]]?.[yaxis?.[1]]?.name,
                        data: dataset
                            .filter(({ x, y }: any) => (x !== null) &&
                                (isValidNumber(range?.x?.min) ? range?.x?.min <= x : true) &&
                                (isValidNumber(range?.x?.max) ? range?.x?.max >= x : true) &&
                                (y !== null) &&
                                (isValidNumber(range?.y?.min) ? range?.y?.min <= y : true) &&
                                (isValidNumber(range?.y?.max) ? range?.y?.max >= y : true)
                            ),
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: true
                        }
                    }
                ]
            }
        }

        return (!!selectedTrials?.length && !!xaxis?.[1] && !!yaxis?.[1] ? (
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Row justify={"end"}>
                    <StyledButton type="primary" onClick={exportGraphs} loading={graphExportStatus === AsyncStates.LOADING}>
                        {"Export Graph"}
                    </StyledButton>
                </Row>
                <HighchartsReact
                    key={"characterization_graph"}
                    highcharts={Highcharts}
                    options={plotOptions}
                />
                {(isValidNumber(slope) && isValidNumber(intercept) &&
                    <Row justify={"center"}>
                        <Text type="secondary" strong>{`Equation for the liner curve is y=(${slope})x + (${intercept}) and R²=${rSqaureValue}`}</Text>
                    </Row>
                )}
                <Row justify={"space-between"} align={"middle"}>
                    <Col span={2}>
                        <Text strong type="secondary">{"X-axis"}</Text>
                    </Col>
                    <Col span={22}>
                        <Slider
                            key={"separateX"}
                            range
                            min={xMin}
                            max={xMax}
                            defaultValue={[xMin, xMax]}
                            onChangeComplete={(e) => {
                                setRange((prevState: any) => ({ ...prevState, x: { min: e?.[0], max: e?.[1] } }))
                            }}
                        />
                    </Col>
                </Row>
                <Row justify={"space-between"} align={"middle"}>
                    <Col span={2}>
                        <Text strong type="secondary">{"Y-axis"}</Text>
                    </Col>
                    <Col span={22}>
                        <Slider
                            key={"separateY"}
                            range
                            min={yMin}
                            max={yMax}
                            defaultValue={[yMin, yMax]}
                            onChangeComplete={(e) => {
                                setRange((prevState: any) => ({ ...prevState, y: { min: e?.[0], max: e?.[1] } }))
                            }}
                        />
                    </Col>
                </Row>
            </Space>
        ) : null)
    }, [selectedTrials, xaxis, yaxis, trialSetList, datasetLabels, getTrendLine, range, exportGraphs, graphExportStatus])


    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Col span={12}>
                <Form layout="vertical">
                    <Form.Item label="Select Trials">
                        <Select mode="multiple" style={{ width: "100%" }} allowClear onChange={(e: any) => {
                            setSelectedTrials(e)
                        }}>
                            {trialSetList.map((trial: any) => (
                                <Option value={trial.formulation_id} key={trial?.formulation_id}>
                                    {trial?.display_id}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="X axis">
                        <Cascader
                            options={options}
                            value={xaxis}
                            displayRender={displayRender}
                            showSearch
                            onChange={(e: any) => {
                                setXaxis(e)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Y axis">
                        <Cascader
                            options={options}
                            value={yaxis}
                            displayRender={displayRender}
                            showSearch
                            onChange={(e: any) => {
                                setYaxis(e)
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Row justify="center">
                {formulationGraphs}
            </Row>
        </Space>
    )
}
