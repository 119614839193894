import { orange } from "@ant-design/colors"
import {
  InfoCircleOutlined,
  ArrowsAltOutlined,
  LinkOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons"
import {
  Checkbox,
  Col,
  Divider,
  message,
  notification,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  // Table,
  Typography,
  Modal,
} from "antd";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Cell } from "src/components/IDataSheet/IDataSheet"
import {
  cellChangesAdd,
  copyTrialsRequest,
  createTrialRequest,
  // autoSaveExperimentRequest,
  editExperimentCleanup,
  editExperimentRequest,
  setEditingState,
  addParamRequest,
  updateParamRequest,
  removeParamRequest,
  savePartialRequest,
} from "src/store/actions/workOrderDetails"
import { StoreState } from "src/store/configureStore"
import { Dataset } from "./getHeaders"
import {
  EditableField,
  RemoveBtn,
  getDatasetTranslated,
} from "src/components/Datasheet/Datasheet"
import Big from "big.js"
import useTranslate from "./useTranslate"
import { apply } from "json-logic-js"
import { setSelectedTrials } from "src/store/actions/workOrders"
import { getDropdownFilteredValue, isValidNumber } from "./decorator"
import { StyledButton } from "src/styled_components/StyledButton"
import { antdTheme, AsyncStates, EXP_PAGE_SIZE } from "src/constants"
import Title from "antd/es/typography/Title"
import { updateExperimentUnitRequest } from "src/store/actions/woUnitConversion"
import { InventoryDetail } from "src/modules/InventoryV2/InventoryDetail"
import { useValue } from "./useValue"
const { Text, Paragraph } = Typography


export const totalTrialValueForUnit = ["mol%", "mol", "phr", "wt%"] // Check if this unit exist send sum of trial values in value_grams

interface UseDatasheetParams {
  showTotals?: boolean
  showModal: (value: string) => void
  showLinkedModal: (value: string) => void
  styles?: any
  batchMode?: boolean,
  showCosting?: boolean,
  displayIdList: string[]
  setDisplayIdList: React.Dispatch<React.SetStateAction<string[]>>
  initialTrialSetList: any
  parameterList: string[]
  setParameterList: React.Dispatch<React.SetStateAction<string[]>>
  trialSetDataList: { [key: string]: string }[]
  setTrialSetDataList: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }[]>
  >
  initialParameterList: any
  // setNewTrial: React.Dispatch<
  //   React.SetStateAction<{
  //     keyword: string
  //     count: number
  //   }>
  // >
  highlightIndex: number
  setHighlightIndex: React.Dispatch<React.SetStateAction<number>>
  setShowNewParamModal: React.Dispatch<React.SetStateAction<boolean>>
  userAccess: boolean
  copyTrials: any
  setCopTrialsVisible: React.Dispatch<React.SetStateAction<boolean>>
  current: Number
  inventoryColState: any
  inventoryStateFlag: any
  initialTrials: any[],
  showBaseCategoryModal: {
    isModalVisible: boolean;
    toUnit: string | null;
    fromUnit: string | null;
    isDirectConversionPossible: boolean,
    base_categories: string[],
    base_ingredients: string[]
  };
  setShowBaseCategoryModal: React.Dispatch<React.SetStateAction<any>>;
  batchSizeList: { value_grams: string; pref_unit: string }[],
  setBatchSizeList: React.Dispatch<React.SetStateAction<any>>,
  unitData: { [key: string]: any },
  setUnitData: React.Dispatch<React.SetStateAction<any>>,
  setShowCard: React.Dispatch<React.SetStateAction<any>>
  total: number,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  setPropertiesModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIngredientDetailInventoryId: React.Dispatch<React.SetStateAction<string | null>>
}

interface UseDatasheetState {
  save: () => void
  addParameter: () => void
  addTrial: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    newTrial: {
      keyword: string
      count: number
    }
  ) => void
  datasheet: Cell[][]
  onCellChanges: (
    changes: {
      cell: Cell
      row: number
      col: number
      value: string
    }[]
  ) => void
  createCopiedTrials: () => void,
  getParameterUnit: (parameter_id: string) => any
}

const labelForSearchSupport = (label: any) => {
  return `${label.name} ${label.category || ""} ${label.sub_category || ""
    } ${label.supplier || ""} ${label.lot_no || ""} ${typeof label.costing === "string"
      ? label.costing
      : label.costing &&
        label.costing.amount &&
        label.costing.quantity &&
        label.costing.unit
        ? `${parseFloat(
          (label.costing.amount / label.costing.quantity).toPrecision(5),
        )}/${label.costing.unit}`
        : ""
    }`.trim();
};

export const generateOptionBar = (
  dataset: Dataset,
  value: any,
  label: any,
  setPropertiesModalOpen: any,
  setIngredientDetailInventoryId: any,
  setPropertiesModalTitle: any,
  datasetLabels: any,
  getCategoryName: any
) => {
  const labelMapping: any = {
    category: "Category",
    sub_category: "Sub-Category",
    costing: "Costing",
    identifier: "Identifier",
    lot_no: "Lot No.",
    name: "Name",
    supplier: "Supplier",
    unit: "Unit",
    stage: "Stage",
    options: "Options",
    main_group: "Main Group",
    sub_group: "Sub Group",
    expiry_date: 'Expiry Date'
  }

  const datasource = Object.entries(datasetLabels[value])
    .filter(
      (prop) =>
        prop[1] !== null &&
        prop[0] !== "meta" &&
        prop[0] !== "project_id" &&
        prop[0] !== "company_id" &&
        prop[0] !== "name" &&
        prop[0] !== "identifier" &&
        prop[0] !== "actions" &&
        prop[0] !== "-",
    )
    .filter(
      (prop) =>
        !Array.isArray(prop[1]) || (Array.isArray(prop[1]) && prop[1].length > 0)
    )
    .filter(
      (prop: [any, any]) =>
        prop[0] !== "costing" || (prop[0] === "costing"
          &&
          Object.keys(prop[1]).filter(k => k !== "currency").reduce((acc, curr) => {
            return acc || prop[1][curr]
          }, false))
    )
    .sort()
    .map(([property, val]: [string, any]) => {
      return {
        key: property,
        properties: labelMapping[property],
        value: { property, val },
      };
    })

  const categoryName = getCategoryName(value)

  return (
    <Select.Option value={value} label={labelForSearchSupport(label)}>
      <Row
        style={{
          justifyContent: "space-between",
          gap: "10px",
          flexWrap: "nowrap",
          flexDirection: "row",
          maxWidth: 700
        }}
      >
        <Col>
          <Text ellipsis={{ tooltip: String(label?.name) }} style={{ maxWidth: 400 }}>{String(label?.name)}</Text>
          {
            // !!datasetLabels?.[value]?.lot_no?.trim() || // tpm fix because of lot_no as number
            !!datasetLabels?.[value]?.lot_no ||
              !!datasetLabels?.[value]?.supplier?.trim() ||
              !!datasetLabels?.[value]?.sub_category?.trim() ||
              !!categoryName?.trim() ||
              !!datasetLabels?.[value]?.costing ? (
              <Row style={{ color: "#8c8c8c", gap: "0px 5px" }}>
                {!!categoryName?.trim() && (
                  <Text>
                    <Text style={{ color: "#bfbfbf" }}>{"Category : "}</Text>
                    <Text ellipsis={{ tooltip: categoryName }} style={{ maxWidth: 100 }}>{categoryName}</Text>
                  </Text>
                )}
                {!!datasetLabels?.[value]?.sub_category?.trim() && (
                  <Text>
                    <Text style={{ color: "#bfbfbf" }}>
                      {"Sub Category : "}
                    </Text>
                    <Text ellipsis={{ tooltip: datasetLabels?.[value]?.sub_category }} style={{ maxWidth: 100 }}>{datasetLabels?.[value]?.sub_category}</Text>
                  </Text>
                )}
                {/* {!!datasetLabels?.[value]?.lot_no?.trim() && ( tmp fix*/}
                {!!datasetLabels?.[value]?.lot_no && (
                  <Text>
                    <Text style={{ color: "#bfbfbf" }}>{"Lot No : "}</Text>
                    <Text ellipsis={{ tooltip: datasetLabels?.[value]?.lot_no }} style={{ maxWidth: 100 }}>{datasetLabels?.[value]?.lot_no}</Text>
                  </Text>
                )}
                {!!datasetLabels?.[value]?.supplier?.trim() && (
                  <Text>
                    <Text style={{ color: "#bfbfbf" }}>{"Supplier : "}</Text>
                    <Text ellipsis={{ tooltip: datasetLabels?.[value]?.supplier }} style={{ maxWidth: 100 }}>{datasetLabels?.[value]?.supplier}</Text>
                  </Text>
                )}
                {!!datasetLabels?.[value]?.costing &&
                  typeof datasetLabels?.[value]?.costing === "string" ? (
                  <Text>
                    <Text style={{ color: "#bfbfbf" }}>{"Costing : "}</Text>
                    <Text ellipsis={{ tooltip: datasetLabels?.[value]?.costing }} style={{ maxWidth: 100 }}>
                      {`${parseFloat(
                        Number(
                          datasetLabels?.[value]?.costing,
                        ).toPrecision(),
                      )}`}
                    </Text>
                  </Text>
                ) : !!datasetLabels?.[value]?.costing &&
                  datasetLabels?.[value]?.costing.amount &&
                  datasetLabels?.[value]?.costing.quantity &&
                  datasetLabels?.[value]?.costing.unit ? (
                  <Text>
                    <Text style={{ color: "#bfbfbf" }}>{"Costing : "}</Text>
                    <Text ellipsis={{
                      tooltip: `${parseFloat(
                        (
                          datasetLabels?.[value]?.costing.amount /
                          datasetLabels?.[value]?.costing.quantity
                        ).toPrecision(5),
                      )}/${datasetLabels?.[value]?.costing.unit}`
                    }} style={{ maxWidth: 100 }}>{`${parseFloat(
                      (
                        datasetLabels?.[value]?.costing.amount /
                        datasetLabels?.[value]?.costing.quantity
                      ).toPrecision(5),
                    )}/${datasetLabels?.[value]?.costing.unit}`}</Text>
                  </Text>
                ) : null}
              </Row>
            ) : (
              <Row style={{ color: "#8c8c8c", gap: "10px" }}>
                <Text ellipsis={{ tooltip: datasetLabels?.[value]?.costing }} style={{ maxWidth: 100 }}>
                  <Text style={{ color: "#bfbfbf" }}>{"Category : "}</Text>
                  <Text>{"N/A"}</Text>
                </Text>
              </Row>
            )}
        </Col>
        {(datasource.length > 0) && !!datasetLabels[value]?.inventory_id?.length && dataset === "ingredients" && (
          <ExpandAltOutlined
            className="ingredient-expand-modal"
            onClick={(e) => {
              e.stopPropagation();
              setPropertiesModalOpen(true);
              setIngredientDetailInventoryId(datasetLabels[value]?.inventory_id);
              setPropertiesModalTitle(String(label?.name));
            }}
          />
        )}
      </Row>
      <Divider style={{ margin: "2px 0" }} />
    </Select.Option>
  );
};

export const IngredientsDetailsModal = ({ propertiesModalOpen, setPropertiesModalOpen, setIngredientDetailInventoryId, inventory_id }: any) => {
  return <Modal
    title={<Title level={5} style={{ marginBottom: "24px" }}>Ingredient Details</Title>}
    centered
    width={1000}
    open={propertiesModalOpen}
    onCancel={(e) => {
      e.stopPropagation()
      setPropertiesModalOpen(false)
      setIngredientDetailInventoryId(null)
    }}
    footer={null}
    closable
    keyboard
  // destroyOnClose
  >
    <InventoryDetail inventory_id={inventory_id} />
  </Modal>
}

export const useDatasheet = (
  dataset: Dataset,
  {
    showTotals = false,
    showModal,
    showLinkedModal,
    styles,
    batchMode = false,
    showCosting = false,
    displayIdList,
    // setNewTrial,
    setDisplayIdList,
    initialTrialSetList,
    parameterList,
    setParameterList,
    trialSetDataList,
    setTrialSetDataList,
    initialParameterList,
    highlightIndex,
    setHighlightIndex,
    setShowNewParamModal,
    userAccess,
    copyTrials,
    setCopTrialsVisible,
    current,
    inventoryColState,
    inventoryStateFlag,
    initialTrials,
    setShowBaseCategoryModal,
    showBaseCategoryModal,
    batchSizeList,
    setBatchSizeList,
    unitData,
    setUnitData,
    setShowCard,
    setTotal,
    setPropertiesModalOpen,
    setIngredientDetailInventoryId
  }: UseDatasheetParams
): UseDatasheetState => {

  const [t] = useTranslate()
  const { getValue, convertValue } = useValue()
  const dispatch = useDispatch()
  const { supplier, batch_number, sub_category } = inventoryColState
  const linkedTrials = useSelector((state: StoreState) => state.workOrderDetails.linkedExperiment)
  const configs = useSelector((state: StoreState) => state.configs.features)
  const selectedTrials = useSelector((state: StoreState) => state.workOrders.selectedTrials || [])
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const unitList = useSelector((state: StoreState) => state.conversion.unitList)
  const ingredientsInventory = useSelector((state: StoreState) => state.displayNames.inventoryData.ingredients)
  const editFormulationsStatus = useSelector((state: StoreState) => state.workOrderDetails.editFormulationsStatus)
  const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder || {})
  const companyId = useSelector(
    (state: StoreState) => state.login.loginResponse.company_id,
  )

  const getUnitOptions = useCallback((currentUnit) => {
    if (workOrder?.is_multiunit) {
      return Array.from(new Set(unitList.map((res: any) => res.category.toLowerCase()))).map((category: any) => ({
        label: String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1),
        options: unitList.filter((res: any) => res.category.toLowerCase() === category).map((res: any, index: any) => ({
          label: res?.name, value: res?.name, key: res.name + index
        }))
      }))
    }
    else {
      if (dataset === Dataset.ingredients) {
        const categoryUnits = unitList.filter(({ category }) => ["weight", "ratio"].includes(category.toLowerCase()))
        return categoryUnits?.map(({ name: value }) => ({
          label: value,
          value,
        }))
      }
      const currentUnitCategory = unitList.find(({ name: unit }) => unit === currentUnit)?.category
      const categoryUnits = unitList.filter(({ category }) => category.toLowerCase() === currentUnitCategory)
      return categoryUnits?.map(({ name: value }) => ({ label: value, value }))
    }
  }, [unitList, dataset, workOrder?.is_multiunit])

  const datasetLabelsSelector = useCallback((state: StoreState) => state.displayNames.data?.[dataset] || {}, [dataset])
  const datasetLabels = useSelector(datasetLabelsSelector)
  // const removeParamApiData = useSelector((state: StoreState) => state.workOrderDetails.removeParameterState || {})

  const commentsList = useMemo(() => initialTrials.map((trial: any) => trial?.meta?.comment), [initialTrials])
  const expIndex = useMemo(() => workOrder?.stages?.findIndex((stage: any) => stage?.identifier === workOrder?.work_order_parent_stage), [workOrder?.stages, workOrder?.work_order_parent_stage])
  const { currency } = useSelector((store: StoreState) => store.login.loginResponse);
  const { updateExperimentWithUnitStatus, unitConvertedData } = useSelector((state: StoreState) => state.woExperimentUnitConversion);
  const { current: currentProject } = useSelector(
    (state: StoreState) => state.projects
  )
  const ingredientAndPropertyCategories = useSelector((state: StoreState) => state.repository.allCategories.data);

  const getCategoryName = useCallback((parameterName: string) => {
    const categoryIdOrName = displayNames?.[dataset]?.[parameterName]?.category
    if (dataset === Dataset.ingredients) {
      return initialParameterList?.parameterData?.[parameterName]?.category ?? ingredientAndPropertyCategories?.ingredient_category?.find((res: any) => res?.category_id === categoryIdOrName)?.name ?? categoryIdOrName
    } else if (dataset === Dataset.properties) {
      return initialParameterList?.parameterData?.[parameterName]?.category ?? ingredientAndPropertyCategories?.property_category?.find((res: any) => res?.category_id === categoryIdOrName)?.name ?? categoryIdOrName
    } else {
      return initialParameterList?.parameterData?.[parameterName]?.category || categoryIdOrName
    }
  }, [ingredientAndPropertyCategories, dataset, displayNames, initialParameterList]);

  useEffect(() => {
    setTrialSetDataList((state) =>
      displayIdList.map((id, idx) => {
        return parameterList.reduce(
          (acc, parameter) => ({
            ...acc,
            [parameter]: getValue(initialTrialSetList[idx]?.[dataset]?.[parameter]?.value) ??
              null,
          }),
          {}
        )
      })
    );
  }, [
    displayIdList,
    parameterList,
    dataset,
    setTrialSetDataList,
    initialTrialSetList,
    getValue
  ]);

  useEffect(() => {
    if (editFormulationsStatus === AsyncStates.SUCCESS) {
      dispatch(editExperimentCleanup())
    }
  }, [dispatch, editFormulationsStatus])

  useEffect(() => {
    return () => {
      dispatch(editExperimentCleanup())
    }
  }, [dispatch])


  const getParameterUnit = useCallback((parameter: string): any => {
    return dataset === Dataset.ingredients
      ? updateExperimentWithUnitStatus === AsyncStates.SUCCESS
        ? unitConvertedData?.[0]?.input_data?.[parameter]?.unit ??
        Object.values(unitData || {})?.[0]
        : parameterList.some((res: any) => Object.keys(unitData).includes(res))
          ? initialParameterList.units?.[parameter] ||
          (parameter.includes(`${t("workOrderDetails.newParameter")}`)
            ? undefined
            : Object.values(unitData || {})?.[0]) ||
          datasetLabels?.[parameter]?.unit?.[0]
          : initialParameterList.units?.[parameter] ||
          datasetLabels?.[parameter]?.unit?.[0]
      : updateExperimentWithUnitStatus === AsyncStates.SUCCESS
        ? unitConvertedData?.[0]?.input_data?.[parameter]?.unit ??
        (parameter.includes(`${t("workOrderDetails.newParameter")}`)
          ? undefined
          : Object.values(unitData || {})?.[0])
        : initialParameterList.units?.[parameter] ||
        unitData[parameter] ||
        datasetLabels?.[parameter]?.unit?.[0];
  }, [dataset, datasetLabels, initialParameterList, parameterList, t, unitConvertedData, unitData, updateExperimentWithUnitStatus],)

  const getStateUnit = useCallback((parameter, state) => {
    if (dataset === Dataset.ingredients && !workOrder?.is_multiunit) {
      if (updateExperimentWithUnitStatus === AsyncStates.SUCCESS) {
        return {
          unit: unitConvertedData?.[0]?.input_data?.[parameter]?.unit ?? Object.values(state || {})?.[0],
          saveRequest: null
        }
      }
      else {
        let saveRequest
        if (
          !initialParameterList.units?.[parameter] &&
          !datasetLabels?.[parameter]?.unit?.[0] &&
          !parameter.includes(`${t("workOrderDetails.newParameter")}`) &&
          !state?.[parameter] &&
          parameterList?.map((res: any) => datasetLabels?.[res]?.unit?.[0])?.find((res: any) => !!res)
        ) {
          saveRequest = { parameter, unit: parameterList?.map((res: any) => datasetLabels?.[res]?.unit?.[0])?.find((res: any) => !!res) }
        }
        return {
          unit: (parameter.includes(`${t("workOrderDetails.newParameter")}`) ? undefined
            : initialParameterList.units?.[parameter] || (Object.values(state || {})?.[0]) || datasetLabels?.[parameter]?.unit?.[0]) ||
            parameterList?.map((res: any) => datasetLabels?.[res]?.unit?.[0])?.find((res: any) => !!res),
          saveRequest
        }
      }
    }
    else {
      if (updateExperimentWithUnitStatus === AsyncStates.SUCCESS) {
        return {
          unit: unitConvertedData?.[0]?.input_data?.[parameter]?.unit ??
            (parameter.includes(`${t("workOrderDetails.newParameter")}`) ? undefined : Object.values(state || {})?.[0]),
          saveRequest: null
        }
      }
      else {
        return {
          unit: initialParameterList.units?.[parameter] || state[parameter] || datasetLabels?.[parameter]?.unit?.[0],
          saveRequest: null
        }
      }
    }
  }, [dataset, parameterList, datasetLabels, updateExperimentWithUnitStatus, initialParameterList, unitConvertedData, t, workOrder?.is_multiunit])

  const [updatedParameterUnits, setUpdatedParameterUnits] = useState<any>([])
  useEffect(() => {
    setUnitData((state: any) => {
      const saveData: any = []
      const newState = parameterList.reduce((acc, parameter) => {
        const { unit: parameterUnit, saveRequest } = getStateUnit(parameter, state)
        if (!!saveRequest?.unit) saveData.push(saveRequest)
        return { ...acc, [parameter]: parameterUnit }
      }, {})
      if (!!saveData?.length) setUpdatedParameterUnits(saveData)
      return newState
    })
  }, [parameterList, setUnitData, getStateUnit])

  useEffect(() => {
    if (!!updatedParameterUnits?.length) {
      const savePayload = {
        "data": initialTrialSetList.flatMap((trial: any) => updatedParameterUnits?.map((param: any) => {
          return {
            experiment_id: trial?.experiment_id,
            parameter_id: trial?.id_set?.formulation_id,
            parameter_identifier: param?.parameter,
            parameter_type: Dataset.ingredients,
            value: trial?.ingredients?.[param?.parameter]?.value || "",
            unit: getParameterUnit(param?.unit)
          }
        })
        )
      }
      dispatch(savePartialRequest(savePayload))
      setUpdatedParameterUnits([])
    }
  }, [dispatch, updatedParameterUnits, initialTrialSetList, getParameterUnit])

  const [duplicateTrials, setDuplicateTrials] = useState<{
    [key: string]: any
  }>({})

  const addParameter = useCallback(() => {
    setParameterList((state) => [
      ...state,
      `${t("workOrderDetails.newParameter")} ${state.length + 1}`,
    ])
  }, [setParameterList, t])

  const removeParameter = useCallback(
    (parameterIndex: number) => {
      // dispatch(setEditingState(true))
      const removeParamPayload = {
        "experiment_id": workOrder?.experiment_id[expIndex],
        "work_order_id": workOrder?.work_order_id,
        "identifier": parameterList[parameterIndex],
        "parameter_type": dataset,
      }
      setParameterList((state) =>
        state.filter(
          (item, index) => index !== parameterIndex
        )
      );
      if (!parameterList[parameterIndex].includes(t("workOrderDetails.newParameter"))) {
        dispatch(removeParamRequest(removeParamPayload))
      };
    },
    [dispatch, setParameterList, parameterList, dataset, expIndex, workOrder?.experiment_id, workOrder?.work_order_id, t]
  )

  // useEffect(() => {
  // setParameterList((state) =>
  //   state.filter((item, index) => item !== removeParamApiData.data.parameter)
  // );
  // }, [removeParamApiData]);

  const getTrialName: any = useCallback((namesList: string[], count: number) => {
    const name = `${t("common.trial")} ${count}`
    const isUnique = namesList.find((id) => id === name)
    if (isUnique) {
      const newCounter = count + 1
      return getTrialName(namesList, newCounter)
    } else {
      return name
    }
  }, [t])

  const createCopiedTrials = useCallback(() => {
    const trials: any = []
    selectedTrials.forEach((idIndex: any) => {
      if (!!Object.keys(initialTrials?.[idIndex] || {}).length) {
        trials.push(initialTrials[idIndex])
      }
      else {
        const selectedDisplayIds: any = displayIdList.find((id: any, index: any) => index === idIndex)
        const trial = initialTrials.find((res: any) => res?.id_set?.formulation_id === duplicateTrials?.[selectedDisplayIds]?.formulation_id)
        if (Object.keys(trial || {}).length) {
          trials.push(trial)
        }
      }
    })
    const newIds: string[] = [];
    const formulationIds = trials.map((trial: any, index: number) => {
      const newId: any = getTrialName([...displayIdList, ...newIds], displayIdList.length + 1 + index);
      newIds.push(newId);
      return {
        formulation_id: trial?.id_set?.formulation_id,
        display_id: newId
      }
    });
    const copyTrialsPayload = {
      "work_order_id": workOrder?.work_order_id,
      "formulation_ids": formulationIds,
      // "stage": workOrder?.work_order_stage,
      "experiment_id": workOrder?.experiment_id[expIndex],
      "copy_config": copyTrials,
    }
    dispatch(copyTrialsRequest(copyTrialsPayload));
    // const newIds: string[] = []
    // dispatch(setEditingState(true))
    // const newDuplicateTrials = {
    //   ...duplicateTrials,
    //   ...trials.reduce((acc: any, trial: any, index: number) => {
    //     const newId: any = getTrialName([...displayIdList, ...newIds], displayIdList.length + 1 + index)
    //     newIds.push(newId)
    //     return ({
    //       ...acc,
    //       [newId]: {
    //         formulation_id: trial?.id_set?.formulation_id,
    //         processing_id: copyTrials?.processing ? trial?.processing?.[0]?.id_set?.processing_id : undefined,
    //         characterization_id: copyTrials?.characterizations ? trial?.characterizations?.[0]?.id_set?.characterization_id : undefined,
    //         properties_id: copyTrials?.properties ? trial?.properties?.[0]?.id_set?.properties_id : undefined
    //       }
    //     })
    //   }, {})
    // }
    // setDuplicateTrials(newDuplicateTrials)
    // const newDisplayIdList = [...displayIdList, ...newIds]
    // setNewTrial((state) => ({ ...state, count: newDisplayIdList.length + 1 }))
    // const trialsList = (dataset === "ingredients" ? trials : trials.flatMap((trialSet: any) => trialSet?.[dataset]))
    // setTrialSetDataList((state) => [...state, ...trialsList.map((id: any) => {
    //   return copyTrials?.[dataset]
    //     ? parameterList.reduce(
    //       (acc, parameter) => ({
    //         ...acc,
    //         [parameter]: id?.[dataset]?.[parameter]?.value || null,
    //       }),
    //       {}
    //     )
    //     : parameterList.reduce(
    //       (acc, parameter) => ({ ...acc, [parameter]: null }),
    //       {}
    //     )
    // })]
    // )
    // setDisplayIdList(newDisplayIdList)
    setTotal(state => state + selectedTrials.length)
    dispatch(setSelectedTrials([]))
    setCopTrialsVisible(false)
    setTimeout(() => {
      const element = document.querySelectorAll(".datasheet-header")
      const lastElement = element[element.length - 1]
      lastElement?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }, 100)
  }, [copyTrials, dispatch, displayIdList, duplicateTrials, initialTrials, selectedTrials,
    setCopTrialsVisible, getTrialName, expIndex, workOrder?.experiment_id, workOrder?.work_order_id, setTotal]
  )

  const addTrial = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, newTrial$) => {
    e?.stopPropagation()
    const newId = `${newTrial$.keyword} ${newTrial$.count}`
    // const isUnique = !displayIdList.find((id) => id === newId)

    const payload = {
      "experiment_id": workOrder?.experiment_id[expIndex],
      "work_order_id": workOrder?.work_order_id,
      "meta": {
        "display_id": newId
      },
      "project_id": currentProject,
      "data": parameterList.reduce((obj, element) => ({
        ...obj,
        [element]: { unit: unitData?.[element], category: datasetLabels[element]?.category, value: null }
      }), {}),
      "stage": workOrder?.work_order_stage
      // "stage": workOrder?.work_order_stage
    }
    dispatch(createTrialRequest(payload));

    // if (isUnique) {
    //   setDisplayIdList((state) => [...state, newId])
    //   setNewTrial((state) => ({ ...state, count: newTrial$.count + 1 }))
    // } else {
    //   addTrial(e, { ...newTrial$, count: newTrial$.count + 1 })
    // }
    setTimeout(() => {
      const element = document.querySelectorAll(".datasheet-header")
      const lastElement = element[element.length - 1]
      lastElement?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }, 5)
    // dispatch(setEditingState(true))
  },
    [
      dispatch,
      currentProject,
      expIndex,
      workOrder?.experiment_id,
      workOrder?.work_order_id,
      workOrder?.work_order_stage,
      datasetLabels,
      parameterList,
      unitData
    ]
  )
  const [inventoryFlag, setInventoryFlag] = inventoryStateFlag

  const setDegreeOfSuperCooling = useCallback(() => {
    setTrialSetDataList((prevState: any) => {
      const newState = [...prevState]
      newState.forEach((res: any) => {
        if (!!res?.["freezing_point"] && !!res?.["nucleation_temperature"]) {
          const degreeOfSuperCooling =
            Number(res?.["freezing_point"]) -
            Number(res?.["nucleation_temperature"])
          if (degreeOfSuperCooling > 0) {
            res["degree_of_supercooling"] = getValue(
              degreeOfSuperCooling
            )
          } else {
            message.error(
              "Degree of supercooling cannot be negative, re-calculate freezing point & nucleation temperature"
            )
          }
        }
      })
      return newState
    })
  }, [setTrialSetDataList, getValue])

  const renderDatasheet = useMemo((): Cell[][] => {

    const Dropdown = (props: any) => {
      const parameter = parameterList?.[props?.parameterIndex]
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [propertiesModalTitle, setPropertiesModalTitle] = useState("")

      const datasetLabelsSelector = useCallback(
        (state: StoreState) => state.displayNames.data?.[dataset] || {},
        [],
      );
      const datasetLabels = useSelector(datasetLabelsSelector);
      const [datalabelsList] = useState(Object.entries(datasetLabels)
        .filter(([value]) => !parameterList.includes(value)))

      const [ingOptions, setIngOptions] = useState<any>(
        [...datalabelsList.slice(0, 10).map(([value, label]: any) =>
          generateOptionBar(dataset, value, label, setPropertiesModalOpen, setIngredientDetailInventoryId, setPropertiesModalTitle, datasetLabels, getCategoryName))
        ]
      )
      const [otherOptions, setOtherOptions] = useState<any>(
        [...datalabelsList.slice(0, 10).map(([value, label]: any) =>
          generateOptionBar(dataset, value, label, setPropertiesModalOpen, setIngredientDetailInventoryId, setPropertiesModalTitle, datasetLabels, getCategoryName))
        ]
      )

      const [searchInput, setSearchInput] = useState<string>("")


      const onParameterChange = (value: any) => {
        // const value = parameter?.display_id || parameter?.name;
        value ? props.onCommit(value) : props.onRevert()
        setParameterList((state) => {
          if (value === "degree_of_supercooling" && Boolean(configs?.pcm_graphs)) {
            setDegreeOfSuperCooling()
          }
          state[props.parameterIndex] = value
          return [...state]
        })
      }


      return (
        <Row align="middle" style={{ flexWrap: "nowrap" }}>
          <Col flex="1">
            <Select
              disabled={userAccess}
              style={{ width: "100%" }}
              autoFocus
              defaultOpen
              showArrow={false}
              showSearch
              placeholder={t("workOrderDetails.enterName")}
              // options={generateOptionFields(datasetLabels).filter((res: any) => !parameterList.includes(res.value))}
              bordered={false}
              onChange={onParameterChange}
              onInputKeyDown={(e) => props.onKeyDown(e)}
              onMouseDown={(e) => e.stopPropagation()}
              value={
                linkedTrials?.find((res: any) => res?.id_set?.formulation_id === parameter)?.meta?.display_id
                || datasetLabels?.[parameter]?.name
              }
              filterOption={(inputValue: string, options: any) => {
                return getDropdownFilteredValue(inputValue, options)
              }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  {/* TMP: Hide for rehau properties tab */}
                  {
                    dataset === Dataset.properties && companyId === 'COMPPAJ1Z9H2922024' ?
                      null :
                      <>
                        <Divider style={{ margin: "4px 0" }} />
                        {/* <CustomSelectionDropdown {...props} /> */}
                        <StyledButton
                          onClick={() => setShowNewParamModal(true)}
                          type="link"
                        >
                          {t("common.newCustomParameter")}
                        </StyledButton>
                      </>
                  }
                </div>
              )}
              popupMatchSelectWidth={false}
              dropdownStyle={{ zIndex: 10 }}
              onPopupScroll={({ target }: any) => {
                if (target.scrollTop + target.offsetHeight > target.scrollHeight - 100) {
                  setIngOptions((prevState: any) => {
                    const curr = prevState.length
                    return [...prevState, ...datalabelsList.filter(([value, label]: any) => searchInput === '' ? true : getDropdownFilteredValue(searchInput, { label: labelForSearchSupport(label), value, children: null })).slice(curr, curr + 5).map(([value, label]: any) =>
                      generateOptionBar(dataset, value, label, setPropertiesModalOpen, setIngredientDetailInventoryId, setPropertiesModalTitle, datasetLabels, getCategoryName),
                    )]
                  })
                  setOtherOptions((prevState: any) => {
                    const curr = prevState.length
                    return [...prevState, ...datalabelsList.filter(([value, label]: any) => searchInput === '' ? true : getDropdownFilteredValue(searchInput, { label: labelForSearchSupport(label), value, children: null })).slice(curr, curr + 5).map(([value, label]: any) =>
                      generateOptionBar(dataset, value, label, setPropertiesModalOpen, setIngredientDetailInventoryId, setPropertiesModalTitle, datasetLabels, getCategoryName),
                    )]
                  })
                }
              }}
              onSearch={(inputValue) => {
                setSearchInput(inputValue)
                setIngOptions((prevState: any) => {
                  return [...datalabelsList.filter(([value, label]: any) => getDropdownFilteredValue(inputValue, { label: labelForSearchSupport(label), value, children: null })).slice(0, 10).map(([value, label]: any) =>
                    generateOptionBar(dataset, value, label, setPropertiesModalOpen, setIngredientDetailInventoryId, setPropertiesModalTitle, datasetLabels, getCategoryName),
                  )]
                })
                setOtherOptions((prevState: any) => {
                  return [...datalabelsList.filter(([value, label]: any) => getDropdownFilteredValue(inputValue, { label: labelForSearchSupport(label), value, children: null })).slice(0, 10).map(([value, label]: any) =>
                    generateOptionBar(dataset, value, label, setPropertiesModalOpen, setIngredientDetailInventoryId, setPropertiesModalTitle, datasetLabels, getCategoryName),
                  )]
                })
              }}
            >
              {!!linkedTrials.filter(
                (res) =>
                  !!res.access &&
                  res.experiment_id !== workOrder?.experiment_id[expIndex],
              ).length &&
                dataset === Dataset.ingredients &&
                [
                  ...new Set(
                    linkedTrials
                      .filter(
                        (res) =>
                          !!res.access &&
                          res.experiment_id !==
                          workOrder?.experiment_id[expIndex],
                      )
                      .map((res: any) => res?.stage),
                  ),
                ].map((res: any) => (
                  <Select.OptGroup
                    label={
                      !!workOrder?.stages?.find(
                        (stage: any) => stage?.identifier === res,
                      )?.name
                        ? `Stage ${workOrder?.stages?.findIndex(
                          (stage: any) => stage?.identifier === res,
                        ) + 1
                        }: ${workOrder?.stages?.find(
                          (stage: any) => stage?.identifier === res,
                        )?.name
                        }`
                        : `Stage ${workOrder?.stages?.findIndex(
                          (stage: any) => stage?.identifier === res,
                        ) + 1
                        }`
                    }
                  >
                    {linkedTrials
                      .filter(
                        (trial) =>
                          trial?.stage === res &&
                          !!trial?.access &&
                          trial.experiment_id !==
                          workOrder?.experiment_id[expIndex] &&
                          !parameterList.includes(
                            trial.formulation_id,
                          ),
                      )
                      .map((trial) => (
                        <Select.Option value={trial?.formulation_id}>
                          {trial?.formulation_display_id}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                ))}
              {dataset === Dataset.ingredients && (
                <Select.OptGroup label={t("common.ingredients")}>
                  {ingOptions}
                </Select.OptGroup>
              )}
              {dataset !== Dataset.ingredients && otherOptions}
            </Select>
          </Col >
        </Row >
      );
    };

    const EditableHeader = memo(({
      formulationId,
      trialSetDataIndex,
      userAccess
    }: {
      formulationId: string
      trialSetDataIndex: number
      userAccess: boolean
    }) => {
      const dispatch = useDispatch()
      const [t] = useTranslate()
      const updateExperimentsStatus = useSelector((state: StoreState) => state.workOrderDetails.updateExperimentsStatus)

      const selectedTrials = useSelector(
        (state: StoreState) => state.workOrders.selectedTrials || []
      )
      const onSelectedTrialsChange = (e: any, trialSetDataIndex: number) => {
        if (e.target.checked) {
          dispatch(
            setSelectedTrials([
              ...new Set([...selectedTrials, trialSetDataIndex]),
            ])
          )
        } else {
          dispatch(
            setSelectedTrials(
              selectedTrials.filter((res: any) => res !== trialSetDataIndex)
            )
          )
        }
      }
      return (
        <Row style={{ padding: 10, flexWrap: "nowrap" }}>
          <Col>
            <Checkbox
              checked={selectedTrials.includes(trialSetDataIndex)}
              onChange={(e) => onSelectedTrialsChange(e, trialSetDataIndex)}
            />
          </Col>
          <Col
            flex={1}
            style={{
              maxWidth: 280,
              marginLeft: 16,
              marginBottom: 15,
              textOverflow: "ellipsis",
            }}
          >
            <EditableField
              type="DISPLAY_ID"
              formulationId={
                initialTrialSetList[trialSetDataIndex]?.id_set?.formulation_id
              }
              displayText={displayIdList[trialSetDataIndex]}
              setValue={setDisplayIdList}
              trialSetDataIndex={trialSetDataIndex}
              displayIdList={displayIdList}
              userAccess={userAccess}
              setDuplicateTrials={setDuplicateTrials}
            />
            <br />
            <StyledButton
              disabled={!formulationId || userAccess || (updateExperimentsStatus === AsyncStates.LOADING)}
              size="small"
              icon={<InfoCircleOutlined />}
              type="link"
              onClick={() => showModal(formulationId)}
              style={{ padding: 0, margin: 0 }}
            >
              {t("workOrderDetails.details")}
            </StyledButton>
            <br />
          </Col>
        </Row>
      )
    })

    const editableFooter = (
      trialSetData: object,
      trialSetDataIndex: number,
      expIndex: any
    ) => {
      let prefixText, displayText
      let bachSize = batchSizeList?.[trialSetDataIndex]?.value_grams
      if (Object.values(unitData || {})[0] === "wt%") {
        prefixText = Object.values(trialSetData || {}).reduce(
          (sum, val) => (isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum), Big(0)
        ).toNumber()
        if (Boolean(configs?.nestle_configs)) {
          displayText = linkedTrials?.filter((res: any) => !!res?.access)?.[trialSetDataIndex]?.ingredients?.["substrate"]?.value ??
            linkedTrials?.filter((res: any) => !!res?.access)?.[0]?.ingredients?.["substrate"]?.value
            ??
            Object.values(trialSetData || {}).reduce((sum, val) => (val && isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum), Big(0))
              .div(100).mul(bachSize || 100)
        } else {
          displayText = bachSize || 100
        }
      } else {
        prefixText = 100
        displayText = bachSize || Object.values(trialSetData || {}).reduce((sum, val) => (isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum), Big(0))
      }


      return (
        <Row style={{ padding: 10, flexWrap: "nowrap" }}>
          <Col
            flex={1}
            style={{ maxWidth: 280, marginLeft: 16, textOverflow: "ellipsis" }}
          >
            {Boolean(Number(prefixText)) ? (
              <EditableField
                type="BATCH_SIZE"
                prefix={`(${getValue(prefixText, 3)}%)`}
                suffix={batchSizeList[trialSetDataIndex]?.pref_unit}
                formulationId={initialTrialSetList[trialSetDataIndex]?.id_set?.formulation_id}
                displayText={displayText}
                setValue={setBatchSizeList}
                trialSetDataIndex={trialSetDataIndex}
                extraValue={prefixText}
                displayIdList={displayIdList}
                userAccess={userAccess}
                setDuplicateTrials={setDuplicateTrials}
                expIndex={expIndex}
              />
            ) : (
              "not set"
            )}
          </Col>
        </Row>
      )
    }

    const calculateCost = (costDetail: any, selectedUnit: any, value: any) => {
      if (costDetail?.unit === selectedUnit) {
        return (value * costDetail?.amount) / costDetail?.quantity
      }
      const costConversionString = `${selectedUnit}_to_${costDetail?.unit}`
      const formula = unitList.find((res: any) => res.name === selectedUnit)?.conversion_metric?.[costConversionString]

      if (formula) {
        const convertedValue = apply(formula, [value])
        return (convertedValue / costDetail?.quantity) * costDetail?.amount
      } else {
        return null
      }
    }

    const displayCostingFooterInfo = () => {
      const filteredParameterList: string[] = parameterList.map((parameter) => {
        const costingDetails = displayNames.ingredients?.[parameter]
        if (costingDetails?.costing && costingDetails?.costing?.amount !== null) {
          return null
        }
        return linkedTrials.find((trial) => trial.access && trial.formulation_id === parameter)?.formulation_display_id ?? displayNames.ingredients?.[parameter]?.name ?? parameter
      }).filter((ingredient) => ingredient)

      return (
        <span style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <span>Costing ({currency?.currency_code})</span>
          {
            !!filteredParameterList.length &&
            <Tooltip title={`To view total costing, please head to the 'Inventory' and update the cost information of: ${filteredParameterList.join(", ")}`}>
              <InfoCircleOutlined />
            </Tooltip>
          }
        </span>
      )
    }

    const findMetaDataMissingFor = (filteredIngs: any[]) => {
      return filteredIngs.reduce(
        (acc: any, curr: any) => {
          if (!!curr.meta?.length) {
            const molecularWt = curr.meta.find(
              (metaItem: any) => metaItem.identifier === "molecular_wt"
            );
            const purityExist = curr.meta.find(
              (metaItem: any) => metaItem.identifier === "purity"
            );
            return {
              ...acc,
              purity: [
                ...acc.purity,
                !!Object.keys(purityExist || {}).length
                  ? null
                  : curr.identifier,
              ]
                .filter((res) => res)
                ?.map((ing) => displayNames.ingredients?.[ing]?.name ?? ing),
              molecular_wt: isValidNumber(molecularWt?.value)
                ? acc.molecular_wt
                : [
                  ...acc.molecular_wt,
                  displayNames.ingredients?.[curr.identifier]?.name,
                ],
            };
          } else {
            return {
              ...acc,
              purity: [
                ...acc.purity,
                displayNames.ingredients?.[curr.identifier]?.name ??
                curr.identifier,
              ],
              molecular_wt: [
                ...acc.molecular_wt,
                displayNames.ingredients?.[curr.identifier]?.name ??
                curr.identifier,
              ].filter((res) => res),
            };
          }
        },
        { molecular_wt: [], purity: [] }
      );
    };

    const generateMissingMetaDataErrorMessage = (metaDataMissingFor: {
      [key: string]: string[];
    }) => {
      return (
        <>
          {!!metaDataMissingFor?.["molecular_wt"]?.length && (
            <>
              <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more", }}>
                <strong>Molecular Weight</strong> is missing for{" "}
                {metaDataMissingFor?.["molecular_wt"].join(", ")},
              </Paragraph>
            </>
          )}
          {!!metaDataMissingFor?.["purity"]?.length && (
            <>
              <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more", }}>
                <strong>Purity</strong> is missing for {metaDataMissingFor?.["purity"].join(", ")}.
              </Paragraph>
            </>
          )}
        </>
      );
    };

    const updateExperiments = (from: string, to_unit: string, changedParameter?: string | undefined) => {
      const payload = {
        exp_data: trialSetDataList.map(
          (trialSetData, trialSetDataIndex) => ({
            ...((dataset === Dataset.ingredients && !workOrder?.is_multiunit) && { to_unit: to_unit }),
            input_data: {
              ...parameterList.reduce(
                (acc, parameter) => ({
                  ...acc,
                  [parameter]: {
                    ...(((dataset !== Dataset.ingredients || workOrder?.is_multiunit) && parameter === changedParameter) && { to_unit: to_unit }),
                    mol_value: unitConvertedData?.[trialSetDataIndex]?.input_data?.[parameter]?.mol_value ?? initialTrialSetList?.[trialSetDataIndex]?.[dataset]?.[parameter]?.mol_value ?? null,
                    value: trialSetData[parameter],
                    unit: unitData[parameter],
                    category: datasetLabels[parameter]?.category,
                    ...(linkedTrials.some(
                      (trial) => trial?.formulation_id === parameter
                    )
                      ? { type: "trials" }
                      : {}),
                  },
                }),
                {}
              ),
            },
            ...(initialTrialSetList[trialSetDataIndex]?.id_set || {}),
            input_meta: {
              base_categories: unitConvertedData[trialSetDataIndex]?.input_meta?.base_categories ||
                initialTrialSetList?.[trialSetDataIndex]?.meta?.base_categories ||
                [],
              display_id: displayIdList[trialSetDataIndex],
              ...(dataset === Dataset.ingredients
                ? {
                  batch_size: {
                    value_grams: !["wt%", "phr", "mol%", "mol"].includes(from) ? Object.values(trialSetDataList[trialSetDataIndex] || {}).reduce((acc: any, curr: any) => {
                      return isValidNumber(convertValue(curr)) ? convertValue(curr) + acc : acc
                    }, 0) : batchSizeList?.[trialSetDataIndex]?.value_grams || 100,
                    pref_unit: !["wt%", "phr", "mol%", "mol"].includes(from) ? from : batchSizeList?.[trialSetDataIndex]?.pref_unit ?? "g",
                  },
                }
                : {}),
              ...(duplicateTrials[displayIdList[trialSetDataIndex]]
                ? {
                  duplicate_keys: {
                    ...duplicateTrials[displayIdList[trialSetDataIndex]],
                  },
                }
                : {}),
              comment: commentsList[trialSetDataIndex] || "",
            },
          })
        ),
        type: dataset
      }
      dispatch(updateExperimentUnitRequest(payload))
    }

    const unitSelect = (parameter: string) => {
      const unit = unitData[parameter];
      const options = getUnitOptions(unit);
      const onChange = (value: string) => {
        if (dataset === Dataset.ingredients) {
          if (value === "phr") {
            setShowBaseCategoryModal({
              isModalVisible: true,
              toUnit: value,
              fromUnit: unit,
              isDirectConversionPossible: true,
              base_categories: [],
              base_ingredients: []
            });
            return;
          } else if (value === "mol" || value === "mol%") {
            const filteredIngs = ingredientsInventory.filter((ingredients: any) => parameterList.includes(ingredients.identifier));
            const metaDataMissingFor = findMetaDataMissingFor(filteredIngs);
            if (!!metaDataMissingFor?.["molecular_wt"].length || !!metaDataMissingFor?.["purity"].length || !!metaDataMissingFor?.["noMolecular_wtValue"]?.length) {
              return Modal.error({
                title: 'Error',
                content: generateMissingMetaDataErrorMessage(metaDataMissingFor),
                okText: 'Ok',
                width: 800
              });
            } else {
              const prohibitedUnitForMol = ["phr"];
              if (prohibitedUnitForMol.includes(unit)) {
                setShowBaseCategoryModal({
                  isModalVisible: true,
                  toUnit: value,
                  fromUnit: unit,
                  isDirectConversionPossible: false,
                  base_categories: [],
                  base_ingredients: []
                });
                return;
              } else if (value === "mol%") {
                setShowBaseCategoryModal({
                  isModalVisible: true,
                  toUnit: value,
                  fromUnit: unit,
                  isDirectConversionPossible: true,
                  base_categories: [],
                  base_ingredients: []
                });
                return
              } else {
                updateExperiments(unit, value, parameter)
                return
              }
            }
          }
          updateExperiments(unit, value, parameter)
        } else {
          updateExperiments(unit, value, parameter)
        }
      };

      return (
        <Select
          disabled={userAccess}
          onMouseDown={(e) => e.stopPropagation()}
          onChange={onChange}
          popupMatchSelectWidth={false}
          autoFocus
          style={{ width: "100%" }}
          defaultOpen
          showSearch
          showArrow={false}
          bordered={false}
          value={unit}
          options={options}
        />
      );
    };

    return [
      [
        // header row
        { value: "", readOnly: true, disableEvents: true, width: 75 },
        { value: <span style={{ color: "#222" }}>No</span>, readOnly: true, width: 50 },
        { value: <span style={{ color: "#222" }}> {t("common.category")}</span>, readOnly: true },
        {
          value:
            <span style={{ color: "#222" }}>getDatasetTranslated(dataset, t)[0].toUpperCase() +
              getDatasetTranslated(dataset, t).slice(1)</span>,
          readOnly: true,
          component: (
            <span style={{ padding: '10px' }}>
              <span style={{ color: "#222" }}>{getDatasetTranslated(dataset, t)[0].toUpperCase() + getDatasetTranslated(dataset, t).slice(1)}</span>
              {!inventoryFlag && getDatasetTranslated(dataset, t) === 'Ingredients' &&
                <Tooltip title="Expand to view ingredient details"><PlusSquareOutlined
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => setInventoryFlag(true)} /></Tooltip>}
              {(inventoryFlag && Object.values(inventoryColState).includes(true)) && <Tooltip title="Collapse ingredient details"><MinusSquareOutlined
                style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                onClick={() => setInventoryFlag(false)} /></Tooltip>}
            </span>
          ),
          forceComponent: true,
        },
        ...(inventoryFlag && supplier ? [{
          value: <span style={{ color: "#222" }}>Supplier</span>,
          readOnly: true,
          component: (
            <span style={{ padding: '10px' }}>
              <Checkbox style={{ marginRight: '0.5rem' }} />
              <span >Supplier</span>
            </span>
          ),
          forceComponent: false,
        }] : []),
        ...(inventoryFlag && batch_number ? [{
          value: <span style={{ color: "#222" }}>Lot No</span>,
          readOnly: true,
          component: (
            <span style={{ padding: '10px' }}>
              <Checkbox style={{ marginRight: '0.5rem' }} />
              <span>Batch No</span>
            </span>
          ),
          forceComponent: false,
        }] : []),
        ...(inventoryFlag && sub_category ? [{
          value: <span style={{ color: "#222" }}>Sub-Category</span>,
          readOnly: true,
          component: (
            <span style={{ padding: '10px' }}>
              <Checkbox style={{ marginRight: '0.5rem' }} />
              <span>Sub-Category</span>
            </span>
          ),
          forceComponent: false,
        }] : []),
        ...(batchMode ? [] : [{
          value: <div style={{ display: 'flex', flexDirection: 'column' }}><span style={{ color: "#222" }}>{t("common.unit")}</span>
            <span style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => setShowCard(true)}>{t('units.addUnit')}</span></div>, readOnly: true,
        }]),
        ...trialSetDataList.map((trialSetData, trialSetDataIndex) => ({
          value: displayIdList[trialSetDataIndex] || "",
          className: "datasheet-header",
          component: (
            <EditableHeader
              formulationId={
                initialTrialSetList[trialSetDataIndex]?.id_set?.formulation_id
              }
              trialSetDataIndex={trialSetDataIndex}
              userAccess={userAccess}
            />
          ),
          forceComponent: true,
          readOnly: true,
        })),
      ],
    ]
      .concat(
        // data rows
        parameterList.map((parameter, parameterIndex) => [
          {
            value: "",
            component: <RemoveBtn parameterIndex={parameterIndex} removeParameter={removeParameter} />,
            forceComponent: true,
            disableEvents: true,
            width: 75
          },
          { value: parameterIndex + 1, readOnly: true, width: 50 },
          {
            value: getCategoryName(parameter),
            disableEvents: true,
          }, //category labels column
          {
            meta: {
              experiment_id: workOrder?.experiment_id[expIndex],
              parameter_id: parameterList[parameterIndex],
              // workOrder.work_order_stage === "formulation_stage"
              //   ? initialTrialSetList[trialSetDataIndex]?.id_set
              //     ?.formulation_id
              //   : workOrder.work_order_stage === "processing_stage"
              //     ? initialTrialSetList[trialSetDataIndex]?.id_set
              //       ?.processing_id
              //     : workOrder.work_order_stage === "characterization_stage"
              //       ? initialTrialSetList[trialSetDataIndex]?.id_set
              //         ?.characterization_id
              //       : initialTrialSetList[trialSetDataIndex]?.id_set
              //         ?.properties_id,
              parameter_type:
                workOrder.work_order_stage === "formulation_stage"
                  ? "ingredients"
                  : workOrder.work_order_stage === "processing_stage"
                    ? "processing"
                    : workOrder.work_order_stage === "characterization_stage"
                      ? "characterization"
                      : "properties",
              parameter_identifier: parameter,
            },
            value: datasetLabels?.[parameter]?.name,
            forceComponent: true,
            dataEditor: (props: any) => (
              <Dropdown {...props} parameterIndex={parameterIndex} />
            ),
            valueViewer: () =>
              linkedTrials
                .map((trial) => trial.formulation_id)
                .includes(parameter) ? (
                linkedTrials?.find(
                  (trial) => trial.formulation_id === parameter
                ).access ? (
                  <Space className="gap-0">
                    <Tag icon={<LinkOutlined />}>
                      <span style={{ textWrap: 'balance' as any, wordBreak: 'break-word' }}> {linkedTrials.find(
                        (trial) => trial.formulation_id === parameter
                      )?.formulation_display_id || parameter}</span>
                    </Tag>
                    <StyledButton
                      shape="round"
                      onClick={() => showLinkedModal(parameter)}
                      style={{ margin: 5 }}
                      size="small"
                      icon={<ArrowsAltOutlined />}
                    />
                  </Space>
                ) : (
                  <Space>
                    <Tag icon={<LinkOutlined />}>
                      {linkedTrials.find(
                        (trial) => trial.formulation_id === parameter
                      ).formulation_display_id || parameter}
                    </Tag>
                    <Tooltip title="Trial data not available. Trial may have been deleted.">
                      <InfoCircleOutlined
                        style={{ margin: 10, color: orange.primary }}
                      />
                    </Tooltip>
                  </Space>
                )
              ) : (
                <span className="value-viewer">
                  <Tooltip title={
                    !!datasetLabels?.[parameter]?.lot_no || !!datasetLabels?.[parameter]?.supplier?.trim() || !!datasetLabels?.[parameter]?.sub_category?.trim() ?
                      <>
                        {!!datasetLabels?.[parameter]?.sub_category?.trim() &&
                          <div>{"Category/Sub Category :"} {datasetLabels?.[parameter]?.sub_category}</div>
                        }
                        {!!datasetLabels?.[parameter]?.lot_no &&
                          <div>{"Lot No :"} {datasetLabels?.[parameter]?.lot_no}</div>
                        }
                        {!!datasetLabels?.[parameter]?.supplier?.trim() &&
                          <div>{"Supplier :"} {datasetLabels?.[parameter]?.supplier}</div>
                        }
                      </>
                      : String(datasetLabels[parameter]?.name ?? "")}>
                    <span style={{ wordWrap: 'break-word' }}>{datasetLabels[parameter]?.name ?? parameter ?? ""}</span>
                  </Tooltip>

                  {dataset === Dataset.ingredients && !!datasetLabels?.[parameter]?.inventory_id?.length && <Tooltip title={"View Ingredient Details"}>
                    <InfoCircleOutlined style={{ outline: "none", border: "none", margin: " 0rem 0.25rem", fontWeight: "bold" }} onClick={(e) => {
                      e.stopPropagation()
                      setPropertiesModalOpen(true)
                      setIngredientDetailInventoryId(datasetLabels[parameter]?.inventory_id ?? null)
                    }} />
                  </Tooltip>
                  }
                </span>
              ),
          }, //parameter column
          ...(inventoryFlag && supplier ? [{ value: ingredientsInventory.find((value: any) => value.identifier === parameter)?.supplier || '-', readOnly: true, width: 50 }] : []),
          ...(inventoryFlag && batch_number ? [{ value: ingredientsInventory.find((value: any) => value.identifier === parameter)?.lot_no || '-', readOnly: true, width: 50 }] : []),
          ...(inventoryFlag && sub_category ? [{ value: ingredientsInventory.find((value: any) => value.identifier === parameter)?.sub_category || '-', readOnly: true, width: 50 }] : []),
          ...(batchMode
            ? []
            : [
              {
                value: unitData[parameter],
                component: unitSelect(parameter),
                forceCommponent: true,
                disableEvents: parameter.includes("New Parameter") ? true : false,
              },
            ]), //units column
          ...trialSetDataList.map((trialSetData, trialSetDataIndex) => {
            // data columns
            let batchSizeVal: any, batchSize: any
            let batchizeGrams = batchSizeList?.[trialSetDataIndex]?.value_grams
            let batchSizeUnit: any = batchSizeList?.[trialSetDataIndex]?.pref_unit || "g"
            const parameterValue: any = trialSetData[parameter]
            if (batchMode) {
              if (Object.values(unitData || {})[0] === "wt%") {
                if (Boolean(configs?.nestle_configs)) {
                  batchSize = (
                    linkedTrials?.filter((res: any) => !!res?.access)?.[trialSetDataIndex]?.ingredients?.["substrate"]?.value ||
                    linkedTrials?.filter((res: any) => !!res?.access)?.[0]?.ingredients?.["substrate"]?.value ||
                    batchizeGrams || 100)
                    * convertValue(parameterValue ?? 0) / 100
                  batchSizeVal = () => (
                    <span className="value-viewer">
                      {`(${parameterValue}%) ${getValue(batchSize, 3)} ${batchSizeUnit}`}
                    </span>
                  )
                } else {
                  const total_perc = Object.values(trialSetData || {}).reduce((sum: Big, val) => (isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum), Big(0)).toNumber() || 1
                  batchSize = Big(isValidNumber(convertValue(parameterValue)) ? convertValue(parameterValue, 3, true) : 0).div(Number(total_perc) || 1).mul(batchizeGrams || 100).toNumber()
                  batchSizeVal = () => (
                    <span className="value-viewer">
                      {`(${isValidNumber(parameterValue) ? parameterValue : ""}%) ${getValue(batchSize, 3)} ${batchSizeUnit}`}
                    </span>
                  )
                }
              } else {
                if (Boolean(configs?.nestle_configs)) {
                  batchSize = batchizeGrams ?? 0
                  batchSizeVal = () => (
                    <span className="value-viewer">
                      {`(${convertValue(parameterValue) * 100 / batchSize}%) ${parameterValue} ${batchSizeUnit || "g"}`}
                    </span>
                  )
                } else {
                  const total = Object.values(trialSetData || {}).reduce((sum: Big, val) => (isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum), Big(0)).toNumber() || 0
                  const percentageValue = Big(isValidNumber(convertValue(parameterValue)) ? convertValue(parameterValue, 3, true) : 0).div(Number(total) || 1).mul(100).toNumber()
                  const batchSizeTotal = batchizeGrams || total
                  batchSize = Big(convertValue(percentageValue) || 0).mul(batchSizeTotal).div(100)
                  batchSizeVal = () => (
                    <span className="value-viewer">
                      {`(${getValue(percentageValue, 2)}%) ${getValue(batchSize, 3)} ${batchSizeUnit || "g"}`}
                    </span>
                  )
                }
              }
            }

            return {
              // meta: {
              //   "EXP": initialTrialSetList,
              //   "experiment_id": initialTrialSetList[trialSetDataIndex]?.experiment_id ?? initialTrialSetList[trialSetDataIndex]?.id_set?.experiment_id,
              //   "ingredient_id": parameter,
              //   "formulation_id": initialTrialSetList[trialSetDataIndex]?.id_set?.formulation_id,
              //   "processing_id": workOrder.work_order_stage === 'processing_stage' ? initialTrialSetList[trialSetDataIndex]?.id_set?.processing_id: null,
              //   "characterization_id": workOrder.work_order_stage === 'characterization_stage' ? initialTrialSetList[trialSetDataIndex]?.id_set?.characterization_id: null,
              //   "properties_id": workOrder.work_order_stage === 'properties_stage' ? initialTrialSetList[trialSetDataIndex]?.id_set?.properties_id: null,
              // },
              meta: {
                experiment_id: workOrder?.experiment_id[expIndex],
                parameter_id: initialTrialSetList[trialSetDataIndex]?.id_set?.formulation_id,
                parameter_type: dataset,
                parameter_identifier: parameter,
              },
              value: parameterValue,
              className: trialSetDataIndex === highlightIndex ? "graph-clicked" : "",
              disableEvents: userAccess,
              readOnly: batchMode ? true : false,
              ...(batchMode ? { valueViewer: batchSizeVal } : {}),
            };
            // }
          }),
        ]) as any
      )
      .concat(
        showTotals //footer row
          ? batchMode
            ? [
              [
                // footer batch size row
                { value: "", readOnly: true, disableEvents: true, width: 75 },
                { value: "", readOnly: true, width: 50 },
                { value: Boolean(configs?.nestle_configs) && expIndex >= 1 ? "Substrate" : t("common.BatchSizes"), readOnly: true },
                ...(inventoryFlag && supplier ? [{ value: "", readOnly: true }] : []),
                ...(inventoryFlag && batch_number ? [{ value: "", readOnly: true }] : []),
                ...(inventoryFlag && sub_category ? [{ value: "", readOnly: true }] : []),
                { value: "", readOnly: true },
                ...trialSetDataList.map(
                  (trialSetData, trialSetDataIndex) => ({
                    component: editableFooter(
                      trialSetData,
                      trialSetDataIndex,
                      expIndex
                    ),
                    forceComponent: true,
                    readOnly: true,
                  })
                ),
              ],
            ]
            : ([
              [
                // footer totals row
                { value: "", readOnly: true, disableEvents: true, width: 75 },
                { value: t("formulations.total"), readOnly: true },
                { value: "", readOnly: true, width: 50 },
                {
                  value: null,
                  readOnly: true,
                  component: <Text></Text>,
                  forceComponent: true,
                  disableEvents: true,
                },
                ...(inventoryFlag && supplier ? [{ value: "", readOnly: true }] : []),
                ...(inventoryFlag && batch_number ? [{ value: "", readOnly: true }] : []),
                ...(inventoryFlag && sub_category ? [{ value: "", readOnly: true }] : []),
                {
                  value: null,
                  readOnly: true,
                  component: <Text></Text>,
                  forceComponent: true,
                  disableEvents: true,
                },
                ...trialSetDataList.map(
                  (trialSetData) => ({
                    value: null,
                    forceComponent: true,
                    disableEvents: true,
                    readOnly: true,
                    className: "total-cell",
                    component: <Row justify={"end"} style={{ paddingRight: 10 }}>
                      <Text
                        style={batchMode
                          ? { color: antdTheme.colorPrimary }
                          : showTotals
                            ? Object.values(trialSetData || {})
                              .reduce((sum, val: any) => isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum, Big(0)
                              )
                              .toNumber() > 100 && Object.values(unitData || {}).some((res: any) => res === "wt%")
                              ? { color: "#f5222d" }
                              : { color: antdTheme.colorPrimary }
                            : { color: antdTheme.colorPrimary, }}
                      >
                        {getValue(
                          Object.values(trialSetData || {})
                            .reduce((sum, val: any) => isValidNumber(convertValue(val)) ? sum.plus(convertValue(val)) : sum, Big(0)), 3
                        )}</Text>
                    </Row>,
                  })
                ),
              ],
            ] as any)
          : [],
        (dataset === "ingredients" && showCosting ?
          [
            [
              { value: "", readOnly: true, disableEvents: true, width: 75 },
              { value: "", readOnly: true, width: 50 },
              {
                value: `Costing (${currency?.currency_code})`,
                component: displayCostingFooterInfo(),
                forceComponent: true,
                readOnly: true,
              },
              { value: "", readOnly: true, className: batchMode ? styles.hide__unit__costing : "" },
              ...(inventoryFlag && supplier ? [{ value: "", readOnly: true }] : []),
              ...(inventoryFlag && batch_number ? [{ value: "", readOnly: true }] : []),
              ...(inventoryFlag && sub_category ? [{ value: "", readOnly: true }] : []),
              { value: "", readOnly: true },
              ...trialSetDataList.map(
                (trialSetData, trialSetDataIndex) => {
                  const cost: any = Object.entries(trialSetData || {}).reduce(
                    (acc: any, [key, parametervalue]: any) => {
                      const costingDetails = displayNames.ingredients?.[key]
                      if (costingDetails?.costing?.amount === undefined || costingDetails?.costing?.amount === null) {
                        return null
                      }
                      if (batchMode) {
                        if (costingDetails?.costing && parametervalue === null) {
                          return acc?.plus(Big(0))
                        } else {
                          let batchValue
                          if (Object.values(unitData || {})[0] === "wt%") {
                            batchValue = Big(Number(trialSetData[key]) || 0)
                              .div(100)
                              .mul(batchSizeList?.[trialSetDataIndex]?.value_grams || 100)

                          } else {
                            const total = Object.values(trialSetData || {}).reduce(
                              (sum, val: any) =>
                                isValidNumber(val) ? sum.plus(val) : sum,
                              Big(0)
                            )
                            const percentageValue = Big(
                              Number(trialSetData[key]) || 0
                            )
                              .div(Number(total) || 1)
                              .mul(100)
                            const batchSizeTotal = batchSizeList?.[trialSetDataIndex]?.value_grams || total
                            batchValue = Big(Number(percentageValue) || 0).mul(batchSizeTotal).div(100)
                          }
                          batchValue = getValue(batchValue, 3)
                          if (costingDetails?.costing && !isNaN(Number(batchValue))) {
                            const resultCost = calculateCost(costingDetails?.costing, batchSizeList?.[trialSetDataIndex]?.pref_unit || "g", Number(batchValue))
                            if (resultCost !== null) {
                              return acc?.plus(resultCost)
                            }
                            return resultCost
                          } else {
                            return null
                          }
                        }
                      } else {
                        if (costingDetails?.costing && parametervalue === null) {
                          return acc?.plus(Big(0))
                        }
                        if (costingDetails?.costing && !isNaN(convertValue(parametervalue)) && parametervalue !== null) {
                          const resultCost = calculateCost(costingDetails?.costing, unitData[key], convertValue(parametervalue))
                          if (resultCost !== null) {
                            return acc?.plus(resultCost)
                          }
                          return resultCost
                        } else {
                          return null
                        }
                      }
                    }, Big(0))
                  return {
                    value: getValue(cost, 3) ?? "-",
                    readOnly: true
                  }
                }
              ),
            ],
          ] : [])
      )
  }, [
    dispatch,
    datasetLabels,
    dataset,
    showModal,
    showLinkedModal,
    trialSetDataList,
    removeParameter,
    unitData,
    parameterList,
    batchMode,
    initialTrialSetList,
    displayIdList,
    showTotals,
    batchSizeList,
    linkedTrials,
    highlightIndex,
    setDisplayIdList,
    t,
    setShowNewParamModal,
    getUnitOptions,
    unitList,
    userAccess,
    styles.hide__unit__costing,
    displayNames,
    showCosting,
    inventoryFlag,
    supplier,
    batch_number,
    sub_category,
    setInventoryFlag,
    inventoryColState,
    ingredientsInventory,
    expIndex,
    workOrder?.experiment_id,
    workOrder?.stages,
    currency?.currency_code,
    configs,
    setDegreeOfSuperCooling,
    setParameterList,
    commentsList,
    duplicateTrials,
    setBatchSizeList,
    setShowBaseCategoryModal,
    unitConvertedData,
    setShowCard,
    workOrder.work_order_stage,
    setIngredientDetailInventoryId,
    setPropertiesModalOpen,
    getValue,
    convertValue,
    getCategoryName,
    workOrder?.is_multiunit,
    companyId
  ])

  const [datasheet, setDatasheet] = useState<Cell[][]>(() => renderDatasheet)

  useEffect(() => {
    setDatasheet(renderDatasheet)
  }, [renderDatasheet])

  //data cell changes
  // const saveCell = useCallback((changes: any) => {
  // 	const getFormulationId = (trial: any) => {
  // 		return trial.id_set[`formulation_id`]
  // 	}
  // 	const getParamId = (trial: any) => {
  // 		if (dataset === Dataset.ingredients) {
  // 			return trial.id_set[`formulation_id`]
  // 		}
  // 		if (dataset === Dataset.characterizations) {
  // 			return trial[dataset][0]?.id_set[`characterization_id`]
  // 		}
  // 		return trial[dataset][0]?.id_set[`${dataset}_id`]
  // 	}
  // 	const param_id = getParamId(initialTrials[changes[0].col - 5])
  // 	const param = parameterList[changes[0].row - 1]
  // 	const value = {
  // 		value: changes[0].value,
  // 		unit: unitData[param],
  // 		category: datasetLabels[param]?.category
  // 	}
  // 	const formulation_id = getFormulationId(initialTrials[changes[0].col - 5])
  // 	const { experiment_id } = workOrder
  // 	const autoSavePayload = {
  // 		formulation_id,
  // 		experiment_id: experiment_id[expIndex],
  // 		param_id,
  // 		param,
  // 		value,
  // 		param_type: dataset
  // 	}
  // 	dispatch(autoSaveExperimentRequest(autoSavePayload))
  // }, [initialTrials, parameterList, datasetLabels, unitData, workOrder, expIndex, dataset, dispatch])

  // const [datalabelsList] = useState(Object.entries(datasetLabels)
  //       .filter(([value]) => !parameterList.includes(value)))

  const onCellChanges = useCallback(
    (changes: { cell: Cell; row: number; col: number; value: string }[]) => {
      let columnsBeforeDataColumns = batchMode ? 4 : 5
      if (inventoryFlag) columnsBeforeDataColumns = columnsBeforeDataColumns + Object.values(inventoryColState || {}).filter((value) => value).length
      // if (changes.length === 1 && changes[0].col >= columnsBeforeDataColumns && changes[0].col < initialTrials.length + columnsBeforeDataColumns) {
      // 	saveCell(changes)
      // } else {
      // }
      if (changes[0].col === 3) {
        const meta = changes[0].cell.meta;
        // category: datasetLabels[parameter]?.category,
        //       unit: unitData[parameter],
        const newParamValue = changes[0].value;
        const oldParamValue = meta?.parameter_id;
        const commonPayload = {
          "experiment_id": meta?.experiment_id,
          "work_order_id": workOrder.work_order_id,
          "parameter_type": dataset,
        };

        if (oldParamValue?.includes(t("workOrderDetails.newParameter"))) {
          const addParamPayload = {
            ...commonPayload,
            identifier: newParamValue,
            category: datasetLabels[newParamValue]?.category,
            unit:
              dataset === Dataset.ingredients
                ? parameterList.some((res: any) =>
                  Object.keys(unitData).includes(res)
                )
                  ? initialParameterList.units?.[newParamValue] ||
                  Object.values(unitData || {})?.[0] ||
                  datasetLabels?.[newParamValue]?.unit?.[0]
                  : initialParameterList.units?.[newParamValue] ||
                  datasetLabels?.[newParamValue]?.unit?.[0]
                : initialParameterList.units?.[newParamValue] ||
                unitData[newParamValue] ||
                datasetLabels?.[newParamValue]?.unit?.[0],
            type: linkedTrials?.find(
              (res: any) =>
                res?.id_set?.formulation_id === newParamValue ||
                res?.formulation_id === newParamValue
            )?.meta?.display_id
              ? "trial"
              : undefined,
          };
          if (newParamValue !== undefined && newParamValue !== '') {
            dispatch(addParamRequest(addParamPayload));
          }
        } else {
          if (oldParamValue !== undefined) { // Scenario: add param from FE, open dropdown and don't add value, remove that param & click outside handling
            const updateParamPayload = {
              ...commonPayload,
              old_identifier: oldParamValue,
              new_identifier: newParamValue
            }
            dispatch(updateParamRequest(updateParamPayload));
          }
        }
      }
      const headerCount = 1
      const dataChanges = changes
        ? changes.filter(
          (change) =>
            change.col >= columnsBeforeDataColumns &&
            change.row >= headerCount
        )
        : []

      if (dataChanges.length) {
        const unknownParameters = parameterList.filter((parameter) =>
          parameter.includes(t("workOrderDetails.newParameter"))
        );
        if (unknownParameters.length) {
          notification.warning({
            message: t("common.unknownParameters"),
            description: `${unknownParameters.join(", ")} ${t(
              "common.areUnknown"
            )}.`,
          });
          dispatch(setEditingState(false));
          return;
        }
        dispatch(cellChangesAdd({ dataChanges }));
        // dispatch(setEditingState(false));
        setTrialSetDataList((state) => {
          dataChanges.forEach(({ cell, row, col, value }) => {
            if (cell?.className !== "total-cell") {
              const x = col - columnsBeforeDataColumns // offsetting coordinates
              const y = row - headerCount; // offsetting coordinates
              const parameter = parameterList[y];
              const previousValue = state[x]?.[parameter] || "";
              state[x][parameter] = getValue(value);
              if (
                !!Boolean(configs?.pcm_graphs) &&
                parameterList.includes("degree_of_supercooling") &&
                !!state[x]?.["freezing_point"] &&
                !!state[x]?.["nucleation_temperature"]
              ) {
                const degreeOfSuperCooling =
                  Number(state[x]?.["freezing_point"]) -
                  Number(state[x]?.["nucleation_temperature"]);
                if (degreeOfSuperCooling > 0) {
                  state[x]["degree_of_supercooling"] = getValue(degreeOfSuperCooling);
                } else {
                  state[x][parameter] = previousValue;
                  message.error(
                    "Degree of supercooling cannot be negative, re-calculate freezing point & nucleation temperature"
                  );
                }
              }
            }
          });
          return [...state];
        });
        if (
          workOrder.work_order_stage === "properties_stage" &&
          Boolean(configs?.pcm_graphs)
        ) {
          setHighlightIndex((prevState: number) =>
            prevState < 0 ? prevState : -1
          );
        }
      } else {
        if (changes[0].col !== 3) {
          dispatch(setEditingState(true));
        }
      }
    },
    [batchMode, dispatch, setTrialSetDataList, inventoryColState, workOrder.work_order_stage, inventoryFlag, parameterList, configs?.pcm_graphs, setHighlightIndex, t, dataset, datasetLabels, initialParameterList?.units, linkedTrials, unitData, workOrder?.work_order_id, getValue]
  )

  const save = useCallback(() => {
    const { material, application, work_order_id, experiment_id, work_order_parent_stage } = workOrder
    const unknownParameters = parameterList.filter((parameter) =>
      parameter.includes(t("workOrderDetails.newParameter"))
    );
    if (unknownParameters.length) {
      notification.warning({
        message: t('common.unknownParameters'),
        description: `${unknownParameters.join(", ")} ${t('common.areUnknown')}.`,
      })
      return
    }
    const toUnit = Object.values(unitData || {})?.[0]
    const exp_data = trialSetDataList.map(
      (trialSetData, trialSetDataIndex) => ({
        to_unit: toUnit,
        input_data: {
          ...parameterList.reduce(
            (acc, parameter) => ({
              ...acc,
              [parameter]: {
                mol_value: unitConvertedData?.[trialSetDataIndex]?.input_data?.[parameter]?.mol_value ?? initialTrialSetList?.[trialSetDataIndex]?.[dataset]?.[parameter]?.mol_value ?? null,
                value: trialSetData[parameter],
                unit: unitData[parameter],
                category: datasetLabels[parameter]?.category,
                ...(linkedTrials.some(
                  (trial) => trial?.formulation_id === parameter
                )
                  ? { type: "trials" }
                  : {}),
              },
            }),
            {}
          ),
        },
        ...(initialTrialSetList[trialSetDataIndex]?.id_set || {}),
        input_meta: {
          base_categories: unitConvertedData?.[trialSetDataIndex]?.input_meta?.base_categories || initialTrialSetList?.[trialSetDataIndex]?.meta?.base_categories || [],
          display_id: displayIdList[trialSetDataIndex],
          ...(dataset === Dataset.ingredients
            ? {
              batch_size: {
                value_grams:
                  !totalTrialValueForUnit.includes(toUnit) ? Object.values(trialSetDataList[trialSetDataIndex] || {}).reduce((acc: any, curr: any) => {
                    return isValidNumber(convertValue(curr)) ? convertValue(curr) + acc : acc
                  }, 0) : batchSizeList?.[trialSetDataIndex]?.value_grams || 100,
                pref_unit:
                  !totalTrialValueForUnit.includes(toUnit) ? toUnit : batchSizeList?.[trialSetDataIndex]?.pref_unit ?? "g",
              }
            }
            : {}),
          ...(duplicateTrials[displayIdList[trialSetDataIndex]] ? {
            duplicate_keys: { ...duplicateTrials[displayIdList[trialSetDataIndex]] },
          }
            : {}),
          comment: commentsList[trialSetDataIndex] || "",
        },
      })
    )
    dispatch(
      editExperimentRequest({
        stage: work_order_parent_stage,
        material,
        application,
        work_order_id,
        exp_data,
        input_key: dataset,
        page_num: current,
        page_size: EXP_PAGE_SIZE,
        experiment_id: experiment_id[expIndex],
        ...(!!showBaseCategoryModal.base_categories?.length && {
          base_categories: showBaseCategoryModal.base_categories,
        }),
        ...(!!showBaseCategoryModal.base_ingredients?.length && {
          base_ingredients: showBaseCategoryModal.base_ingredients,
        }),
      })
    )
  }, [
    dataset,
    workOrder,
    trialSetDataList,
    parameterList,
    datasetLabels,
    unitData,
    displayIdList,
    batchSizeList,
    initialTrialSetList,
    duplicateTrials,
    commentsList,
    dispatch,
    linkedTrials,
    t,
    current,
    expIndex,
    showBaseCategoryModal.base_categories,
    showBaseCategoryModal.base_ingredients,
    unitConvertedData,
    convertValue
  ])

  return { save, addTrial, addParameter, datasheet, onCellChanges, createCopiedTrials, getParameterUnit }
}