import { useCallback, useEffect, useMemo, useState } from "react"
import {
	Space,
	Spin,
	Card,
	Table,
	Typography,
	Statistic,
	Row,
	Col,
	Modal,
	Radio,
	Tooltip,
	Select,
	Segmented,
	Tag,
} from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "../../../store/configureStore"
import { antdTheme, AsyncStates } from "src/constants"
import {
	LoadingOutlined,
	ArrowLeftOutlined,
	InfoCircleOutlined,
	CommentOutlined,
	DeploymentUnitOutlined,
} from "@ant-design/icons"
import {
	// setAnalysisTab,
	setModelVersion,
	getModelInfoClear,
	modelInfoChangeRequest,
	setCustomModelRequest,
	getModelInfoRequest,
	modelCommentListRequest,
	getCustomModelDataRequest,
	getCustomModelDataClear,
	modelCommentListClear,
} from "src/store/actions/modelAnalysis"
import { deleteModelRequest } from "src/store/actions/customML"
import { useHistory } from "react-router-dom"
import { geekblue } from "@ant-design/colors"
import { ModelComments } from "./ModelComments"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import { ExplainableAI } from "./ExplainableAI/ExplainableAI"
import { Performance } from "./Performance/Performance"
import { useModelErrorInfo } from "./useModelErrorInfo"
import { useQuery } from "src/utils/useQuery"
import { PerformancePercentage } from "./PerformancePercentage/PerformancePercentage"
import { useMemberName } from "src/utils/useMemberName"
import { DroppedPropertyWarning } from "../common/DroppedPropertyWarning"
import { useValue } from "src/utils/useValue"
import { getPerformanceClear, getPerformanceRequest } from "src/store/actions/performance"
import { useModelCommentsSocket } from "src/utils/hooks/useModelCommentsSocket"

const { Title, Text, Paragraph } = Typography
const { Option } = Select

export function ModelDetails() {
	const dispatch = useDispatch()
	const { push } = useHistory()
	const [t] = useTranslate()
	const { getValue } = useValue()
	const [metrics] = useState({
		explained_variance_score: "Explained Variance Score",
		max_error: "Max Error",
		mean_absolute_error: "Mean Absolute Error",
		mean_squared_error: "Mean Squared Error",
		median_absolute_error: "Median Absolute Error",
		r2_score: t("aiEngine.modelAnalysis.r2Score"),
		mean_absolute_percentage_error: "Mean Absolute Percentage Error",
		accuracy_score: "Accuracy Score",
		f1_score: "F1 Score",
	})

	const {
		status,
		modelInfo,
		modelData,
		modelCommentListStatus,
		modelCommentList,
		modelInfoChangeStatus,
		requestInverseStatus,
		customModelTotalWoFormulationCount,
		getCustomModelDataStatus
	} = useSelector((state: StoreState) => state.modelAnalysis)

	const isMultiStageModel = useMemo(() => modelData?.is_multistage ?? false, [modelData?.is_multistage])

	const [currentSelectedStage, setCurrentSelectedStage] = useState<number>(1)


	const stagesOptions = useMemo(() => {
		return Object.keys(modelData?.stages_meta || {})?.map((stage: string, idx: number) => {
			const stage_number = Number(stage.split(" ")[1])
			const stage_name = modelData?.stages_meta?.[stage]?.name
			if (idx === 0 && stage_number !== 1) setCurrentSelectedStage(stage_number)
			return ({
				value: stage_number,
				label: stage_name,
				title: stage_name,
				disabled: false,
			})
		})
	}, [modelData?.stages_meta])

	let query = useQuery();
	const { configStatus } = useSelector(
		(state: StoreState) => state.formulate
	)
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
	const [modelUseModalVisible, setModelUseModalVisible] = useState<boolean>(
		false
	)
	const [predType, setPredType] = useState<string>("forward")
	// const [weights, setWeights] = useState<number>(25)
	const [modelComments, setModelComments] = useState<any>({ total: 0 })

	const [commentsDrawerVisible, setCommentsDrawerVisible] = useState<boolean>(false)
	const [version, setVersion] = useState(Number(query.get('version')))
	const [selectedMetric, setSelectedMetric] = useState("mean_absolute_percentage_error")
	const displayNames = useSelector((state: StoreState) => state.displayNames.data)
	const teams = useSelector((state: StoreState) => state.teams.data) ?? []
	const { getName } = useMemberName()

	useEffect(() => {
		setVersion(Number(query.get('version')))
	}, [query])


	useEffect(() => {
		dispatch(modelCommentListRequest({ model_version: version }))
		return () => {
			dispatch(modelCommentListClear())
		}
	}, [dispatch, version])

	useEffect(() => {
		dispatch(getModelInfoRequest({ version }))
		dispatch(getCustomModelDataRequest({ version, formulation_ids: [] }));
		dispatch(
			getPerformanceRequest({
				version,
				model_types: ["string"],
				formulation_ids: [],
			}),
		);
		return () => {
			dispatch(getModelInfoClear());
			dispatch(getCustomModelDataClear())
			dispatch(getPerformanceClear());
		};
	}, [dispatch, query, version]);

	useEffect(() => {
	}, [dispatch, version, currentSelectedStage, modelData?.stages_meta]);

	useEffect(() => {
		if (modelCommentListStatus === AsyncStates.SUCCESS) {
			setModelComments(modelCommentList)
		}
	}, [modelCommentListStatus, modelCommentList])

	useEffect(() => { window.scrollTo(0, 0) }, [])


	useEffect(() => {
		if (status === AsyncStates.ERROR) {
			push("/ai-engine/new_model/model_analysis")
		}
	}, [status, push])

	useEffect(() => {
		return () => {
			dispatch(getModelInfoClear())
		}
	}, [dispatch])

	const ingredientColumns = [
		{
			dataIndex: "ingredients",
			title: t("common.ingredients"),
			key: "ingredients",
			align: "center",
		},
		{
			dataIndex: "unit",
			title: t("common.unitOfMeasurement"),
			key: "unit",
			align: "center",
		},
		{
			dataIndex: "category",
			title: t("common.category"),
			key: "category",
			align: "center",
		},
		{
			dataIndex: "data_points",
			title: t("aiEngine.modelAnalysis.title.dataPoints"),
			key: "data_points",
			align: "center",
		},
		{
			dataIndex: "range",
			title: t("aiEngine.featureRange"),
			key: "range",
			align: "center",
		},
	]

	const ingredientData: any = useMemo(() => {
		if (status === AsyncStates.SUCCESS) {
			return Object.entries(modelInfo?.ingredient_data || {}).map(
				([key, value]: any) => ({
					ingredients: key,
					unit: value.unit,
					category: value.category,
					data_points: value.data_points,
					range: Array.isArray(value?.range)
						? value?.range?.map((res: any) => getValue(res)).join(", ")
						: `${getValue(value?.range?.min) ?? ""} -  ${getValue(value?.range?.max) ?? ""}`,
				})
			)
		} else return []
	}, [modelInfo, status, getValue])

	const processingColumns = [
		{
			dataIndex: "processing",
			title: t("common.processing"),
			key: "processing",
			align: "center",
			render: (text: any, record: any) => {
				return (
					<Space>
						{displayNames.processing?.[text]?.name ?? text}
					</Space>
				)
			},
		},
		{
			dataIndex: "unit",
			title: t("common.unitOfMeasurement"),
			key: "unit",
			align: "center",
		},
		{
			dataIndex: "category",
			title: t("common.category"),
			key: "category",
			align: "center",
		},
		{
			dataIndex: "data_points",
			title: t("aiEngine.modelAnalysis.title.dataPoints"),
			key: "data_points",
			align: "center",
		},
		{
			dataIndex: "range",
			title: t("aiEngine.featureRange"),
			key: "range",
			align: "center",
		},
	]

	const processingData: any = useMemo(() => {
		if (status === AsyncStates.SUCCESS) {
			return Object.entries(modelInfo?.processing_data || {}).map(
				([key, value]: any) => ({
					processing: key,
					unit: value.unit,
					category: value.category,
					data_points: value.data_points,
					range: Array.isArray(value?.range)
						? value?.range?.map((res: any) => getValue(res)).join(", ")
						: `${getValue(value?.range?.min) ?? ""} -  ${getValue(value?.range?.max) ?? ""}`,
				})
			)
		} else return []
	}, [modelInfo, status, getValue])

	const { getModelErrorInfo } = useModelErrorInfo()

	const propertyColumns = [
		{
			dataIndex: "property",
			title: t("common.property"),
			key: "property",
			align: "center",
			render: (text: any, record: any) => {
				return (
					<Space>
						{displayNames.properties?.[text]?.name ?? text}
					</Space>
				)
			},
		},
		{
			dataIndex: "unit",
			title: t("common.unitOfMeasurement"),
			key: "unit",
			align: "center",
			render: (text: any, record: any) => {
				return (
					<Text style={{ maxWidth: "200px" }} ellipsis={{ tooltip: record?.unit }}>
						{record?.unit}
					</Text>
				)
			},
		},
		{
			dataIndex: "data_points",
			title: t("aiEngine.modelAnalysis.title.dataPoints"),
			key: "data_points",
			align: "center",
		},
		{
			dataIndex: "range",
			title: t("aiEngine.propertyRange"),
			key: "range",
			align: "center",
		},
		{
			dataIndex: "eval_metrics",
			title: () => (
				<Space>
					<Text>{t("aiEngine.modelAnalysis.title.evaluationMetrics")}</Text>
					<Tooltip title={"Average error value of K-fold cross Validation on training data"}>
						<InfoCircleOutlined />
					</Tooltip>
				</Space>
			),
			key: "eval_metrics",
			align: "center",
			render: (text: any, record: any) => {
				const response = (!isNaN(text) && typeof text === "number") ? getModelErrorInfo(text, selectedMetric) : null

				return (
					<Space>
						{" "}
						<Text>{text ?? "-"}</Text>
						<Tooltip
							title={response?.modelResult}
						>
							{response && <DeploymentUnitOutlined
								style={{
									color:
										response?.color
								}}
							/>}
						</Tooltip>
					</Space>
				)
			},
		},
	]

	const getMetricsValue = useCallback((data: any, type: string, otherMetricType) => {
		const value = Object.keys(data?.[type] || {}).length > 0 ? parseFloat(data?.[type]?.[selectedMetric]?.toPrecision(3)) : (selectedMetric === "mean_absolute_percentage_error" ? parseFloat(data?.[otherMetricType]) : null)
		const result = value === null ? "-" : isNaN(value) ? "-" : value
		return getValue(result, 2) ?? "-"
	}, [selectedMetric, getValue])

	const propertyData: any = useMemo(() => {
		if (status === AsyncStates.SUCCESS) {
			return Object.entries(modelInfo?.property_data || {})
				.filter(([key, value]: any) => value.property_type !== 'CLASSIFIER')
				.map(
					([key, value]: any) => ({
						property: key,
						unit: value?.unit ?? "-",
						// train_metrics: getMetricsValue(value, "all_train_metrics", "train_metrics"),
						eval_metrics: getMetricsValue(value, "all_eval_metrics", "eval_metrics"),
						data_points: value.data_points,
						range: Array.isArray(value?.range)
							? value?.range?.map((res: any) => getValue(res)).join(", ")
							: `${getValue(value?.range?.min) ?? ""} -  ${getValue(value?.range?.max) ?? ""}`,
					})
				)
		} else return []
	}, [modelInfo, status, getMetricsValue, getValue])

	const changeModelInfo = (version: any, objective: any, comments: any) => {
		const payload: any = {
			version,
			objective,
			comments,
		}
		const stages = Object.keys(modelData?.stages_meta || {})?.map((stage: string) => modelData?.stages_meta[stage]?.version)
		if (stages && stages.length > 0) {
			payload.stage_versions = stages;
		}
		dispatch(modelInfoChangeRequest(payload))
	}

	const socket = useModelCommentsSocket('model_comments', version)

	useEffect(() => {
		const onNewComment = (comment: any) => {
			setModelComments((state: any) => ({ ...state, comments_data: [...state.comments_data, comment] }))
		}
		socket?.on("new_model_comment", onNewComment)

		// const onEditComment = (comment: any) => {
		// 	setModelComments((state: any) => {
		// 		const newState = state
		// 		return newState
		// 	})
		// }
		// socket?.on("update_comment", onEditComment)

		// const onDeleteComment = (comment: any) => {
		// 	setModelComments((state: any) => ({ ...state, comments_data: state.filter((_comment: any) => _comment.comment_id !== comment.comment_id) }))
		// }
		// socket?.on("remove_model_comment", onDeleteComment)

		return () => {
			socket?.off("new_model_comment", onNewComment)
			// socket?.off("update_comment", onNewComment)
			// socket?.off("remove_model_comment", onNewComment)
			socket?.disconnect()
		}
	}, [socket])

	return (
		<Spin
			spinning={
				status === AsyncStates.LOADING ||
				configStatus === AsyncStates.LOADING ||
				requestInverseStatus === AsyncStates.LOADING
			}
			indicator={<LoadingOutlined />}
		>
			<Space direction="vertical" style={{ width: "100%" }} size="large">
				<Spin
					spinning={modelInfoChangeStatus === AsyncStates.LOADING}
					indicator={<LoadingOutlined />}
				>
					<Card bodyStyle={{ padding: 15 }} headStyle={{ padding: 15 }} >
						<Space direction="vertical" style={{ width: "100%" }}>
							<Row justify="space-between">
								<Space style={{ display: 'flex', alignItems: 'center' }} >
									<ArrowLeftOutlined
										onClick={() => {
											window.history.back()
										}}
										style={{
											fontSize: antdTheme.fontSizeHeading4,
											cursor: "pointer",
											marginRight: 10,
											outline: "none",
										}}
									/>
									<Space size={"large"}>
										<Title
											level={3}
											style={{ marginTop: 0, marginBottom: 0 }}
											editable={
												modelData?.is_custom
													? {
														tooltip: t("aiEngine.modelAnalysis.editObjective"),
														onChange: (e) => {
															if (e !== modelData.objective) {
																setVersion(modelData?.version)
																changeModelInfo(modelData?.version, e, modelData?.comments);
															}
														},
													}
													: false
											}
										>
											{modelData?.objective}
										</Title>
										{modelData?.use_metadata && <Tooltip title={t('common.metadataTooltip')}>
											<Tag color="blue">
												{t('common.withMetadata')}
											</Tag>
										</Tooltip>}
									</Space>
								</Space>
								<Space>
									{modelData?.model_display_type?.includes('custom_ai') && (
										<StyledButton
											onClick={() => setDeleteModalVisible(true)}
											danger
										>
											{t("aiEngine.DeleteModel")}
										</StyledButton>
									)}
									<StyledButton
										type="primary"
										onClick={() => setModelUseModalVisible(true)}
									>
										{t("aiEngine.useModel")}
									</StyledButton>
								</Space>
							</Row>
							<Row style={{ marginTop: 10 }}>
								<Space
									direction="vertical"
									style={{ width: "100%" }}
									size="large"
								>
									<Statistic
										title={t("common.description")}
										value={modelData?.comments}
										valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }}
										valueRender={() => (
											<Paragraph
												ellipsis={{ rows: 2, tooltip: modelData?.comments }}
												editable={
													modelData?.is_custom
														? {
															autoSize: { maxRows: 5, minRows: 3 },
															tooltip: t("common.editDescription"),
															onChange: (e) => {
																if (e !== modelData?.comments) {
																	setVersion(modelData?.version)
																	changeModelInfo(modelData?.version, modelData?.objective, e);
																}
															},
														}
														: false
												}
											>
												{modelData?.comments}
											</Paragraph>
										)}
									/>
								</Space>
							</Row>
							<Row gutter={60} justify="space-between">
								<Space>
									<Col>
										<Statistic
											title={t("common.createdBy")}
											value={getName(teams.filter((user) => modelData?.created_by === user?.user_id)?.[0]?.user_id)}
											valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }}
										/>
									</Col>
									<Col>
										<Statistic
											title={
												<Space>
													<Text type="secondary">
														{t("aiEngine.modelType")}
													</Text>
													<Tooltip
														title={`
														${t("modelAnalysis.DescribeModelCreation")}
														${t("common.customCLientModelByYou")}
													`}
													>
														<InfoCircleOutlined />
													</Tooltip>
												</Space>
											}
											value={modelData?.model_display_type
												?.split("_")
												?.map(
													(res: string) =>
														res.charAt(0).toLocaleUpperCase() + res.slice(1)
												)
												?.join(" ")?.replace("Ai", "AI")} valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }}
										/>
									</Col>
									<Col>
										<Statistic
											title={t("aiEngine.modelAnalysis.title.dateCreated")}
											value={new Date(modelData?.created_on).toLocaleString()}
											valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }}
										/>
									</Col>
								</Space>
								<Col style={{ cursor: "pointer", marginTop: 30 }}>
									<Text
										type="secondary"
										onClick={() => setCommentsDrawerVisible(true)}
									>
										<Space>
											<CommentOutlined />
											<Text>
												{modelCommentList?.total_records
													? `${modelCommentList?.total_records} ${t(
														"common.comments"
													)}`
													: t("common.comments")}
											</Text>
										</Space>
									</Text>
								</Col>
							</Row>
							{
								!!modelData?.missing_properties?.length && <DroppedPropertyWarning missingProperties={modelData?.missing_properties} />

							}
						</Space>
					</Card>
				</Spin>

				{isMultiStageModel && (
					<Segmented
						options={stagesOptions}
						value={currentSelectedStage}
						style={{ transition: "all .5s" }}
						size="middle"
						onChange={(stage: any) => {
							dispatch(getModelInfoRequest({ version, stage_version: modelData?.stages_meta?.[`Stage ${stage}`]?.version }))
							dispatch(getCustomModelDataRequest({ version, formulation_ids: [], stage_version: modelData?.stages_meta?.[`Stage ${stage}`]?.version }));
							dispatch(
								getPerformanceRequest({
									version,
									model_types: ["string"],
									formulation_ids: [],
									stage_version: modelData?.stages_meta?.[`Stage ${stage}`]?.version
								}),
							);
							setCurrentSelectedStage(stage)
						}}
						className="inverse-stage-segmented"
					/>
				)}

				<Card
					title={
						<Row justify="space-between">
							<Space direction="vertical" size={'small'} style={{ gap: 0, padding: '8px 0px' }}>
								<Title level={4}>{t("common.ingredients")}</Title>
								<Text type="secondary">
									{t("modelDetails.usedIngredientsInformation")}
								</Text>
							</Space>
							{getCustomModelDataStatus === AsyncStates.SUCCESS &&
								<Text strong style={{ color: geekblue[5], padding: '8px 0px' }}>{`${t("common.noOfTrials")}: ${customModelTotalWoFormulationCount}`}</Text>
							}
						</Row>
					}
					bodyStyle={{ padding: 15 }}
					headStyle={{ padding: 15 }}
				>
					<Space direction="vertical" style={{ width: "100%" }}>
						<Table
							bordered
							columns={ingredientColumns as any}
							dataSource={ingredientData}
						/>
					</Space>
				</Card>
				{Object.keys(modelInfo?.processing_data || {})?.length > 0 &&
					<Card
						title={
							<Space direction="vertical">
								<Title level={4}>{t("common.processing")}</Title>
							</Space>
						}
						bodyStyle={{ padding: 15 }}
						headStyle={{ padding: 15 }}
					>
						<Table
							bordered
							columns={processingColumns as any}
							dataSource={processingData}
						/>
					</Card>
				}
				<Card
					title={
						<Space direction="vertical">
							<Title level={4}>{t("modelDetails.propertyOutputs")}</Title>
							<Text type="secondary">
								{t("modelDetails.informationAboutRange")}
							</Text>
						</Space>
					}
					bodyStyle={{ padding: 15 }}
					headStyle={{ padding: 15 }}
				>
					<Space style={{ display: "flex", justifyContent: "flex-end", gap: 10, marginBottom: 20 }}>
						<Text>{t("modelAnalysis.selectErrorMetric")} :</Text>
						<Select
							style={{ minWidth: 250 }}
							onChange={setSelectedMetric}
							value={selectedMetric}
							showSearch
							optionFilterProp="children"
						>
							{
								Object.entries(metrics || {}).map(([key, value]) =>
									<Option key={key} value={key}>{value}</Option>
								)
							}
						</Select>
					</Space>
					<Table
						bordered
						columns={propertyColumns as any}
						dataSource={propertyData}
					/>
				</Card>

				{modelInfo?.status === "Success" && <ExplainableAI propertyData={propertyData} version={version} currentSelectedStage={currentSelectedStage} />}
				{modelInfo?.status === "Success" && <Performance />}
				{modelInfo?.status === "Success" && <PerformancePercentage currentSelectedStage={currentSelectedStage} />}

				<Modal
					title={`${t("common.confirmDelete")}?`}
					open={deleteModalVisible}
					onCancel={() => setDeleteModalVisible(false)}
					okText={t("common.delete")}
					onOk={() => {
						dispatch(deleteModelRequest({ versions: [modelData?.version] }))
						// dispatch(setAnalysisTab(true))
					}}
				>
					<Text>
						{t("common.deleteConfirmation")}{" "}
						<Text
							strong
						>{`${modelData?.objective} (${modelData?.comments})`}</Text>
						? {t("common.cannotUndoOperation")}
					</Text>
				</Modal>
				<Modal
					title={`${t("common.useModelPrediction")} ?`}
					open={modelUseModalVisible}
					cancelText={t("common.cancel")}
					onCancel={() => {
						setPredType("forward")
						setModelUseModalVisible(false)
					}}
					okText={t("common.confirm")}
					onOk={() => {
						dispatch(setCustomModelRequest({
							type: predType,
							version: modelData.version,
							user_message: "",
						}))
						dispatch(setModelVersion({ value: true, type: predType }))
						setPredType(predType)
						setModelUseModalVisible(false)
						if (predType === "forward") {
							push(`/ai-engine/predict/properties_prediction?version=${modelData.version}`)
						} else {
							push(`/ai-engine/predict/formulation_prediction?version=${modelData.version}`)
						}

					}}
				>
					<Space direction="vertical" style={{ width: "100%" }}>
						<Text>
							{t("aiEngine.bySelecting")}{" "}
							<Text
								strong
							>{`${modelData?.objective} (${modelData?.comments})`}</Text>{" "}
							{t("aiEngine.modelsdataPoint")}
						</Text>
						<Text type="secondary">{t("aiEngine.predictionType")} :</Text>
						<Radio.Group
							value={predType}
							onChange={(e) => setPredType(e.target.value)}
						>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Radio value={"forward"}>
									<Space>
										<Text>{t("aiEngine.propertiesPrediction")}</Text>
										<Tooltip title={t("common.useModelForward")}>
											<InfoCircleOutlined />
										</Tooltip>
									</Space>
								</Radio>
								{modelData.is_inverse && <Radio value={"inverse"}>
									<Space>
										<Text>
											{t("aiEngine.formulationsProcessingsPrediction")}
										</Text>
										<Tooltip
											title={
												t("common.useModelInverse")
											}
										>
											<InfoCircleOutlined />
										</Tooltip>
									</Space>
								</Radio>}
							</Space>
						</Radio.Group>
						{!modelData?.is_inverse && modelData?.is_requested && (
							<Text strong type="secondary">
								{t("common.inverseModelForThisVersion")}
							</Text>
						)}
					</Space>
				</Modal>
			</Space>
			<ModelComments
				commentsDrawerVisible={commentsDrawerVisible}
				setCommentsDrawerVisible={setCommentsDrawerVisible}
				modelComments={modelComments}
				setModelComments={setModelComments}
				socket={socket}
			/>
		</Spin>
	)
}
