import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react"
import {
  Col,
  Input,
  message,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import useTranslate from "src/utils/useTranslate";
import { IDataSheet } from "src/components/IDataSheet";
import { StyledButton } from "src/styled_components/StyledButton";
import { InfoCircleFilled, PlusOutlined } from "@ant-design/icons";
import { NumericalProperty } from "../types";
import "./numerical-output-constraints.scss"
import { useValue } from "src/utils/useValue";
const { Option } = Select;

type P = {
  disableCell: (record: any, type: string) => boolean;
  parameterListDropDown: (index: any, constraintstype: string) => JSX.Element;
  addRow: (inputType: string) => void;
  numericalObjectiveList: any[];
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  numericalProperties: NumericalProperty[];
  setNumericalProperties: Dispatch<
    SetStateAction<{ [key: string]: NumericalProperty[] }>
  >;
  currentSelectedStage?: any;
  isMultiStage: boolean;
};

export const NumericalOutputConstraints = ({
  numericalProperties,
  disableCell,
  parameterListDropDown,
  addRow,
  numericalObjectiveList,
  setUnsavedChanges,
  setNumericalProperties,
  currentSelectedStage,
  isMultiStage,
}: P) => {
  const [t] = useTranslate();
  const { convertValue, getValue: getEUValue } = useValue();
  const priorityDropDown = useCallback(
    (_, index) => {
      const priorityList = numericalProperties
        .filter((res) => res.modelType !== "range")
        .map((res, key) => key + 1);

      return (
        <Row style={{ alignItems: "center", width: "100%", padding: 8, justifyContent: 'space-between', gap: 8 }}>
          <Select
            style={{ flexGrow: 1 }}
            value={numericalProperties[index].priority}
            allowClear
            disabled={
              !numericalProperties[index].parameter.length ||
              !numericalProperties[index]?.modelType?.length ||
              numericalProperties[index]?.modelType === "range"
            }
            onChange={(e: any) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState));
                newState[currentSelectedStage][index].priority = e;
                return { ...newState };
              });
            }}
          // placeholder={t('aiEngine.inverseModel.select')}
          >
            {priorityList.map((res) => (
              <Option key={res} value={res}>
                {res}
              </Option>
            ))}
          </Select>
        </Row>
      );
    },
    [currentSelectedStage, numericalProperties, setNumericalProperties],
  );

  const optimizationTypeDropDown = useCallback(
    (_, index: number) => {
      return (
        <Row
          style={{
            alignItems: "center",
            width: "100%",
            padding: 8,
            justifyContent: "space-between",
            gap: 8,
          }}
        >
          <Select
            style={{
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "bottom",
            }}
            value={numericalProperties[index]?.modelType}
            disabled={!numericalProperties?.[index]?.parameter}
            onChange={(e) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage][index].modelType = e
                const prevData =
                  newState[currentSelectedStage].filter(
                    (prev: NumericalProperty) =>
                      !!prev.modelType.length && prev.modelType !== "range",
                  ).length === 1
                if (e === "minimize" || e === "maximize") {
                  newState[currentSelectedStage][index].min = ""
                  newState[currentSelectedStage][index].max = ""
                  newState[currentSelectedStage][index].value = e
                  if (prevData) {
                    newState[currentSelectedStage][index].priority = 1
                  }
                } else if (e === "range") {
                  newState[currentSelectedStage][index].value = ""
                  newState[currentSelectedStage][index].priority = null
                }
                return { ...newState }
              })
            }}
            placeholder={t("aiEngine.inverseModel.selectType")}
            className="dropdown-content-cell"
          >
            <Option value="range">{t("common.range")}</Option>
            <Option value={"minimize"}>{t("aiEngine.minimize")}</Option>
            <Option value={"maximize"}>{t("aiEngine.maximize")}</Option>
          </Select>
        </Row>
      )
    },
    [currentSelectedStage, numericalProperties, setNumericalProperties, t],
  )

  const minInputComponent = useCallback(
    (property, index) => {
      return (
        <div style={{ padding: 10 }}>
          <Input
            disabled={disableCell(property, "range")}
            style={{ textAlign: "right" }}
            value={numericalProperties[index]?.min}
            onChange={(e: any) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage][index].min = e.target.value
                return { ...newState }
              })
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                if (isNaN(convertedValue)) {
                  message.warning(t("aiEngine.pleaseEnterAValidNumber"))
                  newState[currentSelectedStage][index].min = ""
                } else if (newState[currentSelectedStage][index].max === "") {
                  newState[currentSelectedStage][index].min = strValue !== '' ? getEUValue(convertedValue) : ""
                  setUnsavedChanges(true)
                } else if (convertedValue <= convertValue(newState[currentSelectedStage][index].max)) {
                  newState[currentSelectedStage][index].min = strValue !== '' ? getEUValue(convertedValue) : ""
                  setUnsavedChanges(true)
                } else {
                  message.warning(t("aiEngine.minShouldBeLessThanMax"))
                  newState[currentSelectedStage][index].min = ""
                }
                return { ...newState }
              })
            }}
          />
        </div>
      )
    },
    [
      currentSelectedStage,
      disableCell,
      numericalProperties,
      setNumericalProperties,
      setUnsavedChanges,
      t,
      convertValue,
      getEUValue
    ],
  )

  const maxInputComponent = useCallback(
    (property, index) => {
      return (
        <div style={{ padding: 10 }}>
          <Input
            disabled={disableCell(property, "range")}
            style={{ textAlign: "right" }}
            value={numericalProperties[index]?.max}
            onChange={(e: any) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage][index].max = e.target.value
                return { ...newState }
              })
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))

                if (isNaN(convertedValue)) {
                  message.warning(t("aiEngine.pleaseEnterAValidNumber"))
                  newState[currentSelectedStage][index].max = ""
                } else if (convertedValue >= convertValue(newState[currentSelectedStage][index].min)) {
                  newState[currentSelectedStage][index].max = strValue !== '' ? getEUValue(convertedValue) : ""
                  setUnsavedChanges(true)
                } else {
                  message.warning(t("aiEngine.maxShouldBeGreaterThanMin"))
                  newState[currentSelectedStage][index].max = ""
                }

                return { ...newState }
              })
            }}
          />
        </div>
      )
    },
    [
      currentSelectedStage,
      disableCell,
      numericalProperties,
      setNumericalProperties,
      setUnsavedChanges,
      t,
      convertValue,
      getEUValue
    ],
  )

  const datasheetData = useMemo(() => {
    const priorityColumn = {
      value: "Priority",
      readOnly: true,
      component: (
        <Row style={{ padding: 8, marginLeft: 8 }}>
          <Typography.Text strong>
            {t("common.priority")}
          </Typography.Text>
        </Row>
      ),
      width: 150,
      forceComponent: true,
      align: 'left',
    };

    const datasheetColumnData = numericalProperties.map(
      (property: any, index: any) => {
        const data = [
          {
            value: property.parameter,
            component: parameterListDropDown(
              index,
              "numerical_output_constraints",
            ),
            align: 'left',
            forceComponent: true,
            className: "dropdown-remove-cell",
            width: 450,
          },
          {
            value: property.output_range_optimal,
            readOnly: true,
            forceComponent: true,
            width: 200,
          },
          {
            value: property?.modalType,
            component: optimizationTypeDropDown(property, index),
            forceComponent: true,
            width: 250,
            readOnly: !property.parameter,
            align: 'left',
          },
          {
            value: property?.min,
            forceComponent: true,
            component: minInputComponent(property, index),
            width: 150,
            readOnly: disableCell(property, "range"),
            align: 'left',
            disableEvents: true
          },
          {
            value: property?.max,
            forceComponent: true,
            component: maxInputComponent(property, index),
            width: 150,
            readOnly: disableCell(property, "range"),
            align: 'left',
            disableEvents: true
          },
        ];
        if (true) {
          data.push({
            value: property.priority,
            component: priorityDropDown(property, index),
            forceComponent: true,
            width: 150,
            readOnly:
              !!numericalProperties[index]?.modelType &&
              numericalProperties[index]?.modelType === "range",
            align: 'left',
          });
        }
        return data;
      },
    );

    const data = [
      [
        {
          value: t("aiEngine.objectives"),
          component: (
            <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
              <Typography.Text strong>
                {t("aiEngine.objectives")}
              </Typography.Text>
            </Row>
          ),
          forceComponent: true,
          readOnly: true,
          width: 450,
        },
        {
          value: t("aiEngine.range"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8 }}>
              <Typography.Text strong style={{ width: '200px' }}>
                {t("aiEngine.range")}
              </Typography.Text>
            </Row>
          ),
          forceComponent: true,
          width: 200,
        },
        {
          value: "Optimization Type",
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8, alignItems: "center" }}>
              <Typography.Text strong>
                {t("aiEngine.optimizationType")}
              </Typography.Text>
              <Col span={3}>
                <Tooltip
                  overlayInnerStyle={{ width: "350px" }}
                  title={
                    <Space direction="vertical">
                      <Space direction="horizontal">
                        <strong>{t("common.range")}</strong> -{" "}
                        {t("inverseModel.getValuesBetweenMinAndMaxValues")}
                      </Space>
                      <Space>
                        <strong>{t("aiEngine.minimize")}</strong> -{" "}
                        {t("inverseModel.getTheLowestPossibleValues")}
                      </Space>
                      <Space>
                        <strong>{t("aiEngine.maximize")}</strong>-{" "}
                        {t("inverseModel.getTheHighestPossibleValues")}
                      </Space>
                    </Space>
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </Col>
            </Row>
          ),
          width: 250,
          forceComponent: true,
        },
        {
          value: t("common.min"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8 }}>
              <Typography.Text strong>
                {t("common.min")}
              </Typography.Text>
            </Row>
          ),
          width: 150,
          forceComponent: true,
        },
        {
          value: t("common.max"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8 }}>
              <Typography.Text strong>
                {t("common.max")}
              </Typography.Text>
            </Row>
          ),
          width: 150,
          forceComponent: true,
        },
      ],
      ...datasheetColumnData,
    ];
    if (true) data[0].push(priorityColumn);
    return data;
  }, [t, numericalProperties, parameterListDropDown, optimizationTypeDropDown, minInputComponent, disableCell, maxInputComponent, priorityDropDown]);

  return (
    <Space direction="vertical" size={"middle"} style={{ width: '100%' }}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <StyledButton
            onClick={() => addRow("properties")}
            icon={<PlusOutlined />}
            disabled={
              numericalObjectiveList.length === 0 ||
              numericalProperties.length === numericalObjectiveList.length
            }
            type="default"
            size="small"
            style={{ borderRadius: 5, outline: "none" }}
          >
            {t("common.add")} {t("formulations.type.properties")}
          </StyledButton>
        </div>
      </div>

      <IDataSheet
        data={datasheetData}
        valueRenderer={(cell) => cell.value}
        className="catwise-objective-tab-table"
      />
    </Space>
  )
};
