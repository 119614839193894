import { Table } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    searchTerm: string | undefined;
};

const UnitDetails = ({ searchTerm }: TProps) => {
    const [t] = useTranslate();
    const unitsList = useSelector(
        (state: StoreState) => state.conversion.unitList
    );

    const columns: any = useMemo(
        () => [
            {
                title: t("common.unit"),
                dataIndex: "unit",
                key: "unit",
            },
            {
                title: t("common.Category"),
                dataIndex: "category",
                key: "category",
                fixed: "right",
                render: (text: string) => <div style={{ textTransform: "capitalize" }}>{text}</div> 
            },
        ],
        [t]
    );

    const tableData = useMemo(() => {
        const filteredUnits = searchTerm
            ? unitsList.filter((u) =>
                JSON.stringify(Object.values(u))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : unitsList;
        return filteredUnits.map((u) => ({
            key: u.unit_id,
            unit: u.name,
            category: u.category,
        }));
    }, [unitsList, searchTerm]);

    return (
        <>
            <div className="unit-details-container">
                <Table
                    bordered={false}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    className="repository-table"
                />
            </div>
        </>
    );
};

export default UnitDetails;
