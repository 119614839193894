import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { StoreState } from "src/store/configureStore"
import "../Compare.scss"
import { Space, Card, Select, Empty, Input, Row, Form, Col, Checkbox, Tooltip } from "antd";
import {
	CloseOutlined,
	UpOutlined,
	DownOutlined,
	MinusCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import useTranslate from "src/utils/useTranslate"
import { fetchMethodListRequest } from "src/store/actions/characterizationMethods"
import { prepareChartSeries } from "./zeon-chart/utils"
import { ParameterTooltip } from "src/components/AIEngine/ForwardModel/form/payload-form/ParameterTooltip"
import { useValue } from "src/utils/useValue"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import { StyledButton } from "src/styled_components/StyledButton"


const { Option } = Select

export const ZeonChart = React.memo(
	({
		allPlots,
		plot,
		setPlots,
		editMode,
		formulationList,
		visible
	}: {
		setPlots: any
		plot: any
		allPlots: any
		editMode: any
		formulationList: any
		visible: boolean
	}) => {
		const { id } = plot
		const { getValue } = useValue()
		const requiredFieldStar = useRequiredFieldStar()
		useEffect(() => {
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, plotType: "bar", description: "" } : plot
				)
			)
		}, [id, setPlots])
		const propertyLabels = useSelector((state: StoreState) => state.displayNames?.data?.properties || {})

		const [selectedTrials, setSelectedTrials] = useState<any[]>(
			plot.selectedTrials || []
		)
		const [xLabels, setxLabels] = useState<any[]>([])
		const [selectedPropertySets, setSelectedPropertySets] = useState<any[]>(plot.selectedPropertySets || [])
		const [description, setDescription] = useState<string>(plot.description || "")
		const [title, setTitle] = useState<string>(plot.title || "")
		const [xLabel, setxLabel] = useState<string>(plot.xLabel || "")
		const [t] = useTranslate()
		const stages: any = [t("common.ingredients"), t("common.properties")]
		const [optionalSelectedData, setOptionalSelectedData] = useState<any[]>([])
		const [stateData, setStateData] = useState<any[]>([])

		const [form] = Form.useForm()
		const displayNames = useSelector((state: StoreState) => state.displayNames.data)
		const processingProfilesList = useSelector((state: StoreState) => state.workOrderDetails.processingProfilesList)
		const configs = useSelector((state: StoreState) => state.configs.features)

		const linkedFormulationDetailsData = useSelector(
			(state: StoreState) =>
				state.compareFormulations.linkedFormulationDetailsData
		)
		const { methodList } = useSelector(
			(state: StoreState) => state.characterizationMethods
		)
		const dispatch = useDispatch()

		const [graphsData, setGraphsData] = useState<any>([])

		const numericalIngredientList = useMemo(() => {
			const data = Array.from(
				new Set(
					formulationList.flatMap(({ ingredients }: any) => Object.keys(ingredients || {}))
				)
			).filter((key: any) => formulationList.some(({ ingredients }: any) => typeof ingredients?.[key]?.value === "number"))
			return data
		}, [formulationList])

		useEffect(() => {
			setOptionalSelectedData(
				stateData
					?.flatMap(({ selectedData }: any) => selectedData)
					.map((ele: any) => ele)
			)
		}, [formulationList, selectedTrials, stateData])

		useEffect(() => {
			setxLabels(selectedTrials.map((propertyId) => propertyId))
		}, [selectedTrials, formulationList])

		const commonTooltipFormat = useMemo(() => {
			return {
				tooltip: {
					headerFormat: `<b>{point.x}</b> <br/>`,
					pointFormat: `{series.name} : {point.y} {point.unit} {point.variation_id} {point.characterization_set_id}`,
				},
			}
		}, [])

		useEffect(() => {
			dispatch(fetchMethodListRequest({ method_type: "characterizations" }))
		}, [dispatch])

		useEffect(() => {
			const data = prepareChartSeries({
				formulationList,
				selectedTrials,
				displayNames,
				linkedFormulationDetailsData,
				stateData,
			})
			setGraphsData(data)
		}, [formulationList, selectedTrials, displayNames, linkedFormulationDetailsData, stateData])

		useEffect(() => {
			const yAxis = stateData?.map((entry: any, index) => {
				return {
					title: {
						text:
							entry?.type === "properties"
								? entry?.label ??
								propertyLabels?.[entry?.selectedData?.[0]]?.name ??
								entry?.selectedData?.[0]
								: entry?.label,
					},
					opposite: index % 2 === 0 ? false : true,
				}
			})

			const plotData: any = {
				chart: {
					renderTo: "plot" + id,
					zoomType: "x",
				},
				title: {
					text: title,
				},
				xAxis: {
					title: {
						text: xLabel,
					},
					min: 0,
					max: selectedTrials.length > 5 ? 4 : selectedTrials.length - 1,
					scrollbar: {
						enabled: true,
					},
					categories: selectedTrials.map((trial_id) => formulationList.find((formulations: any) => formulations.id_set.formulation_id === trial_id)?.meta.display_id)
				},
				yAxis,
				legend: {
					enabled: false,
					layout: "horizontal",
					align: "center",
					verticalAlign: "bottom",
					x: 0,
					y: 0,
					labelFormatter: function () {
						let unit =
							(this as any)?.userOptions?.unit?.[0] !== null
								? (this as any)?.userOptions?.unit?.[0]
								: (this as any)?.userOptions?.unit?.[1]
						return `${(this as any).name} ${!!unit ? `(${unit})` : ""}`
					},
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					useHTML: true,
					formatter: function (item: any) {
						const point = (this as any).point
						const data = point.series.userOptions
						const methodName = methodList.filter((curr: any) =>
							curr?.variations.find(
								(res: any) => res.variation_id === data.variation_id
							)
						)?.[0]?.name
						if (data.chartType === "properties") {
							let plotKey = "characterizations"
							const hoverData = formulationList
								.filter((ele: any) => ele.id_set.formulation_id === point?.trial_id)
								.flatMap((formulation: any) => {
									const characterizationsData = Array.isArray(formulation.characterizations) ? formulation.characterizations : []
									plotKey = !!characterizationsData.length ? "characterizations" : "properties"
									return Array.isArray(formulation?.[plotKey]) ? formulation?.[plotKey] : []
								})?.[0]
								?.[plotKey]?.find(
									(parameter: any) =>
										parameter.variation_id === data.variation_id &&
										parameter.characterization_set_id === data.characterization_set_id
								)?.data
							const trialName = formulationList.find((ele: any) => ele.id_set.formulation_id === point?.trial_id)?.meta?.display_id

							const profileId = formulationList.find((ele: any) => ele.id_set.formulation_id === point?.trial_id)?.processing?.[0]?.meta?.profile_id
							const profileName = processingProfilesList.find((res: any) => res?.profile_id === profileId)?.name

							const tableData = Object.entries(hoverData).map(([parameter, methodData]: any) => {
								const tableInfo = `
                                <tr>
                                    <td style ="padding:5px; border: 1px solid #ddd">
										${displayNames?.[data?.chartType]?.[parameter]?.name ?? displayNames?.["characterizations"]?.[parameter]?.name ?? parameter ?? "-"}
									</td>
                                    <td style ="padding:5px; border: 1px solid #ddd">${methodData?.unit ?? "-"}</td>
                                    <td style ="padding:5px; border: 1px solid #ddd">${getValue(methodData?.value, 2) ?? "-"}</td>
                                </tr>
                            `
								return tableInfo
							}
							)

							const htmlEle = `
                                    <table style="border-collapse: collapse;width: 100%; border: 1px solid #ddd; text-align: left; z-index:100">
									<tr>
                                    <th colspan=${6} style ="padding:5px; border: 1px solid #ddd">Work Order Name: ${point.workOrderName}</th>
                                    </tr>
                                    <tr>
                                    <th colspan=${2} style ="padding:5px; border: 1px solid #ddd">${trialName ?? point.category} : ${point.stageName}</th>
                                    </tr>
									<tr>
									${Boolean(configs?.processing_profiles) &&
								`<th colspan=${2} style ="padding:5px; border: 1px solid #ddd">${`Processing: ${profileName}`}</th>`
								}
                                    </tr>
                                    <tr>
                                    <th colspan=${2} style ="padding:5px; border: 1px solid #ddd">${displayNames?.[data?.chartType]?.[data.displayName]?.name ?? data.displayName} : ${getValue(point.y, 2)}</th>
                                    </tr>
                                    <tr>
                                        <th rowspan=${Object.keys(hoverData).length + 1
								} style ="padding:5px; border: 1px solid #ddd">${methodName ?? "-"}</th>
                                        <th style ="padding:5px; border: 1px solid #ddd">Parameter</th>
                                        <th style ="padding:5px; border: 1px solid #ddd">Unit</th>
                                        <th style ="padding:5px; border: 1px solid #ddd">Value</th>
                                    </tr>
                                `
							const result = htmlEle.concat(tableData.join(""))
							return result
						} else {
							return `
									<strong>Work Order Name: ${point.workOrderName}</strong><br/>
									<strong>${point.category} : ${point.stageName}</strong><br/>
                                    <strong>${point.series.name} : ${getValue(point.y, 2)}</strong>`
						}
					},
				},
				plotOptions: {
					series: {
						groupPadding: 1,
						dataLabels: {
							enabled: true,
							connectorShape: "crookedLine",
							crookDistance: "70%",
							format: `{ point.y } `,
						},
						style: {
							fontWeight: "bold",
						},
					},
					scatter: commonTooltipFormat,
					column: {
						pointWidth: 10,
						borderWidth: 2,
						...commonTooltipFormat,
					},
					line: commonTooltipFormat,
					area: commonTooltipFormat,
				},
				series: graphsData,
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 800,
							},
							chartOptions: {
								legend: {
									layout: "horizontal",
									align: "center",
									verticalAlign: "bottom",
								},
							},
						},
					],
				},
			}

			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, selectedPropertySets: plotData } : plot
				)
			)
			setSelectedPropertySets(plotData as any)
		}, [selectedTrials, formulationList, stateData, title, propertyLabels, numericalIngredientList, xLabels, id, displayNames, setPlots, xLabel, linkedFormulationDetailsData, commonTooltipFormat, methodList, graphsData, processingProfilesList, configs, getValue])

		const selectPropertyIds = (value: any[]) => {
			setSelectedTrials(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, selectedTrials: value } : plot
				)
			)
		}

		const changeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
			const value = e.target.value
			setDescription(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, description: value } : plot
				)
			)
		}
		const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value
			setTitle(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, title: value } : plot
				)
			)
		}
		const changexLabel = (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value
			setxLabel(value)
			setPlots((plots: any) =>
				plots.map((plot: any) =>
					plot.id === id ? { ...plot, xLabel: value } : plot
				)
			)
		}

		const moveCardDown = () => {
			setPlots((prevState: any) => {
				const array = [...prevState]
				const element = array.filter((res: any) => res.id === id)[0]
				array[array.indexOf(element)] = array.splice(
					array.indexOf(element) + 1,
					1,
					array[array.indexOf(element)]
				)[0]
				return array
			})
		}

		const moveCardUp = () => {
			setPlots((prevState: any) => {
				const array = [...prevState]
				const element = array.filter((res: any) => res.id === id)[0]
				array[array.indexOf(element)] = array.splice(
					array.indexOf(element) - 1,
					1,
					array[array.indexOf(element)]
				)[0]
				return array
			})
		}

		const addStage = (value: string, key: any) => {
			const { plotGraph } = form?.getFieldsValue()
			Object.assign(plotGraph[key], { type: value, selectedData: [] })
			form.setFieldsValue({ plotGraph })
			const { plotGraph: data }: any = form.getFieldsValue()
			setStateData(data)
		}

		const addData = (values: string, key: any) => {
			const { plotGraph } = form?.getFieldsValue()
			Object.assign(plotGraph[key], {
				selectedData: Array.isArray(values) ? values : [values],
			})
			form.setFieldsValue({ plotGraph })
			const { plotGraph: data }: any = form.getFieldsValue()
			setStateData(data)
		}

		const handleChartType = (value: string, key: any) => {
			const { plotGraph } = form.getFieldsValue()
			Object.assign(plotGraph[key], { plotype: value })
			form.setFieldsValue({ plotGraph })
			const { plotGraph: data }: any = form.getFieldsValue()
			setStateData(data)
		}
		const handleChartLabel = (event: any, key: any) => {
			const { plotGraph } = form.getFieldsValue()
			Object.assign(plotGraph[key], { label: event.target.value })
			form.setFieldsValue({ plotGraph })
			const { plotGraph: data }: any = form.getFieldsValue()
			setStateData(data)
		}

		// List Of Property Sets
		const propertiesListData = useMemo(() => {
			const propertyData = Array.from(new Set(formulationList.flatMap((res: any) => res["properties"])))

			const out: any = propertyData.reduce(
				(acc: any, trial: any) => [...acc,
				...(Array.isArray(trial?.["properties"])
					? trial?.["properties"]?.reduce((_acc: any, { data = {} }: any) => {
						if (typeof data?.value !== "string") {
							_acc = _acc.concat(Object.keys(data))
							return _acc
						}
						return null
					}, []).filter((property: any) => !!property)
					: []),
				], [])
			return [...new Set(out)]
		}, [formulationList])

		return (
			<Card
				size="small"
				type="inner"
				key={id}
				style={!editMode ? { display: "none" } : { width: "100%" }}
				extra={[
					<Space>
						{" "}
						{allPlots?.indexOf(plot) !== 0 && (
							<StyledButton onClick={moveCardUp} icon={<UpOutlined />} />
						)}
						{allPlots?.indexOf(plot) !== allPlots?.length - 1 && (
							<StyledButton onClick={moveCardDown} icon={<DownOutlined />} />
						)}
						<StyledButton
							danger
							onClick={() =>
								setPlots((plots: any) =>
									plots.filter(({ id: _id }: any) => _id !== id)
								)
							}
							icon={<CloseOutlined />}
						/>
					</Space>,
				]}
			>
				<Space
					direction="vertical"
					size="small"
					style={{ width: "100%" }}
					id="charts"
				>
					<Space>
						<>
							<Checkbox
								checked={formulationList.length === selectedTrials.length}
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedTrials(
											formulationList.map(
												(formulation: any, index: any) =>
													formulation?.id_set?.formulation_id
											)
										)
										setPlots((plots: any) =>
											plots.map((plot: any) =>
												plot.id === id
													? {
														...plot,
														selectedTrials: formulationList.map(
															(formulation: any) =>
																formulation?.id_set?.formulation_id
														),
													}
													: plot
											)
										)
									} else {
										setSelectedTrials([])
										setPlots((plots: any) =>
											plots.map((plot: any) =>
												plot.id === id ? { ...plot, selectedTrials: [] } : plot
											)
										)
									}
								}}
							>
								{t("compare.selectAllTrials")}
							</Checkbox>
						</>
					</Space>

					<Select
						size="large"
						style={{ width: "100%" }}
						value={selectedTrials}
						onChange={selectPropertyIds}
						mode="multiple"
						optionFilterProp="children"
						placeholder={t("common.selectTrials")}
					>
						{formulationList.flatMap((formulation: any, index: any) => (
							<Option value={formulation?.id_set?.formulation_id} key={formulation?.id_set?.formulation_id}>
								{formulation?.meta?.display_id}
							</Option>
						))}
					</Select>

					<Row gutter={12}>
						<Col span={12}>
							<Input
								placeholder={t("common.title")}
								value={title}
								onChange={changeTitle}
							/>
						</Col>
						<Col span={12}>
							<Input
								placeholder={t("chart.placeholder.xAxisLabel")}
								value={xLabel}
								onChange={changexLabel}
							/>
						</Col>
					</Row>
					<Input.TextArea
						placeholder={t("common.description")}
						value={description}
						onChange={changeDescription}
					/>

					<Form form={form} requiredMark={false} >
						<Form.List name="plotGraph">
							{(fields, { add, remove }, { errors }) => (
								<>
									{fields.map(({ key, name }) => (
										<Row justify="space-between">
											<Col span={6}>
												<Form.Item
													rules={[{ required: true }]}
													name={[name, "type"]}
													fieldKey={[key, "type"]}
													required tooltip={requiredFieldStar}
												>
													<Select
														placeholder={t("common.forY-axis")}
														onChange={(e: any) => addStage(e, name)}
														style={{ width: 250 }}
													>
														{stages?.map((ele: any) => (
															<Option value={ele.toLowerCase()}>{ele}</Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col>
												<Form.Item
													name={[name, "selectedData"]}
													fieldKey={[key, "selectedData"]}
												>
													<Space>
														{!!stateData[name]?.type &&
															stateData[name]?.type === "ingredients" && (
																<Select
																	style={{ width: 250 }}
																	mode="multiple"
																	value={
																		!!stateData[name]?.selectedData
																			? stateData[name]?.selectedData
																			: []
																	}
																	onChange={(e: any) => addData(e, name)}
																	placeholder={`Select ${stateData[name]?.type} `}
																>
																	{numericalIngredientList
																		?.filter(
																			(element: any) =>
																				!optionalSelectedData?.includes(element)
																		)
																		.flatMap((ingredient: any, index: any) => (
																			<Option value={ingredient} key={index}>
																				{
																					linkedFormulationDetailsData?.find(
																						({ id_set }: any) =>
																							id_set.formulation_id ===
																							ingredient
																					)?.meta?.display_id ?? <ParameterTooltip value={ingredient} />
																				}
																			</Option>
																		))}
																</Select>
															)}
														{!!stateData[name]?.type &&
															stateData[name]?.type !== "ingredients" && (
																<Select
																	showSearch
																	style={{ width: 250 }}
																	value={
																		!!stateData[name]?.selectedData
																			? stateData[name]?.selectedData
																			: null
																	}
																	onChange={(e: any) => addData(e, name)}
																	placeholder={`Select ${stateData[name]?.type} `}
																>
																	{propertiesListData
																		?.filter(
																			(element: any) =>
																				!optionalSelectedData?.includes(element)
																		)
																		?.map((displayName: any, index: any) => (
																			<Option value={displayName} key={index}>
																				{
																					displayNames[stateData[name]?.type]?.[displayName]?.name
																				}
																			</Option>
																		))}
																</Select>
															)}
													</Space>
												</Form.Item>
											</Col>

											<Col span={5}>
												<Form.Item
													name={[name, "plotype"]}
													fieldKey={[key, "plotype"]}
												>
													<Select
														defaultValue={"column"}
														placeholder="Select a Chart Type"
														onChange={(e: any) => handleChartType(e, name)}
														disabled={!stateData[name]?.type}
													>
														<Option value="column">{t("chartType.Bar")}</Option>
														<Option value="line">{t("chartType.Line")}</Option>
														<Option value="scatter">
															{t("chartType.Scatter")}
														</Option>
														<Option value="area">{t("chartType.Area")}</Option>
													</Select>
												</Form.Item>
											</Col>

											<Col span={4}>
												<Form.Item
													name={[name, "label"]}
													fieldKey={[key, "label"]}
												>
													<Input
														placeholder={t("chart.placeholder.enterYAxisLabel")}
														onChange={(e: any) => handleChartLabel(e, name)}
														disabled={!stateData[name]?.type}
													/>
												</Form.Item>
											</Col>
											<Col span={4.5}>
												<MinusCircleOutlined
													className="dynamic-delete-button"
													onClick={() => {
														remove(name)
														const { plotGraph } = form.getFieldsValue()
														Object.assign(plotGraph[name], null)
														form.setFieldsValue({})
														const data = plotGraph.slice(0, -1)
														setStateData(data)
													}}
												/>
											</Col>
										</Row>
									))}

									<Space
										style={{
											display: "flex",
											justifyContent: "center",
											marginTop: 10,
										}}
									>
										{fields?.length < 4 && (
											<Tooltip title={!selectedTrials.length ? `Please Select At least One Trial` : null}>
												<Form.Item>
													<StyledButton
														type="dashed"
														onClick={() => add()}
														style={{ margin: "auto", marginBottom: 10 }}
														size="large"
														icon={<PlusOutlined />}
														disabled={
															!!stateData?.length ? !stateData[stateData?.length - 1]?.selectedData?.length && !!stateData[stateData?.length - 1]?.type : !selectedTrials.length ? true : false
														}
													>
														{t("chart.addYaxis")}
													</StyledButton>
													<Form.ErrorList errors={errors} />
												</Form.Item>
											</Tooltip>
										)}
									</Space>
								</>
							)}
						</Form.List>
					</Form>

					{!!selectedTrials.length && visible ? (
						<Card
							style={{ marginTop: 20 }}
							className="graph-container"
						>
							<HighchartsReact
								highcharts={Highcharts}
								options={selectedPropertySets}
							/>
						</Card>
					) : (
						<Empty description={t("chart.pleaseSelectTrial!")} />
					)}
				</Space>
			</Card>
		)
	}
)
