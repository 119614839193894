import { Layout } from "plotly.js";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Plot from "react-plotly.js";
import { standardDeviation } from "src/utils/general/stats";

const KDEChart: React.FC<any> = ({
  data,
  unit,
  selections,
  handleChartSelection,
  titles,
  showSelections
}: any) => {
  const [chartData, setChartData] = useState<any>(null);

  const getLengthArray = useCallback(() => {
    let lengthArr: any = [];
    data?.forEach((d: any) => {
      let calibrated_length = null;
      if (d?.calibrated_length?.value) {
        calibrated_length = d?.calibrated_length?.value
          .replace(/[^0-9.]/g, "")
          .trim();
      }
      lengthArr.push(calibrated_length || d?.max_length?.pixel_length);
    });

    const max = Math.max(...lengthArr);
    lengthArr = lengthArr.filter((l: any) => l < max);

    return lengthArr;
  }, [data]);

  useEffect(() => {
    const lengthArr = getLengthArray();

    // Function to calculate dynamic bin width
    function calculateDynamicBinWidth(data: any) {
      data.sort(function (a: any, b: any) {
        return a - b;
      });

      const iqr =
        data[Math.floor(0.75 * data.length)] -
        data[Math.floor(0.25 * data.length)];
      const n = data.length;
      const binWidth = (0.4 * iqr) / Math.pow(n, 1 / 3);
      return binWidth;
    }

    // Calculate bin width
    const binWidth = calculateDynamicBinWidth(lengthArr);

    // Create histogram trace
    const histTrace = {
      x: lengthArr,
      type: "histogram",
      histnorm: "probability",
      mode: "lines",
      marker: {
        color: "blue",
      },
      xbins: {
        size: binWidth,
      },
      name: "Probabilities",
    };

    // Create KDE trace
    const kdeTrace = {
      x: lengthArr,
      side: "positive",
      type: "violin",
      line: {
        color:
          "linear-gradient(0deg, rgba(24, 144, 255, 0) 8.87%, #A5D4FF 100.04%)",
        width: 2,
        dash: "dash",
      },
      name: "",
      hoveron: "kde",
      box: {
        visible: true,
      },
      meanline: {
        visible: true,
      },
    };
    setChartData([kdeTrace, histTrace]);

    return () => {
      setChartData(null);
      handleChartSelection({ selections: [] });
    };
  }, [data, handleChartSelection, getLengthArray]);

  useEffect(() => {
    if (showSelections) {
      const lengthArr = getLengthArray().map((n: string) => Number(n));

      const mean =
        lengthArr.reduce((a: number, b: number) => a + b) / lengthArr.length;
      const sd = standardDeviation(lengthArr, true);

      const leftSelectionCoordinate = mean - sd;
      const rightSelectionCoordinate = mean + sd;

      const upperSelectionCoordinate = 0.25;
      const bottomSelectionCoordinate = -0.01;

      handleChartSelection({
        selections: [
          {
            xref: "x",
            yref: "y",
            line: { width: 1, dash: "dot" },
            type: "rect",
            x0: leftSelectionCoordinate,
            y0: upperSelectionCoordinate,
            x1: rightSelectionCoordinate,
            y1: bottomSelectionCoordinate,
          },
        ],
      });
    }
  }, [getLengthArray, handleChartSelection, showSelections]);

  const layout: Partial<Layout> = useMemo(() => ({
    title: titles?.graphTitle || "Object Length Report",
    xaxis: { title: titles?.xAxis || "Object length", ticksuffix: unit },
    yaxis: { title: titles?.yAxis || "Density estimate" },
    dragmode: showSelections ? "select" : false,
    selectdirection: "h",
    selections: selections,
    showlegend: false,
  }), [selections, unit, titles, showSelections]);

  return (
    <Plot
      style={{ width: "100%", height: "480px" }}
      data={chartData}
      layout={layout}
      config={{ displayModeBar: false, responsive: true, autosizable: true }}
      onRelayout={handleChartSelection}
    />
  );
};

export default React.memo(KDEChart);
