import useTranslate from "src/utils/useTranslate";
import { NumericSummaryCharts } from "./SmileNumericalHeatmap"
import { Col, Form, Row, Select } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DisplayNames } from "src/typings";
import { StoreState } from "src/store/configureStore";
import { useSelector } from "react-redux";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useValue } from "src/utils/useValue";
const { Option } = Select;

type TProps = {
    chartsData: NumericSummaryCharts;
    properties: string[]
}

const SmileCharts = ({ chartsData, properties }: TProps) => {
    const [t] = useTranslate();
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const [property1, setProperty1] = useState();
    const [property2, setProperty2] = useState();
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames;
    const { getValue: getLocalValue } = useValue();

    const setChartSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 1400)
            chartRef.current?.chart.setSize(800, 400);
        else if (windowWidth > 1200)
            chartRef.current?.chart.setSize(600, 400);
        else if (windowWidth > 1000)
            chartRef.current?.chart.setSize(500, 400);
        else if (windowWidth <= 1000)
            chartRef.current?.chart.setSize(400, 400);
    }

    useEffect(() => {
        window.addEventListener("resize", setChartSize, false)

        return () => window.removeEventListener("resize", setChartSize);
    }, []);

    const property1Options = useMemo(() => {
        return Object.keys(chartsData.scatter_plot || {}).filter(key => key !== property2);
    }, [property2, chartsData]);

    const property2Options = useMemo(() => {
        return Object.keys(chartsData.scatter_plot || {}).filter(key => key !== property1);
    }, [property1, chartsData]);

    const getDisplayName = useCallback((name: string) => {
        let isProperty = false;
        if (Object.keys(names?.properties || {}).includes(name) || properties.includes(name)) isProperty = true;

        const dName = names.properties?.[name]?.name ?? name;

        return !isProperty ? `Mole fraction of ${dName} present in the reaction ingredients` : dName;
    }, [names.properties, properties]);

    const highchartsScatterPlotOption = useMemo(() => {
        if (!property1 || !property2 || Object.keys(chartsData.scatter_plot || {}).length === 0) return;
        const xPlot = Object.values(chartsData.scatter_plot?.[property1]);
        const yPlot = Object.values(chartsData.scatter_plot?.[property2]);

        const mergedValues = xPlot.map((xVal, idx) => [xVal, yPlot[idx]])

        const options = {
            chart: { type: "scatter" },
            title: { text: "" },
            xAxis: {
                title: { text: property1 ? getDisplayName(property1) : "X Axis" },
                labels: {
                    useHTML: true,
                    formatter: (item: any) => String(getLocalValue(item.value) ?? ""),
                },
            },
            yAxis: {
                title: { text: property2 ? getDisplayName(property2) : "Y Axis" },
                labels: {
                    useHTML: true,
                    formatter: (item: any) => String(getLocalValue(item.value) ?? ""),
                },
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                useHTML: true,
                headerFormat: "<small>{series.name}</small><br/>",
                pointFormatter: function (this: any) {
                    const point = this
                    return `x:${getLocalValue(point.x) ?? ""}<br> y: ${getLocalValue(point.y) ?? ""}`
                },
            },
            series: [
                {
                    data: mergedValues
                }
            ]
        }
        return options
    }, [chartsData, property1, property2, getDisplayName, getLocalValue])

    return (
        <div className="smiles-graph-container" id="graphs">
            <div className="heading">{t("common.charts")}</div>
            <div className="graph-container">
                <div className="subheading">{t("graphs.scatterPlot")}</div>

                <Form
                    onFinish={() => null}
                    layout="vertical"
                >
                    <Row justify="start" gutter={[16, 16]} style={{ marginTop: "12px" }}>
                        <Col span={8}>
                            <Form.Item
                                name="property1"
                            >
                                <Select
                                    placeholder={t("common.select")}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={setProperty1}
                                    showSearch={true}
                                >
                                    {property1Options.map((property: string) => (
                                        <Option
                                            value={property}
                                            key={property}
                                        >
                                            {names.properties?.[property]?.name ?? property}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="property2"
                            >
                                <Select
                                    placeholder={t("common.select")}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={setProperty2}
                                    showSearch={true}
                                >
                                    {property2Options.map((property: string) => (
                                        <Option
                                            value={property}
                                            key={property}
                                        >
                                            {names.properties?.[property]?.name ?? property}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {property1 && property2 && Object.keys(chartsData.scatter_plot || {}).length > 0 && (
                        <Row justify="start" gutter={[16, 16]} style={{ marginTop: "16px" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={highchartsScatterPlotOption}
                                ref={chartRef}
                            />
                        </Row>
                    )}
                </Form>
            </div>
        </div>
    )
}

export default SmileCharts