import { Drawer } from "antd";
import useTranslate from "src/utils/useTranslate";
import { FilterForm } from "./filterForm";

const Filters = ({
    visible,
    setVisible,
    selectedProjects,
    formState,
    applyFilters,
    clearFilters,
    form,
    setFormState,
    setInnerSelector,
    outerSelector,
    setOuterSelector,
    filtersLoading,
    innerSelector,
}: any) => {
    const [t] = useTranslate();
    function onClose() {
        setVisible(false);
    }
    return (
        <Drawer
            visible={visible}
            width={975}
            onClose={onClose}
            bodyStyle={{ padding: "10px 20px" }}
            mask={false}
            title={t("common.filters")}
        >
            <FilterForm
                form={form}
                applyFilters={(values: any) => {
                    console.log({ values });
                    applyFilters(values);
                    // onClose();
                }}
                clearFilters={() => {
                    clearFilters();
                }}
                formState={formState}
                setFormState={setFormState}
                filtersLoading={filtersLoading}
                outerSelector={outerSelector}
                setOuterSelector={setOuterSelector}
                innerSelector={innerSelector}
                setInnerSelector={setInnerSelector}
            />
        </Drawer>
    );
};

export default Filters;
