import "../styles.scss"
import "../../../WorkOrderDetails/react-datasheet.css"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
import { InverseModel as Comp, InverseModelProps } from "./child"
import { Alert, Checkbox, Radio, Select, Space, Spin, Typography } from "antd";
import { modelsConfigCleanup } from "src/store/actions/formulate"

import { useEffect, useState } from "react"
import { useQuery } from "src/utils/useQuery"
import useTranslate, { LanguageUnion } from "src/utils/useTranslate"
import { useHistory } from "react-router-dom"
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons"
import { messages } from "src/utils/hooks"
import { StyledButton } from "src/styled_components/StyledButton"
const { Text } = Typography;
const { Option } = Select;

type P = {
  tab: string
  setUnsavedChanges: Function
  selectedObjective: any
  setExperimentsCurrent: any
  experimentsCurrent: any
  setSelectedObjective: any
}

export type ZeonCurrentPageInfo = {
  variation_id: number;
  version: number;
  currentPage: number;
}

export type Filter = {
  pageNum: number
  user_ids: string[]
  model_type: string[]
  status: string[]
  versions: string[]
}

export const InverseModelCatwise = ({
  tab,
  setUnsavedChanges,
  selectedObjective,
  setExperimentsCurrent,
  experimentsCurrent,
  setSelectedObjective,
}: P) => {
  let query: any = useQuery();
  let modelVersion = query?.get('version');
  const [t] = useTranslate();
  const { push } = useHistory();

  const [isMultistage, setIsMultistage] = useState<boolean>(false)

  const dispatch = useDispatch()

  const configs = useSelector((state: StoreState) => state.configs.features)

  const [tipVisible, setTipVisible] = useState(true);

  const {
    configData,
    configStatus
  } = useSelector(
    (state: StoreState) => state.formulate
  )

  const ln: LanguageUnion = useSelector(
    (state: StoreState) => state.language.current
  )

  // When user refreshes with version in URL, this effect sets radio and dropdown properly
  useEffect(() => {
    setIsMultistage(
      configData?.find(
        (modelData: any) => modelData?.version === Number(modelVersion),
      )?.is_multistage || false,
    )
  }, [configData, isMultistage, modelVersion])

  const { statusMinmax, statusRange } = useSelector(
    (state: StoreState) => state.inverseModel,
  )
  const { expIdStatus } = useSelector((state: StoreState) => state.suggestedExp)


  useEffect(() => {
    if (!Boolean(modelVersion) && !!configData?.length) {
      push(
        `/ai-engine/predict/formulation_prediction?version=${configData.filter(
          ({ is_inverse }: any) => is_inverse,
        )?.[0].version
        }`,
      )
    }
  }, [configData, modelVersion, push])

  useEffect(() => {
    if (
      statusRange?.[0]?.status === AsyncStates.SUCCESS ||
      statusMinmax?.[0]?.status === AsyncStates.SUCCESS ||
      expIdStatus === AsyncStates.SUCCESS
    ) {
      const suggestedExpDocument = document.getElementById(
        "suggested-experiments",
      )
      suggestedExpDocument?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [expIdStatus, statusRange, statusMinmax])

  return (
    <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
      {!!configData.filter((res: any) => res?.is_inverse).length &&
        // !Object.keys(inversePropertiesData?.data || {}).length &&
        !Boolean(modelVersion) &&
        tipVisible && (
          <Alert
            description={
              <Space style={{ paddingLeft: '7px' }}>
                <Typography.Text style={{ margin: 0 }}>{t("aiEngine.tip")}{":"}</Typography.Text>
                <Typography.Text style={{ margin: 0 }}>{t("aiEngine.noModelFound")}</Typography.Text>
              </Space>
            }
            style={{ border: 'none', padding: "16px" }}
            type="info"
            closable
            afterClose={() => {
              setTipVisible(false)
            }}
            closeIcon={
              <StyledButton
                size="small"
                style={{
                  color: "black",
                  outline: "none",
                }}
                type="link"
              >
                <CloseOutlined />
              </StyledButton>
            }
          />
        )}

      <Space
        direction="horizontal"
        style={{ alignItems: "center" }}
        size={"middle"}
      >
        {
          !!configData.filter((res: any) => res?.is_inverse).length ? (
            <Space>
              <Checkbox
                checked={Boolean(modelVersion)}
                value={Number(modelVersion)}
                style={{ height: "100%" }}
                onChange={(e) => {
                  if (!e.target.checked) {
                    push("/ai-engine/predict/formulation_prediction")
                    dispatch(modelsConfigCleanup())
                  } else {
                    push(
                      `/ai-engine/predict/formulation_prediction?version=${configData.filter(
                        ({ is_inverse, is_multistage }: any) =>
                          is_inverse && isMultistage
                            ? is_multistage
                            : !is_multistage,
                      )?.[0].version
                      }`,
                    )
                  }
                }}
              >
                <Text strong>{t("aiEngine.useCustomModel")}</Text>
              </Checkbox>
            </Space>
          ) : <Space>
            <Spin
              indicator={<LoadingOutlined />}
              spinning={configStatus === AsyncStates.LOADING}
            />
            <Typography.Text>{configStatus !== AsyncStates.ERROR ? `${t("common.loading")} ${t("common.models")}` : `${messages[ln].internal_server_error}`}</Typography.Text>
          </Space>
        }
        {
          Boolean(modelVersion) && (
            <>
              <Space>
                <Radio.Group
                  value={isMultistage}
                  onChange={(e) => {
                    setIsMultistage(Boolean(e.target.value))
                    push(
                      `/ai-engine/predict/formulation_prediction?version=${configData.filter(
                        ({ is_inverse, is_multistage }: any) =>
                          is_inverse &&
                          (e.target.value ? is_multistage : !is_multistage),
                      )?.[0].version
                      }`,
                    )
                  }}
                >
                  <Radio
                    value={false}
                    disabled={
                      !Boolean(
                        configData.filter(
                          (modelData: any) =>
                            modelData.is_inverse && !modelData.is_multistage,
                        ).length,
                      )
                    }
                  >
                    {t("aiEngine.singleStage")}
                  </Radio>
                  <Radio
                    value={true}
                    disabled={
                      !Boolean(
                        configData.filter(
                          (modelData: any) =>
                            modelData.is_inverse && modelData.is_multistage,
                        ).length,
                      )
                    }
                  >
                    {t("aiEngine.multiStage")}
                  </Radio>
                </Radio.Group>
              </Space>
              <Space>
                <Text strong style={{ wordBreak: "normal" }}>
                  {t("aiEngine.selectModel")}:{" "}
                </Text>
                <Select
                  style={{ maxWidth: 800, minWidth: 250 }}
                  value={Number(modelVersion)}
                  showSearch
                  optionFilterProp="label"
                  placeholder={t("aiEngine.placeholder.selectModelVersion")}
                  onSelect={(e: any) => {
                    push(
                      `/ai-engine/predict/formulation_prediction?version=${e}`,
                    )
                  }}
                >
                  {configData
                    .filter(
                      (modelData: any) =>
                        modelData.is_inverse &&
                        (isMultistage
                          ? modelData.is_multistage
                          : !modelData.is_multistage),
                    )
                    .map((modelData: any) => (
                      <Option key={modelData?.version} value={modelData?.version} label={`${modelData.objective} (${modelData.comments})`}>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.25rem",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Text
                            ellipsis={{ tooltip: true }}
                            style={{ margin: 0, maxWidth: "95%" }}
                          >
                            {`${modelData.objective} (${modelData.comments})`}
                          </Text>
                        </div>
                      </Option>
                    ))}
                </Select>
              </Space>
            </>
          )
        }
      </Space>


      {Boolean(configs?.ai_engine_with_methods) ||
        Boolean(configs?.characterization_methods) ? (
        <ZeonComponent
          setExperimentCurrent={setExperimentsCurrent}
          setSelectedObjective={setSelectedObjective}
          setUnsavedChanges={setUnsavedChanges}
          tab={tab}
        />
      ) : (
        <GeneralComponent
          setExperimentCurrent={setExperimentsCurrent}
          setSelectedObjective={setSelectedObjective}
          setUnsavedChanges={setUnsavedChanges}
          tab={tab}
        />
      )}
    </Space>
  )
}

const GeneralComponent = (props: InverseModelProps) => {
  return <Comp {...props} />
}

const ZeonComponent = (props: InverseModelProps) => {
  return <Comp {...props} />
}
