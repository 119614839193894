import React, { ReactNode, useMemo } from "react";
import { Space } from "antd";
import { DisplayNames } from "src/typings";
import { CategoricalSummary, NumericalSummary } from "../../types";
import {
  CategoricalDistributionCharts,
  NumericalCategoricalCorr,
  CategoricalNumericalCorr,
  CategoricalDataSummary,
  ColumnDataTypeDistribution,
  NumericalCorrelationHeatmaps,
  NumericalDataSummary,
} from ".";
import { CorrelationStatusTypes, TabKeys } from "../../DataSummary";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { Tab } from "src/components/AIEngine/AIEngine";

type P = {
  names: DisplayNames;
  categoricalData: CategoricalSummary;
  numericalData: NumericalSummary;
  workOrderIds: string[];
  children?: ReactNode;
  characterizationMethodId?: string;
  selectedVariation?: any;
  correlationStatus: CorrelationStatusTypes;
  normalise: boolean | undefined;
  selectedStages: string[];
  selectedExperiments: string[];
  currentTab: Tab;
  tab: TabKeys,
  getDisplayName: (name: string) => string,
  selectedFormulations?: string[]
};

export const Summaries = ({
  names,
  children,
  workOrderIds,
  categoricalData,
  numericalData,
  characterizationMethodId,
  selectedVariation,
  correlationStatus,
  normalise,
  selectedStages,
  selectedExperiments,
  currentTab,
  tab,
  getDisplayName,
  selectedFormulations
}: P) => {
  const configs = useSelector((state: StoreState) => state.configs.features)
  const { data } = useSelector((state: StoreState) => state.dataSummary);


  const mergedData = useMemo(() => {
    const data = Object.values(numericalData?.data_summary || {}).reduce(
      (acc, curr: any) => ({
        ...acc,
        ...curr,
        ...curr?.costing,
      }),
      {},
    );
    const filteredEntries = Object.entries(data || {}).filter(
      ([key, value]) => typeof value !== "undefined",
    );
    return Object.fromEntries(filteredEntries);
  }, [numericalData]);

  return (
    <Space
      style={{ width: "100%", overflowX: "auto" }}
      direction="vertical"
      size={"large"}
    >
      {children}
      <div id="column-data-type-distribution">
        {!!Object.keys(categoricalData?.divided_columns || {}).length && (
          <ColumnDataTypeDistribution
            data={categoricalData?.divided_columns}
            getDisplayName={getDisplayName}
          />
        )}
      </div>
      {Object.keys(mergedData).length > 0 && (
        <div id='numerical-data-summary'>
          <NumericalDataSummary
            mergedData={mergedData as any}
            data={numericalData.data_summary}
            getDisplayName={getDisplayName}
          />
        </div>
      )}

      {!!Object.keys(categoricalData?.describe_summary || {}).length && (
        <CategoricalDataSummary
          data={categoricalData?.describe_summary}
          getDisplayName={getDisplayName}
        />
      )}


      {!!Object.keys(categoricalData?.chart_data || {}).length && (
        <CategoricalDistributionCharts
          data={categoricalData.chart_data}
          getDisplayName={getDisplayName}
        />
      )}

      {!!Object.keys(categoricalData?.categorical_numerical_corr || {}).length && (
        <CategoricalNumericalCorr
          data={categoricalData?.categorical_numerical_corr}
          getDisplayName={getDisplayName}
        />
      )}

      {!!Object.keys(categoricalData?.numerical_categorical_corr || {}).length && (
        <NumericalCategoricalCorr
          data={categoricalData?.numerical_categorical_corr}
          getDisplayName={getDisplayName}
        />
      )}

      {!(!normalise && Boolean(configs?.nomralised_data_summary)) && (
        <>
          {(tab === "overview" || (!!(data as any)?.correlation_summary?.[tab as any]?.length)) && <NumericalCorrelationHeatmaps
            getDisplayName={getDisplayName}
            names={names}
            correlationStatus={correlationStatus}
          />}

        </>
      )}
    </Space>
  );
};
