import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Collapse, Empty, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";

import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import RepositoryService from "src/services/repository/v3";
import jwtManager from "src/utils/jwtManager";
import { Headers } from "src/services/interface";
import PropertySpecimenModal from '../Common/PropertySpecimenModal'

type TProps = {
  selectedProperty: string;
};

const PropertySpecimens = ({ selectedProperty }: TProps) => {
  const [t] = useTranslate();
  const [showModal, setShowModal] = useState(false);
  const [specimens, setSpecimens] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  const fetchSpecimens = useCallback(async () => {
    setLoading(true);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const specimensResponse: any = await RepositoryService.getSpecimens(
      selectedProperty,
      headers
    );
    setLoading(false);
    setSpecimens(specimensResponse.data?.result?.data?.specimens ?? []);
  }, [defaultHeaders, selectedProperty]);

  useEffect(() => {
    fetchSpecimens();
  }, [fetchSpecimens]);

  return (
    <div className="property-specimen-container">
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <Collapse
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              showArrow: false,
              collapsible: "icon",
              label: (
                <div className="collapse-header-container">
                  <span className="collapse-header">
                    {t("inventory.specimens")}
                  </span>
                  <span
                    className="add-new-cta"
                    onClick={() => setShowModal(true)}
                  >
                    <EditOutlined /> {t("common.update")}
                  </span>
                </div>
              ),
              children: (
                <>
                  {specimens.length === 0 && loading && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}

                  {specimens.sort((a, b) => a.localeCompare(b))
                  .map((specimen, i) => (
                    <div key={specimen} className="specimen-list">
                      <span>{specimen}</span>
                    </div>
                  ))}
                </>
              ),
            },
          ]}
        />
      </Spin>
      <PropertySpecimenModal
        key={selectedProperty}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedPropertyId={selectedProperty}
        specimens={specimens}
        setSpecimens={setSpecimens}
      />
    </div>
  );
};

export default PropertySpecimens;
