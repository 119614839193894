import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock"
import HighchartsMore from "highcharts/highcharts-more";

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { ExplainableAIResultProps } from './ExplainableAIBarChart'
import { getDisplayNameElement } from 'src/utils/general/getDisplayNameElement';
import "./ExplainableAI.scss"
import { useValue } from 'src/utils/useValue';
import useTranslate from 'src/utils/useTranslate';


HighchartsMore(Highcharts);

export default function ExplainableAIWaterfall({ property, model, filterBy, setWaterfallPlotOptions }: ExplainableAIResultProps) {
    const [t] = useTranslate()
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const { customModelDataFormulations } = useSelector((state: StoreState) => state.modelAnalysis)
    const { explainableAIData } = useSelector((state: StoreState) => state.explainableAI)
    const { getValue } = useValue()
    const optionsList = useMemo(() => {
        const allOptions = Object.entries(explainableAIData?.[property]?.[model?.[0]]?.plots?.["waterfall"] || {})?.map(([key, response]: any, index: number) => {
            const baseValue = Number(response.base.toFixed(3))
            const finalValue = Number(response.prediction.toFixed(3))
            const allValuesArr = Object.entries(response.values || {}).filter(([key, data]) => data !== 0)?.map(([key, data]: any, index: number) => data).reverse()
            const cumulativeSumArray = allValuesArr.reduce((accumulator, currentValue) => {
                const summedValue = accumulator[accumulator.length - 1] + currentValue
                accumulator.push(Number(summedValue.toFixed(3)));
                return accumulator;
            }, [baseValue]);

            const percent = 2.5
            const min = Number((Math.min(...cumulativeSumArray) - ((Math.min(...cumulativeSumArray) / 100) * percent)).toFixed(3))
            const max = Number((Math.max(...cumulativeSumArray) + ((Math.max(...cumulativeSumArray) / 100) * percent)).toFixed(3))

            const barHeight = 30
            const extraPadding = 100
            const dynamicHeight = allValuesArr.length * barHeight + extraPadding
            const plotHeight = dynamicHeight < 500 ? 500 : dynamicHeight
            const formulation_id = Object.keys(customModelDataFormulations).find((fid: any) => fid === key)

            return {
                chart: {
                    type: 'waterfall',
                    inverted: true,
                    className: "waterfall__chart",
                    height: plotHeight,
                    id: new Date().getTime()
                },

                title: {
                    text: formulation_id ? customModelDataFormulations[formulation_id] : key
                },

                xAxis: {
                    type: 'category',
                    labels: {
                        formatter: function (item: any) {
                            const pointName = item.value
                            if (pointName) {
                                return `<b>${(getDisplayNameElement(displayNames, "processing", pointName) ?? getDisplayNameElement(displayNames, "ingredients", pointName))?.name ?? pointName}</b> = ${getValue(response.data?.[pointName] ?? "-", 3)}`
                            }
                            return null
                        },
                    },
                },
                caption: {
                    useHTML: true,
                    text: `<h3 style="padding:5px"><strong>Actual Value=</strong> ${getValue(response.actual_value ?? "-", 3) ?? "-"}</h3>`,
                    align: "right",
                    verticalAlign: "top"
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    min: min,
                    max: max,
                    plotLines: [
                        {
                            value: baseValue,
                            width: 1,
                            color: "black",
                            dashStyle: "DashDot",
                            zIndex: 10,
                            acrossPanes: true,
                            label: {
                                text: `<b>Base Value</b>= ${getValue(baseValue)}`,
                                rotation: 0,
                                verticalAlign: "bottom",
                                y: 35,
                                align: "right",
                                style: {
                                    padding: "1rem"
                                },
                            }
                        },
                        {
                            value: finalValue,
                            width: 1,
                            color: "black",
                            dashStyle: "DashDot",
                            zIndex: 10,
                            label: {
                                text: `<b>${t("aiEngine.modelAnalysis.predictedValue")}</b>= ${getValue(finalValue)}`,
                                rotation: 0,
                                verticalAlign: "top",
                                textAlign: "right",
                                y: 0
                            }
                        }
                    ]
                },
                tooltip: {
                    formatter: function (item) {
                        const point = this.point
                        return `<b>${(getDisplayNameElement(displayNames, "processing", point.name) ?? getDisplayNameElement(displayNames, "ingredients", point.name))?.name ?? point.name}</b> : ${getValue(point.y) ?? ""}`
                    },
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                        dataLabels: {
                            enabled: true,
                            color: '#000'
                        },
                        marker: {
                            enabled: false
                        },
                    },
                    waterfall: {
                        dataLabels: {
                            formatter: function (this: any) {
                                if (this.point.isFirst) {
                                    return;
                                }
                                return this.y;
                            }
                        },
                    }
                },
                series: [{
                    upColor: "#00FF0080",
                    color: "#FF000080",
                    type: "waterfall",
                    data: [{
                        name: "",
                        y: baseValue,
                        visible: false,
                    },
                    ...Object.entries(response.values || {})?.filter(([key, data]) => data !== 0)?.map(([key, data]: any, index: number) => ({
                        name: key,
                        y: data ? Number(data.toFixed(3)) : data,
                    }))].reverse(),
                    threshold: baseValue,
                    turboThreshold: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function (this: any) {
                            const pointValue = `${getValue(this.y, 2)}%`
                            return pointValue
                        },
                        style: {
                            fontWeight: 'bold'
                        }
                    },
                }]
            } as Highcharts.Options
        })
        setWaterfallPlotOptions((prev: any) => ({ ...prev, options: allOptions }))
        return allOptions
    }, [model, explainableAIData, property, customModelDataFormulations, displayNames, setWaterfallPlotOptions, getValue, t])

    return (
        <div style={{ display: "flex", gap: 10, width: "100%" }}>
            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(1, minmax(0,1fr))", gap: 10 }}>
                {
                    optionsList.map((options: any, index) => (
                        <div style={{ border: "1px solid #ccc", width: "100%", height: "100%" }} key={index}>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
