import { memo, useMemo, useCallback, useState, useEffect } from "react";
import { StoreState } from "../../store/configureStore";
import {
  useDispatch,
  useSelector
} from "react-redux";

import { Space, Table, Checkbox, Empty, Popover, Typography } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { blue, geekblue, grey } from "@ant-design/colors";
import Text from "antd/lib/typography/Text";
import useTranslate from "src/utils/useTranslate";
import { getLinkedTableCellData } from "src/utils/decorator";
import { useTotal } from "./MethodsTable/useTotal";
import "./WorkOrderTable.scss";
import { ZeonMethodsTable } from "./MethodsTable/ZeonMethodsTable";
import { useMethodDetails } from "src/utils/useMethodDetails";
import { fetchLinkedExperimentDetailsRequest } from "src/store/actions/experiment";
import { useValue } from "src/utils/useValue";
import InventoryUtils from "src/modules/InventoryV2/utils";
import { antdTheme } from "src/constants";
import { AsyncStates } from "src/constants";
import { fetchAllMethodsRequest } from "src/store/actions/repository";

interface TableProps {
  formulationList: any[];
  checkbox?: boolean;
  formulationsCheck?: any;
  checkBoxChange?: any;
  from?: string;
  title?: string;
  filters?: any;
  tableId?: string;
}

export const LinkedTrialTable = ({
  record,
  formulationList,
  from,
}: {
  record: any;
  formulationList: any;
  from: any;
}) => {
  const [t] = useTranslate();
  const ingredientsLabels = useSelector(
    (state: StoreState) => state.displayNames.data?.ingredients || {}
  );
  const linkedExperimentDetails = useSelector(
    (state: StoreState) => state.workOrderDetails.linkedExperimentDetails
  );
  const linkedExperimentList = useSelector(
    (state: StoreState) => state.workOrderDetails.linkedExperiment
  );
  const total = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );
  const configs = useSelector(
    (state: StoreState) => state.configs.features
  );
  const { getValue } = useValue()

  const columns = useMemo(
    () => [
      {
        title: t("common.ingredient"),
        dataIndex: "name",
        className: "table-name-column",
        fixed: "left",
        align: "center" as any,
        width: from === "drawer" ? 200 : 250,
        render: (text: string) => {
          return {
            props: {
              style: { background: geekblue[0] },
            },
            children:
              !!ingredientsLabels?.[text]?.lot_no?.trim() ||
                !!ingredientsLabels?.[text]?.supplier?.trim() ||
                !!ingredientsLabels?.[text]?.sub_category?.trim() ? (
                <Popover
                  trigger={"hover"}
                  content={
                    !!ingredientsLabels?.[text]?.lot_no?.trim() ||
                      !!ingredientsLabels?.[text]?.supplier?.trim() ||
                      !!ingredientsLabels?.[text]?.sub_category?.trim() ? (
                      <Space direction="vertical">
                        {!!ingredientsLabels?.[text]?.sub_category?.trim() && (
                          <Text strong>
                            {"Category/Sub Category :"}{" "}
                            {ingredientsLabels?.[text]?.sub_category}
                          </Text>
                        )}
                        {!!ingredientsLabels?.[text]?.lot_no?.trim() && (
                          <Text strong>
                            {"Lot No :"} {ingredientsLabels?.[text]?.lot_no}
                          </Text>
                        )}
                        {!!ingredientsLabels?.[text]?.supplier?.trim() && (
                          <Text strong>
                            {"Supplier :"} {ingredientsLabels?.[text]?.supplier}
                          </Text>
                        )}
                      </Space>
                    ) : (
                      <Text strong>
                        {ingredientsLabels[text]?.name ||
                          linkedExperimentList?.find(
                            (res: any) => res.formulation_id === text
                          )?.formulation_display_id ||
                          text}
                      </Text>
                    )
                  }
                >
                  <Text
                    style={{ color: geekblue[6], wordBreak: "break-word" }}
                    strong
                  >
                    {ingredientsLabels?.[text]?.name || text}
                  </Text>
                </Popover>
              ) : (
                <Text
                  style={{ color: geekblue[6], wordBreak: "break-word" }}
                  strong
                >
                  {ingredientsLabels?.[text]?.name || text}
                </Text>
              ),
          };
        },
      },
      {
        title: t("common.unit"),
        dataIndex: "unit",
        fixed: "left",
        width: 100,
        align: "center" as any,
        render: (text: string) => {
          return getLinkedTableCellData(text);
        },
      },
      {
        title: t("common.category"),
        dataIndex: "category",
        fixed: "left",
        width: 175,
        align: "center" as any,
        render: (text: string) => {
          return getLinkedTableCellData(text);
        },
      },
      ...formulationList
        .filter((res: any) =>
          Object.keys(record || {}).includes(res?.id_set?.formulation_id)
        )
        .map((res: any) => ({
          title: () => (
            <Space
              style={{ width: "100%" }}
              align="center"
              size="small"
              direction="vertical"
            >
              <Typography.Title level={5} style={{ color: grey[1] }}>
                {res?.meta?.display_id}
              </Typography.Title>
            </Space>
          ),
          dataIndex: res?.id_set?.formulation_id,
          width: 150,
          align: "center" as any,
          render: (text: string) => {
            return getLinkedTableCellData(text);
          },
        })),
    ],
    [formulationList, ingredientsLabels, record, t, from, linkedExperimentList]
  );

  const calculateConvertedProportion = useCallback(
    (name: any, formulationId: any) => {
      const currentValue = Number(record?.[formulationId]);
      const linkedTrial = linkedExperimentDetails.find(
        (res: any) => res?.id_set?.formulation_id === record?.linked_trial
      );
      const linkedTrialValue = Number(linkedTrial?.ingredients?.[name]?.value);
      // let linkedTrialTotal = 0;
      // Object.values(linkedTrial?.ingredients || {}).forEach((res: any) => {
      //   if (!isNaN(Number(res?.value))) {
      //     linkedTrialTotal += Number(res?.value);
      //   }
      // });
      // return getValue(
      //   (linkedTrialValue / linkedTrialTotal) * currentValue
      // );
      return Boolean(!!currentValue && !isNaN(currentValue)) ? getValue(linkedTrialValue) : 0
    },
    [linkedExperimentDetails, record, getValue]
  );

  const dataSource = useMemo(() => {
    const data = Array.from(
      new Set(
        linkedExperimentDetails
          .filter(
            (res: any) => res?.id_set?.formulation_id === record?.linked_trial
          )
          .flatMap(({ ingredients }: any) => Object.keys(ingredients || {}))
      )
    )
      .filter((key: any) =>
        linkedExperimentDetails.some(
          ({ ingredients }: any) =>
            !!ingredients?.[key]?.value && ingredients?.[key]?.value !== "-"
        )
      )
      .map((name: any, index: number) => ({
        key: index,
        category: (Boolean(linkedExperimentList?.find((res: any) => res.formulation_id === name)
          ?.formulation_display_id) && Boolean(configs?.kurita_configs)) ? "Polymer" : linkedExperimentDetails?.[0]?.ingredients?.[name]?.category || "-",
        name:
          linkedExperimentList?.find((res: any) => res.formulation_id === name)
            ?.formulation_display_id || name,
        unit:
          linkedExperimentDetails?.[0]?.ingredients?.[name]?.unit ||
          ingredientsLabels[name]?.unit?.[0] ||
          "-",
        ...formulationList
          .filter((res: any) =>
            Object.keys(record || {}).includes(res?.id_set?.formulation_id)
          )
          .reduce(
            (acc: any, formulation: any) => ({
              ...acc,
              [formulation.id_set.formulation_id]:
                calculateConvertedProportion(
                  name,
                  formulation.id_set.formulation_id
                ) || "-",
            }),
            {}
          ),
      }));
    return data;
  }, [
    ingredientsLabels,
    formulationList,
    linkedExperimentDetails,
    linkedExperimentList,
    record,
    calculateConvertedProportion,
    configs
  ]);

  return (
    <>
      <div className="mb-1">
        <span className="highlighted">{t("common.totalTrials")}</span> {total}
      </div>
      <Table
        style={{ margin: 0 }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 450 }}
      />
    </>
  );
};

export const WOIngredientsComparisonTable = memo(
  ({
    formulationList,
    checkbox,
    formulationsCheck,
    checkBoxChange,
    from,
    tableId = "_ingredient_table",
  }: TableProps) => {
    const [t] = useTranslate();
    const dispatch = useDispatch();

    const ingredientsLabels = useSelector(
      (state: StoreState) => state.displayNames.data?.ingredients || {}
    );
    const linkedFormulationList = useSelector(
      (state: StoreState) => state.workOrderDetails.linkedExperiment
    );
    const linkedExperimentDetails = useSelector(
      (state: StoreState) => state.workOrderDetails.linkedExperimentDetails
    );
    const total = useSelector(
      (state: StoreState) => state.workOrderDetails.total
    );
    const configs = useSelector(
      (state: StoreState) => state.configs.features
    );

    const { getValue } = useValue()
    const [expandedRows, setExpandedRows] = useState<any[]>([]);

    const allIngredientsData = useMemo(() => {
      if (formulationList.length === 0) return {}
      return formulationList?.reduce((acc: any, exp: any) => ({ ...acc, ...exp.ingredients }), {})
    }, [formulationList])

    const ingredientCategories = useMemo(() => {
      if (!Object.keys(allIngredientsData ?? {}).length) return []
      if (!!Object.keys(allIngredientsData ?? {}).length) {
        return Array.from(new Set(Object.values(allIngredientsData).map((res: any) => res?.category))).filter((res: any) => res)
      }
      return []
    }, [allIngredientsData]);

    const getCategoryName = useCallback((parameterName: string) => {
      const category = allIngredientsData?.[parameterName]?.category;
      return category
    }, [allIngredientsData]);

    const ingredientTableData = useMemo(() => {
      const data = Array.from(
        new Set(
          formulationList.flatMap(({ ingredients }) =>
            Object.keys(ingredients || {})
          )
        )
      )
        // .filter((key: string) =>
        //   formulationList.some(
        //     ({ ingredients }: any) =>
        //       !!ingredients?.[key]?.value && ingredients?.[key]?.value !== "-"
        //   )
        // )
        .map((name: string, index: number) => ({
          key: linkedFormulationList?.find(
            (res: any) => res.formulation_id === name
          )?.formulation_id,
          category: (Boolean(linkedFormulationList?.find((res: any) => res.formulation_id === name)
            ?.formulation_display_id) && Boolean(configs?.kurita_configs)) ? "Polymer" : getCategoryName(name) || "-",
          name:
            linkedFormulationList?.find(
              (res: any) => res.formulation_id === name
            )?.formulation_display_id || name,
          linked_trial: linkedFormulationList?.find(
            (res: any) => res.formulation_id === name
          )?.formulation_id,
          unit:
            formulationList?.[0]?.ingredients?.[name]?.unit ||
            ingredientsLabels[name]?.unit?.[0] ||
            "-",
          ...formulationList.reduce(
            (acc, formulation) => ({
              ...acc,
              [formulation.id_set.formulation_id]: getValue(
                formulation.ingredients?.[name]?.value
              ),
            }),
            {}
          ),
        }));
      return data;
    }, [ingredientsLabels, formulationList, linkedFormulationList, configs, getValue, getCategoryName]);

    const { totalRow: ingredientTotalRow } = useTotal(ingredientTableData, {
      name: "Total",
      unit: "",
      category: "",
    });

    useEffect(() => {
      setExpandedRows(() => [...linkedExperimentDetails?.map(({ id_set }: any) => id_set.formulation_id)])
    }, [linkedExperimentDetails])

    const ingredientFilterOptions = useMemo(() => {
      const data = Array.from(
        new Set(
          formulationList.flatMap(({ ingredients }) =>
            Object.keys(ingredients || {})
          )
        )
      );
      return data;
    }, [formulationList]);


    return (
      <>
        <div className="mb-1">
          <span className="highlighted">{t("common.totalTrials")}</span> {total}
        </div>

        <Table
          bordered
          size="small"
          pagination={false}
          locale={{
            emptyText: <Empty description={"No Ingredients Available"} />,
          }}
          id={tableId}
          getPopupContainer={() => (document as any).getElementById(tableId)}
          className={"report-table"}
          rowClassName={(record, index) => {
            if (record.name === "Total") {
              return "highlight-row";
            }
            return "";
          }}
          columns={[
            {
              title: t("common.category"),
              dataIndex: "category",
              filters: ingredientCategories.map((category) => ({
                text: category,
                value: category,
              }))
                .sort((value1, value2) =>
                  value1.text.toLowerCase() < value2.text.toLowerCase() ? -1 : 1
                ),
              onFilter: (value, record) => record.category.indexOf(value) === 0,
              sorter: (a, b) => {
                if (a.name !== "Total" && b.name !== "Total") {
                  return a.category.localeCompare(b.category);
                }
              },
              sortDirections: ["ascend", "descend"],
              fixed: "left",
              width: 175,
              align: "center" as any,
              render: (text: string, record: any) => {
                return <Text style={{ wordBreak: "break-word" }}>{text}</Text>;
              },
            },
            {
              title: t("common.ingredient"),
              dataIndex: "name",
              className: "table-name-column",
              fixed: "left",
              width: from === "drawer" ? 200 : 250,
              align: "center" as any,
              sorter: (a, b) => {
                if (a.name !== "Total" && b.name !== "Total") {
                  return a.name.localeCompare(b.name);
                }
              },
              filters: ingredientFilterOptions.map((ingredient) => ({
                value: String(
                  linkedFormulationList?.find(
                    (res: any) => res.formulation_id === ingredient
                  )?.formulation_display_id ?? ingredient
                ),
                text: String(
                  linkedFormulationList?.find(
                    (res: any) => res.formulation_id === ingredient
                  )?.formulation_display_id ??
                  ingredientsLabels[ingredient]?.name ??
                  ingredient
                ),
              })),
              onFilter: (value, record) => {
                if (record.name !== "Total") {
                  return record.name?.indexOf(value) === 0;
                }
                return true;
              },
              sortDirections: ["ascend", "descend"],
              render: (text: string) => {
                return !!ingredientsLabels?.[text]?.lot_no?.trim() ||
                  !!ingredientsLabels?.[text]?.supplier?.trim() ||
                  !!ingredientsLabels?.[text]?.sub_category?.trim() ? (
                  <Popover
                    trigger={"hover"}
                    content={
                      !!ingredientsLabels?.[text]?.lot_no?.trim() ||
                        !!ingredientsLabels?.[text]?.supplier?.trim() ||
                        !!ingredientsLabels?.[text]?.sub_category?.trim() ? (
                        <Space direction="vertical">
                          {!!ingredientsLabels?.[
                            text
                          ]?.sub_category?.trim() && (
                              <Text strong>
                                {"Category/Sub Category :"}{" "}
                                {ingredientsLabels?.[text]?.sub_category}
                              </Text>
                            )}
                          {!!ingredientsLabels?.[text]?.lot_no?.trim() && (
                            <Text strong>
                              {"Lot No :"} {ingredientsLabels?.[text]?.lot_no}
                            </Text>
                          )}
                          {!!ingredientsLabels?.[text]?.supplier?.trim() && (
                            <Text strong>
                              {"Supplier :"}{" "}
                              {ingredientsLabels?.[text]?.supplier}
                            </Text>
                          )}
                        </Space>
                      ) : (
                        <Text strong>
                          {ingredientsLabels[text]?.name ||
                            linkedFormulationList?.find(
                              (res: any) => res.formulation_id === text
                            )?.formulation_display_id ||
                            text}
                        </Text>
                      )
                    }
                  >
                    <Text
                      style={
                        from === "drawer"
                          ? { color: blue[6], wordBreak: "break-word" }
                          : { wordBreak: "break-word" }
                      }
                      strong
                    >
                      {ingredientsLabels?.[text]?.name || text}
                    </Text>
                  </Popover>
                ) : (
                  <Text
                    style={
                      from === "drawer"
                        ? { color: blue[6], wordBreak: "break-word" }
                        : { wordBreak: "break-word" }
                    }
                    strong
                  >
                    {ingredientsLabels?.[text]?.name || text}
                  </Text>
                );
              },
            },
            {
              title: t("common.unit"),
              dataIndex: "unit",
              fixed: "left",
              width: 100,
              align: "center" as any,
            },

            ...formulationList.map(
              (
                {
                  id_set: { formulation_id },
                  use_for_ml,
                  meta: { display_id },
                },
                index: any
              ) => ({
                title: () => (
                  <Space
                    style={{ width: "100%" }}
                    align="center"
                    size="small"
                    direction="vertical"
                  >
                    {checkbox && (
                      <Checkbox
                        checked={formulationsCheck?.includes(formulation_id)}
                        onChange={(e) => checkBoxChange(e, formulation_id)}
                      />
                    )}
                    <Typography.Title level={5} style={{ color: grey[1] }}>
                      {display_id}
                    </Typography.Title>
                    {checkbox &&
                      (use_for_ml ? (
                        <Space>
                          <Text>{t("common.successful")}</Text>
                          <Text>
                            <CheckCircleTwoTone
                              style={{ fontSize: antdTheme.fontSizeHeading3 }}
                              twoToneColor="#52c41a"
                            />
                          </Text>
                        </Space>
                      ) : (
                        <Space>
                          <Text>{t("common.failed")}</Text>
                          <Text>
                            <CloseCircleTwoTone
                              style={{ fontSize: antdTheme.fontSizeHeading3 }}
                              twoToneColor="#ff0000"
                            />
                          </Text>
                        </Space>
                      ))}
                  </Space>
                ),
                dataIndex: formulation_id,
                width: 150,
                align: "center" as any,
              })
            ),
          ]}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <LinkedTrialTable
                  record={record}
                  formulationList={formulationList}
                  from={from}
                />
              );
            },
            expandedRowKeys: expandedRows,
            rowExpandable: (record) => !!record?.linked_trial,
            onExpand: (expanded: boolean, record: any) => {
              if (expanded && !linkedExperimentDetails?.find(({ id_set }: any) => id_set.formulation_id === record.linked_trial)) {
                dispatch(
                  fetchLinkedExperimentDetailsRequest({
                    formulation_id: [record?.linked_trial],
                  })
                );
              } else if (expanded) {
                setExpandedRows([...expandedRows, record?.linked_trial])
              } else if (!expanded) {
                setExpandedRows([...expandedRows.filter(rowId => rowId !== record?.linked_trial)])
              }
            },
          }}
          dataSource={
            !!ingredientTableData?.length
              ? [
                ...ingredientTableData,
                { ...ingredientTotalRow, key: ingredientTableData.length },
              ]
              : []
          }
          scroll={{ x: 450 }}
        />
      </>
    );
  }
);

export const WOProcessingComparisonTable = memo(
  ({
    formulationList,
    checkbox,
    checkBoxChange,
    formulationsCheck,
    from,
    tableId = "_processing_table",
  }: TableProps) => {
    const processingLabels = useSelector(
      (state: StoreState) => state.displayNames.data?.processing || {}
    );
    const [t] = useTranslate();
    const total = useSelector(
      (state: StoreState) => state.workOrderDetails.total
    )
    const { getValue } = useValue()

    const processingTableData = useMemo(() => {
      const processingData = Array.from(
        new Set(
          formulationList.flatMap(({ processing }) =>
            Object.keys(
              processing.reduce(
                (acc: any, { processing }: any) => ({ ...acc, ...processing }),
                {}
              )
            )
          )
        )
      )
        // .filter((key: string) =>
        //   formulationList.some(
        //     ({ processing }: any) =>
        //       !!processing?.[0]?.processing?.[key]?.value &&
        //       processing?.[0]?.processing?.[key]?.value !== "-"
        //   )
        // )
        .map((processing: string) => ({
          category: processingLabels[processing]?.category || "-",
          processing: processingLabels[processing]?.name || processing,
          unit:
            formulationList?.[0]?.processing?.[0]?.processing?.[processing]
              ?.unit ||
            processingLabels?.[processing]?.unit?.[0] ||
            "-",
          ...formulationList.reduce(
            (acc, { processing: trials }: any) => ({
              ...acc,
              ...trials.reduce(
                (acc: any, _processing: any) => ({
                  ...acc,
                  [_processing.id_set.processing_id]: getValue(
                    _processing?.processing?.[processing]?.value
                  ),
                }),
                {}
              ),
            }),
            {}
          ),
        }));
      return processingData;
    }, [formulationList, processingLabels, getValue]);

    return (
      <>
        <div className="mb-1">
          <span className="highlighted">{t("common.totalTrials")}</span> {total}
        </div>
        <Table
          bordered
          size="small"
          pagination={false}
          className={"report-table"}
          id={tableId}
          getPopupContainer={() => (document as any).getElementById(tableId)}
          columns={[
            {
              title: t("common.category"),
              dataIndex: "category",
              filters: Array.from(
                new Set(
                  Object.values(processingLabels || {})?.map(
                    (res: any) => res?.category
                  )
                )
              )
                .map((category) => ({
                  text: String(category),
                  value: String(category),
                }))
                .sort((value1, value2) =>
                  value1.text.toLowerCase() < value2.text.toLowerCase() ? -1 : 1
                ),
              onFilter: (value, record) => record.category.indexOf(value) === 0,
              sorter: (a, b) => a.category.localeCompare(b.category),
              sortDirections: ["ascend", "descend"],
              fixed: "left",
              width: 150,
              align: "center" as any,
            },
            {
              title: t("common.processing"),
              dataIndex: "processing",
              fixed: "left",
              width: 200,
              align: "center" as any,
              sorter: (a, b) => a.processing.localeCompare(b.processing),
              filters: Array.from(
                new Set(
                  Object.values(processingLabels || {})?.map(
                    (res: any) => res?.name
                  )
                )
              )
                .map((processing) => ({
                  text: String(processing),
                  value: String(processing),
                }))
                .sort((value1, value2) =>
                  value1.text.toLowerCase() < value2.text.toLowerCase() ? -1 : 1
                ),
              onFilter: (value, record) =>
                record.processing.indexOf(value) === 0,
              sortDirections: ["ascend", "descend"],
              render: (text: string) => (
                <Text
                  style={from === "drawer" ? { color: blue[6] } : {}}
                  strong
                >
                  {text}
                </Text>
              ),
            },
            {
              title: t("common.unit"),
              dataIndex: "unit",
              fixed: "left",
              width: 100,
              align: "center" as any,
            },
            ...formulationList.flatMap(
              (
                {
                  id_set: { formulation_id },
                  processing,
                  meta: { display_id },
                  use_for_ml,
                }: any,
                index: any
              ) =>
                processing.map(({ id_set: { processing_id } }: any) => ({
                  title: () => (
                    <Space
                      style={{ width: "100%" }}
                      align="center"
                      size="small"
                      direction="vertical"
                    >
                      {checkbox && (
                        <Checkbox
                          checked={formulationsCheck?.includes(formulation_id)}
                          onChange={(e) => checkBoxChange(e, formulation_id)}
                        />
                      )}
                      <Typography.Title level={5} style={{ color: grey[1] }}>
                        {display_id}
                      </Typography.Title>
                      {checkbox &&
                        (use_for_ml ? (
                          <Space>
                            <Text>{t("common.successful")}</Text>
                            <Text>
                              <CheckCircleTwoTone
                                style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                twoToneColor="#52c41a"
                              />
                            </Text>
                          </Space>
                        ) : (
                          <Space>
                            <Text>{t("common.failed")}</Text>
                            <Text>
                              <CloseCircleTwoTone
                                style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                twoToneColor="#ff0000"
                              />
                            </Text>
                          </Space>
                        ))}
                    </Space>
                  ),
                  dataIndex: processing_id,
                  width: 150,
                  align: "center" as any,
                }))
            ),
          ]}
          dataSource={processingTableData}
          scroll={{ x: 450 }}
        />
      </>
    );
  }
);

export const LinkedMethodTable = memo(
  ({
    formulationList,
    checkbox,
    checkBoxChange,
    formulationsCheck,
    from,
    filters,
    tableId = "",
  }: TableProps) => {
    const transformData = useMemo(() => {
      const data = Array.from(
        new Set(
          formulationList.flatMap(({ characterizations, properties }) =>
            characterizations?.length ? characterizations : properties
          )
        )
      );
      const out = data.reduce(
        (acc: any, trial: any) => ({
          ...acc,
          ...(Array.isArray(trial?.["characterizations"]) ||
            Array.isArray(trial?.["properties"])
            ? (trial?.["characterizations"]?.length
              ? trial["characterizations"]
              : trial?.["properties"]
            )?.reduce(
              (
                _acc: any,
                { characterization_set_id, variation_id, data = [] }: any
              ) => ({
                ..._acc,
                [characterization_set_id]: {
                  variation: {
                    id: variation_id,
                    data: data,
                  },
                },
              }),
              {}
            )
            : []),
        }),
        {}
      );
      return out;
    }, [formulationList]);

    return (
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {Object.entries(transformData).map(
          ([set_id, { variation }]: any, index: number) => (
            <ZeonMethodsTable
              formulationList={formulationList}
              index={index}
              checkbox={checkbox}
              checkBoxChange={checkBoxChange}
              formulationsCheck={formulationsCheck}
              from={from}
              set_id={set_id}
              variation={variation}
              filters={filters}
              tableId={tableId}
            />
          )
        )}
      </Space>
    );
  }
);

export const WOCharacterizationsComparisonTable = memo(
  ({
    formulationList,
    checkbox,
    checkBoxChange,
    formulationsCheck,
    from,
    tableId = "_characterization_table",
  }: TableProps) => {
    const [t] = useTranslate();
    const characterizationLabels = useSelector(
      (state: StoreState) => state.displayNames.data?.characterizations || {}
    );
    const total = useSelector(
      (state: StoreState) => state.workOrderDetails.total
    )
    const { getValue } = useValue()

    return (
      <>
        <div className="mb-1">
          <span className="highlighted">{t("common.totalTrials")}</span> {total}
        </div>
        <Table
          bordered
          size="small"
          pagination={false}
          id={tableId}
          getPopupContainer={() => (document as any).getElementById(tableId)}
          className="report-table"
          columns={[
            {
              title: t("common.category"),
              dataIndex: "category",
              filters: Array.from(
                new Set(
                  Object.values(characterizationLabels || {})?.map(
                    (res: any) => res?.category
                  )
                )
              )
                .map((category) => ({
                  text: String(category),
                  value: String(category),
                }))
                .sort((value1, value2) =>
                  value1.text.toLowerCase() < value2.text.toLowerCase() ? -1 : 1
                ),
              onFilter: (value, record) => record.category.indexOf(value) === 0,
              sorter: (a, b) => a.category.localeCompare(b.category),
              sortDirections: ["ascend", "descend"],
              fixed: "left",
              width: 150,
              align: "center" as any,
            },
            {
              title: t("common.characterization"),
              dataIndex: "characterization",
              fixed: "left",
              width: 200,
              align: "center" as any,
              render: (text: string) => (
                <Text
                  style={from === "drawer" ? { color: blue[6] } : {}}
                  strong
                >
                  {text}
                </Text>
              ),
            },
            {
              title: t("common.unit"),
              dataIndex: "unit",
              fixed: "left",
              width: 100,
              align: "center" as any,
            },
            ...formulationList.flatMap(
              (
                {
                  id_set: { formulation_id },
                  characterizations,
                  meta: { display_id },
                  use_for_ml,
                }: any,
                index: any
              ) =>
                characterizations.map(
                  ({
                    id_set: { processing_id, characterization_id },
                  }: any) => ({
                    title: () => (
                      <Space
                        style={{ width: "100%" }}
                        align="center"
                        size="small"
                        direction="vertical"
                      >
                        {checkbox && (
                          <Checkbox
                            checked={formulationsCheck?.includes(
                              formulation_id
                            )}
                            onChange={(e) => checkBoxChange(e, formulation_id)}
                          />
                        )}
                        <Typography.Title level={5} style={{ color: grey[1] }}>
                          {display_id}
                        </Typography.Title>
                        {checkbox &&
                          (use_for_ml ? (
                            <Space>
                              <Text>{t("common.successful")}</Text>
                              <Text>
                                <CheckCircleTwoTone
                                  style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                  twoToneColor="#52c41a"
                                />
                              </Text>
                            </Space>
                          ) : (
                            <Space>
                              <Text>{t("common.failed")}</Text>
                              <Text>
                                <CloseCircleTwoTone
                                  style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                  twoToneColor="#ff0000"
                                />
                              </Text>
                            </Space>
                          ))}
                      </Space>
                    ),
                    dataIndex: characterization_id,
                    width: 150,
                    align: "center" as any,
                  })
                )
            ),
          ]}
          dataSource={Array.from(
            new Set(
              formulationList.flatMap(({ characterizations }) =>
                Object.keys(
                  characterizations.reduce(
                    (acc: any, { characterizations }: any) => ({
                      ...acc,
                      ...characterizations,
                    }),
                    {}
                  )
                )
              )
            )
          )
            // .filter((key: string) =>
            //   formulationList.some(
            //     ({ characterizations }: any) =>
            //       !!characterizations?.[0]?.characterizations?.[key]?.value &&
            //       characterizations?.[0]?.characterizations?.[key]?.value !==
            //       "-"
            //   )
            // )
            .map((characterization: string) => ({
              category:
                characterizationLabels[characterization]?.category || "-",
              characterization:
                characterizationLabels[characterization]?.name ||
                characterization,
              unit:
                formulationList?.[0]?.characterizations?.[0]
                  ?.characterizations?.[characterization]?.unit ||
                characterizationLabels[characterization]?.unit?.[0] ||
                "-",
              ...formulationList.reduce(
                (acc, { characterizations: trials }: any) => ({
                  ...acc,
                  ...trials.reduce(
                    (acc: any, _characterizations: any) => ({
                      ...acc,
                      [_characterizations.id_set.characterization_id]:
                        getValue(
                          _characterizations?.characterizations?.[
                            characterization
                          ]?.value
                        ),
                    }),
                    {}
                  ),
                }),
                {}
              ),
            }))}
          scroll={{ x: 450 }}
        />
      </>
    );
  }
);

export const WOPropertiesComparisonTable = memo(
  ({
    formulationList,
    checkbox,
    checkBoxChange,
    formulationsCheck,
    from,
    tableId = "_property_table",
  }: TableProps) => {
    const dispatch = useDispatch();
    const propertyLabels = useSelector(
      (state: StoreState) => state.displayNames.data?.properties || {}
    );
    const total = useSelector(
      (state: StoreState) => state.workOrderDetails.total
    );
    
    const { getValue } = useValue()
    const [t] = useTranslate();
    const allMethodList = useSelector((state: StoreState) => state.repository.allMethods.data)

		const allMethodStatus = useSelector((state: StoreState) => state.repository.allMethods.status)
	
		
		useEffect(() => {
			if(allMethodStatus === AsyncStates.INITIAL){
				dispatch(fetchAllMethodsRequest())
			}
		}, [allMethodStatus, dispatch])

    const getPropertyCategory = useCallback((parameterName: string) => {
      const category = formulationList?.[0]?.properties?.[0]?.properties?.[parameterName]?.category;
      return category
    }, [formulationList]);


    // const getParametersString = useCallback((methodId) => {

    //   const paraString = allMethodList.find(method => method.method_id === methodId)?.parameters.map((param: any) => {
    //     if (param.value_type === "numerical") {
    //       if (param.value_subtype === "single") {
    //         return param.value + " " + param.unit
    //       }
    //       if (param.value_subtype === "operator") {
    //         return param.operator + param.value + " " + param.unit
    //       }
    //       if (param.value_subtype === "range") {
    //         return param.value + "-" + param.value_max + " " + param.unit
    //       }

    //     } else if (param.value_type === "categorical") {
    //       if (param.value_subtype === "single") {
    //         return param.value_str + " " + param.unit
    //       }
    //     }
    //     return "-"
    //   }).join(", ")
    //   return paraString
    // }, [allMethodList])

    const getPropertyLabel = useCallback((propertyId: any) => {
      const displayNamesProperty = propertyLabels?.[propertyId];
      const method = allMethodList?.find(
        (method) => method.method_id === displayNamesProperty?.method_id
      );
      return !!method
        ? InventoryUtils.getNameWithParameters(
            displayNamesProperty?.name,
            method.parameters,
            getValue
          )
        : InventoryUtils.getNameWithoutParameters(displayNamesProperty);
    }, [allMethodList, getValue, propertyLabels]);

    const propertiesTableData = useMemo(() => {
      const propertiesData =
        Object.entries(
          formulationList.reduce((acc, { properties }) => ({
            ...acc,
            ...properties.reduce(
              (acc: any, { properties }: any) => ({ ...acc, ...properties }),
              {}
            )
          }), {}
          )
        )
          // .filter((key: string) =>
          //   formulationList.some(
          //     ({ properties }: any) =>
          //       !!properties?.[0]?.properties?.[key]?.value &&
          //       properties?.[0]?.properties?.[key]?.value !== "-"
          //   )
          // )
          .map(([property, data]: [string, any]) => ({
            category: getPropertyCategory(property) || propertyLabels[property]?.category || "-",
            property: getPropertyLabel(property),
            unit:
              formulationList?.[0]?.properties?.[0]?.properties?.[property]
                ?.unit || "-",
            ...formulationList.reduce(
              (acc, { properties: trials }: any) => ({
                ...acc,
                ...trials.reduce(
                  (acc: any, _properties: any) => ({
                    ...acc,
                    [_properties.id_set.properties_id]: getValue(
                      _properties?.properties?.[property]?.value
                    ),
                  }),
                  {}
                ),
              }),
              {}
            ),
          }));

      return propertiesData;
    }, [formulationList, getPropertyCategory, propertyLabels, getValue, getPropertyLabel]);

    const propertyCategoryList = useMemo(() => {
      const propertyCategories = propertiesTableData.map((property) => property.category).filter((propertyCategory) => propertyCategory)
      return [...new Set(propertyCategories)]
    }, [propertiesTableData]);

    return (
      <>
        <div className="mb-1">
          <span className="highlighted">{t("common.totalTrials")}</span> {total}
        </div>
        <Table
          bordered
          size="small"
          pagination={false}
          id={tableId}
          getPopupContainer={() => (document as any).getElementById(tableId)}
          className="report-table"
          columns={[
            {
              title: t("common.category"),
              dataIndex: "category",
              filters: propertyCategoryList
                .map((property) => ({
                  text: String(property),
                  value: String(property),
                }))
                .sort((value1, value2) =>
                  value1.text.toLowerCase() < value2.text.toLowerCase() ? -1 : 1
                ),
              onFilter: (value, record) => record.category.indexOf(value) === 0,
              sorter: (a, b) => a.category.localeCompare(b.category),
              sortDirections: ["ascend", "descend"],
              fixed: "left",
              width: 150,
              align: "center" as any,
            },
            {
              title: t("common.property"),
              dataIndex: "property",
              fixed: "left",
              width: 200,
              align: "center" as any,
              sorter: (a, b) => a.property.localeCompare(b.property),
              filters: 
                propertiesTableData.map((pRow) => ({
                  text: String(pRow.property),
                  value: String(pRow.property),
                }))
                .sort((value1, value2) =>
                  value1.text.toLowerCase() < value2.text.toLowerCase() ? -1 : 1
                ),
              onFilter: (value, record) => record.property.indexOf(value) === 0,
              sortDirections: ["ascend", "descend"],
              render: (text: string) => (
                <Text
                  style={from === "drawer" ? { color: blue[6] } : {}}
                  strong
                >
                  {text}
                </Text>
              ),
            },
            {
              title: t("common.unit"),
              dataIndex: "unit",
              fixed: "left",
              width: 100,
              align: "center" as any,
            },
            ...formulationList.flatMap(
              (
                {
                  id_set: { formulation_id },
                  properties,
                  meta: { display_id },
                  use_for_ml,
                }: any,
                index: any
              ) =>
                properties.map(
                  ({ id_set: { processing_id, properties_id } }: any) => ({
                    title: () => (
                      <Space
                        style={{ width: "100%" }}
                        align="center"
                        size="small"
                        direction="vertical"
                      >
                        {checkbox && (
                          <Checkbox
                            checked={formulationsCheck?.includes(
                              formulation_id
                            )}
                            onChange={(e) => checkBoxChange(e, formulation_id)}
                          />
                        )}
                        <Typography.Title level={5} style={{ color: grey[1] }}>
                          {display_id}
                        </Typography.Title>
                        {checkbox &&
                          (use_for_ml ? (
                            <Space>
                              <Text>{t("common.successful")}</Text>
                              <Text>
                                <CheckCircleTwoTone
                                  style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                  twoToneColor="#52c41a"
                                />
                              </Text>
                            </Space>
                          ) : (
                            <Space>
                              <Text>{t("common.failed")}</Text>
                              <Text>
                                <CloseCircleTwoTone
                                  style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                  twoToneColor="#ff0000"
                                />
                              </Text>
                            </Space>
                          ))}
                      </Space>
                    ),
                    dataIndex: properties_id,
                    width: 150,
                    align: "center" as any,
                  })
                )
            ),
          ]}
          dataSource={propertiesTableData}
          scroll={{ x: 450 }}
        />
      </>
    );
  }
);

export const OutputCostingTable = ({ formulationList }: any) => {
  const [t] = useTranslate()
  const ingredients = useSelector(
    (state: StoreState) => state.displayNames.data.ingredients
  );
  const processingProfiles = useSelector(
    (state: StoreState) => state.workOrderDetails.processingProfilesList
  );
  const total = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );
  const { getVariationDetails } = useMethodDetails();
  const { getValue } = useValue()

  const columns = useMemo(
    () => [
      {
        title: "Parameter",
        dataIndex: "parameter",
        fixed: "left",
        width: 250,
        render: (text: any) => <Text strong>{text}</Text>,
      },
      ...formulationList.map(({ id_set, meta }: any) => ({
        title: meta?.display_id,
        dataIndex: id_set?.formulation_id,
        align: "center" as any,
        width: 150,
        render: (text: any) =>
          text ? getValue(text, 3) : "Not Defined",
      })),
    ],
    [formulationList, getValue]
  );

  const dataSource: any = useMemo(() => {
    const parameters = [
      { parameter: "Yield/Batch" },
      { parameter: "Total RM Cost/Batch" },
      { parameter: "Cost of MBE/Ton" },
      { parameter: "Additional Yield (kg)/Ton" },
      { parameter: "Cost MBE Accounting for Additional Yield" },
      { parameter: "Cost Impact Total (%)" },
    ];
    formulationList.forEach((trial: any) => {
      let yield_batch: any,
        total_rm_cost_batch: any,
        cost_of_mbe_ton: any,
        additional_yield_kg_ton: any,
        cost_mbe_accounting_for_additional_yield: any,
        cost_impact_total: any;
      const profile = processingProfiles.find(
        (profile: any) =>
          profile?.profile_id === trial?.processing?.[0]?.meta?.profile_id
      );
      if (["Profile A", "Profile J"].includes(profile?.name)) {
        const currentProfile = profile?.costing;
        const bom_kg = currentProfile.find(
          (res: any) => res?.parameter === "BOM (kg)"
        )?.value;
        const cost_kg_of_bom = currentProfile.find(
          (res: any) => res?.parameter === "Cost/kg of BOM"
        )?.value;
        const factory_yield = currentProfile.find(
          (res: any) => res?.parameter === "Factory Yield (%)"
        )?.value;
        const refrernce_dry_matter_yield = currentProfile.find(
          (res: any) => res?.parameter === "Reference Dry Matter Yield (%)"
        )?.value;
        const refrernce_yield_batch = currentProfile.find(
          (res: any) => res?.parameter === "Reference Yield/Batch"
        )?.value;
        const reference_cost_of_mbe_ton = currentProfile.find(
          (res: any) => res?.parameter === "Reference Cost of MBE/ton"
        )?.value;
        const total_conversion_cost_ton = currentProfile.find(
          (res: any) => res?.parameter === "Total Conversion Cost/Ton"
        )?.value;
        const total_solids = currentProfile.find(
          (res: any) => res?.parameter === "Total Solids"
        )?.value;
        const yield_pct = trial?.properties?.[0]?.properties.find(
          (variation: any) =>
            getVariationDetails(variation?.variation_id).variationName ===
            "Final Product (Without Leaching)"
        )?.data?.["yield_pct"]?.value;

        if (
          profile?.name === "Profile A" &&
          !isNaN(bom_kg) &&
          !!bom_kg &&
          !isNaN(refrernce_dry_matter_yield) &&
          !!refrernce_dry_matter_yield &&
          !isNaN(refrernce_yield_batch) &&
          !!refrernce_yield_batch &&
          !isNaN(yield_pct) &&
          !!yield_pct
        ) {
          yield_batch =
            (((bom_kg * refrernce_dry_matter_yield) / factory_yield) *
              yield_pct) /
            100 /
            refrernce_yield_batch;
        }
        if (
          profile?.name === "Profile J" &&
          !isNaN(bom_kg) &&
          !!bom_kg &&
          !isNaN(total_solids) &&
          !!total_solids &&
          !isNaN(yield_pct) &&
          !!yield_pct &&
          !isNaN(factory_yield) &&
          !!factory_yield
        ) {
          yield_batch =
            (((bom_kg * total_solids * yield_pct) / 100) * 17272 * 0.88 * 1) /
            factory_yield;
        }
        const ingredientSum = Object.entries(trial?.ingredients || {})
          .filter(([key, value]: any) => !!value?.value && !isNaN(value?.value))
          .reduce(
            (sum: any, [key, value]: any) =>
              Number(value?.value) *
              Number(ingredients?.[key]?.costing?.amount) +
              sum,
            0
          );
        if (
          !isNaN(bom_kg) &&
          !!bom_kg &&
          !isNaN(ingredientSum) &&
          !!ingredientSum &&
          !isNaN(cost_kg_of_bom) &&
          !!cost_kg_of_bom
        ) {
          if (profile?.name === "Profile A") {
            total_rm_cost_batch =
              (ingredientSum * bom_kg) / 100 + bom_kg * cost_kg_of_bom;
          }
          if (
            profile?.name === "Profile J" &&
            !!ingredients?.["enzyme_4"]?.costing?.amount
          ) {
            total_rm_cost_batch =
              (ingredientSum * bom_kg) / 100 +
              bom_kg * cost_kg_of_bom +
              17272 * 0.618 +
              ((17272 * 0.05) / 100) *
              ingredients?.["enzyme_4"]?.costing?.amount;
          }
        }

        if (
          !isNaN(total_rm_cost_batch) &&
          !!total_rm_cost_batch &&
          !isNaN(yield_batch) &&
          !!yield_batch
        ) {
          cost_of_mbe_ton = (total_rm_cost_batch / yield_batch) * 10000;
        }

        if (
          !isNaN(yield_batch) &&
          !!yield_batch &&
          !isNaN(refrernce_yield_batch) &&
          !!refrernce_yield_batch &&
          !isNaN(total_conversion_cost_ton) &&
          !!total_conversion_cost_ton
        ) {
          additional_yield_kg_ton =
            (yield_batch - refrernce_yield_batch) /
            (refrernce_yield_batch * 1000 * total_conversion_cost_ton);
        }

        if (
          !isNaN(cost_of_mbe_ton) &&
          !!cost_of_mbe_ton &&
          !isNaN(additional_yield_kg_ton) &&
          !!additional_yield_kg_ton
        ) {
          cost_mbe_accounting_for_additional_yield =
            cost_of_mbe_ton - additional_yield_kg_ton;
        }

        if (
          !isNaN(cost_mbe_accounting_for_additional_yield) &&
          !!cost_mbe_accounting_for_additional_yield &&
          !isNaN(reference_cost_of_mbe_ton) &&
          !!reference_cost_of_mbe_ton
        ) {
          cost_impact_total =
            ((cost_mbe_accounting_for_additional_yield -
              reference_cost_of_mbe_ton) /
              reference_cost_of_mbe_ton) *
            100;
        }

        parameters.forEach((res: any) => {
          const { parameter } = res;
          if (parameter === "Yield/Batch") {
            res[trial.id_set.formulation_id] = getValue(yield_batch);
          } else if (parameter === "Total RM Cost/Batch") {
            res[trial.id_set.formulation_id] =
              getValue(total_rm_cost_batch);
          } else if (parameter === "Cost of MBE/Ton") {
            res[trial.id_set.formulation_id] =
              getValue(cost_of_mbe_ton);
          } else if (parameter === "Additional Yield (kg)/Ton") {
            res[trial.id_set.formulation_id] = getValue(
              additional_yield_kg_ton,
              5
            );
          } else if (parameter === "Cost MBE Accounting for Additional Yield") {
            res[trial.id_set.formulation_id] = getValue(
              cost_mbe_accounting_for_additional_yield
            );
          } else if (parameter === "Cost Impact Total (%)") {
            res[trial.id_set.formulation_id] =
              getValue(cost_impact_total);
          }
        });
      }
    });
    return parameters;
  }, [formulationList, processingProfiles, ingredients, getVariationDetails, getValue]);

  return (
    <>
      <div className="mb-1">
        <span className="highlighted">{t("common.totalTrials")}</span> {total}
      </div>
      <Table
        size="small"
        columns={columns as any}
        className="report-table"
        dataSource={dataSource}
        pagination={false}
        bordered
        scroll={{ x: 450 }}
      />
    </>
  );
};

export const FormulationsCostingTable = ({ formulationList }: any) => {
  const [t] = useTranslate()

  const ingredients = useSelector(
    (state: StoreState) => state.displayNames.data.ingredients
  );
  const linkedTrials = useSelector(
    (state: StoreState) => state.workOrderDetails.linkedExperiment
  );
  const { getValue } = useValue()

  const columns = useMemo(
    () => [
      {
        title: "Parameter",
        dataIndex: "parameter",
        fixed: "left",
        className: "table-name-column",
        width: 250,
        render: (text: any) => {
          const trialName = linkedTrials.find(
            (trial) => trial.formulation_id === text
          )?.formulation_display_id;
          return (
            <Text strong>{ingredients?.[text]?.name || trialName || text}</Text>
          );
        },
      },
      ...formulationList.map(({ id_set, meta }: any) => ({
        title: meta?.display_id,
        dataIndex: id_set?.formulation_id,
        align: "center" as any,
        width: 150,
        render: (text: any) =>
          text ? getValue(text, 3) : "Not Defined",
      })),
    ],
    [formulationList, ingredients, linkedTrials, getValue]
  );

  const dataSource: any = useMemo(() => {
    const parameters = [
      ...new Set(
        formulationList.flatMap((res: any) =>
          Object.keys(res?.ingredients || {})
        )
      ),
    ]
      .filter((key: any) =>
        formulationList.some(
          ({ ingredients }: any) =>
            !!ingredients?.[key]?.value && ingredients?.[key]?.value !== "-"
        )
      )
      .map((res: any) => ({ parameter: res }));
    parameters.forEach((res: any) => {
      formulationList.forEach((trial: any, index: number) => {
        const substrate =
          linkedTrials.filter((key: any) => !!key?.access)?.[index]
            ?.ingredients?.["substrate"]?.value ||
          linkedTrials.filter((key: any) => !!key?.access)?.[0]?.ingredients?.[
            "substrate"
          ]?.value;
        let cost;
        if (!trial?.ingredients?.[res?.parameter]?.value) {
          res[trial.id_set.formulation_id] = "-";
        } else if (!ingredients?.[res?.parameter]?.costing?.amount) {
          res[trial.id_set.formulation_id] = "Not Defined";
        }
        if (
          !!trial?.ingredients?.[res?.parameter]?.value &&
          !!ingredients?.[res?.parameter]?.costing?.amount &&
          !!substrate
        ) {
          cost =
            (((Number(trial?.ingredients?.[res?.parameter]?.value) *
              Number(ingredients?.[res?.parameter]?.costing?.amount)) /
              1000) *
              substrate) /
            100;
        }
        if (!!cost) {
          res[trial.id_set.formulation_id] = getValue(cost, 5);
        }
      });
    });
    return parameters;
  }, [formulationList, linkedTrials, ingredients, getValue]);

  const { totalRow: costingTotal } = useTotal(dataSource, {
    parameter: "Total",
  });

  const total = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );

  return (
    <>
      <div className="mb-1">
        <span className="highlighted">{t("common.totalTrials")}</span> {total}
      </div>
      <Table
        size="small"
        columns={columns as any}
        className="report-table"
        dataSource={
          dataSource?.length
            ? [...dataSource, { ...costingTotal, key: dataSource.length }]
            : []
        }
        pagination={false}
        bordered
        scroll={{ x: 450 }}
        rowClassName={(record, index) => {
          if (record.parameter === "Total") {
            return "highlight-row";
          }
          return "";
        }}
      />
    </>
  );
};

export const ProcessingCostingTable = ({ formulationList }: any) => {
  const [t] = useTranslate()

  const processingProfiles = useSelector(
    (state: StoreState) => state.workOrderDetails.processingProfilesList
  );
  const total = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );
  const { getValue } = useValue()

  const columns = useMemo(
    () => [
      {
        title: "Parameter",
        dataIndex: "parameter",
        fixed: "left",
        className: "table-name-column",
        width: 250,
        render: (text: any) => <Text strong>{text}</Text>,
      },
      ...formulationList.map(({ id_set, meta }: any) => ({
        title: meta?.display_id,
        dataIndex: id_set?.formulation_id,
        align: "center" as any,
        width: 150,
        render: (text: any) =>
          text ? getValue(text, 3) : "Not Defined",
      })),
    ],
    [formulationList, getValue]
  );

  const dataSource: any = useMemo(() => {
    const parameters: any = [
      { parameter: "BOM (kg)", value: "" },
      { parameter: "Cost/kg of BOM", value: "" },
      { parameter: "Factory Yield (%)", value: "" },
      { parameter: "Reference Dry Matter Yield (%)", value: "" },
      { parameter: "Reference Yield/Batch", value: "" },
      { parameter: "Total Conversion Cost/Ton", value: "" },
      { parameter: "Total RM Cost/Batch", value: "" },
      { parameter: "Reference Cost of MBE/ton", value: "" },
      { parameter: "Total Solids", value: "" },
    ];
    parameters.forEach((res: any) => {
      formulationList.forEach((trial: any, index: number) => {
        const currentProfile = processingProfiles.find(
          (res: any) =>
            res?.profile_id === trial?.processing?.[0]?.meta?.profile_id
        )?.costing;
        if (!!currentProfile?.length) {
          res[trial?.id_set?.formulation_id] = currentProfile?.find(
            (key: any) => key?.parameter === res.parameter
          )?.value;
        } else {
          res[trial?.id_set?.formulation_id] = "";
        }
      });
    });
    return parameters;
  }, [formulationList, processingProfiles]);

  return (
    <>
      <div className="mb-1">
        <span className="highlighted">{t("common.totalTrials")}</span> {total}
      </div>
      <Table
        size="small"
        columns={columns as any}
        className="report-table"
        dataSource={dataSource}
        pagination={false}
        bordered
        scroll={{ x: 450 }}
      />
    </>
  );
};


