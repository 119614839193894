import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { motion } from "framer-motion";
import {
    Anchor, Col, Divider, Row, Space, Descriptions, Select, Spin, Checkbox, message, Card, Popover, Empty, Segmented
} from 'antd';
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader';
import useTranslate from 'src/utils/useTranslate';
import { SelectWorkOrderForm } from '../../AIEngine/DataSummary/form';
import { StoreState } from 'src/store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { TabKeys } from '../../AIEngine/DataSummary';
import { antdTheme, AsyncStates } from 'src/constants';
import { workOrdersRequest } from 'src/store/actions/workOrders';
import { Summaries, SummaryWrapper } from '../../AIEngine/DataSummary/tabs/summaries';
import Title from 'antd/es/typography/Title';
import { DisplayNames } from 'src/typings';
import { CloseOutlined, DotChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { analyticsRecipeDistributionClear, analyticsRecipeDistributionTableClear, boxPlotSummaryClear, dataSummaryClear, dataSummaryRequest, dataSummarySuccess, formulationsDataFromCompareClear, getPlotsHistoryRequest, getZeonCharacterizationDsmRequest, surfacePlotClear } from 'src/store/actions/dataSummary';
import { catDataSummaryClear, catDataSummaryRequest } from 'src/store/actions/catDataSummary';
import { pivotTableClear } from 'src/store/actions/pivotTable';
import { DataAnalyticsGraphs } from './DataAnalyticsGraphs';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PlotType } from '../enums';
import "./DataAnalytics.scss"
import { SaveGraphsModal } from './SaveGraphsModal';
import { GraphHistoryDrawer } from './GraphHistoryDrawer';
import { Unsubscribe, doc, onSnapshot, } from "firebase/firestore";
import { FB_COLLECTION_HEATMAP_COLLECTION, firestoreDb } from 'src/utils/firebase';
import { linkedFormulationsAiEngineClear } from 'src/store/actions/common';
import { useQuery } from 'src/utils/useQuery';
import { history } from 'src';
import { StyledButton } from 'src/styled_components/StyledButton';
const { Link } = Anchor


export const DataAnalytics = () => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const workOrdersList = useSelector((state: StoreState) => state.workOrders.data)
    const { current: currentProject } = useSelector((state: StoreState) => state.projects);
    const {
        data: numericalSummary,
        numericalSummaryStatus,
        error: numericalSummaryError,
        selectedFormulationsDataFromCompare: { selectedFormulationIds = [] } = {},
        correlationStatus
    } = useSelector((state: StoreState) => state.dataSummary)
    let query = useQuery();

    const isFromCompareFormulation = Boolean(query?.get("source") === "compare-formulation")
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames
    const {
        data: categoricalSummary,
        status: categoricalSummaryStatus,
        error: categoricalSummaryError,
    } = useSelector((state: StoreState) => state.catDataSummary)
    const project_id = useSelector((state: StoreState) => state.projects.current)
    const [workOrderIds, setWorkOrderIds] = useState<string[]>([])

    const expIds = useMemo(() => workOrdersList.filter(wo => workOrderIds.includes(wo.work_order_id)).flatMap(wo => wo.experiment_id), [workOrdersList, workOrderIds])
    const configs = useSelector((state: StoreState) => state.configs.features)
    const [characterizationMethodId, setCharacterizationMethodId] = useState<string>("")
    const [tab, setTab] = useState<TabKeys>("overview")
    const isErrorShowed = useRef(false)
    const [selectedVariation, setSelectedVariation] = useState<any>({})
    const [normalise, setNormalise] = useState(false)
    const [stageIdentifier, setStageIdentifier] = useState<string[]>([])
    const [selectedExperiments, setSelectedExperiments] = useState<string[]>([])
    const [showSaveGraphsModal, setShowSaveGraphsModal] = useState(false)
    const [selectedPlotsData, setSelectedPlotsData] = useState({})
    const [saveGraphsPayload, setSaveGraphsPayload] = useState({})
    const [showgraphsListDrawer, setShowGraphsListDrawer] = useState(false)
    const [selectedPlots, setSelectedPlots] = useState<CheckboxValueType[]>([])
    const [tempSelectedPlots, setTempSelectedPlots] = useState(selectedPlots)
    const [selectedPlotsPopover, setSelectedPlotsPopover] = useState<boolean>(false)
    const [selectedFormulations, setSelectedFormulations] = useState<string[]>([])

    const plotOptions: { [key: string]: string } = useMemo(() => ({
        ...(!!Object.keys(numericalSummary?.scatter_plot?.data || {}).length && { [PlotType.SCATTER_PLOT]: t("graphs.scatterPlot") }),
        [PlotType.SURFACE_PLOT]:  t("graphs.surfacePlot"),
        ...(!Boolean(configs?.ai_engine_with_methods) && { [PlotType.RECIPE_DISTRIBUTION]:  t("graphs.recipeDistribution") }),
        [PlotType.PIVOT_ANALYSIS]:  t("graphs.pivotAnalysis"),
        ...((!!categoricalSummary?.divided_columns?.original_categorical?.length && Object.values(categoricalSummary?.divided_columns?.Numerical || {}).length) && { [PlotType.VISUALIZE_DISTRIBUTION]:  t("graphs.visualizeDistribution") })
    }), [categoricalSummary?.divided_columns?.Numerical, categoricalSummary?.divided_columns?.original_categorical?.length, configs?.ai_engine_with_methods, numericalSummary?.scatter_plot?.data, t])

    useEffect(() => {
        if (!isFromCompareFormulation) {
            setSelectedFormulations([])
            dispatch(
                workOrdersRequest({
                    project_id: currentProject,
                    closed_stages: true,
                })
            );
        }
    }, [dispatch, currentProject, isFromCompareFormulation]);

    useEffect(() => {
        if (isFromCompareFormulation) {
            if (!!selectedFormulationIds?.length) {
                setSelectedFormulations(selectedFormulationIds ?? [])
            }
        }
    }, [isFromCompareFormulation, selectedFormulationIds,])

    useEffect(() => {
        if (isFromCompareFormulation && !selectedFormulationIds.length) {
            history.push("/data-analytics")
            setSelectedFormulations([])
        }
    }, [isFromCompareFormulation, selectedFormulationIds])

    const options = useMemo(() => {
        return [{
            value: "overview",
            label: t("projects.overview")
        },
        {
            value: "ingredients",
            label: t("common.ingredients"),
        },
        {
            value: "processing",
            label: t("common.processing"),
        },
        {
            value: "properties",
            label: t("formulations.type.properties"),
        }]
    }, [t])

    useEffect(() => {
        if (!workOrderIds.length && !isFromCompareFormulation) {
            setSelectedExperiments([])
            setShowData(false)
        }
    }, [workOrderIds.length, isFromCompareFormulation])

    useEffect(() => {
        let unsub: Unsubscribe;
        const listenToHeatmaps = async () => {
            try {
                const heatMapsQuery = doc(firestoreDb, `${FB_COLLECTION_HEATMAP_COLLECTION}/${numericalSummary?.correlation_task_key}`);
                unsub = onSnapshot(heatMapsQuery, (doc) => {
                    const dataSummaryResponse = doc.data()
                    if (dataSummaryResponse?.status === "completed") {
                        dispatch(dataSummarySuccess({
                            data: JSON.parse(dataSummaryResponse.data), correlationStatus: {
                                apiStatus: AsyncStates.SUCCESS,
                                errorMessage: dataSummaryResponse.message
                            }
                        }))
                    } else if (dataSummaryResponse?.status === "failed") {
                        dispatch(dataSummarySuccess({
                            data: JSON.parse(dataSummaryResponse.data),
                            correlationStatus: {
                                apiStatus: AsyncStates.ERROR,
                                errorMessage: dataSummaryResponse?.message
                            }
                        }))
                    }
                });
            } catch (error) {
            }
        }

        if (numericalSummaryStatus === AsyncStates.SUCCESS && categoricalSummaryStatus === AsyncStates.SUCCESS && numericalSummary?.correlation_task_key) {
            listenToHeatmaps()
        }

        return () => {
            unsub && unsub();
        };
    }, [categoricalSummaryStatus, dispatch, numericalSummary?.correlation_task_key, numericalSummaryStatus]);

    useEffect(() => {
        return () => {
            setWorkOrderIds([])
            setStageIdentifier([])
            setTab("overview")
            setNormalise(false)
            setSelectedFormulations([])
            dispatch(dataSummaryClear())
            dispatch(boxPlotSummaryClear())
            dispatch(catDataSummaryClear())
            dispatch(pivotTableClear())
            setSelectedPlots([])
            setTempSelectedPlots([])
            dispatch(surfacePlotClear())
            dispatch(analyticsRecipeDistributionClear())
            dispatch(analyticsRecipeDistributionTableClear())
            setShowData(false)
            setSelectedPlotsData({})
            setSaveGraphsPayload({})
            setShowSaveGraphsModal(false)
            dispatch(linkedFormulationsAiEngineClear())
            setShowGraphsListDrawer(false)
            if (isFromCompareFormulation) {
                setSelectedFormulations([])
                dispatch(formulationsDataFromCompareClear())
            }
        }
    }, [dispatch, isFromCompareFormulation, project_id])

    useEffect(() => {
        if (categoricalSummaryStatus === AsyncStates.LOADING && numericalSummaryStatus === AsyncStates.LOADING) {
            dispatch(pivotTableClear())
            setSelectedPlots([])
            setTempSelectedPlots([])
            dispatch(surfacePlotClear())
            dispatch(analyticsRecipeDistributionClear())
            dispatch(analyticsRecipeDistributionTableClear())
            setShowData(false)
        }
    }, [dispatch, categoricalSummaryStatus, numericalSummaryStatus])

    useEffect(() => {
        if (!selectedPlots.length) {
            setSelectedPlotsData({})
            setSaveGraphsPayload({})
        }
    }, [selectedPlots.length])

    useEffect(() => {
        if (Boolean(configs?.ai_engine_with_methods)) {
            dispatch(getZeonCharacterizationDsmRequest({
                project_id: project_id,
                characterization_method_id: "",
            }))
        }
    }, [configs, dispatch, project_id])

    useEffect(() => {
        if (isErrorShowed.current) return
        if (numericalSummaryStatus === AsyncStates.ERROR) {
            isErrorShowed.current = true
            setNormalise(false)
            message.error(numericalSummaryError)
        }
    }, [dispatch, numericalSummaryError, numericalSummaryStatus])

    useEffect(() => {
        if (isErrorShowed.current) return
        if (categoricalSummaryStatus === AsyncStates.ERROR) {
            isErrorShowed.current = true
            setNormalise(false)
            message.error(categoricalSummaryError)
        }
    }, [categoricalSummaryError, categoricalSummaryStatus, dispatch])

    const handleViewChange = (activeKey: TabKeys) => {
        setTab(activeKey as TabKeys)
        const zeonCharacterizationMethodId = (Boolean(configs?.characterization_methods)) && {
            characterization_set_id: selectedVariation?.characterization_set_id,
        }
        dispatch(boxPlotSummaryClear())
        dispatch(
            dataSummaryRequest(
                activeKey === "overview"
                    ? {
                        ...zeonCharacterizationMethodId, normalise, ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                            ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : {
                                experiment_ids: selectedExperiments ?? []
                            }),
                        }), with_processing_profile: (Boolean(configs?.processing_profiles) ? true : undefined)
                    }
                    : {
                        type: activeKey, ...zeonCharacterizationMethodId, normalise, ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                            ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : {
                                experiment_ids: selectedExperiments ?? []
                            }),
                        }), with_processing_profile: (Boolean(configs?.processing_profiles) ? true : undefined)
                    }
            )
        )
        dispatch(
            catDataSummaryRequest(
                activeKey === "overview"
                    ? {
                        ...zeonCharacterizationMethodId, normalise, ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                            ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : {
                                experiment_ids: selectedExperiments ?? []
                            }),
                        }), with_processing_profile: (Boolean(configs?.processing_profiles) ? true : undefined)
                    }
                    : {
                        type: activeKey, ...zeonCharacterizationMethodId, normalise, ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                            ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : {
                                experiment_ids: selectedExperiments ?? []
                            }),
                        }), with_processing_profile: (Boolean(configs?.processing_profiles) ? true : undefined)
                    }
            )
        )
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setTempSelectedPlots(e.target.checked ? Object.keys(plotOptions) : []);
    };


    const mergedData = useMemo(() => {
        const data = Object.values(numericalSummary?.data_summary || {}).reduce(
            (acc, curr: any) => ({
                ...acc,
                ...curr,
                ...curr?.costing,
            }),
            {},
        );
        const filteredEntries = Object.entries(data || {}).filter(
            ([key, value]) => typeof value !== "undefined",
        );
        return Object.fromEntries(filteredEntries);
    }, [numericalSummary]);

    const anchorLinksList = useMemo(() => {
        let links: { title: string, id: string }[] = [];
        if (numericalSummaryStatus === AsyncStates.SUCCESS && categoricalSummaryStatus === AsyncStates.SUCCESS &&
            categoricalSummary !== null &&
            numericalSummary !== null
        ) {
            links = [
                {
                    title: t("common.selectWorkOrder"),
                    id: "#select-work-order",
                },
                {
                    title: t("dataSummary.datasetSize"),
                    id: "#dataset-size",
                },
                {
                    title: t("dataSummary.columnDataTypeDistribution"),
                    id: "#column-data-type-distribution",
                },
            ];
            if (Object.keys(mergedData).length > 0) {
                links.push({
                    title: t("dataSummary.numericalDataSummary"),
                    id: "#numerical-data-summary",
                });
            }
            if (!!Object.keys(categoricalSummary?.describe_summary || {}).length) {
                links.push({
                    title: t("dataSummary.categoricalDataSummary"),
                    id: "#categorical-data-summary",
                });
            }
            if (!(!normalise && Boolean(configs?.nomralised_data_summary) && (tab === "overview" || (!!(numericalSummary as any)?.correlation_summary?.[tab as any]?.length)))) {
                links.push({
                    title: t("dataSummary.numericalCorrelationHeatmaps"),
                    id: "#numerical-heat-maps",
                });
            }
            if (true) {
                links.push({
                    title: t("common.graphs"),
                    id: "#graphs",
                });
            }
            if (selectedPlots.includes(PlotType.SCATTER_PLOT) && !!Object.keys(numericalSummary?.scatter_plot?.data || {}).length) {
                links.push({
                    title: t("dataSummary.scatterPlot"),
                    id: `#${PlotType.SCATTER_PLOT}`,
                });
            }
            if (selectedPlots.includes(PlotType.SURFACE_PLOT)) {
                links.push({
                    title: t("dataSummary.surfacePlot"),
                    id: `#${PlotType.SURFACE_PLOT}`,
                });
            }


            if (selectedPlots.includes(PlotType.RECIPE_DISTRIBUTION)) {
                links.push({
                    title: t("aiEngine.RecipesDistribution"),
                    id: "#recipes-distribution",
                });
            }

            if (selectedPlots.includes(PlotType.PIVOT_ANALYSIS)) {
                links.push({
                    title: t("dataSummary.pivotAnalysis"),
                    id: `#${PlotType.PIVOT_ANALYSIS}`,
                });
            }

            if (selectedPlots.includes(PlotType.VISUALIZE_DISTRIBUTION) && (!!categoricalSummary.divided_columns.original_categorical?.length &&
                Object.values(categoricalSummary?.divided_columns?.Numerical || {}).length)) {
                links.push({
                    title: t("dataSummary.visualizeDistributions"),
                    id: `#${PlotType.VISUALIZE_DISTRIBUTION}`,
                });
            }
        }
        return links;
    }, [numericalSummaryStatus, categoricalSummaryStatus, categoricalSummary, numericalSummary, t, mergedData, normalise, configs?.nomralised_data_summary, selectedPlots, tab]);

    const [showData, setShowData] = useState(false)

    useEffect(() => {
        if ((numericalSummaryStatus === AsyncStates.LOADING && categoricalSummaryStatus === AsyncStates.LOADING) || (isFromCompareFormulation && numericalSummaryStatus === AsyncStates.SUCCESS && categoricalSummaryStatus === AsyncStates.SUCCESS)) {
            setShowData(true)
        }
    }, [categoricalSummaryStatus, isFromCompareFormulation, numericalSummaryStatus])

    const normalizeOptions = [{
        value: "actual",
        label: "Actual Data",
        title: "Actual Data",
    },
    {
        value: "normalise",
        label: "Normalized Data",
        title: "Normalized Data",
    }]

    const getDisplayName = useCallback(
        (value: string) => {
            return (
                names.ingredients?.[value]?.name ??
                names.processing?.[value]?.name ??
                names.properties?.[value]?.name ??
                categoricalSummary?.["formulation_display_names"][value] ??
                numericalSummary?.["formulation_display_names"][value] ??
                value
            );
        },
        [names, categoricalSummary, numericalSummary],
    );

    const handlePlotchanges = useCallback((selectedPlots: any[]) => {
        if (!!Object.keys(selectedPlotsData || {}).length) {
            setSelectedPlotsData((prev: any) => {
                const data = selectedPlots.reduce((result: any, key: any) => {
                    const plotData = prev?.data || {}
                    if (plotData?.plot_data?.[key] !== undefined) {
                        return {
                            ...result,
                            plot_data: {
                                ...plotData?.plot_data,
                                [key]: plotData?.plot_data[key]
                            },
                        }
                    }
                    return result;
                }, {});
                return { ...prev, data }
            })
        }
        if (!!Object.keys(saveGraphsPayload || {}).length) {
            setSaveGraphsPayload((prev: any) => {
                const graphpayload = selectedPlots.reduce((result: any, key: any) => {
                    if (prev[key] !== undefined) {
                        result[key] = prev[key];
                    }
                    return result;
                }, {});
                return graphpayload
            })
        }
    }, [saveGraphsPayload, selectedPlotsData])

    return (
        <>
            <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ type: 'just' }}
            >
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <StyledPageHeader title={t("aiEngine.tab.dataSummary")} ghost={false}/>
                    <Row justify="space-between">
                        <Col span={20} id='data__summmary__container'>
                            <div id="select-work-order">
                                <SelectWorkOrderForm
                                    isErrorShowed={isErrorShowed}
                                    setTab={setTab}
                                    setWorkOrderIds={setWorkOrderIds}
                                    workOrderIds={workOrderIds}
                                    expIds={expIds}
                                    workOrders={workOrdersList}
                                    setCharacterizationMethodId={setCharacterizationMethodId}
                                    characterizationMethodId={characterizationMethodId}
                                    setSelectedVariation={setSelectedVariation}
                                    selectedVariation={selectedVariation}
                                    normalise={normalise}
                                    selectedStages={stageIdentifier}
                                    setStageIdentifier={setStageIdentifier}
                                    setSelectedExperiments={setSelectedExperiments}
                                    setNormalise={setNormalise}
                                    selectedFormulations={selectedFormulations}
                                    setSelectedFormulations={setSelectedFormulations}
                                />
                                {showData &&
                                    <>
                                        <Divider />
                                        <Spin
                                            spinning={
                                                numericalSummaryStatus === AsyncStates.LOADING || categoricalSummaryStatus === AsyncStates.LOADING
                                            }
                                            indicator={<LoadingOutlined />}
                                            wrapperClassName="helloworld"
                                            tip={<div>{t("dataAnalytics.loading")}</div>}
                                        >
                                            {(
                                                <Card style={{
                                                    marginBottom: "2.5rem",
                                                    opacity: (numericalSummaryStatus === AsyncStates.LOADING || categoricalSummaryStatus === AsyncStates.LOADING) ? 0.2 : 1,
                                                    transition: 'opacity 0.5s ease-in',
                                                }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: "center" }}>
                                                        {(categoricalSummary !== null && numericalSummary !== null) &&
                                                            <Select
                                                                style={{ width: "10rem" }}
                                                                optionFilterProp="children"
                                                                showSearch
                                                                value={tab}
                                                                onChange={(e: TabKeys) => {
                                                                    handleViewChange(e)
                                                                }}
                                                                options={options}
                                                                dropdownRender={(menu) => {
                                                                    return (
                                                                        <Spin
                                                                            spinning={numericalSummaryStatus === AsyncStates.LOADING && categoricalSummaryStatus === AsyncStates.LOADING}
                                                                            indicator={<LoadingOutlined />}
                                                                        >
                                                                            <div>{menu}</div>
                                                                        </Spin>
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        {Boolean(configs?.nomralised_data_summary) &&
                                                            <>
                                                                <Segmented options={normalizeOptions} value={normalise ? "normalise" : "actual"} onChange={(e) => {
                                                                    dispatch(boxPlotSummaryClear())
                                                                    dispatch(
                                                                        dataSummaryRequest(
                                                                            tab === "overview"
                                                                                ? {
                                                                                    normalise: e === "normalise" ? true : false,
                                                                                    ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                                                                                        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : {
                                                                                            experiment_ids: selectedExperiments ?? []
                                                                                        }),
                                                                                    }),
                                                                                }
                                                                                : {
                                                                                    type: tab,
                                                                                    normalise: e === "normalise" ? true : false,
                                                                                    ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                                                                                        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments ?? [] }),
                                                                                    }),
                                                                                }
                                                                        )
                                                                    )
                                                                    dispatch(
                                                                        catDataSummaryRequest(
                                                                            tab === "overview"
                                                                                ? {
                                                                                    normalise: e === "normalise" ? true : false,
                                                                                    ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                                                                                        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments ?? [] }),
                                                                                    }),
                                                                                }
                                                                                : {
                                                                                    type: tab,
                                                                                    normalise: e === "normalise" ? true : false,
                                                                                    ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
                                                                                        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments ?? [] }),
                                                                                    })
                                                                                }
                                                                        )
                                                                    )
                                                                    setNormalise(e === "normalise" ? true : false)

                                                                }} />
                                                            </>
                                                        }
                                                    </div>
                                                    {((categoricalSummary === null && numericalSummary === null) && (numericalSummaryStatus === AsyncStates.ERROR && categoricalSummaryStatus === AsyncStates.ERROR))
                                                        ? <Empty description={numericalSummaryError ?? categoricalSummaryError} /> :
                                                        (numericalSummaryStatus !== AsyncStates.ERROR && categoricalSummaryStatus !== AsyncStates.ERROR) ?
                                                            <>
                                                                {categoricalSummary !== null && numericalSummary !== null &&
                                                                    <Summaries
                                                                        names={names}
                                                                        workOrderIds={workOrderIds}
                                                                        numericalData={numericalSummary}
                                                                        categoricalData={categoricalSummary}
                                                                        characterizationMethodId={characterizationMethodId}
                                                                        selectedVariation={selectedVariation}
                                                                        correlationStatus={correlationStatus}
                                                                        normalise={normalise}
                                                                        selectedStages={stageIdentifier}
                                                                        selectedExperiments={selectedExperiments}
                                                                        currentTab={"currentTab"}
                                                                        tab={tab}
                                                                        getDisplayName={getDisplayName}
                                                                        selectedFormulations={(isFromCompareFormulation || !!selectedFormulations.length) ? selectedFormulations : []}
                                                                    >
                                                                        {tab === "overview" && numericalSummary !== null &&
                                                                            <div id='dataset-size'>
                                                                                <SummaryWrapper heading={t("dataSummary.datasetSize")} tooltip={t("aiEngine.tab.dataSummary.datasize")} key={"dataset-size"}>
                                                                                    <Title style={{ marginLeft: 5 }} level={5}>
                                                                                        {`${t("dataSummary.datasetSize")}: ${numericalSummary?.overall_summary.L1["Data Set Size"]
                                                                                            }`}
                                                                                    </Title>

                                                                                    <Descriptions size="small" column={1} bordered labelStyle={{ fontWeight: "bold", width: 150 }}>
                                                                                        <Descriptions.Item
                                                                                            label={`${t("common.ingredients")}(${numericalSummary?.overall_summary.L2.Ingredients.Unique
                                                                                                })`}
                                                                                        >
                                                                                            {numericalSummary?.overall_summary.L2.Ingredients.Names.map(
                                                                                                (ingredient) => names.ingredients?.[ingredient]?.name ?? numericalSummary?.['formulation_display_names'][ingredient] ?? ingredient
                                                                                        ).join(", ")}
                                                                                        </Descriptions.Item>

                                                                                        <Descriptions.Item
                                                                                            label={`${t("common.processing")}(${numericalSummary?.overall_summary.L2["Processing Conditions"]
                                                                                                .Unique
                                                                                                })`}
                                                                                        >
                                                                                            {numericalSummary?.overall_summary.L2[
                                                                                                "Processing Conditions"
                                                                                        ].Names.map((processing) => names.processing?.[processing]?.name ?? processing)
                                                                                                .join(
                                                                                                    ", "
                                                                                                )}
                                                                                        </Descriptions.Item>

                                                                                        <Descriptions.Item
                                                                                            label={`${t("common.properties")}(${numericalSummary?.overall_summary.L2["Properties"].Unique
                                                                                                })`}
                                                                                        >
                                                                                            {numericalSummary?.overall_summary.L2.Properties.Names.map(
                                                                                                (property) => names.properties?.[property]?.name ?? property
                                                                                        ).join(", ")}
                                                                                        </Descriptions.Item>

                                                                                        {
                                                                                            !!Object.keys(numericalSummary?.overall_summary.L2 || {}).includes("Costing") &&
                                                                                            <Descriptions.Item
                                                                                                label={`Costing`}
                                                                                            >
                                                                                                {numericalSummary?.overall_summary.L2?.["Costing"]?.Names?.map((cost) => cost)}
                                                                                            </Descriptions.Item>
                                                                                        }
                                                                                    </Descriptions>
                                                                                </SummaryWrapper>
                                                                            </div>
                                                                        }
                                                                    </Summaries>
                                                                }
                                                                <Divider />
                                                                {(numericalSummaryStatus === AsyncStates.SUCCESS && categoricalSummaryStatus === AsyncStates.SUCCESS) &&
                                                                    <div>
                                                                        <SummaryWrapper
                                                                            heading={t("common.graphs")}
                                                                            key={"graphs"}
                                                                            id={"graphs"}
                                                                        >
                                                                            <div className='analytics__graphs__container'>
                                                                                <Popover
                                                                                    style={{ zIndex: 5 }}
                                                                                    content={<>
                                                                                        <Checkbox indeterminate={tempSelectedPlots.length > 0 && tempSelectedPlots.length < Object.keys(plotOptions || {}).length} onChange={onCheckAllChange} checked={Object.keys(plotOptions || {}).length === tempSelectedPlots.length}>
                                                                                            {t("common.selectAll")}
                                                                                        </Checkbox>
                                                                                        <Checkbox.Group value={tempSelectedPlots} style={{ width: '100%', display: "flex", flexDirection: "column" }} onChange={(values) => {
                                                                                            setTempSelectedPlots(values);
                                                                                        }}>
                                                                                            {Object.keys(plotOptions || {}).map((key: string) => {
                                                                                                return <Checkbox key={key} value={key}>{plotOptions[key]}</Checkbox>
                                                                                            })}
                                                                                        </Checkbox.Group>
                                                                                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem", padding: "1rem 0rem" }}>
                                                                                            <StyledButton danger onClick={() => setTempSelectedPlots([])}>{t("common.clearAll")}</StyledButton>
                                                                                            <StyledButton type='primary' onClick={() => {
                                                                                                setSelectedPlots(tempSelectedPlots);
                                                                                                setTempSelectedPlots([]);
                                                                                                setSelectedPlotsPopover(false);
                                                                                                handlePlotchanges(tempSelectedPlots)
                                                                                                setTimeout(() => {
                                                                                                    document.getElementById("data-analytics-graphs")?.scrollIntoView()
                                                                                                }, 300)
                                                                                            }}>{t("common.apply")}</StyledButton>
                                                                                        </div>
                                                                                    </>}
                                                                                    placement="bottomLeft"
                                                                                    title={
                                                                                        <Row justify="end">
                                                                                            <CloseOutlined
                                                                                                onClick={() => {
                                                                                                    setSelectedPlotsPopover(false)
                                                                                                }}
                                                                                                style={{ fontSize: antdTheme.fontSizeHeading5, cursor: "pointer" }}
                                                                                            />
                                                                                        </Row>
                                                                                    }
                                                                                    open={selectedPlotsPopover}
                                                                                >
                                                                                    <StyledButton
                                                                                        type="primary"
                                                                                        ghost
                                                                                        icon={<DotChartOutlined />}
                                                                                        onClick={() => {
                                                                                            setTempSelectedPlots(selectedPlots);
                                                                                            setSelectedPlotsPopover(true);
                                                                                        }}
                                                                                    >
                                                                                        {t("graphs.addGraphs")}
                                                                                    </StyledButton>
                                                                                </Popover>
                                                                                <div className='analytics__saved__graphs__container'>
                                                                                    {(!!Object.keys(saveGraphsPayload || {}).length && !!selectedPlots.length) &&
                                                                                        <StyledButton
                                                                                            onClick={() => {
                                                                                                setShowSaveGraphsModal(true)
                                                                                            }}>{t("graphs.saveGraph")}</StyledButton>
                                                                                    }
                                                                                    <StyledButton onClick={() => {
                                                                                        dispatch(getPlotsHistoryRequest({
                                                                                            name: "",
                                                                                            user_id: ""
                                                                                        }))
                                                                                        setShowGraphsListDrawer(true)
                                                                                    }}>{t("graphs.viewSavedGraphs")}</StyledButton>
                                                                                </div>
                                                                            </div>
                                                                            {!selectedPlots.length && <div className='empty__container'>
                                                                                <Empty description={t("graphs.plotsEmpty")} />
                                                                            </div>}

                                                                            {categoricalSummary !== null && numericalSummary !== null &&
                                                                                <>
                                                                                    <DataAnalyticsGraphs
                                                                                        workOrderIds={workOrderIds}
                                                                                        numericalData={numericalSummary}
                                                                                        categoricalData={categoricalSummary}
                                                                                        characterizationMethodId={characterizationMethodId}
                                                                                        selectedVariation={selectedVariation}
                                                                                        correlationStatus={correlationStatus}
                                                                                        normalise={normalise}
                                                                                        selectedStages={stageIdentifier}
                                                                                        selectedExperiments={selectedExperiments}
                                                                                        tab={tab}
                                                                                        selectedPlots={selectedPlots}
                                                                                        setSaveGraphsPayload={setSaveGraphsPayload}
                                                                                        selectedPlotsData={selectedPlotsData}
                                                                                        selectedFormulations={(isFromCompareFormulation || !!selectedFormulations.length) ? selectedFormulations : []}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </SummaryWrapper >
                                                                    </div>
                                                                }
                                                            </>
                                                            : <Empty />
                                                    }
                                                </Card>
                                            )}
                                        </Spin>
                                    </>
                                }

                            </div>
                        </Col>
                        {(categoricalSummary !== null && numericalSummary !== null) &&
                            <Col span={3} className={numericalSummaryStatus === AsyncStates.SUCCESS && categoricalSummaryStatus ? "anchor__links" : ""}>
                                <Anchor
                                    style={{ marginTop: "0.5rem" }}
                                    affix={true}
                                >
                                    {
                                        anchorLinksList?.map((anchorLink) =>
                                            <Link href={anchorLink.id} title={anchorLink.title} key={anchorLink.id} />
                                        )
                                    }
                                </Anchor>
                            </Col>
                        }
                    </Row>
                </Space>
            </motion.div>
            <SaveGraphsModal saveGraphsPayload={saveGraphsPayload} setSaveGraphsPayload={setSaveGraphsPayload} showSaveGraphsModal={showSaveGraphsModal} setShowSaveGraphsModal={setShowSaveGraphsModal} getDisplayName={getDisplayName} />
            <GraphHistoryDrawer showgraphsListDrawer={showgraphsListDrawer} setShowGraphsListDrawer={setShowGraphsListDrawer} getDisplayName={getDisplayName} setSelectedPlots={setSelectedPlots} setSelectedPlotsData={setSelectedPlotsData} handlePlotchanges={handlePlotchanges} />
        </>
    )
}