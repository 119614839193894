import { createActions } from "redux-actions"

export enum DataSummaryActionTypes {
	REQUEST = "DATA_SUMMARY_REQUEST",
	SUCCESS = "DATA_SUMMARY_SUCCESS",
	FAILURE = "DATA_SUMMARY_FAILURE",
	CLEAR = "DATA_SUMMARY_CLEAR",
}

export enum DataSummaryPlotOptionsActionTypes {
	SUCCESS = "DATA_SUMMARY_PLOT_OPTIONS_SUCCESS",
	CLEAR = "DATA_SUMMARY_PLOT_OPTIONS_CLEAR",
}

export enum FormulationsDataFromCompareActionTypes {
	SUCCESS = "FORMULATIONS_DATA_FROM_COMPARE_SUCCESS",
	CLEAR = "FORMULATIONS_DATA_FROM_COMPARE_CLEAR",
}

export enum SurfacePlotActionTypes {
	REQUEST = "SURFACE_PLOT_REQUEST",
	SUCCESS = "SURFACE_PLOT_SUCCESS",
	FAILURE = "SURFACE_PLOT_FAILURE",
	CLEAR = "SURFACE_PLOT_CLEAR"
}

export enum AnalyticsRecipeDistributionActionTypes {
	REQUEST = "ANALYTICS_RECIPE_DISTRIBUTION_REQUEST",
	SUCCESS = "ANALYTICS_RECIPE_DISTRIBUTION_SUCCESS",
	FAILURE = "ANALYTICS_RECIPE_DISTRIBUTION_FAILURE",
	CLEAR = "ANALYTICS_RECIPE_DISTRIBUTION_CLEAR"
}

export enum AnalyticsRecipeDistributionTableActionTypes {
	REQUEST = "ANALYTICS_RECIPE_DISTRIBUTION_TABLE_REQUEST",
	SUCCESS = "ANALYTICS_RECIPE_DISTRIBUTION_TABLE_SUCCESS",
	FAILURE = "ANALYTICS_RECIPE_DISTRIBUTION_TABLE_FAILURE",
	CLEAR = "ANALYTICS_RECIPE_DISTRIBUTION_TABLE_CLEAR",
	REMOVE = "ANALYTICS_RECIPE_DISTRIBUTION_TABLE_REMOVE"
}

export enum SavePlotsActionTypes {
	REQUEST = "SAVE_PLOTS_REQUEST",
	SUCCESS = "SAVE_PLOTS_SUCCESS",
	FAILURE = "SAVE_PLOTS_FAILURE",
	CLEAR = "SAVE_PLOTS_CLEAR"
}

export enum GetPlotsHistoryActionTypes {
	REQUEST = "GET_PLOTS_HISTORY_REQUEST",
	SUCCESS = "GET_PLOTS_HISTORY_SUCCESS",
	FAILURE = "GET_PLOTS_HISTORY_FAILURE",
	CLEAR = "GET_PLOTS_HISTORY_CLEAR"
}

export enum DeleteSavedPlotsActionTypes {
	REQUEST = "DELETE_SAVED_PLOTS_REQUEST",
	SUCCESS = "DELETE_SAVED_PLOTS_SUCCESS",
	FAILURE = "DELETE_SAVED_PLOTS_FAILURE",
	CLEAR = "DELETE_SAVED_PLOTS_CLEAR"
}

export enum BoxPlotSummaryActionTypes {
	REQUEST = "BOX_PLOT_SUMMARY_REQUEST",
	SUCCESS = "BOX_PLOT_SUMMARY_SUCCESS",
	FAILURE = "BOX_PLOT_SUMMARY_FAILURE",
	CLEAR = "BOX_PLOT_SUMMARY_CLEAR",
}

export enum RecipeDistributionActionTypes {
	REQUEST = "RECIPE_DISTRIBUTION_REQUEST",
	SUCCESS = "RECIPE_DISTRIBUTION_SUCCESS",
	FAILURE = "RECIPE_DISTRIBUTION_FAILURE",
	CLEAR = "RECIPE_DISTRIBUTION_CLEAR"
}

export enum GetZeonCharacterizationDsmActionTypes {
	REQUEST = "GET_ZEON_CHARACTERIZATION_DSM_REQUEST",
	SUCCESS = "GET_ZEON_CHARACTERIZATION_DSM_SUCCESS",
	FAILURE = "GET_ZEON_CHARACTERIZATION_DSM_FAILURE",
	CLEAR = "GET_ZEON_CHARACTERIZATION_DSM_CLEAR",
}

export const {
	dataSummaryRequest,
	dataSummarySuccess,
	dataSummaryFailure,
	dataSummaryClear,
	surfacePlotRequest,
	surfacePlotSuccess,
	surfacePlotFailure,
	surfacePlotClear,
	analyticsRecipeDistributionRequest,
	analyticsRecipeDistributionSuccess,
	analyticsRecipeDistributionFailure,
	analyticsRecipeDistributionClear,
	analyticsRecipeDistributionTableRequest,
	analyticsRecipeDistributionTableSuccess,
	analyticsRecipeDistributionTableFailure,
	analyticsRecipeDistributionTableClear,
	analyticsRecipeDistributionTableRemove,
	dataSummaryPlotOptionsSuccess,
	dataSummaryPlotOptionsClear,
	recipeDistributionRequest,
	recipeDistributionSuccess,
	recipeDistributionFailure,
	recipeDistributionClear,
	getZeonCharacterizationDsmRequest,
	getZeonCharacterizationDsmSuccess,
	getZeonCharacterizationDsmFailure,
	getZeonCharacterizationDsmClear,
	boxPlotSummaryRequest,
	boxPlotSummarySuccess,
	boxPlotSummaryFailure,
	boxPlotSummaryClear,
	savePlotsRequest,
	savePlotsSuccess,
	savePlotsFailure,
	savePlotsClear,
	getPlotsHistoryRequest,
	getPlotsHistorySuccess,
	getPlotsHistoryFailure,
	getPlotsHistoryClear,
	deleteSavedPlotsRequest,
	deleteSavedPlotsSuccess,
	deleteSavedPlotsFailure,
	deleteSavedPlotsClear,
	formulationsDataFromCompareSuccess,
	formulationsDataFromCompareClear
} = createActions({
	[DataSummaryActionTypes.REQUEST]: (payload) => payload,
	[DataSummaryActionTypes.SUCCESS]: (data) => (data),
	[DataSummaryActionTypes.FAILURE]: (error) => ({ error }),
	[DataSummaryActionTypes.CLEAR]: () => { },
	[SurfacePlotActionTypes.REQUEST]: (payload) => payload,
	[SurfacePlotActionTypes.SUCCESS]: (data) => ({ data }),
	[SurfacePlotActionTypes.FAILURE]: (error) => ({ error }),
	[SurfacePlotActionTypes.CLEAR]: () => { },
	[AnalyticsRecipeDistributionActionTypes.REQUEST]: (payload) => payload,
	[AnalyticsRecipeDistributionActionTypes.SUCCESS]: (data) => ({ data }),
	[AnalyticsRecipeDistributionActionTypes.FAILURE]: (error) => ({ error }),
	[AnalyticsRecipeDistributionActionTypes.CLEAR]: () => { },
	[AnalyticsRecipeDistributionTableActionTypes.REQUEST]: (payload) => payload,
	[AnalyticsRecipeDistributionTableActionTypes.SUCCESS]: (data) => ({ data }),
	[AnalyticsRecipeDistributionTableActionTypes.FAILURE]: (error) => ({ error }),
	[AnalyticsRecipeDistributionTableActionTypes.CLEAR]: () => { },
	[AnalyticsRecipeDistributionTableActionTypes.REMOVE]: (formulationId) => formulationId,
	[DataSummaryPlotOptionsActionTypes.SUCCESS]: (data) => ({ data }),
	[DataSummaryPlotOptionsActionTypes.CLEAR]: () => { },
	[RecipeDistributionActionTypes.REQUEST]: (payload) => payload,
	[RecipeDistributionActionTypes.SUCCESS]: (data) => ({ data }),
	[RecipeDistributionActionTypes.FAILURE]: (error) => ({ error }),
	[RecipeDistributionActionTypes.CLEAR]: () => { },
	[GetZeonCharacterizationDsmActionTypes.REQUEST]: (payload) => payload,
	[GetZeonCharacterizationDsmActionTypes.SUCCESS]: (response) => (response),
	[GetZeonCharacterizationDsmActionTypes.FAILURE]: (error) => ({ error }),
	[GetZeonCharacterizationDsmActionTypes.CLEAR]: () => { },
	[BoxPlotSummaryActionTypes.REQUEST]: (payload) => payload,
	[BoxPlotSummaryActionTypes.SUCCESS]: (data) => ({ data }),
	[BoxPlotSummaryActionTypes.FAILURE]: (error) => ({ error }),
	[BoxPlotSummaryActionTypes.CLEAR]: () => { },
	[SavePlotsActionTypes.REQUEST]: (payload) => payload,
	[SavePlotsActionTypes.SUCCESS]: (data) => ({ data }),
	[SavePlotsActionTypes.FAILURE]: (error) => ({ error }),
	[SavePlotsActionTypes.CLEAR]: () => { },
	[GetPlotsHistoryActionTypes.REQUEST]: (payload) => payload,
	[GetPlotsHistoryActionTypes.SUCCESS]: (data) => ({ data }),
	[GetPlotsHistoryActionTypes.FAILURE]: (error) => ({ error }),
	[GetPlotsHistoryActionTypes.CLEAR]: () => { },
	[DeleteSavedPlotsActionTypes.REQUEST]: (payload) => payload,
	[DeleteSavedPlotsActionTypes.SUCCESS]: (data) => ({ data }),
	[DeleteSavedPlotsActionTypes.FAILURE]: (error) => ({ error }),
	[DeleteSavedPlotsActionTypes.CLEAR]: () => { },
	[FormulationsDataFromCompareActionTypes.SUCCESS]: (data) => data,
	[FormulationsDataFromCompareActionTypes.CLEAR]: () => { },
})
