import { handleActions } from "redux-actions";
import {
  CustomTrainActionTypes,
  FilterDataActionTypes,
  DeleteModelsActionTypes,
  CustomTraingsActionTypes,
  EquationModelActionTypes,
} from "../actions/customML";
import { AsyncStates } from "../../constants";

export type CustomMlState = {
  filterDataStatus: AsyncStates;
  customDataTrainStatus: AsyncStates;
  deleteModelStatus: AsyncStates;
  filterData: any;
  customTrainData: any;
  customTrainingsData: any;
  customTrainingsStatus: AsyncStates;
  formulationfilterDataStatus: AsyncStates;
  formulationfilterData: any;
  equationModelStatus: AsyncStates;
  equationModelData: any;
};

const defaultState: CustomMlState = {
  filterDataStatus: AsyncStates.INITIAL,
  customDataTrainStatus: AsyncStates.INITIAL,
  deleteModelStatus: AsyncStates.INITIAL,
  filterData: { dataframe: [], version: "" },
  customTrainData: "",
  customTrainingsData: [],
  customTrainingsStatus: AsyncStates.INITIAL,
  formulationfilterData: { dataframe: [], version: "" },
  formulationfilterDataStatus: AsyncStates.INITIAL,
  equationModelStatus: AsyncStates.INITIAL,
  equationModelData: [],
};

const customMlReducer = handleActions(
  {
    [FilterDataActionTypes.REQUEST]: (state) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      filterDataStatus: AsyncStates.LOADING,
    }),
    [FilterDataActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        filterDataStatus: AsyncStates.SUCCESS,
        filterData: action.payload,
      };
    },
    [FilterDataActionTypes.FORMULATION_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        formulationfilterDataStatus: AsyncStates.SUCCESS,
        formulationfilterData: action.payload,
      };
    },
    [FilterDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      filterDataStatus: AsyncStates.ERROR,
    }),
    [FilterDataActionTypes.CLEAR]: (state, action) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      filterDataStatus: AsyncStates.INITIAL,
    }),
    [CustomTraingsActionTypes.REQUEST]: (state) => ({
      ...state,
      customTrainingsStatus: AsyncStates.LOADING,
    }),
    [CustomTraingsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      customTrainingsStatus: AsyncStates.SUCCESS,
      customTrainingsData: action.payload,
    }),
    [CustomTraingsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      customTrainingsData: { dataframe: [], version: "" },
      customTrainingsStatus: AsyncStates.ERROR,
    }),
    [CustomTraingsActionTypes.CLEAR]: (state, action) => ({
      ...state,
      customTrainingsData: { dataframe: [], version: "" },
      customTrainingsStatus: AsyncStates.INITIAL,
    }),
    [CustomTrainActionTypes.REQUEST]: (state) => ({
      ...state,
      customDataTrainStatus: AsyncStates.LOADING,
    }),
    [CustomTrainActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      customDataTrainStatus: AsyncStates.SUCCESS,
      customTrainData: action.payload,
    }),
    [CustomTrainActionTypes.FAILURE]: (state, action) => ({
      ...state,
      customDataTrainStatus: AsyncStates.ERROR,
    }),
    [CustomTrainActionTypes.CLEAR]: (state, action) => ({
      ...state,
      customDataTrainStatus: AsyncStates.INITIAL,
    }),
    [EquationModelActionTypes.REQUEST]: (state) => ({
      ...state,
      equationModelStatus: AsyncStates.LOADING,
    }),
    [EquationModelActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      equationModelStatus: AsyncStates.SUCCESS,
      equationModelData: action.payload,
    }),
    [EquationModelActionTypes.FAILURE]: (state, action) => ({
      ...state,
      equationModelStatus: AsyncStates.ERROR,
    }),
    [EquationModelActionTypes.CLEAR]: (state, action) => ({
      ...state,
      equationModelStatus: AsyncStates.INITIAL,
      equationModelData: []
    }),
    [DeleteModelsActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteModelStatus: AsyncStates.LOADING,
    }),
    [DeleteModelsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteModelStatus: AsyncStates.SUCCESS,
    }),
    [DeleteModelsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteModelStatus: AsyncStates.ERROR,
    }),
  },
  defaultState
);

export default customMlReducer;
