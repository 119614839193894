import { Tabs, TabsProps } from 'antd'
import { NumericalOutputConstraints } from './numerical-output-constraints'
import { CategoricalOutputConstraints } from '../categorical-output-constraints'
import Constraints from './Constraints'
import { useEffect } from 'react'
import './input.scss'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { InverseConfigs } from '../InverseConfigs'

const PayloadInput = ({
  modelData,
  addRow,
  disableCell,
  numericalObjectiveList,
  parameterListDropDown,
  setUnsavedChanges,
  numericalProperties,
  setRange,
  currentSelectedStage,
  isMultiStageModel,
  categoricalObjectiveList,
  categoricalRange,
  optionsDropDown,
  rangeParameterList,
  setRangeParameterList,
  catwiseRangeParameterList,
  setCatwiseRangeParameterList,
  categorialInputList,
  setCategorialInputList,
  activePredictionInputTab,
  setActivePredictionInputTab,
  selectedStages,
  catConstraintsList,
  setCatConstraintsList,
  setSelectAllIngredientsInCategory,
  selectAllIngredientsInCategory,
  exludeAllParameterConstraints,
  setExludeAllParameterConstraints,
  exludeAllCategoryConstraints,
  setExludeAllCategoryConstraints,
  isCrossCategorical,
  setIsCrossCategorical,
  setIsCrossCategoricalModalVisible,
  isCategoricalConstraintsUseDefault,
  setIsCategoricalConstraintsUseDefault,
  setInverseConfigurationData,
  inverseConfigurationData
}: any) => {
  const [t] = useTranslate()

  const onChange = (key: string) => {
    setActivePredictionInputTab(key)
  }
  const configs = useSelector((state: StoreState) => state.configs.features)

  // For the time being TODO : Remove after multistage objectives support
  useEffect(() => {
    if (currentSelectedStage !== selectedStages[1]) {
      if (activePredictionInputTab === "objectives" || activePredictionInputTab === "configurations")
        setActivePredictionInputTab("category-constraints")
    }
  }, [
    activePredictionInputTab,
    currentSelectedStage,
    selectedStages,
    setActivePredictionInputTab,
  ])


  useEffect(() => {
    const collator = new Intl.Collator([], { numeric: true })

    const defaultConstraintsIngredients = Array.from(
      new Set(
        Object.values(modelData?.categories?.ingredients || {})
          ?.flatMap((i: any) => i)
          .sort(collator.compare),
      ),
    ).flatMap((category, catIndex) => [
      {
        constraint: "sum_of_category_quantity",
        type: "ingredients",
        category,
        min: null,
        max: null,
        catIndex,
      },
      {
        constraint: "count_of_category_items",
        type: "ingredients",
        category,
        min: null,
        max: null,
        catIndex,
      },
    ])
    const defaultConstraintsProcessing = Array.from(
      new Set(
        Object.values(modelData?.categories?.processing || {})?.flatMap(
          (i: any) => i,
        ),
      ),
    ).flatMap((category, catIndex) => [
      {
        constraint: "sum_of_category_quantity",
        type: "processing",
        category,
        min: null,
        max: null,
        catIndex,
      },
      {
        constraint: "count_of_category_items",
        type: "processing",
        category,
        min: null,
        max: null,
        catIndex,
      },
    ])
    const defaultCategoriesIngredients = (previousCategoriesData: any[]) => Array.from(
      new Set(
        Object.values(modelData?.categories?.ingredients || {})
          ?.flatMap((i: any) => i)
          .sort(collator.compare),
      ),
    ).map((category) => ({
      category,
      ...(category === "Reactants" || category === "Solvents"
        ? {
          exclude: false,
          include: true,
        }
        : {
          exclude: Boolean(configs.nestle_configs) ? true : exludeAllCategoryConstraints?.["ingredients"]?.includes(category) ?? false,
          include: previousCategoriesData?.find((prevCat: any) => prevCat.category === category)?.include ?? false,
        }),
      sum_of_category_quantity: { min: null, max: null },
      count_of_category_items: { min: null, max: null },
      item_constraints: {},
      parameter_constraints_categorical: [],
      type: "ingredients",
    }))
    const defaultCategoriesProcessing = Array.from(
      new Set(
        Object.values(modelData?.categories?.processing || {})
          ?.flatMap((i: any) => i)
          .sort(collator.compare),
      ),
    ).map((category) => ({
      category,
      exclude: false,
      include: true,
      sum_of_category_quantity: { min: null, max: null },
      count_of_category_items: { min: null, max: null },
      item_constraints: {},
      parameter_constraints_categorical: [],
      type: "processing",
    }))
    setCatConstraintsList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      if (!newState.hasOwnProperty(String(currentSelectedStage)) || newState?.[currentSelectedStage]?.length <= 0) {
        newState[currentSelectedStage] = [
          ...defaultConstraintsIngredients,
          ...defaultConstraintsProcessing,
        ]
      }
      return newState
    })
    setCatwiseRangeParameterList((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = [
        {
          parameter_type: "ingredients",
          categories: defaultCategoriesIngredients(newState?.[currentSelectedStage]?.[0]?.categories),
        },
        {
          parameter_type: "processing",
          categories: defaultCategoriesProcessing,
        },
      ]
      return newState
    })

    // Not including currentSelectedStage as modelData dependency covers it anyway and we don't want rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modelData,
    setCatwiseRangeParameterList,
    currentSelectedStage,
    configs.nestle_configs,
  ])

  const items: TabsProps["items"] = [
    ...(currentSelectedStage === selectedStages[1]
      ? [
        {
          key: "objectives",
          label: `${t("common.objectives")}`,
          children: (
            <>
              {/* OUTPUT CONSTRAINTS */}
              <NumericalOutputConstraints
                addRow={addRow}
                disableCell={disableCell}
                numericalObjectiveList={numericalObjectiveList}
                parameterListDropDown={parameterListDropDown}
                setUnsavedChanges={setUnsavedChanges}
                numericalProperties={numericalProperties}
                setNumericalProperties={setRange}
                currentSelectedStage={currentSelectedStage}
                isMultiStage={isMultiStageModel}
              />

              {categoricalObjectiveList.length > 0 && (
                <CategoricalOutputConstraints
                  addRow={addRow}
                  categoricalObjectiveList={categoricalObjectiveList}
                  categoricalRange={categoricalRange}
                  optionsDropDown={optionsDropDown}
                  parameterListDropDown={parameterListDropDown}
                />
              )}
            </>
          ),
        },
      ]
      : []),
    {
      key: "category-constraints",
      label: `${t("common.categoryConstraints")}`,
      children: (
        <Constraints
          view={"category-constraints"}
          parameterList={rangeParameterList}
          setParameterList={setRangeParameterList}
          catwiseParameterList={catwiseRangeParameterList}
          setCatwiseParameterList={setCatwiseRangeParameterList}
          modelData={modelData}
          setUnsavedChanges={setUnsavedChanges}
          categorialInputList={categorialInputList}
          setCategorialInputList={setCategorialInputList}
          range={numericalProperties}
          categoricalRange={categoricalRange}
          currentSelectedStage={currentSelectedStage}
          isMultiStage={isMultiStageModel}
          catConstraintsList={catConstraintsList}
          setCatConstraintsList={setCatConstraintsList}
          setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
          selectAllIngredientsInCategory={selectAllIngredientsInCategory}
          exludeAllParameterConstraints={exludeAllParameterConstraints}
          setExludeAllParameterConstraints={setExludeAllParameterConstraints}
          exludeAllCategoryConstraints={exludeAllCategoryConstraints}
          setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
          isCrossCategorical={isCrossCategorical}
          setIsCrossCategorical={setIsCrossCategorical}
          setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}
          isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
          setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
        />
      ),
    },
    {
      key: "parameter-constraints",
      label: `${t("common.parameterConstraints")}`,
      children: (
        <Constraints
          view={"parameter-constraints"}
          parameterList={rangeParameterList}
          setParameterList={setRangeParameterList}
          catwiseParameterList={catwiseRangeParameterList}
          setCatwiseParameterList={setCatwiseRangeParameterList}
          modelData={modelData}
          setUnsavedChanges={setUnsavedChanges}
          categorialInputList={categorialInputList}
          setCategorialInputList={setCategorialInputList}
          range={numericalProperties}
          categoricalRange={categoricalRange}
          currentSelectedStage={currentSelectedStage}
          isMultiStage={isMultiStageModel}
          catConstraintsList={catConstraintsList}
          setCatConstraintsList={setCatConstraintsList}
          setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
          selectAllIngredientsInCategory={selectAllIngredientsInCategory}
          exludeAllParameterConstraints={exludeAllParameterConstraints}
          setExludeAllParameterConstraints={setExludeAllParameterConstraints}
          exludeAllCategoryConstraints={exludeAllCategoryConstraints}
          setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
          isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
          setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
        />
      ),
    },
    ...(currentSelectedStage === selectedStages[1]
      ? [
        {
          key: "configurations",
          label: t("aiEngine.configurations"),
          children: <InverseConfigs
            currentSelectedStage={currentSelectedStage}
            inverseConfigurationData={inverseConfigurationData?.[currentSelectedStage] ?? {}}
            setInverseConfigurationData={setInverseConfigurationData}
          />,
        },
      ] : [])
  ]

  return (
    <Tabs
      activeKey={activePredictionInputTab}
      items={items}
      animated
      onChange={onChange}
      type="card"
      size="small"
      className="category-tabs"
      style={{ width: "100%" }}
    />
  )
}

export default PayloadInput
