import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workOrdersFilterOptionsRequest, workOrdersRequest } from "../../store/actions/workOrders";
import { StoreState } from "../../store/configureStore";
import { motion } from "framer-motion";
import { WorkOrders as CelsureWorkOrders } from "src/components/Celsure/WorkOrders/WorkOrders";
import { permissions, projectStatus, projectType } from "src/constants";
import { WorkOrdersPage } from "./WorkOrdersPage";
import { Radio, Space } from "antd";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import useTranslate from "src/utils/useTranslate";
import { MenuOutlined } from "@ant-design/icons";
import { StyledButton } from "src/styled_components/StyledButton";
import { displayTextPermissions } from "src/utils/decorator";
import { parseSearchQuery } from "src/utils/general";
import { usePermission } from "src/utils/usePermissions";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { useHistory, useLocation } from "react-router-dom";
import CloseAllWorkOrdersModal from "./CloseAllWorkOrdersModal";

enum Layout {
  vertical,
  horizontal,
}

export const WorkOrders = ({ projectId }: any) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const mounted = useRef(false);
  const location = useLocation();
  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  )
  const { project_type } = useSelector(
    (state: StoreState) => state.login.loginResponse
  )
  const [projectIds, setProjectIds] = useState([(projectId || currentProject)]);
  const [projectIdsDup, setProjectIdsDup] = useState(projectIds);
  const [tabKey, setTabKey] = useState<"open" | "closed">("open")
  const userAccess = usePermission()
  const [layout, setLayout] = useState(Layout.vertical)
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { count: workOrderCount } = useSelector(
    (state: StoreState) => state.workOrders
  );
  const closedQueryParams = new URLSearchParams(location.search).get("closed");
  const [woFilters, setWoFilters] = useState({});
  const [tabFilter, setTabFilter] = useState(closedQueryParams === "true" ? { status: "closed" } : {});

  useEffect(() => {
    if (mounted.current) setProjectIds([currentProject]);
    else mounted.current = true;
  }, [currentProject]);

  useEffect(() => {
    dispatch(workOrdersFilterOptionsRequest({ project_ids: projectIds }))
    setProjectIdsDup(projectIds);
    setWoFilters({});
    setPageNumber(1);
  }, [dispatch, projectIds]);

  useEffect(() => {
    const payload: Record<string, any> = { project_ids: projectIdsDup, is_table: true, page_num: pageNumber, page_size: pageSize };
    if (Object.keys(woFilters || {}).length > 0 || Object.keys(tabFilter || {}).length > 0) payload.filters = { ...(woFilters || {}), ...(tabFilter || {}) }
    dispatch(workOrdersRequest(payload));
  }, [dispatch, pageNumber, pageSize, projectIdsDup, woFilters, tabFilter]);

  const setWorkOrderFilters = useCallback((filter) => {
    if (Object.keys(filter || {}).length > 0) {
      setPageNumber(1);
    }
    setWoFilters(filter)
  }, []);

  const setTabFilters = useCallback((filter) => {
    if (Object.keys(filter || {}).length > 0) {
      setPageNumber(1);
    }
    setTabFilter(filter)
  }, []);

  useEffect(() => {
    const query = window.location.search.split("?")[1];
    const searchParams = parseSearchQuery<{
      closed: string;
    }>(query);
    setTabKey(searchParams.closed === "true" ? "closed" : "open");
    if (searchParams.closed === "true") {
      setShowAll(false);
    }
  }, []);

  const newOrder = () => {
    dispatch(resetWorkOrder());
    push("/work-orders/create-workorder");
  };

  const [showAll, setShowAll] = useState<boolean>(true)

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
      className="page__container"
    >
      {project_type !== projectType.celsure ? (
        <Space
          direction="vertical"
          size="large"
          style={{ width: "100%", gap: 0 }}
        >
          <StyledPageHeader
            ghost={false}
            title={`${t("common.workOrders")} ${workOrderCount ? "(" + workOrderCount + ")" : "(0)"}`}
            extra={
              <Space size="large">
                {/* {tabKey !== "closed" && ( */}
                <Radio.Group
                  onChange={(e) => setLayout(e.target.value)}
                  defaultValue={layout}
                >
                  <Radio.Button value={Layout.horizontal}>
                    <MenuOutlined rotate={90} />
                  </Radio.Button>
                  <Radio.Button value={Layout.vertical}>
                    <MenuOutlined />
                  </Radio.Button>
                </Radio.Group>
                {/* )} */}
                {userAccess?.permission !== permissions.viewer &&
                  userAccess?.status === projectStatus.in_progress &&
                  userAccess?.stages?.includes("work_order_initiated") ? (
                  <Space>
                    <StyledButton
                      type="primary"
                      size="middle"
                      onClick={newOrder}
                    >
                      {t("workOrders.button.newWorkOrder")}
                    </StyledButton>
                  </Space>
                ) : (
                  displayTextPermissions(userAccess)
                )}
                <CloseAllWorkOrdersModal projectIds={projectIds} buttonType={"primary"} />
              </Space>
            }
          // footer={
          //   <Tabs
          //     size="large"
          //     activeKey={tabKey}
          //     onChange={(key) => setTabKey(key as "open" | "closed")}
          //   >
          //     <Tabs.TabPane
          //       tabKey="open"
          //       key="open"
          //       tab={`${t("common.active")} (${activeWorkOrders})`}
          //     />
          //     <Tabs.TabPane
          //       tabKey="closed"
          //       key="closed"
          //       tab={`${t("common.closed")} (${closedWorkOrders})`}
          //     />
          //   </Tabs>
          // }
          />
          <WorkOrdersPage
            tabKey={tabKey}
            layout={layout}
            setTabKey={setTabKey}
            showAll={showAll}
            setShowAll={setShowAll}
            projectIds={projectIds}
            setProjectIds={setProjectIds}
            showPagination={true}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            woFilters={woFilters}
            setWorkOrderFilters={setWorkOrderFilters}
            tabFilter={tabFilter}
            setTabFilters={setTabFilters}
          />
        </Space>
      ) : (
        <CelsureWorkOrders />
      )}
    </motion.div>
  );
};
