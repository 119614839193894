import { Col, Flex, Form, Input, Modal, Row, Select, Steps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    processPaintFilmAnalysisRequest,
} from "src/store/actions/semAnalysis";
import { StyledButton } from "src/styled_components/StyledButton";
import ImageUpload from "../ImageUpload";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { SCALE_UNITS } from "../ScaleUnits";
import { CloseOutlined } from "@ant-design/icons";
import { fileToBase64 } from "src/utils/general/files";

const NewPaintFilmAnalysisModal = ({
    showPaintFilmAnalysisModal,
    setShowPaintFilmAnalysisModal,
}: any) => {
    const processPaintFilmAnalysisStatus = useSelector((state: StoreState) => state.semAnalysis.processPaintFilmAnalysisStatus)
    const [files, setFiles] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [scaleFile, setScaleFile] = useState<any>();
    const [scaleImage, setScaleImage] = useState<any>();
    const [currentStep, setCurrentStep] = useState(0);
    const [scaleForm] = Form.useForm();
    const [batchForm] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        if (processPaintFilmAnalysisStatus === AsyncStates.SUCCESS) {
            setFiles([]);
            setScaleFile(undefined);
            scaleForm.resetFields();
            batchForm.resetFields();
            setCurrentStep(0);
            setShowPaintFilmAnalysisModal(false);
        }
    }, [batchForm, dispatch, processPaintFilmAnalysisStatus, scaleForm, setShowPaintFilmAnalysisModal])

    useEffect(() => {
        if (!scaleFile) setScaleImage(undefined);
        else {
            const reader: any = new FileReader();
            reader.onload = (event: any) => {
                setScaleImage(event?.target?.result);
            };
            reader.readAsDataURL(scaleFile);
        }
    }, [scaleFile]);

    useEffect(() => {
        if (!files || files.length === 0) setImages([]);
        else {
            (async () => {
                const fileReaderPromises = [];
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    fileReaderPromises.push(fileToBase64(file));
                }
                try {
                    const rawImageData: any = await Promise.all(fileReaderPromises);
                    setImages(rawImageData);
                } catch (err) {
                    console.error(err);
                }
            })();
        }
    }, [files]);

    const steps = [
        {
            title: "Scale",
        },
        {
            title: "Upload",
        },
    ];

    const handleSetFile = useCallback((file: any, type: any) => {
        if (type === "scale") setScaleFile(file);
        if (type === "batch") setFiles((prev) => [...prev, file]);
    }, [])

    const handlePaintFilmAnalysis = useCallback(() => {
        batchForm
            .validateFields({ validateOnly: false })
            .then(() => {
                if (files && files.length > 0) {
                    const formData = new FormData();

                    files.forEach(file => {
                        formData.append("files", file);
                    })
                    formData.append("batch_name", batchForm.getFieldValue("batch_name"));
                    formData.append("scale", `${scaleForm.getFieldValue("scale")} ${scaleForm.getFieldValue("unit")}`);
                    formData.append("scale_image", scaleFile);

                    dispatch(processPaintFilmAnalysisRequest(formData));
                }
            })
            .catch((err) => console.error(err));
    }, [batchForm, dispatch, files, scaleFile, scaleForm])

    return (
        <Modal
            width={600}
            title={
                <Flex gap={10} justify="space-between">
                    <h3>New Paint Film Analysis</h3>
                    <Steps
                        current={currentStep}
                        items={steps}
                        size="small"
                        style={{ marginRight: 20, width: 250 }}
                    />
                </Flex>
            }
            open={showPaintFilmAnalysisModal}
            onCancel={() => setShowPaintFilmAnalysisModal(false)}
            footer={
                currentStep === 0 ? (
                    <StyledButton
                        type="primary"
                        size="middle"
                        onClick={() =>
                            scaleForm
                                .validateFields({ validateOnly: false })
                                .then(() => scaleImage && setCurrentStep(1))
                                .catch((err) => console.error(err))
                        }
                        loading={processPaintFilmAnalysisStatus === AsyncStates.LOADING}
                    >
                        Continue
                    </StyledButton>
                ) : (
                    files &&
                    files.length > 0 && (
                        <StyledButton
                            type="primary"
                            size="middle"
                            onClick={handlePaintFilmAnalysis}
                            loading={processPaintFilmAnalysisStatus === AsyncStates.LOADING}
                        >
                            Run Analysis
                        </StyledButton>
                    )
                )
            }
        >
            <>
                {currentStep === 0 && (
                    <>
                        <p style={{ marginBottom: 15 }}>Upload scale image & scale</p>
                        <Flex gap={10} justify="space-between" vertical>
                            {scaleImage ? (
                                <div className="image-preview-container">
                                    <div className="image-preview">
                                        <img src={scaleImage} alt="Scale" />
                                        <span
                                            className="close"
                                            onClick={() => setScaleFile(undefined)}
                                        >
                                            <CloseOutlined />
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <ImageUpload
                                    setFile={(file: any) => handleSetFile(file, "scale")}
                                    title={`Scale Image`}
                                    className="paint-film-image-upload"
                                />
                            )}
                            <Form form={scaleForm}>
                                <Row gutter={[8, 8]}>
                                    <Col>
                                        <Form.Item rules={[{ required: true }]} name="scale">
                                            <Input type="number" placeholder="Scale" />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item rules={[{ required: true }]} name="unit">
                                            <Select
                                                placeholder="Select unit"
                                                options={SCALE_UNITS.map((unit) => ({
                                                    label: unit,
                                                    value: unit,
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Flex>
                    </>
                )}
                {currentStep === 1 && (
                    <>
                        <p style={{ marginBottom: 15 }}>Upload images for analysis</p>
                        <Flex gap={10} justify="space-between" vertical>
                            <ImageUpload
                                setFile={(file: any) => handleSetFile(file, "batch")}
                                title={`Upload Images`}
                                className="paint-film-image-upload"
                                multiple={true}
                            />
                            {images && images.length > 0 && (
                                <div className="image-preview-container">
                                    {images.map(({ id, rawData }) => (
                                        <div className="image-preview" key={id}>
                                            <img src={rawData} alt="Scale" />
                                            <span
                                                className="close"
                                                onClick={() =>
                                                    setFiles((prev) =>
                                                        prev.filter((file) => file.uid !== id)
                                                    )
                                                }
                                            >
                                                <CloseOutlined />
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <Form form={batchForm}>
                                <Row gutter={[8, 8]}>
                                    <Col>
                                        <Form.Item rules={[{ required: true }]} name="batch_name">
                                            <Input type="text" placeholder="Batch Name" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Flex>
                    </>
                )}
            </>
        </Modal>
    );
};

export default NewPaintFilmAnalysisModal;
