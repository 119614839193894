import {
	Space,
	Input,
	Select,
	Radio,
	Divider,
	Tag,
	Tooltip,
	Popover,
	Form,
	Row,
	AutoComplete,
	Tabs,
	Switch,
	Typography
} from "antd"
import { MenuOutlined, PlusOutlined, PushpinOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useMemo, useState, useEffect, useCallback } from "react"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"
import { motion } from "framer-motion"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import "./Projects.scss"
import { useHistory } from "react-router-dom"
import { antdTheme, AsyncStates, permissions } from "src/constants"
import { ProjectTable } from "./ProjectTable"
import { ProjectCard } from "./ProjectCard"
import Title from "antd/lib/typography/Title"
import { ProjectFilter, ProjectFilters } from "./ProjectFilters"
import { useForm } from "antd/lib/form/Form"
import { newProjectRequest } from 'src/store/actions/projects'
import { listTemplateRequest } from "src/store/actions/templates"
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar"


const { Text } = Typography
const { Option } = Select

enum Layout {
	vertical,
	horizontal,
}

const projectFiltersDefaultState: ProjectFilter = {
	project_owner: null,
	member: null,
	tags: [],
	showOnlyPolymers: false
}

export const Projects = () => {
	const [t] = useTranslate()
	const { push } = useHistory()

	const configs = useSelector((state: StoreState) => state.configs.features)
	const projects = useSelector((state: StoreState) => state.projects.projectList)
	const { user_role, user_id } = useSelector((state: StoreState) => state.login.loginResponse)
	const [projectsList, setProjectsList] = useState<any>([])
	const [filterTerm, setfilterTerm] = useState("")
	const [serachField, setSearchField] = useState<string>("name")
	const [projectType, setProjectType] = useState("all")
	const [layout, setLayout] = useState(Layout.vertical)
	const [filters, setFilters] = useState<ProjectFilter>(projectFiltersDefaultState)
	// const [categoryInAscending, setCategoryInAscending] = useState(true)

	// const menu = useMemo(
	// 	() => (
	// 		<Menu
	// 			onClick={(e) => {
	// 				e.key === "asc"
	// 					? setCategoryInAscending(true)
	// 					: setCategoryInAscending(false)
	// 			}}
	// 		>
	// 			<Menu.Item key="asc">{t("common.category")} A - Z</Menu.Item>
	// 			<Menu.Item key="dsc">{t("common.category")} Z - A</Menu.Item>
	// 		</Menu>
	// 	),
	// 	[t]
	// )

	const filteredProjects = useMemo(() => {
		if (serachField === "name") {
			return projectsList.filter((project: any) => projectType === "all" || String(project.status).toLowerCase() === projectType).filter((project: any) => {
				return !filterTerm?.trim()
					? true
					: project?.name
						?.toLowerCase()
						?.includes(filterTerm?.toLocaleLowerCase()) || !!(project?.tags ?? [])?.filter((tag: string) => tag.includes(filterTerm?.toLocaleLowerCase()))?.length
			}
			)
		} else {
			return projectsList.filter((project: any) => projectType === "all" || String(project.status).toLowerCase() === projectType).filter((project: any) => {
				if (!filterTerm?.trim()) {
					return true
				} else {
					const value = project?.additional_fields?.find(
						(res: any) =>
							String(res?.field_name).toLowerCase()?.trim() ===
							String(serachField).toLowerCase()?.trim()
					)?.value
					if (!!value) {
						return String(value)
							?.toLocaleLowerCase()
							?.includes(filterTerm?.toLocaleLowerCase())
					} else {
						return false
					}
				}
			})
		}
	}, [projectsList, filterTerm, serachField, projectType])

	// const categories = useMemo(
	// 	() => [
	// 		...new Set(filteredProjects.map((project: any) => project.category)),
	// 	].sort((a: any, b: any) =>
	// 		(categoryInAscending ? b > a : a > b) ? -1 : 1
	// 	),
	// 	[filteredProjects, categoryInAscending]
	// )

	const tabList = [
		{
			key: "all",
			tab: t("projects.all"),
		},
		{
			key: "in_progress",
			tab: t("projects.onGoing"),
		},
		{
			key: "completed",
			tab: t("aiEngine.completed"),
		},
		{
			key: "archived",
			tab: t("projects.archived"),
		},
	]

	useEffect(() => {
		setProjectsList(
			projects.filter((project) =>
				projectType === "all" ? true : project.status === projectType
			)
		)
	}, [projectType, projects])

	useEffect(() => {
		setProjectsList(((prevState: any) =>
			projects.filter((project: any) => {
				if (filters.project_owner) {
					return project?.created_by === filters.project_owner
				}
				if (filters.member) {
					return (project.members ?? []).some((member: any) => {
						return member.user_id === filters.member
					})
				}
				if (!!(filters.tags as string[])?.length) {
					return !!(project.tags ?? []).filter((tag: any) => (filters.tags as string[])?.includes(tag)).length
				}
				if (filters?.showOnlyPolymers) {
					return (project.tags ?? []).includes("Polymer")
				}
				return true
			})
		))
	}, [filters, projects])

	const getProjectStatus = useCallback((status: string) => {
		switch (status) {
			case "in_progress":
				return <Tag color="orange" style={{ borderRadius: "10px" }}> {t("projects.ongoing")}  </Tag>
			case "completed":
				return <Tag color="green" style={{ borderRadius: "10px" }}> {t("projects.completed")}  </Tag>
			case "archived":
				return <Tag color="blue" style={{ borderRadius: "10px" }}> {t("projects.archived")}  </Tag>
		}
	}, [t])

	const getPinAccess = useCallback((project: any) => {
		if (user_role === "admin") return true
		if (user_id === project?.created_by) return true
		let userAccess = false
		project?.members?.forEach((res: any) => {
			if (res?.user_id === user_id) {
				if (res?.permission === permissions.projectAdmin || res?.permission === permissions.editor) {
					userAccess = true
				} else {
					userAccess = false
				}
			}
		})
		return userAccess
	}, [user_role, user_id])

	const tooglePolymers = (e: boolean) => {
		setFilters((prevState: any) => {
			return ({ ...prevState, showOnlyPolymers: e })
		})
	}

	return (
		<motion.div
			initial={{ opacity: 0, x: 20 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 20 }}
			transition={{ type: "just" }}
			className="page__container"
		>
			<Space direction="vertical" size="large" style={{ width: "100%" }}>
				<StyledPageHeader
					ghost={false}
					title={t(`projects.header.title`)}
					extra={
						<Space size="large">
							{configs?.pcm_graphs &&
								<Space>
									<Text strong type="secondary">{"Show Only Polymers"}</Text>
									<Switch onChange={tooglePolymers} />
								</Space>
							}
							<Radio.Group
								onChange={(e) => { setFilters(projectFiltersDefaultState); setLayout(e.target.value) }}
								defaultValue={layout}
							>
								<Tooltip title="Table View">
									<Radio.Button value={Layout.vertical}>
										<MenuOutlined />
									</Radio.Button>
								</Tooltip>
								<Tooltip title="Grid View">
									<Radio.Button value={Layout.horizontal}>
										<MenuOutlined rotate={90} />
									</Radio.Button>
								</Tooltip>
							</Radio.Group>
							<Popover
								trigger={"click"}
								placement="bottomRight"
								content={
									<CreateProjectTabs
										t={t}
										push={push}
									/>
								}
							>
								<StyledButton
									type="primary"
									icon={<PlusOutlined />}
									size="middle"
								>
									{t("projects.createProject")}
								</StyledButton>
							</Popover>
						</Space>
					}
				/>
				<StyledCard
					tabList={tabList}
					className="project__cards__container"
					tabProps={{ size: "large" }}
					onTabChange={setProjectType}
					activeTabKey={projectType}
					defaultActiveTabKey={"all"}
					headStyle={{ padding: "0px 16px" }}
					tabBarExtraContent={
						<Space>
							{Boolean(configs?.zeon_configs) && (
								<Select
									placeholder="Search by"
									value={serachField}
									onChange={setSearchField}
								>
									<Option key={"1"} value={"name"}>
										{"Project Name"}
									</Option>
									<Option key={"2"} value={"Project ID"}>
										{"Project ID"}
									</Option>
									<Option key={"3"} value={"Project No"}>
										{"Project No"}
									</Option>
									<Option key={"4"} value={"Reference No"}>
										{"Reference No"}
									</Option>
								</Select>
							)}
							<Input
								placeholder={"Search By Project Name or Tags"}
								onChange={(e) => setfilterTerm(e.target.value)}
								allowClear
								style={{ width: 250 }}
							/>
							{layout === Layout.horizontal && <>
								<ProjectFilters filters={filters} setFilters={setFilters} />
								{/* <Dropdown overlay={menu}>
									<StyledButton type="primary" ghost>
										<MenuOutlined />
										{t("common.btn.sort")} <DownOutlined />
									</StyledButton>
								</Dropdown> */}
							</>
							}
						</Space>
					}
				>
					<Space direction="vertical" size="large" style={{ width: "100%" }}>
						{layout === Layout.horizontal ? <>
							{!!filteredProjects.find(
								(project: any) => project?.favourite
							) && (
									<>
										<Space className="pinned__project__title" style={{ display: 'flex', alignItems: "center", marginBottom: 16 }}>
											<PushpinOutlined
												style={{ fontSize: antdTheme.fontSizeHeading3 }}
											/>
											<Title style={{ marginTop: 0, marginBottom: 0 }} level={4}>{t("projects.pinned")}</Title>
										</Space>
										<ProjectCard
											relatedProjects={filteredProjects.filter((project: any) =>
												Boolean(project?.favourite)
											)}
											getPinAccess={getPinAccess}
											getProjectStatus={getProjectStatus}
										/>
									</>
								)}

							<ProjectCard
								relatedProjects={filteredProjects}
								getPinAccess={getPinAccess}
								getProjectStatus={getProjectStatus}
							/>
							<Divider style={{ marginTop: 0 }} />
						</>
							:
							<ProjectTable
								filteredProjects={filteredProjects}
								projectType={projectType}
								getPinAccess={getPinAccess}
								getProjectStatus={getProjectStatus}
							/>
						}
					</Space>
				</StyledCard>
			</Space>
		</motion.div>
	)
}

export const CreateProjectTabs = ({ t, push }: any) => {
	const [newForm] = useForm()
	const [templateForm] = useForm()
	const requiredFieldStar = useRequiredFieldStar()

	const dispatch = useDispatch()
	const [tabType, setTabType] = useState<string>("new")
	const categories = useSelector((state: StoreState) => state.projects.projectList.map(project => project.category))
	const categoryOptions = useMemo(() => [...new Set(categories)].map(category => ({ label: category, value: category })), [categories])
	const { listTemplate: { status, data: templateData } } = useSelector((state: StoreState) => state.templates)

	useEffect(() => {
		dispatch(listTemplateRequest())
	}, [dispatch])

	return (
		<Tabs
			activeKey={tabType}
			onChange={(value: string) => {
				setTabType(value);
			}}
			style={{ height: "auto", width: "100%" }}
		>
			<Tabs.TabPane tab={"New"} key={"new"}>
				<Form
					layout="vertical"
					onFinish={(values) =>
						dispatch(newProjectRequest({ ...values }))
					}
					form={newForm}
					requiredMark={false}
					style={{ width: "100%", marginTop: 12, marginBottom: 12 }}
				>
					<Form.Item
						name="name"
						label={t("projects.projectName")}
						validateFirst
						rules={[
							{
								required: true,
								message: "required",
								transform: (value: any) => value?.trim(),
							},
						]}
						required tooltip={requiredFieldStar}
					>
						<Input minLength={1} style={{ minWidth: 300 }} placeholder={t("projects.projectName")} />
					</Form.Item>
					<Form.Item
						name="category"
						label={t("common.category")}
						validateFirst
						rules={[
							{
								required: true,
								message: "required",
								transform: (value: any) => value?.trim(),
							},
						]}
						required tooltip={requiredFieldStar}
					>
						<AutoComplete
							style={{ width: "100%", minWidth: 300 }}
							showSearch
							filterOption={true}
							options={categoryOptions}
							placeholder={t("common.category")}
						/>
					</Form.Item>
					<Row justify="end">
						<StyledButton
							size="middle"
							htmlType="submit"
							type="primary"
						>
							{t("common.create")}
						</StyledButton>
					</Row>
				</Form>
			</Tabs.TabPane>
			<Tabs.TabPane tab={t("common.FromTemplate")} key={"template"}>
				<Form
					layout="vertical"
					onFinish={(values) =>
						push("/project/update", { template_id: values.template })
					}
					form={templateForm}
					requiredMark={false}
					style={{ width: "100%", marginTop: 12, marginBottom: 12 }}
				>
					<Form.Item
						name="template"
						label={t("common.templates")}
						validateFirst
						rules={[
							{
								required: true,
								message: "required",
								transform: (value: any) => value?.trim(),
							},
						]}
						required tooltip={requiredFieldStar}
					>
						<Select
							optionFilterProp="children"
							style={{ width: "100%", minWidth: 300 }}
							placeholder="Please select a template"
							disabled={status === AsyncStates.LOADING}
						>
							{templateData?.filter((res: any) => res?.template_type === "project")?.map((temp: any) => {
								return (
									<Select.Option
										key={temp.template_id}
										value={temp.template_id}
									>
										{temp.name}
									</Select.Option>
								)
							})}
						</Select>
					</Form.Item>
					<Row justify="end">
						<StyledButton
							size="middle"
							htmlType="submit"
							type="primary"
						>
							{t("common.create")}
						</StyledButton>
					</Row>
				</Form>
			</Tabs.TabPane>
		</Tabs>
	)
}
