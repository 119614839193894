import { axiosInstance } from "src/utils/sendApi";
import { Headers } from "../interface";

const RepositoryService = {
  getMethods: (property_id: string, headers: Headers) =>
    axiosInstance.get(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/${property_id}/methods`,
      {
        headers,
      }
    ),
  getStandards: (property_id: string, headers: Headers) =>
    axiosInstance.get(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/property/${property_id}/standards`,
      {
        headers,
      }
    ),
  createStandard: (property_id: string, payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/property/${property_id}/standards`,
      payload,
      {
        headers,
      }
    ),
  updateStandards: (property_id: string, payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/property/${property_id}/standards`,
      payload,
      {
        headers,
      }
    ),
    
  getSpecimens: (property_id: string, headers: Headers) =>
    axiosInstance.get(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/property/${property_id}/specimens`,
      {
        headers,
      }
    ),
  createSpecimens: (property_id: string, payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/property/${property_id}/specimens`,
      payload,
      {
        headers,
      }
    ),
  updateSpecimens: (property_id: string, payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL +
        `v1/data/repo/property/${property_id}/specimens`,
      payload,
      {
        headers,
      }
    ),
};

export default RepositoryService;
