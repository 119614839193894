import { useCallback, useMemo } from "react";
import {
  Space,
  Table,
  Tooltip,
  Typography,
  Checkbox,
  Empty,
  Row,
  Popconfirm,
} from "antd";
import { StoreState } from "src/store/configureStore";
import {
  DownOutlined,
  UpOutlined,
  LoadingOutlined,
  RightCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { blue, geekblue, volcano } from "@ant-design/colors";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import "./InfiniteTable.scss";
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { useHistory } from "react-router-dom";
import { setProject } from "src/store/actions/projects";
import { StyledButton } from "src/styled_components/StyledButton";
import { FormulationsFilterData } from "src/services/formulation/interface";

const { Text } = Typography;

const Styles = {
  cursor: "pointer",
};

export const InfiniteTable = ({
  selected,
  selectAll,
  formulations,
  callbacks,
  sorting,
  columns,
  columnsLabels,
  drawerDetails,
  selectedFormulation,
}: any) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    drawerHandle,
    handleSelected,
    loadMoreItems,
    setSorting,
    onSelectAll,
  } = callbacks;
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data
  );
  const projectList = useSelector(
    (state: StoreState) => state.projects.projectList
  );
  const apiFilterFormulationsStatus = useSelector<StoreState, AsyncStates>(
    (state) => state.formulationsFilter.status
  );
  const configs = useSelector((state: StoreState) => state.configs.features);
  const filterFormulations = useSelector<StoreState, FormulationsFilterData>(
    (state) => state.formulationsFilter.formulationsFilterData
  );

  const getAccessor = (key: string) => {
    switch (key) {
      case "project":
        return "project_id";
      case "modified":
        return "updated";
      default:
        return key;
    }
  };

  const sortRecords = useCallback(
    (type: string, order: string) => {
      setSorting((prevState: any) => {
        if (prevState?.sort_order === order && prevState?.sort_on === type) {
          return { sort_on: null, sort_order: null };
        } else {
          return { sort_on: type, sort_order: order };
        }
      });
    },
    [setSorting]
  );

  const tableColumns = useMemo(() => {
    return [
      {
        Header: (
          <Space>
            <Checkbox onChange={onSelectAll} indeterminate={selectAll}>
              {t("common.formulation")}
            </Checkbox>
            {
              <Tooltip title={t("common.descendingSort")}>
                {" "}
                <DownOutlined
                  onClick={() =>
                    sortRecords("formulation_display_id", "descending")
                  }
                  style={{
                    color:
                      sorting.sort_on === "formulation_display_id" &&
                        sorting.sort_order === "descending"
                        ? volcano[3]
                        : "initial",
                    ...Styles,
                  }}
                />
              </Tooltip>
            }
            {
              <Tooltip title={t("common.ascendingSort")}>
                {" "}
                <UpOutlined
                  onClick={() =>
                    sortRecords("formulation_display_id", "ascending")
                  }
                  style={{
                    color:
                      sorting.sort_on === "formulation_display_id" &&
                        sorting.sort_order === "ascending"
                        ? volcano[3]
                        : "initial",
                    ...Styles,
                  }}
                />
              </Tooltip>
            }
          </Space>
        ),
        accessor: "formulation_display_id",
      },
      ...Object.entries(columns || {})
        .filter(([key, value]: any) => !!value)
        .map(([key, value]: any) => {
          const accessor = getAccessor(key);
          return {
            Header: (
              <Space>
                {columnsLabels[key]}
                {!["project_id", "work_order", "stage_name", "lab_location", "work_order_type"].includes(
                  accessor
                ) && (
                    <>
                      {
                        <Tooltip title={t("common.descendingSort")}>
                          {" "}
                          <DownOutlined
                            onClick={() => sortRecords(accessor, "descending")}
                            style={{
                              color:
                                sorting.sort_on === accessor &&
                                  sorting.sort_order === "descending"
                                  ? volcano[3]
                                  : "initial",
                              ...Styles,
                            }}
                          />
                        </Tooltip>
                      }
                      {
                        <Tooltip title={t("common.ascendingSort")}>
                          {" "}
                          <UpOutlined
                            onClick={() => sortRecords(accessor, "ascending")}
                            style={{
                              color:
                                sorting.sort_on === accessor &&
                                  sorting.sort_order === "ascending"
                                  ? volcano[3]
                                  : "initial",
                              ...Styles,
                            }}
                          />
                        </Tooltip>
                      }
                    </>
                  )}
              </Space>
            ),
            accessor: accessor,
          };
        }),
    ];
  }, [columns, sorting, onSelectAll, selectAll, sortRecords, t, columnsLabels]);

  const tableData = useMemo(
    () =>
      formulations?.formulation_data?.map((data: any, key: number) => ({
        ...data,
        key,
      })),
    [formulations?.formulation_data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableColumns, data: tableData });

  const getTooltipTitle = (tooltipTitleInfo: any) => {
    return !!tooltipTitleInfo.length ? tooltipTitleInfo : null;
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%", overflowX: "auto" }}>
        {/* <InfiniteScroll
				height={Boolean(configs?.nestle_configs) ? "450px" : "500px"}
				next={loadMoreItems}
				dataLength={formulations.formulation_data?.length}
				hasMore={formulations.formulation_data?.length < formulations.total_records}
				loader={<Spin indicator={<LoadingOutlined />} size="large" spinning>
					<div style={{ textAlign: 'center', height: "100px" }}></div>
				</Spin>}
				
			> */}
        {!!formulations?.formulation_data?.length ? (
          <table className="infinite-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      className="infinite-table-header"
                      {...column.getHeaderProps(column)}
                      style={
                        column.id !== "formulation_display_id"
                          ? { textAlign: "center" }
                          : {}
                      }
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: any) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={drawerHandle.bind(
                      formulations?.formulation_data?.[i]
                    )}
                    {...row.getRowProps()}
                    style={
                      drawerDetails.formulation_id ===
                        formulations.formulation_data?.[i]?.formulation_id ||
                        [...selected.keys()].includes(
                          formulations.formulation_data?.[i]?.formulation_id
                        )
                        ? { backgroundColor: blue[0], cursor: "pointer" }
                        : !!selectedFormulation
                          ? { background: volcano[0], cursor: "pointer" }
                          : { cursor: "pointer" }
                    }
                  >
                    {row.cells.map((cell: any, index: any) => {
                      if (cell.column.id === "formulation_display_id") {
                        return (
                          <td {...cell.getCellProps()}>
                            <Row
                              style={{ width: "100%" }}
                              justify="space-between"
                            >
                              <Space>
                                <Checkbox
                                  onChange={handleSelected.bind(
                                    formulations.formulation_data?.[i]
                                      ?.formulation_id
                                  )}
                                  checked={selected.get(
                                    formulations.formulation_data?.[i]
                                      ?.formulation_id
                                  )}
                                />
                                <Space
                                  style={{ padding: 0, cursor: "pointer" }}
                                >
                                  <RightCircleOutlined
                                    style={{
                                      color: geekblue[4],
                                      marginLeft: 15,
                                    }}
                                  />
                                  <Text
                                    style={{ width: 80 }}
                                    ellipsis={{ tooltip: cell.value }}
                                  >
                                    {cell.value}
                                  </Text>
                                </Space>
                              </Space>
                              {Boolean(configs?.pcm_graphs) && (
                                <Space>
                                  {
                                    <Tooltip
                                      title={getTooltipTitle(
                                        Array.from(
                                          new Set(
                                            Object.entries(
                                              formulations?.formulation_data?.[
                                                i
                                              ]?.ingredients || {}
                                            )?.map(
                                              ([key, values]: any) =>
                                                values?.category
                                            )
                                          )
                                        )
                                          ?.filter(
                                            (currentCatergory) =>
                                              currentCatergory
                                          )
                                          ?.map((category) => (
                                            <div>{category}</div>
                                          ))
                                      )}
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  }
                                </Space>
                              )}
                            </Row>
                          </td>
                        );
                      } else if (
                        ["created", "updated"].includes(cell.column.id)
                      ) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="table-data-center"
                          >
                            {new Date(cell.value).toLocaleString([], {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </td>
                        );
                      } else if (cell.column.id === "material") {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="table-data-center"
                          >
                            <Text
                              style={{ width: 100 }}
                              ellipsis={{
                                tooltip:
                                  displayNames?.material?.[cell?.value]?.name ||
                                  cell?.value ||
                                  "-",
                              }}
                            >
                              {displayNames?.material?.[cell?.value]?.name ||
                                cell?.value ||
                                "-"}
                            </Text>
                          </td>
                        );
                      } else if (cell.column.id === "project_id") {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="table-data-center"
                          >
                            <Text
                              style={{ width: 100 }}
                              ellipsis={{
                                tooltip: projectList.find(
                                  (res: any) => res?.project_id === cell.value
                                )?.name,
                              }}
                            >
                              {
                                projectList.find(
                                  (res: any) => res?.project_id === cell.value
                                )?.name
                              }
                            </Text>
                          </td>
                        );
                      } else if (cell.column.id === "work_order") {
                        return (
                          <td
                            className="table-data-center"
                            {...cell.getCellProps()}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Popconfirm
                              title={`${t("projects.open")} ${cell.value}`}
                              onConfirm={() => {
                                dispatch(resetWorkOrder());
                                dispatch(
                                  setProject(
                                    formulations.formulation_data?.[i]
                                      ?.project_id
                                  )
                                );
                                history.push(
                                  `/work-orders/details/${formulations.formulation_data?.[i]?.work_order_id}/`
                                );
                              }}
                            >
                              <Text
                                style={{
                                  width: 100,
                                  color: blue.primary,
                                  cursor: "pointer",
                                }}
                                ellipsis={{
                                  tooltip: {
                                    title: cell.value,
                                    placement: "left",
                                  },
                                }}
                              >
                                {cell.value}
                              </Text>
                            </Popconfirm>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className="table-data-center"
                            {...cell.getCellProps()}
                          >
                            <Text
                              style={{ width: 100 }}
                              ellipsis={{ tooltip: cell.value }}
                            >
                              {cell.value}
                            </Text>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Table
            loading={{
              spinning: apiFilterFormulationsStatus === AsyncStates.LOADING,
              indicator: <LoadingOutlined />,
            }}
            locale={{
              emptyText:
                apiFilterFormulationsStatus === AsyncStates.LOADING ? (
                  ""
                ) : (
                  <Empty
                    description={t(
                      "formulations.noFormulationUnderSelectedProject"
                    )}
                  />
                ),
            }}
          />
        )}
        {/* </InfiniteScroll> */}
      </Space>
      {filterFormulations &&
        rows &&
        filterFormulations.total_records > rows.length && (
          <Space
            style={{
              width: "100%",
              padding: 8,
              paddingRight: 0,
              justifyContent: "flex-end",
            }}
          >
            <StyledButton
              style={{ outline: "none" }}
              onClick={loadMoreItems}
              loading={apiFilterFormulationsStatus === AsyncStates.LOADING}
            >
              Load More
            </StyledButton>
          </Space>
        )}
    </>
  );
};
