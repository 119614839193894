import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import {
  Col,
  message,
  Row,
  Tag,
  Space,
  Table,
  TableColumnsType,
  Cascader,
  Popover,
  Input,
  Popconfirm,
  Typography,
  Tooltip,
} from "antd";
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { antdTheme, AsyncStates } from "src/constants"
import { FilterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux"
import {
  deletePredictionIdsCleanup,
  deletePredictionIdsRequest,
  getPredictionRequest,
} from "src/store/actions/inverseModel"
import { StoreState } from "src/store/configureStore"
import { DisplayNames, ModelConfig } from "src/typings"
import { suggestedExperimentsClear } from "src/store/actions/suggestedExp"
import { Filter } from "../InverseModelNew/catwise/inverse-model"
import { useHistory } from "react-router-dom"
import { ColumnsType } from "antd/es/table"
import "./InverseResults.scss"
import {
  generateVersionName,
  predictionStatus,
  predictionStatusColorText,
  predictionStatusIcon,
} from "src/utils/decorator"
import { useValue } from "src/utils/useValue"
import { useTranslationLabel } from "src/utils/hooks/useTranslationLabel"
import { AiOutlineSisternode } from "react-icons/ai";
import dayjs from "dayjs";
import { AvatarComponent, dateSorter, stringSorter } from "src/components/DashboardUpdated/Dashboard";
import { 
  PiNumberSquareOneFill,
  PiNumberSquareTwoFill,
  PiNumberSquareThreeFill,
  PiNumberSquareFourFill,
  PiNumberSquareFiveFill,
  PiNumberSquareSixFill,
  PiNumberSquareSevenFill,
  PiNumberSquareEightFill,
  PiNumberSquareNineFill,
  PiNumberSquareZeroFill
 } from "react-icons/pi";
 import { useMemberName } from 'src/utils/useMemberName'
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import StyledBadge from "src/styled_components/StyledBadge";

const { Search } = Input

type P = {
  inverseCurrent: number
  setInverseCurrent: Dispatch<SetStateAction<number>>
  inverseFilters: Filter | null
  setInverseFilters: Dispatch<SetStateAction<Filter | null>>
  selectedObjective: string
}

export const ObjectivesIcon=({number}:{number:number})=>{
  const [t] = useTranslate()

  const getNumberIcon = (n: string | number) => {

    const style: React.CSSProperties ={ 
      display:'flex',
      fontSize: antdTheme.fontSizeHeading3,
    }

    switch (n) {
      case '1':
      case 1:
        return <PiNumberSquareOneFill style={style}/>
      case '2':
      case 2:
        return <PiNumberSquareTwoFill style={style}/>
      case '3':
      case 3:
        return <PiNumberSquareThreeFill style={style}/>
      case '4':
      case 4:
        return <PiNumberSquareFourFill style={style}/>
      case '5':
      case 5:
        return <PiNumberSquareFiveFill style={style}/>
      case '6':
      case 6:
        return <PiNumberSquareSixFill style={style}/>
      case '7':
      case 7:
        return <PiNumberSquareSevenFill style={style}/>
      case '8':
      case 8:
        return <PiNumberSquareEightFill style={style}/>
      case '9':
      case 9:
        return <PiNumberSquareNineFill style={style}/>
      case '0':
      case 0:
        return <PiNumberSquareZeroFill style={style}/>
      default:
        return null
    }
  }

  return <Tooltip title={`${t('aiEngine.objectives')} : ${number}`}>
      <Space
      style={{
        color: 'black',
        display:'flex',
        gap: '0'
      }}
    >
      {String(number).split('').map((n)=>getNumberIcon(n))}
    </Space>
    </Tooltip>
}

const InverseResults = ({
  inverseCurrent,
  setInverseCurrent,
  inverseFilters,
  setInverseFilters,
  selectedObjective,
}: P) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { getValue } = useValue()
  const { getName } = useMemberName()
  const { push } = useHistory()
  const [inversePageSize, setInversePageSize] = useState(10)
  const [deleteExperimentIds, setDeleteExperimentIds] = useState<string[]>([])
  const inverseModel = useSelector((state: StoreState) => state.inverseModel)
  const configData = useSelector(
    (state: StoreState) => state.formulate.configData,
  ) as ModelConfig[]
  const configStatus = useSelector(
    (state: StoreState) => state.formulate.configStatus,
  )
  const teams = useSelector((state: StoreState) => state.teams.data)

  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  ) as DisplayNames

  const configs = useSelector((state: StoreState) => state.configs.features)
  const { newInverseModel } = useSelector(
    (state: StoreState) => state.newInverseModel,
  )
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )

  const projectsUsedInPredictions = useSelector((state: StoreState) => state.formulate.projectsUsedInPredictions?.inverse)

  useEffect(() => {
    if (
      deleteExperimentIds.includes(
        selectedObjective ?? newInverseModel?.prediction_id,
      ) &&
      inverseModel.statusDeletePredictionIds === AsyncStates.SUCCESS
    ) {
      dispatch(suggestedExperimentsClear())
    }
  }, [
    newInverseModel?.prediction_id,
    selectedObjective,
    deleteExperimentIds,
    inverseModel.statusDeletePredictionIds,
    dispatch,
  ])

  useEffect(() => {
    if (inverseModel.statusDeletePredictionIds === AsyncStates.SUCCESS) {
      dispatch(
        getPredictionRequest(
          inverseFilters ?? {
            pageNum: 1,
          },
        ),
      )
      setInverseCurrent(1)
      setInversePageSize(10)
      dispatch(deletePredictionIdsCleanup())
      setDeleteExperimentIds([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    inverseFilters,
    inverseModel.statusDeletePredictionIds,
    inverseCurrent,
    deleteExperimentIds.length,
    inverseModel.predictionIdsData.data,
  ])

  const deleteObjectives = useCallback(() => {
    if (deleteExperimentIds.length) {
      dispatch(
        deletePredictionIdsRequest({ prediction_ids: deleteExperimentIds }),
      )
      setInverseCurrent(1)
      setInversePageSize(10)
    } else {
      message.error(t("aiEngine.recordNotSelected"))
    }
  }, [deleteExperimentIds, dispatch, setInverseCurrent, t])

  function pageChange(pageNum: number, pageSize: number) {
    const payload = { ...inverseFilters, pageNum, pageSize }
    setInverseCurrent(pageNum)
    setInversePageSize(pageSize)
    dispatch(getPredictionRequest(payload))
  }

  const tLabel = useTranslationLabel()

  const inverseResultsColumns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: `${t("aiEngine.objective")} / ${t("common.title")}`,
      dataIndex: "title",
      key: "title",
      sorter: (a: any, b: any) => {
        return a.title.localeCompare(b.title)
      },
      render: (value, record) => {
        return (
          <Space
            size={'small'}
            style={{
              color: 'black',
              ...(record?.notRead ? { fontWeight: 'bold' } : {})
            }}
          >
            <StyledBadge dot color={record?.notRead ? antdTheme.colorPrimary : "#fff"} />
            <Typography.Text ellipsis={{
              tooltip: value
            }} style={{ width: '250px' }}>{value}</Typography.Text>
            
            <ObjectivesIcon number={record.objectives} />
            
          </Space>
        )
      },
      width: 250,
      fixed: "left",
    },
    {
      title: t("common.model"),
      dataIndex: "model",
      key: "model",
      sorter: (a: any, b: any) => {
        return a.model.localeCompare(b.model)
      },
      render: (text: any, record: any) => {
        return (
          <Space size={'small'} style={{
            verticalAlign:'middle',
          }} >
            <Tooltip title={record.model_type}>
                <AiOutlineSisternode
                  color={record?.notRead ? antdTheme.colorPrimary : "black"}
                  style={{
                    display: 'flex',
                    fontSize: antdTheme.fontSizeHeading4,
                    visibility:
                      record.model_type === 'Single Stage'
                        ? 'hidden'
                        : 'visible',
                    ...(!record?.notRead ? { opacity: '0.65' } : {})
                  }}
                />
            </Tooltip>

            <Typography.Text
              ellipsis={{
                tooltip: text
              }}
              style={
                record?.notRead
                  ? { fontWeight: 'bold', width: '150px' }
                  : { width: '150px' }
              }
            >
              {text}
            </Typography.Text>
          </Space>
        )
      },
      width: 150,
    },
    {
      title: t("aiEngine.status"),
      key: "status",
      dataIndex: "status",
      sorter: (a: any, b: any) => {
        return a.status.localeCompare(b.status)
      },
      render: (tag) => {
        return (
          <Tag
            color={predictionStatusColorText[tag]}
            icon={predictionStatusIcon[tag]}
            style={{
              textTransform: "capitalize",
              border: "1px solid",
              fontWeight: "bold",
            }}
          >
            {tLabel(predictionStatus[tag])}
          </Tag>
        )
      },
    },
    {
      title: t("common.project"),
      dataIndex: "project_name",
      key: "project_name",
      sorter: (a: any, b: any) => {
        return a?.["project_name"]?.localeCompare(b?.["project_name"])
      },
      render: (text: any, record: any) => {
        return (
          <Typography.Text
            ellipsis={{
              tooltip: text,
            }}
            style={record?.notRead ? { fontWeight: "bold", width: 100 } : { width: 100 }}
          >
            {text}
          </Typography.Text>
        )
      },
    },
    {
      title: t("aiEngine.requestedOn"),
      dataIndex: "requested_on",
      key: "requested_on",
      sorter: (a: any, b: any) => {
        return dateSorter(a.requested_on, b.requested_on)
      },
      render: (text: any, record: any) => {
        return <Typography.Text style={record?.notRead ? { fontWeight: 'bold' } : {}}>{
          dayjs(text).format('DD MMM YYYY HH:mm')
        }</Typography.Text>
      }
    },
    {
      title: t("common.createdBy"),
      dataIndex: "created_by",
      key: "created_by",
      sorter: (a: any, b: any, c: any) => {
        return stringSorter(getName(a.created_by), getName(b.created_by))
      },
      render: (text: any, record: any) => <AvatarComponent id={text} />
    },
  ]

  const [serachField, setSearchField] = useState<string>("")

  const inverseResultsData = useMemo(() => {
    return inverseModel.predictionIdsData.data.map((item: any, idx: number) => {
      console.log(item)
      const isMultistage = item?.is_multistage || false
      const data = {
        key: item.prediction_id,
        title: item.title || "Prediction",
        model: generateVersionName(item.version, configData),
        model_type: isMultistage
          ? t("aiEngine.multiStage")
          : t("aiEngine.singleStage"),
        objectives: isMultistage ? Object.values(item?.objectives || {}).map((i: any) => Object.keys(i)).flat()
          .length : Object.entries(item?.objectives || {}).length,
        created_by: item.user_id,
        requested_on: item.updated,
        status: item.status,
        is_multistage: isMultistage,
        notRead: !item?.read?.includes(userId),
        project_name: item?.project_name
      }
      return data
    })
  }, [configData, inverseModel.predictionIdsData.data, t, userId])

  const expandedRowRender = (record: any) => {
    const isMultistage = record?.is_multistage || false

    if (isMultistage) return expandTableMultiStage(record)
    else return expandTable(record)
  }

  const expandTable = (record: any) => {
    const columns: TableColumnsType<any> = [
      { title: "Objective", dataIndex: "objective", key: "objective" },
      // {
      //   title: "Historical Experiment Range",
      //   dataIndex: "range",
      //   key: "range",
      // },
      {
        title: t("aiEngine.optimizationType"),
        key: "optimization_type",
        dataIndex: "optimization_type",
      },
      { title: t("common.min"), dataIndex: "min", key: "min" },
      { title: t("common.max"), dataIndex: "max", key: "max" },
      ...(Boolean(configs?.kurita_configs) ||
        Boolean(configs?.catwise_inverse_constraints) ||
        Boolean(configs?.kuraray_configs) ||
        Boolean(configs?.nestle_configs)
        ? [
          {
            title: t("common.priority"),
            dataIndex: "priority",
            key: "priority",
          },
        ]
        : []),
    ]

    const data = []
    const objectives = inverseModel.predictionIdsData.data.find(
      (preds: any) => preds?.prediction_id === record?.key,
    ).objectives

    const priority = inverseModel.predictionIdsData.data.find(
      (preds: any) => preds?.prediction_id === record?.key,
    ).priority_list


    for (let obj of Object.keys(objectives)) {
      const actualObjective = objectives?.[obj]
      data.push({
        key: obj,
        objective:
          displayNames?.properties?.[obj]?.name ||
          obj
            .split("_")
            .map((value: string) => value[0].toUpperCase() + value.slice(1))
            .join(" "),
        range: actualObjective?.range,
        optimization_type:
          typeof actualObjective === "object" &&
            !Array.isArray(actualObjective) &&
            actualObjective !== null
            ? t("common.range")
            : `${actualObjective[0].toUpperCase()}${actualObjective.slice(1)}`,
        min: getValue(actualObjective?.min),
        max: getValue(actualObjective?.max),
        ...(Boolean(configs?.kurita_configs) ||
          Boolean(configs?.catwise_inverse_constraints) ||
          Boolean(configs?.kuraray_configs) ||
          Boolean(configs?.nestle_configs)
          ? { priority: priority?.[obj] }
          : {}),
      })
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{ padding: "24px" }}
        className="objectives-expandable-table"
      />
    )
  }

  const expandTableMultiStage = (record: any) => {
    const columns: TableColumnsType<any> = [
      { title: "Objective", dataIndex: "objective", key: "objective" },
      { title: "Stage", dataIndex: "stage", key: "stage" },
      // {
      //   title: "Historical Experiment Range",
      //   dataIndex: "range",
      //   key: "range",
      // },
      {
        title: t("aiEngine.optimizationType"),
        key: "optimization_type",
        dataIndex: "optimization_type",
      },
      { title: t("common.min"), dataIndex: "min", key: "min" },
      { title: t("common.max"), dataIndex: "max", key: "max" },
      {
        title: t("common.priority"),
        dataIndex: "priority",
        key: "priority",
      },
    ]

    const data = []
    let objectives = inverseModel.predictionIdsData.data.find(
      (preds: any) => preds?.prediction_id === record?.key,
    ).objectives

    let priority = inverseModel.predictionIdsData.data.find(
      (preds: any) => preds?.prediction_id === record?.key,
    ).priority_list

    for (let stage of Object.keys(objectives)) {
      for (let obj of Object.keys(objectives[stage])) {
        const actualObjective = objectives?.[stage]?.[obj]
        data.push({
          key: obj,
          objective:
            displayNames?.properties?.[obj]?.name ||
            obj
              .split("_")
              .map((value: string) => value[0].toUpperCase() + value.slice(1))
              .join(" "),
          stage: stage,
          range: actualObjective?.range,
          optimization_type:
            typeof actualObjective === "object" &&
              !Array.isArray(actualObjective) &&
              actualObjective !== null
              ? t("common.range")
              : `${actualObjective[0].toUpperCase()}${actualObjective.slice(
                1,
              )}`,
          min: getValue(actualObjective?.min),
          max: getValue(actualObjective?.max),
          priority: priority?.[stage]?.[obj],
        })
      }
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{ padding: "24px" }}
        className="objectives-expandable-table"
      />
    )
  }

  const options: any[] = useMemo(() => [
    {
      value: "versions",
      label: t("common.model"),
      children: configData
        .filter((res) => !!res.is_custom)
        .map((res) => ({
          value: res.version, label: <Typography.Text
            style={{ width: 250 }}
            ellipsis={{ tooltip: generateVersionName(res.version, configData) }}
          >{generateVersionName(res.version, configData)}</Typography.Text>
        })),
    },
    {
      value: "status",
      label: t("aiEngine.status"),
      children: [
        {
          value: "Completed",
          label: t("common.completed"),
        },
        {
          value: "In Progress",
          label: t("common.inProgress"),
        },
        {
          value: "Failed",
          label: t("common.failed"),
        },
      ],
    },
    {
      value: "model_type",
      label: t("aiEngine.modelType"),
      children: [
        {
          value: "Single Stage",
          label: t("aiEngine.singleStage"),
        },
        {
          value: "Multi Stage",
          label: t("aiEngine.multiStage"),
        },
      ],
    },
    {
      value: "user_ids",
      label: t("aiEngine.user"),
      children: teams?.map((res: any) => ({
        value: res.user_id,
        label: res?.user_name,
      })),
    },
    ...(!!projectsUsedInPredictions?.length ?
      [{
        value: "project_ids",
        label: t("inventory.Projects"),
        children: projectsUsedInPredictions?.map((project) => ({
          value: project.project_id,
          label: (
            <Typography.Text
              style={{ width: 250 }}
              ellipsis={{ 
                tooltip: <>{project?.project_name}  {!!project?.category_name?.length && <Typography.Text style={{ color: "#8C8C8C" }}>&#x2022; {project.category_name}</Typography.Text>}</> }}
            >
              {project?.project_name}  {!!project?.category_name?.length && <Typography.Text style={{ color: "#8C8C8C" }}>&#x2022; {project.category_name}</Typography.Text>} 
            </Typography.Text>
          ),
        })),
      }] : []),
  ], [configData, projectsUsedInPredictions, t, teams])

  const [filterSelections, setFilterSelections] = useState<any[]>([])

  function clearFilters() {
    setFilterSelections([])
    setInverseFilters(null)
    setInverseCurrent(1)
    setInversePageSize(10)
    dispatch(getPredictionRequest({ pageNum: 1 }))
  }

  function applyFilters(values: any) {
    let interimPayload: { [key: string]: string[] } = {}
    for (const value of values) {
      if (value.length === 1) {
        interimPayload[value[0]] = options
          .find((op) => op.value === value[0])
          .children.map((child: any) => child.value)
      }
      if (value.length === 2) {
        if (interimPayload[value[0]]) {
          interimPayload[value[0]].push(value[1])
        } else {
          interimPayload[value[0]] = [value[1]]
        }
      }
    }
    const { status, versions, user_ids, model_type, project_ids } = interimPayload

    const payload = {
      pageNum: 1,
      user_ids: user_ids || [],
      model_type: model_type || [],
      status: status || [],
      versions: versions || [],
      title: serachField || "",
      project_ids: project_ids || []
    }
    setInverseFilters(payload)
    setInverseCurrent(1)
    setInversePageSize(10)
    dispatch(getPredictionRequest(payload))
    setIsFilterOpen(false)
  }

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  useEffect(() => {
    const initialFilters = Object.keys(inverseFilters || {}).filter(x => x !== 'pageNum' && x !== 'title').map((key: string) => {
      return inverseFilters?.[key as keyof Omit<Filter, 'pageNum' | 'title'>]?.map((value: string) => {
        return [key, value]
      })
    }).flat()
    setFilterSelections(initialFilters)
  }, [inverseFilters])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {/* Top action bar */}
      <Space style={{ justifyContent: "flex-end", width: "100%" }}>
        <Popconfirm
          title={`${t("common.delete")} selected record(s)`}
          icon={<StyledDeleteIcon />}
          okText={t("common.confirm")}
          cancelText={t("common.cancel")}
          onConfirm={() => deleteObjectives()}
        >
          <StyledButton
            loading={
              inverseModel.statusDeletePredictionIds === AsyncStates.LOADING
            }
            icon={<StyledDeleteIcon />}
            disabled={deleteExperimentIds.length <= 0}
          >
            {t("aiEngine.deleteRecords")}
          </StyledButton>
        </Popconfirm>
        <Popover
          content={
            <Space direction="vertical">
              <Cascader.Panel
                multiple
                options={options}
                onChange={(value: any, selections: any) => {
                  setFilterSelections(value)
                }}
                value={filterSelections}
                style={{ maxHeight: '300px', overflow: "auto" }}
              />
              <Row justify="end" gutter={16}>
                <Col>
                  <StyledButton
                    type="primary"
                    onClick={() => {
                      applyFilters(filterSelections)
                    }}
                  >
                    {t("aiEngine.button.apply")}
                  </StyledButton>
                </Col>
                <Col>
                  <StyledButton onClick={() => clearFilters()} type="dashed">
                    {t("common.clear")}
                  </StyledButton>
                </Col>
              </Row>
            </Space>
          }
          placement="bottomLeft"
          trigger={"click"}
          open={isFilterOpen}
          onOpenChange={(visible) => setIsFilterOpen(visible)}
        >
          <StyledButton
            icon={<FilterOutlined />}
            className={isFilterOpen ? "action-button-active" : ""}
          >
            {t("common.filters")}
          </StyledButton>
        </Popover>
        <Search
          placeholder={t("common.search")}
          value={serachField}
          style={{ width: 300 }}
          onChange={(e) => {
            setSearchField(e.target.value)
          }}
          onSearch={() => {
            applyFilters(filterSelections)
          }}
        />
      </Space>

      {/* Prediction history table */}
      <Table
        columns={inverseResultsColumns}
        dataSource={inverseResultsData}
        onRow={(record) => {
          return {
            onClick: () => {
              dispatch(suggestedExperimentsClear())
              push(
                `/ai-engine/history/formulation_prediction?predId=${record?.key}`,
              )
            },
            style: { cursor: "pointer" },
          }
        }}
        showSorterTooltip={false}
        pagination={{
          current: inverseCurrent,
          pageSize: inversePageSize,
          showSizeChanger: true,
          responsive: true,
          position: ["bottomRight"],
          total: inverseModel.predictionIdsData?.total,
          onChange: pageChange,
        }}
        className="predictions-table"
        loading={
          inverseModel.statusPredictionIds === AsyncStates.LOADING ||
          configStatus === AsyncStates.LOADING
        }
        rowSelection={{
          onChange: (ids) => {
            setDeleteExperimentIds(ids as string[])
          },
          getCheckboxProps: (record) => {
            return {
              disabled: record?.status === "In Progress",
            }
          },
        }}
        expandable={{ expandedRowRender }}
        style={{ width: "100%", overflow: "auto", background: "white" }}
      />
    </Space>
  )
}

export default InverseResults
