import { green, orange, red, yellow } from '@ant-design/colors'
import { useCallback, useMemo } from 'react'
import useTranslate from 'src/utils/useTranslate'

export const useModelErrorInfo = () => {
    const [t] = useTranslate()
    const errorMetricCondition: {
        [key: string]: number[]
    } = useMemo(() => {
        return {
            explained_variance_score: [1, 0.9, 0.75, 0.5],
            max_error: [0, 10, 25, 40],
            mean_absolute_error: [0, 10, 25, 40],
            mean_squared_error: [0, 10, 25, 40],
            median_absolute_error: [0, 10, 25, 40],
            r2_score: [1, 0.9, 0.75, 0.5],
            mean_absolute_percentage_error: [0, 10, 25, 40],
            accuracy_score: [1, 0.9, 0.75, 0.5],
            f1_score: [100, 90, 75, 50],
        }
    }, [])

    const getModelErrorInfo = useCallback((value: number, selectedMetric: string) => {
        const values = errorMetricCondition[selectedMetric]
        const greaterConditionType = ["max_error", "mean_absolute_error", "mean_squared_error", "median_absolute_error", "mean_absolute_percentage_error"]

        if (greaterConditionType.includes(selectedMetric) && value > values[values.length - 1]) {
            return {
                modelResult: "Worst",
                color: red[5]
            }
        } else {
            for (let i = 0; i < values.length - 1; i++) {
                if (!isNaN(value) && ((value >= values[i] && value <= values[i + 1]) || (value <= values[i] && value >= values[i + 1]))) {
                    const nextIndex = i + 1
                    if (i === 0 && nextIndex === 1) {
                        return {
                            modelResult: t("common.excellent"),
                            color: green[5]
                        }
                    }
                    else if (i === 1 && nextIndex === 2) {

                        return {
                            modelResult: t("common.good"),
                            color: yellow[5]
                        }
                    }
                    else if (i === 2 && nextIndex === 3) {
                        return {
                            modelResult: "Bad",
                            color: orange[5]
                        }
                    }
                }
            }
            if (!isNaN(value) && value < values[values.length - 1]) {
                return {
                    modelResult: "Worst",
                    color: red[5]
                }
            }
        }
    }, [errorMetricCondition, t])
    return { errorMetricCondition, getModelErrorInfo }
}

