import { createActions } from "redux-actions";

export enum FilterDataActionTypes {
  REQUEST = "FILTER_DATA_REQUEST",
  SUCCESS = "FILTER_DATA_SUCCESS",
  FORMULATION_SUCCESS = "FILTER_DATA_FORMULATION_SUCCESS",
  FAILURE = "FILTER_DATA_FAILURE",
  CLEAR = "FILTER_DATA_CLEAR",
}

export enum CustomTraingsActionTypes {
  REQUEST = "GET_CUSTOM_TRAINGS_REQUEST",
  SUCCESS = "GET_CUSTOM_TRAINGS_SUCCESS",
  FAILURE = "GET_CUSTOM_TRAINGS_FAILURE",
  CLEAR = "GET_CUSTOM_TRAINGS_CLEAR",
}

export enum CustomTrainActionTypes {
  REQUEST = "CUSTOM_TRAIN_REQUEST",
  SUCCESS = "CUSTOM_TRAIN_SUCCESS",
  FAILURE = "CUSTOM_TRAIN_FAILURE",
  CLEAR = "CUSTOM_TRAIN_CLEAR",
}
export enum EquationModelActionTypes {
  REQUEST = "EQUATION_MODEL_REQUEST",
  SUCCESS = "EQUATION_MODEL_SUCCESS",
  FAILURE = "EQUATION_MODEL_FAILURE",
  CLEAR = "EQUATION_MODEL_CLEAR",
}

export enum DeleteModelsActionTypes {
  REQUEST = "DELETE_MODEL_REQUEST",
  SUCCESS = "DELETE_MODEL_SUCCESS",
  FAILURE = "DELETE_MODEL_FAILURE",
}

export const {
  filterDataRequest,
  filterDataSuccess,
  filterDataFormulationSuccess,
  filterDataFailure,
  filterDataClear,
  getCustomTraingsRequest,
  getCustomTraingsSuccess,
  getCustomTraingsFailure,
  getCustomTraingsClear,
  customTrainRequest,
  customTrainSuccess,
  customTrainFailure,
  customTrainClear,
  equationModelRequest,
  equationModelSuccess,
  equationModelFailure,
  equationModelClear,
  deleteModelRequest,
  deleteModelSuccess,
  deleteModelFailure,
} = createActions({
  [FilterDataActionTypes.REQUEST]: (payload) => payload,
  [FilterDataActionTypes.SUCCESS]: (response) => response,
  [FilterDataActionTypes.FORMULATION_SUCCESS]: (response) => response,
  [FilterDataActionTypes.FAILURE]: () => {},
  [FilterDataActionTypes.CLEAR]: () => {},
  [CustomTraingsActionTypes.REQUEST]: (payload) => payload,
  [CustomTraingsActionTypes.SUCCESS]: (response) => response,
  [CustomTraingsActionTypes.FAILURE]: () => {},
  [CustomTraingsActionTypes.CLEAR]: () => {},
  [CustomTrainActionTypes.REQUEST]: (payload) => payload,
  [CustomTrainActionTypes.SUCCESS]: (response) => response,
  [CustomTrainActionTypes.FAILURE]: () => {},
  [CustomTrainActionTypes.CLEAR]: () => {},
  [EquationModelActionTypes.REQUEST]: (payload) => payload,
  [EquationModelActionTypes.SUCCESS]: (response) => response,
  [EquationModelActionTypes.FAILURE]: () => {},
  [EquationModelActionTypes.CLEAR]: () => {},
  [DeleteModelsActionTypes.REQUEST]: (payload) => payload,
  [DeleteModelsActionTypes.SUCCESS]: (response) => response,
  [DeleteModelsActionTypes.FAILURE]: () => {},
});
