import { Typography, Select } from 'antd';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { StyledText } from 'src/components/QualityCheck/FilesTable'
import {
  qcSetGetFilesFilters,
  qcGetFilesRequest
} from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate'

const TopHeader = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { getFilesTotal, getFilesFiltersUnsaved, getFilesFiltersEnableClear } =
    useSelector((state: StoreState) => state.qualityCheck)

  return (
    <div
      style={{ height: '5%', display: 'flex', gap: 12, alignItems: 'center' }}
      className="quality-check-files-table-header"
    >
      <Typography.Title level={5}>
        {`${t('common.items')} (${getFilesTotal})`}
      </Typography.Title>

      <CustomSelect
        type="categories"
        placeholder={t('common.selectCategory')}
      />

      <CustomSelect type="suppliers" placeholder={t('common.SelectSupplier')} />

      {getFilesFiltersUnsaved && (
        <StyledButton
          onClick={() => {
            dispatch(qcGetFilesRequest({ page_num: 1 }))
          }}
          style={{
            outline: 'none',
            height: 28,
            fontWeight: 500
          }}
          type="default"
        >
          {t('common.applyFilter')}
        </StyledButton>
      )}

      {getFilesFiltersEnableClear && (
        <StyledButton
          onClick={() => {
            dispatch(qcSetGetFilesFilters({ categories: [], suppliers: [] }))
            dispatch(qcGetFilesRequest({ page_num: 1 }))
          }}
          style={{
            outline: 'none',
            height: 28,
            fontWeight: 500
          }}
          type="default"
        >
          {t('common.clearFilter')}
        </StyledButton>
      )}
    </div>
  )
}

export default TopHeader

const CustomSelect = ({
  type,
  placeholder
}: {
  type: string
  placeholder: string
}) => {
  const dispatch = useDispatch()

  const { getFilesFiltersResponse, getFilesFilters } = useSelector(
    (state: StoreState) => state.qualityCheck
  )

  const onClear = useCallback(() => {
    dispatch(qcSetGetFilesFilters({ [type]: [] }))
  }, [dispatch, type])

  return (
    <Select
      placeholder={StyledText(placeholder)}
      style={{ width: 200 }}
      value={getFilesFilters[type]}
      onChange={(value) => {
        dispatch(qcSetGetFilesFilters({ [type]: value }))
      }}
      options={[
        ...getFilesFiltersResponse[type]?.map((category: any) => {
          return {
            label: StyledText(category),
            value: category
          }
        })
      ]}
      dropdownStyle={{ width: 200 }}
      maxTagCount={1}
      maxTagPlaceholder={(values) => StyledText(`+ ${values.length} ...`)}
      mode="multiple"
      allowClear
      onClear={() => onClear()}
    />
  )
}
