import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Drawer, message, Modal, Row, Spin, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
	deleteFiltersCleanup,
	deleteFiltersRequest,
	getSavedFiltersRemove,
	getSavedFiltersRequest,
} from "src/store/actions/saveFormulationsFilters"
import { AsyncStates } from "src/constants"
import { useMesssage } from "src/utils/hooks"
import { red } from "@ant-design/colors"
import { StyledButton } from "src/styled_components/StyledButton"
import { FormInstance } from "antd/es/form/Form"
import { GetSavedFiltersResponse } from "src/store/sagas/saveFormulationsFilters"
import { List } from "./list"
import { LoadingOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
const { Title } = Typography

type P = {
	visible: boolean
	setVisible: Dispatch<SetStateAction<boolean>>
	setFilters: Dispatch<SetStateAction<any>>
	form: FormInstance
	setOuterSelector: Dispatch<SetStateAction<string | undefined>>
	setInnerSelector: Dispatch<SetStateAction<string[]>>
	setFormState: Dispatch<SetStateAction<any>>
	filter_type?: 'formulation' | 'inventory'
	onApplyFilter?: (item: GetSavedFiltersResponse["result"]["data"][0]) => void
}

export const FiltersHistoryDrawer = ({
	setVisible,
	visible,
	setFilters,
	form,
	setOuterSelector,
	setInnerSelector,
	setFormState,
	filter_type='formulation',
	onApplyFilter
}: P) => {
	const { m } = useMesssage()
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const drawerRef = useRef<HTMLDivElement>(null)
	const [selectedFilters, setSelectedFilters] = useState<string[]>([])
	const savedFormulationsFiltersRes = useSelector((state: StoreState) => state.savedFormulationsFilters)
	const deleteFiltersRes = useSelector((state: StoreState) => state.deleteFormulationsFilters)
	const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)
	const workOrdersList = useSelector((state: StoreState) => state.workOrders.data)

	useEffect(() => {
		dispatch(
			getSavedFiltersRequest({
				user_id: userId,
				filter_type
			})
		)
	}, [userId, dispatch, filter_type])

	useEffect(() => {
		if (deleteFiltersRes.status === AsyncStates.ERROR) {
			dispatch(deleteFiltersCleanup())
		}
		if (deleteFiltersRes.status === AsyncStates.SUCCESS) {
			dispatch(getSavedFiltersRemove(selectedFilters))
			setSelectedFilters([])
			dispatch(deleteFiltersCleanup())
		}
	}, [deleteFiltersRes, dispatch, selectedFilters])

	function applyFilter(item: GetSavedFiltersResponse["result"]["data"][0]) {
		const query = item?.query || {}
		if (Object.keys(query).length === 0) {
			form.resetFields()
		}
		if (Object.keys(query).length > 0) {
			setInnerSelector(query?.data?.map((res: any) => res?.selector ?? "and"))
			setOuterSelector(query?.selector)
			form.setFieldValue(
				"outer_conditions",
				query?.data?.map((res: any) => ({
					inner_conditions: res?.data?.map((key: any) => {
						return ({
							...key,
							...(key?.parameter === "stage" && ({
								val: [...new Set(workOrdersList.flatMap((wo: any) => key.val.map((stage: any) => wo?.stages?.findIndex((res: any) => stage === res?.identifier))).filter((id: any) => id !== -1))] 
							})),
							parameter: [
								"material",
								"application",
								"grade",
								"order_type",
								"work_order_id",
								"stage"
							].includes(key?.parameter)
								? [key?.parameter]
								: [key?.parameter_type, key?.category, key?.parameter],
						})
					}),
				}))
			)
		}
		setFilters(query)
		setFormState({
			outer_conditions: query?.data?.map((res: any) => ({
				inner_conditions: res?.data?.map((key: any) => ({
					...key,
					...(key?.parameter === "stage" && ({ 
						val: [...new Set(workOrdersList.flatMap((wo: any) => key.val.map((stage: any) => wo?.stages?.findIndex((res: any) => stage === res?.identifier))).filter((id: any) => id !== -1))] 
					})),
					parameter: [
						"material",
						"application",
						"grade",
						"order_type",
						"work_order_id",
						"stage"
					].includes(key?.parameter)
						? [key?.parameter]
						: [key?.parameter_type, key?.category, key?.parameter],
				})),
			})),
		})
		message.success(t("common.filterAppliedSuccessfully"))
		onClose()
	}

	function deleteSelectedFilters() {
		if (selectedFilters.length === 0)
			return message.error("Please select at least one filter to delete")

		dispatch(
			deleteFiltersRequest({
				filter_ids: selectedFilters,
			})
		)
	}

	function onClose() {
		setSelectedFilters([])
		setVisible(false)
	}

	return (
		<Drawer
			open={visible}
			width={600}
			onClose={onClose}
			styles={{ body: { padding: "0 30px" } }}
			mask={false}
			title={
				<Row justify="space-between">
					<Title level={4} style={{ margin: 0 }}>
						{t("projects.savedFilter")}
					</Title>
				</Row>
			}
			extra={
				<StyledButton
					onClick={() => {
						Modal.confirm({
							title: `${t("common.delete")} Selected Filters ?`,
							icon: <StyledDeleteIcon />,
							okText: t("common.confirm"),
							cancelText: t("common.cancel"),
							okButtonProps: { style: { borderRadius: "12px" } },
							cancelButtonProps: { style: { borderRadius: "12px" } },
							onOk: () => {
								deleteSelectedFilters();
							},
						});
					}}
					loading={savedFormulationsFiltersRes.status === AsyncStates.LOADING}
					danger
					icon={<StyledDeleteIcon />}
					disabled={!selectedFilters.length}
				>
					{t("aiEngine.deleteRecords")}
					{selectedFilters.length > 0 && `(${selectedFilters.length})`}
				</StyledButton>
			}
		>
			<Spin
				spinning={savedFormulationsFiltersRes.status === AsyncStates.LOADING}
				indicator={<LoadingOutlined />}
			>
				<div ref={drawerRef}>
					{(savedFormulationsFiltersRes.status === AsyncStates.SUCCESS ||
						savedFormulationsFiltersRes.status === AsyncStates.INITIAL) &&
						savedFormulationsFiltersRes.data && (
							<List
								data={savedFormulationsFiltersRes.data}
								applyFilter={filter_type === 'inventory' ? onApplyFilter: applyFilter}
								drawerRef={drawerRef}
								selectedFilters={selectedFilters}
								setSelectedFilters={setSelectedFilters}
							/>
						)}

					{savedFormulationsFiltersRes.status === AsyncStates.ERROR && (
						<div style={{ color: red.primary }}>
							{typeof savedFormulationsFiltersRes.error === "string"
								? savedFormulationsFiltersRes.error
								: m("internal_server_error")}
						</div>
					)}
				</div>
			</Spin>
		</Drawer>
	)
}
