import { sendGet, sendPost } from "src/utils/sendApi";

export const applyForQC = sendPost("v1/data/qc")

export const getQCFiles = sendPost("v1/data/qc/get_files")

export const getQCFilesFilters = sendGet("v1/data/qc/get_filter_options")

export const getFileExtractions = sendPost("v1/data/qc/open_file")

export const renameBatch = sendPost("v1/data/qc/rename_batch")

export const deleteFile = sendPost("v1/data/qc/delete")

export const mergeBatches = sendPost("v1/data/qc/merge")

export const saveChangesAndApply = sendPost("v1/data/qc/save")

export const getRegisterLinkData = sendGet("v1/data/qc/get_link_details")

export const validateLink = sendPost("v1/data/qc/validate_link")

export const linkRecurringCycle = sendPost("v1/data/qc/register_link")

export const syncOnDemand = sendGet("v1/data/qc/sync_now")

export const getFirebaseProgressIds = sendGet("v1/data/qc/progress")

export const generateReport = sendPost("v1/data/qc/generate_report")