import Dexie, { Table } from 'dexie';

interface Mappings {
  id?: number;
  name: string;
  ingredients: any;
  processing: any;
  properties: any;
  references: any;
}

interface Files {
  id: string;
  file: File;
  mapping_id?: string;
}

class MappingsDB extends Dexie {
  mappings!: Table<Mappings, number>;
  constructor() {
    super('MappingsDB');
    this.version(1).stores({
      mappings: 'id',
    });
  }
}

class FilesDB extends Dexie {
  files!: Table<Files, number>;
  constructor() {
    super('FilesDB');
    this.version(1).stores({
      files: 'id, mapping_id',
    });
  }
}

export const mappingsDB = new MappingsDB();
export const filesDB = new FilesDB();
