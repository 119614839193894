import { useParams } from "react-router-dom";
import ResidualAnalysisDetails from "./ResidualAnalysis/ResidualAnalysisDetails/ResidualAnalysisDetails";
import FlowabilityAnalysisDetails from "./FlowabilityAnalysis/FlowabilityAnalysisDetails/FlowabilityAnalysisDetails";
import ProtrusionAnalysisDetails from "./ProtrusionAnalysis/ProtrusionAnalysisDetails/ProtrusionAnalysisDetails";
import PaintFilmAnalysisDetails from "./PaintFilmAnalysis/PaintFilmAnalysisDetails/PaintFilmAnalysisDetails";
import { IMAGE_ANALYSIS_TYPES } from "./SEMAnalysisWrapper";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { analysisTypesRequest } from "src/store/actions/semAnalysis";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import GeneralAnalysisDetails from "./GeneralAnalysis/GeneralAnalysisDetails/GeneralAnalysisDetails";
import SeedAnalysisDetails from "./SeedAnalysis/SeedAnalysisDetails/SeedAnalysisDetails";

const SEMAnalysisDetailsWrapper = () => {
    const params = useParams<any>();
    const dispatch = useDispatch();
    const { analysisTypesStatus } = useSelector((state: StoreState) => state.semAnalysis);

    useLayoutEffect(() => {
        if (analysisTypesStatus === AsyncStates.INITIAL) dispatch(analysisTypesRequest());
    }, [analysisTypesStatus, dispatch]);

    if (analysisTypesStatus !== AsyncStates.SUCCESS) return <Spin indicator={<LoadingOutlined />} spinning={true} />

    return (
        <>
            {params.analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS && <ResidualAnalysisDetails />}
            {params.analysisType === IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS && <ProtrusionAnalysisDetails />}
            {params.analysisType === IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS && <GeneralAnalysisDetails />}
            {params.analysisType === IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS && <FlowabilityAnalysisDetails />}
            {params.analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS && <PaintFilmAnalysisDetails />}
            {params.analysisType === IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS && <SeedAnalysisDetails />}
        </>
    )
}

export default SEMAnalysisDetailsWrapper;