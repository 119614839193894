import { handleActions } from "redux-actions"
import { FormulationDatum } from "src/services/formulation/interface"
import { AsyncStates } from "../../constants"
import {
  FetchExperimentActionTypes,
  FetchLinkedExperimentActionTypes,
  FetchLinkedExperimentDetailsActionTypes,
  FetchSimilarFormulationsActionTypes,
} from "../actions/experiment"
import {
  ReferenceFormulationsActionTypes,
  WorkOrderActionTypes,
  FormulationActionTypes,
  EditExperimentActionTypes,
  StageExistsActionTypes,
  CreateWorkOrderActionTypes,
  EditWorkOrderActionTypes,
  ResetWorkOrderActionTypes,
  DeleteWorkOrderActionTypes,
  ArchiveFormulationActionTypes,
  DataExportTypes,
  EditDisplayIdActionTypes,
  EditingStateActionTypes,
  IncludeDoeExpActionTypes,
  IncludeExpActionTypes,
  PlotsDataActionTypes,
  UploadGraphsDataActionTypes,
  GraphsDataHistoryActionTypes,
  WorkOrderStatusChangeActionTypes,
  DuplicateWoActionTypes,
  FetchSetDetailsActionTypes,
  FetchFileListActionTypes,
  UploadFileAttachmentActionTypes,
  DeleteFileAttachmentActionTypes,
  SaveProcessingProfilesActionTypes,
  DeleteProcessingProfilesActionTypes,
  ListProcessingProfilesActionTypes,
  AutoSaveExperimentActionTypes,
  DataSanityActionTypes,
  DataSanityDataActionTypes,
  CreateTrialActionTypes,
  CellChangesActionTypes,
  CopyTrialsActionTypes,
  AddParamActionTypes,
  UpdateParamActionTypes,
  RemoveParamActionTypes,
  WoBuildOfMaterialsActionTypes,
  FindAndUpdateSpecificExperimentDataActionTypes,
  SavePartialActionTypes,
  GraphsDataDeleteActionTypes,
  WoCommentListActionTypes,
  ExportGraphActionTypes
} from "../actions/workOrderDetails"
import { Cell } from "src/components/IDataSheet/IDataSheet"

interface ICellChange {
  cell: Cell
  row: number
  col: number
  value: string
}
interface IMeta {
  experiment_id: string
  parameter_id: string
  parameter_identifier: string
  parameter_type: string
}

interface IActionState {
  status: AsyncStates
  data: any
  error: any
}

const actionStateInitialData = {
  status: AsyncStates.INITIAL,
  data: {},
  error: "",
}

export type WorkOrderDetailsState = {
  createWOStatus: AsyncStates
  createWOError: string
  editWOStatus: AsyncStates
  editWOError: string
  deleteWOStatus: AsyncStates
  deleteWOError: string
  workOrderStatus: AsyncStates
  workOrderError: string
  refFormulationsStatus: AsyncStates
  refFormulationsError: string
  experiment: any[]
  total: number
  displayIdList: String[]
  firebase_task_id: String
  experimentStatus: AsyncStates
  experimentError: string
  linkedExperiment: any[]
  linkedExperimentStatus: AsyncStates
  linkedExperimentError: string
  linkedExperimentDetailsStatus: AsyncStates
  linkedExperimentDetails: any[]
  formulationsStatus: AsyncStates
  formulationsError: string
  editFormulationsStatus: AsyncStates
  autoSaveFormulationsStatus: AsyncStates
  editFormulationsError: string
  editDisplayIdStatus: AsyncStates
  editDisplayIdError: string
  archiveFormulationsStatus: AsyncStates
  archiveFormulationsError: string
  workOrder: {
    [key: string]: any
  }
  formulations: FormulationDatum[]
  referenceFormulations: any[]
  dataExportStatus: AsyncStates
  dataExport: any
  editingState: boolean
  editingProceduresState: boolean
  uploadGraphsDataStatus: AsyncStates
  graphsTaskId: string
  plotsDataStatus: AsyncStates
  plotsData: { [key: string]: any }
  graphExportStatus: AsyncStates
  graphsDataHistoryStatus: AsyncStates
  graphsDataDeleteStatus: AsyncStates
  graphsDataHistory: any
  woStatusChange: AsyncStates
  duplicateWoData: any
  setDetailsStatus: AsyncStates
  setdetailsError: string
  fileList: any[]
  fileListError: string
  fileListStatus: AsyncStates
  uploadFileAttachmentError: string
  uploadFileAttachmentStatus: AsyncStates
  deleteFileAttachmentStatus: AsyncStates
  stageDataExist: any
  stageExistLoading: AsyncStates
  saveProcessingProfilesStatus: AsyncStates
  processingProfilesList: any
  deleteProcessingProfilesStatus: AsyncStates
  processingProfilesLoading: any
  dataSanityKey: string
  dataSanityStatus: AsyncStates
  dataSanityError: string
  dataSanityData: any[]
  dataSanityDataStatus: AsyncStates
  dataSanityDataError: string
  dataSanityColumnData: any[]
  createTrialStatus: AsyncStates
  cellChanges: ICellChange[]
  savePartialStatus: AsyncStates
  savePartialData: any
  copyTrialsStatus: AsyncStates
  similarFormulations: any
  addParameterState: IActionState
  updateParameterState: IActionState
  removeParameterState: IActionState

  woBuildOfMaterials: any
  woBuildOfMaterialsStatus: AsyncStates
  woBuildOfMaterialsError: string
  updateExperimentsStatus: AsyncStates
  woCommentListError: string
  woCommentListStatus: AsyncStates
  woCommentList: any[]
}

const defaultState: WorkOrderDetailsState = {
  uploadFileAttachmentError: "",
  uploadFileAttachmentStatus: AsyncStates.INITIAL,
  createWOStatus: AsyncStates.INITIAL,
  createWOError: "",
  editWOStatus: AsyncStates.INITIAL,
  editWOError: "",
  deleteWOStatus: AsyncStates.INITIAL,
  deleteWOError: "",
  workOrderStatus: AsyncStates.INITIAL,
  workOrderError: "",
  refFormulationsStatus: AsyncStates.INITIAL,
  refFormulationsError: "",
  experimentStatus: AsyncStates.INITIAL,
  experimentError: "",
  experiment: [],
  total: 0,
  displayIdList: [],
  firebase_task_id: "",
  linkedExperimentStatus: AsyncStates.INITIAL,
  linkedExperimentError: "",
  linkedExperiment: [],
  linkedExperimentDetailsStatus: AsyncStates.INITIAL,
  linkedExperimentDetails: [],
  formulationsStatus: AsyncStates.INITIAL,
  formulationsError: "",
  editFormulationsStatus: AsyncStates.INITIAL,
  autoSaveFormulationsStatus: AsyncStates.INITIAL,
  editFormulationsError: "",
  editDisplayIdStatus: AsyncStates.INITIAL,
  editDisplayIdError: "",
  archiveFormulationsStatus: AsyncStates.INITIAL,
  archiveFormulationsError: "",
  workOrder: {},
  formulations: [],
  referenceFormulations: [],
  dataExportStatus: AsyncStates.INITIAL,
  dataExport: [],
  editingState: false,
  editingProceduresState: false,
  uploadGraphsDataStatus: AsyncStates.INITIAL,
  graphsTaskId: "",
  plotsDataStatus: AsyncStates.INITIAL,
  plotsData: {},
  graphExportStatus: AsyncStates.INITIAL,
  graphsDataHistoryStatus: AsyncStates.INITIAL,
  graphsDataDeleteStatus: AsyncStates.INITIAL,
  graphsDataHistory: [],
  woStatusChange: AsyncStates.INITIAL,
  duplicateWoData: {},
  setDetailsStatus: AsyncStates.INITIAL,
  setdetailsError: "",
  fileList: [],
  fileListError: "",
  fileListStatus: AsyncStates.INITIAL,
  deleteFileAttachmentStatus: AsyncStates.INITIAL,
  stageDataExist: [],
  stageExistLoading: AsyncStates.INITIAL,
  saveProcessingProfilesStatus: AsyncStates.INITIAL,
  processingProfilesLoading: AsyncStates.INITIAL,
  processingProfilesList: [],
  deleteProcessingProfilesStatus: AsyncStates.INITIAL,
  dataSanityKey: "",
  dataSanityStatus: AsyncStates.INITIAL,
  dataSanityError: "",
  dataSanityData: [],
  dataSanityDataStatus: AsyncStates.INITIAL,
  dataSanityDataError: "",
  dataSanityColumnData: [],
  createTrialStatus: AsyncStates.INITIAL,
  cellChanges: [],
  savePartialStatus: AsyncStates.INITIAL,
  savePartialData: {},
  copyTrialsStatus: AsyncStates.INITIAL,
  similarFormulations: {},

  addParameterState: actionStateInitialData,
  updateParameterState: actionStateInitialData,
  removeParameterState: actionStateInitialData,

  woBuildOfMaterials: { experiments: [], total: 0 },
  woBuildOfMaterialsStatus: AsyncStates.INITIAL,
  woBuildOfMaterialsError: "",
  updateExperimentsStatus: AsyncStates.INITIAL,
  woCommentListError: "",
  woCommentListStatus: AsyncStates.INITIAL,
  woCommentList: []
}

const getUpdatedCellChanges = (
  existingChanges: ICellChange[],
  newChanges: ICellChange[],
): ICellChange[] => {
  let result: ICellChange[] = [...existingChanges]
  newChanges.forEach((newChange) => {
    const uniqueKey = getUniqueKey(newChange.cell.meta)
    const existingIndex = result.findIndex((item) => {
      const itemKey = getUniqueKey(item.cell.meta)
      return itemKey === uniqueKey
    })
    if (existingIndex !== -1) {
      result[existingIndex].value = newChange.value
    } else {
      result.push(newChange)
    }
  })
  return result
}

const getUniqueKey = (meta: IMeta) => {
  // separate function to get the unique key safely
  return `${meta?.experiment_id ?? ""}_${meta?.parameter_id ?? ""}_${meta?.parameter_identifier ?? ""
    }_${meta?.parameter_type ?? ""}`
}

const workOrderDetailsReducer = handleActions<WorkOrderDetailsState, any>(
  {
    [FetchSetDetailsActionTypes.REQUEST]: (state) => ({
      ...state,
      setDetailsStatus: AsyncStates.LOADING,
      setdetailsError: "",
    }),
    [FetchSetDetailsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      setDetailsStatus: AsyncStates.SUCCESS,
      setdetailsError: "",
      experiment: action.payload,
    }),
    [FetchSetDetailsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      setDetailsStatus: AsyncStates.ERROR,
      setdetailsError: action.payload,
    }),
    [IncludeExpActionTypes.INCLUDE_SUGGESTED_EXP]: (state, action) => {
      return {
        ...state,
        workOrder: { ...action.payload },
      }
    },
    [IncludeDoeExpActionTypes.INCLUDE_DOE_EXP]: (state, action) => ({
      ...state,
      workOrder: { doe_id: action.payload },
    }),
    [IncludeExpActionTypes.INCLUDE_PREDICTION_EXP]: (state, action) => ({
      ...state,
      workOrder: { prediction_experiment_ids: action.payload },
    }),
    [IncludeExpActionTypes.SET_PREDICTION_ID]: (state, action) => ({
      ...state,
      workOrder: { ...state.workOrder, prediction_id: action.payload },
    }),
    [IncludeExpActionTypes.SET_INCLUDE_ALL]: (state, action) => ({
      ...state,
      workOrder: { ...state.workOrder, includeAll: action.payload },
    }),
    [IncludeExpActionTypes.SET_GENERATE_TYPE]: (state, action) => ({
      ...state,
      workOrder: { ...state.workOrder, generateType: action.payload },
    }),
    [CreateWorkOrderActionTypes.REQUEST]: (state) => ({
      ...state,
      createWOStatus: AsyncStates.LOADING,
      createWOError: "",
    }),
    [CreateWorkOrderActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      createWOStatus: AsyncStates.SUCCESS,
      createWOError: "",
      workOrder: action.payload,
    }),
    [CreateWorkOrderActionTypes.SETSUCCESS]: (state, action) => {
      return {
        ...state,
        createWOStatus: AsyncStates.SUCCESS,
        workOrder: {
          ...state.workOrder,
          data_processing: action.payload?.data_processing ?? false,
        },
      }
    },
    [CreateWorkOrderActionTypes.FAILURE]: (state, action) => ({
      ...state,
      createWOStatus: AsyncStates.ERROR,
      createWOError: action.payload,
    }),
    [EditWorkOrderActionTypes.REQUEST]: (state) => ({
      ...state,
      editWOStatus: AsyncStates.LOADING,
      editWOError: "",
    }),
    [EditWorkOrderActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      editWOStatus: AsyncStates.SUCCESS,
      editWOError: "",
      workOrder: action.payload,
    }),
    [EditWorkOrderActionTypes.FAILURE]: (state, action) => ({
      ...state,
      editWOStatus: AsyncStates.ERROR,
      editWOError: action.payload,
    }),
    [EditWorkOrderActionTypes.CLEAR]: (state) => ({
      ...state,
      editWOStatus: AsyncStates.INITIAL,
      editWOError: "",
    }),
    [DeleteWorkOrderActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteWOStatus: AsyncStates.LOADING,
      deleteWOError: "",
    }),
    [DeleteWorkOrderActionTypes.SUCCESS]: (state) => ({
      ...state,
      deleteWOStatus: AsyncStates.SUCCESS,
      deleteWOError: "",
    }),
    [DeleteWorkOrderActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteWOStatus: AsyncStates.ERROR,
      deleteWOError: action.payload,
    }),
    [WorkOrderActionTypes.REQUEST]: (state) => ({
      ...state,
      workOrderStatus: AsyncStates.LOADING,
      workOrderError: "",
    }),
    [WorkOrderActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      workOrderStatus: AsyncStates.SUCCESS,
      workOrderError: "",
      workOrder: action.payload || {},
    }),
    [WorkOrderActionTypes.FAILURE]: (state, action) => ({
      ...state,
      workOrderStatus: AsyncStates.ERROR,
      workOrderError: action.payload,
    }),
    [WorkOrderActionTypes.CLEAR]: (state, action) => ({
      ...state,
      workOrderStatus: AsyncStates.INITIAL,
      workOrderError: "",
      workOrder: {}
    }),
    [ReferenceFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      refFormulationsStatus: AsyncStates.LOADING,
      refFormulationsError: "",
    }),
    [ReferenceFormulationsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      refFormulationsStatus: AsyncStates.SUCCESS,
      refFormulationsError: "",
      referenceFormulations: action.payload,
    }),
    [ReferenceFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      refFormulationsStatus: AsyncStates.ERROR,
      refFormulationsError: action.payload,
    }),
    [FetchExperimentActionTypes.REQUEST]: (state) => ({
      ...state,
      experimentStatus: AsyncStates.LOADING,
      experimentError: "",
    }),
    [FetchExperimentActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      experimentStatus: AsyncStates.SUCCESS,
      experimentError: "",
      experiment: action.payload.data,
      total: action.payload.total,
      displayIdList: action.payload?.display_id_list,
    }),
    [FetchExperimentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      experimentStatus: AsyncStates.ERROR,
      experimentError: action.payload,
    }),
    [FetchExperimentActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      experimentStatus: AsyncStates.INITIAL,
      experiment: [],
      firebase_task_id: "",
    }),
    [FetchLinkedExperimentActionTypes.REQUEST]: (state) => ({
      ...state,
      linkedExperimentStatus: AsyncStates.LOADING,
      linkedExperimentError: "",
    }),
    [FetchLinkedExperimentActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      linkedExperimentStatus: AsyncStates.SUCCESS,
      linkedExperimentError: "",
      linkedExperiment: action.payload,
    }),
    [FetchLinkedExperimentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      linkedExperimentStatus: AsyncStates.ERROR,
      linkedExperimentError: action.payload,
    }),
    [FetchLinkedExperimentActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      linkedExperimentStatus: AsyncStates.INITIAL,
      linkedExperimentError: "",
      linkedExperiment: [],
    }),
    [FetchLinkedExperimentDetailsActionTypes.REQUEST]: (state, action) => ({
      ...state,
      linkedExperimentDetailsStatus: AsyncStates.LOADING,
    }),
    [FetchLinkedExperimentDetailsActionTypes.SUCCESS]: (state, action) => {
      const newFormulationIds = action.payload.map(
        (res: any) => res?.id_set?.formulation_id,
      )
      const oldStateData = state.linkedExperimentDetails.filter(
        (res: any) => !newFormulationIds.includes(res?.id_set?.formulation_id),
      )
      return {
        ...state,
        linkedExperimentDetailsStatus: AsyncStates.SUCCESS,
        linkedExperimentDetails: [...oldStateData, ...action.payload],
      }
    },
    [FetchLinkedExperimentDetailsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      linkedExperimentDetailsStatus: AsyncStates.ERROR,
    }),
    [FetchLinkedExperimentDetailsActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      linkedExperimentDetailsStatus: AsyncStates.INITIAL,
      linkedExperimentDetails: [],
    }),

    [FormulationActionTypes.REQUEST]: (state) => ({
      ...state,
      formulationsStatus: AsyncStates.LOADING,
      formulationsError: "",
    }),
    [FormulationActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      formulationsStatus: AsyncStates.SUCCESS,
      formulationsError: "",
      formulations: action.payload,
    }),
    [FormulationActionTypes.FAILURE]: (state, action) => ({
      ...state,
      formulationsStatus: AsyncStates.ERROR,
      formulationsError: action.payload,
    }),
    [EditExperimentActionTypes.CLEANUP]: (state) => ({
      ...state,
      editFormulationsStatus: AsyncStates.INITIAL,
      editFormulationsError: "",
    }),
    [AutoSaveExperimentActionTypes.REQUEST]: (state) => ({
      ...state,
      autoSaveFormulationsStatus: AsyncStates.LOADING,
      editFormulationsError: "",
    }),
    [AutoSaveExperimentActionTypes.SUCCESS]: (state, action: any) => {
      // const newExp = state.experiment.map((exp) => {
      //   if (
      //     exp.id_set.formulation_id ===
      //     action.payload.data?.[0].id_set.formulation_id
      //   ) {
      //     return action.payload.data?.[0];
      //   }
      //   return exp;
      // });
      return {
        ...state,
        autoSaveFormulationsStatus: AsyncStates.SUCCESS,
        // experiment: newExp,
        editFormulationsError: "",
      }
    },
    [EditExperimentActionTypes.REQUEST]: (state) => ({
      ...state,
      editFormulationsStatus: AsyncStates.LOADING,
      editFormulationsError: "",
    }),
    [EditExperimentActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        editFormulationsStatus: AsyncStates.SUCCESS,
        autoSaveFormulationsStatus: AsyncStates.SUCCESS,
        experiment: action.payload.data,
        total: action.payload.total,
        editFormulationsError: "",
        firebase_task_id: action.payload?.firebase_task_id,
      }
    },
    [EditExperimentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      editFormulationsStatus: AsyncStates.ERROR,
      editFormulationsError: action.payload,
    }),
    [ArchiveFormulationActionTypes.REQUEST]: (state) => ({
      ...state,
      archiveFormulationsStatus: AsyncStates.LOADING,
      archiveFormulationsError: "",
    }),
    [ArchiveFormulationActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      experiment: state.experiment.filter(
        (res: any) => !action.payload.includes(res?.id_set?.formulation_id),
      ),
      archiveFormulationsStatus: AsyncStates.SUCCESS,
      archiveFormulationsError: "",
    }),
    [ArchiveFormulationActionTypes.FAILURE]: (state, action) => ({
      ...state,
      archiveFormulationsStatus: AsyncStates.ERROR,
      archiveFormulationsError: action.payload,
    }),
    [DataExportTypes.REQUEST]: (state, action) => ({
      ...state,
      dataExportStatus: AsyncStates.LOADING,
      dataExport: [],
    }),
    [DataExportTypes.SUCCESS]: (state, action) => ({
      ...state,
      dataExportStatus: AsyncStates.SUCCESS,
      dataExport: action.payload,
    }),
    [DataExportTypes.FAILURE]: (state, action) => ({
      ...state,
      dataExportStatus: AsyncStates.ERROR,
    }),
    [DataExportTypes.CLEAR]: (state, action) => ({
      ...state,
      dataExportStatus: AsyncStates.INITIAL,
    }),
    [EditDisplayIdActionTypes.REQUEST]: (state) => ({
      ...state,
      editDisplayIdStatus: AsyncStates.LOADING,
      editDisplayIdError: "",
    }),
    [EditDisplayIdActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      editDisplayIdStatus: AsyncStates.SUCCESS,
      editDisplayIdError: "",
      experiment: state.experiment.map((trial) =>
        trial.id_set.formulation_id === action.payload.formulation_id
          ? {
            ...trial,
            meta: {
              ...trial.meta,
              ...(action.payload?.formulation_display_id && {
                display_id: action.payload.formulation_display_id,
              }),
              ...(action.payload?.comment && {
                comment: action.payload.comment,
              }),
            },
          }
          : trial,
      ),
    }),
    [EditDisplayIdActionTypes.FAILURE]: (state, action) => ({
      ...state,
      editDisplayIdStatus: AsyncStates.ERROR,
      editDisplayIdError: action.payload,
    }),
    [EditDisplayIdActionTypes.RESET]: (state, action) => ({
      ...state,
      editDisplayIdStatus: AsyncStates.INITIAL,
      editDisplayIdError: "",
    }),
    [EditingStateActionTypes.SET_EDITING_STATE]: (state, action) => ({
      ...state,
      editingState: action.payload,
    }),
    [EditingStateActionTypes.SET_EDITING_PROCEDURES_STATE]: (
      state,
      action,
    ) => ({
      ...state,
      editingProceduresState: action.payload,
    }),
    [ResetWorkOrderActionTypes.RESET]: () => defaultState,
    [UploadGraphsDataActionTypes.REQUEST]: (state, action) => ({
      ...state,
      uploadGraphsDataStatus: AsyncStates.LOADING,
    }),
    [UploadGraphsDataActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      uploadGraphsDataStatus: AsyncStates.SUCCESS,
      graphsTaskId: action?.payload,
      workOrder: { ...state.workOrder, plots_data_uploaded: true },
    }),
    [UploadGraphsDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      uploadGraphsDataStatus: AsyncStates.ERROR,
    }),
    [PlotsDataActionTypes.REQUEST]: (state, action) => ({
      ...state,
      plotsDataStatus: AsyncStates.LOADING,
    }),
    [PlotsDataActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      plotsDataStatus: AsyncStates.SUCCESS,
      plotsData: action.payload,
    }),
    [PlotsDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      plotsDataStatus: AsyncStates.ERROR,
    }),
    [PlotsDataActionTypes.CLEAR]: (state, action) => ({
      ...state,
      plotsDataStatus: AsyncStates.INITIAL,
      plotsData: {},
    }),
    [ExportGraphActionTypes.REQUEST]: (state, action) => ({
      ...state,
      graphExportStatus: AsyncStates.LOADING,
    }),
    [ExportGraphActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      graphExportStatus: AsyncStates.SUCCESS,
    }),
    [ExportGraphActionTypes.FAILURE]: (state, action) => ({
      ...state,
      graphExportStatus: AsyncStates.ERROR,
    }),
    [GraphsDataHistoryActionTypes.REQUEST]: (state, action) => ({
      ...state,
      graphsDataHistoryStatus: AsyncStates.LOADING,
    }),
    [GraphsDataHistoryActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      graphsDataHistoryStatus: AsyncStates.SUCCESS,
      graphsDataHistory: action.payload,
    }),
    [GraphsDataHistoryActionTypes.FAILURE]: (state, action) => ({
      ...state,
      graphsDataHistoryStatus: AsyncStates.ERROR,
    }),
    [GraphsDataDeleteActionTypes.REQUEST]: (state) => ({
      ...state,
      graphsDataDeleteStatus: AsyncStates.LOADING,
    }),
    [GraphsDataDeleteActionTypes.SUCCESS]: (state) => ({
      ...state,
      graphsDataDeleteStatus: AsyncStates.SUCCESS,
    }),
    [GraphsDataDeleteActionTypes.FAILURE]: (state) => ({
      ...state,
      graphsDataDeleteStatus: AsyncStates.ERROR,
    }),
    [WorkOrderStatusChangeActionTypes.REQUEST]: (state, action) => ({
      ...state,
      woStatusChange: AsyncStates.LOADING,
    }),
    [WorkOrderStatusChangeActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      woStatusChange: AsyncStates.SUCCESS,
    }),
    [WorkOrderStatusChangeActionTypes.FAILURE]: (state, action) => ({
      ...state,
      woStatusChange: AsyncStates.ERROR,
    }),
    [DuplicateWoActionTypes.SET]: (state, action) => ({
      ...state,
      createWOStatus: AsyncStates.SUCCESS,
      duplicateWoData: action.payload,
    }),
    [FetchFileListActionTypes.REQUEST]: (state, action) => ({
      ...state,
      fileListStatus: AsyncStates.LOADING,
    }),
    [FetchFileListActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      fileList: action.payload,
      fileListStatus: AsyncStates.SUCCESS,
    }),
    [FetchFileListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      fileListError: action.payload,
      fileListStatus: AsyncStates.ERROR,
    }),
    [UploadFileAttachmentActionTypes.REQUEST]: (state, action) => ({
      ...state,
      uploadFileAttachmentStatus: AsyncStates.LOADING,
    }),
    [UploadFileAttachmentActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      uploadFileAttachmentStatus: AsyncStates.SUCCESS,
    }),
    [UploadFileAttachmentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      uploadFileAttachmentError: action.payload,
      uploadFileAttachmentStatus: AsyncStates.ERROR,
    }),
    [DeleteFileAttachmentActionTypes.REQUEST]: (state, action) => ({
      ...state,
      deleteFileAttachmentStatus: AsyncStates.LOADING,
    }),
    [DeleteFileAttachmentActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteFileAttachmentStatus: AsyncStates.SUCCESS,
    }),
    [DeleteFileAttachmentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteFileAttachmentStatus: AsyncStates.ERROR,
    }),
    [StageExistsActionTypes.REQUEST]: (state, action) => ({
      ...state,
      stageExistLoading: AsyncStates.LOADING,
    }),
    [StageExistsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      stageDataExist: action.payload,
      stageExistLoading: AsyncStates.SUCCESS,
    }),
    [StageExistsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      stageDataExist: [],
      stageExistLoading: AsyncStates.ERROR,
    }),
    [StageExistsActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      stageDataExist: [],
      stageExistLoading: AsyncStates.INITIAL,
    }),
    [SaveProcessingProfilesActionTypes.REQUEST]: (state) => ({
      ...state,
      saveProcessingProfilesStatus: AsyncStates.LOADING,
    }),
    [SaveProcessingProfilesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      saveProcessingProfilesStatus: AsyncStates.SUCCESS,
    }),
    [SaveProcessingProfilesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      saveProcessingProfilesStatus: AsyncStates.ERROR,
    }),
    [DeleteProcessingProfilesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteProcessingProfilesStatus: AsyncStates.ERROR,
    }),
    [DeleteProcessingProfilesActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteProcessingProfilesStatus: AsyncStates.LOADING,
    }),
    [DeleteProcessingProfilesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteProcessingProfilesStatus: AsyncStates.SUCCESS,
    }),
    [ListProcessingProfilesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      listProcessingSetsStatus: AsyncStates.ERROR,
    }),
    [ListProcessingProfilesActionTypes.REQUEST]: (state) => ({
      ...state,
      listProcessingSetsStatus: AsyncStates.LOADING,
    }),
    [ListProcessingProfilesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      listProcessingSetsStatus: AsyncStates.SUCCESS,
      processingProfilesList: action.payload,
    }),
    [DataSanityActionTypes.REQUEST]: (state) => ({
      ...state,
      dataSanityStatus: AsyncStates.LOADING,
      dataSanityDataStatus: AsyncStates.LOADING,
    }),
    [DataSanityActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      dataSanityStatus: AsyncStates.SUCCESS,
      dataSanityKey: action.payload?.redis_key,
      dataSanityColumnData: action.payload?.checkStatus || action.payload,
    }),
    [DataSanityActionTypes.CLEAR]: (state, action) => ({
      ...state,
      dataSanityStatus: AsyncStates.INITIAL,
    }),
    [DataSanityActionTypes.FAILURE]: (state, action) => ({
      ...state,
      dataSanityStatus: AsyncStates.ERROR,
      dataSanityDataStatus: AsyncStates.ERROR,
    }),
    [DataSanityDataActionTypes.REQUEST]: (state) => ({
      ...state,
      dataSanityDataStatus: AsyncStates.LOADING,
    }),
    [DataSanityDataActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      dataSanityDataStatus: AsyncStates.SUCCESS,
      dataSanityData: action.payload.result,
    }),
    [DataSanityDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      dataSanityDataStatus: AsyncStates.ERROR,
    }),
    [CreateTrialActionTypes.REQUEST]: (state) => ({
      ...state,
      createTrialStatus: AsyncStates.LOADING,
    }),
    [CreateTrialActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      createTrialStatus: AsyncStates.SUCCESS,
      experiment: [...state.experiment, action.payload.data[0]],
    }),
    [CreateTrialActionTypes.FAILURE]: (state, action) => ({
      ...state,
      createTrialStatus: AsyncStates.ERROR,
    }),
    [CellChangesActionTypes.ADD]: (state, action) => ({
      ...state,
      cellChanges: getUpdatedCellChanges(
        state.cellChanges,
        action.payload.dataChanges,
      ),
    }),
    [CellChangesActionTypes.CLEAR]: (state) => ({
      ...state,
      cellChanges: [],
    }),
    [CopyTrialsActionTypes.REQUEST]: (state) => ({
      ...state,
      copyTrialsStatus: AsyncStates.LOADING,
    }),
    [CopyTrialsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      copyTrialsStatus: AsyncStates.SUCCESS,
      experiment: [...state.experiment, ...action.payload.data],
    }),
    [CopyTrialsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      copyTrialsStatus: AsyncStates.ERROR,
    }),
    [FetchSimilarFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      similarFormulationsStatus: AsyncStates.LOADING,
      similarFormulationsError: "",
    }),
    [FetchSimilarFormulationsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      similarFormulationsStatus: AsyncStates.SUCCESS,
      similarFormulations: action.payload,
      similarFormulationsError: "",
    }),
    [FetchSimilarFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      similarFormulationsStatus: AsyncStates.ERROR,
      similarFormulationsError: action.payload,
    }),
    [FetchSimilarFormulationsActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      similarFormulationsStatus: AsyncStates.INITIAL,
      similarFormulations: [],
    }),

    [AddParamActionTypes.REQUEST]: (state) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.LOADING,
      },
    }),
    [AddParamActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.SUCCESS,
        data: action.payload
      },
    }),
    [AddParamActionTypes.FAILURE]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.ERROR,
      },
    }),
    [UpdateParamActionTypes.REQUEST]: (state) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.LOADING,
      },
    }),
    [UpdateParamActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.SUCCESS,
        data: action.payload
      },
    }),
    [UpdateParamActionTypes.FAILURE]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.ERROR,
      },
    }),
    [RemoveParamActionTypes.REQUEST]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.LOADING,
        // data: action.payload
      },
    }),
    [RemoveParamActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.SUCCESS,
      },
    }),
    [RemoveParamActionTypes.FAILURE]: (state, action) => ({
      ...state,
      addParameterState: {
        ...state.addParameterState,
        status: AsyncStates.ERROR,
      },
    }),
    [WoBuildOfMaterialsActionTypes.WO_BUILD_OF_MATERIALS_REQUEST]: (
      state,
      action,
    ) => ({
      ...state,
      woBuildOfMaterialsStatus: AsyncStates.LOADING,
      woBuildOfMaterialsError: "",
      woBuildOfMaterials: { experiments: [], total: 0 },
    }),
    [WoBuildOfMaterialsActionTypes.WO_BUILD_OF_MATERIALS_SUCCESS]: (
      state,
      action,
    ) => {
      return {
        ...state,
        woBuildOfMaterialsStatus: AsyncStates.SUCCESS,
        woBuildOfMaterialsError: "",
        woBuildOfMaterials: action.payload,
      }
    },
    [WoBuildOfMaterialsActionTypes.WO_BUILD_OF_MATERIALS_FAILURE]: (
      state,
      action,
    ) => ({
      ...state,
      woBuildOfMaterialsStatus: AsyncStates.ERROR,
      woBuildOfMaterialsError: action.payload.error,
      woBuildOfMaterials: { experiments: [], total: 0 },
    }),
    [WoBuildOfMaterialsActionTypes.WO_BUILD_OF_MATERIALS_CLEAR]: (state) => ({
      ...state,
      woBuildOfMaterialsStatus: AsyncStates.INITIAL,
      woBuildOfMaterialsError: "",
      woBuildOfMaterials: { experiments: [], total: 0 },
    }),
    [SavePartialActionTypes.REQUEST]: (state, action) => ({
      ...state,
      updateExperimentsStatus: AsyncStates.LOADING,
    }),
    [FindAndUpdateSpecificExperimentDataActionTypes.REQUEST]: (
      state,
      action,
    ) => ({
      ...state,
      updateExperimentsStatus: AsyncStates.LOADING,
    }),
    [FindAndUpdateSpecificExperimentDataActionTypes.SUCCESS]: (
      state,
      action,
    ) => {
      const apiResponse: any[] = action.payload ?? []
      const experimentsData = [...state.experiment] ?? []
      apiResponse.forEach((experiment, index) => {
        const findExpIndex = experimentsData.findIndex(
          (exp) =>
            exp.id_set.formulation_id === experiment.id_set.formulation_id,
        )
        if (experimentsData?.[findExpIndex]) {
          experimentsData[findExpIndex] = experiment
        }
      })
      return {
        ...state,
        updateExperimentsStatus: AsyncStates.SUCCESS,
        experiment: experimentsData,
      }
    },
    [FindAndUpdateSpecificExperimentDataActionTypes.FAILURE]: (
      state,
      action,
    ) => ({
      ...state,
      updateExperimentsStatus: AsyncStates.ERROR,
    }),
    [FindAndUpdateSpecificExperimentDataActionTypes.CLEAR]: (
      state,
      action,
    ) => ({
      ...state,
      updateExperimentsStatus: AsyncStates.INITIAL,
    }),
    [WoCommentListActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        woCommentListStatus: AsyncStates.LOADING,
        error: "",
      })
    },
    [WoCommentListActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        woCommentListStatus: AsyncStates.SUCCESS,
        woCommentList: action.payload,
        woCommentListError: "",
      };
    },
    [WoCommentListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      woCommentListStatus: AsyncStates.ERROR,
      woCommentListError: action.payload?.message,
    }),
    [WoCommentListActionTypes.CLEAR]: (state) => {
      return {
        ...state,
        woCommentListStatus: AsyncStates.INITIAL,
        woCommentList: [],
        woCommentListError: "",
      };
    },
  },
  defaultState,
)

export default workOrderDetailsReducer
