import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store, persistor } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import RelayEnvironment from "./store/RelayEnvironment";
import mixpanel from "mixpanel-browser";
import { Router } from "react-router-dom";
import { ErrorBoundaryFallback } from "src/components/core";
import { Font } from "@react-pdf/renderer";
import JapaneseNasuRegularFont from "src/assets/fonts/JapaneseNasuRegularFont.ttf";

import { UnsecuredPageWarning } from "./components/UnsecuredPageWarning/UnsecuredPageWarning";
import { Userpilot } from "userpilot";

mixpanel.init("64851b96d7bf854774fa04d701d2d0c5");

export const history = createBrowserHistory();
Font.register({
  family: "Japanese",
  format: "opentype",
  src: JapaneseNasuRegularFont,
});

Sentry.init({
  dsn: "https://574bc2415caf49dba712b2425946962c@o504699.ingest.sentry.io/5722346",
  release: process.env.REACT_APP_NAME + "@" + process.env.REACT_APP_VERSION,
  integrations: [new Integrations.BrowserTracing() as any, new Sentry.Replay()],
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});
const isSIT = process.env.REACT_APP_ENV === "sit";
if (isSIT) {
  Userpilot.initialize(process.env.REACT_APP_USERPILOT_TOKEN!);
}

export const enabledDomains = [
  "labs.polymerize.io",
  "polymerize-labs-sandbox.web.app",
];

if (!enabledDomains.includes(window.location.hostname)) {
  mixpanel.opt_out_tracking();
}

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

enableMocking().then(() => {
  if (window.self === window.top) {
    ReactDOM.render(
      <Provider store={store}>
        <Sentry.ErrorBoundary
          fallback={({ error }) => <ErrorBoundaryFallback error={error} />}
        >
          <PersistGate loading={null} persistor={persistor}>
            <RelayEnvironmentProvider environment={RelayEnvironment}>
              <Router history={history}>
                <App />
              </Router>
            </RelayEnvironmentProvider>
          </PersistGate>
        </Sentry.ErrorBoundary>
      </Provider>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(<UnsecuredPageWarning />, document.getElementById("root"));
  }
})

// expose store when run in Cypress
if ((window as any).Cypress) {
  (window as any).appStore = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
