import { useCallback, useMemo, useState } from "react"
import { Space, Table, Typography, Tooltip, Dropdown, Modal, Radio, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { antdTheme, AsyncStates } from "src/constants"
import {
  InfoCircleOutlined,
  // DeploymentUnitOutlined,
  LoadingOutlined,
  MoreOutlined,
} from "@ant-design/icons"
// import { green, yellow, orange } from "@ant-design/colors"
import { deleteModelRequest } from "src/store/actions/customML"
import useTranslate from "src/utils/useTranslate"
import { DropDownOverlay } from "./ModelAnalysisDropdown"
import { StyledButton } from "src/styled_components/StyledButton"
import { ProjectDropDownMenu } from "../common/ProjectDropDownMenu"
import { useHistory } from "react-router-dom"
import "./ModelAnalysis.scss";
import DeleteConfirmationModal from "src/components/DeleteConfirmationModal"
const { Text } = Typography;

export function ModelAnalysis() {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const [searchedModelInput, setSearchedModelInput] = useState("")
  const { push } = useHistory()
  const { configData, configStatus } = useSelector(
    (state: StoreState) => state.formulate
  )
  const { deleteModelStatus } = useSelector(
    (state: StoreState) => state.CustomML
  )
  const { requestInverseStatus } = useSelector(
    (state: StoreState) => state.modelAnalysis
  )

  const { current: currentProject, projectList } = useSelector(
    (state: StoreState) => state.projects,
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [modelUseModalVisible, setModelUseModalVisible] =
    useState<boolean>(false);
  const [predType, setPredType] = useState<string>("forward");
  // const [selectedMetric, setSelectedMetric] = useState("mean_absolute_percentage_error")

  const [selectedProjects, setSelectedProjects] = useState<string[]>([
    currentProject,
  ]);

  const filteredCustomMLData = useMemo(() => {
    return configData
      .filter((model: any) => {
        const isSelectAllAndNullExists =
          (selectedProjects.length === projectList.length &&
            !model.project_id) ||
          !selectedProjects.length;
        return isSelectAllAndNullExists
          ? true
          : selectedProjects.includes(model.project_id);
      })
      .filter((model: any) => model?.is_custom);
  }, [configData, selectedProjects, projectList]);

  const filteredConfigData = useMemo(() => {
    return filteredCustomMLData.filter((model: any) => {
      const modelName = `${model.objective} (${model.comments})`;
      const includesModelName = modelName
        .toLocaleLowerCase()
        ?.includes(searchedModelInput.toLocaleLowerCase());
      return !!model?.is_custom && includesModelName;
    });
  }, [searchedModelInput, filteredCustomMLData]);

  const dropDownOverlay = useCallback((record: any) => {
    return (
      <DropDownOverlay
        record={record}
        setDeleteModalVisible={setDeleteModalVisible}
        setModelUseModalVisible={setModelUseModalVisible}
        setSelectedRecord={setSelectedRecord}
      />
    );
  }, []);

  // const getMetricsValue = useCallback((data: any, type: string, otherMetricType: string) => {
  // 	const filteredLength = data.filter((res: any) => (Object.keys(res?.[type] || {}).length > 0) && Object.keys(res?.[type] || {}).includes(selectedMetric)).length
  // 	const dataLength = (!filteredLength.length && selectedMetric === "mean_absolute_percentage_error") ? data.length : filteredLength
  // 	const accuracy = data.reduce((sum: number, element: any) => {
  // 		return sum + (((Object.keys(element?.[type] || {}).length > 0) && (Object.keys(element?.[type] || {}).includes(selectedMetric))) ? (element?.[type]?.[selectedMetric] ?? 0) : ((selectedMetric === "mean_absolute_percentage_error") ? element?.[otherMetricType] : 0))
  // 	}, 0) / dataLength
  // 	return parseFloat(accuracy.toPrecision(4))
  // }, [selectedMetric])

  // const { getModelErrorInfo } = useModelErrorInfo()

  // const sortValues = (valueA: number, valueB: number) => {
  // 	if (isNaN(valueA) && isNaN(valueB)) {
  // 		return 0;
  // 	}
  // 	if (isNaN(valueA)) {
  // 		return 1;
  // 	}
  // 	if (isNaN(valueB)) {
  // 		return -1;
  // 	}
  // 	return valueA - valueB;
  // }

  const columns: any = useMemo(() => {
    if (configStatus === AsyncStates.SUCCESS) {
      return [
        {
          dataIndex: "model_name",
          title: t("aiEngine.modelAnalysis.title.modelName"),
          key: "model_name",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          render: (text: any, record: any) => {
            return (
              <Tooltip
                placement="topLeft"
                title={`${record.objective} (${record.comments})`}
              >
                <Text>{`${record.objective} (${record.comments})`}</Text>
              </Tooltip>
            )
          },
        },
        {
          dataIndex: "model_display_type",
          title: () => (
            <Space>
              <Text>{t("aiEngine.modelType")}</Text>
              <Tooltip
                title={`${t("modelAnalysis.DescribeModelCreation")} ${t(
                  "common.customCLientModelByYou"
                )}`}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          ),
          key: "model_display_type",
          align: "center",
          render: (text: string, record: any) =>
            text?.split("_")
              .map(
                (res: string) =>
                  res.charAt(0).toLocaleUpperCase() + res.slice(1)
              )
              .join(" ")?.replace("Ai", "AI"),
        },
        {
          dataIndex: "created_on",
          title: t("aiEngine.modelAnalysis.title.dateCreated"),
          key: "created_on",
          align: "center",
          sortDirections: ['ascend', 'descend', 'ascend'],
          defaultSortOrder: 'descend',
          sorter: (a: any, b: any) => {
            return new Date(a.created_on).getTime() - new Date(b.created_on).getTime()
          },
          render: (text: any) => !!text?.length ? new Date(text).toLocaleString() : "-"
        },
        // {
        // 	dataIndex: "eval_metrics",
        // 	title: () => (
        // 		<Space>
        // 			<Text>{t("aiEngine.modelAnalysis.title.evaluationMetrics")}</Text>
        // 			<Tooltip title={"Average error value of K-fold cross Validation on training data"}>
        // 				<InfoCircleOutlined />
        // 			</Tooltip>
        // 		</Space>
        // 	),
        // 	key: "eval_metrics",
        // 	align: "center",
        // 	render: (text: any, record: any) => {
        // 		const data = Object.values(record.model_data || {})
        // 		const accuracy = getMetricsValue(data, "all_eval_metrics", "eval_metrics")
        // 		const response = (!isNaN(accuracy) && typeof accuracy === "number") ? getModelErrorInfo(accuracy, selectedMetric) : null

        // 		return (
        // 			<Space>
        // 				<Text>{getValue(accuracy, 2) || "-"}</Text>
        // 				<Tooltip
        // 					title={response?.modelResult}
        // 				>
        // 					{response && <DeploymentUnitOutlined
        // 						style={{
        // 							color:
        // 								response?.color
        // 						}}
        // 					/>}
        // 				</Tooltip>
        // 			</Space>
        // 		)
        // 	},
        // 	sorter: (a: any, b: any) => {
        // 		const getData = (record: any) => Object.values(record.model_data || {})
        // 		const getMetrics = (record: any) => {
        // 			const data = getData(record)
        // 			return getMetricsValue(data, "all_eval_metrics", "eval_metrics")
        // 		}
        // 		const valueA = getMetrics(a)
        // 		const valueB = getMetrics(b)
        // 		return sortValues(valueA, valueB)
        // 	}
        // },
        // {
        // 	dataIndex: "data_points",
        // 	title: t("aiEngine.modelAnalysis.title.dataPoints"),
        // 	key: "data_points",
        // 	align: "center",
        // 	render: (text: any, record: any) => {
        // 		const data = Object.values(record.model_data || {})
        // 		const dataPoint = (data.reduce((sum: number, element: any) => sum + element.data_points, 0) / data.length)

        // 		return (
        // 			<Text>
        // 				{(!isNaN(dataPoint) && typeof dataPoint === "number") ? Math.floor(dataPoint) : "-"}
        // 			</Text>
        // 		)
        // 	},
        // 	sorter: (a: any, b: any) => {
        // 		const getData = (record: any) => Object.values(record.model_data || {})
        // 		const getDataPoints = (record: any) => {
        // 			const data = getData(record)
        // 			return data.reduce(
        // 				(sum: number, element: any) => sum + element.data_points,
        // 				0
        // 			) / data.length
        // 		}
        // 		const sortOrder = getDataPoints(a) - getDataPoints(b)
        // 		return sortOrder
        // 	}
        // },
        {
          dataIndex: "actions",
          title: t("common.actions"),
          key: "actions",
          align: "center",
          width: 100,
          render: (text: any, record: any) => (
            <Dropdown key="more" overlay={() => dropDownOverlay(record)}>
              <StyledButton
                type="link"
                style={{
                  border: "none",
                  padding: 0,
                  color: 'black',
                  outline: 'none'
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <MoreOutlined
                  style={{
                    fontSize: antdTheme.fontSizeHeading3,
                    verticalAlign: "top",
                  }}
                />
              </StyledButton>
            </Dropdown>
          ),
        },
      ];
    } else return [];
  }, [configStatus, dropDownOverlay, t]);

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Table
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Space direction="vertical">
              <Text> </Text>
              <Text strong>{`${filteredConfigData?.length} ${t(
                "common.models"
              )}`}</Text>
            </Space>
            <Space style={{ alignItems: "flex-end", flexWrap: "wrap" }}>
              <Input
                type="text"
                style={{ minWidth: 300 }}
                placeholder={t("model.searchByModelName")}
                allowClear
                onChange={(event) => {
                  setSearchedModelInput(event.target.value);
                }}
              />
              {/* <Space direction="vertical">
								<Text>{t("modelAnalysis.selectErrorMetric")} :</Text>
								<Select
									style={{ minWidth: 250 }}
									onChange={setSelectedMetric}
									value={selectedMetric}
									showSearch
									optionFilterProp="children"
								>
									{
										Object.entries(metrics || {}).map(([key, value]) =>
											<Option key={key} value={key}>{value}</Option>
										)
									}
								</Select>
							</Space> */}
              <Space direction="vertical">
                <Text>{t("modelAnalysis.filterBasedOnProjects")} :</Text>
                <ProjectDropDownMenu
                  setSelectedProjects={setSelectedProjects}
                  selectedProjects={selectedProjects}
                />
              </Space>
            </Space>
          </Space>
        )}
        bordered
        columns={columns}
        dataSource={filteredConfigData}
        className="reset-sort-color model-analysis-table-title"
        loading={{
          spinning:
            configStatus === AsyncStates.LOADING ||
            deleteModelStatus === AsyncStates.LOADING ||
            requestInverseStatus === AsyncStates.LOADING,
          indicator: <LoadingOutlined />,
        }}
      />
      <DeleteConfirmationModal
        title={`${t("common.confirmDelete")}?`}
        description={
          <Text>
            {t("common.deleteConfirmation")}{" "}
            <Text
              strong
            >{`${selectedRecord.objective} (${selectedRecord.comments})`}</Text>
            ? {t("common.cannotUndoOperation")}
          </Text>
        }
        onCancel={() => setDeleteModalVisible(false)}
        visible={deleteModalVisible}
        onConfirm={() => {
          dispatch(deleteModelRequest({ versions: [selectedRecord.version] }));
          setSelectedRecord({});
          setDeleteModalVisible(false);
        }}
      />
      <Modal
        title={`${t("common.useModelPrediction")} ?`}
        open={modelUseModalVisible}
        cancelText={t("common.cancel")}
        onCancel={() => {
          setSelectedRecord({});
          setPredType("forward");
          setModelUseModalVisible(false);
        }}
        okText={t("common.confirm")}
        onOk={() => {
          setPredType(predType);
          setSelectedRecord({});
          setModelUseModalVisible(false);
          if (predType === "forward") {
            push(
              `/ai-engine/predict/properties_prediction?version=${selectedRecord.version}`
            );
          } else {
            push(
              `/ai-engine/predict/formulation_prediction?version=${selectedRecord.version}`
            );
          }
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text>
            {t("aiEngine.bySelecting")}{" "}
            <Text
              strong
            >{`${selectedRecord.objective} (${selectedRecord.comments})`}</Text>{" "}
            {t("aiEngine.modelsdataPoint")}
          </Text>
          <Text type="secondary">{t("aiEngine.predictionType")} :</Text>
          <Radio.Group
            value={predType}
            onChange={(e) => setPredType(e.target.value)}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Radio value={"forward"}>
                <Space>
                  <Text>{t("aiEngine.propertiesPrediction")}</Text>
                  <Tooltip title={t("modelAnalysis.useModelForForwardPred")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              </Radio>
              {selectedRecord?.is_inverse && (
                <Radio value={"inverse"}>
                  <Space>
                    <Text>
                      {t("aiEngine.formulationsProcessingsPrediction")}
                    </Text>
                    <Tooltip title={t("common.useModelInverse")}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                </Radio>
              )}
            </Space>
          </Radio.Group>
          {!selectedRecord.is_inverse && selectedRecord.is_requested && (
            <Text strong type="secondary">
              {t("common.inverseModelForThisVersion")}
            </Text>
          )}
        </Space>
      </Modal>
    </Space>
  );
}
