import { InfoCircleOutlined } from "@ant-design/icons"
import { Row, Select, Space, Table, Tooltip, Typography } from "antd"
import { useEffect, useState } from "react"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
import useTranslate from "src/utils/useTranslate"

const { Text } = Typography
const { Option } = Select

export const MapColumns = ({ callbacks, data }: any) => {
	const { tableData } = data
	const { setCurrent, setTableData } = callbacks
	const [t] = useTranslate()

	const [errorList, setErrorList] = useState<any>({
		formulations: [],
		experiments: [],
		processing: [],
		properties: [],
		characterisation: [],
	})

	useEffect(() => {
		const mappedExpColumns = tableData.experiments.map((res: any) => res.columns_mapped_to)
		const duplicateExpColumns = mappedExpColumns.filter(
			(item: any, index: any) =>
				!!item && mappedExpColumns.indexOf(item) !== index && item !== "ignore"
		)
		const missingExpsError = []
		if (!mappedExpColumns.includes("experiments")) {
			missingExpsError.push(t("mapColumns.columnTypeExperimentsRequired"))
		}
		const duplicateExpsError = duplicateExpColumns.map(
			(res: any) => `${t("mapColumns.onlyMapSheetOfType")} "${res?.charAt(0)?.toUpperCase() + res?.slice(1)}" ${t("common.once")}.`
		)

		const errorListObj: any = {
			formulations: [],
			experiments: [...duplicateExpsError, ...missingExpsError],
			processing: [],
			properties: [],
			characterisation: [],
		}

		if (!!tableData?.formulations?.length) {
			const mappedFormulation = tableData.formulations.map((res: any) => res.columns_mapped_to)
			const duplicateFormulations = mappedFormulation.filter((item: any, index: any) => !!item &&
				mappedFormulation.indexOf(item) !== index &&
				!["ignore", "experiment"].includes(item) &&
				!!item
			)
			const missingFormulationsError = []
			if (!mappedFormulation.includes("ingredients")) {
				missingFormulationsError.push(t("mapColumns.columnTypeIngredientsRequired"))
			}
			if (!mappedFormulation.includes("ingredients_unit")) {
				missingFormulationsError.push(t("mapColumns.columnTypeIngredientsUnitRequired"))
			}
			if (!mappedFormulation.includes("ingredients_category")) {
				missingFormulationsError.push(t("mapColumns.columnTypeIngredientsCategoryRequired"))
			}
			const duplicateFormulationsError = duplicateFormulations.map(
				(res: any) => `${t("mapColumns.onlyMapSheetOfType")} "${res?.charAt(0)?.toUpperCase() + res?.slice(1)}" ${t("common.once")}.`
			)
			errorListObj.formulations = [...duplicateFormulationsError, ...missingFormulationsError]
		}

		if (!!tableData?.processing?.length) {
			const mappedProcessing = tableData.processing.map((res: any) => res.columns_mapped_to)
			const duplicateProcessing = mappedProcessing.filter(
				(item: any, index: any) =>
					!!item &&
					mappedProcessing.indexOf(item) !== index &&
					!["ignore", "experiment"].includes(item) &&
					!!item
			)
			const missingProcessingError = []
			if (!mappedProcessing.includes("processing_parameters")) {
				missingProcessingError.push(t("mapColumns.columnTypeProcessingParametersRequired"))
			}
			if (!mappedProcessing.includes("processing_unit")) {
				missingProcessingError.push(t("mapColumns.columnTypeProcessingUnitRequired"))
			}
			if (!mappedProcessing.includes("processing_category")) {
				missingProcessingError.push(t("mapColumns.columnTypeProcessingCategoryRequired"))
			}
			const duplicateProcessingError = duplicateProcessing.map(
				(res: any) =>
					`${t("mapColumns.onlyMapSheetOfType")} "${res?.charAt(0)?.toUpperCase() + res?.slice(1)
					}" ${t("common.once")}.`
			)
			errorListObj.processing = [...duplicateProcessingError, ...missingProcessingError]
		}

		if (!!tableData?.properties?.length) {
			const mappedProperties = tableData.properties.map((res: any) => res.columns_mapped_to)
			const duplicateProperties = mappedProperties.filter(
				(item: any, index: any) =>
					!!item &&
					mappedProperties.indexOf(item) !== index &&
					!["ignore", "experiment"].includes(item) &&
					!!item
			)
			const missingPropertiesError = []
			if (!mappedProperties.includes("property_parameters")) {
				missingPropertiesError.push(t("mapColumns.columnTypePropertyParametersRequired"))
			}
			if (!mappedProperties.includes("property_unit")) {
				missingPropertiesError.push(t("mapColumns.columnTypePropertyUnitRequired"))
			}
			if (!mappedProperties.includes("property_category")) {
				missingPropertiesError.push(t("mapColumns.columnTypePropertyCategoryRequired"))
			}
			const duplicatePropertiesError = duplicateProperties.map(
				(res: any) =>
					`${t("mapColumns.onlyMapSheetOfType")} "${res?.charAt(0)?.toUpperCase() + res?.slice(1)
					}" ${t("common.once")}.`
			)
			errorListObj.properties = [...duplicatePropertiesError, ...missingPropertiesError]
		}

		if (!!tableData.characterisation?.length) {
			const mappedCharacterisation = tableData.characterisation.map((res: any) => res.columns_mapped_to)
			const duplicateCharacterisation = mappedCharacterisation.filter(
				(item: any, index: any) =>
					!!item &&
					mappedCharacterisation.indexOf(item) !== index &&
					!["ignore", "experiment"].includes(item) &&
					!!item
			)
			const missingCharacterisationError = []
			if (!mappedCharacterisation.includes("characterisation_unit")) {
				missingCharacterisationError.push(t("mapColumns.columnTypeCharacterisationUnitRequired"))
			}
			if (!mappedCharacterisation.includes("characterisation_parameters")) {
				missingCharacterisationError.push(t("mapColumns.columnTypeCharacterisationParametersRequired"))
			}
			if (!mappedCharacterisation.includes("characterisation_category")) {
				missingCharacterisationError.push(t("mapColumns.columnTypeCharacterisationCategoryRequired"))
			}
			const duplicateCharacterisationError = duplicateCharacterisation.map(
				(res: any) =>
					`${t("mapColumns.onlyMapSheetOfType")} "${res?.charAt(0)?.toUpperCase() + res?.slice(1)
					}" ${t("common.once")}.`
			)
			errorListObj.characterisation = [...duplicateCharacterisationError, ...missingCharacterisationError,]
		}
		setErrorList(errorListObj)
	}, [tableData, t])

	const onSelectColumnsChange = (dataType: string, record: any, e: any) => {
		setTableData((prevState: any) => {
			const newState = { ...prevState }
			if ((e === "experiment" || e === "ignore") && dataType !== "experiments") {
				const dataTypes = ["formulations", "characterisation", "processing", "properties",]
				dataTypes.forEach((key: any) => {
					if (!!newState?.[key]) {
						newState[key].forEach((res: any) => {
							if (res.columns === record.columns) {
								res.columns_mapped_to = e
							}
						})
					}

				})
			} else {
				newState[dataType].forEach((res: any) => {
					if (res.columns === record.columns) {
						res.columns_mapped_to = e
					}
				})
			}
			return newState
		})
	}

	const commonColumns = [
		{
			dataIndex: "columns",
			title: (
				<Space>
					<Text>{t("mapColumns.yourColumn")}</Text>
					<Tooltip title={t("mapColumns.columnHeader")}>
						<InfoCircleOutlined />
					</Tooltip>
				</Space>
			),
			key: "columns",
		},
		{
			dataIndex: "row_value",
			title: (
				<Space>
					<Text>{t("mapColumns.rowValue")}</Text>
					<Tooltip title={t("mapColumns.extractedFirstRowExample")}>
						<InfoCircleOutlined />
					</Tooltip>
				</Space>
			),
			key: "row_value",
		},
	]

	const expColumns = {
		dataIndex: "columns_mapped_to",
		title: (
			<Space>
				<Text>{t("mapcolumn.columnsMappedTo")}</Text>
				<Tooltip title={t("mapcolumn.headerRequiredByOurSystem")}>
					<InfoCircleOutlined />
				</Tooltip>
			</Space>
		),
		key: "columns_mapped_to",
		render: (text: any, record: any, index: any) => {
			return (
				<Select
					value={record.columns_mapped_to}
					style={{ width: "100%" }}
					onChange={(e: any) => onSelectColumnsChange("experiments", record, e)}
				>
					<Option key="experiments" value={"experiments"}>
						{t("common.experiments")}
					</Option>
					<Option key="ignore" value={"ignore"}>
						{t("common.ignore")}
					</Option>
				</Select>
			)
		},
	}

	const formulationColumns = {
		dataIndex: "columns_mapped_to",
		title: (
			<Space>
				<Text>{t("mapcolumn.columnsMappedTo")}</Text>
				<Tooltip title={t("mapcolumn.headerRequiredByOurSystem")}>
					<InfoCircleOutlined />
				</Tooltip>
			</Space>
		),
		key: "columns_mapped_to",
		render: (text: any, record: any, index: any) => {
			return (
				<Select
					value={record.columns_mapped_to}
					style={{ width: "100%" }}
					onChange={(e: any) =>
						onSelectColumnsChange("formulations", record, e)
					}
				>
					<Option key="ingredients" value={"ingredients"}>
						{t("common.ingredients")}
					</Option>
					<Option key="ingredients_unit" value={"ingredients_unit"}>
						{t("common.ingredientsUnit")}
					</Option>
					<Option key="ingredients_category" value={"ingredients_category"}>
						{t("common.ingredientsCategory")}
					</Option>
					<Option key="experiment" value={"experiment"}>
						{t("common.experiment")}
					</Option>
					<Option key="ignore" value={"ignore"}>
						{t("common.ignore")}
					</Option>
				</Select>
			)
		},
	}

	const processColumns = {
		dataIndex: "columns_mapped_to",
		title: (
			<Space>
				<Text>{t("mapcolumn.columnsMappedTo")}</Text>
				<Tooltip title={t("mapcolumn.headerRequiredByOurSystem")}>
					<InfoCircleOutlined />
				</Tooltip>
			</Space>
		),
		key: "columns_mapped_to",
		render: (text: any, record: any, index: any) => {
			return (
				<Select
					value={record.columns_mapped_to}
					style={{ width: "100%" }}
					onChange={(e: any) => onSelectColumnsChange("processing", record, e)}
				>
					<Option key="processing_parameters" value={"processing_parameters"}>
						{t("mapColumns.processingParameters")}
					</Option>
					<Option key="processing_unit" value={"processing_unit"}>
						{t("mapColumns.processingUnit")}
					</Option>
					<Option key="processing_category" value={"processing_category"}>
						{t("mapColumns.processingCategory")}
					</Option>
					<Option key="experiment" value={"experiment"}>
						{t("common.experiment")}
					</Option>
					<Option key="ignore" value={"ignore"}>
						{t("common.ignore")}
					</Option>
				</Select>
			)
		},
	}

	const propertyColumns = {
		dataIndex: "columns_mapped_to",
		title: (
			<Space>
				<Text>{t("mapcolumn.columnsMappedTo")}</Text>
				<Tooltip title={t("mapcolumn.headerRequiredByOurSystem")}>
					<InfoCircleOutlined />
				</Tooltip>
			</Space>
		),
		key: "columns_mapped_to",
		render: (text: any, record: any, index: any) => {
			return (
				<Select
					value={record.columns_mapped_to}
					style={{ width: "100%" }}
					onChange={(e: any) => onSelectColumnsChange("properties", record, e)}
				>
					<Option key="property_parameters" value={"property_parameters"}>
						{t("common.propertyParameters")}
					</Option>
					<Option key="property_unit" value={"property_unit"}>
						{t("common.propertyUnit")}
					</Option>
					<Option key="property_category" value={"property_category"}>
						{t("common.propertyCategory")}
					</Option>
					<Option key="property_method" value={"property_method"}>
						{"Property Method"}
					</Option>
					<Option key="property_standard" value={"property_standard"}>
						{"Property Standard"}
					</Option>
					<Option key="property_specimen" value={"property_specimen"}>
						{"Property Specimen"}
					</Option>
					<Option key="experiment" value={"experiment"}>
						{t("common.experiment")}
					</Option>
					<Option key="ignore" value={"ignore"}>
						{t("common.ignore")}
					</Option>
				</Select>
			)
		},
	}

	const characterisationColumns = {
		dataIndex: "columns_mapped_to",
		title: (
			<Space>
				<Text>{t("mapcolumn.columnsMappedTo")}</Text>
				<Tooltip title={t("mapcolumn.headerRequiredByOurSystem")}>
					<InfoCircleOutlined />
				</Tooltip>
			</Space>
		),
		key: "columns_mapped_to",
		render: (text: any, record: any, index: any) => {
			return (
				<Select
					value={record.columns_mapped_to}
					style={{ width: "100%" }}
					onChange={(e: any) => onSelectColumnsChange("characterisation", record, e)}
				>
					{/* <Option key="characterisation_type" value={"characterisation_type"}>{"Characterisation Type"}</Option> */}
					<Option
						key="characterisation_category"
						value={"characterisation_category"}
					>
						{t("mapColumns.characterisationCategory")}
					</Option>
					{/* <Option key="characterisation_method" value={"characterisation_method"}>{"Characterisation Method"}</Option> */}
					<Option
						key="characterisation_parameters"
						value={"characterisation_parameters"}
					>
						{t("mapColumns.characterisationParameters")}
					</Option>
					<Option key="characterisation_unit" value={"characterisation_unit"}>
						{t("mapColumns.characterisationUnit")}
					</Option>
					<Option key="experiment" value={"experiment"}>
						{t("common.experiment")}
					</Option>
					<Option key="ignore" value={"ignore"}>
						{t("common.ignore")}
					</Option>
				</Select>
			)
		},
	}

	return (
		<Space direction="vertical" style={{ width: "100%" }} size="large">
			<StyledCard
				title={
					<Space direction="vertical" style={{ width: "100%" }} size="small">
						<Text type="secondary">{t("mapColumns.mapTheColumnHeaders")}</Text>
						<Text type="secondary">
							{t("mapColumns.InterpretedColumnHeaders")} ?
						</Text>
					</Space>
				}
			>
				<Space direction="vertical" style={{ width: "100%" }} size="large">
					{!!tableData?.experiments?.length && (
						<>
							<Table
								columns={[...commonColumns, expColumns]}
								dataSource={tableData?.experiments}
								pagination={false}
								title={() => <Text strong>{t("common.experiments")}</Text>}
							/>
							<Space
								direction="vertical"
								style={{ width: "100%", marginBottom: 15 }}
								size="middle"
							>
								{errorList.experiments.map((res: any) => (<Text type="danger">{res}</Text>))}
							</Space>
						</>
					)}
					{!!tableData?.formulations?.length && (
						<>
							<Table
								columns={[...commonColumns, formulationColumns]}
								dataSource={tableData?.formulations}
								pagination={false}
								title={() => <Text strong>{t("common.formulation")}</Text>}
							/>
							<Space
								direction="vertical"
								style={{ width: "100%", marginBottom: 15 }}
								size="middle"
							>
								{errorList.formulations.map((res: any) => (<Text type="danger">{res}</Text>))}
							</Space>
						</>
					)}
					{!!tableData?.processing?.length && (
						<>
							<Table
								columns={[...commonColumns, processColumns]}
								dataSource={tableData?.processing}
								pagination={false}
								title={() => <Text strong>{t("common.process")}</Text>}
							/>
							<Space
								direction="vertical"
								style={{ width: "100%", marginBottom: 15 }}
								size="middle"
							>
								{errorList.processing.map((res: any) => (<Text type="danger">{res}</Text>))}
							</Space>
						</>
					)}
					{!!tableData?.characterisation?.length && (
						<>
							<Table
								columns={[...commonColumns, characterisationColumns]}
								dataSource={tableData?.characterisation}
								pagination={false}
								title={() => (
									<Text strong>{t("common.characterisation")}</Text>
								)}
							/>
							<Space
								direction="vertical"
								style={{ width: "100%", marginBottom: 15 }}
								size="middle"
							>
								{errorList.characterisation.map((res: any) => (
									<Text type="danger">{res}</Text>
								))}
							</Space>
						</>
					)}
					{!!tableData?.properties?.length && (
						<>
							<Table
								columns={[...commonColumns, propertyColumns]}
								dataSource={tableData?.properties}
								pagination={false}
								title={() => <Text strong>{t("common.property")}</Text>}
							/>
							<Space
								direction="vertical"
								style={{ width: "100%", marginBottom: 15 }}
								size="middle"
							>
								{errorList.properties.map((res: any) => (<Text type="danger">{res}</Text>))}
							</Space>
						</>
					)}
				</Space>
			</StyledCard>
			<Row justify="end">
				<Space>
					<StyledButton onClick={() => setCurrent(1)} size="large">{`${t(
						"common.back"
					)}: ${t("common.mapSheets")}`}</StyledButton>
					<StyledButton
						disabled={!Object.values(errorList).every((res: any) => !res.length)}
						onClick={() => setCurrent(3)}
						size="large"
					>
						{t("dataMapper.nextSelectExperiments")}
					</StyledButton>
				</Space>
			</Row>
		</Space>
	)
}
