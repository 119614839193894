import { Form, Modal, Select, Typography } from "antd";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import "../../modules/InventoryV2/Repository/Common/Common.scss";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import jwtManager from "src/utils/jwtManager";
import RepositoryService from "src/services/repository/v3";
import {
    //  NodeEditor, 
    useRootEngine
} from "flume";
import engine from "./engine";
import { LoadingOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
// import config from "./config";

interface Values {
    title?: string;
    description?: string;
    modifier?: string;
}

const collator = new Intl.Collator([], { numeric: false })

export const NewPropertyValueModal = memo(({ parameter, updateParameterDataList, parameterDataList, setParameterList, parameterIndex, addOrUpdateParameter }: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    const propertyList = useSelector((state: StoreState) => state.repository.properties.data)
    const allMethodList = useSelector((state: StoreState) => state.repository.allMethods.data)
    const propertyListOptions = useMemo(() => propertyList
        .sort((a, b) => collator.compare(a.name, b.name))
        .reduce((allCategories, property) => {
            const catOptionIndex = allCategories.findIndex((prop: any) => prop.label === property.category_name)
            if (catOptionIndex !== -1) {
                allCategories[catOptionIndex].options.push({ label: property.name, value: property.property_id })
            } else {
                allCategories.push({ label: property.category_name, options: [{ label: property.name, value: property.property_id }] })
            }
            return allCategories
        }, [])
        , [propertyList]
    )

    const [propertyId, setPropertyId] = useState<undefined | string>(parameterDataList[parameter]?.propertyId)
    const [methodId, setMethodId] = useState<undefined | string>(parameterDataList[parameter]?.methodId)
    const [standard, setStandard] = useState<undefined | string>(parameterDataList[parameter]?.standard)
    const [specimen, setSpecimen] = useState<undefined | string>(parameterDataList[parameter]?.specimen)

    const [loadingMethods, setLoadingMethods] = useState<boolean>(false);
    const [methodOptions, setMethodOptions] = useState<any[]>([]);
    const [standardOptions, setStandardOptions] = useState<any[]>([]);
    const [specimenOptions, setSpecimenOptions] = useState<any[]>([]);

    const onPropertyChange = (propertyId: string) => {
        setPropertyId(propertyId)
        setMethodId(undefined)
        setStandard(undefined)
        setSpecimen(undefined)
        form.setFieldsValue({ methodId: undefined, standard: undefined, specimen: undefined })
    };
    const onMethodChange = (methodId: string) => {
        setMethodId(methodId)
    };
    const onStandardChange = (standard: string) => {
        setStandard(standard)
    };
    const onSpecimenChange = (specimen: string) => {
        setSpecimen(specimen)
    };

    const defaultHeaders = useSelector(
        (state: StoreState) => state.defaultHeaders
    );

    const fetchMethods = useCallback(async () => {
        setLoadingMethods(true);
        const headers = {
            ...defaultHeaders,
            token: jwtManager.getToken(),
        } as any;
        const methodsResponse: any = propertyId && await RepositoryService.getMethods(
            propertyId,
            headers
        );

        setMethodOptions(methodsResponse?.data?.result?.data?.map((method: any) => {
            return {
                label: method.method_name,
                value: method.method_id
            }
        })?.sort((a: any, b: any) => a.label?.localeCompare(b.label)) ?? []);
        setLoadingMethods(false);
    }, [defaultHeaders, propertyId]);

    const fetchStandards = useCallback(async () => {
        // setLoadingStandards(true);
        const headers = {
            ...defaultHeaders,
            token: jwtManager.getToken(),
        } as any;
        const standardsResponse: any = propertyId && await RepositoryService.getStandards(
            propertyId,
            headers
        );

        setStandardOptions(standardsResponse?.data?.result?.data?.standards?.map((standard: string) => {
            return {
                label: standard,
                value: standard
            }
        })?.sort((a: any, b: any) => a.label?.localeCompare(b.label)) ?? []);
        // setLoadingStandards(false);
    }, [defaultHeaders, propertyId]);

    const fetchSpecimens = useCallback(async () => {
        // setLoading(true);
        const headers = {
            ...defaultHeaders,
            token: jwtManager.getToken(),
        } as any;
        const specimensResponse: any = propertyId && await RepositoryService.getSpecimens(
            propertyId,
            headers
        );
        // setLoading(false);
        setSpecimenOptions(specimensResponse?.data?.result?.data?.specimens?.map((specimen: string) => {
            return {
                label: specimen,
                value: specimen
            }
        })?.sort((a: any, b: any) => a.label?.localeCompare(b.label)) ?? []);
    }, [defaultHeaders, propertyId]);

    useEffect(() => {
        open && fetchMethods();
    }, [fetchMethods, open]);

    useEffect(() => {
        open && fetchStandards();
    }, [fetchStandards, open]);

    useEffect(() => {
        open && fetchSpecimens();
    }, [fetchSpecimens, open]);

    const propertiesDN = useSelector((state: StoreState) => state.displayNames.data.properties)

    const onCancel = () => {
        setOpen(false)
        setPropertyId(parameterDataList[parameter]?.propertyId)
        setMethodId(parameterDataList[parameter]?.methodId)
        setStandard(parameterDataList[parameter]?.standard)
        setSpecimen(parameterDataList[parameter]?.specimen)
        form.setFieldsValue({
            propertyId: parameterDataList[parameter]?.propertyId,
            methodId: parameterDataList[parameter]?.methodId,
            standard: parameterDataList[parameter]?.standard,
            specimen: parameterDataList[parameter]?.specimen,
        })
    }

    const [nodes, setNodes] = useState({
        "fC8plxyQNE": {
            "x": 244.94509250515154,
            "y": -88.4615596729322,
            "type": "error",
            "width": 170,
            "connections": {
                "inputs": {
                    "error": [
                        {
                            "nodeId": "6OF0fSSBhL",
                            "portName": "found"
                        }
                    ]
                },
                "outputs": {}
            },
            "inputData": {
                "error": {
                    "boolean": false
                }
            },
            "root": true,
            "id": "fC8plxyQNE"
        },
        "y9NiLVYDc5": {
            "id": "y9NiLVYDc5",
            "x": -450.1098525497943,
            "y": -162.72524764511633,
            "type": "combinations",
            "width": 200,
            "connections": {
                "inputs": {},
                "outputs": {
                    "combinations": [
                        {
                            "nodeId": "6OF0fSSBhL",
                            "portName": "combinations"
                        }
                    ]
                }
            },
            "inputData": {}
        },
        "CncFw2aCx4": {
            "id": "CncFw2aCx4",
            "x": -447.0439426296363,
            "y": 50.08569629375753,
            "type": "currentCombination",
            "width": 200,
            "connections": {
                "inputs": {},
                "outputs": {
                    "combination": [
                        {
                            "nodeId": "6OF0fSSBhL",
                            "portName": "combination"
                        }
                    ]
                }
            },
            "inputData": {}
        },
        "6OF0fSSBhL": {
            "id": "6OF0fSSBhL",
            "x": -93.14285714285705,
            "y": -101.06813644576874,
            "type": "combination_detector",
            "width": 170,
            "connections": {
                "inputs": {
                    "combinations": [
                        {
                            "nodeId": "y9NiLVYDc5",
                            "portName": "combinations"
                        }
                    ],
                    "combination": [
                        {
                            "nodeId": "CncFw2aCx4",
                            "portName": "combination"
                        }
                    ]
                },
                "outputs": {
                    "found": [
                        {
                            "nodeId": "fC8plxyQNE",
                            "portName": "error"
                        }
                    ]
                }
            },
            "inputData": {
                "combinations": {},
                "combination": {}
            }
        }
    })
    // console.log("🚀 ~ CharacterizationDatasheet ~ nodes:", nodes)

    useCallback((nodes) => {
        // Do whatever you want with the nodes
        setNodes(nodes)
    }, [])

    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setShowError(false)
    }, [propertyId, methodId, standard, specimen])

    const { error } = useRootEngine(nodes, engine, { combinations: parameterDataList, currentCombination: { propertyId, methodId, standard, specimen } })

    const onCreate = useCallback((values: Values) => {
        if (error) {
            setShowError(true)
        } else {
            setOpen(false);
            const tempId = window.crypto.randomUUID()
            setParameterList((state: any) => {
                const newState = [...state]
                newState[parameterIndex] = tempId
                return newState
            })
            updateParameterDataList(tempId, { ...values, temp: true })
            addOrUpdateParameter(parameter, tempId, { ...parameterDataList, ...{ [tempId]: { ...values, temp: true } } })
        }
    }, [addOrUpdateParameter, error, parameter, parameterDataList, parameterIndex, setParameterList, updateParameterDataList]);

    const [t] = useTranslate()

    const getParametersString = useMemo(() => {

        const paraString = allMethodList.find(method => method.method_id === parameterDataList[parameter]?.methodId)?.parameters.map((param: any) => {
            if (param.value_type === "numerical") {
                if (param.value_subtype === "single") {
                    return param.value + " " + param.unit
                }
                if (param.value_subtype === "operator") {
                    return param.operator + param.value + " " + param.unit
                }
                if (param.value_subtype === "range") {
                    return param.value + "-" + param.value_max + " " + param.unit
                }

            } else if (param.value_type === "categorical") {
                if (param.value_subtype === "single") {
                    return param.value_str + " " + (!param.unit || param.unit === "-" ? "" : param.unit)
                }
            }
            return "-"
        }).join(", ")
        return paraString
    }, [allMethodList, parameter, parameterDataList])

    return (
        <>
            <span style={{ wordWrap: 'break-word' }} onClick={() => setOpen(true)}>
                {(propertyList?.find(property => property.property_id === parameterDataList[parameter]?.propertyId)?.name || propertiesDN?.[parameter]?.name || parameter) + `${parameterDataList[parameter]?.methodId ? ` (${getParametersString})` : ""}`}
            </span>
            <Modal
                destroyOnClose={true}
                open={open}
                title={parameter.includes(t("workOrderDetails.newParameter")) ? "Add a parameter" : "Modify"}
                okText={parameter.includes(t("workOrderDetails.newParameter")) ? "Add" : "Modify"}
                cancelText="Cancel"
                okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
                onCancel={onCancel}
                modalRender={(dom) => (
                    <Form
                        layout="vertical"
                        form={form}
                        name="form_in_modal"
                        onFinish={(values) => onCreate(values)}
                        initialValues={{
                            propertyId: parameterDataList[parameter]?.propertyId,
                            methodId: parameterDataList[parameter]?.methodId,
                            standard: parameterDataList[parameter]?.standard,
                            specimen: parameterDataList[parameter]?.specimen,
                        }}
                    >
                        {dom}
                    </Form>
                )}
            >
                {/* <div style={{ width: 800, height: 600 }}>
                    <NodeEditor
                        portTypes={config.portTypes}
                        nodeTypes={config.nodeTypes}
                        defaultNodes={[
                            {
                                type: "error",
                                x: 190,
                                y: -150
                            }
                        ]}
                        nodes={nodes}
                        onChange={setNodes}
                    />
                </div> */}
                <Form.Item
                    name="propertyId"
                    label="Property"
                    rules={[{ required: true, message: 'Please input the name of property!' }]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        onChange={onPropertyChange}
                        optionFilterProp="label"
                        allowClear
                        options={propertyListOptions as any}
                        showSearch
                        value={propertyId}
                        defaultValue={propertyId}
                    />
                </Form.Item>
                <Form.Item
                    name="methodId"
                    label="Method"
                    rules={[{ required: false, message: 'Please input the name of testing method!' }]}
                >
                    {loadingMethods ? <LoadingOutlined /> : <Select
                        placeholder="Select a option and change input text above"
                        allowClear
                        options={methodOptions}
                        onChange={onMethodChange}
                        value={methodId}
                        defaultValue={methodId}
                    />}
                </Form.Item>
                <Form.Item
                    name="standard"
                    label="Standard"
                    rules={[{ required: false, message: 'Please input the standard of method!' }]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        allowClear
                        options={standardOptions}
                        onChange={onStandardChange}
                        value={standard}
                        defaultValue={standard}
                    />
                </Form.Item>
                <Form.Item
                    name="specimen"
                    label="Specimen"
                    rules={[{ required: false, message: 'Please input the specimen!' }]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        allowClear
                        options={specimenOptions}
                        onChange={onSpecimenChange}
                        value={specimen}
                        defaultValue={specimen}
                    />
                </Form.Item>
                {showError && <Typography.Text style={{ textAlign: "right" }} type="warning">Parameter already exists!</Typography.Text>}
            </Modal>
        </>
    )
})

