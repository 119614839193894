import { Badge, Select, Typography } from 'antd';
import useTranslate from 'src/utils/useTranslate'
import {
  TEXT_PRIMARY,
  BORDER_STYLE,
  TEXT_SECONDARY,
  AvatarComponent,
  StyledText
} from '../Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { antdTheme, AsyncStates } from 'src/constants';
import { StyledButton } from 'src/styled_components/StyledButton';
import { useHistory } from 'react-router-dom';
import { TwoFactorAuthModal } from 'src/components/Notifications/Notifications';
import { inverseNotificationClick } from 'src/store/actions/inverseModel';
import { notificationReadRequest } from 'src/store/actions/notifications';
import { resetWorkOrder } from 'src/store/actions/workOrderDetails';

const NotificationSection = () => {
  const [t] = useTranslate()
  const [notificationType, setNotificationType] = useState('all')
  const { push } = useHistory()

  return (
    <div
      style={{
        width: '100%',
        outline: BORDER_STYLE
      }}
    >
      <HeaderSection setType={setNotificationType} />
      <NotificationList type={notificationType} />
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '64px'
      }} >
        <StyledButton
          type='default'
          onClick={()=>{
            push('/notifications')
          }}
        >
          {t('common.showAll')}
        </StyledButton>
      </div>
    </div>
  )
}

export default NotificationSection

const QuickFilter = ({ setType }: any) => {
  const [t] = useTranslate()

  const notificationsData = useSelector(
    (state: StoreState) => state.notifications.data.notifications
  )

  const underscoreToCapitalized = useCallback(
    (str: string) => {
      let output = str
      if (str === 'all') output = t('common.all')
      return output
        ?.split('_')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ')
    },
    [t]
  )

  const options = useMemo(
    () => [
      'all',
      ...new Set(notificationsData.map((item: any) => item.notification_type))
    ],
    [notificationsData]
  )

  // return (
  //   <Segmented
  //     options={options.map((item: string) => ({
  //       label: underscoreToCapitalized(item),
  //       value: item
  //     }))}
  //     onChange={(value) => {
  //       setType(value)
  //     }}
  //     size="small"
  //     style={{
  //       overflow: "auto",
  //       scrollbarWidth: 'none',
  //       flexGrow: 1,
  //       fontSize: antdTheme.fontSizeSM,
  //       backgroundColor: 'transparent'
  //     }}
  //     className="dashboard-notification-filter"
  //   />
  // )

  return (
    <Select
      options={options.map((item: string) => ({
        label: StyledText(underscoreToCapitalized(item)),
        value: item
      }))}
      onChange={(value) => {
        setType(value)
      }}
      size="small"
      style={{
        fontSize: antdTheme.fontSizeSM,
        fontWeight: 'bold',
        maxWidth: '200px'
      }}
      defaultValue={'all'}
      dropdownStyle={{ width: 200 }}
      className='dashboard-notification-filter'
    />
  )
}

const HeaderSection = ({ setType }: any) => {
  const [t] = useTranslate()

  return (
    <div
      style={{
        padding: '12px 12px',
        borderBottom: BORDER_STYLE,
        display: 'flex',
        height: '60px',
        alignItems: 'center',
        gap: '16px'
      }}
    >
      <Typography.Title
        style={{
          color: `${TEXT_PRIMARY}`,
          lineHeight: '16px',
          margin: 0,
          flexShrink: 0
        }}
        level={5}
      >
        {t('navBar.notifications')}
      </Typography.Title>
      <QuickFilter setType={setType} />
    </div>
  )
}

const NotificationList = ({ type }: any) => {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const [modalVisible, setModalVisible] = useState(false)

  const notificationsData = useSelector(
    (state: StoreState) => state.notifications.data.notifications
  )

  const {
    verifyOtpTrueStatus,
    loginResponse: { two_fa, otp },
  } = useSelector((state: StoreState) => state.login);

  useEffect(() => {
    if (verifyOtpTrueStatus === AsyncStates.SUCCESS) {
      setModalVisible(false);
    }
  }, [verifyOtpTrueStatus]);

  const setRead = (key: string) => {
    const currentMessage = notificationsData.find(
      ({ notification_id }: any) => key.includes(notification_id)
    );
    if (currentMessage === undefined) {
      return;
    }
    if (!currentMessage?.is_read) {
      dispatch(notificationReadRequest(key));
    }
    if(currentMessage?.model_version) {
      push(`/model-details?version=${currentMessage.model_version}`);
    }
    if(currentMessage?.work_order_id) {
      dispatch(resetWorkOrder());
      push(`/work-orders/details/${currentMessage.work_order_id}/`);
    }
    if(currentMessage?.download_link) {
      window.open(currentMessage.download_link, "_blank");
    }
    if (currentMessage?.notification_type === "prediction") {
      dispatch(inverseNotificationClick(true));
      push(`/ai-engine/history/formulation_prediction?predId=${currentMessage.ref_id}`);
    }
    if (currentMessage?.notification_type === "customML") {
      push("/ai-engine/new_model/custom_ml");
    }
    if (currentMessage?.notification_type === "2fa_status" && two_fa && !otp) {
      setModalVisible(true);
    }
  };

  const filteredData = useMemo(
    () =>
      type === 'all' ? notificationsData : notificationsData
        .filter(
          (item: any) => item?.notification_type === type
        ),
    [notificationsData, type]
  )

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {filteredData.slice(0, 10).map((notification: any) => {
        return (
          <NotificationItem
            sender={notification.sender}
            text={notification.message.receivers_message}
            created={notification.created}
            is_read={notification.is_read}
            key={notification.created}
            onClick={() => {
              setRead(notification.notification_id)
            }}
          />
        )
      })}
      <TwoFactorAuthModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
    </motion.div>
  )
}

const NotificationItem = ({ sender, text, created, is_read, onClick }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: '8px',
        padding: '12px',
        borderBottom: BORDER_STYLE,
        minHeight: '64px'
      }}
      key={created}
      onClick={onClick}
      className='dashboard-notification-item'
    >
      <Badge
        dot={true}
        color={!is_read ? '#B20909' : '#FFFFFF'}
        styles={{
          indicator: {
            width: '4px',
            height: '4px',
            marginTop: '8px'
          }
        }}
      />
      <div
        style={{
          height: '27px'
        }}
      >
        <AvatarComponent id={sender} />
      </div>

      <Typography.Paragraph
        style={{
          color: TEXT_PRIMARY,
          width: '70%',
          margin: 0
        }}
        ellipsis={{
          rows: 2
        }}
        strong
      >
        {text}
      </Typography.Paragraph>
      <Typography.Text
        style={{ color: TEXT_SECONDARY, fontSize: antdTheme.fontSizeSM, width: '20%' }}
      >
        {dayjs(created).fromNow()}
      </Typography.Text>
    </div>
  )
}
