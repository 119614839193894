import { Space } from 'antd'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { CategoricalInputConstraints } from '../general/CategoricalInputConstraints'
import { InputConstraints } from './InputConstraints'
import { CategoryInput, Parameter } from '../types'
import { BaseInputConstraints } from '../base-input-constraints'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { ExludeAllCategoryConstraintsTypes, ExludeAllParameterConstraintsTypes, SelectAllIngredientsInCategoryTypes } from './child'
type P = {
  parameterList: Parameter[]
  setParameterList: Dispatch<SetStateAction<{ [key: string]: Parameter[] }>>
  catwiseParameterList: any
  setCatwiseParameterList: Dispatch<SetStateAction<any>>
  modelData: any
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  categorialInputList: CategoryInput[]
  setCategorialInputList: Dispatch<
    SetStateAction<{ [key: string]: CategoryInput[] }>
  >
  range: any
  categoricalRange: any
  view: "parameter-constraints" | "category-constraints"
  currentSelectedStage: number
  isMultiStage: boolean
  catConstraintsList: any[]
  setCatConstraintsList: Dispatch<SetStateAction<{ [key: string]: any[] }>>
  selectAllIngredientsInCategory: string[],
  setSelectAllIngredientsInCategory: Dispatch<SetStateAction<SelectAllIngredientsInCategoryTypes>>,
  exludeAllParameterConstraints: any
  setExludeAllParameterConstraints: Dispatch<SetStateAction<ExludeAllParameterConstraintsTypes>>,
  exludeAllCategoryConstraints: any,
  setExludeAllCategoryConstraints: Dispatch<SetStateAction<ExludeAllCategoryConstraintsTypes>>
  isCrossCategorical?: any,
  setIsCrossCategorical?: Dispatch<SetStateAction<ExludeAllCategoryConstraintsTypes>>
  setIsCrossCategoricalModalVisible?: Dispatch<SetStateAction<boolean>>
  isCategoricalConstraintsUseDefault?: any
  setIsCategoricalConstraintsUseDefault?: Dispatch<SetStateAction<boolean>>
}

export type ConstraintsProps = P

export default function Constraints({
  view,
  parameterList,
  setParameterList,
  catwiseParameterList,
  setCatwiseParameterList,
  setUnsavedChanges,
  modelData,
  setCategorialInputList,
  categorialInputList,
  range,
  categoricalRange,
  currentSelectedStage,
  isMultiStage,
  catConstraintsList,
  setCatConstraintsList,
  selectAllIngredientsInCategory,
  setSelectAllIngredientsInCategory,
  exludeAllParameterConstraints,
  setExludeAllParameterConstraints,
  exludeAllCategoryConstraints,
  setExludeAllCategoryConstraints,
  isCrossCategorical,
  setIsCrossCategorical,
  setIsCrossCategoricalModalVisible,
  isCategoricalConstraintsUseDefault,
  setIsCategoricalConstraintsUseDefault,
}: P) {
  const labels = useSelector(
    (state: StoreState) => state.displayNames.data || {},
  )

  const categorialConstraintsData = useMemo(() => {
    return Object.entries(
      modelData?.inverse_input_constraints ?? modelData?.properties ?? {},
    ).filter(([key, value]) => Array.isArray(value))
  }, [modelData])

  const inputContraintsData = useMemo(() => {
    if (modelData?.properties) {
      const numericalKeys = range.map((r: any) => r?.parameter)
      const categoricalKeys = categoricalRange.map((r: any) => r?.parameter)
      const allKeys = [...numericalKeys, ...categoricalKeys]

      const stages = [
        ...new Set(
          allKeys?.flatMap((key: any) => {
            return Object.keys(modelData?.items_per_property?.[key] ?? {})
          }),
        ),
      ]

      const data = allKeys?.map((key: any) => {
        return modelData.items_per_property?.[key]
      })
      const result = [
        ...new Set(
          stages.flatMap((stage) => data?.flatMap((d: any) => d?.[stage])),
        ),
      ]

      const res = result.flatMap((r: any) => {
        return stages.flatMap((stage) =>
          Array.isArray(modelData?.[stage]?.[r]) && modelData?.[stage]?.[r]
            ? { [r]: modelData?.[stage]?.[r] }
            : { [r]: modelData?.[stage]?.[r] },
        )
      })

      const finalObj = res.reduce((acc: any, curr: any) => {
        if (Object.values(curr)?.[0] !== undefined) {
          return { ...acc, ...curr }
        }
        return acc
      }, {})
      return finalObj
    } else {
      return modelData?.inverse_input_constraints
    }
  }, [modelData, categoricalRange, range])

  const isCategoriesAvailable = useMemo(
    () =>
      Object.entries(inputContraintsData || {})?.filter(([key, value]: any) =>
        Array.isArray(value),
      ),
    [inputContraintsData],
  )

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={"middle"}>
      {modelData?.version >= 0 ? (
        <BaseInputConstraints labels={labels} modelData={modelData} />
      ) : null}

      <InputConstraints
        view={view}
        categoricalRange={categorialConstraintsData}
        categorialInputList={categorialInputList}
        setCategorialInputList={setCategorialInputList}
        range={range}
        parameterList={parameterList}
        setParameterList={setParameterList}
        catwiseParameterList={catwiseParameterList}
        setCatwiseParameterList={setCatwiseParameterList}
        modelData={modelData}
        setUnsavedChanges={setUnsavedChanges}
        inputContraintsData={inputContraintsData}
        currentSelectedStage={currentSelectedStage}
        isMultiStage={isMultiStage}
        catConstraintsList={catConstraintsList}
        setCatConstraintsList={setCatConstraintsList}
        selectAllIngredientsInCategory={selectAllIngredientsInCategory}
        setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
        exludeAllParameterConstraints={exludeAllParameterConstraints}
        setExludeAllParameterConstraints={setExludeAllParameterConstraints}
        exludeAllCategoryConstraints={exludeAllCategoryConstraints}
        setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
        isCrossCategorical={isCrossCategorical}
        setIsCrossCategorical={setIsCrossCategorical}
        setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}
        isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
        setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
      />

      {isCategoriesAvailable.length > 0 && false && (
        <CategoricalInputConstraints
          categoricalRange={categorialConstraintsData}
          categorialInputList={categorialInputList}
          setCategorialInputList={setCategorialInputList}
          modelData={modelData}
          setUnsavedChanges={setUnsavedChanges}
          inputContraintsData={inputContraintsData}
          currentSelectedStage={currentSelectedStage}
        />
      )}
    </Space>
  )
}