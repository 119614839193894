import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import { UpdateUserPreferencesActionTypes, UserPreferencesActionTypes } from "../actions/userPreferences";

export type UserPreferencesState = {
    status: AsyncStates;
    error: any;
    preferences: {
        [key: string]: any
    }
};

const defaultState: UserPreferencesState = {
    status: AsyncStates.INITIAL,
    error: "",
    preferences: {
        formulation_page: {
            application: false,
            material: false,
            created: true,
            modified: true,
            work_order: true,
            stage_name: true,
            project: true,
            lab_location: false,
            work_order_type: false
        }
    }
};

const userPreferencesReducer = handleActions(
    {
        [UserPreferencesActionTypes.REQUEST]: (state) => ({
            ...state,
            status: AsyncStates.LOADING,
        }),
        [UserPreferencesActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            status: AsyncStates.SUCCESS,
            preferences: action.payload ?? { ...state.preferences },
        }),
        [UserPreferencesActionTypes.FAILURE]: (state, action) => ({
            ...state,
            status: AsyncStates.ERROR,
            error: action.payload,
        }),

        [UpdateUserPreferencesActionTypes.REQUEST]: (state) => ({
            ...state,
            status: AsyncStates.LOADING,
        }),
        [UpdateUserPreferencesActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            status: AsyncStates.SUCCESS,
            preferences: action.payload ?? { ...state.preferences },
        }),
        [UpdateUserPreferencesActionTypes.FAILURE]: (state, action) => ({
            ...state,
            status: AsyncStates.ERROR,
            error: action.payload,
        }),
    },
    defaultState
);

export default userPreferencesReducer;
