import React, { Dispatch, SetStateAction } from "react"
import { Checkbox, Row, Select, Space, Tooltip, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { NumericalSummary } from "src/components/AIEngine/DataSummary/types"
import { DisplayNames } from "src/typings"
import { DownSquareFilled } from "@ant-design/icons"
import { antdTheme } from "src/constants"

const { Text } = Typography
const { Option } = Select

type P = {
	selectedIngredients: string[]
	selectedProperties: string[]
	selectedProcessing: string[]
	setSelectedIngredients: Dispatch<SetStateAction<string[]>>
	setSelectedProperties: Dispatch<SetStateAction<string[]>>
	setSelectedProcessing: Dispatch<SetStateAction<string[]>>
	data: NumericalSummary["correlation_summary"] | any
	names: DisplayNames
	getDisplayName: (value: string) => string,
	setSelectedCosting: Dispatch<SetStateAction<string[]>>
	selectedCosting: string[]
}

export const FilterSection = ({
	data,
	names,
	selectedIngredients,
	selectedProperties,
	selectedProcessing,
	setSelectedIngredients,
	setSelectedProcessing,
	setSelectedProperties,
	getDisplayName,
	selectedCosting,
	setSelectedCosting
}: P) => {
	const [t] = useTranslate()


	return (
		<Row
			style={{
				marginTop: "1rem",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<Space direction="vertical">
				<Text>{t("inventory.filterBy")} :</Text>

				<Tooltip
					title={
						data.ingredients.length === 0
							? t("graphs.heatmap.noIngredientsData")
							: ""
					}
				>
					<Select
						placeholder={t("common.selectIngredients")}
						mode={"multiple"}
						disabled={data.ingredients.length === 0}
						style={{ width: 500 }}
						optionFilterProp="children"
						value={selectedIngredients}
						allowClear
						dropdownRender={(menu) => {
							const ingredientsData = data.ingredients

							const checked =
								ingredientsData.length === selectedIngredients.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedIngredients(ingredientsData)
											} else {
												setSelectedIngredients([])
											}
										}}
									>
										{t("common.selectAllIngredients")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e) => setSelectedIngredients(e)}
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#2980B9' }}/>}
					>
						{data.ingredients.map((ingredient: string) => (
							<Option value={ingredient} key={ingredient}>
								{names.ingredients?.[ingredient]?.name ?? getDisplayName(ingredient) ?? ingredient}
							</Option>
						))}
					</Select>
				</Tooltip>

				<Tooltip
					title={
						data.processing.length === 0
							? t("graphs.heatmap.noProcessingData")
							: ""
					}
				>
					<Select
						placeholder={t("common.selectProcessings")}
						mode={"multiple"}
						disabled={data.processing.length === 0}
						optionFilterProp="children"
						style={{ width: 500 }}
						value={selectedProcessing}
						allowClear
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#633974' }}/>}
						dropdownRender={(menu) => {
							const processingData = data.processing
							const checked =
								processingData.length === selectedProcessing.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedProcessing(processingData)
											} else {
												setSelectedProcessing([])
											}
										}}
									>
										{t("common.selectAllProcessings")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e) => {
							setSelectedProcessing(e)
						}}
					>
						{data.processing.map((processing: string) => (
							<Option value={processing} key={processing}>
								{names.processing?.[processing]?.name ?? processing}
							</Option>
						))}
					</Select>
				</Tooltip>

				<Tooltip
					title={
						data.properties.length === 0
							? t("graphs.heatmap.noProperties")
							: ""
					}
				>
					<Select
						placeholder={t("common.selectProperties")}
						mode={"multiple"}
						disabled={data.properties.length === 0}
						style={{ width: 500 }}
						optionFilterProp="children"
						value={selectedProperties}
						allowClear
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#F39C12' }}/>}
						dropdownRender={(menu) => {
							const propertiesData = data.properties
							const checked =
								propertiesData.length === selectedProperties.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e: any) => {
											if (e.target.checked) {
												setSelectedProperties(propertiesData)
											} else {
												setSelectedProperties([])
											}
										}}
									>
										{t("common.selectAllProperties")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e: any) => {
							setSelectedProperties(e)
						}}
					>
						{data.properties.map((property: string) => (
							<Option value={property} key={property}>
								{names.properties?.[property]?.name ?? property}
							</Option>
						))}
					</Select>
				</Tooltip>

				{!!data?.costing?.length &&
					<Select
						placeholder={t("graphs.heatmap.selectCosting")}
						mode={"multiple"}
						optionFilterProp="children"
						style={{ width: 500 }}
						value={selectedCosting}
						allowClear
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#1a9850' }}/>}
						dropdownRender={(menu) => {
							const costingData = data.costing
							const checked = costingData.length === selectedCosting.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedCosting(costingData)
											} else {
												setSelectedCosting([])
											}
										}}
									>
										{t("graphs.heatmap.selectAllCosting")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e) => {
							setSelectedCosting(e)
						}}
					>
						{data.costing.map((cost: string) => (
							<Option value={cost} key={cost}>
								{cost}
							</Option>
						))}
					</Select>
				}
			</Space>
		</Row>
	)
}
