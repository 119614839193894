import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Space,
  Table,
  Typography,
  Spin,
  Popover,
  List,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  checkPropertyExistsClear
} from "src/store/actions/inventory";
import { antdTheme, AsyncStates } from "src/constants";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { StyledCard } from "src/styled_components/StyledCard";
import useTranslate from "src/utils/useTranslate";
import { displayNamesRequest } from "src/store/actions/displayNames";
import { sortArray } from "src/utils/general/sort";
import { useQuery } from "src/utils/useQuery";
import { unitListRequest } from "src/store/actions/conversion";
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import { DeleteModal } from "src/components/Inventory/modals/DeleteModal";
import { WorkOrdersModal } from "src/components/Inventory/modals/WorkOrdersModal";
import { AttachmentsModal } from "src/components/Inventory/modals/AttachmentsModal";
import { AddUnitModal } from "src/components/UnitsConversion/modals";
import { FileUploadModal } from "src/components/Inventory/modals/FileUploadModal";
import TrialDataTypesSidebar from "./TrialDataTypesSidebar";
import { TRIAL_DATA_TYPES } from "../Common/Data";
import { UpdateTrialDataModal } from "./UpdateTrialDataModal";

const { Text, Title } = Typography;

export const TrialsData = () => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const params = useQuery();
  const projectList = useSelector(
    (state: StoreState) => state.projects.projectList
  );
  const inventoryDataList = useSelector(
    (state: StoreState) => state.displayNames.inventoryData
  );
  const inventoryDataStatus = useSelector(
    (state: StoreState) => state.displayNames.statusWithoutSpinner
  );
  const inventoryUploadStatus = useSelector(
    (state: StoreState) => state.inventory.inventoryUploadStatus
  );
  const inventoryFileUploadTaskId = useSelector(
    (state: StoreState) => state.inventory.inventoryFileUploadTaskId
  );
  const { deletePropertyStatus, updateParameterStatus, createParameterStatus } =
    useSelector((state: StoreState) => state.inventory);
  const configs = useSelector((state: StoreState) => state.configs.features);
  const [data, setData] = useState<any>([]);
  const [type, setType] = useState<TRIAL_DATA_TYPES>(
    TRIAL_DATA_TYPES.CHARACTERIZATIONS
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [workOrdersVisisble, setWorkOrdersVisible] = useState<boolean>(false);
  const [attachmentsModalVisible, setAttachmentsModalVisible] =
    useState<boolean>(false);
  const [dataExistsCheck, setDataExistsCheck] = useState(false);
  const [selectedRows, setSelectRows] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);


  useEffect(() => {
    setType((params.get("value") as any) ?? "characterizations");
  }, [params]);

  useEffect(() => {
    dispatch(displayNamesRequest({ backgroundFetch: true }));
  }, [dispatch]);

  useEffect(() => {
    if (deletePropertyStatus === AsyncStates.SUCCESS) {
      setDeleteModalVisible(false);
      setSelectRows([]);
    }
  }, [deletePropertyStatus]);

  const resetData = useCallback(() => {
    dispatch(checkPropertyExistsClear());
    setDeleteModalVisible(false);
    setWorkOrdersVisible(false);
    setSelectRows([]);
    setDataExistsCheck(false);
  }, [dispatch]);

  // const setModal = useCallback(
  //   (record: any, modalType: string) => {
  //     if (modalType === "delete") {
  //       setDataExistsCheck(
  //         selectedRows?.some((res: any) => !!res?.project_id?.length)
  //       );
  //       setDeleteModalVisible(true);
  //     } else if (modalType === "edit") {
  //       record.type = type;
  //       dispatch(setInventoryData(record));
  //       setUpdateModalVisible(true);
  //       setDataExistsCheck(!!record?.project_id?.length);
  //     } else if (modalType === "view") {
  //       record.type = type;
  //       dispatch(setInventoryData(record));
  //       dispatch(
  //         checkPropertyExistsRequest({ type, identifier: record?.identifier })
  //       );
  //       setWorkOrdersVisible(true);
  //     } else if (modalType === "attachments") {
  //       record.type = type;
  //       dispatch(setInventoryData(record));
  //       dispatch(
  //         inventoryAttachmentsListRequest({
  //           type,
  //           identifier: record?.identifier,
  //         })
  //       );
  //       setAttachmentsModalVisible(true);
  //     }
  //   },
  //   [dispatch, type, selectedRows]
  // );

  const filterCategories = useMemo(() => {
    return [
      ...new Set(
        inventoryDataList?.[type]
          ?.map((res: any) => res?.category)
          .filter((res: any) => !!res?.trim())
      ),
    ];
  }, [inventoryDataList, type]);

  const getProjectsContent = useCallback(
    (rowValue) => {
      return (
        <div style={{ maxWidth: 1000, maxHeight: 500, overflow: "auto" }}>
          <List
            size="small"
            dataSource={projectList.filter((project: any) =>
              !!rowValue?.length
                ? rowValue?.includes(project?.project_id)
                : false
            )}
            renderItem={(item: any) => (
              <List.Item>
                <Text strong>{item?.name}</Text>
              </List.Item>
            )}
          />
        </div>
      );
    },
    [projectList]
  );

  const columns: any = useMemo(() => {
    const data: any = [
      {
        key: "name",
        title: t("common.Name"),
        dataIndex: "name",
        width: 200,
        fixed: "left",
        render: (name: string) => (
          <Text style={{ wordBreak: "break-word" }}>{name || "-"}</Text>
        ),
        sorter: (a: any, b: any, c: any) => {
          if (c === "ascend") {
            return b.name?.toLocaleLowerCase() > a.name?.toLocaleLowerCase()
              ? -1
              : 1;
          } else {
            return b.name?.toLocaleLowerCase() < a.name?.toLocaleLowerCase()
              ? 1
              : -1;
          }
        },
      },
      {
        key: "category",
        title: t("common.category"),
        dataIndex: "category",
        width: 150,
        render: (text: string, record: any) => (
          <Text style={{ wordBreak: "break-word" }}>{text}</Text>
        ),
        filters: filterCategories
          .map((res: any) => ({
            text: res,
            value: res,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          ),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.category === value,
        sorter: (a: any, b: any, c: any) => {
          if (c === "ascend") {
            return b.category?.toLocaleLowerCase() >
              a.category?.toLocaleLowerCase()
              ? -1
              : 1;
          } else {
            return b.category?.toLocaleLowerCase() <
              a.category?.toLocaleLowerCase()
              ? 1
              : -1;
          }
        },
      },
    ];
    data.push({
      key: "unit",
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ color: "#222" }}>{t("common.unit")}</Text>
          <Text
            style={{ cursor: "pointer", color: antdTheme.colorPrimary }}
            onClick={() => setShowCard(true)}
          >
            {t("units.addUnit")}
          </Text>
        </div>
      ),
      dataIndex: "unit",
      width: 100,
      render: (rowValue: any, row: any, index: any) => {
        if (typeof rowValue === "string") return rowValue;
        const units = rowValue?.filter((value: any) => !!value);
        return units?.length > 0 ? units?.join(", ") : "-";
      },
    });
    if (type !== "metadata_properties") {
      data.push({
        key: "project_id",
        title: t("projects.header.title"),
        dataIndex: "project_id",
        width: 200,
        render: (rowValue: any, row: any, index: any) => {
          return (
            <Popover
              content={getProjectsContent(rowValue)}
              title={
                <Title level={5} type="secondary">
                  {t("projects.header.title")}
                </Title>
              }
              trigger="hover"
              placement="left"
            >
              <Text style={{ width: 250 }} ellipsis={{}}>
                {!!rowValue?.length
                  ? rowValue
                    ?.map(
                      (res: any) =>
                        projectList.find(
                          (project: any) => project?.project_id === res
                        )?.name || res
                    )
                    ?.join(", ")
                  : ""}
              </Text>
            </Popover>
          );
        },
        filters: projectList
          .map((res: any) => ({
            text: res?.name,
            value: res?.project_id,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          )
          .concat({ text: "None", value: null }),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return !value
            ? !record?.project_id?.length
            : record?.project_id?.includes(value);
        },
      });
    }
    if (Boolean(configs?.nestle_configs)) {
      data.push({
        key: "main_group",
        title: "Main Group",
        dataIndex: "main_group",
        width: 150,
        align: "center",
        render: (text: any, record: any) => {
          return !!text?.length ? text.join(", ") : null;
        },
      });
      data.push({
        key: "sub_group",
        title: "Sub Group",
        dataIndex: "sub_group",
        width: 150,
        align: "center",
        render: (text: any, record: any) => {
          return !!text?.length ? text.join(", ") : null;
        },
      });
    }
    // data.push({
    //   key: "actions",
    //   title: `${t("common.actions")}`,
    //   dataIndex: "actions",
    //   width: 100,
    //   align: "center",
    //   fixed: "right",
    //   render: (text: any, record: any) => {
    //     return (
    //       <Dropdown
    //         overlay={() => (
    //           <Menu>
    //             <Menu.Item onClick={() => setModal(record, "edit")} key="edit">
    //               <StyledButton type="text" icon={<EditOutlined />}>
    //                 {t("common.edit")}
    //               </StyledButton>
    //             </Menu.Item>
    //             {type !== "metadata_properties" && (
    //               <Menu.Item
    //                 onClick={() => setModal(record, "view")}
    //                 key="view"
    //               >
    //                 <StyledButton type="text" icon={<ContainerOutlined />}>
    //                   {t("common.viewWorkOrders")}
    //                 </StyledButton>
    //               </Menu.Item>
    //             )}
    //           </Menu>
    //         )}
    //       >
    //         {<MoreOutlined style={{ cursor: "pointer" }} />}
    //       </Dropdown>
    //     );
    //   },
    // });
    return data;
  }, [
    filterCategories,
    t,
    type,
    projectList,
    getProjectsContent,
    configs,
  ]);

  const setInitialDisplayNames: any = useCallback(() => {
    setData(
      inventoryDataList?.[type]?.map((res: any, index: number) => ({
        ...res,
        key: index,
      }))
    );
  }, [inventoryDataList, type]);

  useEffect(() => {
    setInitialDisplayNames();
  }, [setInitialDisplayNames]);

  const [uploadTaskStatus, setUploadTaskStatus] = useState<AsyncStates>(
    AsyncStates.INITIAL
  );
  useEffect(() => {
    let unsub: Unsubscribe;
    const listenToFileUploadTaskStatus = async () => {
      const taskDocRef = doc(
        firestoreDb,
        `${FB_COLLECTION_NAME_TASKERS}/${inventoryFileUploadTaskId}`
      );
      unsub = onSnapshot(taskDocRef, (doc) => {
        const taskDoc = doc.data();
        if (taskDoc) {
          const taskStatus = taskDoc?.status;
          if (taskStatus === "STARTED") {
            setUploadTaskStatus(AsyncStates.LOADING);
          }
          if (taskStatus === "COMPLETED") {
            setUploadTaskStatus(AsyncStates.SUCCESS);
            dispatch(displayNamesRequest({ backgroundFetch: true }));
            unsub();
          }
        }
      });
    };
    if (inventoryFileUploadTaskId) {
      listenToFileUploadTaskStatus();
    }
    return () => {
      uploadTaskStatus === AsyncStates.SUCCESS && unsub && unsub();
    };
  }, [inventoryFileUploadTaskId, uploadTaskStatus, dispatch]);

  return (
    <>
      <Spin
        spinning={inventoryDataStatus === AsyncStates.LOADING}
        indicator={<LoadingOutlined />}
      >
        <div className="category-container">
          <div className="content-container">
            <>
              <TrialDataTypesSidebar type={type} setType={setType} />
              <div className="main-container">
                <StyledCard bodyStyle={{ padding: "8px 16px", height: "100%" }}>
                  {/* <div>{t("inventory.doubleclick.note")}</div> */}
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    <Table
                      scroll={{
                        x: 450,
                        y:
                          window.screen.availWidth <= 1024
                            ? "55vh"
                            : window.screen.availWidth >= 1920
                              ? "70vh"
                              : "80vh",
                      }}
                      pagination={{
                        defaultPageSize: 30,
                        pageSizeOptions: [30, 60, 90, 120],
                      }}
                      // onRow={(record, rowIndex) => {
                      //   return {
                      //     onDoubleClick: (event) => setModal(record, "edit"),
                      //   };
                      // }}
                      onChange={(_, filters) => {
                        const { category, project_id }: any = filters;
                        let filteredData = [...selectedRows];
                        if (!!category?.length) {
                          filteredData = filteredData.filter((row: any) =>
                            category?.includes(row?.category)
                          );
                        }
                        if (!!project_id?.length) {
                          if (project_id?.includes(null)) {
                            filteredData = filteredData.filter(
                              (row: any) =>
                                project_id?.some((res: any) =>
                                  row?.project_id?.includes(res)
                                ) ||
                                row?.project_id?.length === 0 ||
                                row?.project_id?.length === undefined
                            );
                          } else {
                            filteredData = filteredData.filter((row: any) =>
                              project_id?.some((res: any) =>
                                row?.project_id?.includes(res)
                              )
                            );
                          }
                        }
                        setSelectRows(filteredData);
                      }}
                      style={{ cursor: "pointer", height: "100%" }}
                      // rowSelection={{
                      //   selectedRowKeys: selectedRows.map(
                      //     (res: any) => res?.key
                      //   ),
                      //   onChange: (e: any, record: any) =>
                      //     setSelectRows(record),
                      // }}
                      // title={() => (
                      //   <Row justify="space-between">
                      //     <Space>
                      //       <StyledButton
                      //         onClick={() => {
                      //           setDataExistsCheck(false);
                      //           setUpdateModalVisible(true)
                      //         }}
                      //         icon={<PlusCircleOutlined />}
                      //         type="primary"
                      //       >
                      //         {t("common.addNewData")}
                      //       </StyledButton>
                      //       <StyledButton
                      //         onClick={() => setModal(null, "delete")}
                      //         icon={<StyledDeleteIcon />}
                      //         disabled={!selectedRows?.length}
                      //         danger
                      //         type="primary"
                      //       >
                      //         {t("common.delete")}
                      //       </StyledButton>
                      //     </Space>
                      //   </Row>
                      // )}
                      loading={{
                        spinning:
                          updateParameterStatus === AsyncStates.LOADING ||
                          createParameterStatus === AsyncStates.LOADING ||
                          uploadTaskStatus === AsyncStates.LOADING ||
                          inventoryUploadStatus === AsyncStates.LOADING,
                        indicator: <LoadingOutlined />,
                      }}
                      bordered
                      columns={columns}
                      dataSource={data}
                    />
                  </Space>
                </StyledCard>
                <DeleteModal
                  visible={deleteModalVisible}
                  setVisible={setDeleteModalVisible}
                  type={type}
                  selectedRows={selectedRows}
                  dataExistsCheck={dataExistsCheck}
                />
                <UpdateTrialDataModal
                  visible={updateModalVisible}
                  setVisible={setUpdateModalVisible}
                  type={type}
                  selectedRows={selectedRows}
                  dataExistsCheck={dataExistsCheck}
                />
                <WorkOrdersModal
                  visible={workOrdersVisisble}
                  resetData={resetData}
                />
                <AttachmentsModal
                  visible={attachmentsModalVisible}
                  setVisible={setAttachmentsModalVisible}
                />
                <AddUnitModal
                  refetch={() => dispatch(unitListRequest({ category: "" }))}
                  isAddNewUnitModal={showCard}
                  closeModal={() => setShowCard(false)}
                />
                <FileUploadModal
                  fileUploadVisible={fileUploadVisible}
                  setFileUploadVisible={setFileUploadVisible}
                />
                {/* {categories.data.length > 0 && <TabHeader
                                title={`${t("repository.totalCategories")} (${categories.data.length
                                    })`}
                                addItem={addCategory}
                                setSearchTerm={setSearchTerm}
                            />}
                            <CategoryDetails
                                searchTerm={searchTerm}
                                setSelectedCategory={setSelectedCategory}
                                setMode={setMode}
                                setShowModal={setShowModal}
                                type={type}
                            /> */}
              </div>
            </>
          </div>

          {/* <AddEditCategoryModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    mode={mode}
                    selectedCategoryId={selectedCategory}
                    type={type}
                /> */}
        </div>
      </Spin>
    </>
  );
};
