import { Action, handleActions } from "redux-actions"
import { AnalyticsRecipeDistributionActionTypes, AnalyticsRecipeDistributionTableActionTypes, BoxPlotSummaryActionTypes, DataSummaryActionTypes, DataSummaryPlotOptionsActionTypes, DeleteSavedPlotsActionTypes, FormulationsDataFromCompareActionTypes, GetPlotsHistoryActionTypes, GetZeonCharacterizationDsmActionTypes, RecipeDistributionActionTypes, SavePlotsActionTypes, SurfacePlotActionTypes } from "src/store/actions/dataSummary"
import { AsyncStates } from "src/constants"
import { NumericalSummary } from "src/components/AIEngine/DataSummary/types"
import { CharacterizationVariation } from "src/typings"
import { CorrelationStatusTypes } from "src/components/AIEngine/DataSummary"

type CharacterizationVMethodZeonDsm =
	| {
		type: "methods"
		data: {
			characterization_id: string
			characterization_name: string
		}[]
	}
	| {
		type: "variations"
		data: {
			characterization_name: string
			characterization_id: string
			variations: CharacterizationVariation[]
		}
	}

export type DataSummaryReducerState =
	| {
		status: AsyncStates
		data: NumericalSummary
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionDisplayNames: any,
		recipeDistributionData: any[],
		recipeDistributionError: null,
		characterizationMethodsZeonDsm?: CharacterizationVMethodZeonDsm | null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string,
		dataSummaryPlotOptions: {
			plotOptions: { [key: string]: any }
			plotOptionsFormulationDisplayNames: any
		},
		surfacePlotStatus: AsyncStates,
		surfacePlotData: any,
		surfacePlotError: null,
		analyticsRecipeDistributionStatus: AsyncStates,
		analyticsRecipeDistributionData: any[],
		analyticsRecipeDistributionError: null,
		analyticsRecipeDistributionTableStatus: AsyncStates,
		analyticsRecipeDistributionTable: {
			analyticsRecipeDistributionTableData: any[],
			analyticsRecipeDistributionTableDisplayNames: {}
		},
		analyticsRecipeDistributionTableError: null,
		savePlotsStatus: AsyncStates,
		savePlotsData: any,
		savePlotsError: null,
		plotsFilterListStatus: AsyncStates,
		plotsFilterListData: any[],
		plotsFilterListError: null,
		deleteSavedPlotsStatus: AsyncStates,
		deleteSavedPlotsData: any[],
		deleteSavedPlotsError: null,
		selectedFormulationsDataFromCompare: any,
		correlationStatus: CorrelationStatusTypes
	}
	| {
		status: AsyncStates
		data: null
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionData: any[],
		recipeDistributionDisplayNames: any,
		recipeDistributionError: null,
		characterizationMethodsZeonDsm?: null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
		dataSummaryPlotOptions: {
			plotOptions: { [key: string]: any }
			plotOptionsFormulationDisplayNames: any
		},
		surfacePlotStatus: AsyncStates,
		surfacePlotData: any,
		surfacePlotError: null,
		analyticsRecipeDistributionStatus: AsyncStates,
		analyticsRecipeDistributionData: any[],
		analyticsRecipeDistributionError: null,
		analyticsRecipeDistributionTableStatus: AsyncStates,
		analyticsRecipeDistributionTable: {
			analyticsRecipeDistributionTableData: any[],
			analyticsRecipeDistributionTableDisplayNames: {}
		},
		analyticsRecipeDistributionTableError: null,
		savePlotsStatus: AsyncStates,
		savePlotsData: any,
		savePlotsError: null,
		plotsFilterListStatus: AsyncStates,
		plotsFilterListData: any[],
		plotsFilterListError: null,
		deleteSavedPlotsStatus: AsyncStates,
		deleteSavedPlotsData: any[],
		deleteSavedPlotsError: null,
		selectedFormulationsDataFromCompare: any,
		correlationStatus: CorrelationStatusTypes
	}
	| {
		status: AsyncStates
		data: null
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionDisplayNames: any,
		recipeDistributionData: any[],
		recipeDistributionError: null,
		characterizationMethodsZeonDsm?: CharacterizationVMethodZeonDsm | null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
		dataSummaryPlotOptions: {
			plotOptions: { [key: string]: any }
			plotOptionsFormulationDisplayNames: any
		},
		surfacePlotStatus: AsyncStates,
		surfacePlotData: any,
		surfacePlotError: null,
		analyticsRecipeDistributionStatus: AsyncStates,
		analyticsRecipeDistributionData: any[],
		analyticsRecipeDistributionError: null,
		analyticsRecipeDistributionTableStatus: AsyncStates,
		analyticsRecipeDistributionTable: {
			analyticsRecipeDistributionTableData: any[],
			analyticsRecipeDistributionTableDisplayNames: {}
		},
		analyticsRecipeDistributionTableError: null,
		savePlotsStatus: AsyncStates,
		savePlotsData: any,
		savePlotsError: null,
		plotsFilterListStatus: AsyncStates,
		plotsFilterListData: any[],
		plotsFilterListError: null,
		deleteSavedPlotsStatus: AsyncStates,
		deleteSavedPlotsData: any[],
		deleteSavedPlotsError: null,
		selectedFormulationsDataFromCompare: any,
		correlationStatus: CorrelationStatusTypes
	}
	| {
		status: AsyncStates
		data: NumericalSummary | null
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionDisplayNames: any,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionData: any[],
		recipeDistributionError: null
		characterizationMethodsZeonDsm?: CharacterizationVMethodZeonDsm | null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
		dataSummaryPlotOptions: {
			plotOptions: { [key: string]: any }
			plotOptionsFormulationDisplayNames: any
		},
		surfacePlotStatus: AsyncStates,
		surfacePlotData: any,
		surfacePlotError: null,
		analyticsRecipeDistributionStatus: AsyncStates,
		analyticsRecipeDistributionData: any[],
		analyticsRecipeDistributionError: null,
		analyticsRecipeDistributionTableStatus: AsyncStates,
		analyticsRecipeDistributionTable: {
			analyticsRecipeDistributionTableData: any[],
			analyticsRecipeDistributionTableDisplayNames: {}
		},
		analyticsRecipeDistributionTableError: null,
		savePlotsStatus: AsyncStates,
		savePlotsData: any,
		savePlotsError: null,
		plotsFilterListStatus: AsyncStates,
		plotsFilterListData: any[],
		plotsFilterListError: null,
		deleteSavedPlotsStatus: AsyncStates,
		deleteSavedPlotsData: any[],
		deleteSavedPlotsError: null,
		selectedFormulationsDataFromCompare: any,
		correlationStatus: CorrelationStatusTypes
	}


const defaultState: any = {
	status: AsyncStates.INITIAL,
	data: null,
	error: null,
	numericalSummaryStatus: AsyncStates.INITIAL,
	recipeDistributionStatus: AsyncStates.INITIAL,
	recipeDistributionData: [],
	recipeDistributionError: null,
	characterizationMethodsZeonDsm: null,
	characterizationMethodsZeonDsmStatus: AsyncStates.INITIAL,
	characterizationMethodsZeonDsmError: "",
	boxPlotSummaryData: [],
	boxPlotSummaryStatus: AsyncStates.INITIAL,
	boxPlotSummaryError: "",
	dataSummaryPlotOptions: {
		plotOptions: {},
		plotOptionsFormulationDisplayNames: {}
	},
	surfacePlotStatus: AsyncStates.INITIAL,
	surfacePlotData: [],
	surfacePlotError: "",
	analyticsRecipeDistributionStatus: AsyncStates.INITIAL,
	analyticsRecipeDistributionData: [],
	analyticsRecipeDistributionError: null,
	analyticsRecipeDistributionTableStatus: AsyncStates.INITIAL,
	analyticsRecipeDistributionTable: {
		analyticsRecipeDistributionTableData: [],
		analyticsRecipeDistributionTableDisplayNames: {}
	},
	analyticsRecipeDistributionTableError: null,
	savePlotsStatus: AsyncStates.INITIAL,
	savePlotsData: null,
	savePlotsError: "",
	plotsFilterListStatus: AsyncStates.INITIAL,
	plotsFilterListData: [],
	plotsFilterListError: "",
	deleteSavedPlotsStatus: AsyncStates.INITIAL,
	deleteSavedPlotsData: [],
	deleteSavedPlotsError: "",
	selectedFormulationsDataFromCompare: {},
	correlationStatus: {
		apiStatus: AsyncStates.INITIAL,
		errorMessage: ""
	}
}

const DataSummaryReducer = handleActions<
	any,
	{ data: NumericalSummary; error: string }
>(
	{
		[DataSummaryActionTypes.REQUEST]: (state) => ({
			...state,
			numericalSummaryStatus: AsyncStates.LOADING,
			data: state.data,
			error: null,
			correlationStatus: {
				apiStatus: AsyncStates.LOADING,
				errorMessage: ""
			}
		}),
		[DataSummaryActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				numericalSummaryStatus: AsyncStates.SUCCESS,
				data: { ...state.data, ...action.payload.data },
				error: null,
				correlationStatus: action.payload.correlationStatus
			}
		},
		[DataSummaryActionTypes.FAILURE]: (
			state,
			action: Action<{ error: string }>
		) => ({
			...state,
			numericalSummaryStatus: AsyncStates.ERROR,
			data: null,
			error: action.payload.error,
			correlationStatus: {
				apiStatus: AsyncStates.ERROR,
				errorMessage: "Error"
			}
		}),
		[DataSummaryActionTypes.CLEAR]: (state, action) => ({
			...state,
			numericalSummaryStatus: AsyncStates.INITIAL,
			data: null,
			error: null,
			correlationStatus: {
				apiStatus: AsyncStates.INITIAL,
				errorMessage: ""
			}
		}
		),
		[SurfacePlotActionTypes.REQUEST]: (state) => ({
			...state,
			surfacePlotStatus: AsyncStates.LOADING,
			surfacePlotError: null,
		}),
		[SurfacePlotActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				surfacePlotStatus: AsyncStates.SUCCESS,
				surfacePlotData: action.payload.data,
				surfacePlotError: null,
			}
		},
		[SurfacePlotActionTypes.FAILURE]: (state, action) => ({
			...state,
			surfacePlotStatus: AsyncStates.ERROR,
			surfacePlotData: [],
			surfacePlotError: action.payload.error,
		}),
		[SurfacePlotActionTypes.CLEAR]: (state, action) => ({
			...state,
			surfacePlotStatus: AsyncStates.INITIAL,
			surfacePlotData: [],
			surfacePlotError: null,
		}),
		[AnalyticsRecipeDistributionActionTypes.REQUEST]: (state) => ({
			...state,
			analyticsRecipeDistributionStatus: AsyncStates.LOADING,
			analyticsRecipeDistributionError: null,
		}),
		[AnalyticsRecipeDistributionActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				analyticsRecipeDistributionStatus: AsyncStates.SUCCESS,
				analyticsRecipeDistributionData: action.payload.data,
				analyticsRecipeDistributionError: null,
			}
		},
		[AnalyticsRecipeDistributionActionTypes.FAILURE]: (state, action) => ({
			...state,
			analyticsRecipeDistributionStatus: AsyncStates.ERROR,
			analyticsRecipeDistributionData: [],
			analyticsRecipeDistributionError: action.payload.error,
		}),
		[AnalyticsRecipeDistributionActionTypes.CLEAR]: (state, action) => ({
			...state,
			analyticsRecipeDistributionStatus: AsyncStates.INITIAL,
			analyticsRecipeDistributionData: [],
			analyticsRecipeDistributionError: null,
		}),
		[AnalyticsRecipeDistributionTableActionTypes.REQUEST]: (state) => ({
			...state,
			analyticsRecipeDistributionTableStatus: AsyncStates.LOADING,
			analyticsRecipeDistributionTableError: null,
		}),
		[AnalyticsRecipeDistributionTableActionTypes.SUCCESS]: (state, action: any) => {
			const apiResponse = action.payload.data
			const existingTableState = state.analyticsRecipeDistributionTable?.analyticsRecipeDistributionTableData ?? []
			const tableData = [...existingTableState, apiResponse?.data?.[0]]
			const existingDisplayNameState = state.analyticsRecipeDistributionTable?.analyticsRecipeDistributionTableDisplayNames ?? {}
			const displaynames = {
				"ingredients": [...new Set([...(existingDisplayNameState?.["ingredients"] ?? []), ...(apiResponse?.display_names?.["ingredients"] ?? [])])],
				"processing": [...new Set([...(existingDisplayNameState?.["processing"] ?? []), ...(apiResponse?.display_names?.["processing"] ?? [])])],
				"characterizations": [...new Set([...(existingDisplayNameState?.["characterizations"] ?? []), ...(apiResponse?.display_names?.["characterizations"] ?? [])])],
				"properties": [...new Set([...(existingDisplayNameState?.["properties"] ?? []), ...(apiResponse?.display_names?.["properties"] ?? [])])],
				"linked_trials": {
					...existingDisplayNameState?.["linked_trials"],
					...apiResponse?.display_names?.["linked_trials"]
				}
			}
			return {
				...state,
				analyticsRecipeDistributionTableStatus: AsyncStates.SUCCESS,
				analyticsRecipeDistributionTable: {
					analyticsRecipeDistributionTableData: tableData,
					analyticsRecipeDistributionTableDisplayNames: displaynames
				},
				analyticsRecipeDistributionTableError: null,
			}
		},
		[AnalyticsRecipeDistributionTableActionTypes.FAILURE]: (state, action) => ({
			...state,
			analyticsRecipeDistributionTableStatus: AsyncStates.ERROR,
			analyticsRecipeDistributionTableError: action.payload.error,
		}),
		[AnalyticsRecipeDistributionTableActionTypes.REMOVE]: (state, action: any) => {
			const formulationId = action.payload
			const existingTableState = state.analyticsRecipeDistributionTable?.analyticsRecipeDistributionTableData?.filter((exp: any) => exp.id_set.formulation_id !== formulationId)

			return {
				...state,
				analyticsRecipeDistributionTableStatus: AsyncStates.INITIAL,
				analyticsRecipeDistributionTable: {
					...state.analyticsRecipeDistributionTable,
					analyticsRecipeDistributionTableData: existingTableState,
				},
			}
		},
		[AnalyticsRecipeDistributionTableActionTypes.CLEAR]: (state, action) => ({
			...state,
			analyticsRecipeDistributionTableStatus: AsyncStates.INITIAL,
			analyticsRecipeDistributionTable: {
				analyticsRecipeDistributionTableData: [],
				analyticsRecipeDistributionTableDisplayNames: {}
			},
			analyticsRecipeDistributionTableError: null,
		}),
		[DataSummaryPlotOptionsActionTypes.SUCCESS]: (state, action) => ({
			...state,
			dataSummaryPlotOptions: action.payload.data
		}),
		[DataSummaryPlotOptionsActionTypes.CLEAR]: (state, action) => ({
			...state,
			dataSummaryPlotOptions: {
				plotOptions: {},
				plotOptionsFormulationDisplayNames: {}
			},
		}),
		[RecipeDistributionActionTypes.REQUEST]: (state) => ({
			...state,
			recipeDistributionStatus: AsyncStates.LOADING,
			recipeDistributionData: state.recipeDistributionData,
			recipeDistributionError: null,
		}),
		[RecipeDistributionActionTypes.SUCCESS]: (
			state,
			action: Action<{ data: any }>
		) => {
			return {
				...state,
				recipeDistributionStatus: AsyncStates.SUCCESS,
				recipeDistributionData: action.payload.data.recipeData,
				recipeDistributionDisplayNames: action.payload.data.displayNames,
				recipeDistributionError: null,
			}
		},
		[RecipeDistributionActionTypes.FAILURE]: (
			state,
			action: Action<{ error: string }>
		) => ({
			...state,
			recipeDistributionStatus: AsyncStates.ERROR,
			recipeDistributionData: null,
			recipeDistributionError: action.payload.error,
		}),
		[RecipeDistributionActionTypes.CLEAR]: (
			state,
			action: Action<{ error: string }>
		) => ({
			...state,
			recipeDistributionStatus: AsyncStates.INITIAL,
			recipeDistributionData: [],
			recipeDistributionDisplayNames: {},
			recipeDistributionError: null,
		}),
		[GetZeonCharacterizationDsmActionTypes.REQUEST]: (state) => {
			return {
				...state,
				characterizationMethodsZeonDsmStatus: AsyncStates.LOADING,
				characterizationMethodsZeonDsmError: "",
			}
		},
		[GetZeonCharacterizationDsmActionTypes.SUCCESS]: (
			state,
			action: any
		) => {
			const firstKey = Object.keys(action.payload)[0]

			if (!firstKey)
				return {
					...state,
					characterizationMethodsZeonDsmStatus: AsyncStates.SUCCESS,
					characterizationMethodsZeonDsmError: "",
					characterizationMethodsZeonDsm: {
						type: "variations",
						data: {
							characterization_id: "",
							characterization_name: "",
							variations: [],
						},
					},
				}

			const data =
				firstKey && action.payload[firstKey].variations
					? {
						type: "variations",
						data: {
							characterization_id: firstKey,
							characterization_name: action.payload[firstKey].name,
							variations: action.payload[firstKey].variations,
						},
					}
					: {
						type: "methods",
						data: Object.keys(action.payload).map((chracId) => {
							return {
								characterization_id: chracId,
								characterization_name: action.payload[chracId].name as string,
							}
						}),
					}

			return {
				...state,
				characterizationMethodsZeonDsmStatus: AsyncStates.SUCCESS,
				characterizationMethodsZeonDsmError: "",
				characterizationMethodsZeonDsm: data,
			}
		},
		[GetZeonCharacterizationDsmActionTypes.FAILURE]: (state, action) => ({
			...state,
			characterizationMethodsZeonDsmStatus: AsyncStates.ERROR,
			characterizationMethodsZeonDsmError: action.payload.error,
		}),
		[GetZeonCharacterizationDsmActionTypes.CLEAR]: (state, action) => ({
			...state,
			characterizationMethodsZeonDsmStatus: AsyncStates.INITIAL,
			characterizationMethodsZeonDsmError: "",
			characterizationMethodsZeonDsm: null,
		}),
		[BoxPlotSummaryActionTypes.REQUEST]: (state) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.LOADING,
			boxPlotSummaryError: null,
		}),
		[BoxPlotSummaryActionTypes.SUCCESS]: (state, action) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.SUCCESS,
			boxPlotSummaryData: action.payload.data,
			boxPlotSummaryError: null,
		}),
		[BoxPlotSummaryActionTypes.FAILURE]: (state, action) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.ERROR,
			boxPlotSummaryData: [],
			boxPlotSummaryError: action.payload.error,
		}),
		[BoxPlotSummaryActionTypes.CLEAR]: (state, action) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.INITIAL,
			boxPlotSummaryData: [],
			boxPlotSummaryError: null,
		}),
		[SavePlotsActionTypes.REQUEST]: (state) => ({
			...state,
			savePlotsStatus: AsyncStates.LOADING,
			savePlotsError: null,
		}),
		[SavePlotsActionTypes.SUCCESS]: (state, action) => ({
			...state,
			savePlotsStatus: AsyncStates.SUCCESS,
			savePlotsData: action.payload.data,
			savePlotsError: null,
		}),
		[SavePlotsActionTypes.FAILURE]: (state, action) => ({
			...state,
			savePlotsStatus: AsyncStates.ERROR,
			savePlotsData: [],
			savePlotsError: action.payload.error,
		}),
		[SavePlotsActionTypes.CLEAR]: (state, action) => ({
			...state,
			savePlotsStatus: AsyncStates.INITIAL,
			savePlotsData: [],
			savePlotsError: null,
		}),
		[GetPlotsHistoryActionTypes.REQUEST]: (state) => ({
			...state,
			plotsFilterListStatus: AsyncStates.LOADING,
			plotsFilterListError: null,
		}),
		[GetPlotsHistoryActionTypes.SUCCESS]: (state, action) => ({
			...state,
			plotsFilterListStatus: AsyncStates.SUCCESS,
			plotsFilterListData: action.payload.data,
			plotsFilterListError: null,
		}),
		[GetPlotsHistoryActionTypes.FAILURE]: (state, action) => ({
			...state,
			plotsFilterListStatus: AsyncStates.ERROR,
			plotsFilterListData: [],
			plotsFilterListError: action.payload.error,
		}),
		[GetPlotsHistoryActionTypes.CLEAR]: (state, action) => ({
			...state,
			plotsFilterListStatus: AsyncStates.INITIAL,
			plotsFilterListData: [],
			plotsFilterListError: null,
		}),
		[DeleteSavedPlotsActionTypes.REQUEST]: (state) => ({
			...state,
			deleteSavedPlotsStatus: AsyncStates.LOADING,
			deleteSavedPlotsError: null,
		}),
		[DeleteSavedPlotsActionTypes.SUCCESS]: (state, action: any) => {
			const plotIds: string[] = [action.payload.data]
			return {
				...state,
				plotsFilterListData: state.plotsFilterListData.filter((plot: any) => !plotIds.includes(plot.plots_data_id)),
				deleteSavedPlotsStatus: AsyncStates.SUCCESS,
				deleteSavedPlotsData: action.payload.data,
				deleteSavedPlotsError: null,
			}
		},
		[DeleteSavedPlotsActionTypes.FAILURE]: (state, action) => ({
			...state,
			deleteSavedPlotsStatus: AsyncStates.ERROR,
			deleteSavedPlotsData: [],
			deleteSavedPlotsError: action.payload.error,
		}),
		[DeleteSavedPlotsActionTypes.CLEAR]: (state, action) => ({
			...state,
			deleteSavedPlotsStatus: AsyncStates.INITIAL,
			deleteSavedPlotsData: [],
			deleteSavedPlotsError: null,
		}),
		[FormulationsDataFromCompareActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				selectedFormulationsDataFromCompare: action.payload,
			}
		},
		[FormulationsDataFromCompareActionTypes.CLEAR]: (state, action) => ({
			...state,
			selectedFormulationsDataFromCompare: {},
		}),
	},
	defaultState
)

export default DataSummaryReducer
