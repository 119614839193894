import { PlusOutlined } from "@ant-design/icons";
import {
  Row,
  Typography,
  Select,
  Space,
  Tooltip,
  Col,
  Tag,
  Modal,
  message,
  Collapse,
  Menu,
  Input,
  Empty,
  Checkbox,
  Divider,
  Switch,
  InputNumber
} from "antd";
import { useSelector } from "react-redux";
import { IDataSheet } from "src/components/IDataSheet";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { getDropdownFilteredValue } from "src/utils/decorator";
import useTranslate from "src/utils/useTranslate";
import "./inputConstraints.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StyledCard } from "src/styled_components/StyledCard";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useValue } from "src/utils/useValue";
import { antdTheme } from "src/constants";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";

const EXCEPTIONS = {
  mustIncludes: {
    categories: ["Solvents", "Reactants"],
    parameters: ["water", "substrate"],
  },
};

const { Text } = Typography;

const { Option } = Select;

export const InputConstraints = ({
  view,
  parameterList,
  setParameterList,
  categoricalRange,
  categorialInputList,
  setCategorialInputList,
  catwiseParameterList,
  setCatwiseParameterList,
  modelData,
  setUnsavedChanges,
  range,
  inputContraintsData,
  currentSelectedStage,
  catConstraintsList,
  setCatConstraintsList,
  selectAllIngredientsInCategory,
  setSelectAllIngredientsInCategory,
  exludeAllParameterConstraints,
  setExludeAllParameterConstraints,
  exludeAllCategoryConstraints,
  setExludeAllCategoryConstraints,
  isCrossCategorical,
  setIsCrossCategorical,
  setIsCrossCategoricalModalVisible,
  isMultiStage,
  isCategoricalConstraintsUseDefault,
  setIsCategoricalConstraintsUseDefault,
}: any) => {
  const { convertValue, getValue: getEUValue, getDecimalSeparator } = useValue()
  const [selectedCategoryIngredients, setSelectedCategoryIngredients] =
    useState<any>({ category: "" })
  const [selectedCategoryProcessing, setSelectedCategoryProcessing] =
    useState<any>({ category: "" })

  useEffect(() => {
    setSelectedCategoryIngredients({ category: "" })
    setSelectedCategoryProcessing({ category: "" })
  }, [currentSelectedStage])

  const [t] = useTranslate()
  const labels = useSelector(
    (state: StoreState) => state.displayNames.data || {},
  )

  const INCLUSION_MODES = useMemo(() => [
    {
      value: "default",
      label: t("common.canInclude"),
    },
    {
      value: "include",
      label: t("common.mustInclude"),
    },
    {
      value: "exclude",
      label: t("common.exclude"),
    },
  ], [t]);

  const addRow = useCallback(
    (type: string, category: string, catIndex: number, subtype: string) => {

      if (type === "ingredients") {
        if (selectedCategoryIngredients.category === "") {
          showModalIngredients(subtype)
        } else {
          if (subtype === "numerical") {
            setParameterList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage]?.push({
                parameter: "",
                exclude: exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ?? false,
                include: false,
                min: null,
                max: null,
                type,
                category,
                catIndex,
              })
              return { ...newState }
            })
          }
          if (subtype === "categorical") {
            setCategorialInputList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage]?.push({
                parameter: "",
                data: [],
                category,
                catIndex,
                type,
              })
              return { ...newState }
            })
          }
        }
      }
      if (type === "processing") {
        if (selectedCategoryProcessing.category === "") {
          showModalProcessing(subtype)
        } else {
          if (subtype === "numerical") {
            setParameterList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage]?.push({
                parameter: "",
                exclude: false,
                include: false,
                min: null,
                max: null,
                type,
                category,
                catIndex,
              })
              return { ...newState }
            })
          }
          if (subtype === "categorical") {
            setCategorialInputList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage]?.push({
                parameter: "",
                data: [],
                category,
                catIndex,
                type,
              })
              return { ...newState }
            })
          }
        }
      }
    },
    [
      selectedCategoryIngredients.category,
      setParameterList,
      currentSelectedStage,
      setCategorialInputList,
      selectedCategoryProcessing.category,
      exludeAllParameterConstraints?.numerical_parameter
    ],
  )

  const removeRow = useCallback((parameterIndex: any, subtype, category, parameter) => {
    if (subtype === "numerical") {
      setParameterList((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = newState[
          currentSelectedStage
        ].filter((item: any, index: number) => index !== parameterIndex)
        return { ...newState }
      })

      setSelectAllIngredientsInCategory((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = {
          ...newState[currentSelectedStage],
          [subtype]: {
            ...newState[currentSelectedStage]?.[subtype],
            [category]: !!newState[currentSelectedStage]?.[subtype]?.[category]?.length ? newState[currentSelectedStage]?.[subtype]?.[category].filter((item: any, index: number) => item !== parameter) : []
          }
        }
        return newState
      })

      setExludeAllParameterConstraints((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        const catergoryData = newState[currentSelectedStage]?.numerical_parameter?.[category]?.data?.filter((categoryItem: string) => categoryItem !== parameter) ?? []
        const allCategoryData = newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data?.filter((categoryItem: string) => categoryItem !== parameter) ?? []

        newState[currentSelectedStage] = {
          ...newState[currentSelectedStage],
          numerical_parameter: {
            ...newState[currentSelectedStage]?.numerical_parameter,
            [category]: {
              ...newState[currentSelectedStage]?.numerical_parameter?.[category],
              isChecked: !catergoryData.length ? false : (newState[currentSelectedStage]?.numerical_parameter?.[category]?.isChecked ?? false),
              data: catergoryData,
            },
            ...(!!newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data?.length && ({
              "all": {
                ...newState[currentSelectedStage]?.numerical_parameter?.["all"],
                isChecked: !allCategoryData.length ? false : (newState[currentSelectedStage]?.numerical_parameter?.["all"]?.isChecked ?? false),
                data: allCategoryData
              }
            }))
          }
        }
        return newState
      }, [])
    }
    if (subtype === "categorical") {
      setCategorialInputList((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = newState[
          currentSelectedStage
        ].filter((item: any, index: number) => index !== parameterIndex)
        return { ...newState }
      })
    }
  },
    [setParameterList, setSelectAllIngredientsInCategory, setExludeAllParameterConstraints, currentSelectedStage, setCategorialInputList],
  )

  const categoryListDropDown = useCallback(
    (catIndex: any, type: string, category: string) => {
      return (
        <div style={{ width: "100%", padding: 16 }}>
          <Typography.Text style={{
            textAlign: "left",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            verticalAlign: "bottom",
            display: "block"
          }}>
            {category}
          </Typography.Text>
        </div>
      )
    },
    [],
  )

  const ingredientsDropdownOptionsNumerical = useCallback(
    (category) => {
      let ingredientsNames = modelData?.ingredients
        ? Object.keys(modelData?.ingredients)
        : modelData?.inputs_ingredients
          ? modelData?.inputs_ingredients
          : []
      const collator = new Intl.Collator([], { numeric: true })

      return Object.entries(inputContraintsData || {})
        ?.filter(
          ([key, value]: any) =>
            !parameterList?.map((row: any) => row?.parameter).includes(key) &&
            !Array.isArray(value),
        )
        .filter(([key]: any) => ingredientsNames?.includes(key))
        .filter(([key]: any) =>
          selectedCategoryIngredients.category === ""
            ? catwiseParameterList
              ?.find(
                (typeObj: any) => typeObj.parameter_type === "ingredients",
              )
              ?.categories?.filter((catObj: any) => !catObj.exclude)
              ?.map((catObj: any) => catObj.category)
              .includes(modelData?.categories?.["ingredients"]?.[key]?.[0])
            : modelData?.categories?.["ingredients"]?.[key]?.includes(
              selectedCategoryIngredients.category,
            ),
        )
        .map(([key, value]: any) => ({
          value: key,
          label:
            modelData?.display_names?.ingredients?.[key] ??
            labels?.ingredients?.[key]?.name ??
            labels?.processing?.[key]?.name ??
            key,
          disabled:
            selectedCategoryIngredients.category === ""
              ? !modelData?.categories?.["ingredients"]?.[key]?.includes(
                category,
              )
              : false,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.ingredients,
      modelData?.inputs_ingredients,
      modelData?.categories,
      modelData?.display_names?.ingredients,
      inputContraintsData,
      parameterList,
      selectedCategoryIngredients.category,
      catwiseParameterList,
      labels?.ingredients,
      labels?.processing,
    ],
  )

  const ingredientsDropdownOptionsCategorical = useCallback(
    (category) => {
      let ingredientsNames = modelData?.ingredients
        ? Object.keys(modelData?.ingredients)
        : modelData?.inputs_ingredients
          ? modelData?.inputs_ingredients
          : []
      const collator = new Intl.Collator([], { numeric: false })

      return Object.entries(inputContraintsData || {})
        ?.filter(
          ([key, value]: any) =>
            !categorialInputList
              ?.map((row: any) => row?.parameter)
              .includes(key) && Array.isArray(value),
        )
        .filter(([key]: any) => ingredientsNames?.includes(key))
        .filter(([key]: any) =>
          selectedCategoryIngredients.category === ""
            ? catwiseParameterList
              ?.find(
                (typeObj: any) => typeObj.parameter_type === "ingredients",
              )
              ?.categories?.filter((catObj: any) => !catObj.exclude)
              ?.map((catObj: any) => catObj.category)
              .includes(modelData?.categories?.["ingredients"]?.[key][0])
            : modelData?.categories?.["ingredients"]?.[key]?.includes(
              selectedCategoryIngredients.category,
            ),
        )
        .map(([key, value]: any) => ({
          value: key,
          label:
            modelData?.display_names?.ingredients?.[key] ??
            labels?.ingredients?.[key]?.name ??
            labels?.processing?.[key]?.name ??
            key,
          disabled:
            selectedCategoryIngredients.category === ""
              ? !modelData?.categories?.["ingredients"]?.[key]?.includes(
                category,
              )
              : false,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.ingredients,
      modelData?.inputs_ingredients,
      modelData?.categories,
      modelData?.display_names?.ingredients,
      inputContraintsData,
      categorialInputList,
      selectedCategoryIngredients.category,
      catwiseParameterList,
      labels?.ingredients,
      labels?.processing,
    ],
  )

  const processingDropdownOptionsNumerical = useCallback(
    (category) => {
      let processingNames = modelData?.processing
        ? Object.keys(modelData?.processing)
        : modelData?.inputs_processing
          ? modelData?.inputs_processing
          : []
      const collator = new Intl.Collator([], { numeric: true })

      return Object.entries(inputContraintsData || {})
        ?.filter(
          ([key, value]: any) =>
            !parameterList?.map((row: any) => row?.parameter).includes(key) &&
            !Array.isArray(value),
        )
        .filter(([key]: any) => processingNames?.includes(key))
        .filter(([key]: any) =>
          selectedCategoryProcessing.category === ""
            ? catwiseParameterList
              ?.find((typeObj: any) => typeObj.parameter_type === "processing")
              ?.categories?.filter((catObj: any) => !catObj.exclude)
              ?.map((catObj: any) => catObj.category)
              .includes(modelData?.categories?.["processing"]?.[key][0])
            : modelData?.categories?.["processing"]?.[key]?.includes(
              selectedCategoryProcessing.category,
            ),
        )
        .map(([key, value]: any) => ({
          value: key,
          label:
            labels?.processing?.[key]?.name ??
            labels?.processing?.[key]?.name ??
            key,
          disabled:
            selectedCategoryProcessing.category === ""
              ? !modelData?.categories?.["processing"]?.[key]?.includes(
                category,
              )
              : false,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.processing,
      modelData?.inputs_processing,
      modelData?.categories,
      inputContraintsData,
      parameterList,
      selectedCategoryProcessing.category,
      catwiseParameterList,
      labels?.processing,
    ],
  )

  const processingDropdownOptionsCategorical = useCallback(
    (category) => {
      let processingNames = modelData?.processing
        ? Object.keys(modelData?.processing)
        : modelData?.inputs_processing
          ? modelData?.inputs_processing
          : []
      const collator = new Intl.Collator([], { numeric: false })

      return Object.entries(inputContraintsData || {})
        ?.filter(
          ([key, value]: any) =>
            !categorialInputList
              ?.map((row: any) => row?.parameter)
              .includes(key) && Array.isArray(value),
        )
        .filter(([key]: any) => processingNames?.includes(key))
        .filter(([key]: any) =>
          selectedCategoryProcessing.category === ""
            ? catwiseParameterList
              ?.find((typeObj: any) => typeObj.parameter_type === "processing")
              ?.categories?.filter((catObj: any) => !catObj.exclude)
              ?.map((catObj: any) => catObj.category)
              .includes(modelData?.categories?.["processing"]?.[key][0])
            : modelData?.categories?.["processing"]?.[key]?.includes(
              selectedCategoryProcessing.category,
            ),
        )
        .map(([key, value]: any) => ({
          value: key,
          label:
            labels?.processing?.[key]?.name ??
            labels?.processing?.[key]?.name ??
            key,
          disabled:
            selectedCategoryProcessing.category === ""
              ? !modelData?.categories?.["processing"]?.[key]?.includes(
                category,
              )
              : false,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.processing,
      modelData?.inputs_processing,
      modelData?.categories,
      inputContraintsData,
      categorialInputList,
      selectedCategoryProcessing.category,
      catwiseParameterList,
      labels?.processing,
    ],
  )

  const catwiseParameterConstraintsIngredients = useMemo(() => {
    return catwiseParameterList?.find((typeObj: any) => typeObj.parameter_type === "ingredients")
      ?.categories?.filter((catObj: any) => !catObj.exclude)
  }, [catwiseParameterList])

  const catwiseParameterConstraintsProcessing = useMemo(() => {
    return catwiseParameterList?.find((typeObj: any) => typeObj.parameter_type === "processing")
      ?.categories?.filter((catObj: any) => !catObj.exclude)
  }, [catwiseParameterList])

  const catergoriesWiseIngredients = catwiseParameterConstraintsIngredients?.reduce((acc: any, curr: any) => {
    if (!!curr?.category?.length) {
      return {
        ...acc, [curr.category]: Object.keys(inputContraintsData || {}).reduce((result: string[], currIng) => {
          if (modelData?.categories?.["ingredients"]?.[currIng]?.includes(curr.category)) {
            if (!result.includes(currIng)) {
              result.push(currIng)
            }
          }
          return result
        }, [])
      }
    }
    return acc
  }, {})

  const paramterListDropDown = useCallback(
    (
      index: any,
      parameter: string,
      type: string,
      subtype: string,
      category: string,
    ) => {
      return (
        <Row
          style={{
            alignItems: "center",
            width: "100%",
            padding: 8,
            justifyContent: "space-between",
            gap: 8,
            flexWrap: "nowrap",
          }}
        >
          <Tooltip title={t("common.remove")}>
            <StyledDeleteIcon
              style={{
                fontSize: antdTheme.fontSizeHeading4,
                verticalAlign: "baseline",
                outline: "none",
              }}
              onClick={() => removeRow(index, subtype, category, parameter)}
            />
          </Tooltip>
          {((type === "ingredients" &&
            selectedCategoryIngredients.category === "") ||
            (type === "processing" &&
              selectedCategoryProcessing.category === "")) && (
              <Tag>
                <Typography.Text
                  style={{ width: 60, maxWidth: 60, textAlign: "center" }}
                  ellipsis={{ tooltip: category }}
                >
                  {category}
                </Typography.Text>
              </Tag>
            )}
          <Select
            value={modelData?.display_names?.[type]?.[parameter]}
            showSearch
            placeholder={t("aiEngine.selectInputs")}
            optionFilterProp="children"
            filterOption={(inputValue, options: any) =>
              getDropdownFilteredValue(inputValue, options)
            }
            style={{
              textAlign: "left",
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "bottom",
            }}
            notFoundContent={
              <Space style={{ background: "whitesmoke", width: "100%" }}>
                <Text>{"No such parameter"}</Text>
              </Space>
            }
            dropdownRender={(menu) => {
              return (
                <>
                  {type === "ingredients" && selectedCategoryIngredients.category !== "" &&
                    <Checkbox checked={selectAllIngredientsInCategory?.[subtype]?.[category]?.length === catergoriesWiseIngredients?.[category]?.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const categoriesData = ingredientsDropdownOptionsNumerical(category)
                          if (!!categoriesData?.length) {
                            const filteredKeys = categoriesData.reduce((acc: string[], curr) => {
                              if (!curr.disabled) {
                                if (!acc.includes(curr.value)) {
                                  acc.push(curr.value)
                                }
                              }
                              return acc
                            }, [])

                            setSelectAllIngredientsInCategory((prev: any) => {
                              return {
                                ...prev,
                                [currentSelectedStage]: {
                                  ...prev[currentSelectedStage],
                                  [subtype]: {
                                    ...prev[currentSelectedStage]?.[subtype],
                                    [category]: [...new Set([...(prev[currentSelectedStage]?.[subtype]?.[category] ?? []), ...filteredKeys])]
                                  }
                                }
                              }
                            })

                            const newParametersData = filteredKeys.reduce((acc: any[], curr, index) => {
                              acc.push({
                                catIndex: selectedCategoryIngredients?.catIndex,
                                category,
                                parameter: curr,
                                exclude: false,
                                include: false,
                                min: null,
                                max: null,
                                type
                              })
                              return acc
                            }, [])

                            setParameterList((prevState: any) => {
                              const newState = JSON.parse(JSON.stringify(prevState))
                              const updatedList = [...(newState?.[currentSelectedStage] ?? []), ...newParametersData]
                              newState[currentSelectedStage] = updatedList.filter((inputFeature) => !!inputFeature.parameter.length)
                              return newState
                            })
                          }
                        } else {
                          setSelectAllIngredientsInCategory((prev: any) => {
                            return {
                              ...prev,
                              [currentSelectedStage]: {
                                ...prev[currentSelectedStage],
                                [subtype]: {
                                  ...prev[currentSelectedStage]?.[subtype],
                                  [category]: []
                                }
                              }
                            }
                          })
                          setParameterList((prevState: any) => {
                            const newState = JSON.parse(JSON.stringify(prevState))
                            newState[currentSelectedStage] = newState[currentSelectedStage].filter((res: any) => res.category !== category)
                            return { ...newState }
                          })
                        }

                      }}>Select All</Checkbox>
                  }
                  <Divider style={{ margin: "0.25rem 0rem" }} />
                  {menu}
                </>
              )
            }}
            options={
              type === "ingredients"
                ? subtype === "numerical"
                  ? ingredientsDropdownOptionsNumerical(category)
                  : ingredientsDropdownOptionsCategorical(category)
                : type === "processing"
                  ? subtype === "numerical"
                    ? processingDropdownOptionsNumerical(category)
                    : processingDropdownOptionsCategorical(category)
                  : []
            }
            onChange={(e: any) => {
              if (subtype === "categorical") {
                setCategorialInputList((prevState: any) => {
                  const newState = JSON.parse(JSON.stringify(prevState))
                  newState[currentSelectedStage]?.forEach(
                    (res: any, parameterIndex: any) => {
                      if (index === parameterIndex) {
                        res.parameter = e
                        res.data = []
                      }
                    },
                  )
                  return { ...newState }
                })
              }
              if (subtype === "numerical") {
                setParameterList((prevState: any) => {
                  const newState = JSON.parse(JSON.stringify(prevState))
                  newState[currentSelectedStage]?.forEach(
                    (res: any, parameterIndex: any) => {
                      if (index === parameterIndex) {
                        res.parameter = e
                        res.exclude = exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ?? false
                        res.include = false
                        res.min = exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ? 0 : null
                        res.max = exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ? 0 : null
                      }
                    },
                  )
                  return { ...newState }
                })
                if (exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked) {
                  setExludeAllParameterConstraints((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))
                    newState[currentSelectedStage] = {
                      ...newState[currentSelectedStage],
                      numerical_parameter: {
                        ...newState[currentSelectedStage]?.numerical_parameter,
                        [category]: {
                          ...newState[currentSelectedStage]?.numerical_parameter?.[category],
                          data: [...new Set([...(newState[currentSelectedStage]?.numerical_parameter?.[category]?.data ?? []), e])]
                        },
                        ...(!!newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data?.length && ({
                          "all": {
                            ...newState[currentSelectedStage]?.numerical_parameter?.["all"],
                            data: [...new Set([...(newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data ?? []), e])]
                          }
                        }))
                      }
                    }
                    return newState
                  }, [])
                }

                setSelectAllIngredientsInCategory((prev: any) => {
                  return {
                    ...prev,
                    [currentSelectedStage]: {
                      ...prev[currentSelectedStage],
                      [subtype]: {
                        ...prev[currentSelectedStage]?.[subtype],
                        [category]: [...new Set([...(prev?.[currentSelectedStage]?.[category] ?? []), e])]
                      }
                    },
                  }
                })
              }
              setUnsavedChanges(true)
            }}
          />
        </Row>
      )
    },
    [
      selectedCategoryIngredients.category,
      selectedCategoryProcessing.category,
      t,
      modelData?.display_names,
      ingredientsDropdownOptionsNumerical,
      ingredientsDropdownOptionsCategorical,
      processingDropdownOptionsNumerical,
      processingDropdownOptionsCategorical,
      removeRow,
      setUnsavedChanges,
      setCategorialInputList,
      currentSelectedStage,
      setParameterList,
      selectAllIngredientsInCategory,
      selectedCategoryIngredients?.catIndex,
      catergoriesWiseIngredients,
      setSelectAllIngredientsInCategory,
      exludeAllParameterConstraints?.numerical_parameter,
      setExludeAllParameterConstraints
    ],
  )

  const getConstraintMode = useCallback((catObj: any) => {
    if (catObj.include) {
      return INCLUSION_MODES[1].value
    }

    if (catObj.exclude) {
      return INCLUSION_MODES[2].value
    }

    return INCLUSION_MODES[0].value
  }, [INCLUSION_MODES])

  const excludeCategory = useCallback(
    (index: any, type, catObj) => {
      return (
        <Row justify="center" style={{ width: "100%", padding: 16 }}>
          <Select
            value={getConstraintMode(catObj)}
            showSearch
            placeholder={t("aiEngine.selectInputs")}
            optionFilterProp="children"
            filterOption={(inputValue, options: any) =>
              getDropdownFilteredValue(inputValue, options)
            }
            style={{
              textAlign: "left",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "bottom",
            }}
            notFoundContent={
              <Space style={{ background: "whitesmoke", width: "100%" }}>
                <Text>{"No such parameter"}</Text>
              </Space>
            }
            options={INCLUSION_MODES.map((mode) => ({
              ...mode,
              ...(mode.value === INCLUSION_MODES[2].value &&
                EXCEPTIONS.mustIncludes.categories.includes(
                  catObj.category,
                ) && { disabled: true }),
            }))}
            onChange={(value: string) => {
              setCatwiseParameterList((prevState: any) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage] = prevState[
                  currentSelectedStage
                ].map((typeObj: any) => {
                  if (typeObj.parameter_type === type) {
                    const newTypeObj = {
                      ...typeObj,
                      categories: typeObj.categories.map((_catObj: any) => {
                        if (
                          _catObj.category === catObj.category &&
                          index === _catObj.catIndex
                        ) {
                          if (value === INCLUSION_MODES[0].value) {
                            return {
                              ..._catObj,
                              include: false,
                              exclude: false,
                              min: null,
                              max: null,
                              sum_of_category_quantity: {
                                min: null,
                                max: null,
                              },
                              count_of_category_items: { min: null, max: null },
                            }
                          } else {
                            return value === INCLUSION_MODES[2].value
                              ? {
                                ..._catObj,
                                include: false,
                                exclude: false,
                                [value]: true,
                                min: null,
                                max: null,
                                sum_of_category_quantity: {
                                  min: null,
                                  max: null,
                                },
                                count_of_category_items: {
                                  min: null,
                                  max: null,
                                },
                              }
                              : {
                                ..._catObj,
                                include: false,
                                exclude: false,
                                [value]: true,
                                min: null,
                                max: null,
                                sum_of_category_quantity: {
                                  min: null,
                                  max: null,
                                },
                                count_of_category_items: {
                                  min: null,
                                  max: null,
                                },
                              }
                          }
                        }
                        return _catObj
                      }),
                    }
                    return newTypeObj
                  }
                  return typeObj
                })
                return newState
              })
              setCatConstraintsList((constraints: any) => {
                const newConstraints = JSON.parse(JSON.stringify(constraints))
                newConstraints[currentSelectedStage] = constraints[
                  currentSelectedStage
                ].map((constraint: any) => {
                  if (
                    constraint.catIndex === index &&
                    constraint.type === type
                  ) {
                    const newConstraint = {
                      ...constraint,
                      min: null,
                      max: null,
                    }
                    return newConstraint
                  }
                  return constraint
                })
                return { ...newConstraints }
              })

              if (type === "ingredients") {
                setExludeAllCategoryConstraints((prev: any) => {
                  const newState = JSON.parse(JSON.stringify(prev))
                  const excludeCategoryData = newState[currentSelectedStage]?.[type] ?? []
                  newState[currentSelectedStage] = {
                    ...newState[currentSelectedStage],
                    [type]: value !== "exclude" ? excludeCategoryData?.filter((category: string) => category !== catObj.category) : value === "exclude" ? excludeCategoryData?.includes(catObj.category) ? excludeCategoryData : [...excludeCategoryData, catObj.category] : excludeCategoryData
                  }
                  return newState
                })
              }
              // To remove items when excludes from parameter contraints
              if (value === "exclude") {
                setParameterList((prevState: any) => {
                  const newState = JSON.parse(JSON.stringify(prevState))
                  newState[currentSelectedStage] = newState[currentSelectedStage].filter((parameter: any) => parameter.category !== catObj.category)
                  return newState
                })

                if (type === "ingredients") {
                  const subtype = "numerical"

                  setSelectAllIngredientsInCategory((prev: any) => {
                    return {
                      ...prev,
                      [currentSelectedStage]: {
                        ...prev[currentSelectedStage],
                        [subtype]: {
                          ...prev[currentSelectedStage]?.[subtype],
                          [catObj.category]: []
                        }
                      }
                    }
                  })
                }
              }
              setUnsavedChanges(true)
            }}
          />
        </Row>
      )
    },
    [
      getConstraintMode,
      t,
      setCatwiseParameterList,
      setCatConstraintsList,
      setUnsavedChanges,
      currentSelectedStage,
      setExludeAllCategoryConstraints,
      setParameterList,
      setSelectAllIngredientsInCategory,
      INCLUSION_MODES
    ],
  )

  const excludeIngredients = useCallback(
    (index: any, parObj: any) => {
      return (
        <Row justify="center">
          <Select
            value={getConstraintMode(parObj)}
            showSearch
            placeholder={t("aiEngine.selectInputs")}
            optionFilterProp="children"
            filterOption={(inputValue, options: any) =>
              getDropdownFilteredValue(inputValue, options)
            }
            style={{
              textAlign: "left",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "bottom",
            }}
            notFoundContent={
              <Space style={{ background: "whitesmoke", width: "100%" }}>
                <Text>{"No such parameter"}</Text>
              </Space>
            }
            options={INCLUSION_MODES.map((mode) => ({
              ...mode,
              ...(mode.value === INCLUSION_MODES[2].value &&
                EXCEPTIONS.mustIncludes.parameters.includes(
                  parObj.parameter,
                ) && { disabled: true }),
            }))}
            onChange={(value: any) => {
              setParameterList((prevState: any) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage] = prevState[
                  currentSelectedStage
                ].map((res: any, parameterIndex: any) => {
                  if (index === parameterIndex) {
                    if (value === INCLUSION_MODES[0].value) {
                      return {
                        ...res,
                        include: false,
                        exclude: false,
                        min: null,
                        max: null,
                      }
                    } else {
                      return value === INCLUSION_MODES[2].value
                        ? {
                          ...res,
                          include: false,
                          exclude: false,
                          [value]: true,
                          min: 0,
                          max: 0,
                        }
                        : {
                          ...res,
                          include: false,
                          exclude: false,
                          [value]: true,
                          min: null,
                          max: null,
                        }
                    }
                  }
                  return res
                })
                return newState
              })
              if (parObj.type === "ingredients") {
                if (value === "exclude") {
                  setExludeAllParameterConstraints((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))

                    newState[currentSelectedStage] = {
                      ...newState[currentSelectedStage],
                      numerical_parameter: {
                        ...newState[currentSelectedStage]?.numerical_parameter,
                        [parObj.category]: {
                          ...newState[currentSelectedStage]?.numerical_parameter?.[parObj.category],
                          data: [...new Set([...(newState[currentSelectedStage]?.numerical_parameter?.[parObj.category]?.data ?? []), parObj.parameter])]
                        },
                        ...(!!newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data?.length && ({
                          "all": {
                            ...newState[currentSelectedStage]?.numerical_parameter?.["all"],
                            data: [...new Set([...(newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data ?? []), parObj.parameter])]
                          }
                        }))
                      }
                    }
                    return newState
                  }, [])
                } else {
                  setExludeAllParameterConstraints((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))
                    newState[currentSelectedStage] = {
                      ...newState[currentSelectedStage],
                      numerical_parameter: {
                        ...newState[currentSelectedStage]?.numerical_parameter,
                        [parObj.category]: {
                          ...newState[currentSelectedStage]?.numerical_parameter?.[parObj.category],
                          data: (newState[currentSelectedStage]?.numerical_parameter?.[parObj.category]?.data ?? [])?.filter((categoryItem: string) => categoryItem !== parObj.parameter),
                        },
                        ...(!!(newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data ?? [])?.length && ({
                          "all": {
                            ...newState[currentSelectedStage]?.numerical_parameter?.["all"],
                            data: newState[currentSelectedStage]?.numerical_parameter?.["all"]?.data?.filter((categoryItem: string) => categoryItem !== parObj.parameter)
                          }
                        }))
                      }
                    }
                    return newState
                  }, [])
                }
              }
            }}
          />
        </Row>
      )
    },
    [getConstraintMode, t, setParameterList, currentSelectedStage, setExludeAllParameterConstraints, INCLUSION_MODES],
  )

  const categorialInputListDropDown = useCallback(
    (index: any, parameter: any) => {
      const isCategorical = Array.isArray(
        modelData?.inputs_range?.[parameter] ??
        inputContraintsData?.[parameter],
      )
      return (
        <Select
          style={{ minWidth: 250, width: '100%' }}
          value={categorialInputList?.[index]?.data}
          mode="multiple"
          onChange={(e: any) => {
            setCategorialInputList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage][index].data = e
              return { ...newState }
            })
            setUnsavedChanges(true)
          }}
        >
          {isCategorical &&
            (
              modelData?.inputs_range?.[parameter] ??
              inputContraintsData?.[parameter]
            )?.map((res: any) => (
              <Option value={res}>
                {typeof res === "number"
                  ? res
                  : res?.[0]?.toUpperCase() + res.slice(1)}
              </Option>
            ))}
        </Select>
      )
    },
    [categorialInputList, currentSelectedStage, inputContraintsData, modelData?.inputs_range, setCategorialInputList, setUnsavedChanges],
  )

  const minMaxInputComponentCategoryConstraint = useCallback(
    (type, categoryObj, constraintIndex) => {
      return (
        <div style={{ padding: 10 }}>
          <InputNumber
            decimalSeparator={getDecimalSeparator()}
            disabled={!!categoryObj?.exclude ? true : false}
            className="min__max__input"
            value={
              catConstraintsList?.[constraintIndex]?.[type[1]] === null
                ? ""
                : catConstraintsList?.[constraintIndex]?.[type[1]]
            }
            onChange={(e: any) => {
              const strValue = e
              const newCatConstraintList: any = [...catConstraintsList]
              if (type[0] === "category_constraint") {
                newCatConstraintList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (type[1] === "min") {
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== "" ? strValue : null,
                      }
                    } else if (type[1] === "max") {
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== "" ? strValue : null,
                      }
                    }
                  }
                  return p
                })
              }
              setCatConstraintsList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newCatConstraintList,
              }))
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              const newCatConstraintList: any = [...catConstraintsList]
              if (type[0] === "category_constraint") {
                newCatConstraintList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (isNaN(convertedValue)) {
                      newCatConstraintList[idx][type[1]] = null
                      return message.error(
                        t("aiEngine.pleaseEnterAValidNumber"),
                      )
                    }
                    if (type[1] === "min") {
                      if (newCatConstraintList[idx]?.max !== null) {
                        if (newCatConstraintList[idx]?.max < convertedValue) {
                          newCatConstraintList[idx].min = null
                          return message.error(
                            t("aiEngine.minShouldBeLessThanMax"),
                          )
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== "" ? convertedValue : null,
                      }
                    } else if (type[1] === "max") {
                      if (newCatConstraintList[idx]?.min !== null) {
                        if (newCatConstraintList[idx]?.min > convertedValue) {
                          newCatConstraintList[idx].max = null
                          return message.error(
                            t("aiEngine.maxShouldBeGreaterThanMin"),
                          )
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== "" ? convertedValue : null,
                      }
                    }
                  }
                  return p
                })
              }
              setCatConstraintsList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newCatConstraintList,
              }))
              setUnsavedChanges(true)
            }}
            key={`${type[0]}-${type[1]}-${constraintIndex}`}
          />
        </div>
      )
    },
    [catConstraintsList, currentSelectedStage, setCatConstraintsList, setUnsavedChanges, t, convertValue, getDecimalSeparator],
  )

  const catwiseStateIngredients = useMemo(
    () => {
      return [
        ...(!!(catwiseParameterList || [])
          ?.filter((typeObj: any) => typeObj.parameter_type === "ingredients")
          ?.flatMap((o: any) => o.categories)?.length
          ? [
            [
              {
                value: t("common.category"),
                forceComponent: true,
                readOnly: true,
                width: 200,
                component: (
                  <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                    <Typography.Text strong>
                      {t("common.category")}
                    </Typography.Text>
                  </Row>
                ),
              },
              {
                value: t("aiEngine.inclusionMode"),
                component: (
                  <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                    <Typography.Text strong>
                      {t("aiEngine.inclusionMode")}
                    </Typography.Text>
                  </Row>
                ),
                forceComponent: true,
                readOnly: true,
                width: 200,
              },
              {
                value: `${t("common.min")} ${t("common.quantity")}`,
                readOnly: true,
                forceComponent: true,
                component: (
                  <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                    <Typography.Text strong>
                      {`${t("common.min")} ${t("common.quantity")}`}
                    </Typography.Text>
                  </Row>
                ),
              },
              {
                value: `${t("common.max")} ${t("common.quantity")}`,
                readOnly: true,
                forceComponent: true,
                component: (
                  <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                    <Typography.Text strong>
                      {`${t("common.max")} ${t("common.quantity")}`}
                    </Typography.Text>
                  </Row>
                ),
              },
              {
                value: `${t("common.min")} ${t("common.count")}`,
                readOnly: true,
                forceComponent: true,
                component: (
                  <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                    <Typography.Text strong>
                      {`${t("common.min")} ${t("common.count")}`}
                    </Typography.Text>
                  </Row>
                ),
              },
              {
                value: `${t("common.max")} ${t("common.count")}`,
                readOnly: true,
                forceComponent: true,
                component: (
                  <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                    <Typography.Text strong>
                      {`${t("common.max")} ${t("common.count")}`}
                    </Typography.Text>
                  </Row>
                ),
              },
            ],
          ]
          : []),
        ...(catwiseParameterList || [])
          ?.filter((typeObj: any) => typeObj.parameter_type === "ingredients")
          ?.flatMap((typeObj: any) => {
            return [
              ...typeObj?.categories?.flatMap((categoryObj: any) => {
                return [
                  [
                    {
                      value: "",
                      component: categoryListDropDown(
                        categoryObj.catIndex,
                        typeObj.parameter_type,
                        categoryObj.category,
                      ),
                      forceComponent: true,
                    },
                    {
                      value: String(categoryObj?.exclude),
                      component: excludeCategory(
                        categoryObj.catIndex,
                        typeObj.parameter_type,
                        categoryObj,
                      ),
                      forceComponent: true,
                      disableEvents: true,
                    },
                    ...(catConstraintsList || [])
                      ?.flatMap((constraintObj: any, index: any) => ({
                        constraintObj,
                        index,
                      }))
                      .filter(({ constraintObj }: any) => {
                        const flag =
                          constraintObj.type === typeObj.parameter_type &&
                          constraintObj.category === categoryObj.category &&
                          constraintObj.catIndex === categoryObj.catIndex
                        return flag
                      })
                      .flatMap(({ constraintObj, index }: any) => {
                        return [
                          {
                            value: constraintObj.min,
                            type: ["category_constraint", "min"],
                            constraintIndex: index,
                            readOnly: !!categoryObj?.exclude ? true : false,
                            component: minMaxInputComponentCategoryConstraint(
                              ["category_constraint", "min"],
                              categoryObj,
                              index,
                            ),
                            forceComponent: true,
                            disableEvents: true,
                          },
                          {
                            value: constraintObj.max,
                            type: ["category_constraint", "max"],
                            constraintIndex: index,
                            readOnly: !!categoryObj?.exclude ? true : false,
                            component: minMaxInputComponentCategoryConstraint(
                              ["category_constraint", "max"],
                              categoryObj,
                              index,
                            ),
                            forceComponent: true,
                            disableEvents: true,
                          },
                        ]
                      }),
                  ],
                ]
              }),
            ]
          }),
      ]
    },
    [
      catwiseParameterList,
      t,
      categoryListDropDown,
      excludeCategory,
      catConstraintsList,
      minMaxInputComponentCategoryConstraint,
    ],
  )

  const catwiseStateProcessing = useMemo(
    () => [
      ...(!!(catwiseParameterList || [])
        ?.filter((typeObj: any) => typeObj.parameter_type === "processing")
        .flatMap((o: any) => o.categories)?.length
        ? [
          [
            {
              value: t("common.category"),
              forceComponent: true,
              readOnly: true,
              width: 200,
              component: (
                <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                  <Typography.Text strong>
                    {t("common.category")}
                  </Typography.Text>
                </Row>
              ),
            },
            {
              value: t("aiEngine.inclusionMode"),
              component: (
                <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                  <Typography.Text strong>
                    {t("aiEngine.inclusionMode")}
                  </Typography.Text>
                </Row>
              ),
              forceComponent: true,
              readOnly: true,
              width: 200,
            },
            {
              value: `${t("common.min")} ${t("common.quantity")}`,
              readOnly: true,
              forceComponent: true,
              component: (
                <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                  <Typography.Text strong>
                    {`${t("common.min")} ${t("common.quantity")}`}
                  </Typography.Text>
                </Row>
              ),
            },
            {
              value: `${t("common.max")} ${t("common.quantity")}`,
              readOnly: true,
              forceComponent: true,
              component: (
                <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                  <Typography.Text strong>
                    {`${t("common.max")} ${t("common.quantity")}`}
                  </Typography.Text>
                </Row>
              ),
            },
            {
              value: `${t("common.min")} ${t("common.count")}`,
              readOnly: true,
              forceComponent: true,
              component: (
                <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                  <Typography.Text strong>
                    {`${t("common.min")} ${t("common.count")}`}
                  </Typography.Text>
                </Row>
              ),
            },
            {
              value: `${t("common.max")} ${t("common.count")}`,
              readOnly: true,
              forceComponent: true,
              component: (
                <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                  <Typography.Text strong>
                    {`${t("common.max")} ${t("common.count")}`}
                  </Typography.Text>
                </Row>
              ),
            },
          ],
        ]
        : []),
      ...(catwiseParameterList || [])
        ?.filter((typeObj: any) => typeObj.parameter_type === "processing")
        .flatMap((typeObj: any) => {
          return [
            ...typeObj.categories?.flatMap((categoryObj: any) => {
              return [
                [
                  {
                    value: "",
                    component: categoryListDropDown(
                      categoryObj.catIndex,
                      typeObj.parameter_type,
                      categoryObj.category,
                    ),
                    forceComponent: true,
                  },
                  {
                    value: String(categoryObj?.exclude),
                    component: excludeCategory(
                      categoryObj.catIndex,
                      typeObj.parameter_type,
                      categoryObj,
                    ),
                    forceComponent: true,
                    disableEvents: true,
                  },
                  ...(catConstraintsList || [])
                    ?.flatMap((constraintObj: any, index: any) => ({
                      constraintObj,
                      index,
                    }))
                    .filter(({ constraintObj }: any) => {
                      const flag =
                        constraintObj.type === typeObj.parameter_type &&
                        constraintObj.category === categoryObj.category &&
                        constraintObj.catIndex === categoryObj.catIndex
                      return flag
                    })
                    .flatMap(({ constraintObj, index }: any) => {
                      return [
                        {
                          value: constraintObj.min,
                          type: ["category_constraint", "min"],
                          constraintIndex: index,
                          readOnly: !!categoryObj?.exclude ? true : false,
                          component: minMaxInputComponentCategoryConstraint(
                            ["category_constraint", "min"],
                            categoryObj,
                            index,
                          ),
                          forceComponent: true,
                          disableEvents: true
                        },
                        {
                          value: constraintObj.max,
                          type: ["category_constraint", "max"],
                          constraintIndex: index,
                          readOnly: !!categoryObj?.exclude ? true : false,
                          component: minMaxInputComponentCategoryConstraint(
                            ["category_constraint", "max"],
                            categoryObj,
                            index,
                          ),
                          forceComponent: true,
                          disableEvents: true
                        },
                      ]
                    }),
                ],
              ]
            }),
          ]
        }),
    ],
    [
      catwiseParameterList,
      t,
      categoryListDropDown,
      excludeCategory,
      catConstraintsList,
      minMaxInputComponentCategoryConstraint,
    ],
  )

  useEffect(() => {
    setCatwiseParameterList((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = newState[currentSelectedStage]?.map(
        (typeObj: any) => {
          const newTypeObj = {
            ...typeObj,
            categories: typeObj.categories.map(
              (catObj: any, catIndex: number) => {
                const newCatObj = {
                  ...catObj,
                  ...(catConstraintsList || [])
                    ?.filter(
                      (constraintObj: any) =>
                        constraintObj.type === typeObj.parameter_type &&
                        catIndex === constraintObj.catIndex,
                    )
                    ?.reduce((acc: any, constraintObj: any) => {
                      const newConstraint = {
                        ...acc,
                        ...constraintObj,
                        ...{
                          [constraintObj.constraint]: {
                            min: constraintObj.min,
                            max: constraintObj.max,
                          },
                        },
                        item_constraints: {
                          ...(categorialInputList || [])
                            ?.filter(({ category, catIndex, type }: any) => {
                              const flag =
                                constraintObj.category === category &&
                                catIndex === constraintObj.catIndex &&
                                type === constraintObj.type
                              return flag
                            })
                            .reduce(
                              (acc: any, paramObj: any) => ({
                                ...acc,
                                ...(paramObj.parameter && !!paramObj?.data?.length
                                  ? { [paramObj.parameter]: paramObj.data }
                                  : {}),
                              }),
                              {},
                            ),
                          ...(parameterList || [])
                            ?.filter(({ category, catIndex, type }: any) => {
                              const flag =
                                constraintObj.category === category &&
                                catIndex === constraintObj.catIndex &&
                                type === constraintObj.type
                              return flag
                            })
                            .reduce(
                              (acc: any, paramObj: any) => ({
                                ...acc,
                                ...(paramObj.parameter
                                  ? {
                                    [paramObj.parameter]: {
                                      ...paramObj,
                                      range: {
                                        min: paramObj.min,
                                        max: paramObj.max,
                                      },
                                      exclude: paramObj.exclude,
                                      include: paramObj.include,
                                    },
                                  }
                                  : {}),
                              }),
                              {},
                            ),
                        },
                      }
                      return newConstraint
                    }, {}),
                }
                return newCatObj
              },
            ),
          }

          return newTypeObj
        },
      )
      return newState
    })
  }, [
    parameterList,
    catConstraintsList,
    setCatwiseParameterList,
    categorialInputList,
    currentSelectedStage,
  ])

  const [isModalOpenIngredients, setIsModalOpenIngredients] = useState({
    visible: false,
    sourceSubtype: "",
  })
  const [isModalOpenProcessing, setIsModalOpenProcessing] = useState({
    visible: false,
    sourceSubtype: "",
  })

  const handleChangeIngredients = (catObj: any, checked: boolean) => {
    if (checked) {
      setSelectedCategoryIngredients(catObj)
    }
  }

  const handleChangeProcessing = (catObj: any, checked: boolean) => {
    if (checked) {
      setSelectedCategoryProcessing(catObj)
    }
  }

  const handleModalSelectionIngredients = (
    { catObj }: any,
    subtype: string,
  ) => {
    const { category, catIndex } = catObj
    setSelectedCategoryIngredients(catObj)
    if (subtype === "categorical") {
      setCategorialInputList((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage]?.push({
          parameter: "",
          data: [],
          category,
          catIndex,
          type: catObj.type,
        })
        return { ...newState }
      })
    }
    if (subtype === "numerical") {
      setParameterList((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage]?.push({
          parameter: "",
          exclude: false,
          include: false,
          min: null,
          max: null,
          type: catObj.type,
          category,
          catIndex,
        })
        return { ...newState }
      })
    }
    setIsModalOpenIngredients({ visible: false, sourceSubtype: "" })
  }

  const handleModalSelectionProcessing = ({ catObj }: any, subtype: string) => {
    const { category, catIndex } = catObj
    setSelectedCategoryProcessing(catObj)
    if (subtype === "categorical") {
      setCategorialInputList((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage]?.push({
          parameter: "",
          data: [],
          category,
          catIndex,
          type: catObj.type,
        })
        return { ...newState }
      })
    }
    if (subtype === "numerical") {
      setParameterList((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage]?.push({
          parameter: "",
          exclude: false,
          include: false,
          min: null,
          max: null,
          type: catObj.type,
          category,
          catIndex,
        })
        return { ...newState }
      })
    }
    setIsModalOpenProcessing({ visible: false, sourceSubtype: "" })
  }

  const showModalIngredients = (subtype: string) => {
    setIsModalOpenIngredients({ visible: true, sourceSubtype: subtype })
  }

  const showModalProcessing = (subtype: string) => {
    setIsModalOpenProcessing({ visible: true, sourceSubtype: subtype })
  }

  const handleCancelIngredients = () => {
    setIsModalOpenIngredients({ visible: false, sourceSubtype: "" })
  }

  const handleCancelProcessing = () => {
    setIsModalOpenProcessing({ visible: false, sourceSubtype: "" })
  }

  const minMaxInputComponentParameterConstraint = useCallback(
    (type, parObj, constraintIndex) => {
      return (
        <div style={{ padding: 10 }}>
          <Input
            disabled={!!parObj?.exclude ? true : false}
            style={{ textAlign: "right" }}
            value={
              parameterList?.[constraintIndex]?.[type[1]] === null
                ? ""
                : parameterList?.[constraintIndex]?.[type[1]]
            }
            onChange={(e: any) => {
              const strValue = e.target.value
              const newParameterList: any = [...parameterList]
              if (type[0] === "parameter_constraint") {
                newParameterList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (type[1] === "min") {
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== "" ? strValue : null,
                      }
                    } else if (type[1] === "max") {
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== "" ? strValue : null,
                      }
                    }
                  }
                  return p
                })
              }
              setParameterList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newParameterList,
              }))
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              const newParameterList: any = [...parameterList]
              if (type[0] === "parameter_constraint") {
                newParameterList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (isNaN(convertedValue)) {
                      newParameterList[idx][type[1]] = null
                      return message.error(
                        t("aiEngine.pleaseEnterAValidNumber"),
                      )
                    }
                    if (type[1] === "min") {
                      if (newParameterList[idx]?.max !== null) {
                        if (convertValue(newParameterList[idx]?.max) < convertedValue) {
                          newParameterList[idx].min = null
                          return message.error(
                            t("aiEngine.minShouldBeLessThanMax"),
                          )
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== "" ? getEUValue(strValue) : null,
                      }
                    } else if (type[1] === "max") {
                      if (newParameterList[idx]?.min !== null) {
                        if (convertValue(newParameterList[idx]?.min) > convertedValue) {
                          newParameterList[idx].max = null
                          return message.error(
                            t("aiEngine.maxShouldBeGreaterThanMin"),
                          )
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== "" ? getEUValue(strValue) : null,
                      }
                    }
                  }
                  return p
                })
              }
              setParameterList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newParameterList,
              }))
              setUnsavedChanges(true)
            }}
          />
        </div>
      )
    },
    [currentSelectedStage, parameterList, setParameterList, setUnsavedChanges, t, convertValue, getEUValue],
  )

  const nonExcludedCategories = useMemo(() => !!catwiseParameterConstraintsIngredients?.length ? catwiseParameterConstraintsIngredients.map((catObj: any) => catObj?.category) ?? [] : [], [catwiseParameterConstraintsIngredients])

  const ingredientsNumericalParameterConstraintsData = useMemo(() => {
    return [
      ...(parameterList || [])
        ?.map((parObj: any, index: any) => ({
          parObj,
          index,
        }))
        .filter(({ parObj }: any) => parObj.type === "ingredients")
        .filter(({ parObj }: any) => {
          return selectedCategoryIngredients.category === "" ? nonExcludedCategories.includes(parObj.category) : true
        }).filter(({ parObj }: any) => {
          const flag = parObj.category === selectedCategoryIngredients.category || selectedCategoryIngredients.category === ""
          return flag
        }).flatMap(({ parObj, index }: any, _index: number) => [
          ...(!_index
            ? [
              [
                {
                  value: t("common.ingredients"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.inputFeatures")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                },
                {
                  value: t("inputConstraints.optimalRange"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("inputConstraints.optimalRange")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
                {
                  value: t("aiEngine.inclusionMode"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.inclusionMode")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                },
                {
                  value: t("common.min"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("common.min")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
                {
                  value: t("common.max"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("common.max")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
              ],
            ]
            : []),
          [
            {
              value: index,
              component: paramterListDropDown(
                index,
                parObj.parameter,
                parObj.type,
                "numerical",
                parObj.category,
              ),
              forceComponent: true,
              disableEvents: true,
              className: "dropdown-remove-cell",
              width: 400,
            },
            {
              value: `${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.min, 3, true) ??
                convertValue(inputContraintsData?.[parObj?.parameter]?.min, 3, true) ??
                ""
                } - ${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.max, 3, true) ??
                convertValue(inputContraintsData?.[parObj?.parameter]?.max, 3, true) ??
                ""
                }`,
              readOnly: true,
            },
            {
              value: index,
              component: excludeIngredients(index, parObj),
              forceComponent: true,
              disableEvents: true,
            },
            {
              value: parObj?.min,
              readOnly: !!parObj?.exclude ? true : false,
              type: ["parameter_constraint", "min"],
              constraintIndex: index,
              component: minMaxInputComponentParameterConstraint(
                ["parameter_constraint", "min"],
                parObj,
                index,
              ),
              forceComponent: true,
              disableEvents: true
            },
            {
              value: parObj?.max,
              readOnly: !!parObj?.exclude ? true : false,
              type: ["parameter_constraint", "max"],
              constraintIndex: index,
              component: minMaxInputComponentParameterConstraint(
                ["parameter_constraint", "max"],
                parObj,
                index,
              ),
              forceComponent: true,
              disableEvents: true
            },
          ],
        ]),
    ]
  }, [
    excludeIngredients,
    inputContraintsData,
    minMaxInputComponentParameterConstraint,
    modelData?.inputs_range,
    parameterList,
    paramterListDropDown,
    selectedCategoryIngredients.category,
    nonExcludedCategories,
    t,
    convertValue
  ])

  const ingredientsCategoricalParameterConstraintsData = useMemo(() => {
    return [
      ...(categorialInputList || [])
        ?.map((parObj: any, index: any) => ({
          parObj,
          index,
        }))
        .filter(({ parObj }: any) => parObj.type === "ingredients")
        .filter(({ parObj }: any) => {
          const flag =
            parObj.category === selectedCategoryIngredients.category ||
            selectedCategoryIngredients.category === ""
          return flag
        })
        .flatMap(({ parObj, index }: any, _index: number) => [
          ...(!_index
            ? [
              [
                {
                  value: t("common.ingredients"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.inputFeatures")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                },
                {
                  value: t("common.value"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("common.value")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
              ],
            ]
            : []),
          [
            {
              value: index,
              component: paramterListDropDown(
                index,
                parObj.catIndex,
                parObj.type,
                "categorical",
                parObj.category,
              ),
              forceComponent: true,
              disableEvents: true,
              className: "dropdown-remove-cell",
              width: 400,
            },
            {
              value: parObj?.min,
              component: categorialInputListDropDown(index, parObj.parameter),
              forceComponent: true,
              readOnly: !!parObj?.exclude ? true : false,
              type: ["parameter_constraint", "min"],
              constraintIndex: index,
            },
          ],
        ]),
    ]
  }, [
    categorialInputList,
    categorialInputListDropDown,
    paramterListDropDown,
    selectedCategoryIngredients.category,
    t,
  ])

  const processingNumericalParameterConstraintsData = useMemo(() => {
    return [
      ...(parameterList || [])
        ?.map((parObj: any, index: any) => ({
          parObj,
          index,
        }))
        .filter(({ parObj }: any) => parObj.type === "processing")
        .filter(({ parObj }: any) => {
          const flag =
            parObj.category === selectedCategoryProcessing.category ||
            selectedCategoryProcessing.category === ""
          return flag
        })
        .flatMap(({ parObj, index }: any, _index: number) => [
          ...(!_index
            ? [
              [
                {
                  value: t("common.processing"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.inputFeatures")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                },
                {
                  value: t("inputConstraints.optimalRange"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("inputConstraints.optimalRange")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
                {
                  value: t("aiEngine.inclusionMode"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.inclusionMode")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                },
                {
                  value: t("common.min"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("common.min")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
                {
                  value: t("common.max"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("common.max")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
              ],
            ]
            : []),
          [
            {
              value: index,
              component: paramterListDropDown(
                index,
                parObj.parameter,
                parObj.type,
                "numerical",
                parObj.category,
              ),
              forceComponent: true,
              disableEvents: true,
              className: "dropdown-remove-cell",
              width: 400,
            },
            {
              value: `${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.min, 3, true) ??
                convertValue(inputContraintsData?.[parObj?.parameter]?.min, 3, true) ??
                ""
                } - ${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.max, 3, true) ??
                convertValue(inputContraintsData?.[parObj?.parameter]?.max, 3, true) ??
                ""
                }`,
              readOnly: true,
            },
            {
              value: index,
              component: excludeIngredients(index, parObj),
              forceComponent: true,
              disableEvents: true,
            },
            {
              value: parObj?.min,
              readOnly: !!parObj?.exclude ? true : false,
              type: ["parameter_constraint", "min"],
              constraintIndex: index,
              component: minMaxInputComponentParameterConstraint(
                ["parameter_constraint", "min"],
                parObj,
                index,
              ),
              forceComponent: true,
              disableEvents: true,
            },
            {
              value: parObj?.max,
              readOnly: !!parObj?.exclude ? true : false,
              type: ["parameter_constraint", "max"],
              constraintIndex: index,
              component: minMaxInputComponentParameterConstraint(
                ["parameter_constraint", "max"],
                parObj,
                index,
              ),
              forceComponent: true,
              disableEvents: true,
            },
          ],
        ]),
    ]
  }, [
    excludeIngredients,
    inputContraintsData,
    minMaxInputComponentParameterConstraint,
    modelData?.inputs_range,
    parameterList,
    paramterListDropDown,
    selectedCategoryProcessing.category,
    t,
    convertValue
  ])

  const categoriesList: { [key: string]: string[] } = useMemo(() => {
    return {
      ingredients: !!catwiseParameterList?.length ? catwiseParameterList?.reduce((acc: any, curr: any) => {
        if (curr?.parameter_type === "ingredients") {
          const categories = curr.categories.map((categoryItem: any) => categoryItem.category)
          if (!!categories.length) {
            acc.push(...categories)
          }
        }
        return [...new Set(acc)]
      }, []) : []
    }
  }, [catwiseParameterList])


  const processingCategoricalParameterConstraintsData = useMemo(() => {
    return [
      ...(categorialInputList || [])
        ?.map((parObj: any, index: any) => ({
          parObj,
          index,
        }))
        .filter(({ parObj }: any) => parObj.type === "processing")
        .filter(({ parObj }: any) => {
          const flag =
            parObj.category === selectedCategoryProcessing.category ||
            selectedCategoryProcessing.category === ""
          return flag
        })
        .flatMap(({ parObj, index }: any, _index: number) => [
          ...(!_index
            ? [
              [
                {
                  value: t("common.processing"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.inputFeatures")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                },
                {
                  value: t("common.value"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                      >
                        {t("common.value")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                },
              ],
            ]
            : []),
          [
            {
              value: index,
              component: paramterListDropDown(
                index,
                parObj.catIndex,
                parObj.type,
                "categorical",
                parObj.category,
              ),
              forceComponent: true,
              disableEvents: true,
              className: "dropdown-remove-cell",
              width: 400,
            },
            {
              value: parObj?.min,
              component: categorialInputListDropDown(index, parObj.parameter),
              forceComponent: true,
              readOnly: !!parObj?.exclude ? true : false,
              type: ["parameter_constraint", "min"],
              constraintIndex: index,
            },
          ],
        ]),
    ]
  }, [
    categorialInputList,
    categorialInputListDropDown,
    paramterListDropDown,
    selectedCategoryProcessing.category,
    t,
  ])

  const updateInclusionMode = (e: CheckboxChangeEvent, parameterType: string) => {
    setCatwiseParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = prevState[currentSelectedStage].map((typeObj: any) => {
        if (typeObj.parameter_type === parameterType) {
          const newTypeObj = {
            ...typeObj,
            categories: typeObj.categories.map((_catObj: any) => {
              return {
                ..._catObj,
                include: false,
                exclude: e.target.checked,
                min: null,
                max: null,
                sum_of_category_quantity: {
                  min: null,
                  max: null,
                },
                count_of_category_items: {
                  min: null,
                  max: null,
                },
              }
            }),
          }
          return newTypeObj
        }
        return typeObj
      })
      return newState
    })

    setCatConstraintsList((constraints: any) => {
      const newConstraints = JSON.parse(JSON.stringify(constraints))
      newConstraints[currentSelectedStage] = constraints[currentSelectedStage].map((constraint: any) => {
        if (constraint.type === parameterType) {
          const newConstraint = {
            ...constraint,
            min: null,
            max: null,
          }
          return newConstraint
        }
        return constraint
      }
      )
      return { ...newConstraints }
    })
    setExludeAllCategoryConstraints((prev: any) => {
      const newState = JSON.parse(JSON.stringify(prev))
      newState[currentSelectedStage] = {
        ...newState[currentSelectedStage],
        [parameterType]: e.target.checked ? !!categoriesList?.[parameterType]?.length ? categoriesList?.[parameterType] : [] : []
      }
      return newState
    })
    setUnsavedChanges(true)
  };

  const handleExcludeAllSelectionInNumericalParameters = (selectedCategory: string, isChecked: boolean) => {
    setParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = newState[currentSelectedStage].map((parameter: any) => {
        if (selectedCategory === "all") {
          return {
            ...parameter,
            include: false,
            exclude: isChecked,
            min: 0,
            max: 0,
            sum_of_category_quantity: {
              min: null,
              max: null,
            },
            count_of_category_items: {
              min: null,
              max: null,
            },
          }
        } else if (selectedCategory === parameter.category) {
          return {
            ...parameter,
            include: false,
            exclude: isChecked,
            min: 0,
            max: 0,
            sum_of_category_quantity: {
              min: null,
              max: null,
            },
            count_of_category_items: {
              min: null,
              max: null,
            },
          }
        } else {
          return parameter
        }
      })
      return newState
    })

    const allCategories: string[] = selectedCategory === "all" ? parameterList.reduce((acc: string[], curr: any) => {
      if (!acc.includes(curr.category)) {
        acc.push(curr.category)
      }
      return acc
    }, ["all"]) : ["all", selectedCategory]

    const allUniqueCategories: string[] = [...new Set(allCategories)]

    const data: { [key: string]: string[] } = allUniqueCategories.reduce((acc: any, curr: any) => {
      return {
        ...acc, [curr]: {
          isChecked,
          data: isChecked ? parameterList.filter((params: any) => curr === "all" ? true : params.category === curr).map((params: any) => params.parameter) : []
        }
      }
    }, {})

    setExludeAllParameterConstraints((prev: any) => {
      const newState = JSON.parse(JSON.stringify(prev))
      newState[currentSelectedStage] = {
        ...newState[currentSelectedStage],
        numerical_parameter: {
          ...newState[currentSelectedStage]?.numerical_parameter,
          ...data
        }
      }
      return newState
    })
    setUnsavedChanges(true)
  }

  return (
    <>
      {/* Parameter Constraints */}
      {view === "parameter-constraints" && (
        <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
          {/* Ingredients */}
          <Collapse
            style={{ width: "100%" }}
            collapsible={
              catwiseParameterConstraintsIngredients?.length
                ? undefined
                : "disabled"
            }
            defaultActiveKey={[]}
          >
            <Collapse.Panel
              key="parameter-constraint-ingredients"
              header={`${t("common.ingredients")} (${catwiseParameterConstraintsIngredients?.length
                ? catwiseParameterConstraintsIngredients.length
                : t("common.noData")
                })`}
            >
              <Row align={"middle"} gutter={16} style={{ alignItems: "start" }}>
                <Col span={4}>
                  <Menu
                    defaultSelectedKeys={[""]}
                    selectedKeys={nonExcludedCategories.includes(selectedCategoryIngredients.category) ? [selectedCategoryIngredients.category] : [""]}
                    style={{ outline: "none" }}
                    items={[
                      {
                        key: "",
                        label: "All",
                        style: { outline: "none", textOverflow: "ellipsis" },
                      },
                      ...(catwiseParameterConstraintsIngredients || []).map(
                        (catObj: any) => ({
                          key: catObj.category,
                          label: catObj.category,
                          style: { outline: "none", textOverflow: "ellipsis" },
                        }),
                      ),
                    ]}
                    onClick={(e) =>
                      handleChangeIngredients(e?.key === '' ? { category: '' } : catwiseParameterConstraintsIngredients?.find((catObj: any) => catObj?.category === e?.key), true)
                    }
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size={"middle"}
                    style={{ width: "100%" }}
                  >
                    <Space
                      size={"middle"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <StyledButton
                        size="small"
                        onClick={() =>
                          addRow(
                            "ingredients",
                            selectedCategoryIngredients.category,
                            selectedCategoryIngredients.catIndex,
                            "numerical",
                          )
                        }
                        icon={<PlusOutlined />}
                        type="link"
                        disabled={
                          ingredientsDropdownOptionsNumerical(
                            selectedCategoryIngredients.category,
                          ).length <= 0
                        }
                        style={{ outline: "none" }}
                      >
                        {`${t("common.add")} ${t("common.numerical")} ${t(
                          "common.parameter",
                        )}`}
                      </StyledButton>

                      <StyledButton
                        size="small"
                        onClick={() =>
                          addRow(
                            "ingredients",
                            selectedCategoryIngredients.category,
                            selectedCategoryIngredients.catIndex,
                            "categorical",
                          )
                        }
                        icon={<PlusOutlined />}
                        type="link"
                        disabled={
                          ingredientsDropdownOptionsCategorical(
                            selectedCategoryIngredients.category,
                          ).length <= 0
                        }
                        style={{ outline: "none" }}
                      >
                        {`${t("common.add")} ${t("common.categorical")} ${t(
                          "common.parameter",
                        )}`}
                      </StyledButton>
                    </Space>
                    {!!parameterList
                      ?.filter((parObj: any) => parObj?.type === "ingredients")?.filter((parObj: any) => nonExcludedCategories.includes(parObj.category))
                      .filter((parObj: any) => {
                        const flag =
                          parObj?.category ===
                          selectedCategoryIngredients.category ||
                          selectedCategoryIngredients.category === ""
                        return flag
                      }).length && (
                        <StyledCard title={"Numerical Parameters"}>
                          <div style={{ display: "flex", justifyContent: "flex-end", padding: "0.5rem 0rem" }}>
                            {
                              !!parameterList.filter((param: any) => (selectedCategoryIngredients.category === "" ? true : selectedCategoryIngredients.category === param.category) && param.parameter.length)?.length &&
                              <Checkbox
                                checked={!!exludeAllParameterConstraints?.numerical_parameter?.[selectedCategoryIngredients.category === "" ? "all" : selectedCategoryIngredients.category]?.data?.length && !!parameterList.length ? exludeAllParameterConstraints?.numerical_parameter?.[selectedCategoryIngredients.category === "" ? "all" : selectedCategoryIngredients.category]?.data?.length === parameterList.filter((parameter: any) => (selectedCategoryIngredients.category === "" ? true : selectedCategoryIngredients.category === parameter.category))?.length ?? false : false}
                                onChange={(e) => handleExcludeAllSelectionInNumericalParameters(selectedCategoryIngredients.category === "" ? "all" : selectedCategoryIngredients.category, e.target.checked)}>{t("aiEngine.excludeAll")}</Checkbox>
                            }
                          </div>
                          <IDataSheet
                            data={ingredientsNumericalParameterConstraintsData}
                            valueRenderer={(cell) => cell.value}
                            className="catwise-parameter-constraints-tab-table"
                          />
                        </StyledCard>
                      )}
                    {!!categorialInputList
                      ?.filter((parObj: any) => parObj?.type === "ingredients")
                      .filter((parObj: any) => {
                        const flag =
                          parObj.category ===
                          selectedCategoryIngredients.category ||
                          selectedCategoryIngredients.category === ""
                        return flag
                      }).length && (
                        <StyledCard title={"Categorical Parameters"}>
                          <IDataSheet
                            data={ingredientsCategoricalParameterConstraintsData}
                            valueRenderer={(cell) => cell.value}
                            className="catwise-parameter-constraints-tab-table"
                          />
                        </StyledCard>
                      )}
                    {!parameterList
                      ?.filter((parObj: any) => parObj?.type === "ingredients")?.filter((parObj: any) => nonExcludedCategories.includes(parObj.category))
                      .filter((parObj: any) => {
                        const flag =
                          parObj?.category ===
                          selectedCategoryIngredients.category ||
                          selectedCategoryIngredients.category === ""
                        return flag
                      }).length &&
                      !categorialInputList
                        ?.filter((parObj: any) => parObj?.type === "ingredients")
                        .filter((parObj: any) => {
                          const flag =
                            parObj.category ===
                            selectedCategoryIngredients.category ||
                            selectedCategoryIngredients.category === ""
                          return flag
                        }).length && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <Empty
                            description={
                              <Text>
                                {`${t("common.add")} ${t(
                                  "common.numerical",
                                )} ${t("common.or")} ${t(
                                  "common.categorical",
                                )} ${t("common.parameter")}`}
                              </Text>
                            }
                          />
                        </div>
                      )}
                  </Space>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>

          {/* Processing */}
          <Collapse
            style={{ width: "100%" }}
            collapsible={catwiseStateProcessing.length ? undefined : "disabled"}
            defaultActiveKey={[]}
          >
            <Collapse.Panel
              key="parameter-constraint-ingredients"
              header={`${t("common.processing")} (${catwiseParameterConstraintsProcessing?.length
                ? catwiseParameterConstraintsProcessing?.length
                : t("common.noData")
                })`}
            >
              <Row align={"middle"} gutter={16} style={{ alignItems: "start" }}>
                <Col span={4}>
                  <Menu
                    defaultSelectedKeys={[""]}
                    selectedKeys={[selectedCategoryProcessing.category]}
                    style={{ outline: "none" }}
                    items={[
                      {
                        key: "",
                        label: "All",
                        style: { outline: "none", textOverflow: "ellipsis" },
                      },
                      ...(catwiseParameterConstraintsProcessing || []).map(
                        (catObj: any) => ({
                          key: catObj.category,
                          label: catObj.category,
                          style: { outline: "none", textOverflow: "ellipsis" },
                        }),
                      ),
                    ]}
                    onClick={(e) =>
                      handleChangeProcessing(e?.key === '' ? { category: '' } : catwiseParameterConstraintsProcessing?.find((catObj: any) => catObj?.category === e?.key), true)
                    }
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size={"middle"}
                    style={{ width: "100%" }}
                  >
                    <Space
                      size={"middle"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <StyledButton
                        size="small"
                        onClick={() =>
                          addRow(
                            "processing",
                            selectedCategoryProcessing.category,
                            selectedCategoryProcessing.catIndex,
                            "numerical",
                          )
                        }
                        icon={<PlusOutlined />}
                        type="link"
                        disabled={
                          processingDropdownOptionsNumerical(
                            selectedCategoryProcessing.category,
                          ).length <= 0
                        }
                        style={{ outline: "none" }}
                      >
                        {`${t("common.add")} ${t("common.numerical")} ${t(
                          "common.parameter",
                        )}`}
                      </StyledButton>

                      <StyledButton
                        size="small"
                        onClick={() =>
                          addRow(
                            "processing",
                            selectedCategoryProcessing.category,
                            selectedCategoryProcessing.catIndex,
                            "categorical",
                          )
                        }
                        icon={<PlusOutlined />}
                        type="link"
                        disabled={
                          processingDropdownOptionsCategorical(
                            selectedCategoryProcessing.category,
                          ).length <= 0
                        }
                        style={{ outline: "none" }}
                      >
                        {`${t("common.add")} ${t("common.categorical")} ${t(
                          "common.parameter",
                        )}`}
                      </StyledButton>
                    </Space>
                    {!!parameterList
                      ?.filter((parObj: any) => parObj?.type === "processing")
                      .filter((parObj: any) => {
                        const flag =
                          parObj.category ===
                          selectedCategoryProcessing.category ||
                          selectedCategoryProcessing.category === ""
                        return flag
                      }).length && (
                        <StyledCard title={"Numerical Parameters"}>
                          <IDataSheet
                            data={processingNumericalParameterConstraintsData}
                            valueRenderer={(cell) => cell.value}
                            className="catwise-parameter-constraints-tab-table"
                          />
                        </StyledCard>
                      )}
                    {!!categorialInputList
                      ?.filter((parObj: any) => parObj?.type === "processing")
                      .filter((parObj: any) => {
                        const flag =
                          parObj.category ===
                          selectedCategoryProcessing.category ||
                          selectedCategoryProcessing.category === ""
                        return flag
                      }).length && (
                        <StyledCard title={"Categorical Parameters"}>
                          <IDataSheet
                            data={processingCategoricalParameterConstraintsData}
                            valueRenderer={(cell) => cell.value}
                            className="catwise-parameter-constraints-tab-table"
                          />
                        </StyledCard>
                      )}
                    {!parameterList
                      ?.filter((parObj: any) => parObj?.type === "processing")
                      .filter((parObj: any) => {
                        const flag =
                          parObj?.category ===
                          selectedCategoryProcessing.category ||
                          selectedCategoryProcessing.category === ""
                        return flag
                      }).length &&
                      !categorialInputList
                        ?.filter((parObj: any) => parObj?.type === "processing")
                        .filter((parObj: any) => {
                          const flag =
                            parObj.category ===
                            selectedCategoryProcessing.category ||
                            selectedCategoryProcessing.category === ""
                          return flag
                        }).length && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <Empty
                            description={
                              <Text>
                                {`${t("common.add")} ${t(
                                  "common.numerical",
                                )} ${t("common.or")} ${t(
                                  "common.categorical",
                                )} ${t("common.parameter")}`}
                              </Text>
                            }
                          />
                        </div>
                      )}
                  </Space>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </Space>
      )}

      {/* Category Consraints */}
      {view === "category-constraints" && (
        <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
          {/* Ingredients */}
          <Collapse
            style={{ width: "100%" }}
            collapsible={catwiseStateIngredients.length ? undefined : "disabled"}
            defaultActiveKey={[]}
          >
            <Collapse.Panel
              key="category-constraints-ingredients"
              header={`${t("common.ingredients")} (${catwiseStateIngredients.length
                ? catwiseStateIngredients.length - 1
                : t("common.noData")
                })`}
            >
              {!!catwiseStateIngredients?.length &&
                <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem 0rem", gap: 10 }}>

                  {<div style={{ display: "flex", gap: 0 }}>
                    <Checkbox checked={isCrossCategorical?.[currentSelectedStage]} onChange={(e) => {
                      setIsCrossCategorical((prev: any) => {
                        const newState = JSON.parse(JSON.stringify(prev))
                        newState[currentSelectedStage] = e.target.checked
                        return newState
                      })
                      if (e.target.checked) setIsCrossCategoricalModalVisible(true)
                      setUnsavedChanges(true)
                    }}
                    >{t("aiEngine.crossCategorical")}</Checkbox>
                    {isCrossCategorical?.[currentSelectedStage] && <StyledButton
                      size="small"
                      type="link"
                      onClick={() => setIsCrossCategoricalModalVisible(true)}
                      style={{ outline: "none" }}
                    >
                      {t('common.show')}
                    </StyledButton>}
                  </div>}

                  <Space>
                    <Tooltip title={t("aiEngine.useDefaultConstraintForCategories")}>
                      <Switch
                        checked={isCategoricalConstraintsUseDefault?.[currentSelectedStage]}
                        onChange={(e: any) => {
                          setIsCategoricalConstraintsUseDefault((prev: any) => {
                            const newState = JSON.parse(JSON.stringify(prev))
                            newState[currentSelectedStage] = e
                            return newState
                          })
                          setUnsavedChanges(true)
                        }}
                        checkedChildren={t('aiEngine.useDefault')}
                        unCheckedChildren={t('aiEngine.useDefault')}
                        style={{ outline: 'none' }}
                      />
                    </Tooltip>

                    <Checkbox checked={exludeAllCategoryConstraints?.["ingredients"]?.length === categoriesList?.["ingredients"]?.length} onChange={(e) => updateInclusionMode(e, "ingredients")}>{t("aiEngine.excludeAll")}</Checkbox>
                  </Space>
                </div>
              }
              <IDataSheet
                className="catwise-category-constraints-tab-table"
                data={catwiseStateIngredients}
                valueRenderer={(cell) => cell.value}
              />
            </Collapse.Panel >
          </Collapse >

          {/* Processing */}
          < Collapse
            style={{ width: "100%" }}
            collapsible={catwiseStateProcessing.length ? undefined : "disabled"}
            defaultActiveKey={[]}
          >
            <Collapse.Panel
              key="category-constraints-processing"
              header={`${t("common.processing")} (${catwiseStateProcessing.length
                ? catwiseStateProcessing.length - 1
                : t("common.noData")
                })`}
            >
              <IDataSheet
                className="catwise-category-constraints-tab-table"
                data={catwiseStateProcessing}
                valueRenderer={(cell) => cell.value}
              />
            </Collapse.Panel>
          </Collapse >
        </Space >
      )}

      {/* Modals for Parameter Constraints */}
      <Modal
        title={t("common.selectACategory")}
        open={isModalOpenIngredients.visible}
        onCancel={handleCancelIngredients}
        footer={null}
        centered
      >
        <Select
          value={selectedCategoryIngredients.category}
          style={{ width: "100%" }}
          onChange={(category: string, option: any) =>
            handleModalSelectionIngredients(
              option,
              isModalOpenIngredients.sourceSubtype,
            )
          }
          options={catwiseParameterList
            ?.filter((typeObj: any) => typeObj.parameter_type === "ingredients")
            ?.flatMap((catObj: any) => catObj.categories)
            ?.filter((catObj: any) => !catObj.exclude)
            ?.map((catObj: any) => ({
              value: catObj.category,
              label: catObj.category,
              catObj,
            }))}
        />
      </Modal>

      <Modal
        title={t("common.selectACategory")}
        open={isModalOpenProcessing.visible}
        onCancel={handleCancelProcessing}
        footer={null}
        centered
      >
        <Select
          value={selectedCategoryProcessing.category}
          style={{ width: "100%" }}
          onChange={(category: string, option: any) =>
            handleModalSelectionProcessing(
              option,
              isModalOpenProcessing.sourceSubtype,
            )
          }
          options={catwiseParameterList
            ?.filter((typeObj: any) => typeObj.parameter_type === "processing")
            ?.flatMap((catObj: any) => catObj.categories)
            ?.filter((catObj: any) => !catObj.exclude)
            ?.map((catObj: any) => ({
              value: catObj.category,
              label: catObj.category,
              catObj,
            }))}
        />
      </Modal>
    </>
  )
}
