import { volcano } from '@ant-design/colors'
import { FilterOutlined } from '@ant-design/icons';
import { Collapse, Drawer, Form, Modal, Row, Typography, Input, Col, List, Checkbox, Descriptions, Space, Tag, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollToTopInDrawer } from 'src/components/Common'
import { AsyncStates } from 'src/constants'
import { deleteFavoritesRequest, getFavoritesRequest } from 'src/store/actions/formulate'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import useTranslate from 'src/utils/useTranslate'
import utc from 'dayjs/plugin/utc'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
dayjs.extend(utc)

const { Title, Text } = Typography

type Props = {
  open: boolean
  setShowFavouritesDrawer: Dispatch<React.SetStateAction<boolean>>
  setFavoritesCurrentPage: Dispatch<React.SetStateAction<number>>
  setIngredientsParameterList: Dispatch<React.SetStateAction<{ [key: number]: string[] }>>
  setProcessingParameterList: Dispatch<React.SetStateAction<{ [key: number]: string[] }>>
  setIngredientsDataList: Dispatch<React.SetStateAction<{ [key: number]: any[] }>>
  setProcessingDataList: Dispatch<React.SetStateAction<{ [key: number]: any[] }>>
  ingredientsDataList: any
  processingDataList: any
  ingredientsInputs: any
  processingInputs: any,
  currentSelectedStage: number
}

export const FavouritesDrawer = ({ open, setShowFavouritesDrawer, setFavoritesCurrentPage, setIngredientsParameterList, setProcessingParameterList, setIngredientsDataList, setProcessingDataList, ingredientsDataList, processingDataList, ingredientsInputs, processingInputs, currentSelectedStage }: Props) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const favoritesRef = useRef<HTMLDivElement>(null)
  const [favoritesFilterForm] = useForm()
  const configs = useSelector((state: StoreState) => state.configs.features);

  const { favoritesData: { favoritesList, total_count, favoritesDisplayNames }, deleteFavoritesStatus, getFavoritesStatus } = useSelector((state: StoreState) => state.formulate)
  const displayLabel = useSelector((state: StoreState) => state.displayNames.data)

  const [selectedFavoritesList, setSelectedFavoritesList] = useState<string[]>([])

  const applyFilters = (values: any) => {
    dispatch(getFavoritesRequest({
      ...values,
      page_num: 1
    }))
    setFavoritesCurrentPage(1)
  }

  const getDisplayName = (type: string, key: string) => {
    return displayLabel?.[type]?.[key]?.name ?? key
  }

  const clearFilters = () => {
    favoritesFilterForm.resetFields()
    dispatch(getFavoritesRequest({
      page_num: 1
    }))
    setFavoritesCurrentPage(1)
  }

  const onClose = () => {
    setShowFavouritesDrawer(false)
  }

  useEffect(() => {
    if (deleteFavoritesStatus === AsyncStates.SUCCESS) {
      dispatch(getFavoritesRequest({
        page_num: 1
      }))
      setFavoritesCurrentPage(1)
      setSelectedFavoritesList([])
    }
  }, [deleteFavoritesStatus, dispatch, setFavoritesCurrentPage])

  const selectFavorite = (item: any) => {
    let showWarning = false

    if (item.processing.length) {
      const filteredProcessing = item.processing.filter((res: string) => !!Object.keys(processingInputs?.[currentSelectedStage] ?? processingInputs ?? {}).includes(res))
      if (filteredProcessing.length !== item.processing.length) {
        showWarning = true
      }
      setProcessingParameterList((prev) => ({
        ...prev,
        [currentSelectedStage]: [...new Set([...prev?.[currentSelectedStage], ...filteredProcessing])].filter((res) => res)
      }))
      setProcessingDataList((prev) => {
        return {
          ...prev,
          [currentSelectedStage]: [
            ...Object.keys(processingDataList).map(() => Object.entries(processingInputs).reduce((acc, [processing, { min, max }]: any) => ({ ...acc, [processing]: (min === max && !configs?.no_autofill) ? max : '' }), {}))
          ]
        }
      })
    }
    if (item.ingredients.length) {
      const filteredIngredients = item.ingredients.filter((res: string) => !!Object.keys(ingredientsInputs?.[currentSelectedStage] ?? ingredientsInputs ?? {}).includes(res))
      if (filteredIngredients.length !== item.ingredients.length) {
        showWarning = true
      }
      setIngredientsParameterList((prev) => ({
        ...prev,
        [currentSelectedStage]: [...new Set([...prev?.[currentSelectedStage], ...filteredIngredients])].filter((res) => res)
      }))
      setIngredientsDataList((prev) => {
        return {
          ...prev,
          [currentSelectedStage]: [
            ...Object.keys(ingredientsDataList).map(() => Object.entries(ingredientsInputs).reduce((acc, [ingredient, { min, max }]: any) => ({ ...acc, [ingredient]: (min === max && !configs?.no_autofill) ? max : '' }), {}))]
        }
      })
    }
    if (showWarning) {
      message.warning("Some of the Ingredients/Processing are not available in the current Project")
    }
    setShowFavouritesDrawer(false)
  }

  const handlePagination = (page: number) => {
    dispatch(getFavoritesRequest({
      page_num: page
    }))
    setFavoritesCurrentPage(page)
  }

  return (
    <Drawer
      width={720}
      onClose={onClose}
      open={open}
      closable={true}
      title={
        <Row justify="space-between" align="middle">
          <Title level={4} style={{ margin: 0 }}>
            {t("common.Favourites")}
          </Title>
          <StyledButton
            onClick={() => {
              Modal.confirm({
                title: `${t("common.delete")} selected record(s)?`,
                icon: <StyledDeleteIcon />,
                okText: t("common.confirm"),
                cancelText: t("common.cancel"),
                okButtonProps: { style: { borderRadius: "12px" } },
                cancelButtonProps: { style: { borderRadius: "12px" } },
                onOk: () => {
                  dispatch(
                    deleteFavoritesRequest({ ids: selectedFavoritesList })
                  );
                },
              });
            }}
            disabled={selectedFavoritesList.length === 0}
            loading={deleteFavoritesStatus === AsyncStates.LOADING}
            style={{ marginRight: 35 }}
            type="dashed"
            danger
            icon={<StyledDeleteIcon/>}
          >
            {t("aiEngine.deleteRecords")}
          </StyledButton>
        </Row>
      }
    >
      <StyledCard ref={favoritesRef}>
        <Collapse>
          <Collapse.Panel key="filters" header={t("common.addFilters")}>
            <StyledCard>
              <Form
                layout="vertical"
                onFinish={applyFilters}
                form={favoritesFilterForm}
              >
                <Form.Item label={t("common.name")} name="filter_name">
                  <Input />
                </Form.Item>

                <Row justify="end" gutter={16}>
                  <Col>
                    <StyledButton
                      htmlType="submit"
                      type="primary"
                      icon={<FilterOutlined />}
                      loading={getFavoritesStatus === AsyncStates.LOADING}
                    >
                      {t("aiEngine.button.apply")}
                    </StyledButton>
                  </Col>

                  <Col>
                    <StyledButton
                      onClick={() => clearFilters()}
                      type="dashed"
                    >
                      {t("common.clear")}
                    </StyledButton>
                  </Col>
                </Row>
              </Form>
            </StyledCard>
          </Collapse.Panel>
        </Collapse>

        <StyledCard style={{ background: "#ffffff", overflowX: "auto" }}>
          <List
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              responsive: true,
              position: "top",
              total: total_count,
              onChange: (page) => handlePagination(page),
            }}
            loading={getFavoritesStatus === AsyncStates.LOADING}
            size="small"
            grid={{ column: 1, gutter: 16 }}
            dataSource={favoritesList || []}
            renderItem={(favorite: any) => (
              <List.Item style={{ marginTop: 10 }} key={favorite.id}>
                <StyledCard
                  style={{
                    background: "#ffffff",
                    border: "1px solid #e8e8e8",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  size="small"
                  key={favorite.id}
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedFavoritesList((prev) => [
                              ...prev,
                              favorite.id,
                            ]);
                          } else {
                            setSelectedFavoritesList((prev) =>
                              prev.filter((id) => id !== favorite.id)
                            );
                          }
                        }}
                        checked={selectedFavoritesList.includes(favorite.id)}
                      >
                        <Typography.Title
                          level={5}
                          style={{ userSelect: "none" }}
                        >
                          {favorite.name}
                        </Typography.Title>
                      </Checkbox>
                    </div>
                  }
                  extra={
                    <StyledButton
                      type="link"
                      size="middle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => selectFavorite(favorite)}
                    >
                      {t("aiEngine.button.apply")}
                    </StyledButton>
                  }
                >
                  <Descriptions
                    column={1}
                    bordered
                    size="small"
                    style={{ background: "white" }}
                  >
                    <Descriptions.Item key={t("common.ingredients")} label={t("common.ingredients")}>
                      <Space
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: "125px",
                          overflowY: "auto",
                          padding: "10px",
                        }}
                      >
                        {favorite.ingredients.map((ingredient: any) => (
                          <Tag>
                            <Text
                              style={{
                                color: "#222",
                                backgroundColor: "white",
                              }}
                            >
                              {favoritesDisplayNames?.["ingredients"]?.[ingredient] ?? getDisplayName("ingredients", ingredient)}
                            </Text>
                          </Tag>
                        ))}
                      </Space>
                    </Descriptions.Item>
                    {favorite.processing.length > 0 && (
                      <Descriptions.Item label={t("common.processing")}>
                        <Space
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxHeight: "125px",
                            overflowY: "auto",
                            padding: "10px",
                          }}
                        >
                          {favorite.processing.map((processing: any) => (
                            <Tag>
                              <Text
                                style={{
                                  color: volcano[5],
                                  backgroundColor: volcano[0],
                                }}
                              >
                                {getDisplayName("processing", processing)}
                              </Text>
                            </Tag>
                          ))}
                        </Space>
                      </Descriptions.Item>
                    )}
                    {favorite.created_on && (
                      <Descriptions.Item key={t("common.createdOn")} label={t("common.createdOn")}>
                        {dayjs
                          .utc(favorite.created_on)
                          .format("MMMM Do YYYY, h:mm A") + " (UTC)"}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </StyledCard>
              </List.Item>
            )}
          />
        </StyledCard>
        {favoritesList?.length > 5 && (
          <ScrollToTopInDrawer sectionRef={favoritesRef} />
        )}
      </StyledCard>
    </Drawer>
  );
}
