import {
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { fetchFileListRequest } from "src/store/actions/workOrderDetails";
import { AsyncStates } from "src/constants";
import { useMemberName } from "src/utils/useMemberName";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import { setIsEditing } from "src/store/actions/isEditing";
import { CustomPrompt } from "src/utils/CustomPrompts";
import { blue } from "@ant-design/colors";
import { setSelectedTrials } from "src/store/actions/workOrders";
import "./ClosedWoDetails.scss";
import { fetchMethodListRequest } from "src/store/actions/characterizationMethods";
import { suggestedExperimentsRequest } from "src/store/actions/suggestedExp";
import { inverseNotificationClick } from "src/store/actions/inverseModel";
import { useHistory } from "react-router-dom";
import {
  forwardPredIdRequest,
  forwardPredResultCurrentpageRequest,
  forwardPredResultFiltersRequest,
  forwardPredResultRequest,
} from "src/store/actions/formulate";
import dayjs from "dayjs";
import { getExternalLink } from "src/utils/decorator";

const { Link, Text } = Typography;

export const ClosedWoDetails = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { getName } = useMemberName();
  const { push } = useHistory();
  const configs = useSelector((state: StoreState) => state.configs.features);
  const formulations = useSelector(
    (state: StoreState) => state.workOrderDetails.experiment
  );
  const displayIdList = useSelector(
    (state: StoreState) => state.workOrderDetails.displayIdList
  );
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data
  );
  const woStatusChange = useSelector(
    (state: StoreState) => state.workOrderDetails.woStatusChange
  );
  const experimentStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.experimentStatus
  );

  useEffect(() => window.scrollTo(0, 0), []);

  const [formulationList, setFormulationlist] = useState<any>([]);
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder
  );
  const isEditing = useSelector((state: StoreState) => state.isEditing);

  useEffect(() => {
    dispatch(fetchMethodListRequest({ method_type: "characterizations" }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedTrials([]));
    };
  }, [dispatch]);

  useEffect(() => {
    setFormulationlist(formulations.filter((res: any) => res?.use_for_ml));
  }, [formulations]);

  useEffect(() => {
    if (woStatusChange === AsyncStates.SUCCESS) {
      dispatch(setIsEditing(false));
    }
  }, [dispatch, woStatusChange]);

  useEffect(() => {
    dispatch(
      fetchFileListRequest({
        work_order_id: workOrder.work_order_id,
      })
    );
  }, [workOrder, dispatch]);

  const prediction_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.prediction_id
  );

  const getLabLocation = () => {
    return (
      workOrder?.lab_location &&
      `${workOrder?.lab_location?.name}, (${workOrder?.lab_location?.city}, ${workOrder?.lab_location?.country})`
    );
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <CustomPrompt
          isEditing={isEditing}
          message={`${t("common.unsavedChangesLost")}!.`}
        />
        {/* <StyledPageHeader
          title={t("workOrderDetails.workOrder")}
          onBack={onBack}
          ghost={false}
          extra={
            !disabled && (
              <>
                <StyledButton
                  type="primary"
                  size="large"
                  onClick={() => setVisible(true)}
                >
                  {t("common.reopenWorkOrder")}
                </StyledButton>
                <StyledButton
                  onClick={() => setCreateWoModalVisible(true)}
                  type="primary"
                  size="large"
                >
                  {t("common.createDuplicateWO")}
                </StyledButton>
              </>
            )
          }
        /> */}
        <Spin
          spinning={experimentStatus === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <StyledCard>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="large"
              >
                {!!workOrder?.suggested_experiment_id?.length && (
                  <>
                    <Card
                      type="inner"
                      bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
                    >
                      <Row justify="space-between">
                        <Col>
                          {t("workOrderDetails.basedOnAi")}
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: "more",
                            }}
                            strong
                            style={{ display: "inline" }}
                          >
                            {workOrder.suggested_experiment_id.join(", ")}
                          </Typography.Paragraph>
                        </Col>
                        {!workOrder.work_order_id && (
                          <Col>
                            <StyledButton
                              size="small"
                              onClick={() => {
                                dispatch(
                                  suggestedExperimentsRequest({
                                    prediction_id,
                                    pageNum: 1,
                                    version: workOrder.version,
                                  })
                                );
                                dispatch(inverseNotificationClick(true));
                                push(`/ai-engine/history/formulation_prediction?predId=${prediction_id}`);
                              }}
                            >
                              {t("workOrderDetails.viewAll")}
                            </StyledButton>
                          </Col>
                        )}
                      </Row>
                    </Card>
                    {/* <br /> */}
                  </>
                )}
                {!!workOrder?.prediction_experiment_ids?.length && (
                  <>
                    <Card
                      type="inner"
                      bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
                    >
                      <Row justify="space-between">
                        <Col>
                          {t("workOrderDetails.basedOnAi")}
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: "more",
                            }}
                            strong
                            style={{ display: "inline" }}
                          >
                            {workOrder.prediction_experiment_ids.join(", ")}
                          </Typography.Paragraph>
                        </Col>
                        {!workOrder.work_order_id && (
                          <Col>
                            <StyledButton
                              size="small"
                              onClick={() => {

                                dispatch(
                                  forwardPredResultRequest({
                                    prediction_id,
                                    type: "history",
                                  })
                                );
                                dispatch(forwardPredIdRequest(prediction_id));
                                dispatch(
                                  forwardPredResultCurrentpageRequest(1)
                                );
                                dispatch(forwardPredResultFiltersRequest({}));
                                push(
                                  `/ai-engine/history/properties_prediction?predId=${prediction_id}`
                                );
                              }}
                            >
                              {t("workOrderDetails.viewAll")}
                            </StyledButton>
                          </Col>
                        )}
                      </Row>
                    </Card>
                    {/* <br /> */}
                  </>
                )}
                {!!workOrder?.doe_id?.length && (
                  <>
                    <Card
                      type="inner"
                      bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
                    >
                      <Row justify="space-between">
                        <Col>
                          {`${t("workOrder.basedOnDoe")} - `}
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: "more",
                            }}
                            strong
                            style={{ display: "inline" }}
                          >
                            {workOrder.doe_id.join(", ")}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Card>
                    {/* <br /> */}
                  </>
                )}
                <Descriptions bordered column={2}>
                  <Descriptions.Item
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.title")}
                      </Text>
                    }
                    contentStyle={{ maxWidth: "45%" }}
                  >
                    <Tooltip
                      title={workOrder?.work_order_name}
                      placement={"top"}
                    >
                      <p className="desc__text__info">
                        {workOrder?.work_order_name?.length > 50
                          ? workOrder?.work_order_name.slice(0, 50) + "..."
                          : workOrder?.work_order_name}
                      </p>
                    </Tooltip>
                  </Descriptions.Item>

                  {!!workOrder?.work_order_desc && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("common.description")}
                        </Text>
                      }
                      contentStyle={{ maxWidth: "45%" }}
                    >
                      <Tooltip title={workOrder?.work_order_desc}>
                        <p className="desc__text__info">
                          {workOrder?.work_order_desc?.length > 50
                            ? workOrder?.work_order_desc.slice(0, 50) + "..."
                            : workOrder?.work_order_desc}
                        </p>
                      </Tooltip>
                    </Descriptions.Item>
                  )}

                  {!!workOrder?.start_date && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("workOrderDetails.startDate")}
                        </Text>
                      }
                    >
                      {new Date(workOrder?.start_date).toLocaleDateString()}
                    </Descriptions.Item>
                  )}
                  {!!workOrder?.close_date && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("workOrderDetails.closeDate")}
                        </Text>
                      }
                    >
                      {new Date(workOrder?.close_date).toLocaleDateString()}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.material")}
                      </Text>
                    }
                  >
                    {displayNames?.material?.[workOrder?.material]?.name ||
                      workOrder?.material_name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    className="closed__workorder__desc__item"
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.application")}
                      </Text>
                    }
                    contentStyle={{ wordBreak: "break-all", maxWidth: "45%" }}
                  >
                    <Tooltip title={workOrder?.application}>
                      <p className="desc__text__info">
                        {workOrder?.application?.length > 40
                          ? workOrder?.application.slice(0, 40) + "..."
                          : workOrder?.application}
                      </p>
                    </Tooltip>
                  </Descriptions.Item>
                  {!!workOrder?.work_order_dri?.length && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("common.members")}
                        </Text>
                      }
                    >
                      {workOrder?.work_order_dri
                        .map((res: any) => getName(res))
                        .join(", ")}
                    </Descriptions.Item>
                  )}
                  {!!workOrder?.grade && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("common.grade")}
                        </Text>
                      }
                    >
                      {workOrder?.grade}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    className="closed__workorder__desc__item"
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.workOrderOutcome")}
                      </Text>
                    }
                  >
                    {workOrder?.meta?.description}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.comment")}
                      </Text>
                    }
                    contentStyle={{ maxWidth: "45%" }}
                  >
                    <Tooltip title={workOrder?.meta?.comment}>
                      <p className="desc__text__info">
                        {workOrder?.meta?.comment?.length > 40
                          ? workOrder?.meta?.comment.slice(0, 40) + "..."
                          : workOrder?.meta?.comment}
                      </p>
                    </Tooltip>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.closedby")}
                      </Text>
                    }
                  >
                    {getName(workOrder?.meta?.user_id)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Text style={{ fontWeight: "bold" }}>
                        {t("common.closedOn")}
                      </Text>
                    }
                  >
                    {new Date(workOrder?.updated).toLocaleDateString()}
                  </Descriptions.Item>
                  {!!Object.keys(workOrder?.lab_location || {}).length && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("common.labLocation")}
                        </Text>
                      }
                    >
                      {getLabLocation() && getLabLocation().length > 30 ? (
                        <Tooltip title={getLabLocation()}>
                          {getLabLocation().slice(0, 30) + "..."}
                        </Tooltip>
                      ) : (
                        getLabLocation()
                      )}
                    </Descriptions.Item>
                  )}
                  {Boolean(configs?.pcm_graphs) && (
                    <Descriptions.Item
                      label={
                        <Text style={{ fontWeight: "bold" }}>
                          {t("wo.finalFormulation")}
                        </Text>
                      }
                    >
                      <Text
                        strong
                        type={
                          !!formulationList.filter(
                            (res: any) => res?.is_final_formulation
                          ).length
                            ? "success"
                            : "warning"
                        }
                      >
                        {formulationList
                          .filter((res: any) => res?.is_final_formulation)
                          ?.map((res: any) => res?.id_set?.formulation_id)
                          ?.map(
                            (res: any) =>
                              (
                                displayIdList.find(
                                  (id: any) => id?.formulation_id === res
                                ) as any
                              )?.formulation_display_id
                          )
                          ?.join(", ") || "Not Applicable"}
                      </Text>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </Space>
            </StyledCard>
          </Space>
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <StyledCard title={t("projects.customFields")}>
            <Descriptions column={1}>
              {workOrder?.additional_fields?.map((field: any) => <Descriptions.Item
              label={
                <Text style={{ fontWeight: "bold" }}>
                  {field.field_name}
                </Text>
              }
              >
                {
                  field.field_type === "date"
                    ? !!field.value || !!field.default_value
                      ? dayjs(field?.value ?? field.default_value)
                      : ""
                    : field.value ?? field.default_value ?? ""
                }
                {
                  !!field.value && field.field_type === "link" && (
                    <Link
                      style={{ marginLeft: "1rem" }}
                      href={getExternalLink(field.value)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkOutlined />
                    </Link>
                  )
                }
              </Descriptions.Item>)}
            </Descriptions>
            </StyledCard>
          </Space>
        </Spin>
      </Space>
    </>
  );
};
