import { put, takeLatest, call, select } from "redux-saga/effects";
import jwtManager from "src/utils/jwtManager";
import {
	smileSummaryFailure,
	smileSummaryHistoryFailure,
	smileSummaryHistoryRequest,
	smileSummaryHistorySuccess,
	smileSummaryRequest,
	smileSummaryResultFailure,
	smileSummaryResultRequest,
	smileSummaryResultSuccess,
	smileSummarySuccess,
} from "../actions/smileSummary";
import {
	fetchSmileResults,
	fetchSmilesHistory,
	fetchSmileSummary,
} from "src/services/smileSummary";
import { notification } from "antd";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";

type smileAction = {
	type: string;
	payload: any;
};

function* smileSummarySaga(action: smileAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	);
	try {
		const { defaultHeaders } = yield select((state) => state);
		const headers = { ...defaultHeaders, token: jwtManager.getToken() };
		const { data } = yield call(fetchSmileSummary, action.payload, headers);
		if (data?.status?.toLowerCase() === "failed") {
			notification.error({
				message: data.message,
				duration: 5,
			});
			throw new Error(data.message);
		} else {
			yield put(smileSummarySuccess(data));
			notification.success({
				message: data.message,
				duration: 5,
			});
		}
	} catch (error: any) {
		const errorMessage =
			error?.message ||
			error?.response?.data?.detail ||
			error?.response?.data?.message;
		yield put(smileSummaryFailure(errorMessage));
		notification.error({
			message: errorMessage || messages[ln].internal_server_error,
			duration: 5,
		});
	}
}

function* smileSummaryResultsSaga(
	action: smileAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	);
	try {
		const { defaultHeaders } = yield select((state) => state);
		const headers = { ...defaultHeaders, token: jwtManager.getToken() };
		const { data } = yield call(fetchSmileResults, action.payload, headers);
		if (data?.status?.toLowerCase() === "failed") {
			notification.error({
				message: data.message,
				duration: 5,
			});
			throw new Error(data.message);
		} else yield put(smileSummaryResultSuccess(data));
	} catch (error: any) {
		const errorMessage =
			error?.message ||
			error?.response?.data?.detail ||
			error?.response?.data?.message;
		yield put(smileSummaryResultFailure(errorMessage));
		notification.error({
			message: errorMessage || messages[ln].internal_server_error,
			duration: 5,
		});
	}
}

function* smileSummaryHistorySaga(
	action: smileAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	);
	try {
		const { defaultHeaders } = yield select((state) => state);
		const headers = { ...defaultHeaders, token: jwtManager.getToken() };
		const { data } = yield call(fetchSmilesHistory, action.payload, headers);
		if (data?.status?.toLowerCase() === "failed") {
			notification.error({
				message: data.message,
				duration: 5,
			});
			throw new Error(data.message);
		} else yield put(smileSummaryHistorySuccess(data));
	} catch (error: any) {
		const errorMessage =
			error?.message ||
			error?.response?.data?.detail ||
			error?.response?.data?.message;
		yield put(smileSummaryHistoryFailure(errorMessage));
		notification.error({
			message: errorMessage || messages[ln].internal_server_error,
			duration: 5,
		});
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(smileSummaryRequest, smileSummarySaga);
	yield takeLatest(smileSummaryResultRequest, smileSummaryResultsSaga);
	yield takeLatest(smileSummaryHistoryRequest, smileSummaryHistorySaga);
}
